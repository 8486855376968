import { Box, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import BasicCard from '../../../components/card.media';
import ImageAdd from '../../../data/image-add';
import { motion } from 'framer-motion';

const Cardssong = () => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Box>
    <Box sx={{ textAlign: 'center' }}>
      <Typography sx={{ fontSize: '32px', fontWeight: 'bold' }}>
        Spark-Singer: Karaoke Songs & Videos
      </Typography>
      <Typography sx={{ fontSize: '20px', color: '#8D858C' }}>
        Sing for yourself. Sing like the stars. All skill levels welcome
      </Typography>
    </Box>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: 10,
        flexDirection: { lg: 'row', md: 'row', xs: 'column' },
        alignItems: 'center',
      }}
    >
      {ImageAdd &&
        ImageAdd.map((data, index) => (
          <BasicCard
            key={index}
            video={
              <motion.div
                whileHover={{ scale: 1.1, opacity: 0.8 }} // Apply hover effect here
                transition={{ duration: 0.3 }}
              >
                <img
                  style={{ width: isMobile ? '150px' : '200px' }}
                  src={data.src}
                  alt={`song-${index}`}
                />
              </motion.div>
            }
            white="black"
            tittle1={data.tittle1}
            tittle2={data.tittle2}
            tittle3={data.tittle3}
          />
        ))}
    </Box>
  </Box>
  );
};

export default Cardssong;
