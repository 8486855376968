import { Box, Divider, IconButton, TextField, Typography, Modal, Container, Skeleton, Badge, Tooltip, CircularProgress, Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { FaEdit } from "react-icons/fa";
import { FaShare } from "react-icons/fa";
import { IoIosContacts } from "react-icons/io";
import BasicTabs from '../../../components/profile-tabs';
import TransitionsModal from '../../../components/model';
import { Cancel, MoreOutlined } from '@mui/icons-material';
import FollowTab from '../../../components/follow-tabs';
import { IoIosContact } from "react-icons/io";
import { IoMdCheckmark } from "react-icons/io";
import DragDropFileUpload from '../../../components/drag.drop-file4';
import { FaFacebookF } from "react-icons/fa";
import { ImWhatsapp } from "react-icons/im";
import { FaTwitter } from "react-icons/fa";
import { CiLink } from "react-icons/ci";
import { MdEmail } from "react-icons/md";
import { IoIosMore } from "react-icons/io";
import { jwtDecode } from "jwt-decode";
import axios from 'axios';
import { url } from '../../../config/config';
import girl from '../../../assest/images/girl2.jpg';
import img from '../../../assest/images/03d82d29-8fa2-44ac-b34e-353358b62ee9.jpeg';
import FadeLoader from 'react-spinners/FadeLoader';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import FacebookIcon from '@mui/icons-material/Facebook';
import { FaExpand } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import CloseIcon from '@mui/icons-material/Close';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { AiOutlineFullscreen } from "react-icons/ai";
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import gift from './gift-removebg-preview.png'
import ProfileTimeAgo from '../../../components/profileTimestamps';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { BiSolidMessageRounded } from "react-icons/bi";
import { FaWhatsapp } from "react-icons/fa";
import { PiShareFatLight } from "react-icons/pi";
import { useNavigate } from 'react-router-dom';
import EditProfileDragDrop from '../../../components/editProfile';
import diamond from '../../../assest/images/diamonds_100 (1).png'
import premium from '../../../assest/images/premiumIcon.png'
import songesss from '../../../assest/images/premiumIcon.png'
import coverimagess from '../../../assest/images/no-cover-image.jpg'
import { useSelector } from 'react-redux';
import Meta from '../../../components/meta_data';
import men from '../../../assest/images/male.webp'
import { use } from 'react';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  border: '2px solid #000',
  boxShadow: 24,
  p: 5
};
const Header = ({ func }) => {
  const [value, setValue] = React.useState('1');
  const [uploadImage, setUploadImage] = useState('')
  const [previewImage, setPreviewImage] = useState(null);
  const [open, setOpen] = useState(false)
   const [opens, setOpens] = useState(false);
  const premium = useSelector((state) => state)
  // console.log(premium.Upload.isPremium,"pareeeeee");
  
  const handleChange = (e) => {
    setBio(e.target.value);
  };
  const handleFileUpload = (file) => {
    setUploadImage(file)
    setPreviewImage(URL.createObjectURL(file));
  };
  const [profile, setprofile] = useState([])
  const [mixSong, setmixSong] = useState([])
  // useEffect(()=>{
  //  setTimeout(() => {
  //   const GetProfile=async()=>{
  //     let token =  window.localStorage.getItem('token')
  //     try {
  //       const res = await axios.get(`http://localhost:4000/profile?page=1`);

  //       console.log(res.data)
  //   } catch (error) {
  //       // Handle errors
  //       console.log(error)
  //   }
  //   }
  //   GetProfile()
  //  }, 1000);
  // },[])
  // useEffect(()=>{
  //   setTimeout(() => {
  //    const GetProfile=async()=>{


  //     try {
  //       let token =  window.localStorage.getItem('token')
  //       const decoded = jwtDecode(token);
  //       const res = await axios.get(`${url}/getmixedsongs/${decoded.userId}`);

  //       setmixSong(res.data.data)
  //       console.log(res.data.data)
  //   } catch (error) {  
  //   }
  //   //    
  //    }
  //    GetProfile()
  //   }, 1000);
  //  },[])
  const [userEmail, setUserEmail] = useState([])
  const [userProfileData, setUserProfileData] = useState([])
  const [followingDetails, setFollowingDetails] = useState([])
  const [followerDetails, setFollowerDetails] = useState([])
  const [followingCount, setFollowingCount] = useState(0)
  const [followerCount, setFollowerCount] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false); // Renamed state
  const [loading, setLoading] = useState(false);
 const[getwallet,setGetWallet] =useState()
 const [wallet, setWallet] = useState({});
 const [payment,setPayment]=useState();
 const [isPremiumActive, setIsPremiumActive] = useState(false);
  const [coverImg, setcoverImg] = useState(null)
  const [unFollow, setUnFollow] = useState([])
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const maxLength = 22;
  const modalAnimation = `
  @keyframes slideUp {
    0% {
      transform: translate(-50%, 100%); /* Start from the bottom */
    }
    100% {
      transform: translate(-50%, -50%); /* End at the center */
    }
  }
`;

  useEffect(() => {
    const getFollowingData = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/Following/getFollowingByUserID`, config)
        console.log(res.data.data[0]?.isFollow,"setFollowingDetails")
        setIsPremiumActive(res.data.data[0]?.userData[0].isPremium)
        setFollowingDetails(res.data.data)
        setFollowingCount(res.data.data.length,"djoduhdju")
        console.log(res.data.data,"djoduhdju");
        
      } catch (error) {
        console.log(error)
      }
    }
    getFollowingData()
  }, [])
  console.log(followingDetails)

  useEffect(() => {
    const getFollowersData = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/Following/getFollowersByUserID`, config)
        console.log(res.data.data, 'follower')
        setFollowerDetails(res.data.data)
        setIsPremiumActive(res.data.data[0]?.userData[0].isPremium)
        console.log(res.data.data[0]?.userData[0].isPremium,'premiii')
        setFollowerCount(res.data.data.length)
      } catch (error) {
        console.log(error)
      }
    }
    getFollowersData()
  }, [unFollow, followingCount])

  useEffect(() => {
    const GetProfileData = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/User/getUserById/${localStorage.getItem('token')}`, config)
        setUserProfileData(res.data.data)
        setBio(res.data.data[0].Bio)
        setUploadImage(res.data.data[0].ProfilePhoto,"userdata.....")
        console.log(res.data.data, "profileeeeeeeeeeeeee")
        const email = await res.data.data[0].Name;
        const username = await res.data.data[0].Name;
        console.log(email,'email')
        setUserEmail(username.split('@'))
      } catch (error) {
        console.log(error)
      }
    }
    GetProfileData()
  }, [])

  const [count, setCount] = useState([])
  // console.log(count,"profileddatajsjkjkjkhihiuiug")
  

  useEffect(() => {
    const GetProfileData = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/songcollaboration/getSongCollaborationCountLoginUserID`, config)
        setCount(res.data.data)
        console.log(res.data, "profiledata.......")
      } catch (error) {
        console.log(error, 'errrors')
      }
    }
    GetProfileData()
  }, [])
  console.log(count)
  useEffect(() => {
    const GetWallet = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/Wallet/getWalletBalance`, config)
        console.log(res.data, "getWalletBalance....")
        setWallet(res.data)
       
      } catch (error) {
        console.log(error, 'errrors')
      }
    }
    GetWallet()
  }, [])
  useEffect(() => {
    const GetWalletPayment = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/Payment/getUserSubAndWallet`, config)
        console.log(res.data, "getWalletBalancesetPayment....")
        // setPayment(res.data)
        // setIsPremiumActive(res.data.isPremiumActive);
      } catch (error) {
        console.log(error, 'errrors')
      }
    }
    GetWalletPayment()
  }, [])
  const [bio, setBio] = useState('')
  const Recording_Model = () => {
    return (
      <Box sx={{ textAlign: "center",gap:3 }}>
        <Box><Typography fontSize={20} fontWeight={700}>Recordings Count</Typography></Box>
        <Box mt={3}><Typography fontSize={13}>The recordings count indicates the total number of recordings you’ve created on SparkSinger. However, it may not account for recordings that have been deleted by you or other users you collaborated with.</Typography></Box>
        {/* <Box mt={3}><Button variant='contained' >ok</Button></Box> */}
      </Box>
    )
  }
 
  const handleUnfollow = async (id, val) => {
    console.log(id, 'followingClick')
    setLoading(id);
    try {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
      const res = await axios.delete(`${url}/api/Following/deleteFollowing/${val}`, config)
      setUnFollow((unFollow) => [...unFollow, id])
      // setFollowingCount(followingCount - 1)
      setFollowingCount((prevCount) => Math.max(0, prevCount - 1));
      console.log(res.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(null);
    }
  }

  const handleFollow = async (val, id) => {
    console.log(val, id, 'TetsValuesssssss')
    setLoading(id);
    setUnFollow((prevUnFollow) => {
      const index = prevUnFollow.indexOf(val?.userData[0]?._id);
      if (index !== -1) {
        return [
          ...prevUnFollow.slice(0, index),
          ...prevUnFollow.slice(index + 1),
        ];
      }
      return prevUnFollow;
    });
   
    // setFollowingDetails((prevState) => {
    //   const alreadyExists = prevState.some(item => item?.userData[0]?._id === val?.userData[0]?._id);
    //   console.log(alreadyExists,'dataAleardyyyyy')
    //   if (alreadyExists) {
    //     console.log('alreadyExisting');
    //     setUnFollow((prevUnFollow) => {
    //       const index = prevUnFollow.indexOf(val?.userData[0]?._id);
    //       if (index !== -1) {
    //         return [
    //           ...prevUnFollow.slice(0, index),
    //           ...prevUnFollow.slice(index + 1),
    //         ];
    //       }
    //       return prevUnFollow;
    //     });
    //     return prevState;
    //   } else {
    //     return [...prevState, val];
    //   }
    // });
   
    try {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
      const request = {
        Name: '',
        FollowingUserID: id
      }
      const res = await axios.post(`${url}/api/Following/createFollowing`, request, config)
      // console.log(res.data, 'followSuccesssMessage')
      // setFollowingCount(followingCount + 1)
      setFollowingCount((prevCount) => prevCount + 1);
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(null);
    }
  }
  // console.log(unFollow, 'followingggggggggggg')

  const handleNavigate = (UserId) => {

    // console.log(UserId, 'User.......Id');
    navigate(`/otherProfile/${UserId}`)

  }
  const showProfileModal = () => setIsModalOpen(true);  // Renamed function
  const hideProfileModal = () => setIsModalOpen(false); // Renamed function
  // console.log('List Data');
  // console.log(followingDetails, followerDetails, 'dataValuesssssssssss')
   const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const Folowing_Model = () => {
    return (
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box sx={{ padding: '5px' }}><Typography sx={{ color: 'rgb(45, 40, 103)', fontSize: '17px', fontWeight: 700 }} fontSize={13}>{userProfileData.length !== 0 && userEmail[0]}</Typography></Box>

        </Box>
        <Divider />
        <Box><FollowTab
          count={followingCount}
          countFollower={followerCount}
          values={0}
          tab1={
            <Box sx={{ height: 300, overflowY: 'scroll', width: '100%' ,color:"red"}}>
              {followingDetails && followingDetails.length > 0 ? (
                followingDetails && followingDetails.map((val, ind) => (
                  // console.log(val.userData[0]?.isPremium,"followinggdgd"),
                  
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: '10px' }}>
                    <Box sx={{ display: "flex", gap: 1, cursor: "pointer" }} onClick={() => handleNavigate(val?.userData[0]?._id)}>
                      <Box>
                        
                      <Badge                                
    overlap="circular"
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    badgeContent={
      val.userData[0]?.isPremium ? (  // Show only if isPremium is true
            <Avatar
                sx={{ bgcolor: 'red', width: 20, height: 20, fontSize: 10, border: '2px solid white' }}
                alt="Premium User"
                src={songesss} // Ensure this is the correct image
            />
        ) : null  // Hide if isPremium is false
    }
><Avatar src={val?.userData && val?.userData[0]?.ProfilePhoto|| men} /></Badge></Box>
                      <Box sx={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "140px", // Adjust width as needed
        minWidth: "50px",
        flexShrink: 1,
      }}><Typography color={'rgb(45, 40, 103)'} sx={{ marginTop: '7px' }} fontWeight={700} fontSize={16}>{val?.userData && val?.userData[0]?.Name !== '' ? val?.userData[0]?.Name : 'Default'}</Typography></Box>
                    </Box>
                    {
                      unFollow.includes(val.userData[0]?._id) ? <Box onClick={() => { handleFollow(val, val?.FollowingUserID) }}><Button size='small' sx={{ fontSize: 16, backgroundColor: 'rgb(108, 46, 255)', color: 'white', boxShadow: 'rgba(31, 32, 33, 0.1) 0px 2px 8px', fontWeight: 700, textTransform: 'capitalize', width: '108px','&:hover': {
      backgroundColor: 'rgb(88, 26, 235)', // Change color on hover
    } }}  startIcon={loading === val?.userData[0]?._id? <CircularProgress size={16} color="inherit" /> : <GoPlus size={20} />}
    disabled={loading=== val?.userData[0]?._id}>
                        {loading === val?.userData[0]?._id? "Following..." : "Follow"}
                      </Button></Box> : <Box onClick={() => { handleUnfollow(val.userData[0]?._id, val.FollowingUserID) }}><Button size='small' sx={{ fontSize: 16, backgroundColor: 'rgb(228, 233, 238)', color: 'rgb(114, 140, 166)', boxShadow: 'rgba(31, 32, 33, 0.1) 0px 2px 8px', fontWeight: 700, textTransform: 'capitalize', }} startIcon={loading === val?.userData[0]?._id? <CircularProgress size={14} color="inherit" /> : <IoMdCheckmark size={14} />}
            disabled={loading === val?.userData[0]?._id}>  {loading === val?.userData[0]?._id? "Unfollowing..." : "Following"}
                      </Button>
                      </Box>
                    }
                  </Box>
                ))
              ):(
                <Typography sx={{ textAlign: "center", marginTop: 5, color: "gray", fontSize: 16 }}>
                No following users.
            </Typography>
              )}
            </Box>
          }
          tab2={
            <Box sx={{ height: 300, overflowY: 'scroll', width: '100%'}}>
                 {followerDetails && followerDetails.length > 0 ? (
                followerDetails && followerDetails.map((val, ind) => (
                  // console.log(val,"followerDetails"),
                  
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: '10px' }}>
                    <Box sx={{ display: "flex", gap: 1, cursor: "pointer" }} onClick={() => handleNavigate(val.userData[0]._id)}>
                      <Box>
                      <Badge                                
    overlap="circular"
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    badgeContent={
      val.userData[0]?.isPremium ? (  // Show only if isPremium is true
            <Avatar
                sx={{ bgcolor: 'red', width: 20, height: 20, fontSize: 10, border: '2px solid white' }}
                alt="Premium User"
                src={songesss} // Ensure this is the correct image
            />
        ) : null  
    }
>
            <Avatar src={val.userData && val.userData[0]?.ProfilePhoto|| men} /></Badge></Box>
                      <Box sx={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "140px", // Adjust width as needed
        minWidth: "50px",
        flexShrink: 1,
      }}><Typography color={'rgb(45, 40, 103)'} sx={{ marginTop: '7px' }} fontWeight={700} fontSize={16}>{val.userData && val.userData[0]?.Name !== '' ? val.userData[0]?.Name : 'Default'}</Typography></Box>
                    </Box>
                    {
                      val.isFollow ? <Box onClick={() => { handleUnfollow(val.userData[0]._id, val.User_ID) }}><Button size='small' sx={{ fontSize: 16, backgroundColor: 'rgb(238, 241, 245)', color: '#859CB2', boxShadow: 'rgba(31, 32, 33, 0.1) 0px 2px 8px', fontWeight: 700, textTransform: 'capitalize' }} startIcon={loading === val?.userData[0]?._id? <CircularProgress size={14} color="inherit" /> : <IoMdCheckmark size={14} />} disabled={loading === val?.userData[0]?._id}>
                        {loading === val?.userData[0]?._id? "Unfollowing..." : "Following"}
                      </Button></Box> : <Box onClick={() => { handleFollow(val, val.User_ID) }}><Button size='small' sx={{ fontSize: 16, backgroundColor: 'rgb(108, 46, 255)', color: 'white', boxShadow: 'rgba(31, 32, 33, 0.1) 0px 2px 8px', fontWeight: 700, textTransform: 'capitalize', width: '108px' , '&:hover': {
      backgroundColor: 'rgb(88, 26, 235)', // Change color on hover
    }}} startIcon={loading === val?.userData[0]?._id? <CircularProgress size={16} color="inherit" /> : <GoPlus size={20}/>}disabled={loading=== val?.userData[0]?._id}>
                       {loading === val?.userData[0]?._id? "Following..." : "Follow"}
                      </Button></Box>
                    }

                  </Box>
                ))
              ) : (
                <Typography sx={{ textAlign: "center", marginTop: 5, color: "gray", fontSize: 16 }}>
                No followers yet.
            </Typography>
        )
              }
            </Box>
          }
        /></Box>
      </Box>
    )
  }
  const Follower_Model = () => {

    return (
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box sx={{ padding: '5px' }}><Typography sx={{ color: 'rgb(45, 40, 103)', fontSize: '17px', fontWeight: 700 }} fontSize={13}>{userProfileData.length !== 0 && userEmail[0]}</Typography></Box>

        </Box>
        <Divider />
        <Box><FollowTab
          count={followingCount}
          countFollower={followerCount}
          values={1}
          tab1={
            <Box sx={{ height: 300, overflowY: 'scroll', width: '100%' }}>
              { followingDetails && followingDetails.length > 0 ? (
                followingDetails && followingDetails.map((val, ind) => (
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: '10px' }}>
                    <Box sx={{ display: "flex", gap: 1, cursor: "pointer" }} onClick={() => handleNavigate(val?.userData[0]?._id)}>
                      <Box>
                      <Badge                                
    overlap="circular"
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    badgeContent={
      val.userData[0]?.isPremium ? (  // Show only if isPremium is true
            <Avatar
                sx={{ bgcolor: 'red', width: 20, height: 20, fontSize: 10, border: '2px solid white' }}
                alt="Premium User"
                src={songesss} // Ensure this is the correct image
            />
        ) : null  // Hide if isPremium is false
    }
>
                        <Avatar src={val?.userData && val?.userData[0]?.ProfilePhoto|| men} /></Badge></Box>
                      <Box sx={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "140px", // Adjust width as needed
        minWidth: "50px",
        flexShrink: 1,
      }}><Typography color={'rgb(45, 40, 103)'} sx={{ marginTop: '7px' }} fontWeight={700} fontSize={16}>{val?.userData && val?.userData[0]?.Name !== '' ? val?.userData[0]?.Name : 'Default'}</Typography></Box>
                    </Box>
                    {
                      unFollow.includes(val.userData[0]?._id) ? <Box onClick={() => { handleFollow(val, val?.FollowingUserID) }}><Button size='small' sx={{ fontSize: 16, backgroundColor: 'rgb(108, 46, 255)', color: 'white', boxShadow: 'rgba(31, 32, 33, 0.1) 0px 2px 8px', fontWeight: 700, textTransform: 'capitalize', width: '108px','&:hover': {
      backgroundColor: 'rgb(88, 26, 235)', // Change color on hover
    } }}  startIcon={loading === val?.userData[0]?._id? <CircularProgress size={16} color="inherit" /> : <GoPlus size={20} />} disabled={loading=== val?.userData[0]?._id}>
                        {loading === val?.userData[0]?._id? "Following..." : "Follow"}
                      </Button></Box> : <Box onClick={() => { handleUnfollow(val.userData[0]?._id, val.FollowingUserID) }}><Button size='small' sx={{ fontSize: 16, backgroundColor: 'rgb(228, 233, 238)', color: 'rgb(114, 140, 166)', boxShadow: 'rgba(31, 32, 33, 0.1) 0px 2px 8px', fontWeight: 700, textTransform: 'capitalize' }} startIcon={loading === val?.userData[0]?._id? <CircularProgress size={14} color="inherit" /> : <IoMdCheckmark size={14} />}
                      disabled={loading === val?.userData[0]?._id}> {loading === val?.userData[0]?._id? "Unfollowing..." : "Following"}
                      </Button>
                      </Box>
                    }
                  </Box>
                ))
              ) : (
                <Typography sx={{ textAlign: "center", marginTop: 5, color: "gray", fontSize: 16 }}>
                  No Following Users
                </Typography>
              )
              }
            </Box>
          }
          tab2={
            <Box sx={{ height: 300, overflowY: 'scroll', width: '100%' }}>
              {followerDetails && followerDetails.length > 0 ? (
                followerDetails && followerDetails.map((val, ind) => (
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: '10px' }}>
                    <Box sx={{ display: "flex", gap: 1, cursor: "pointer" }} onClick={() => handleNavigate(val.userData[0]._id)}>
                      <Box>
                      <Badge                                
    overlap="circular"
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    badgeContent={
      val.userData[0]?.isPremium ? (  // Show only if isPremium is true
            <Avatar
                sx={{ bgcolor: 'red', width: 20, height: 20, fontSize: 10, border: '2px solid white' }}
                alt="Premium User"
                src={songesss} // Ensure this is the correct image
            />
        ) : null  // Hide if isPremium is false
    }
>
                        <Avatar src={val.userData && val.userData[0]?.ProfilePhoto|| men} /></Badge></Box>
                      <Box sx={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "140px", // Adjust width as needed
        minWidth: "50px",
        flexShrink: 1,
      }}><Typography color={'rgb(45, 40, 103)'} sx={{ marginTop: '7px' }} fontWeight={700} fontSize={16}>{val.userData && val.userData[0]?.Name !== '' ? val.userData[0]?.Name : 'Default'}</Typography></Box>
                    </Box>
                    {
                      val.isFollow ? <Box onClick={() => { handleUnfollow(val.userData[0]._id, val.User_ID) }}><Button size='small' sx={{ fontSize: 16, backgroundColor: 'rgb(#2F67FE, 233, 238)', color: 'rgb(114, 140, 166)', boxShadow: 'rgba(31, 32, 33, 0.1) 0px 2px 8px', fontWeight: 700, textTransform: 'capitalize' }} startIcon={loading === val?.userData[0]?._id? <CircularProgress size={14} color="inherit" /> : <IoMdCheckmark size={14} />} disabled={loading === val?.userData[0]?._id}>
                       {loading === val?.userData[0]?._id? "Unfollowing..." : "Following"}
                      </Button></Box> : <Box onClick={() => { handleFollow(val, val.User_ID) }}><Button size='small' sx={{ fontSize: 16, backgroundColor: 'rgb(108, 46, 255)', color: 'white', boxShadow: 'rgba(31, 32, 33, 0.1) 0px 2px 8px', fontWeight: 700, textTransform: 'capitalize', width: '108px','&:hover': {
      backgroundColor: 'rgb(88, 26, 235)', // Change color on hover
    } }} startIcon={loading === val?.userData[0]?._id? <CircularProgress size={16} color="inherit" /> : <GoPlus size={20}/>}disabled={loading=== val?.userData[0]?._id}>
                       {loading === val?.userData[0]?._id? "Following..." : "Follow"}
                      </Button></Box>
                    }

                  </Box>
                ))
              ) : (
                <Typography sx={{ textAlign: "center", marginTop: 5, color: "gray", fontSize: 16 }}>
                  No Followers Yet
                </Typography>
              )
              }
            </Box>
          }
        /></Box>
      </Box>
    )
  }
  const Edit_Model = () => {

    // console.log(bio)
    const handleClick = async () => {
      setLoading(true);
      const formData = new FormData();
      formData.append('ProfileImage', uploadImage)
      formData.append('Bio', bio)
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.put(`${url}/api/User/updateUser`, formData, config);
        console.log(res)
        setOpenSuccessModal(true);
        window.location.reload()
        
      } catch (error) {
        // Handle errors
        console.log(error)

      }finally {
        
        setLoading(false); // Stop loading
      }
    }
    return (

      <Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Box>
            <Box><Typography fontSize={16} fontWeight={"bold"}>Edit profile</Typography></Box>
          </Box>

        </Box>
        <Box sx={{ mt: 2 }}>

        </Box>


        {/* Image preview */}
        <Box sx={{ mt: 2, textAlign: 'center' }} >
          <EditProfileDragDrop onFileUpload={handleFileUpload} profileImage={userProfileData[0]?.ProfilePhoto} />
          {/* {uploadImage ? (
            <img
              src={previewImage}
              alt="Preview"
              style={{ width: '100px', height: '100px', borderRadius: '50%' }}
            />
          ) : (
            <img
              src={userProfileData[0]?.ProfilePhoto}
              alt="Preview"
              style={{ width: '100px', height: '100px', borderRadius: '10%' }}
            />
          )} */}
        </Box>

        <Box sx={{ border: "5px solid #C0CCD8", p: 1, mt: 2, borderRadius: 2 }}>
  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
    <Box>
      <Typography fontSize={13} fontWeight={"bold"} color="black">
        Description
      </Typography>
    </Box>
    <Box>
    <Typography fontSize={13}>{230 - (bio?.length || 0)} Characters left</Typography>
    </Box>
  </Box>
  <Box>
    <textarea
      placeholder="Singing song"
      value={bio}
      maxLength={230}
      onChange={handleChange}
      style={{
        width: "100%",
        height: 40,
        outline: "none",
        border: "none",
        resize: "none",
        overflow: "auto",
        fontSize: 13,
        padding: 5,
      }}
    />
  </Box>
</Box>
        <Box sx={{ textAlign: "center", mt: 2 }}>
  <IconButton>
    <Button
      sx={{ width: 350 }}
      variant="contained"
      onClick={handleClick}
      disabled={loading} // Disable button while loading
    >
      {loading ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Save"}
    </Button>
  </IconButton>
</Box>
<Dialog 
  open={openSuccessModal} 
  onClose={() => setOpenSuccessModal(false)}
  sx={{
    "& .MuiPaper-root": {
      backgroundColor: "#1e1e2f", // Dark background
      color: "white", // Text color
      borderRadius: "12px",
      boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)",
      transform: "scale(0.9)", // Start slightly smaller
      opacity: 0,
      transition: "transform 0.3s ease-out, opacity 0.3s ease-out",
      ...(openSuccessModal && { transform: "scale(1)", opacity: 1 }) // Animate on open
    }
  }}
>
  <DialogTitle sx={{ fontSize: "22px", fontWeight: "bold", textAlign: "center" }}>
    🎉 Success!
  </DialogTitle>
  
  <DialogContent>
    <Typography sx={{ fontSize: "18px", textAlign: "center", color: "#f8f8f8" }}>
      Your profile has been updated successfully!
    </Typography>
  </DialogContent>
  
  <DialogActions sx={{ display: "flex", justifyContent: "center", pb: 2 }}>
    <Button 
      onClick={() => setOpenSuccessModal(false)} 
      variant="contained" 
      sx={{ 
        backgroundColor: "#4caf50", // Green success button
        color: "white",
        "&:hover": { backgroundColor: "#388e3c" }, 
        fontSize: "16px", 
        fontWeight: "bold", 
        borderRadius: "8px", 
        padding: "8px 20px",
        transition: "background-color 0.2s ease-in-out"
      }}
    >
      Done
    </Button>
  </DialogActions>
</Dialog>
      </Box>
    )
  }
  const Share_Model = () => {
    return (
      <Box>
        <Box>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: '5px' }}>
            <Box>
              <Box><Typography fontSize={17} fontWeight={"bold"} color={'rgb(45, 40, 103)'}>Share</Typography></Box>
              <Box><Typography fontSize={13} fontWeight={400} color={'rgb(114, 140, 166)'}>Spread the word</Typography></Box>
            </Box>

          </Box>
          <Divider></Divider>
          <Box sx={{ display: "flex", justifyContent: "space-around", marginBottom: '10px', padding: '10px' }}>
            <Box sx={{ display: "flex", alignItems: "center", background: '#f0f2f4', borderRadius: '5px', marginTop: '15px', marginBottom: '15px', padding: '5px', paddingRight: '15px',cursor:'pointer' }}>
              <Box>
                <IconButton><FacebookIcon style={{ fill: 'rgb(16 149 244)' }} /></IconButton>
              </Box>
              <Box ><Typography>Facebook</Typography></Box>
            </Box>
            <a style={{ cursor: 'pointer', textDecoration: 'none' }} href={`https://wa.me/?text=Share ${userProfileData[0].Name}'s profile on Smule: ${url}/api/shareSongCollab/67beb91068a1ddef7d29efba`} target='_blank'>
              <Box sx={{ display: "flex", alignItems: "center", background: '#f0f2f4', borderRadius: '5px', marginTop: '15px', marginBottom: '15px', padding: '5px', paddingRight: '15px' }}>
                <Box><IconButton><ImWhatsapp color='green' /></IconButton></Box>
                <Box><Typography sx={{ color: 'rgb(45, 40, 103)', textDecoration: 'none' }}>Whats app</Typography></Box></Box>
            </a>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-start", gap: 2, paddingLeft: 3 }}>
  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center",cursor:'pointer' }}>
  <a href="https://x.com/Spark_Singer" target="_blank" rel="noopener noreferrer">
      <Avatar sx={{ backgroundColor: "#1DA1F2" }}>
        <FaTwitter cursor="pointer" color="white" />
      </Avatar>
    </a>
    <Box sx={{ fontWeight: "bold", fontSize: 12, textAlign: "center", marginTop: 0.5, }}>Twitter</Box>
  </Box>
  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" ,cursor:'pointer'}}>
    <Avatar sx={{ backgroundColor: "#728CA6" }}><CiLink /></Avatar>
    <Box sx={{ fontWeight: "bold", fontSize: 12, textAlign: "center", marginTop: 0.5 }}>Copy Link</Box>
  </Box>
  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center",cursor:'pointer' }}>
    <Avatar sx={{ backgroundColor: "#728CA6" }}><MdEmail /></Avatar>
    <Box sx={{ fontWeight: "bold", fontSize: 12, textAlign: "center", marginTop: 0.5 }}>Email</Box>
  </Box>
  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" ,cursor:'pointer'}}>
    <Avatar sx={{ backgroundColor: "#728CA6" }}><IoIosMore /></Avatar>
    <Box sx={{ fontWeight: "bold", fontSize: 12, textAlign: "center", marginTop: 0.5 }}>More</Box>
  </Box>
</Box>
      </Box>
    )
  }
  const [expand, setExpand] = useState(false)
  const [zoomLevel, setZoomLevel] = React.useState(1);
  const handleExpand = () => {
    setExpand(true)

  }
  // console.log(followerDetails, followingDetails, 'data fieldssssss')
  const handleClose = () => {
    setOpen(false)
  }
  const [fullScreen, setFullScreen] = useState(false)
  const handleFullscreen = () => {
    setFullScreen(true)
  }
  const handleZoomIn = () => {
    setZoomLevel((prev) => Math.min(prev + 0.2, 3)); // Max zoom level: 3
  };
  
  const handleZoomOut = () => {
    setZoomLevel((prev) => Math.max(prev - 0.2, 1)); // Min zoom level: 1
  };

  // console.log(userEmail)
  return (
    <>
  <Meta image={userProfileData[0]?.ProfilePhoto} title={ userProfileData.length > 0 ? `${userProfileData[0]?.UserName}'s on SparkSinger | SparkSinger Social Singing Karaoke App` : 'SparkSinger Profile'} description={`${userProfileData[0]?.Bio}`} keywords="Karoke, Singing, SparkSinger"/>
      {
        userProfileData.length !== 0 ? <Box>
          <Box sx={{ background: 'rgb(255 255 255)' }}>
            <Container maxWidth='md'>
              <Box sx={{ backgroundColor: "#3EB8F9", height: expand ? '' : '200px', borderBottomLeftRadius: 10, backgroundImage: `url(${userProfileData.length !== 0 ? userProfileData[0]?.CoverImage : img})`, borderBottomRightRadius: 10, padding: 2, backgroundSize: expand ? '100% 100%' : 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center',cursor:"pointer" }} onClick={() => { setOpen(true) }}>
              
              <Box
  sx={{
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    display: 'flex',
    alignItems: 'center', 
    textAlign:"center",
    justifyContent: "flex-end", 
  }}
>
<Box sx={{display:'flex',background:'#7262E7',padding:'5px ',borderRadius:'10px',gap:1,textAlign:'center',justifyContent:"center"}}>
                    <Box sx={{fontSize:15}}>{wallet?.AvailableBalance}</Box>
                    <Box><img src={diamond} size={10} style={{width:20}}/></Box>
                </Box>
  {/* <Avatar
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 1, 
      width: 35, 
      height: 35, 
      fontSize: 10, 
      backgroundColor: '#352D49', 
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ fontSize: 12, color: "white" }}>
        {wallet?.AvailableBalance}
      </span> 
      <img
        src={diamond}
        style={{
          width: 20,
          height: 20, 
        }}
        alt="diamond"
      />
    </Box>
  </Avatar> */}
</Box>
<Box sx={{ position: 'relative' }}>
  {/* User Profile Avatar */}
  {userProfileData && userProfileData.length !== 0 && (
    <Badge                                
    overlap="circular"
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    badgeContent={
      premium.Upload.isPremium  ? (  // Show only if isPremium is true
            <Avatar
                sx={{ bgcolor: 'red', width: 40, height: 40, fontSize: 10, border: '2px solid white' }}
                alt="Premium User"
                src={songesss} // Ensure this is the correct image
            />
        ) : null  // Hide if isPremium is false
    }
>
<Tooltip title={hover ? "View Profile" : ""} arrow>
      <Avatar
        sx={{
          width: "162px",
          height: "162px",
          borderRadius: "50%",
          marginTop: "90px",
          opacity: 1,
          cursor: "pointer",
          top: expand ? 90 : "",
          transition: "0.3s ease-in-out",
          border: hover ? "3px solid #3498db" : "3px solid transparent", // Change border color
          filter: hover ? "brightness(0.8)" : "none", // Darken on hover
        }}
        src={userProfileData[0]?.ProfilePhoto || men}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={(e) => {
          e.stopPropagation();  // Prevents cover image click from triggering
          showProfileModal();   // Opens Profile Photo Modal
        }} // Open modal on click
      />
    </Tooltip></Badge>
  )}
  
  {/* Premium Avatar */}
  {/* {premium.Upload.isPremium ? (
        <Avatar
          sx={{
            position: "relative",
            bottom: '40px',
            left: '16%',
            transform: 'translateX(-50%)',
            width: 40,
            height: 40,
            overflow: 'hidden',
            border: '2px solid white', // Added border for a premium look
          }}
        >
          <img
            src={songesss}
            alt="Premium Avatar"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Avatar>
       ) : null}  */}
       
</Box>

              </Box>
              <Box sx={{ display: "flex", flexDirection: { md: "row", sm: "column", xs: "column" }, justifyContent: { md: "space-between", sm: "center", xs: "center" }, textAlign: { sm: "center", xs: "center", md: "left" }, padding: 2, paddingTop: '0' }}>
                <Box sx={{ display: "flex", flexDirection: { md: "row", sm: "column", xs: "column" }, gap: 2, alignItems: "center", justifyContent: "center" }}>
                  <Box sx={{ display: "flex", mt: 9, flexDirection: "column", justifyContent: "center", gap: 0.4, fontFamily: "sans-serif" }}>
                    <Box><Typography fontSize={14} fontWeight={500} color={'rgb(45, 40, 103)'} >{userProfileData[0]?.Name}</Typography></Box>
                    <Box><Typography fontSize={14} fontWeight={500} color={'rgb(170, 173, 180)'}>{userProfileData[0]?.UserName}</Typography></Box>
                    <Box sx={{wordBreak:"break-word"}}><Typography fontSize={14} fontWeight={500} color={'rgb(45, 40, 103)'} >{userProfileData[0]?.Bio}</Typography></Box>
                    <Box sx={{ display: "flex", color: "#2D2867", gap: 1 }}>
                      <Box>
                        <TransitionsModal
                          tittle={<Typography color='#2D2867' sx={{ textTransform: 'none' }} fontSize={15} fontWeight={700}>{count.RecordingResult > 1 ? `${count.RecordingResult} Recordings` : `${count.RecordingResult} Recording`}</Typography>}
                          children={Recording_Model()}
                        />
                      </Box>
                      <Box>
                        <TransitionsModal
                          tittle={<Typography color='#2D2867' sx={{ textTransform: 'none' }} fontSize={15} fontWeight={700}>{followingCount > 1 ? `${followingCount} Following` : `${followingCount} Following`}</Typography>}
                          children={Folowing_Model()}
                        />
                      </Box>
                      <Box>
                        <TransitionsModal
                          tittle={<Typography color='#2D2867' sx={{ textTransform: 'none' }} fontSize={15} fontWeight={700}>{followerCount > 1 ? `${followerCount} Followers` : `${followerCount} Follower`}</Typography>}
                          children={Follower_Model()}
                        />
                      </Box>

                    </Box>
                    <Box><Typography fontSize={15} fontWeight={400} color={'rgb(45, 40, 103)'}>{userProfileData.length !== 0 != '' ? userProfileData[0].UserProfileNote : 'born on 2002'}</Typography></Box>

                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", gap: 2}}>
                  <Box>   <TransitionsModal
                    tittle={<Box sx={{backgroundColor:"#2D2867",borderRadius:1 }}> <Button sx={{ backgroundColor: "#3C86F7", color: "white" ,"&hover":{backgroundColor:"red"}}} size='small' variant="outlined" startIcon={<FaEdit />}>
                      Edit
                    </Button></Box>}

                    children={Edit_Model()}
                  /></Box>
                  <Box>
                    <Box>

                      <TransitionsModal
                        tittle={<Box sx={{backgroundColor:"#2D2867",borderRadius:1 }}><Button sx={{ backgroundColor: "#3C86F7", color: "white" }} size='small' variant="outlined" startIcon={<FaShare />}>
                          Share
                        </Button></Box>}
                        children={Share_Model()}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Divider sx={{ bgcolor: 'rgb(228, 233, 238)', height: 2, borderRadius: '2px' }} />
            </Container>
          </Box>
          <BasicTabs mixSong={mixSong} />

        </Box> : <Box sx={{ background: 'rgb(255 255 255)' }}>
          <Container maxWidth='md'>
            <Skeleton animation='wave' variant='rounded' height={200} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                <Skeleton sx={{ opacity: 1, position: 'absolute', top: 190, marginLeft: '30px' }} variant='circular' animation='wave' width={162} height={162} />
              </Box>
              <Box sx={{ display: 'flex', gap: 1, marginTop: '10px' }}>
                <Skeleton variant='rounded' width={75} height={30} />
                <Skeleton variant='rounded' width={75} height={30} />
              </Box>
            </Box>
            <Skeleton sx={{ marginTop: '60px' }} width={200} />
            <Skeleton sx={{ marginTop: '10px' }} width={200} />
            <Box sx={{ display: 'flex', gap: 2, marginTop: '10px' }}>
              <Box sx={{ display: 'flex', gap: .5 }}>
                <Skeleton variant='rounded' width={40} />
                <Skeleton variant='rounded' width={120} />
              </Box>
              <Box sx={{ display: 'flex', gap: .5 }}>
                <Skeleton variant='rounded' width={40} />
                <Skeleton variant='rounded' width={120} />
              </Box>
              <Box sx={{ display: 'flex', gap: .5 }}>
                <Skeleton variant='rounded' width={40} />
                <Skeleton variant='rounded' width={120} />
              </Box>
            </Box>
            <Skeleton sx={{ marginTop: '10px' }} />
            <Divider sx={{ marginTop: '40px', marginBottom: '20px', bgcolor: 'rgb(228, 233, 238)', height: 2, borderRadius: '2px' }} />
            <Box sx={{ display: 'flex', gap: 4, paddingBottom: '20px' }}>
              <Box>
                <Skeleton variant='rounded' width={60} height={10} />
              </Box>
              <Box>
                <Skeleton variant='rounded' width={60} height={10} />
              </Box>
              <Box>
                <Skeleton variant='rounded' width={60} height={10} />
              </Box>
              <Box>
                <Skeleton variant='rounded' width={60} height={10} />
              </Box>
              <Box>
                <Skeleton variant='rounded' width={60} height={10} />
              </Box>
              <Box>
                <Skeleton variant='rounded' width={60} height={10} />
              </Box>
            </Box>
          </Container>

        </Box>
      }
         <>
      <style>{modalAnimation}</style> {/* Add the animation styles to the document */}

      <Modal open={isModalOpen} onClose={hideProfileModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: 'linear-gradient(135deg, #e0f7fa, #b2ebf2)', // Updated colors
            boxShadow: 24,
            p: 3,
            borderRadius: '12px', // Slightly rounded corners for better aesthetics
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: '3px solid white',
            animation: 'slideUp 0.5s ease-out', // Apply the animation here
          }}
        >
          {/* Properly Positioned Close Icon */}
          <Box
            sx={{
              position: 'absolute',
              top: 12,
              right: 12,
              width: '32px',
              height: '32px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#2D2867',
              borderRadius: '50%',
              cursor: 'pointer',
              zIndex: 10, // Ensures it stays on top
            }}
            onClick={hideProfileModal}
          >
            <CloseIcon sx={{ color: '#fff', fontSize: 22 }} />
          </Box>

          {/* Image/Avatar Section */}
          <Avatar
            src={userProfileData[0]?.ProfilePhoto || men}
            sx={{
              width: '320px',
              height: '320px',
              borderRadius: '16px', // Slightly rounded edges for a modern look
            }}
          />

          <Box mt={2}>
            <Typography fontSize={18} fontWeight={700} color={'#2D2867'} textAlign="center">
              {userProfileData[0]?.Name}
            </Typography>
          </Box>
        </Box>
      </Modal>
    </>


      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', height: '100%',}}>
            <Box sx={{ width: fullScreen ? '100%' : '80%', background: '#000000', height: '100%' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
                <Box onClick={() => {
                  setOpen(false)
                  setFullScreen(false)
                }} sx={{ cursor: 'pointer' }}>
                  <CloseIcon style={{ color: 'white', fontSize: '30px' }} />
                </Box>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Box sx={{ cursor: 'pointer' }}onClick={handleZoomIn}>
                    <ZoomInIcon style={{ color: 'white', fontSize: '40px' }} />
                  </Box>
                  <Box sx={{ cursor: 'pointer' }}onClick={handleZoomOut}>
                    <ZoomOutIcon style={{ color: 'white', fontSize: '40px' }} />
                  </Box>
                  {
                    fullScreen ? <Box sx={{ cursor: 'pointer' }} onClick={() => { setFullScreen(false) }}>
                      <CloseFullscreenIcon style={{ color: 'white', fontSize: '40px' }} />
                    </Box> : <Box sx={{ cursor: 'pointer' }} onClick={handleFullscreen}>
                      <AiOutlineFullscreen size={37} color='white' />
                    </Box>
                  }
                </Box>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', height: fullScreen ? '90%' : '80%' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', height: fullScreen ? '90%' : '80%' }}>
              <img 
  src={userProfileData[0]?.CoverImage || coverimagess}
  alt="Cover"
  style={{ 
    objectFit: 'contain', 
    padding: '60px', 
    transform: `scale(${zoomLevel})`, 
    transition: 'transform 0.3s ease-in-out', 
    marginTop: '20px' 
  }} 
/>
            </Box>
              </Box>
            </Box>
            {
              fullScreen ? '' :
              <Box sx={{ width: '20%', height: '100%', backgroundColor: '#DEF1FD', padding: '15px',alignItems:"center" }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {/* Avatar with Badge */}
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    premium.Upload.isPremium ? (
                      <Avatar
                        sx={{ bgcolor: 'red', width: 18, height: 18, fontSize: 10, border: '2px solid white' }}
                        alt="Premium User"
                        src={songesss}
                      />
                    ) : null
                  }
                >
                  <img
                    style={{ width: '60px', height: '60px', borderRadius: '50%' }}
                    src={userProfileData[0]?.ProfilePhoto || men}
                    alt="User"
                  />
                </Badge>
            
                {/* Name and Username - Centered */}
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '8px' }}>
                  <Box sx={{ fontSize: '16px', fontWeight: 700 }}>
                    {userProfileData[0]?.Name || 'Unknown'}
                  </Box>
                  <Box sx={{ color: '#2D2867', fontSize: '14px' }}>
                    {userProfileData[0]?.UserName || 'Username'}
                  </Box>
                </Box>
                
                {/* Options Icon (if needed) */}
                
              </Box>
            </Box>
            
            }
          </Box>
        </Box>
      </Modal>
      <Modal open={opens} onClose={() => setOpens(false)}>
        <Box
          sx={{
            width: 300,
            bgcolor: "white",
            borderRadius: 2,
            p: 2,
            m: "auto",
            mt: 10,
            boxShadow: 24,
          }}
        >
          {/* Header */}
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "5px" }}>
            <Box>
              <Typography fontSize={15} fontWeight={700} color={"rgb(45, 40, 103)"}>
                Share
              </Typography>
              <Typography fontSize={11} fontWeight={400} color={"rgb(114, 140, 166)"}>
                Spread the word
              </Typography>
            </Box>
             <Box><IconButton><Cancel style={{fill:'rgb(192, 204, 216)'}} onClick={() => setOpens(false)}/></IconButton></Box>
          </Box>

          <Divider />

          {/* Social Media Share Buttons */}
          <Box sx={{ display: "flex", justifyContent: "space-around", marginBottom: "10px", padding: "10px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                background: "#f0f2f4",
                borderRadius: "5px",
                marginTop: "15px",
                padding: "5px",
                paddingRight: "15px",
              }}
            >
              <IconButton>
                <FacebookIcon style={{ fill: "rgb(16 149 244)" }} />
              </IconButton>
              <Typography>Facebook</Typography>
            </Box>

            <a style={{ cursor: "pointer", textDecoration: "none" }} href="https://wa.me/?text=http://99.62.153.141:5555/profile">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  background: "#f0f2f4",
                  borderRadius: "5px",
                  marginTop: "15px",
                  padding: "5px",
                  paddingRight: "15px",
                }}
              >
                <IconButton>
                  <ImWhatsapp color="green" />
                </IconButton>
                <Typography sx={{ color: "rgb(45, 40, 103)" }}>WhatsApp</Typography>
              </Box>
            </a>
          </Box>

          {/* More Sharing Options */}
          <Box sx={{ display: "flex", justifyContent: "flex-start", gap: 2, paddingLeft: 3 }}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <a href="https://x.com/Spark_Singer" target="_blank" rel="noopener noreferrer">
      <Avatar sx={{ backgroundColor: "#1DA1F2" }}>
        <FaTwitter cursor="pointer" color="white" />
      </Avatar>
    </a>
              <Typography sx={{ fontWeight: "bold", fontSize: 12, textAlign: "center", marginTop: 0.5 }}>Twitter</Typography>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Avatar sx={{ backgroundColor: "#728CA6" }}>
                <CiLink />
              </Avatar>
              <Typography sx={{ fontWeight: "bold", fontSize: 12, textAlign: "center", marginTop: 0.5 }}>Copy Link</Typography>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Avatar sx={{ backgroundColor: "#728CA6" }}>
                <MdEmail />
              </Avatar>
              <Typography sx={{ fontWeight: "bold", fontSize: 12, textAlign: "center", marginTop: 0.5 }}>Email</Typography>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Avatar sx={{ backgroundColor: "#728CA6" }}>
                <IoIosMore />
              </Avatar>
              <Typography sx={{ fontWeight: "bold", fontSize: 12, textAlign: "center", marginTop: 0.5 }}>More</Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default Header