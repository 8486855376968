import React from 'react';
import {  Typography } from '@mui/material';
import { purple } from '@mui/material/colors';
const primary = purple[400]; 

export default function Errorpage() {
  return (
    <section style={{ padding: '40px 0', background: '#fff', fontFamily: "'Arvo', serif" }}>
    <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ textAlign: 'center' }}>
          <div style={{ backgroundImage: 'url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif)', height: '400px', backgroundPosition: 'center' }}>
            <Typography style={{ fontSize: '80px' }}>404</Typography>
          </div>
          <div style={{ marginTop: '-30px' }}>
            <h3 style={{ fontSize: '80px' }}>Look like you're lost</h3>
            <p>The page you are looking for is not available!</p>
            <a href="/" style={{ color: '#fff', padding: '10px 20px', background: '#39ac31', margin: '20px 0', display: 'inline-block' }}>
              Go to Home
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}