import { Box, Typography } from '@mui/material'
import React from 'react'

const Header = () => {
  return (
    
    <Box sx={{display:"flex",flexDirection:"column",color:"#907575",textAlign:"center",gap:6,backgroundColor:'#2D2867',padding:4}}>
        <Typography sx={{fontSize:40,fontWeight:900, background: "linear-gradient(to right bottom, #8A59FF, #8427C0)",
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      color: "transparent"}}>Join Top Artists</Typography>
        <Box>
        <Typography sx={{fontSize:20}}>Watch exclusive performances of world-renowned artists and</Typography>
        <Typography sx={{fontSize:20}}>sing along to some of their most famous and beloved songs.</Typography>
        </Box>
    </Box>
  )
}

export default Header