import { Box, Card, CircularProgress, Typography, Modal, Tooltip, Grid, Tabs, Tab, Drawer } from '@mui/material'
import React, { useEffect, useState, useRef, useContext, useCallback } from 'react'
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import LanguageIcon from '@mui/icons-material/Language';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import { Stack } from '@mui/material';
import man from './aru manama aru.jpg'
import { deepOrange } from '@mui/material/colors';
import BasicMenu from '../../../components/basic-menu2';
import { Link } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import ShareTwoToneIcon from '@mui/icons-material/ShareTwoTone';
import { Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import '../styles/reacording.css'
import axios from 'axios';
import { url } from '../../../config/config';
import AudioScreen from './audio';
import { PiSelectionBackgroundBold, PiSpeakerHigh } from "react-icons/pi";
import { BsGlobe } from "react-icons/bs";
import { FaPlay } from "react-icons/fa";
import { MdSkipNext } from "react-icons/md";
import { FaStepBackward } from "react-icons/fa";
import { IoIosSkipBackward } from "react-icons/io";
import { IoMdPause } from "react-icons/io";
import Module from './module';
import ModuleComment from './moduleComment';
import { useParams } from 'react-router-dom';
import TimeAgo from '../timeStmaps';
import { IoReloadOutline } from "react-icons/io5";
import ReplayIcon from '@mui/icons-material/Replay';
import ClearIcon from '@mui/icons-material/Clear';
import { Explore, MoreVertOutlined, Style } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { jwtDecode } from 'jwt-decode';
import CustomGlobe from '../../../components/globe/globe';
import { FullScreenSize, MediaPlay } from '../../../features/addlyric/addLyric';
import { useDispatch } from 'react-redux';
import { IoHeart } from "react-icons/io5";
import { BsShuffle } from "react-icons/bs";
import { MdSkipPrevious } from "react-icons/md";
import { FaArrowRotateLeft } from "react-icons/fa6";
import { FaArrowRotateRight } from "react-icons/fa6";
import { IoMdSkipBackward } from "react-icons/io";
import { IoRepeat } from "react-icons/io5";
import PropTypes from 'prop-types';
import { LuRepeat } from "react-icons/lu";
import { LuRepeat1 } from "react-icons/lu";
import { TbRepeatOff } from "react-icons/tb";
import { IoIosShareAlt } from "react-icons/io";
import { FaCommentDots } from "react-icons/fa6";
import { FaGift } from "react-icons/fa6";
import { AudioContext } from '..';
import InformationScreen from './infoScreen';
import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Authmodel from '../../../components/auth_model';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Video from './video';
import ModalComponent from '../../songs/componenets/module';
import { GoPlus } from "react-icons/go";
import { PiSpeakerSimpleSlash } from "react-icons/pi";
import songesss from "../../../assest/images/premiumIcon.png"
import Meta from '../../../components/meta_data';
import { setVolume } from '../../../features/redux/player';
import VolumeSlider from './volume';
import recordSong from '../../../data/recorde-song';

const MAX_SOUND = 100;


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}



CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'black',
  boxShadow: 24,
  p: 4,
};

const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'white',
  boxShadow: 24,
};

const LikeScreen = ({ value, func }) => {

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  console.log(value)
  console.log('like work')
  //  const length = value&& value.flat();
  const handleClose = () => {
    func(true)
  }
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const navigate = useNavigate();
  return (
    <Box>
      <Box sx={{ display: screenWidth > 996 ? 'block' : 'none' }}>
        <Card sx={{ height: '480px', width: '566px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: .5 }}>
              <Box sx={{ color: 'rgb(45, 40, 103)', fontSize: '17px', fontWeight: 700 }}>
              </Box>
              <Box sx={{ color: 'rgb(114, 140, 166)' }}>{value.length} people loves this recording</Box>
            </Box>
            < Box>
              <Box onClick={handleClose} sx={{ marginTop: '10px', cursor: 'pointer' }}><CancelIcon style={{ fontSize: '30px', color: 'rgb(192, 204, 216)' }} /></Box>
            </Box>
          </Box>
          <Box sx={{ height: '100%' }}>
          <Box sx={{ 
  borderTop: '3px solid #EAE9F0',  
  overflowY: 'auto', 
  height: '400px'  // Try setting a fixed height
}}>
              {
                value && value.map((val, ind) => (


                  <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px' }}>
                    <Box sx={{ display: 'flex', gap: 2, padding: '10px', alignItems: 'center' }}>
                      <Box >
                        <Badge
                          overlap="circular"
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          badgeContent={
                            val.users[0]?.isPremium ? (  // Show only if isPremium is true
                              <Avatar
                                sx={{ bgcolor: 'red', width: 20, height: 20, fontSize: 10, border: '2px solid white' }}
                                alt="Premium User"
                                src={songesss} // Ensure this is the correct image
                              />
                            ) : null  // Hide if isPremium is false
                          }
                        >
                          <Avatar sx={{ width: '45px', height: '45px', borderRadius: '50%' }} src={val.users[0].ProfilePhoto} /></Badge>
                      </Box>
                      <Box>
                        <Box sx={{ color: 'rgb(45, 40, 103)', fontSize: '16px', fontWeight: 700 }}>{val.users[0].Name !== '' ? val.users[0].Name : 'Default'}</Box>
                      </Box>
                      {/* <Box>
                    <Box sx={{color:'rgb(114, 140, 166)',fontSize:'16px',fontWeight:400}}>{val.users[0].Email}</Box>
                    </Box>   */}
                    </Box>
                    <Box onClick={() => {
                      navigate(`/otherProfile/${val.users[0]._id}`)
                    }} sx={{ padding: '10px', marginTop: '10px', cursor: 'pointer' }}>
                      <KeyboardArrowRightIcon style={{ fontSize: '30px', color: 'rgb(192, 204, 216)', cursor: 'pointer' }} />
                    </Box>
                  </Box>
                ))
              }
            </Box>
          </Box>
        </Card>
      </Box>
      <Modal style={{ display: screenWidth < 996 && screenWidth > 510 ? 'block' : 'none' }}
        open={screenWidth < 996 ? true : false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style1}>
          <Card sx={{ height: '480px', width: '480px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: .5 }}>
                <Box sx={{ color: 'rgb(45, 40, 103)', fontSize: '17px', fontWeight: 700 }}>Loves</Box>
                <Box sx={{ color: 'rgb(114, 140, 166)' }}>{value.length} people loves this recording</Box>
              </Box>
              < Box>
                <Box onClick={handleClose} sx={{ marginTop: '10px', cursor: 'pointer' }}><CancelIcon style={{ fontSize: '30px', color: 'rgb(192, 204, 216)' }} /></Box>
              </Box>
            </Box>
            <Box sx={{ height: '100%' }}>
              <Box sx={{ borderTop: '3px solid #EAE9F0', overflowY: 'scroll', height: '100%' }}>
                {
                  value && value.flat().map((val, ind) => (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px' }}>
                      <Box sx={{ display: 'flex', gap: 2, padding: '10px' }}>
                        <Box >
                          <Avatar sx={{ width: '45px', height: '45px', borderRadius: '50%' }} src={val.ProfilePhoto} />
                        </Box>
                        <Box>
                          <Box sx={{ color: 'rgb(45, 40, 103)', fontSize: '16px', fontWeight: 700 }}>{val.FirstName !== '' ? val.FirstName : 'Default'}</Box>
                        </Box>
                      </Box>
                      <Box onClick={() => {
                        navigate(`/otherProfile/${val._id}`)
                      }} sx={{ padding: '10px', marginTop: '10px', cursor: 'pointer' }}>
                        <KeyboardArrowRightIcon style={{ fontSize: '30px', color: 'rgb(192, 204, 216)', cursor: 'pointer' }} />
                      </Box>
                    </Box>
                  ))
                }
              </Box>
            </Box>
          </Card>
        </Box>
      </Modal>
      <Drawer anchor='bottom'
        open={screenWidth < 510 ? true : false}
        PaperProps={{
          sx: {
            backgroundColor: 'white',
          }
        }}
      >
        <Box>
          <Card sx={{ height: '100%', width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: .5 }}>
                <Box sx={{ color: 'rgb(45, 40, 103)', fontSize: '17px', fontWeight: 700 }}>Loves</Box>
                <Box sx={{ color: 'rgb(114, 140, 166)' }}>{value.length} people loves this recording</Box>
              </Box>
              < Box>
                <Box onClick={handleClose} sx={{ marginTop: '10px', cursor: 'pointer' }}><CancelIcon style={{ fontSize: '30px', color: 'rgb(192, 204, 216)' }} /></Box>
              </Box>
            </Box>
            <Box sx={{ height: '100%' }}>
              <Box sx={{ borderTop: '3px solid #EAE9F0', overflowY: 'scroll', height: '100%' }}>
                {
                  value && value.flat().map((val, ind) => (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px' }}>
                      <Box sx={{ display: 'flex', gap: 2, padding: '10px' }}>
                        <Box >
                          <Avatar sx={{ width: '45px', height: '45px', borderRadius: '50%' }} src={val.ProfilePhoto} />
                        </Box>
                        <Box>
                          <Box sx={{ color: 'rgb(45, 40, 103)', fontSize: '16px', fontWeight: 700 }}>{val.FirstName !== '' ? val.FirstName : 'Default'}</Box>
                        </Box>
                      </Box>
                      <Box onClick={() => {
                        navigate(`/otherProfile/${val._id}`)
                      }} sx={{ padding: '10px', marginTop: '10px', cursor: 'pointer' }}>
                        <KeyboardArrowRightIcon style={{ fontSize: '30px', color: 'rgb(192, 204, 216)', cursor: 'pointer' }} />
                      </Box>
                    </Box>
                  ))
                }
              </Box>
            </Box>
          </Card>
        </Box>
      </Drawer>
    </Box>
  )
}

const GiftListScreen = ({ value, func }) => {
  const [image, setImage] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const GetGift = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }

        const res = await axios.get(`${url}/api/SongCollaborationGift/getSongCollaborationGiftBySongCollaborationID/`, config);
        setImage(res.data.data, "kesannsjudysu")

      } catch (error) {
        console.log(error)
      }
      //    
    }
    GetGift()
  }, [])
  console.log(value, 'giftData')
  console.log(image, 'image')
  const [text, setText] = useState(true)

  const findUsersWithMultipleEntries = (arr) => {
    // Create a map to store user data and counts
    const userCounts = {};

    // Iterate over each object in the array
    arr.forEach(obj => {
      obj.users.forEach(user => {
        if (userCounts[user._id]) {
          userCounts[user._id].count++;
        } else {
          userCounts[user._id] = { ...user, count: 1 };
        }
      });
    });

    // Filter users with more than one entry
    const usersWithMultipleEntries = Object.values(userCounts).filter(user => user.count > 1);

    return usersWithMultipleEntries;
  };


  const result = findUsersWithMultipleEntries(value);
  console.log(result);
  // Output: [{ userId: "user1", count: 3 }]

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  console.log(value)
  //  const length = value&& value.flat();
  const handleClose = () => {
    func(true)
  }
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <Box>
      
      <Box sx={{ display: screenWidth < 996 ? 'none' : 'block' }}>
        <Card sx={{ height: '480PX', width: '566px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: .5 }}>
              <Box sx={{ color: 'rgb(45, 40, 103)', fontSize: '17px', fontWeight: 700 }}>Received gifts</Box>
              <Box sx={{ color: 'rgb(114, 140, 166)' }}>{value && value.length} gifts</Box>
            </Box>
            < Box>
              <Box sx={{ marginTop: '10px', cursor: 'pointer' }} onClick={() => { func(true) }}><CancelIcon style={{ fontSize: '30px', color: 'rgb(192, 204, 216)' }} /></Box>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center', borderTop: '3px solid #EAE9F0', background: 'rgb(240, 242, 244)' }}>
              <Box sx={{ marginTop: '15px', marginBottom: '10px' }}>
                <button onClick={() => { setText(true) }} style={{ width: '130px', height: '32px', background: text ? 'rgb(114, 140, 166)' : 'rgb(255, 255, 255)', color: text ? 'rgb(255, 255, 255)' : 'rgb(114, 140, 166)', borderTopLeftRadius: '3px', borderBottomLeftRadius: '3px', border: 'none', fontSize: '14px', fontWeight: 700, cursor: 'pointer' }}>All Gifts</button>
                <button onClick={() => { setText(false) }} style={{ width: '130px', height: '32px', background: text ? 'rgb(255, 255, 255)' : 'rgb(114, 140, 166)', color: text ? 'rgb(114, 140, 166)' : 'rgb(255, 255, 255)', borderTopRightRadius: '3px', borderBottomRightRadius: '3px', border: 'none', fontSize: '14px', fontWeight: 700, cursor: 'pointer' }}>Top Gifters</button>
              </Box>
            </Box>
          </Box>
          {
            text ? <Box sx={{ overflowY: 'scroll', height: '100%' }}>
              <Box sx={{ marginBottom: '130px' }}>
                {
                  value && value.map((val, ind) => (
                    // console.log(val.
                    //   Description, 'gigetehjhsyus'),


                    <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px', cursor: 'pointer' }}>
                      <Box sx={{ display: 'flex', padding: '15px', gap: 2 }} onClick={() => {
                        navigate(`/otherProfile/${val.users[0]._id}`)
                      }}>
                        <Avatar src={val.users[0].ProfilePhoto} sx={{ width: '45px', height: '45px', borderRadius: '50%' }} />
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: .3 }}>
                          <Box sx={{ display: 'flex', gap: .5 }} >
                            <Box sx={{ color: 'rgb(45, 40, 103)', fontSize: '17px', fontWeight: 700, cursor: 'pointer' }}>{val.users[0]?.Name}</Box>
                            <Box sx={{color:'rgb(114, 140, 166)',fontSize:'17px',fontWeight:400,}}>sent gift</Box>

                          </Box>
                          <Box sx={{ color: 'rgb(114, 140, 166)', fontSize: '17px', fontWeight: 400, }}>
                          <Box sx={{color:'rgb(114, 140, 166)',fontSize:'17px',fontWeight:400,wordBreak: 'break-word' }}>{val.Description}</Box>
                            <TimeAgo timestamp={val.createdAt} />
                          </Box>
                        </Box>
                      </Box>
                      <Box sx={{ padding: '10px' }}>


                        <img style={{ height: '60px', width: '60px' }} src={val.gifts[0]?.GiftImageURL} />


                      </Box>
                    </Box>
                  ))
                }
              </Box>
            </Box> : <Box sx={{ overflowY: 'scroll', height: '100%' }}>
              {
                result && result.map((val, ind) => (
                  <Box onClick={() => {
                    navigate(`/otherProfile/${val._id}`)
                  }} sx={{
                    display: 'flex', justifyContent: 'space-between', cursor: 'pointer', '&:hover': {
                      boxShadow: 'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'
                    }
                  }}>
                    <Box sx={{ display: 'flex', gap: 2, padding: '10px' }}>
                      <Box>
                        <Avatar sx={{ width: '45px', height: '45px', borderRadius: '50%' }} src={val.ProfilePhoto} />
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '5px' }}>
                        <Box sx={{ fontSize: '14px', fontWeight: 700, color: 'rgb(45, 40, 103)' }}>{val.Name}</Box>
                        <Box sx={{ fontSize: '14px', fontWeight: 400, color: 'rgb(114, 140, 166)' }}>{val.count} gifts</Box>
                      </Box>
                    </Box>
                    <Box sx={{ padding: '10px', marginTop: '10px', cursor: 'pointer' }}>
                      <KeyboardArrowRightIcon style={{ fontSize: '30px', color: 'rgb(192, 204, 216)', cursor: 'pointer' }} />
                    </Box>
                  </Box>
                ))
              }
            </Box>
          }
        </Card>
      </Box>
      <Modal style={{ display: screenWidth < 996 && screenWidth > 510 ? 'block' : 'none' }}
        open={screenWidth < 996 ? true : false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style1}>
          <Card sx={{ height: '480PX', width: '566px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: .5 }}>
                <Box sx={{ color: 'rgb(45, 40, 103)', fontSize: '17px', fontWeight: 700 }}>Received gifts</Box>
                <Box sx={{ color: 'rgb(114, 140, 166)' }}>{value && value.length} gifts</Box>
              </Box>
              < Box>
                <Box sx={{ marginTop: '10px', cursor: 'pointer' }} onClick={() => { func(true) }}><CancelIcon style={{ fontSize: '30px', color: 'rgb(192, 204, 216)' }} /></Box>
              </Box>
            </Box>
            <Box>
              <Box sx={{ display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center', borderTop: '3px solid #EAE9F0', background: 'rgb(240, 242, 244)' }}>
                <Box sx={{ marginTop: '15px', marginBottom: '10px' }}>
                  <button onClick={() => { setText(true) }} style={{ width: '130px', height: '32px', background: text ? 'rgb(114, 140, 166)' : 'rgb(255, 255, 255)', color: text ? 'rgb(255, 255, 255)' : 'rgb(114, 140, 166)', borderTopLeftRadius: '3px', borderBottomLeftRadius: '3px', border: 'none', fontSize: '14px', fontWeight: 700, cursor: 'pointer' }}>All Gifts</button>
                  <button onClick={() => { setText(false) }} style={{ width: '130px', height: '32px', background: text ? 'rgb(255, 255, 255)' : 'rgb(114, 140, 166)', color: text ? 'rgb(114, 140, 166)' : 'rgb(255, 255, 255)', borderTopRightRadius: '3px', borderBottomRightRadius: '3px', border: 'none', fontSize: '14px', fontWeight: 700, cursor: 'pointer' }}>Top Gifters</button>
                </Box>
              </Box>
            </Box>
            {
              text ? <Box sx={{ overflowY: 'scroll', height: '100%' }}>
                {
                  value && value.map((val, ind) => (
                    console.log(val, 'jshsgsgsh'),

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px' }}>
                      <Box sx={{ display: 'flex', padding: '15px', gap: 2 }}>
                        <Avatar src={val.users[0]?.ProfilePhoto} sx={{ width: '45px', height: '45px', borderRadius: '50%' }} />
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: .3 }}>
                          <Box sx={{ display: 'flex', gap: .5 }}>
                            <Box sx={{ color: 'rgb(45, 40, 103)', fontSize: '17px', fontWeight: 700,wordBreak: 'break-word' }}>{val.users[0]?.Name}</Box>
                            <Box sx={{ color: 'rgb(114, 140, 166)', fontSize: '17px', fontWeight: 400, }}>send gift</Box>
                          </Box>
                          <Box sx={{ color: 'rgb(114, 140, 166)', fontSize: '17px', fontWeight: 400, }}>
                            <TimeAgo timestamp={val.createdAt} />
                          </Box>
                        </Box>
                      </Box>
                      <Box sx={{ padding: '10px' }}>

                        <img style={{ height: '60px', width: '60px' }} src={val.gifts[0]?.GiftImageURL} />

                      </Box>
                    </Box>
                  ))
                }
              </Box> : <Box sx={{ overflowY: 'scroll', height: '100%' }}>
                {
                  result && result.map((val, ind) => (
                    <Box onClick={() => {
                      navigate(`/otherProfile/${val._id}`)
                    }} sx={{
                      display: 'flex', justifyContent: 'space-between', cursor: 'pointer', '&:hover': {
                        boxShadow: 'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'
                      }
                    }}>
                      <Box sx={{ display: 'flex', gap: 2, padding: '10px' }}>
                        <Box>
                          <Avatar sx={{ width: '45px', height: '45px', borderRadius: '50%' }} src={val.ProfilePhoto} />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '5px' }}>
                          <Box sx={{ fontSize: '14px', fontWeight: 700, color: 'rgb(45, 40, 103)' }}>{val.Email}</Box>
                          <Box sx={{ fontSize: '14px', fontWeight: 400, color: 'rgb(114, 140, 166)' }}>{val.count} gifts</Box>
                        </Box>
                      </Box>
                      <Box sx={{ padding: '10px', marginTop: '10px', cursor: 'pointer' }}>
                        <KeyboardArrowRightIcon style={{ fontSize: '30px', color: 'rgb(192, 204, 216)', cursor: 'pointer' }} />
                      </Box>
                    </Box>
                  ))
                }
              </Box>
            }

          </Card>
        </Box>
      </Modal>
      <Drawer anchor='bottom'
        open={screenWidth < 510 ? true : false}
        PaperProps={{
          sx: {
            backgroundColor: 'white',
          }
        }}
      >
        <Box>
          <Card sx={{ height: '100%', width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: .5 }}>
                <Box sx={{ color: 'rgb(45, 40, 103)', fontSize: '17px', fontWeight: 700 }}>Received gifts</Box>
                <Box sx={{ color: 'rgb(114, 140, 166)' }}>{value && value.length} gifts</Box>
              </Box>
              < Box>
                <Box sx={{ marginTop: '10px', cursor: 'pointer' }} onClick={() => { func(true) }}><CancelIcon style={{ fontSize: '30px', color: 'rgb(192, 204, 216)' }} /></Box>
              </Box>
            </Box>
            <Box>
              <Box sx={{ display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center', borderTop: '3px solid #EAE9F0', background: 'rgb(240, 242, 244)' }}>
                <Box sx={{ marginTop: '15px', marginBottom: '10px' }}>
                  <button onClick={() => { setText(true) }} style={{ width: '130px', height: '32px', background: text ? 'rgb(114, 140, 166)' : 'rgb(255, 255, 255)', color: text ? 'rgb(255, 255, 255)' : 'rgb(114, 140, 166)', borderTopLeftRadius: '3px', borderBottomLeftRadius: '3px', border: 'none', fontSize: '14px', fontWeight: 700, cursor: 'pointer' }}>All Gifts</button>
                  <button onClick={() => { setText(false) }} style={{ width: '130px', height: '32px', background: text ? 'rgb(255, 255, 255)' : 'rgb(114, 140, 166)', color: text ? 'rgb(114, 140, 166)' : 'rgb(255, 255, 255)', borderTopRightRadius: '3px', borderBottomRightRadius: '3px', border: 'none', fontSize: '14px', fontWeight: 700, cursor: 'pointer' }}>Top Gifters</button>
                </Box>
              </Box>
            </Box>
            {
              text ? <Box sx={{ overflowY: 'scroll', height: '100%' }}>
                {
                  value && value.map((val, ind) => (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px' }}>
                      <Box sx={{ display: 'flex', padding: '15px', gap: 2 }}>
                        <Avatar src={val.users[0].ProfilePhoto} sx={{ width: '45px', height: '45px', borderRadius: '50%' }} />
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: .3 }}>
                          <Box sx={{ display: 'flex', gap: .5 }}>
                            <Box sx={{ color: 'rgb(45, 40, 103)', fontSize: '17px', fontWeight: 700 }}>{val.users[0].Email}</Box>
                            <Box sx={{ color: 'rgb(114, 140, 166)', fontSize: '17px', fontWeight: 400, }}>send gift</Box>
                          </Box>
                          <Box sx={{ color: 'rgb(114, 140, 166)', fontSize: '17px', fontWeight: 400, }}>
                            <TimeAgo timestamp={val.createdAt} />
                          </Box>
                        </Box>
                      </Box>
                      <Box sx={{ padding: '10px' }}>
                        {
                          image && image.map((data, index) => (
                            data._id === val.GiftID ? <img style={{ height: '56px', width: '56px' }} src={data.GiftImageURL} /> : ''
                          ))
                        }
                      </Box>
                    </Box>
                  ))
                }
              </Box> : <Box sx={{ overflowY: 'scroll', height: '100%' }}>
                {
                  result && result.map((val, ind) => (
                    <Box onClick={() => {
                      navigate(`/otherProfile/${val._id}`)
                    }} sx={{
                      display: 'flex', justifyContent: 'space-between', cursor: 'pointer', '&:hover': {
                        boxShadow: 'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'
                      }
                    }}>
                      <Box sx={{ display: 'flex', gap: 2, padding: '10px' }}>
                        <Box>
                          <Avatar sx={{ width: '45px', height: '45px', borderRadius: '50%' }} src={val.ProfilePhoto} />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '5px' }}>
                          <Box sx={{ fontSize: '14px', fontWeight: 700, color: 'rgb(45, 40, 103)' }}>{val.Email}</Box>
                          <Box sx={{ fontSize: '14px', fontWeight: 400, color: 'rgb(114, 140, 166)' }}>{val.count} gifts</Box>
                        </Box>
                      </Box>
                      <Box sx={{ padding: '10px', marginTop: '10px', cursor: 'pointer' }}>
                        <KeyboardArrowRightIcon style={{ fontSize: '30px', color: 'rgb(192, 204, 216)', cursor: 'pointer' }} />
                      </Box>
                    </Box>
                  ))
                }
              </Box>
            }

          </Card>
        </Box>
      </Drawer>
    </Box>
  )
}

const Header = () => {
  const [recordSong, setrecordSong] = useState([])
  const [moduleOpen, setModuleOpen] = useState(true)
  const autoPlay = useSelector((state) => state)
  // console.log(autoPlay,"jouhhndndd");
  
  const autoPlayIstrue = autoPlay.Upload.AutoPlayOn[0].isTrue;
  const [like, setLike] = useState(false)
  const [globe, setGlobe] = useState(false);
  const [restrict, setRestrict] = useState(false)
  const dispatch = useDispatch();
  const userTokanValue = localStorage.getItem('token');
  // let location = useLocati on();

  // const indexNext = location.state.param2 + 1;
  // const WholeData = location.state.param1;
  // console.log(location.state)
  const navigate = useNavigate();
  const params = useParams()
  // console.log(params)
  const [audioLoop, setAudioLoop] = useState(false)
  const [autoplay, setAutoPlay] = useState(true)
  const [likeCount, setLikeCount] = useState(0)
  const [giftCount, setGiftCount] = useState(0)
  const [commentCount, setCommentCount] = useState(0)
  const [likeData, setLikeData] = useState([])
  const [userData, setUserData] = useState([])
  const [open, setOpen] = useState(false);
  const [loop, setLoop] = useState(false)
  const [repeat, setRepeat] = useState(true)
  const playerRef = useRef();
  const [listScreen, setListScreen] = useState(true)
  const [likeArrey, setLikeArrey] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContnet] = useState('')
  const [modalType, setModalType] = useState('');
  const [modalLink, setModalLink] = useState('');
  const [likeList, setLikeList] = useState([])
  const [volumeController, setVolumeController] = useState(60)
  useEffect(() => {
    const GetProfile = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = userTokanValue !== null ? await axios.get(`${url}/api/SongCollaboration/getSongCollaborationById/${params.id}`, config) : await axios.get(`${url}/api/SongCollaboration/getSongCollaborationOtherById/${params.id}`);
        console.log(res.data.data[0]?.songcollaborationcommentsCount, 'SongCollborationVlauessss')
        setrecordSong(res.data.data)

        const data = {
          mediaPlay: false
        }
        dispatch(MediaPlay(data))
        const like = await res.data.data[0]
        setLikeArrey(like.LikesUserID)
        console.log(like)
        setLikeCount(like.NoOfLikes)
        setGiftCount(like && like.NoOfGifts)
        setCommentCount(like && like.songcollaborationcommentsCount)
      } catch (error) {
        console.log(error)
      }
      //    
    }
    GetProfile()
    setAudio(false)
    setPlay(false)
    setLike(false)
  }, [params.id])
  useEffect(() => {
    const GetProfile = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = userTokanValue !== null ? await axios.get(`${url}/api/SongCollaboration/getSongCollaborationById/${params.id}`, config) : await axios.get(`${url}/api/SongCollaboration/getSongCollaborationOtherById/${params.id}`);
        console.log(res.data.data, 'SongCollborationVlauessss111')
        setrecordSong(res.data.data)
        const like = await res.data.data[0]
        console.log(like)
        setGiftCount(like && like.NoOfGifts)
        setCommentCount(like && like.songcollaborationcommentsCount)
        console.log();
        
      } catch (error) {
        console.log(error)
      }
      //    
    }
    GetProfile()
  }, [])
  // console.log(recordSong, 'datasVa;uessssssss')
  //  useEffect(() => {
  //   const  getSongCollabLike=async()=>{
  //     try {
  //       const data = {
  //         SongCollaborationID : params.id 
  //       }
  //       const config={
  //         headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  //       }
  //       const res = userTokanValue !== null ? await axios.post(`${url}/api/SongCollaborationLike/getSongCollaborationLikeByUserIDs`,data,config) : await axios.post(`${url}/api/SongCollaborationLike/getSongCollaborationLikeOtherByUserIDs`,data);
  //       setLikeData(res.data.data)
  //       setLikeList(res.data.data)
  //         console.log(res.data.data,'res')
  //   } catch (error) {
  //     console.log(error)
  //   }
  //   // 
  //   const data = {
  //     mediaPlay : false
  // }
  // dispatch(MediaPlay(data))   
  //    }
  //     getSongCollabLike()
  //  },[])
  //  console.log(likeData.length)
  const inputRef = useRef(null)
  const [audioTime, setAudioTime] = useState('')
  const hanldeAudio = () => {
    setAudioTime(audioRef.current.currentTime)
  }
  const audioRef = useContext(AudioContext);

  const handleplay = () => {
    console.log('playinggggPlyaer')
    setPlay(false)
    audioRef.current.play()
    const data = {
      mediaPlay: false
    }
    dispatch(MediaPlay(data))
  }
  const theme=createTheme({
      components:{
        PiSpeakerHigh:{
          styleOverrides:{
            root:{
              color:"black"
            }
          }
        }
        
      }
    })
  const handlePause = () => {
    console.log('pausnngHappinnngggg')
    setPlay(true)
    audioRef.current.pause()
    const data = {
      mediaPlay: true
    }
    dispatch(MediaPlay(data))
    console.log(autoPlay, 'checkingggggg')
  }
  const [play, setPlay] = useState(false)

  const str_pad_left = (string, pad, length) => {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }

  const handleInput = async (e) => {
    setAudioTime(e.target.value)
    audioRef.current.currentTime = e.target.value;
    //  audioRef.current.play()
    // audioRef.current.pause()
  }

  //  setPlay(autoPlay && autoPlay.Upload.MediaPlayerPlay[0].isTrue)

  // if(autoPlay && autoPlay.Upload.MediaPlayerPlay[0].isTrue === false){
  //   setPlay(false)
  // }

  const [moduleComment, setModuleComment] = useState(true)

  // console.log(audioRef.current)


  const min = inputRef.current ? inputRef.current.min : ''
  const max = inputRef.current ? inputRef.current.max : ''
  const value = inputRef.current ? inputRef.current.value : ''
  if (inputRef.current != null) {
    inputRef.current.style.background = `linear-gradient(to right, #C0CCD8 0%, #C0CCD8 ${(value - min) / (max - min) * 100}%, rgba(236, 232, 255, 0.4) ${(value - min) / (max - min) * 100}%, rgba(236, 232, 255, 0.4) 100%)`
    inputRef.current.oninput = function () {
      this.style.background = `linear-gradient(to right, #C0CCD8 0%, #C0CCD8 ${(this.value - this.min) / (this.max - this.min) * 100}%, rgba(236, 232, 255, 0.4) ${(this.value - this.min) / (this.max - this.min) * 100}%, rgba(236, 232, 255, 0.4) 100%)`
      console.log('vijay')
    };
  }

  const min1 = playerRef.current ? playerRef.current.min : ''
  const max1 = playerRef.current ? playerRef.current.max : ''
  const value1 = playerRef.current ? playerRef.current.value : ''
  if (playerRef.current != null) {
    playerRef.current.style.background = `linear-gradient(to right, rgb(208 63 10) 0%, rgb(208 63 10) ${(value1 - min1) / (max1 - min1) * 100}%, rgb(138 138 138) ${(value1 - min1) / (max1 - min1) * 100}%, rgb(138 138 138) 100%)`
    playerRef.current.oninput = function () {
      this.style.background = `linear-gradient(to right, rgb(208 63 10) 0%, rgb(208 63 10) ${(this.value - this.min1) / (this.max1 - this.min1) * 100}%, rgb(138 138 138) ${(this.value - this.min1) / (this.max - this.min1) * 100}%, rgb(138 138 138) 100%)`
      console.log('vijay')
    };
  }

  
  const [values, setValues] = useState([])
  useEffect(() => {

    const GetExplore = async () => {

      try {
        const res = await axios.get(`${url}/api/SongCollaboration/getSongCollaborationForiegnkey`);
        setValues(res.data.data)
        console.log(res.data.data)
      } catch (error) {

      }
    }
    GetExplore()

  }, [])

  function findNextObjectById(array, id) {
    let index = array.findIndex(obj => obj._id === id);
    if (index !== -1 && index < array.length - 1) {
      return array[index + 1];
    } else {
      return null; // Next object not found
    }
  }

  function findPreviousObjectById(array, id) {
    let index = array.findIndex(obj => obj._id === id);
    if (index !== -1 && index < array.length - 1) {
      return array[index - 1];
    } else {
      return null; // Next object not found
    }
  }

  let paramID = params.id

  let nextObject = findNextObjectById(values, paramID)
  let prevoiusObject = findPreviousObjectById(values, paramID)
  // console.log(prevoiusObject)

  if (nextObject) {
    console.log("Next object:", nextObject);
  } else {
    // console.log("Next object not found.");
  }
  // console.log(nextObject)
  const [count, setCount] = useState(5)



  const audioDuration = audioRef.current ? Math.floor(audioRef.current.duration) : 0;
  const currentValue = Math.floor(audioTime);
  const [isPremiumActive, setIsPremiumActive] = useState(false)
  const [payment, setPayment] = useState();
  const minutes = Math.floor(audioDuration / 60);
  const seconds = Math.floor(audioDuration - minutes * 60);
  const currentTimeVaue = str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);
  const currentminutes = Math.floor(audioTime / 60);
  const currentSeconds = Math.floor(audioTime - currentminutes * 60)
  const currentRunning = str_pad_left(currentminutes, '0', 2) + ':' + str_pad_left(currentSeconds, '0', 2);
  const [audio, setAudio] = useState(false)
  const [share, setShare] = useState('')
  const handleLoad = () => {
    setAudio(true)
  }


  const handleModule = (val) => {
    setModuleOpen(val)
  }
  const handleComment = (val) => {
    setModuleComment(val)
  }
  const [image, setImage] = useState([])
  useEffect(() => {
    const GetGift = async () => {
      try {

        const res = await axios.get(`${url}/api/Gift/getGift`);
        setImage(res.data.data)

      } catch (error) {
        console.log(error)
      }
      //    
    }
    GetGift()
  }, [])
  const userTokenData = localStorage.getItem('token') !== null ? jwtDecode(localStorage.getItem('token')) : ''
  const [upNextClose, setUpNextClose] = useState(true)
  const handleCloseUpnext = () => {
    setUpNextClose(false)
    setAutoPlay(false)
  }
  const handleLike = () => {
    setLike(true)
    if (!localStorage.getItem('token')) {
      setRestrict(true)
    } else {
      setLikeCount(likeCount + 1)
      try {
        const data = {
          UserID: userTokenData.userId,
          SongCollaborationID: params.id
        }
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = axios.post(`${url}/api/SongCollaborationLike/createSongCollaborationLike`, data, config)
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    }
  }

  const OpenModal = (val, data, link) => {
    console.log(val, data)
    setIsModalOpen(true)
    setModalContnet(val)
    setModalType(data)
    setModalLink(link)
  }
  const closeModal = () => {
    setIsModalOpen(false)
    setModalContnet('')
    setModalType('')
  }

  // useEffect(() => {
  //   if(audioRef.current.currentTime === audioRef.current.duration){
  //     console.log('vijay')
  //   }
  // },[])\

  useEffect(() => {
    if (audio == true && currentTimeVaue === currentRunning) {
      setAutoPlay(true)
      setCount(5)
      setUpNextClose(true)
    }
  }, [currentRunning])

  useEffect(() => {
    if (audio == true && currentTimeVaue === currentRunning && upNextClose === true && autoPlayIstrue === true && nextObject !== null) {
      console.log('upNextCalledd')
      const interval = setTimeout(() => {
        navigate(`/record/${nextObject._id}`)
        setAutoPlay(false)
      }, 5000)
      return () => clearTimeout(interval)
    } else if (nextObject !== null) {
      console.log('underOnessss')
      const interval = setTimeout(() => {
        setAutoPlay(false)
      }, 5000)
      return () => clearTimeout(interval)
    }
  }, [currentRunning, upNextClose, autoPlayIstrue])

  useEffect(() => {
    if (audio == true && currentTimeVaue === currentRunning) {
      const interval = setInterval(() => {
        setCount(count - 1)
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [count, currentRunning])

  const handleReplay = () => {
    audioRef.current.currentTime = 0
    audioRef.current.play()
  }
  // console.log(autoplay)
  // console.log(audioRef.current)
  recordSong && recordSong.map((val) => {
    // console.log(val)
  })

  const handleGiftCount = (val) => {
    setGiftCount(giftCount + val)
  }

  const handleCommentCount = (val) => {
    setCommentCount(commentCount + val)
  }


  const handleGlobe = (val) => {
    setGlobe(val)
  }
  const handleNavigation = () => {  
    navigate("/appgride"); // Change this to the actual route
  };  
   
  const handleClose = () => {
    const data = {
      fullscreen: false
    }
    dispatch(FullScreenSize(data))
  }
  // console.log(userTokenData)
  const [tabValue, setTabValue] = useState(0)
  const [commentData, setCommentData] = useState([])
  const handleTab = (event, newValue) => {
    setTabValue(newValue);
    switch (newValue) {
      case 1:
        const getComment = async () => {
          const songCollabId = await recordSong[0]?._id
          try {
            const res = await axios.get(`${url}/api/SongCollaborationComment/getSongCollaborationCommentById/${songCollabId}`);
            console.log(res.data.data, 'comment')
            setCommentData(res.data.data)
          } catch (error) {
            console.log(error)
          }
        }
        getComment()
        break;
    }
  }
  useEffect(() => {
    const GetWalletPayment = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/Payment/getUserSubAndWallet`, config)
        console.log(res.data, "getWalletBalancesetPayment....")
        setPayment(res.data)
        setIsPremiumActive(res.data.isPremiumActive);
      } catch (error) {
        console.log(error, 'errrors')
      }
    }
    GetWalletPayment()
  }, [])

  const handlePlayerPlay = () => {
    audioRef.current.play()
  }
  // console.log(recordSong)

  // console.log(likeArrey)
  const handleLikeUserList = async () => {
    console.log('likePressedddddd')
    setListScreen(false)
    setGiftScreen(false)
    const data = {
      SongCollaborationID: params.id
    }
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }
    try {
      const res = userTokanValue !== null ? await axios.post(`${url}/api/SongCollaborationLike/getSongCollaborationLikeByUserIDs`, data, config) : await axios.post(`${url}/api/SongCollaborationLike/getSongCollaborationLikeOtherByUserIDs`, data);
      console.log(res.data.data, 'likeDataaaaaaaaaa')
      setLikeList(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  const [giftScreen, setGiftScreen] = useState(false)
  const [giftData, setGiftData] = useState([])
  const handleListGift = async () => {
    setListScreen(false)
    setGiftScreen(true)
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }
    try {
      const res = await axios.get(`${url}/api/SongCollaborationGift/getSongCollaborationGiftBySongCollaborationID/${params.id}`, config)
      console.log(res.data.data, "kesavanjognjigif........")
      setGiftData(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleLikeClose = (val) => {
    setListScreen(val)
  }

  const HandleClose = () => {
    setRestrict(false)
  }

  const handleGiftOpen = () => {
    if (!localStorage.getItem('token')) {
      setRestrict(true)
    } else {
      setModuleOpen(false)
    }
  }

  const handlePrevoius = () => {
    if (prevoiusObject !== undefined) {
      navigate(`/record/${prevoiusObject?._id}`)
    } else {
      if (prevoiusObject === undefined && currentValue > 3) {
        audioRef.current.currentTime = 0
        audioRef.current.play()
      }
    }
  }
  // console.log(autoPlay)
  // console.log(typeof volumeController, 'VolumeControllerrrrr')
  return (
    <Box className='wholeDiv' sx={{ display: "flex", gap: 10 }}>
       <Meta  title={ recordSong.length > 0 ? `${recordSong[0]?.karaokes[0]?.Name}'s on SparkSinger | SparkSinger Social Singing Karaoke App` : 'SparkSinger Profile'}  keywords="Karoke, Singing, SparkSinger"/>
      {
        recordSong && recordSong.map((data, index) => (
          console.log(data,"jodjdjdjd"),

          <>
            <Box className='topAlign' sx={{ marginTop: '40px' }}>
              <div>
                {
                  data.PostType === 'Audio' ? <>
                    <div>
                      {
                        globe ? <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Box className='songInfoTop' sx={{ display: "none", justifyContent: "space-between" }}>
                            <Box sx={{ display: "flex", gap: 1 }}>
                              <Box>
                                  <Badge
                                  overlap="circular"
                                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                  badgeContent={
                                    data.users[0]?.isPremium ? (  // Show only if isPremium is true
                                      <Avatar
                                        sx={{ bgcolor: 'red', width: 17, height: 17, fontSize: 10, border: '2px solid white' }}
                                        alt="Premium User"
                                        src={songesss} // Ensure this is the correct image
                                      />
                                    ) : null  // Hide if isPremium is false
                                  }
                                >
                                   <Avatar alt="Travis Howard" src={data.users.length != 0 ? data.users[0].ProfilePhoto : man} />
                                </Badge>
      
                            </Box>
                              <Box >
                                <Badge
                                  overlap="circular"
                                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                  badgeContent={
                                    data.users[0]?.isPremium ? (  // Show only if isPremium is true
                                      <Avatar
                                        sx={{ bgcolor: 'red', width: 17, height: 17, fontSize: 10, border: '2px solid white' }}
                                        alt="Premium User"
                                        src={songesss} // Ensure this is the correct image
                                      />
                                    ) : null  // Hide if isPremium is false
                                  }
                                >
                                  <Avatar alt="Travis Howard" src={data.users.length != 0 ? data.users[0].ProfilePhoto : man} />
                                </Badge>
                              </Box>
                              <Box>
                                <Box>
                                  <Typography sx={{ fontSize: { lg: 14, md: 10 }, marginTop: '10px' }}>{data.users.length != 0 ? data.users[0].Email : 'vijaykumar'} </Typography>

                                  {/* <Typography sx={{fontSize:{lg:14,md:10} }}>VarinderBawa786</Typography> */}
                                </Box>
                              </Box>
                            </Box>
                            <Box>
                              <BasicMenu id={data.users && data.users[0]?._id} value={data.karaokes && data.karaokes[0]?._id} />
                            </Box>
                          </Box>
                          <CustomGlobe handlepLay={handleGlobe} data={recordSong} />
                        </Box> :
                          <Box>
                            <Box className='songInfoTop' sx={{ display: "none", justifyContent: "space-between" }}>
                              <Box sx={{ display: "flex", gap: 1 }}>
                                <Box>
                                {/* <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                      data.users[0]?.isPremium ? (  // Show only if isPremium is true
                                        <Avatar
                                          sx={{ bgcolor: 'red', width: 17, height: 17, fontSize: 10, border: '2px solid white' }}
                                          alt="Premium User"
                                          src={songesss} // Ensure this is the correct image
                                        />
                                      ) : null  // Hide if isPremium is false
                                    }
                                  >
                                    <Avatar alt="Travis Howard" src={data.users.length != 0 ? data.users[0].ProfilePhoto : man} />
                                  </Badge> */}
      
                            </Box>
                                <Box >
                                  <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                      data.users[0]?.isPremium ? (  // Show only if isPremium is true
                                        <Avatar
                                          sx={{ bgcolor: 'red', width: 17, height: 17, fontSize: 10, border: '2px solid white' }}
                                          alt="Premium User"
                                          src={songesss} // Ensure this is the correct image
                                        />
                                      ) : null  // Hide if isPremium is false
                                    }
                                  >
                                    <Avatar alt="Travis Howard" src={data.users.length != 0 ? data.users[0].ProfilePhoto : man} />
                                  </Badge>
                                </Box>
                                <Box>
                                  <Box>
                                    <Typography sx={{ fontSize: { lg: 14, md: 10 }, marginTop: '10px' }}>{data.users.length != 0 ? data.users[0].Email : 'vijaykumar'} </Typography>

                                    {/* <Typography sx={{fontSize:{lg:14,md:10} }}>VarinderBawa786</Typography> */}
                                  </Box>
                                </Box>
                              </Box>
                              <Box>
                                <BasicMenu id={data.users && data.users[0]?._id} value={data.karaokes && data.karaokes[0]?._id} />
                              </Box>
                            </Box>
                            <div className={autoPlay?.Upload.MediaPlayerPlay[0].isTrue ? 'vijay1' : 'vijay'} style={{ backgroundImage: `url(${data.CoverImageURL})`, opacity: audio ? '' : .5, display: audio ? '' : 'flex', alignItems: audio ? '' : 'center', textAlign: audio ? '' : 'center', justifyContent: audio ? '' : 'center' }}>
                              <>
                                {
                                  audio ? <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '15px' }}>
                                      {
                                        currentTimeVaue === currentRunning ? <div>
                                          <div style={{ color: 'white' }}>
                                            <PiSpeakerHigh size={20} />
                                          </div>
                                          {/* <div  style={{position:'absolute',top:20,left:40}}>
                                <input type='range' max={MAX_SOUND}   min={0} onChange={handleVolume} style={{width:'70px'}}/>
                              </div>  */}
                                        </div> :
                                      <ThemeProvider theme={theme}><VolumeSlider   audioRef={audioRef} autoPlay={autoPlay}/></ThemeProvider> 
                                      }
                                      {
                                        currentTimeVaue === currentRunning ? <div style={{ width: '24px', height: '18px' }}>
                                          <BsGlobe color='white' size={20} />
                                        </div> : <div className={autoPlay?.Upload.MediaPlayerPlay[0].isTrue ? '' : 'globe'} style={{ width: '24px', height: '18px' }} onClick={() => { setGlobe(true) }}>
                                          <BsGlobe color='white' size={20} />
                                        </div>
                                      }
                                    </Box>
                                    {
                                      currentTimeVaue === currentRunning ? <div style={{ display: 'flex', gap: 30, justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
                                        <div onClick={handlePrevoius}>
                                          <IoIosSkipBackward color='white' size={30} />
                                        </div>
                                        <div onClick={handleReplay}>
                                          <ReplayIcon style={{ color: 'white', fontSize: '40px',cursor:"pointer" }} />
                                        </div>
                                        <div onClick={() => {
                                          navigate(`/record/${nextObject?._id}`)
                                        }}>
                                          <MdSkipNext color='white' size={40} />
                                        </div>
                                      </div> : <div style={{ display: 'flex', gap: 30, justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
                                        <div onClick={handlePrevoius} className={autoPlay?.Upload.MediaPlayerPlay[0].isTrue ? '' : 'controls'} style={{ opacity: prevoiusObject === undefined && currentValue < 3 ? 0.5 : '' }}>
                                          <IoIosSkipBackward color='white' size={30} />
                                        </div>

                                        {
                                          autoPlay?.Upload.MediaPlayerPlay[0].isTrue ? <div className={autoPlay?.Upload.MediaPlayerPlay[0].isTrue ? '' : 'controls'} onClick={handleplay}>
                                            <FaPlay color='white' size={30} cursor={"pointer"}  />
                                          </div> : <div className={autoPlay?.Upload.MediaPlayerPlay[0].isTrue ? '' : 'controls'} onClick={handlePause}>
                                            <IoMdPause color='white' size={30} cursor={"pointer"} />
                                          </div>
                                        }
                                        <div onClick={() => {
                                          navigate(`/record/${nextObject?._id}`)
                                        }} className={autoPlay?.Upload.MediaPlayerPlay[0].isTrue ? '' : 'controls'}>
                                          <MdSkipNext color='white' size={40} />
                                        </div>
                                      </div>
                                    }
                                    {
                                      currentTimeVaue === currentRunning && nextObject !== null ? <>
                                        {
                                          autoplay ? <Box sx={{ position: 'absolute', opacity: 1, background: '#3C86F7', padding: '5px', color: 'white', fontSize: '12px', borderRadius: '5px', left: 200, bottom: 75 }}>{autoPlayIstrue === true ? `Coming up in ${count}` : 'Up next :'}</Box> : ''
                                        }
                                      </> : ''
                                    }
                                    <Box>
                                      {
                                        currentTimeVaue === currentRunning && nextObject !== null ? <>
                                          {
                                            autoplay ? <Card sx={{ margin: '30px', marginTop: '130px' }} >
                                              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Box sx={{ display: 'flex', padding: '5px', gap: 1 }}>
                                                  <Box onClick={() => navigate(`/record/${nextObject._id}`)}>
                                                    <img style={{ width: '60px', height: '60px', marginTop: '5px' }} src={nextObject && nextObject.CoverImageURL} />
                                                  </Box>
                                                  <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '12px' }}>
                                                    <Box sx={{ color: 'rgb(45, 40, 103', fontSize: '14px', fontWeight: 'bold' }}>{nextObject && nextObject.Description}</Box>
                                                    <Box>{nextObject && nextObject.users[0].Email}</Box>
                                                  </Box>
                                                </Box>
                                                <Box sx={{ padding: '5px', cursor: 'pointer' }}>
                                                  <ClearIcon onClick={handleCloseUpnext} style={{ color: 'rgb(129, 131, 140)', fontSize: '20px' }} />
                                                </Box>
                                              </Box>
                                            </Card> : ''
                                          }
                                        </>
                                          :
                                          <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                                              <div className={autoPlay?.Upload.MediaPlayerPlay[0].isTrue ? '' : 'details'} style={{ color: 'white' }}>
                                                <p style={{ margin: '0', fontWeight: 'bold', width: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{data.karaokes[0]?.Name}</p>
                                                <p style={{ margin: '0', fontWeight: 'bold', width: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{data.karaokes[0]?.Artist}</p>
                                              </div>
                                              <div className={autoPlay?.Upload.MediaPlayerPlay[0].isTrue ? '' : 'timimg'}>
                                                <div style={{ color: 'white', fontWeight: 'bold' }}>{currentRunning} / {audioRef.current !== undefined ? currentTimeVaue : '00:00'}</div>
                                              </div>
                                            </div>
                                            <div className={autoPlay?.Upload.MediaPlayerPlay[0].isTrue ? '' : 'runningbar'} style={{ padding: '10px' }}>
                                              <input style={{ width: '96%' }} ref={inputRef} id="myinput" max={audioDuration} value={currentValue} onChange={handleInput} type='range' />
                                            </div>
                                          </Box>
                                      }
                                    </Box>
                                  </div> : <Box sx={{ display: 'flex', alignItems: 'center' }}><CircularProgress /></Box>
                                }
                              </>
                            </div>
                          </Box>
                      }
                    </div>
                    <audio autoPlay onCanPlay={handleLoad} onTimeUpdate={hanldeAudio} ref={audioRef} src={data.MixedSongURL} loop={audioLoop} />
                  </> : data.PostType === 'Video' ? <Box sx={{ width: '480px', height: '480px' }}>
                    <Video wholeData={values} data={recordSong} />
                  </Box> : data.PostType === 'Animation' && <Box sx={{ width: '480px', height: '480px' }}>
                    <Video wholeData={values} data={recordSong} />
                  </Box>
                }
              </div>
              {/* <div className='video_animi'>
              
              </div> */}

              {/* <Box sx={{position:"absolute",top:"50%",right:"40%",color:"white"}}>
            <SkipPreviousIcon fontSize='large'/>
            <PlayArrowIcon fontSize='large'/>
            <SkipNextIcon fontSize='large'/>
            </Box> */}
            </Box>
            {
              moduleComment ? <>
                {
                  moduleOpen ?
                    <Box className='songDetails' sx={{ marginTop: listScreen ? '80px' : '50px', width: '100%' }}>
                      <Box className='song-data' sx={{ display: 'flex', flexDirection: 'column' }}>
                        {
                          listScreen ? <>
                            <Box className='songInfo' sx={{ display: "flex", justifyContent: "space-between" }}>
                              <Box sx={{ display: "flex", gap: 1.5 }}>
                                {/* <Box>
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <Avatar
                                            sx={{ bgcolor: 'red', width: 20, height: 20, fontSize: 10 }}
                                            alt="Remy Sharp"
                                            src="/broken-image.jpg"
                                        >
                                            VIP
                                        </Avatar>
                                    }
                                >
                                    <Avatar alt="Travis Howard" src={man} />
                                </Badge>
      
                            </Box> */}
                                <Box sx={{ display: 'flex', gap: .5 }}>
                                  {
                                    data.joinOriginaluser.length > 0 && <Box sx={{ cursor: 'pointer', color: "red" }} onClick={() => {
                                      navigate(`/otherProfile/${data.joinOriginaluser[0]?._id}`)
                                    }}>
                                      <Badge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        badgeContent={
                                          data.users[0]?.isPremium ? (  // Show only if isPremium is true
                                            <Avatar
                                              sx={{ bgcolor: 'red', width: 17, height: 17, fontSize: 10, border: '2px solid white' }}
                                              alt="Premium User"
                                              src={songesss} // Ensure this is the correct image
                                            />
                                          ) : null  // Hide if isPremium is false
                                        }
                                      >
                                        <Avatar
                                          style={{ width: 40, height: 40 }}
                                          alt="Travis Howard"
                                          src={data.users.length !== 0 ? data.joinOriginaluser[0]?.ProfilePhoto : man}
                                        />

                                      </Badge>
                                    </Box>
                                  }
                                  <Box sx={{ cursor: 'pointer' }} onClick={() => {
                                    navigate(`/otherProfile/${data.users[0]?._id}`)
                                  }}>
                                    <Badge
                                      overlap="circular"
                                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                      badgeContent={
                                        data.users[0]?.isPremium ? (  // Show only if isPremium is true
                                          <Avatar
                                            sx={{ bgcolor: 'red', width: 17, height: 17, fontSize: 10, border: '2px solid white' }}
                                            alt="Premium User"
                                            src={songesss} // Ensure this is the correct image
                                          />
                                        ) : null  // Hide if isPremium is false
                                      }
                                    >
                                      <Avatar style={{ width: 40, height: 40 }} alt="Travis Howard" src={data.users.length != 0 ? data.users[0].ProfilePhoto : man} />
                                    </Badge>
                                  </Box>
                                </Box>
                                {
                                  data?.joinOriginaluser?.length > 0 ? <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Box sx={{ display: 'flex', gap: .5 }}>
                                      {
                                        data.joinOriginaluser.length > 0 && <Box onClick={() => {
                                          navigate(`/otherProfile/${data.joinOriginaluser[0]?._id}`)
                                        }}>
                                          <Box sx={{ color: 'rgb(45, 40, 103)', fontSize: 16, fontWeight: 700, cursor: 'pointer' }}>{data.joinOriginaluser[0]?.Name}</Box>
                                        </Box>
                                      }
                                      {
                                        data.joinOriginaluser.length > 0 && <Box sx={{ alignItems: 'center', cursor: 'pointer' }}>
                                          <GoPlus size={15} color='rgb(114, 140, 166)' />
                                        </Box>
                                      }
                                    </Box>
                                    <Box onClick={() => {
                                      navigate(`/otherProfile/${data.users[0]?._id}`)
                                    }}>
                                      <Typography sx={{ fontSize: { lg: 14, md: 10 }, alignItems: 'center', fontWeight: 700, color: 'rgb(114, 140, 166)', cursor: 'pointer' }}>{data.users[0].Name != 0 ? data.users[0].Name : 'user'} </Typography>

                                      {/* <Typography sx={{fontSize:{lg:14,md:10} }}>VarinderBawa786</Typography> */}
                                    </Box>
                                  </Box> : <Box sx={{ marginTop: '7px' }} onClick={() => {
                                    navigate(`/otherProfile/${data.users[0]?._id}`)
                                  }}>
                                    <Typography sx={{ fontSize: { lg: 14, md: 10 }, alignItems: 'center', fontWeight: 700, color: 'rgb(45, 40, 103)', cursor: 'pointer' }}>{data.users[0].Name != 0 ? data.users[0].Name : 'user'} </Typography>

                                    {/* <Typography sx={{fontSize:{lg:14,md:10} }}>VarinderBawa786</Typography> */}
                                  </Box>
                                }

                              </Box>
                              
                                 
                                  
                                
                              <Box sx={{ cursor: 'pointer' }}>
                               
                                <BasicMenu id={data.users && data.users[0]?._id } value={data.karaokes && data.karaokes[0]?._id} />
                              </Box>
                            </Box>
                            <Box marginTop={2} sx={{ fontSize: { xl: "24px", lg: "20px", md: "20px" }, overflow: 'hidden', fontWeight: 700, color: 'rgb(45, 40, 103)', marginTop: '30px' }}>
                              <Box>{data.karaokes && data.karaokes[0]?.Name}</Box>
                            </Box>
                            <Box sx={{ display: "flex", gap: 1, fontSize: { lg: 14, md: 8. }, marginTop: '10px', marginBottom: '10px' }}>
                              {
                                likeCount !== 0 ? <Box sx={{ borderBottom: "1px dotted rgb(192, 204, 216)", color: 'rgb(114, 140, 166)', font: '14px', fontWeight: 700, cursor: 'pointer' }} onClick={handleLikeUserList}>{likeCount} <span style={{ fontSize: '14px', fontWeight: 400 }}>loves</span></Box> : ''

                              }
                              {
                                giftCount !== 0 ? <Box sx={{ borderBottom: "1px dotted rgb(192, 204, 216)", color: 'rgb(114, 140, 166)', font: '14px', fontWeight: 700, cursor: 'pointer' }} onClick={handleListGift}>{giftCount} <span style={{ fontSize: '14px', fontWeight: 400 }}>gifts</span></Box> : ''
                              }
                              {
                                commentCount !== 0 ? <Box sx={{ borderBottom: "1px dotted rgb(192, 204, 216)", color: 'rgb(114, 140, 166)', font: '14px', fontWeight: 700, cursor: 'pointer' }} onClick={() => setModuleComment(false)}>{commentCount} <span style={{ fontSize: '14px', fontWeight: 400 }}>comments</span></Box> : ''
                              }
                            </Box>
                            <Box marginTop={2} sx={{ fontSize: { lg: 14, md: 8 } }}>
                              <Box sx={{ color: 'rgb(45, 40, 103)', lineHeight: '20px', wordBreak: 'break-word' }}>
                                <Typography>{data.Description}</Typography>
                              </Box>
                              {/* <Box>   <Link href="#" sx={{textDecoration:"none"}}>#TmailLovesong</Link> HAPPY BIRTHDAY <Link href="#" sx={{textDecoration:"none"}}>{data.users[0]?.Email}</Link> </Box>
                        <Box>   <Link href="#" sx={{textDecoration:"none"}}>#smulecommunityIN #smuleindia #kolloywood #classic #popularsongs 💓💓💓💓</Link> </Box> */}
                            </Box>
                            <Box sx={{ display: "flex", gap: 2 }}>
                              <Box>
                                <Typography sx={{ color: 'rgb(114, 140, 166)', font: '14px', fontWeight: 700 }}>{data.NoOfPlay && data.NoOfPlay} <span style={{ fontSize: '14px', fontWeight: 400 }}>{data.NoOfPlay && data.NoOfPlay === 1 ? 'play' : 'plays'}</span> </Typography>
                              </Box>
                              <Box sx={{ fontSize: '14px', fontWeight: 400, color: 'rgb(114, 140, 166)', marginTop: '4px' }}><TimeAgo timestamp={recordSong && data.createdAt} /></Box>
                            </Box>
                            <Box className='all-controls' sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", background: "#FEFEFE", padding: 1, marginTop: 2, borderRadius: 2 }}>
                              <Box sx={{ display: "flex", gap: 4, padding: '10px' }}>
                                {/* {
                          likeData &&
                          likeData.map((likeValue,likeInd) => (
                              likeValue._id === userTokenData.userId && 
                          ))
                        } */}
                                <Box>
                                  {
                                    data.isLiked === true ? <Box sx={{ cursor: 'pointer' }}>
                                      <FavoriteIcon style={{ fill: 'rgb(253 40 110)', fontSize: '25px' }} />
                                    </Box> : <Box>
                                      {
                                        like ? <Box sx={{ cursor: 'pointer' }}>
                                          <FavoriteIcon style={{ fill: 'rgb(253 40 110)', fontSize: '25px' }} />
                                        </Box> : <Box sx={{ cursor: 'pointer' }} onClick={handleLike}>
                                          <FavoriteBorderIcon style={{ color: "#2D2867", fontSize: '25px' }} />
                                        </Box>
                                      }
                                    </Box>
                                  }
                                  {

                                  }
                                </Box>
                                <Box sx={{ cursor: 'pointer' }}><FaGift color='#2D2867' size={25} onClick={handleGiftOpen} /></Box>
                                <Box sx={{ cursor: 'pointer' }}><FaCommentDots color='#2D2867' size={25} onClick={() => setModuleComment(false)} /></Box>
                                <Box onClick={() => OpenModal('Spread the word', 'share', `http://99.62.153.141:5555/record/${params.id}`)} sx={{ cursor: 'pointer' }}><IoIosShareAlt color='#2D2867' size={25} /></Box>

                              </Box>
                              <Box>  
      {data.CollaborationTypeID === "Duet" ? (  
        <Box>  
          <Button  
            variant="contained"  
            sx={{ backgroundColor: "#6A2DFB", textTransform: "none", fontWeight: "bold" }}  
            onClick={handleNavigation}  
          >  
            Join now  
          </Button>  
        </Box>  
      ) : (  
        <Box>  
          <Button  
            variant="contained"  
            sx={{ backgroundColor: "#6A2DFB" }}  
            onClick={handleNavigation}  
          >  
            Install app  
          </Button>  
        </Box>  
      )}  
    </Box>  
                            </Box>
                          </> : <InformationScreen>{
                            giftScreen ? <GiftListScreen value={giftData} func={handleLikeClose} /> : <LikeScreen func={handleLikeClose} value={likeList} />
                          }</InformationScreen>
                        }
                      </Box>
                    </Box>
                    : <Module count={handleGiftCount} data={image} user={recordSong} func={handleModule} />
                }

              </> : <ModuleComment count={handleCommentCount} user={recordSong} funcComment={handleComment} />
            }
          </>
        ))
      }
      <Modal
        open={autoPlay && autoPlay.Upload.FullscreenMode[0]?.isTrue}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', gap: 4 }}>
              <Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box>
                    {
                      recordSong && recordSong.map((val, ind) => (
                        <Box sx={{ display: 'flex' }}>
                          {
                            val.PostType !== 'Audio' ?
                              <Box sx={{ width: { xl: '500px', lg: '400px', md: '400px' }, height: { xl: '500px', lg: '400px', md: '400px' } }}>
                                <video ref={audioRef} src={val.MixedSongURL} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                              </Box> : <Box sx={{ backgroundImage: `url(${val.CoverImageURL})`, width: { xl: '500px', lg: '400px', md: '400px' }, height: { xl: '500px', lg: '400px', md: '400px' }, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '5px' }}>
                              </Box>
                          }
                        </Box>
                      ))
                    }
                  </Box>
                </Box>
              </Box>
              <Box>
                <Card sx={{ background: '#252134', width: { xl: '500px', lg: '400px', md: '400px' }, height: { xl: '500px', lg: '400px', md: '400px' }, overflowY: 'scroll' }}>
                  <Tabs TabIndicatorProps={{ style: { background: 'linear-gradient(to right , #FF0191, #1C0B4C)' } }} onChange={handleTab} value={tabValue} aria-label="basic tabs example">
                    <Tab sx={{ textTransform: "capitalize", width: '50%', color: 'white', fontSize: '18px', fontWeight: 700 }} label="Lyrics" {...a11yProps(0)} />
                    <Tab sx={{ textTransform: "capitalize", width: '50%', color: 'white', fontSize: '18px', fontWeight: 700 }} label="Comments" {...a11yProps(1)} />
                  </Tabs>

                  <CustomTabPanel value={tabValue} index={0}>
                    <Box>
                      {
                        recordSong && recordSong.map((val, ind) => (
                          <>
                            {
                              val.karaokes[0] && val.karaokes[0].KaraokeLyrics.map((data, index) => (
                                <Box sx={{ color: 'white' }}>{data.Sentence}</Box>
                              ))
                            }
                          </>
                        ))
                      }
                    </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabValue} index={1}>
                    <Box>
                      {
                        commentData && commentData.map((val, ind) => (
                          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', padding: '5px', gap: 1 }}>
                              {
                                val && val.users?.map((data, index) => (
                                  <Avatar sx={{ width: '45px', height: '45px', borderRadius: '50%', marginTop: '10px' }} src={data.ProfilePhoto} />
                                ))
                              }
                              <Box sx={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
                                {
                                  val.users && val.users.map((data, index) => (
                                    <Box sx={{ color: 'white' }}>{data.Email}</Box>
                                  ))
                                }
                                <Box sx={{ color: 'white' }}>{val.CommentText}</Box>
                              </Box>
                            </Box>
                            <Box>
                              <MoreVertIcon style={{ color: 'rgb(114, 140, 166)', marginTop: '12px' }} />
                            </Box>
                          </Box>
                        ))
                      }
                    </Box>
                  </CustomTabPanel>
                </Card>
              </Box>
            </Box>
            <Box>
              {
                recordSong && recordSong.map((val, ind) => (
                  console.log(val,"hdhdhdhdhh"),
                  
                  <Box>
                    <Card>
                      <Box sx={{ display: 'flex', margin: '10px', justifyContent: 'space-between', marginBottom: '0' }}>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <Box>{currentRunning}</Box>
                          <Box sx={{ marginTop: '3px' }}>
                            <div>
                              <input ref={playerRef} id="myinput" max={audioDuration} value={currentValue} style={{ width: '80%' }} onChange={handleInput} type='range' />
                            </div>
                          </Box>
                        </Box>
                        <Box>
                          {audioRef.current !== undefined ? currentTimeVaue : '00:00'}
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', padding: '10px', justifyContent: 'space-between' ,backgroundColor:"#110E39"}}>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                          <>
                            <Box>
                              <img style={{ width: '60px', height: '60px', borderRadius: '5px' }} src={val.CoverImageURL} />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                              <Box sx={{ width: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontWeight: 700,color:"white" }}>{val.karaokes[0]?.Name}</Box>
                              <Box sx={{ width: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontWeight: 400,color:"white" }}>{val.karaokes[0]?.Artist}</Box>
                            </Box>
                          </>
                          <Box sx={{ display: 'flex', gap: 2 }}>
                            <Tooltip placement='top' title='Shuffle'>
                              <Box sx={{ padding: '5px', paddingTop: '15px' ,color:"white",cursor:'pointer' }}>
                                <BsShuffle size={25} />
                              </Box>
                            </Tooltip>
                            <Tooltip placement='top' title='Previous'>
                              <Box onClick={() => { navigate(`/record/${prevoiusObject._id}`) }} sx={{ padding: '5px', paddingTop: '15px',color:"white",cursor:'pointer'  }}>
                                <MdSkipPrevious size={30} />
                              </Box>
                            </Tooltip>
                            <Tooltip placement='top' title='Skip backward 30 seconds'>
                              <Box onClick={() => { audioRef.current.currentTime = currentValue - 30 }} sx={{ padding: '5px', paddingTop: '15px',color:"white",cursor:'pointer'  }}>
                                <FaArrowRotateLeft size={25} />
                              </Box>
                            </Tooltip>
                            {
                              play ? <Tooltip placement='top' title='Play'>
                                <Box onClick={() => {
                                  setPlay(false)
                                  audioRef.current.play()
                                }} sx={{ border: '2px', borderImageSource: 'linear-gradient(to right, rgb(221,97,66) 30%, rgb(201,71,106) 30%)', padding: '5px', borderRadius: '50%', paddingTop: '15px',color:"white",cursor:'pointer'  }}>
                                  <FaPlay size={25} />
                                </Box>
                              </Tooltip> : <Tooltip placement='top' title='Pause'>
                                <Box onClick={() => {
                                  setPlay(true)
                                  audioRef.current.pause()
                                }} sx={{ border: '2px', borderImageSource: 'linear-gradient(to right, rgb(221,97,66) 30%, rgb(201,71,106) 30%)', padding: '5px', borderRadius: '50%', paddingTop: '15px',color:"white",cursor:'pointer'  }}>
                                  <IoMdPause size={25} />
                                </Box>
                              </Tooltip>
                            }

                            <Tooltip placement='top' title='Skip forward 30 seconds'>
                              <Box onClick={() => { audioRef.current.currentTime = currentValue + 30  }} sx={{ padding: '5px', paddingTop: '15px',color:"white",cursor:'pointer'  }}>
                                <FaArrowRotateRight size={25} />
                              </Box>
                            </Tooltip>
                            <Tooltip placement='top' title='Next'>
                              <Box onClick={() => {
                                navigate(`/record/${nextObject._id}`)
                              }} sx={{ marginTop:'10px',opacity : nextObject !== null && nextObject !== undefined ? 1 : 0.3,padding: '5px', paddingTop: '15px',color:"white",cursor:'pointer'  }}>
                                <MdSkipNext size={30} />
                              </Box>
                            </Tooltip>
                            {
                              repeat ? <Tooltip placement='top' title='Repeat : All'>
                                <Box onClick={() => {
                                  setRepeat(false)
                                  setAudioLoop(true)
                                }} sx={{ padding: '5px', paddingTop: '15px',color:"white",cursor:'pointer'  }}>
                                  <LuRepeat size={25} />
                                </Box>
                              </Tooltip> : loop ? <Tooltip placement='top' title='Repeat : Off'>
                                <Box onClick={() => {
                                  setRepeat(true)
                                  setLoop(false)
                                }} sx={{ padding: '5px', paddingTop: '15px',color:"white",cursor:'pointer' }}>
                                  <TbRepeatOff size={25} />
                                </Box>
                              </Tooltip> : <Tooltip placement='top' title='Repeat : One'>
                                <Box onClick={() => {
                                  setAudioLoop(false)
                                  setLoop(true)
                                }} sx={{ padding: '5px', paddingTop: '15px',color:"white" }}>
                                  <LuRepeat1 size={25} />
                                </Box>
                              </Tooltip>
                            }
                          </Box>
                        </Box>
                        <Box sx={{ display: 'flex', paddingTop: '15px' }}>
                          <Box >
                          <Box >
                                      {
                                        currentTimeVaue === currentRunning ? <div>
                                          <div style={{ color: 'black' }}>
                                            <PiSpeakerHigh size={20}  />
                                          </div>
                                          {/* <div  style={{position:'absolute',top:20,left:40}}>
                                <input type='range' max={MAX_SOUND}   min={0} onChange={handleVolume} style={{width:'70px'}}/>
                              </div>  */}
                                        </div> :
                                        <Box sx={{mr:10,cursor:'pointer'}}>
                                         <ThemeProvider theme={theme}><VolumeSlider  audioRef={audioRef} autoPlay={autoPlay}/></ThemeProvider> </Box>
                                      }
                                      {
                                        currentTimeVaue === currentRunning ? <div style={{ width: '24px', height: '18px' }}>
                                          {/* <BsGlobe color='black' size={20} /> */}
                                        </div> : <div className={autoPlay?.Upload.MediaPlayerPlay[0].isTrue ? '' : 'globe'} style={{ width: '24px', height: '18px' }} onClick={() => { setGlobe(true) }}>
                                          {/* <BsGlobe color='black' size={20} /> */}
                                        </div>
                                      }
                                    </Box>
                          </Box>
                          <Tooltip title='More' placement='top'>
                            <Box>
                              
                            </Box>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Card>
                  </Box>
                ))
              }
            </Box>
          </Box>

        </Box>

      </Modal>
      <Authmodel open={restrict} HandleClose={HandleClose} />
      <ModalComponent modalLink={modalLink} isOpen={isModalOpen} onClose={closeModal} content={modalContent} type={modalType} />
    </Box>
  )
}

export default Header

// const VolumeSlider = ({audioRef, autoPlay}) => {
//   const player = useSelector(state => state.player)
//   const dispatch = useDispatch()

//   const handleVolume = useCallback((e) => {
//     console.log(e.target.value, 'VoulumeContoller')
//     dispatch(setVolume(Number(e.target.value)))
//     const volume = Number(e.target.value) / MAX_SOUND
//     audioRef.current.volume = volume
//   }, [])

//   return (
//     <Box>
//     <div className={autoPlay?.Upload.MediaPlayerPlay[0].isTrue ? 'pauseSpeaker' : 'speaker'} style={{ width: '300px', display: 'flex' }}>
//       {
//         player.volume === 0 ? <div onClick={() => {
//           audioRef.current.volume = 0.6
//           dispatch(setVolume(60))
//         }} style={{ color: 'white' }}>
//           <PiSpeakerSimpleSlash size={20} />
//         </div> : <div onClick={() => {
//           audioRef.current.volume = 0
//           dispatch(setVolume(0))
//         }} style={{ color: 'white' }}>
//           <PiSpeakerHigh size={20} />
//         </div>
//       }
//       <div className='volumeTab'>
//         <input type='range' max={MAX_SOUND} value={player.volume} min={0} onChange={handleVolume} style={{ width: '70px' }} />
//       </div>
//     </div>
//   </Box>
//   )
// }