import { Box, Typography } from '@mui/material'
import React from 'react'
import Appcard from '../../../components/app-card'
import AppleIcon from '@mui/icons-material/Apple';
import { PlayArrow } from '@mui/icons-material';
import { BsGooglePlay } from 'react-icons/bs';
import { TbBrandAppgallery } from 'react-icons/tb';
const Header = () => {
  return (
    
    <Box sx={{textAlign:"center"}}>
       <Box sx={{display:"flex",flexDirection:"column",textAlign:"center",gap:1,padding:5}}>
       <Typography sx={{fontSize:"40px",fontWeight:'bold'}}>Spark:Karaoke Songs & Videos</Typography>
        <Typography sx={{fontSize:"25px"}}>Spark-Singing karaoke solo, duet, or with friends! 5M+ songs with lyrics and more!</Typography>
       </Box>
       <Box sx={{display:"flex",justifyContent:"center",cursor:"pointer",alignItems:"center",textAlign:"center",gap:{lg:7,md:5,xs:3},flexDirection:{xs:"column",lg:"row",md:"row"}}}>
        <Appcard 
         icon={<AppleIcon fontSize='large'/>}
         tittle1='Download on the'
         tittle2='App Store'
        />
        <Appcard 
         icon={<BsGooglePlay fontSize={30}/>}
         tittle1='Download on the'
         tittle2='Google Play'
        />
        <Appcard 
         icon={<TbBrandAppgallery fontSize={30}/>}
         tittle1='Download on the'
         tittle2='App Gallery'
        />
        
       </Box>
       <Typography sx={{margin:2}} >LET'S SPARK SINGING™</Typography>
    </Box>

  )
}

export default Header