import React, { useEffect, useState } from 'react';
import { Box, Modal, Typography, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Cancel, Start } from '@mui/icons-material';
import { BsEmojiAngryFill, BsEmojiAstonishedFill, BsEmojiHeartEyesFill, BsEmojiSurpriseFill, BsFillEmojiSmileFill } from "react-icons/bs";
import axios from 'axios';
import elpsss from "../assest/images/NewLogo.png";
import { url } from '../config/config';
import Meta from '../components/meta_data';

const Help = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [ishelps, setIshelps] = useState([]);
  const params = useParams();

  useEffect(() => {
    const detailsHelp = async () => {
      try {
        const config = { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
        const res = await axios.get(`${url}/api/help/get-help-heading`, config);
        setIshelps(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    detailsHelp();
  }, []);

  const handleParagraphClick = (id, name) => {
    navigate(`/categories/${id}/${name}`);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const helpTopics = [
    { id: 1, name: "how-to-use-sparksinger", text: 'How to use SparkSinger' },
    { id: 2, name: "sparkSinger-subscription", text: 'How to manage a SparkSinger subscription' },
    { id: 3, name: "perks-premium", text: 'Perks of being premium' },
    { id: 4, name: "profile-management-sparksinger", text: 'Profile management SparkSinger' },
    { id: 5, name: "safety-on-the-sparkSinger", text: 'Safety on the SparkSinger' },
    { id: 6, name: "technical-issues-sparksinger", text: 'Technical issues SparkSinger ' },
    { id: 7, name: "gifting-on-sparksinger", text: 'Gifting on SparkSinger' },
    { id: 8, name: "Song-to-the-Songbook", text: 'Upload a song to sparksingers ' },
  ];

  const filteredTopics = searchText
  ? helpTopics.filter(topic => topic.text.toLowerCase().includes(searchText.toLowerCase()))
  : helpTopics;

  return (
    <Box sx={{ backgroundColor: "white",minHeight:"100vh" }}>
      <Meta title="Help at Sparksinger| Sparksinger Social Singing & Karaoke App" description="SparkSinger is a social karaoke app where you can enjoy millions of songs, connect with friends worldwide, and even sing with top artists. Join the global singing community today! | SparkSinger Social" keywords="Karaoke, Singing, Social Singing" />
      <Box sx={{
        backgroundColor: "white",
        display: 'flex',
        justifyContent: "space-around",
        alignItems: "center",
        textAlign: "center",
        padding: 1,
        
      }}>
        <Box sx={{ width: 200 }}>
          <img src={elpsss} alt="Logo" style={{ width: "70%" }} />
        </Box>
        <Box sx={{ color: "black", cursor: "pointer", '&:hover': { color: '#55AEEA' } }} onClick={() => setModalOpen(true)}>
          Submit your feedback
        </Box>
      </Box>

      {/* Search Section */}
      <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        background: "linear-gradient(to right, #AD23C0, #5924E4)",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          width: { xs: "90%", sm: "70%", md: "60%" },
          marginBottom: 2,
          borderRadius: 20,
        }}
      >
        <input
          type="text"
          placeholder="Search..."
          value={searchText}
          onChange={handleSearch}
          style={{
            width: { xs: "50%", sm: "50%", md: "90%" },
            padding: "10px",
            borderRadius: "20px",
            border: "1px solid #ccc",
            fontSize: "16px",
          }}
        />
      </Box>

      <Box>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: { xs: "20px", sm: "22px", md: "25px" },
            marginBottom: 1,
            color: "white",
          }}
        >
          Alone it's Music,
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: { xs: "20px", sm: "22px", md: "25px" },
            color: "white",
          }}
        >
          Together it's Magic
        </Typography>
      </Box>
    </Box>

      {/* Help Topics */}
      <Box
  sx={{
    mt: 4,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
    backgroundColor: "white",
  }}
>
  {filteredTopics.length > 0 ? (
    filteredTopics.map((item) => (
      <Box
        key={item.id}
        sx={{
          mt: 10,
          width: 400,
          height: 100,
          border: '1px solid #6C3FEA',
          backgroundColor: 'white',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#7439ED',
          '&:hover': { backgroundColor: '#6C3FEA', color: 'white', cursor: 'pointer' },
        }}
        onClick={() => handleParagraphClick(item.id, item.name)}
      >
        <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>
          {item.text}
        </Typography>
      </Box>
    ))
  ) : (
    <Typography sx={{ color: 'red', fontWeight: 'bold', mt: 2 }}>
      No results found.
    </Typography>
  )}
</Box>

      {/* Feedback Modal */}
      <Modal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={{
          width: 400,
          bgcolor: 'white',
          p: 4,
          borderRadius: 2,
          boxShadow: 24,
          textAlign: 'center',
        }}>
          <Box sx={{ textAlign: 'right',cursor:"pointer" }}>
            <Cancel onClick={() => setModalOpen(false)} />
          </Box>
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Submit Your Feedback
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mb: 2 }}>
            <BsEmojiSurpriseFill style={{ fontSize: 40, color: '#FFD700' }} />
            <BsEmojiHeartEyesFill style={{ fontSize: 40, color: '#FFD700' }} />
            <BsFillEmojiSmileFill style={{ fontSize: 60, color: '#FFD700' }} />
            <BsEmojiAstonishedFill style={{ fontSize: 40, color: '#FFD700' }} />
            <BsEmojiAngryFill style={{ fontSize: 40, color: '#FFD700' }} />
          </Box>

          <Typography sx={{ mb: 3, color: '#555' }}>
            We value your thoughts! Please provide your valuable feedback.
          </Typography>

          <Box sx={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Start />}
              onClick={() => window.location.href = 'http://SparkSingerFeedback-1476499505.us-east-1.elb.amazonaws.com'}
              sx={{ mb: 2 }}
            >
              Start Using
            </Button>
            <Typography variant="body2" sx={{ color: '#777' }}>
              Go to the feedback screen
            </Typography>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Help;
