import { useCallback, useState } from 'react';
import { Box, Paper, Typography, IconButton, Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import { useSelector } from 'react-redux';
function DragDropFileUpload({ onFileUpload }) {
  const [dragOver, setDragOver] = useState(false);
   const value = useSelector((state) => state)
   console.log(value.Upload.SongMeta)
  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    setDragOver(true);  
  }, []);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    setDragOver(false);
  }, []);

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();
      setDragOver(false);
      if (event.dataTransfer.files && event.dataTransfer.files[0]) {
        onFileUpload(event.dataTransfer.files[0]);
      }
    },
    [onFileUpload]
  );

  const handleChange = useCallback(
    (event) => {
      if (event.target.files && event.target.files[0]) {
        onFileUpload(event.target.files[0]);
      }
    },
    [onFileUpload]
  );

  return (
    <label htmlFor="raised-button-file">
    <Paper
      variant="outlined"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{

        padding: 20,
        textAlign: 'center',
        cursor: 'pointer',
        background: dragOver ? '#eee' : '#fafafa',
        width:"230px",
        height:'235px',
        backgroundColor:'#EDF5FF'
      }}
    >
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="raised-button-file"
        multiple
        type="file"
        onChange={handleChange}
      />
    
        <Box display="flex" flexDirection="column" gap={2} alignItems="center" sx={{marginTop:'30px'}}>
          <IconButton color="primary" aria-label="upload picture" component="span">
            <LibraryMusicIcon style={{ fontSize: 60 }} />
          </IconButton>
          <Typography sx={{backgroundColor:"#564CF2",color:'white',padding:'10px',width:'150px',borderRadius:'5px',cursor:'pointer'}} type='file' variant="contained">Select Cover Art</Typography>
          {/* <Button sx={{backgroundColor:"#564CF2"}} variant="contained">Select Cover Art</Button> */}
          <Box display='flex' gap='5px'>
          <Typography color='#9598B4'>or</Typography>
          <Typography>drag and drop it here</Typography>
          </Box>
        </Box>
    </Paper>
       </label>
  );
}

export default DragDropFileUpload;