import React from 'react'
import ScrollableTabsButtonAuto from '../../../components/tabs'
import { Box } from '@mui/material'

import SongTab from '../../../data/song-tab-data'
const Songtabs = () => {

  return (
   
    <Box >
        <ScrollableTabsButtonAuto/>     
    </Box>
  )
}

export default Songtabs