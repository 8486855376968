import { Box, Typography } from '@mui/material'
import React from 'react'
import Meta from '../components/meta_data'

const Careers = () => {
  return (
  
    <Box sx={{ padding: 5, backgroundColor: '#F7F7F7', borderRadius: 2 }}>
      <Meta title="Copyright and Intellectual Property Violations | Sparksinger Social Singing & Karaoke App" description="SparkSinger is a social karaoke app where you can enjoy millions of songs, connect with friends worldwide, and even sing with top artists. Join the global singing community today! | SparkSinger Social" keywords="Karaoke, Singing, Social Singing" />
       <Typography variant="h4" gutterBottom sx={{ marginTop: 4 }}>
           COPYRIGHT AND INTELLECTUAL PROPERTY INFRINGEMENT POLICY
         </Typography>
         <Typography variant="body1" paragraph>
           <strong>(Updated  march 19, 2025)</strong>
         </Typography>
         <Typography variant="body1" paragraph>
           To protect the rights of copyright holders, it is SparkSinger’s policy to respond to written notices of alleged infringement that comply with the Digital Millennium Copyright Act ("DMCA").
         </Typography>
       
         <Typography variant="h6" gutterBottom fontWeight={"bold"}>
           RESPECT OF THIRD-PARTY RIGHTS
         </Typography>
         <Typography variant="body1" paragraph>
           SparkSinger respects the intellectual property of others and takes the protection of intellectual property very seriously, and we ask our users to do the same. Infringing activity will not be tolerated on or through the Service.
         </Typography>
       
         <Typography variant="h6" gutterBottom fontWeight={"bold"}>
           REPEAT INFRINGER POLICY
         </Typography>
         <Typography variant="body1" paragraph>
           SparkSinger’s intellectual property policy is to (a) remove material that SparkSinger believes in good faith, upon notice from an intellectual property owner or their agent, is infringing the intellectual property of a third party by being made available through the Service, and (b) remove any Content posted to the Service by “repeat infringers.” SparkSinger considers a “repeat infringer” to be any user that has uploaded Content to the Service and for whom SparkSinger has received more than two takedown notices compliant with the provisions of 17 U.S.C. § 512(c) with respect to such Content. SparkSinger has discretion to terminate the account of any user of the Service after receipt of a single notification of claimed infringement or upon SparkSinger’s own determination.
         </Typography>
       
         <Typography variant="h6" gutterBottom fontWeight={"bold"}>
           PROCEDURE FOR REPORTING CLAIMED INFRINGEMENT
         </Typography>
         <Typography variant="body1" paragraph>
           If You believe that any Content made available on or through the Service has been used or exploited in a manner that infringes an intellectual property right You own or control, promptly send a “Notification of Claimed Infringement” containing the following information to the Designated Agent identified below. Your communication must include substantially the following:
         </Typography>
         <ul>
           <li>A physical or electronic signature of a person authorized to act on behalf of the owner of the work(s) that has/have been allegedly infringed;</li>
           <li>Identification of the works or material claimed to have been infringed, or, if multiple works at a single online site are covered by a single notification, a representative list of such works at that site;</li>
           <li>Identification of the specific material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient for SparkSinger to locate the material;</li>
           <li>Information reasonably sufficient to permit SparkSinger to contact You, such as an address, telephone number, and, if available, an electronic mail address at which the complaining party may be contacted;</li>
           <li>A statement that You have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and</li>
           <li>A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
         </ul>
         <Typography variant="body1" paragraph>
           You should consult with Your lawyer and/or see 17 U.S.C. § 512 of the DMCA to confirm Your obligations to provide a valid notice of claimed infringement.
         </Typography>
         <Typography variant="body1" paragraph>
           A copy of Your Notice of Claimed Infringement will be sent to the person who uploaded the material addressed in the notice, who may submit a counter notification, as described below, that could result in restoring material removed in response to Your notice.
         </Typography>
         <Typography variant="body1" paragraph>
           For a Notice of Claimed Infringement involving intellectual property claims other than copyright, a statement made under penalty of perjury is preferred but not required.
         </Typography>
       
         <Typography variant="h6" gutterBottom fontWeight={"bold"}>
           COPYRIGHT CONTACT INFORMATION
         </Typography>
         <Typography variant="body1" paragraph>
           Send written notices for claimed infringement by email or by postal mail to:
         </Typography>
         <Typography variant="body1" paragraph>
       SparkSinger, Inc.<br />
       Attention: Copyright Agent<br />
       10601 Clarence Dr. Suite 250<br />
       Frisco, TX, USA 75033<br />
       <a href="mailto:support@sparksinger.com">support@sparksinger.com</a>
     </Typography>
       
         <Typography variant="h6" gutterBottom fontWeight={"bold"}>
           COUNTER-NOTIFICATION (COPYRIGHTS ONLY)
         </Typography>
         <Typography variant="body1" paragraph>
           If You receive a notification from SparkSinger that material made available by You on or through the Service has been the subject of a Notification of Claimed Infringement, You will have the right to provide SparkSinger with what is called a “Counter Notification.” To be effective, a Counter Notification must be in writing, provided to SparkSinger’s Copyright Agent to the same email or postal address identified above for reporting copyright infringement. Your Counter Notification should include substantially the following information:
         </Typography>
         <ul>
           <li>Your physical or electronic signature;</li>
           <li>Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled;</li>
           <li>A statement, under penalty of perjury, that You have a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled;</li>
           <li>Your name, physical address, and telephone number; and</li>
           <li>A statement that You consent to the jurisdiction of a United States District Court for the judicial district in which Your address is located or, if your address is outside of the United States, for any judicial district in which SparkSinger may be found, and that You will accept service of process from the person who provided a notice in compliance with SparkSinger’s Procedure for Reporting Claimed Infringement as described above.</li>
         </ul>
         <Typography variant="body1" paragraph>
           If You are submitting a Counter Notification, You should consult a lawyer or see 17 U.S.C. § 512 of the DMCA to confirm Your obligations to provide a valid counter notification under the DMCA.
         </Typography>
         <Typography variant="body1" paragraph>
           If You submit a Counter Notification, a copy of the counter notification, including Your name and contact information, will be sent to the copyright owner or person who provided the Notification of Claimed Infringement.
         </Typography>
         <Typography variant="body1" paragraph>
           SparkSinger reserves the right to seek damages from any party that submits a notification of claimed infringement or counter notification in violation of the law.
         </Typography>
      
    </Box>




  )
}

export default Careers