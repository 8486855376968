import { Email, ExpandMore, Facebook, Twitter, WhatsApp } from '@mui/icons-material';
import { Avatar, Box, Typography,Button,TextField, Card, Menu, MenuItem, Accordion, AccordionSummary, AccordionDetails, IconButton} from '@mui/material'
import React, { useEffect, useState } from 'react'
import {FaShare} from "react-icons/fa";
import {FaCreditCard} from 'react-icons/fa';
import { FaGift } from 'react-icons/fa6';
import AccordionUsage from '../components/accordian-usage';
import { useNavigate } from 'react-router-dom';
import image from "../assest/images/1.png"
import axios from 'axios';
import { url } from '../config/config';
import {RxDoubleArrowDown} from "react-icons/rx";
import Spark from './spark';
import Topmenu from './topmenu';
import { jwtDecode } from 'jwt-decode';
import Appcard from '../components/app-card';
import { ImAppleinc } from 'react-icons/im';
import { BsGooglePlay } from 'react-icons/bs';
import Meta from '../components/meta_data';

const Friend = () => {
   const userTokenData = localStorage.getItem('token') !== null ? jwtDecode(localStorage.getItem('token')) : '' 
     
       const LoGinUserID = userTokenData.userId
       
      //  console.log(LoGinUserID,"data.....");
    const [link, setLink] = useState('');
    const [expanded, setExpanded] = useState(false);
    const [infaq,setinfaq]=useState();
    const [visible, setVisible] = useState(true);
    // const [showSpark, setshowSpark] = useState(true);
    const [showSpark, setShowSpark] = useState(false);
    const navigate=useNavigate()
    const [faqs, setFaqs] = useState([]);
    

    // Function to handle link copy
    const handleCopy = () => {
      navigator.clipboard.writeText('https://www.sparksinger.com/pricing') 
        .then(() => {
           
        })
        .catch((err) => {
          console.error('Failed to copy: ', err);
        });
    };
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
      };
      const handleBack = () => {
        navigate(`/gifts-diamonds`)
      }
      useEffect(() => {
        const detailsfaq = async () => {
          try {
            const config = {
              headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            }
            const res = await axios.get(`${url}/api/FAQ/getFAQ`,config)
            console.log(res,"FAQQQQQQQQQQQQQQQQQ")
            setFaqs(res.data.data)
            
          } catch (error) {
            console.log(error)
          }
        }
        detailsfaq()
      }, [])
      useEffect(() => {
        setShowSpark(true); 
        const timeout = setTimeout(() => {
            setShowSpark(false);
        }, 5000);
        return () => clearTimeout(timeout);
    }, []);

  return (
    <Box sx={{backgroundColor:'white' ,fontFamily: "Helvetica Neue", }}>
      <Meta title="SparkSinger | Refer a Friend to VIP | " description="SparkSinger is the #1 karaoke app. Sing 14M+ songs with lyrics! Record with pro audio FX to sound your best! Sing karaoke solo, or duet with friends and popular artists" keywords="Karoke, Singing, SparkSinger" />
           <Box
      sx={{
        backgroundColor: '#9036FF',
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'center', 
        alignItems: 'center', 
        textAlign: 'center', 
        padding: 1,
        gap:4
      }}
    >
      <Typography sx={{display:'flex' ,fontWeight: 'bold', color: '#fff', marginBottom: 2,fontSize:20 }}>
        Download SparkSinger App
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          justifyContent: 'center',
        }}
      >
        <Appcard icon={<ImAppleinc fontSize="large" />} tittle1="Download on the" tittle2="App Store" />
        <Appcard icon={<BsGooglePlay fontSize="large" />} tittle1="Download on the" tittle2="Google Play" />
      </Box>
      
    </Box>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        padding: 1,
        background: "linear-gradient(to right,#FF3FFF, #1B1BDB)",

      }}
    >
      <Typography
        sx={{
          fontSize: "50px",
          color: "white",
          fontFamily: "Arial, sans-serif",
          fontWeight: "bold",
          fontStyle: "italic",
          mt:3,
          
        }}
      >
        Refer A Friend To Premium
      </Typography>
      <Typography sx={{ fontSize: 25, color: "white " }}>
        You’ll both get a reward when they subscribe through your link!
      </Typography>
      <Box
  sx={{
    width: "100%", 
    height: "60vh", 
    backgroundImage: `url(${image})`, 
    backgroundSize: "contain",
    backgroundPosition: "center", 
    backgroundRepeat: "no-repeat", 
   
  }}
>
  {/* Your content goes here */}
</Box>

    </Box>

    {/* <Box sx={{width:"50%",alignItems:"center", position:"absolute", top:"50px", left:"50%"}}><img style={{width:"30%"}} src={image}/></Box>   */}
    <Box>
    <Typography
  sx={{
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: 'center',
    fontSize: 25,
    padding: 8,
    flexDirection: 'row',  
    gap: 1, 
    flexWrap: 'wrap',
  }}
>
  Share the excitement of Premium and earn a reward of 350 Diamonds each time
  <br />
  a friend subscribes using your personal link. Your friends will also
  <br />
  receive 350 Diamonds! Use your Diamonds to send unique gifts to your
  <br />
  favorite performers! Find out more about gifting.
  
  
</Typography>
    </Box>
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <IconButton
    onClick={handleBack}
    sx={{
      backgroundColor: '#FF3FFF', // Red background color
      color: 'white', // White icon color
      borderRadius: '50%', // Makes the background a circle
      padding: 2, // Padding inside the button
      '&:hover': {
        backgroundColor: '#1B1BDB', // Darker red on hover
      },
    }}
  >
    <RxDoubleArrowDown fontSize="large" />
  </IconButton>
</Box>
    
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center', 
        alignItems: 'center', 
        flexWrap: 'wrap', 
        gap: 5, 
        padding: 1,
        mt:6
      }}
    >
      
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Avatar sx={{ width: 150, height: 150, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <FaShare size={60} /> 
        </Avatar>
        <Typography sx={{fontWeight:"bold",fontSize:20}}>Share Your Link</Typography>
        <Typography>Share it with all your friends!</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Avatar sx={{ width: 150, height: 150, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <FaCreditCard size={60}></FaCreditCard>
        </Avatar>
        <Typography sx={{fontWeight:"bold",fontSize:20}}>Friend Subscribes</Typography>
        <Typography>Be sure they click on your link!</Typography>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Avatar sx={{ width: 150, height: 150, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <FaGift  size={60}></FaGift>
        </Avatar>
        <Typography sx={{fontWeight:"bold",fontSize:20}}>Both of you get rewarded</Typography>
        <Typography>350 Diamonds!</Typography>
      </Box>
    </Box>
    <Card sx={{ width: "100%", height: "50%",mt:6,backgroundColor:"#F7F7F7" }}>
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column', 
      justifyContent: 'center', 
      alignItems: 'center',
      height: '100%', 
    }}
  >
    <Typography sx={{ marginBottom: 2,fontWeight:"bold",fontSize:20 }}>Share Your Personal Link:</Typography>
    <TextField
      variant="outlined"
      placeholder="https://www.sparksinger.com/pricing"
      value={link}
      onChange={(e) => setLink(e.target.value)}
      sx={{ marginBottom: 2, width: '400px' }} 
    />
    <Button variant="contained" color="primary" onClick={handleCopy} sx={{ marginBottom: 2 }}>
      Copy Link
    </Button>
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 2 }}>
    <Avatar sx={{backgroundColor:"#316FF6"}}><Facebook /></Avatar>
    <Typography>Facebook</Typography>
  </Box>
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 2 }}>
    <Avatar sx={{backgroundColor:"	#1DA1F2"}}><Twitter /></Avatar>
    <Typography>Twitter</Typography>
  </Box>
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 2 }}>
    <Avatar sx={{backgroundColor:"#25D366"}}><WhatsApp /></Avatar>
    <Typography>WhatsApp</Typography>
  </Box>
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Avatar><Email /></Avatar>
    <Typography>Email</Typography>
  </Box>
</Box>
  </Box>
</Card>
{/* <Accordion></Accordion> */}
<Box sx={{ display: "flex", flexDirection: 'column', width: "60%", padding: 6, gap: 3, margin: '0 auto' }}>
      <Typography sx={{ fontSize: 30, fontWeight: "bold" }}>FAQ</Typography>
      {faqs&&faqs.map((faq, index) => (
        <Accordion  key={index} sx={{ backgroundColor: "#F7F7F7" }} expanded={expanded === `panel${index + 1}`} onChange={handleChange(`panel${index + 1}`)}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls={`panel${index + 1}-content`}
            id={`panel${index + 1}-header`}
          >
            <Typography sx={{ fontSize: '1.2rem', color: "#7D3DE7", fontWeight: "bold", fontSize: 20 }}>
              {faq.Question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {faq.Answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
   
  </Box>
  )
}

export default Friend