import { Box, Typography } from '@mui/material'
import React from 'react'
import DragDropFileUpload from '../../../components/drag-drop-file'
import { useDispatch } from 'react-redux'
import { AddSticker } from '../../../features/addlyric/addSticker'
import { useNavigate } from 'react-router-dom'
const Addimage = () => {
  const dispatch = useDispatch()
  const navigate= useNavigate()
  const handleFileUpload = (file) => {
    if(file){
      const url = URL.createObjectURL(file);
      dispatch(AddSticker({ url, name: file.name, size: file.size }));
      navigate('/uploadsticker/cropimage')
    }
  };
  return (
  <Box sx={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center",gap:3,height:"80vh"}}>
    <DragDropFileUpload  onFileUpload={handleFileUpload}/>
    <Box>
            <Box><Typography fontSize={12} color='#9598B4'>By uploading this song, you confirm that you are complying with all legal guidelines set forth by our Community Guidelines and Terms of Service.</Typography></Box>
            <Box><Typography fontSize={12} color='#9598B4'>If you have any questions, please refer to our FAQ or contact Smule for more assistance.</Typography></Box>
        </Box>
  </Box>
  )
}

export default Addimage