import React from 'react'
import { Avatar, Badge, Box, Button, CircularProgress, colors, Container, Divider, Modal, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import girl from '../../assest/images/girl.webp'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import axios from 'axios';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { url } from '../../config/config';
import TimeAgo from '../recordings/timeStmaps';
import { Cancel, Handshake } from '@mui/icons-material';
import image3 from '../../assest/images/NewLogo.png'
import Appcard from '../../components/app-card';
import { ImAppleinc } from 'react-icons/im';
import songesss from '../../assest/images/premiumIcon.png'
import { FadeLoader } from 'react-spinners';
import  men from "../../assest/images/male.webp"
import music from '../../assest/images/cover music.png'
import { BiLogoPlayStore } from 'react-icons/bi';

const Seeall = () => {
    const [songcollaboration, setSongCollaboration] = useState([])
    const [pagination, setPagination] = useState(0)
    const [groups, setgroups] = useState([])
    const userTokanValue = localStorage.getItem('token');
    const navigate = useNavigate()
    const [songs, setSongs] = useState([])
    const [loading, setloading] = useState(true)
    const [user, setUser] = useState([])
     const [open, setOpen] = useState(false);
    const params = useParams();
    console.log(params.value, 'paramsValuesss')
    const { state } = useLocation()
    console.log(state, params, "data..........")
    const handleOpen = () => {
        setOpen(true); // Open modal
      };
    
      const handleClose = () => {
        setOpen(false); // Close modal
      };
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            console.log(scrollTop + windowHeight)
            console.log(documentHeight)
            if (Math.round(scrollTop + windowHeight) >= documentHeight - 1) {
                setPagination(prevPagination => prevPagination + 1)
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const searchResult = async () => {
            console.log(`${url}/api/GlobalFilter/getGlobalFilter/${params.textValue}?FilterIndex=${Number(params.value)}&page=${pagination}`);

            try {
                const config = {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                }
                const res = userTokanValue !== null ? await axios.get(`${url}/api/GlobalFilter/getGlobalFilter/${params.textValue}?FilterIndex=${Number(params.value)}&page=${pagination}`, config) : await axios.get(`${url}/api/GlobalFilter/getGlobalOtherFilter/${params.textValue}?FilterIndex=${Number(params.value)}&page=${pagination}`)
                console.log(res.data.data, 'SearchdataValuesssss')
                
                if (pagination === 0) {
                    setSongCollaboration(res.data.data)
                    setUser(res.data.data)
                    setSongs(res.data.data)
                    setgroups(res.data.data)
                }
                else {
                    setSongCollaboration((prevValues) => [...prevValues, ...res.data.data])

                }

            } catch (error) {
                console.log(error, 'errorsearchResult')
            }
            finally {

                setloading(false);
            }
        }
        searchResult()
    }, [params.value, pagination])
    // songcollaboration && songcollaboration.map((val) => (
    //     console.log(val.users.length)
    // ))
    // console.log(user)
    const handleNavigation = (val) => {
        navigate(`/searchPagination/${val}/${params.value}`,);
    };

    const handleBack = () => {
        navigate(`/search/${params.textValue}`)
    }
    // console.log(songcollaboration, 'songCollabDetailssssssssssss')
    return (
        <Container sx={{ backgroundColor: "#FFFFFF",minHeight: '100vh'  }}>


            <Box pt={4}>

                <Box sx={{ display: 'flex', gap: 2, }}>
                    <Box onClick={handleBack} sx={{
                        fontSize: 25, borderBottom: '1px solid black', cursor: 'pointer', "&:hover ": {
                            color: 'lightgreen', borderBottom: '1px soild lightgreen'
                        }
                    }}>All results </Box>
                    <Box><Typography fontSize={25} color={"#673EF7"} fontWeight={"bold"}>{` Showing results for "${params.textValue}"`}</Typography></Box>
                </Box>
               <Box mt={3}><Divider/></Box> 
                <Box sx={{ display: 'flex', justifyContent: 'center', }}>
                    {
                        loading ? <Box sx={{ marginTop: 20 }}>
                             <FadeLoader color="LightGray"height={10} width={4} margin={-6} sx={{ color: "#6C2EFF" }} />


                        </Box> : null
                    }
                </Box>
                {
                    params.value === '1' ? <Box sx={{ overflowX: 'auto'}}>
                        {
                            songcollaboration && songcollaboration.map((data, index) => (
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 2, cursor: 'pointer', }}>

                                    <><Box key={index} sx={{ display: "flex", gap: 1 }}>
                                        <Button onClick={() => {
                                            navigate(`/record/${data._id}`)
                                        }}><Avatar
                                                alt="Remy Sharp"
                                                src={data.CoverImageURL || music}
                                                sx={{ width: 60, height: 60 }}
                                                variant='square' /></Button>
                                        <Box sx={{ marginTop: '5px' }}>
                                            <Box sx={{ pt: 0 }}><Typography fontSize={'1em'} fontWeight={700}>{data.karaokes[0].Name}</Typography></Box>
                                            <Box><Typography fontSize={9} style={{ width: 100, color: '#aaa', fontSize: '1em', fontWeight: 400 ,':hover': {color: '#1ED760' }}}>{data.users[0].Name}</Typography></Box>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: .5 }}>
                                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                                    <Box sx={{ fontSize: 9 }}><PlayArrowIcon sx={{ fontSize: '13px', color: '#cfcfcf' }} /></Box>
                                                    <Box sx={{ fontSize: '13px', color: '#cfcfcf' }}>{data.NoOfPlay}</Box>
                                                </Box>
                                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                                    <Box sx={{ fontSize: 9 }}><FavoriteIcon sx={{ fontSize: '13px', color: '#cfcfcf' }} /></Box>
                                                    <Box sx={{ fontSize: '13px', color: '#cfcfcf' }}>{data.NoOfLikes}</Box>
                                                </Box>
                                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                                    <Box sx={{ fontSize: 9 }}><ChatBubbleIcon sx={{ fontSize: '13px', color: '#cfcfcf' }} /></Box>
                                                    <Box sx={{ fontSize: '13px', color: '#cfcfcf' }}>{data.NoOfComments}</Box>
                                                </Box>

                                            </Box>
                                        </Box>
                                    </Box><Box>
                                            {
                                                data.users.length > 1 ? <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>  <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                                                    <Box><Typography sx={{ fontSize: '11px', color: '#636363' }}>Recorded by</Typography></Box>
                                                    <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                                                        <Box><Avatar alt="Remy Sharp" src={girl} /></Box>
                                                        <Box><Typography fontSize={13} fontWeight={600}>_WaNhi_</Typography></Box>
                                                    </Box>

                                                </Box> <Box><Typography>+</Typography></Box> <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                                                        <Box><Typography sx={{ fontSize: '11px', color: '#636363' }}>Recorded by</Typography></Box>
                                                        <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                                                            <Box><Avatar alt="Remy Sharp" src={girl} /></Box>
                                                            <Box><Typography fontSize={13} fontWeight={600}>_WaNhi_</Typography></Box>
                                                        </Box>

                                                    </Box></Box> : <Box onClick={() => { navigate(`/otherProfile/${data.users[0]?._id}`) }} sx={{ display: 'flex', gap: 1 }}>
                                                    <Box sx={{ fontSize: '11px', color: '#636363', marginTop: '10px' }}>Recorded by</Box>
                                                    <Box>
                                                        <Avatar src={data.users[0].ProfilePhoto || men} />
                                                    </Box>
                                                    <Box sx={{ color: '#636363', fontSize: '11px', marginTop: '10px' }}>
                                                        {data.users[0].Name !== '' ? data.users[0].Name : data.users[0].Email}
                                                    </Box>
                                                </Box>
                                            }
                                        </Box><Box><Typography fontSize={13}>Recorded  <TimeAgo timestamp={data.createdAt} /></Typography></Box></>
                                </Box>

                            ))
                        }
                    </Box> : params.value === '0' ? <Box pt={2}>
                        <Box sx={{ display: "flex", flexDirection: "column", padding: '15px', gap: 2 , overflowX: 'auto'}}>
                            {
                                songs && songs.map((val, ind) => (

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                                        <Box sx={{ display: 'flex', gap: 2,cursor:"pointer" }}onClick={() => { navigate(`/allsong/${val._id}`) }} >
                                            <Box>
                                                <Avatar
                                                    alt="Remy Sharp"
                                                    src={val.CoverImageURL || music}
                                                    sx={{ width: 60, height: 60 }}
                                                    variant='square' />
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: .5, width: '400px' }}>
                                                <Box sx={{ color: '#000', fontSize: '12px', fontWeight: 700 }}>{val.Name}</Box>
                                                {/* <Box sx={{ color: '#aaa', fontSize: '12px' }}>Hi-Fi</Box> */}
                                                <Box sx={{ color: '#aaa', fontSize: '12px',':hover': {color: '#1ED760' } }}>{val.users[0]?.UserName}</Box>
                                                <Box sx={{ display: 'flex',gap:1 }}>
                                                <Avatar
                                                    alt="Remy Sharp"
                                                    src={val.users[0]?. ProfilePhoto || men}
                                                    sx={{ width: 20, height: 20 }}
                                                    variant="circular" />
                                                    {/* <Box><AccountCircleIcon style={{ color: '#aaa' }} /></Box> */}
                                                    <Box sx={{ color: '#aaa', fontSize: '12px',':hover': {color: '#1ED760' } }}>{val.users[0]?.Name}</Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{display:"flex",textAlign:"center",gap:3 ,marginTop: '10px', cursor: 'pointer',height:20 }}>
                                        <Box 
  onClick={() => { navigate(`/allsong/${val._id}`) }} 
  sx={{ 
    background: 'none', 
    border: '1px solid black', 
    padding: '5px 10px', 
    height:20 ,
    borderRadius: '5px', 
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    ':hover': {
      background: '#1ED760',
      color: 'black'
    }
  }}
>
  Lyrics
</Box>
<Box 
  
  sx={{ 
    background: 'none', 
    border: '1px solid black', 
    padding: '5px 10px', 
    height:20 , 
    borderRadius: '5px', 
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    ':hover': {
      background: '#1ED760',
      color: 'black'
    }
  }}onClick={handleOpen}
>
  Sing
</Box>
                                        </Box>
                                    </Box>
                                ))
                            }
                        </Box>
                    </Box> : params.value === '2' ? <Box pt={2}>

                        <Box  sx={{ gap: 6 }}>
                            {
                                user.length && user.map((data, index) => {
                                    // console.log(data._id, 'UserIds');
                                    // console.log(data, 'User Name');
                                    const handleNavigateOtherProfile = () => {
                                        console.log(`/otherProfile/${data._id}`,'`/otherProfile/${data._id}`');
                                        navigate(`/otherProfile/${data._id}`)
                                    }
                                    return (
                                        <Box onClick={() => handleNavigateOtherProfile()} key={index} sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "15px",
                                            gap: 2,
                                            cursor: "pointer",
                                            borderBottom: "1px solid black",
                                        }}>
                                           <Box>
                                                
                                                    

                                                <Badge                               
                                                overlap="circular"
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                badgeContent={
                                                    data.isPremium? (  // Show only if isPremium is true
                                                        <Avatar
                                                            sx={{ bgcolor: 'red', width: 30, height: 30, fontSize: 10, border: '2px solid white' }}
                                                            alt="Premium User"
                                                            src={songesss} // Ensure this is the correct image
                                                        />
                                                    ) : null  // Hide if isPremium is false
                                                }
                                            >
                                                    <Avatar
                                                        sx={{ height: '100px', width: '100px' }}
                                                        src={data.ProfilePhoto  || men}
                                                    />
                                                </Badge>
                                           
                                        </Box>

                                            <Box sx={{ textAlign: "left", color: "black" }}><Typography fontSize={13} fontWeight={700}>{data.Name}</Typography></Box>

                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    </Box> : params.value === '4' ?
                        <Box sx={{ display: "flex", gap: 4, pt: 2 }} >
                           <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 ,}}>
    {songcollaboration &&
        songcollaboration.map((group, index) => (
            <Box
                key={index}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'left',
                    width: 'calc(20% - 16px)', // Adjust width for 5 items per row
                    minWidth: '170px', // To prevent too small a box
                    overflow: 'hidden', // Ensure no overflow
                    textOverflow: 'ellipsis', // To truncate overflowing text
                }}
            >
                <Box>
                    <Avatar
                        alt={group.ChannelName}
                        src={group.CoverImage}
                        sx={{ width: 170, height: 170 }}
                        variant='square'
                        onClick={() =>
                            navigate(`/othergroupinfo/${group._id}`, {
                                state: group,
                            })
                        }
                    />
                </Box>
                <Box>
                    <Typography fontSize={13} fontWeight={700} noWrap>{group.ChannelName}</Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                    {/* <Box>{group.MemberUserIDsCount} members</Box> */}
                </Box>
            </Box>
        ))}
</Box>
                        </Box> : params.value === '3' &&
                        songcollaboration && songcollaboration.map((val, ind) => (
                            ind < 6 ?
                                <Box sx={{ display: 'flex', justifyContent: "space-evenly", padding: '10px', }}>
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        <Box>
                                            <Avatar
                                                alt="Remy Sharp"
                                                src={val.karaokes[0].CoverImageURL || music}
                                                sx={{ width: 60, height: 60 }}
                                                variant='square' />
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: .5, width: '400px' }}>
                                            <Box sx={{ color: '#000', fontSize: '12px', fontWeight: 700 }}>{val.karaokes[0].Name}</Box>
                                            {/* <Box sx={{color:'#aaa',fontSize:'12px'}}>Hi-Fi</Box> */}
                                            <Box sx={{ display: 'flex' }}>
                                                {/* <Box><AccountCircleIcon style={{color:'#aaa'}}/></Box> */}
                                                <Box sx={{ color: '#aaa', fontSize: '12px' }}>{val.karaokes[0].Name}</Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ marginTop: '10px', cursor: 'pointer' }}>
                                                                             <Box
                                        
                                        sx={{
                                            background: '#FFC986',
                                            border: '1px solid black',
                                            fontWeight:"bold",
                                            padding: '5px 10px',
                                            height: 20,
                                            borderRadius: '5px',
                                            cursor: 'pointer',
                                            transition: 'all 0.3s ease',
                                            ':hover': {
                                                background: '#1ED760',
                                                color: 'black'
                                            }
                                        }} onClick={handleOpen}
                                        >
                                        {loading ? <CircularProgress size={24} /> : "Join Now"}
                                        </Box>
                                      
                                    </Box>
                                    <Box sx={{mt:2,ml:1}}><Typography  fontSize={13}>days left <TimeAgo timestamp={val.createdAt}  /> </Typography></Box>
                                </Box> : ""
                        ))
                }
            </Box>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
    <Box
      sx={{
        width: '600px',
        backgroundColor: '#f9f9f9',
        padding: 4,
        margin: 'auto',
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -30%)',
        borderRadius: 2,
        boxShadow: 24,
      }}
    >
      <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
        <Cancel onClick={handleClose} style={{ cursor: 'pointer' }} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
        <Box>
          <img src={image3} alt="App Preview" style={{ width: 180, height: 'auto' }} />
        </Box>
        <Box>
          <Typography id="modal-title" variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
            Get SparkSinger
          </Typography>
          <Typography id="modal-description" variant="body1" sx={{ mb: 3 }}>
          Sing and be part of the SparkSinger community!
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Appcard icon={<ImAppleinc fontSize="large" />} tittle1="Download on the" tittle2="App Store" />
            <Appcard icon={<BiLogoPlayStore  size={30}  />} tittle1="Download on the" tittle2="Google Play" />
          </Box>
        </Box>
      </Box>
    </Box>
  </Modal>

        </Container>
    )
}

export default Seeall