import React, { useCallback, useRef } from 'react'
import ClearIcon from '@mui/icons-material/Clear';
import { Avatar, Badge, Box, Button, Card, CircularProgress, IconButton, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link, useNavigate } from 'react-router-dom';
import AccordionUsage from './accordian-usage';
import Divider from '@mui/material/Divider';
import BasicMenu from './basic-menu';
import logo from '../assest/images/logo.png'
import './Styles/left_Drawer.css'
import Exploredata from '../data/explore-data';
import { exploreScrool } from '../features/addlyric/addLyric';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import { url } from '../config/config';
import CancelIcon from '@mui/icons-material/Cancel';
import img from '../assest/images/peacock.jpg';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { CiClock2 } from "react-icons/ci";
import ProfileTimeAgo from './profileTimestamps';
import songesss from '../assest/images/premiumIcon.png'
import { BiBell } from 'react-icons/bi';
import { jwtDecode } from 'jwt-decode';
import { GroupOutlined, Redeem } from '@mui/icons-material';
import image3 from '../assest/images/cover music.png'
import { FadeLoader } from 'react-spinners';
import image4 from "../assest/images/groupimg.webp"
import Meta from './meta_data';
const userTokenData = localStorage.getItem('token') !== null ? jwtDecode(localStorage.getItem('token')) : ''
const LoGinUserID = userTokenData.userId
// console.log(userTokenData,'jiodhjdhb');

const NotificationDrawer
    = ({ Anchor, value,onNotificationClose }) => {
        const dispatch = useDispatch()
        const userId = '66582a159d847957e91b6923'

        const ClearNotify = async () => {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                }
                const data = {

                }
                const res = await axios.patch(`${url}/api/Notification/NotificationViewedByUserID`, data, config);
                return res
            } catch (error) {
                return error
            }
        }

        const [data, setData] = useState([])
        console.log(data[0]?.SenderID, "jsjdsjsjjs");
        
        const [isPremiumActive, setIsPremiumActive] = useState(false)
        const [payment,setPayment]=useState();
        const [pagination, setPagination] = useState(0);
        const [loading, setLoading] = useState(false);
        const [hasMore, setHasMore] = useState(true);
        const drawerRef = useRef(null);
        const menuRef = useRef(null); // Ref for dropdown menu
        // useEffect(() => {
        //   const handleScroll = () => {
        //     const scrollTop = window.scrollY;
        //     const windowHeight = window.innerHeight;
        //     const documentHeight = document.documentElement.scrollHeight;
      
        //     console.log(scrollTop + windowHeight, documentHeight, "scrolling...");
      
        //     if (Math.round(scrollTop + windowHeight) >= documentHeight - 1 && !loading) {
        //       console.log("Load more notifications...");
        //       setPagination((prev) => prev + 1);
        //     }
        //   };
      
        //   window.addEventListener("scroll", handleScroll);
        //   return () => {
        //     window.removeEventListener("scroll", handleScroll);
        //   };
        // }, [loading]);
        const handleMenuScroll = () => {
          if (menuRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = menuRef.current;
            if (scrollTop + clientHeight >= scrollHeight -10 && !loading) {
              setPagination((prev) => prev + 1);
            }
          }
        };
        useEffect(() => {
          const menuElement = menuRef.current;
          if (menuElement) {
            menuElement.addEventListener("scroll", handleMenuScroll);
            return () => menuElement.removeEventListener("scroll", handleMenuScroll);
          }
        }, [handleMenuScroll]);
        // useEffect(() => {
        //   const handleScroll = () => {
        //     const scrollTop = window.scrollY;
        //     const windowHeight = window.innerHeight;
        //     const documentHeight = document.documentElement.scrollHeight;
        
        //     console.log(scrollTop + windowHeight, documentHeight, "scrolling...");
        
        //     if (scrollTop + windowHeight >= documentHeight - 10) {  // Small offset for better detection
        //       console.log("EverOne Increment");
        //       setPagination((prevPagination) => prevPagination + 1);
        //     }
        //   };
        
        //   window.addEventListener("scroll", handleScroll);
          
        //   return () => {
        //     window.removeEventListener("scroll", handleScroll);
        //   };
        // }, []); 
        useEffect(() => {
            const userNotifications = async () => {
              if (!hasMore) return; // Stop if no more data
        
              setLoading(true);
                try {
                    const config = {
                        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                    }
                    const res = await axios.get(`${url}/api/Notification/getNotificationByUserID?page=${pagination}`, config)
                    console.log(res.data.data.data1, 'notification')
                    console.log(res.data.data.data2, 'notification123')
                    const result = await ClearNotify()
                    console.log(result)
                    if (pagination === 0) {
                        setData(res.data.data.data1)
                        console.log(res.data.data.data1,"paginationnn");
                        
                      } else {
                        console.log('paginationkkk')
                        setData((prevValues) => [...prevValues, ...res.data.data.data1])
                      }
                      console.log(res.data.data.data1,"kegrfdshd")
                      if (res.data.data.data1.length === 0) {
                        setHasMore(false);
                    }
                } catch (error) {
                    console.log(error)
                }finally {
                  setLoading(false);
                }
            }
            userNotifications()
        }, [pagination])
        console.log(data)
        console.log(pagination, 'paginationsssss')
         useEffect(() => {
        const GetWalletPayment = async () => {
          try {
            const config = {
              headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            }
            const res = await axios.get(`${url}/api/Payment/getUserSubAndWallet`, config)
            console.log(res.data, "getWalletBalancesetPayment....")
            setPayment(res.data)
            // setIsPremiumActive(res.data.isPremiumActive);
          } catch (error) {
            console.log(error, 'errrors')
          }
        }
        GetWalletPayment()
      }, [])

        // const handleClose=() =>{
        //     handleNotificationcall(false)

        const handleClose = () => {
            onNotificationClose(false)
        }
        // }
        console.log(data[0]?.type
         
          ,"johnh" );
        
        const Handlesubmitjoin = async (id) => {
          console.log(id,data[0].Message,"johiinih");
          
      
            try {
              const join = {
                ChannelOwnerID: LoGinUserID,
                ChannelID: id,
                RelatedUserId: data[0].users[0]?._id,
              };

              const invite = {
                ChannelOwnerID: data[0].users[0]?._id,
                ChannelID: id,
                RelatedUserId:LoGinUserID,
              };
              // const group = {
              //   ChannelOwnerID: LoGinUserID,
              //   ChannelID: id,
              //   RelatedUserId: data[0]?.SenderID,
              // }
              console.log(`${url}/api/Channel/AcceptChannelMember`,  data[0].Message === "Invite to Join Channel" ? invite : join, 'dataform');
      
              const config = {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  'Content-Type': 'application/json'
      
                }
              };
      
              const res = await axios.post(`${url}/api/Channel/AcceptChannelMember`,  data[0].Message === "Invite to Join Channel" ? invite : join,
                 config);
      
              console.log(res, "invirets....");
              window.location.reload()
            } catch (error) {
              console.error('Network Error:', error.response ? error.response.data : error.message);
            }
       
        };
        const HandlesubmitRemove = async (id) => {
          if (true) {
            // console.log(UserID, 'looo')
            try {
              const group = {
                ChannelID: id,
                ChannelOwnerID: LoGinUserID,
                RequestedID: data[0]?.SenderID,
                
              }
              console.log(data, 'dataform');
      
              const config = {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  'Content-Type': 'application/json'
      
                }
              };
      
              const res = await axios.post(`${url}/api/Channel/RemoveRequestFromChannelByUserId`, group, config);
      
              console.log(res, "kesava,,,,,,");
              // navigate('/usergroups');
              window.location.reload()
            } catch (error) {
              console.error('Network Error:', error.response ? error.response.data : error.message);
            }
          }
        };
        const navigate = useNavigate();
        const handleNavigate =(_id) => {
            console.log(_id, 'User------------Id');
            navigate(`/otherProfile/${_id}`)
            window.location.reload()
            
            }
            const handleSongCollborationNavigate = (data) => {
                console.log(data,'clikedValuessss')
                navigate(`/record/${data._id }`)
              }
              const handleScroll = () => {
                if (!drawerRef.current) return;
                const { scrollTop, scrollHeight, clientHeight } = drawerRef.current;
                
                // If user scrolls to the bottom, fetch next page
                if (scrollTop + clientHeight >= scrollHeight - 10) {
                  setPagination((prev) => prev + 1);
                }
              };
             
        return (
          
          
          <Box 
          ref={menuRef}
          onScroll={handleMenuScroll}
          sx={{ 
              height: { xs: "600px", md: "900px" }, // Responsive height
              overflowY: "auto", 
              border: "1px solid #ccc", 
              padding: "10px",
              width:600
              
          }}
      >
        <Meta title="Notification | SparkSinger " description="SparkSinger is the #1 karaoke app. Sing 14M+ songs with lyrics! Record with pro audio FX to sound your best! Sing karaoke solo, or duet with friends and popular artists" keywords="Karoke, Singing, SparkSinger" />
                <Box sx={{

                    '::-webkit-scrollbar': {
                        display: "none"
                    },

                }}>
                    <Box>
                        <Box sx={{ padding: '10px', cursor: 'pointer' }} onClick={handleClose}><CancelIcon style={{ fontSize: '30px', color: 'rgb(192, 204, 216)' }} /></Box>
                    </Box>
                </Box>
                <Box  sx={{ 
      position: "sticky", 
      top: 0, 
      // backgroundColor: "#DEF1FD", // Ensure it's visible
      zIndex: 1000, 
      padding: "10px", 
      display: "flex", 
      justifyContent: "space-between",
      alignItems: "center"
    }}>
                    <Card sx={{ boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px',backgroundColor:'#DEF1FD' ,margin: { xs: "10px", md: "20px" }, }}>
                    <Box
      sx={{backgroundColor:"#238AEA",
        color: "rgb(45, 40, 103)",
        fontSize: { xs: "20px", md: "32px" },
        fontWeight: 700,
        padding: "10px",
        paddingBottom: "20px",
        paddingTop: "20px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
        "& .vibrate": {
          animation: "vibrate 0.5s infinite ease-in-out",
        },
        "@keyframes vibrate": {
          "0%": { transform: "translateX(0px) rotate(0deg)" },
          "20%": { transform: "translateX(-2px) rotate(-2deg)" },
          "40%": { transform: "translateX(2px) rotate(2deg)" },
          "60%": { transform: "translateX(-2px) rotate(-2deg)" },
          "80%": { transform: "translateX(2px) rotate(2deg)" },
          "100%": { transform: "translateX(0px) rotate(0deg)" },
        },
      }}
    >
      <Box sx={{ fontSize: { xs: "20px", md: "32px" }, fontWeight: 700, color: "rgb(45, 40, 103)" }}>
      <Box className="vibrate">
        
        <BiBell size={32} />
      </Box></Box>
      
      Notifications
    </Box>
    {loading && data.length === 0 ? (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
            <FadeLoader color="LightGray"height={10} width={4} margin={-6} sx={{ color: "#6C2EFF" }} />
          </Box>
        ) : (
                       data && data.map((val, ind) => (
                             console.log(val,"gjjgdjjgs"),
                                
                               
                                <>
                                   {/* <Box sx={{color:'red',textAlign:"start"}}>{val.Message}</Box> */}
                                    {
                                      
                                        val.users && val.users.map((data, index) => (
                                            console.log(data.
                                              Message
                                               ,"hsjdhdjsjsh"),

                                            
                                            val.Type === 0 ?
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '15px', background: val.isViewed === 1 ? '#d3d3d3' : '', borderRadius: '3px' }}>
                                                    <Box sx={{ display: 'flex', gap: 1, padding: '10px' }}>
                                                        <Box>
                                                            
                                                        <Badge                                
                                                 overlap="circular"
                                                 anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                     badgeContent={
                                        data.isPremium ? (  
         <Avatar
                sx={{ bgcolor: 'red', width: 20, height: 20, fontSize: 10, border: '2px solid white',cursor:"pointer" }}
                alt="Premium User"
                src={songesss} 
            />
        ) : null  
    }
>
  
                                                            <Avatar src={data.ProfilePhoto} onClick={() => handleNavigate(val.users[0]?._id)} sx={{cursor:"pointer"}} /> </Badge>

                                                        </Box>
                                                        <Box sx={{ display: 'flex', marginTop: '8px', gap: .6 }}>
                                                            <Box sx={{ color: 'rgb(45, 40, 103)', fontSize: '15px', fontWeight: 700 }}>{data.Name !== '' ? data.Name : 'User'}</Box>
                                                            <Box>is now following you</Box>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{ fontSize: '12px', fontWeight: 700, color: 'rgb(129, 131, 140)', marginTop: '10px', padding: '10px' }}><ProfileTimeAgo timestamp={val.createdAt} /></Box>
                                                </Box> : <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '15px', background: val.isViewed === 1 ? '#d3d3d3' : '', borderRadius: '3px' }}>
                                                    <Box sx={{ display: 'flex', gap: 1, padding: '15px' }}>
                                                        <Box sx={{cursor:'pointer'}}>
                                                        <Badge                                
                                                 overlap="circular"
                                                 anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                     badgeContent={
                                        data.isPremium ? (  // Show only if isPremium is true
         <Avatar
                sx={{ bgcolor: 'red', width: 20, height: 20, fontSize: 10, border: '2px solid white' }}
                alt="Premium User"
                src={songesss} 
            />
        ) : null  
    }
>
                                                         <Avatar src={data.ProfilePhoto|| image3}  onClick={() => handleNavigate(val.users[0]?._id)}/> </Badge>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                            <Box sx={{ display: 'flex', gap: .5, paddingBottom: '5px' }}>
                                                            
                                                                <Box sx={{ color: 'rgb(45, 40, 104)', fontSize: '15px', fontWeight: 700 }}>{data.Name !== '' ? data.Name : 'User'}</Box>
                                                                
                                                                <Box>
                                                                    {
                                                                        val.Type && val.Type === 1 ? <Box>loved:</Box> : val.Type && val.Type === 2 ? <Box>commented:</Box> : val.Type && val.Type === 3 ? <Box>Gifted:</Box> :val.Type && val.Type === 5 ? <Box>Group:</Box> : ''
                                                                    }
                                                                </Box>
                                                                <Box sx={{color:'rgb(45, 40, 104)',textAlign:"start"}}>{val.Message}</Box>
                                                            </Box>
                                                            
                                                            
                                                            {
                                                                val.Type && val.Type === 2 ?
                                                                    <>
                                                                        {
                                                                            val.songcollaborationcomments && val.songcollaborationcomments.map((val, ind) => (
                                                                                <Box sx={{ width:"50%", fontSize: '14px', fontWeight: 400, color: 'rgb(88, 90, 100)' , fontSize: '16px',
                                                                                 fontWeight: '500',
                                                                                  lineHeight: '20px',
                                                                                   wordBreak: 'break-word',   // Ensures words break correctly
                                                                                 whiteSpace: 'pre-wrap' }}>{val.CommentText}</Box>
                                                                            ))
                                                                        }
                                                                    </>
                                                                    : val.Type && val.Type === 3 ? <>{
                                                                        
                                                                        val.songcollaborationgifts
                                                                        && val.songcollaborationgifts.map((giftin, ind) => (
                                                                            console.log(giftin.gifts[0]?.
                                                                              GiftImageURL
                                                                                ,"igiugiu"),
                                                                              <Box
                                                                                key={ind}
                                                                                sx={{
                                                                                  display: "flex",
                                                                                  alignItems: "center",
                                                                                  gap: 2, // Adds spacing between the image and text
                                                                                  padding: "10px 0",
                                                                                }}
                                                                              
                                                                              >
                                                                                {/* Left-side image */}
                                                                                
                                                                                <img
                                                                                 src={giftin.
                                                                                  GiftImageURL}
                                                                                 alt={giftin.gifts?.[0]?.Name || image3}
                                                                                 style={{ width: "90px", height: "90px", borderRadius: "4px" }}/>

                                                                        
                                                                                {/* Right-side text content */}
                                                                                <Box>
                                                                                  <Typography sx={{ color: 'rgb(45, 40, 104)', fontSize: '15px', fontWeight: 700, wordBreak: 'break-word'}}>
                                                                                    {giftin.Description}
                                                                                  </Typography>
                                                                                 
                                                                                </Box>
                                                                                
                                                                              </Box>
                                                                            ))
                                                                        
                                                                    }</>  : 
                                                                    val.Type && val.Type === 5 ? <>
                                                                  {val.channels &&
  val.channels.map((groupsin, ind) => (
    <Box key={ind}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2, // Adds spacing between the image and text
          padding: "10px 0",
          cursor:'pointer'
        }}
        onClick={() =>
          navigate(`/groupinfo/${groupsin._id}`, {
            state: groupsin,
          })
        }
      >
        {/* Left-side image */}
        <img
          src={groupsin.CoverImage || image4}
          alt={groupsin.ChannelName}
          style={{ width: "64px", height: "64px", borderRadius: "4px",cursor:"pointer" }}
        />

        {/* Right-side text content */}
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            sx={{ color: "rgb(45, 40, 104)", fontSize: "15px", fontWeight: 700 }}
          >
            {groupsin.ChannelName}
          </Typography>
          <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
            Member Count: {groupsin.MemberCount}
          </Typography>
        </Box>

        {/* If isMember is true, show the group icon */}
        {groupsin.isMember && (
          <Box onClick={() =>
            navigate(`/groupinfo/${groupsin._id}`, {
              state: groupsin,
            })
          } sx={{cursor:"pointer"}}><Avatar><GroupOutlined/></Avatar></Box>
        )}
      </Box>

      {/* Show buttons only if isMember is false */}
      {!groupsin.isMember && (
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="outlined"
            onClick={() => {
              HandlesubmitRemove(groupsin._id);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              Handlesubmitjoin(groupsin._id);
            }}
          >
            Accept
          </Button>
        </Box>
      )}
    </Box>
  ))}</>:""
                                                                    
                                                            }
                                                            
                                                            
                                                            <Box sx={{ display: 'flex', marginTop: '10px', gap: 1 }}>
                                                                
                                                                {
                                                                    val.songcollaborations && val.songcollaborations.map((collabData, num) => (
                                                                        // console.log(collabData. users[0]?._id,"songessss"),
                                                                        
                                                                        <>
                                                                            <Box sx={{ cursor: 'pointer'}}>
                                                                                <img src={collabData.CoverImageURL || image3}  onClick={() => {navigate(`/record/${collabData._id}`)}}style={{ width: '64px', height: '64px', borderRadius: '4px' }} />
                                                                                <Box key={ind} sx={{ color: 'rgb(45, 40, 104)', fontSize: '15px', fontWeight: 700, paddingBottom: '3px' }}>
                                                                                            {collabData.karaokes.Name }
                                                                                        </Box>
                                                                            </Box>
                                                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                                                {
                                                                                    val.songcollaborations && val.songcollaborations.map((val, ind) => (
                                                                                        console.log(val,"musiceeeee"),
                                                                                        <Box>
                                                                                        <Box key={val} sx={{ color: 'rgb(45, 40, 104)', fontSize: '15px', fontWeight: 700, paddingBottom: '3px' }}>
                                                                                            {val.karaokes[0].Name }
                                                                                        </Box>
                                                                                        <Box key={val} sx={{ color: '#AAADB4', fontSize: '14px', fontWeight: 700, paddingBottom: '3px' }}>
                                                                                            {val.users[0].Name }
                                                                                        </Box>
                                                                                        </Box>
                                                                                        
                                                                                    ))
                                                                                }
                                                                                
                                                                                {
                                                                                    val.songcollaborationsuser && val.songcollaborationsuser.map((val, ind) => (
                                                                                        <Box sx={{ fontSize: '14px', fontWeight: 400, paddingBottom: '5px' }}>{val.users[0]?.Bio}</Box>
                                                                                    ))
                                                                                }
                                                                                
                                                                                    {
                                                                                        val.channels  && val.channels.map((channelsss, ind) => (
                                                                                            console.log(channelsss),
                                                                                            
                                                                                            <Box sx={{ fontSize: '14px', fontWeight: 400, paddingBottom: '5px' }}>{channelsss.ChannelName}</Box>
                                                                                            
                                                                                        ))
                                                                                    }

                                                                                
                                                                                
                                                                                
                                                                               
                                                                                <Box sx={{ display: "flex", justifyContent: "flex-start", color: "#AAADB4", fontSize: "10px", gap: 1 }}>
                                                                                    <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>

                                                                                        <Box><PlayArrowIcon fontSize='small' /></Box>
                                                                                        <Box>{collabData.NoOfPlay}</Box>
                                                                                    </Box>.
                                                                                    <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                                                                        <Box><FavoriteIcon fontSize='small' /></Box>
                                                                                        <Box>{collabData.NoOfLikes}</Box>
                                                                                    </Box>.
                                                                                    <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                                                                        <Box><Redeem fontSize='small' /></Box>
                                                                                        <Box>{collabData.NoOfGifts}</Box>
                                                                                    </Box>.
                                                    
                                                                                    <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                                                                        <Box><CiClock2 color='rgb(200, 202, 207)' fontSize='small' /></Box>
                                                                                        <Box sx={{ fontSize: '12px', fontWeight: 700, color: 'rgb(129, 131, 140)' }}><ProfileTimeAgo timestamp={collabData.createdAt} /></Box>
                                                                                    </Box>

                                                                                </Box>
                                                                            </Box>
                                                                        </>
                                                                    ))
                                                                }
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{ fontSize: '12px', fontWeight: 700, color: 'rgb(129, 131, 140)', marginTop: '10px', padding: '10px' }}><ProfileTimeAgo timestamp={val.createdAt} /></Box>
                                                </Box>
                                        ))
                                    }
                                </>
                            ))
                            
                       ) }
                        {hasMore && (
          <Box sx={{ display: "flex", justifyContent: "center", margin: "20px" }}>
            <Button variant="contained"  disabled={loading}>
              {loading ? "Loading..." : "Load More"}
            </Button>
          </Box>
        )}
                        
                    </Card>
                </Box>
            </Box>
        )
    }

export default NotificationDrawer
