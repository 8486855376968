import React,{useState}from "react";
import { Box , Modal} from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import XIcon from '@mui/icons-material/X';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EmailIcon from '@mui/icons-material/Email';
import CancelIcon from '@mui/icons-material/Cancel';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    boxShadow: 24,
    p: 1,
  };

  const ModalComponent = ({isOpen, onClose, content, type,modalLink}) => {
      if(!isOpen) return null;

      const renderCount = () => {
        switch (type){
            case 'share' : 
            return(
                  <Box sx={{width:'400px'}}>
                    <Box sx={{display:'flex',justifyContent:'space-between',borderBottom:'1px solid rgba(45, 40, 103, 0.1)',paddingBottom:'10px',backgroundColor:"#C0CCD8"}}>
                        <Box sx={{display:'flex',flexDirection:'column',gap:.2}}>
                            <Box sx={{color:'rgb(45, 40, 103)',fontSize:'17px',fontWeight:700}}>Share</Box>
                            <Box sx={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:500}}>{content}</Box>
                        </Box>
                        <Box onClick={onClose} sx={{marginTop:'5px',cursor:'pointer'}}><CancelIcon style={{fontSize:'30px',color:'black'}}/></Box>
                    </Box>
                    <Box sx={{display:'flex',justifyContent:'center',marginTop:'20px'}}>
                        <Box sx={{display:'flex',gap:1}}>
                            <Box sx={{display:'flex',background:'rgb(240, 242, 244)',gap:.3,padding:'10px',borderRadius:'3px',paddingTop:'5px',paddingBottom:'5px'}}>
                                <Box sx={{marginTop:'5px'}}><FacebookIcon/></Box>
                                <Box sx={{color:'rgb(45, 40, 103)',fontSize:'14px',fontWeight:700,marginTop:'7px'}}>Facebook</Box>
                            </Box>
                            <a href={`https://wa.me/?text=${window.location.href}`} target="_blank">
                            <Box sx={{display:'flex',background:'rgb(240, 242, 244)',gap:.3,padding:'10px',borderRadius:'3px',paddingTop:'5px',paddingBottom:'5px'}}>
                                <Box sx={{marginTop:'5px'}}><WhatsAppIcon/></Box>
                                <Box sx={{color:'rgb(45, 40, 103)',fontSize:'14px',fontWeight:700,marginTop:'7px'}}>WhatsApp</Box>
                            </Box>
                            </a>
                        </Box>
                    </Box>
                    <Box sx={{display:"flex",flexWrap:'wrap',whiteSpace:'nowrap',padding:'15px',marginTop:'15px',gap:3}}>
                        <Box sx={{display:'flex',flexDirection:'column'}}>
                            <Box sx={{textAlign:'center'}}>
                                <XIcon/>
                            </Box>
                            <Box sx={{color:'rgb(45, 40, 103)',fontSize:'12px',fontWeight:500}}>Twitter</Box>
                        </Box>
                        <Box sx={{display:'flex',flexDirection:'column'}}>
                            <Box sx={{textAlign:'center'}}>
                                <ContentCopyIcon/>
                            </Box>
                            <Box sx={{color:'rgb(45, 40, 103)',fontSize:'12px',fontWeight:500}}>Copy Link</Box>
                        </Box>
                        <Box sx={{display:'flex',flexDirection:'column'}}>
                            <Box sx={{textAlign:'center'}}>
                                <EmailIcon/>
                            </Box>
                            <Box sx={{color:'rgb(45, 40, 103)',fontSize:'12px',fontWeight:500}}>Email</Box>
                        </Box>
                    </Box>
                  </Box>    
            );
            default :
            return <Box>{content}</Box>
        }
      }

      return(
        <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
         <Box sx={style}>
              {renderCount()}
         </Box>
      </Modal>
      )
  }


  export default ModalComponent;