import { Box, Button, Card, CardContent, Divider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { url } from '../config/config';
import elpsss from "../assest/images/NewLogo.png"
import step1 from '../assest/images/step1.png'
import step2 from '../assest/images/step2.png'
import step3 from '../assest/images/step3.png'
import step4 from '../assest/images/step4.png'
import step5 from '../assest/images/step5.png'
import step6 from '../assest/images/step6.png'
import Meta from '../components/meta_data';
const Paragraph = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [ishelp, setIshelp] = useState([]);
  // const params = useParams();
  const {id,name}= useParams()
  console.log(id,"johnnnnnn");
  const content = {
    1: 
      <Box sx={{ padding: 3, maxWidth: 800, margin: '0 auto',textAlign:'start',color:"#743CED" }}>
     <h1>How to Use SparkSinger</h1>
    
    <h2>Introduction</h2>

    <h2>Singing on SparkSinger</h2>

    <h3>How Do I Join a Collab?</h3>
    <ol>
        <li>In the Songbook, select a song you’d like to sing.</li>
        <li>Tap on “View Invites” to see a list of available collab invitations.</li>
        <li>Find a collab you’d like to join and tap “Join” to start singing with others.</li>
    </ol>
    <p>When you join a collab, you are adding your voice to other previously recorded singers.</p>

    <h3>How Do I Start a Collab?</h3>
    <ol>
        <li>From the Songbook, choose a song you’d like to sing.
            <em>(Note: Some songs may require a PREMIUM subscription or viewing a short ad.)</em>
        </li>
        <li>Select “Duet” or “Group” to begin:
            <ul>
                <li><strong>Duet:</strong> Add your voice and let another singer join in to create a dynamic recording showcased on both profiles.</li>
                <li><strong>Group:</strong> Open your recording to many singers for a collaborative piece that evolves with each addition.</li>
            </ul>
        </li>
    </ol>
    <p>Note: To maintain creative control, set the collab to private and manually invite specific singers. Once satisfied, make it public to share your masterpiece with the world.</p>

    <h3>How Do I Invite Other Singers to Join My Collabs?</h3>
    <ol>
        <li>If your collab is set to public, you can invite all your followers. They’ll see your invite in their notification list.</li>
        <li>After saving your recording, you can:
            <ul>
                <li>Send personalized invitations via direct messages.</li>
                <li>Share your invite in existing group chats.</li>
                <li>Invited singers will receive a message with a link to your collab.</li>
            </ul>
        </li>
        <li>Once saved, your collab will appear under the “Invites” tab on your profile. Tap “Invite Singers” later to invite more participants.</li>
    </ol>

    <h3>Why Doesn’t My Invite Appear Under “View Invites”?</h3>
    <p>To improve the collab experience, only public invites from singers with a profile picture are shown.</p>
    <ul>
        <li>Ensure your invite is public.</li>
        <li>Upload a profile picture to increase visibility.</li>
    </ul>
    <p>Enjoy collaborating and creating music with the SparkSinger community!</p>

    <h2>How to Delete a Recording on SparkSinger?</h2>
    <p>You can delete any solo, duet, or group recording you’ve posted. However, recordings made by other singers cannot be deleted by you.</p>

    <h3>Deleting a Recording in the App</h3>
    <ol>
        <li>Go to your profile and select the recording you want to delete.</li>
        <li>Tap the three dots icon at the bottom right of the screen.</li>
        <li>Select "Delete" and proceed to delete the recording.</li>
    </ol>

    <h3>Deleting a Recording on the Website</h3>
    <ol>
        <li>Sign in to your account at <a href="https://sparksinger.com">sparksinger.com</a>.</li>
        <li>Locate the recording you want to delete.</li>
        <li>Click the three dots at the top right of the album art for the recording.</li>
        <li>From the menu, select "Edit performance".</li>
        <li>Open the popup menu, select "Delete" to remove the recording.</li>
    </ol>
    <p>Keep your recordings list updated with only the performances you love!</p>

    <h2>Managing Group Recordings on SparkSinger</h2>

    <h3>How to Remove Yourself from Another Singer’s Group Recording?</h3>
    <ol>
        <li>Open the group recording where you’ve contributed.</li>
        <li>Tap the three dots menu.</li>
        <li>Select “Hide” to remove your layer.
            <em>Your contribution will be hidden within a few minutes.</em>
        </li>
    </ol>
    <p>If you need further assistance, feel free to contact the SparkSinger Support Team via this <a href="#">link</a>.</p>

    <h3>How to Remove Another Singer’s Recording from Your Group Song</h3>
    <ul>
        <li>You cannot remove another singer’s recording from your group performance.</li>
        <li>For better control over your group recordings:
            <ol>
                <li>Set the recording to private.</li>
                <li>Manually invite specific singers to contribute.</li>
                <li>Once satisfied with the final result, close the recording and make it public.</li>
            </ol>
        </li>
    </ul>
    <p>This ensures you maintain editorial control while sharing a polished group recording with the world!</p>

    <h2>Using Video on SparkSinger</h2>

    <h3>Singing with Video</h3>
    <ol>
        <li>Go to the Songbook and choose a song.</li>
        <li>Join an existing collab or start a new solo or collab.</li>
        <li>Before you begin singing, toggle the Video switch to “On”.</li>
    </ol>

    <h3>Switching Between Front and Back Cameras</h3>
    <ul>
        <li><strong>iOS & Android:</strong> Use the camera switch button below the Vocal Volume icon at the top-right corner before recording.</li>
    </ul>

    <h3>Checking Your Video Posting Status</h3>
    <ul>
        <li><strong>iOS & Android:</strong>
            <ul>
                <li>Go to your profile to see the channel tab or recordings.</li>
                <li>Once videos are uploaded, your profile is updating for each recording uploading.</li>
            </ul>
        </li>
    </ul>

    <h3>Handling Video Posting Failures</h3>
    <ol>
        <li>Tap the Retry button next to the error message.</li>
        <li>Ensure a stable internet connection.</li>
        <li>Refresh your profile by pulling down the list to automatically retry failed uploads.</li>
    </ol>

    <h3>Video Specifics for Android</h3>

    <h4>Why Are My Audio and Video Out of Sync During Review?</h4>
    <p>Some devices may show audio and video out of sync due to slower processing speeds. Tap “Continue” to post your video, and they should sync properly after posting.</p>

    <h4>Why Can’t I Record Videos?</h4>
    <ul>
        <li>Video recording requires Android 7.0 or above and a device capable of handling video processing.</li>
        <li>If your device doesn’t meet these requirements, you can still use audio-only recordings.</li>
    </ul>
    

<div class="section">
<h2>SparkSinger Guide</h2>
    <h2>Video Specifics for iOS</h2>
    <h3>What Devices Support Video?</h3>
    <ul>
        <li>Devices running iOS 13.4 or above support video recording.</li>
        <li>Unsupported devices can still join collabs using only audio.</li>
    </ul>

    <h3>Sharing Videos to Social Media</h3>
    <h4>Sharing to Snapchat</h4>
    <ol>
        <li>Open the song you wish to share.</li>
        <li>Tap the Share icon and select Snapchat.</li>
        <li>Use the video trimmer to select a 10-second clip.</li>
        <li>Customize with Snapchat filters, stickers, animations, and text before sharing.</li>
    </ol>
    <p>Enjoy creating and sharing your video performances!</p>
</div>

<div class="section">
    <h2>Uploading Content</h2>
    <h3>Adding a Song to the Songbook</h3>
    <h4>Who can add a song to SparkSinger Songbook?</h4>
    <p>Anyone with a SparkSinger account can add a song to the Songbook. If you don’t have an account, you can sign up at <a href="https://www.sparksinger.com">www.sparksinger.com</a>.</p>

    <h4>How do I add a song to the Songbook?</h4>
    <ol>
        <li><strong>Prepare a Backing Track</strong>
            You first need a backing track for your song that doesn’t include vocals.
        </li>
        <li><strong>Log in to Your Account</strong>
            Sign into your SparkSinger account at <a href="https://www.sparksinger.com">www.sparksinger.com</a> and click the green "Upload" button.
        </li>
        <li><strong>Select the Songbook Option</strong>
            Click the “Songbook” option on the right.
        </li>
        <li><strong>Upload Your Backing Track</strong>
            Select and upload your backing track.
        </li>
        <li><strong>Enter Song Details</strong>
            Enter the title, artist, genre(s), and tag(s) for your song.
        </li>
        <li><strong>Add the Lyrics</strong>
            Enter the lyrics for your song and assign parts for duets or groups.
        </li>
        <li><strong>Time Your Lyrics to the Music</strong>
            Use the provided tools to time each line to the music.
        </li>
        <li><strong>Save Your Song</strong>
            Click "Save" to make your song available in the Songbook.
        </li>
    </ol>

    <h4>Can I add a song using my mobile phone or tablet?</h4>
    <p>No, songs can only be added through a desktop or laptop computer.</p>

    <h4>How can I edit a song I’ve added?</h4>
    <p>Log into your account and go to the Songs section of your profile. Click on the song you wish to edit and select the “Edit Song” button.</p>

    <h4>Can I add a song privately?</h4>
    <p>No, all added songs become publicly available in the Songbook.</p>

    <h4>How are user-added songs different from songs created by SparkSinger?</h4>
    <p>User-added songs have timed lyrics, while SparkSinger adds pitch lines and syllable-level timing.</p>

    <h4>Can I remove a song I’ve added to the Songbook?</h4>
    <p>Yes, log into your account, go to the song’s page, and select the delete option.</p>

    <h4>Can I see if the community is singing my song?</h4>
    <p>Yes, view stats like recordings and ratings in the Songs section of your profile.</p>

    <h4>How can I learn more about the Songbook?</h4>
    <p>Visit the <a href="https://www.sparksinger.com">Uploading FAQ</a>.</p>
</div>

<div class="section">
    <h2>Uploading Requirements</h2>
    <ul>
        <li>Maximum file size: 20 MB</li>
        <li>Recording length: 30 seconds to 10 minutes</li>
        <li>Supported formats: mp3</li>
        <li>Sample Rate: 16 Bit, 44100Hz or 48000Hz</li>
        <li>File type: Mono or stereo files only</li>
        <li>Maximum compression: 64kbps (mono) and 128kbps (stereo)</li>
    </ul>
</div>
<header>
        <h2>Welcome to SparkSinger</h2>
        <p>Follow these steps to create and upload your music files.</p>
    </header>

    <section id="create-backing-track">
        <h2>Creating Your Backing Track</h2>
        <p>Backing tracks typically feature drums, piano, guitar, synths, and bass. You can use various recording programs with built-in synthesizers, drum machines, and recording tools.</p>
        <ul>
            <li><strong>Beginner Option:</strong> GarageBand by Apple (macOS and iOS).</li>
            <li>Ensure your backing track is in an accepted format before uploading (see below).</li>
        </ul>
    </section>

    <section id="file-requirements">
        <h2>File Requirements</h2>
        <ul>
            <li>Maximum file size: 20 MB</li>
            <li>Recording length: Up to 10 minutes</li>
            <li>Supported formats: m4a, mp3</li>
            <li>Sample Rate: 16 Bit, 44100Hz or 48000Hz</li>
            <li>File type: Mono or stereo</li>
            <li>Maximum compression: 64kbps (mono) and 128kbps (stereo)</li>
        </ul>
    </section>

    <section id="convert-files">
        <h2>Converting Your File</h2>
        <p>Use these tools to save or export your files in the correct format:</p>
        <h3>Paid Options</h3>
        <ul>
            <li>Apple GarageBand</li>
            <li>Adobe Audition</li>
            <li>Reaper</li>
            <li>Ableton Live</li>
        </ul>
        <h3>Free Options</h3>
        <ul>
            <li><a href="https://www.videolan.org/vlc/">VLC Media Player</a></li>
            <li><a href="https://www.audacityteam.org/">Audacity</a> (requires additional codecs for exporting mp3 or m4a files)</li>
        </ul>
    </section>

    <section id="reduce-file-size">
        <h2>Reducing File Size</h2>
        <p>If your file is too large:</p>
        <ul>
            <li>Lower the compression bitrate (e.g., 96kbps instead of 128kbps).</li>
            <li>Save the file as mono instead of stereo if stereo is unnecessary.</li>
        </ul>
    </section>

    <section id="upload-tutorial">
        <h2>Uploading Your Beat to AutoRap</h2>
        <ol>
            <li><strong>Open SparkSinger.com:</strong> Visit the website in your browser.</li>
            <li><strong>Access the Upload Section:</strong> Click "Upload" and choose "Upload a Beat to AutoRap."</li>
            <li><strong>Select and Upload:</strong> Choose your beat file from your device.</li>
            <li><strong>Provide Details:</strong>
                <ul>
                    <li>Enter the title and artist.</li>
                    <li>Fill in the genre and mood (required).</li>
                    <li>Upload cover art.</li>
                </ul>
            </li>
            <li><strong>Mark the Verse Start:</strong> Play the beat and mark where the first verse begins.</li>
            <li><strong>Publish:</strong> Click "Publish Your Beat."</li>
        </ol>
    </section>

    <section id="social-features">
        <h2>Followers, Chat, and Comments</h2>
        <h3>Following Singers</h3>
        <p>Follow singers to see their activities and join collaborations. Limits:</p>
        <ul>
            <li>Follow up to 2,000 singers without restrictions.</li>
            <li>Users cannot follow more than 1,000 singers per day.</li>
        </ul>
        <h3>Chat Features</h3>
        <p>To send messages:</p>
        <ol>
            <li>Go to a singer's profile.</li>
            <li>Click the message icon to start chatting.</li>
        </ol>
    </section>
    <h2>SparkSinger Chat Updates: New Rules and Guidelines</h2>

<div class="section">
    <h2>How to Start a 1:1 Chat</h2>
    <ol>
        <li>Tap the "Message" button to start a chat.</li>
        <li>Alternatively, go to the Messages screen.</li>
        <li>Tap the new message icon (top-right corner), select a user (or search by username), and hit "Next" to initiate a 1:1 chat.</li>
    </ol>
</div>

<div class="section">
    <h2>How to Start a Group Chat</h2>
    <ol>
        <li>Tap the new message icon on the Messages screen.</li>
        <li>Select multiple users, then tap "Next" to create a group chat.</li>
        <li>Your friends will receive your group chat request. Once accepted, they can view the entire group chat history, even if added later.</li>
    </ol>
</div>

<div class="section">
    <h2>How to Name a Group Chat</h2>
    <ul>
        <li>You must name your group chat during its creation.</li>
        <li>A descriptive name makes it easier for others to identify and accept the group chat.</li>
        <li>To rename a group chat later:
            <ul>
                <li>Open the group chat.</li>
                <li>Tap the details icon (<em>i</em>) in the top-right corner.</li>
            </ul>
        </li>
    </ul>
</div>

<div class="section">
    <h2>Adding or Removing Members in a Group Chat</h2>
    <ol>
        <li>Open the group chat.</li>
        <li>Tap the details icon (<em>i</em>) in the top-right corner.</li>
        <li>On the Group Chat Details screen:
            <ul>
                <li>Tap "+" to add members.</li>
                <li>Tap "-" to remove members.</li>
                <li>Changes to the group (additions or removals) will be announced in the chat.</li>
            </ul>
        </li>
    </ol>
</div>

<div class="section">
    <h2>How to Leave a Group Chat</h2>
    <ol>
        <li>Open the group chat.</li>
        <li>Tap the details icon (<em>i</em>) in the top-right corner.</li>
        <li>On the Group Chat Details screen, select "Leave Group Chat".</li>
        <li>Your departure will be announced to other group members.</li>
    </ol>
</div>

<div class="section">
    <h2>Turning Off Chat Notifications</h2>
    <ul>
        <li>For specific chats:
            <ul>
                <li>Go to Group Chat Details and toggle off "Receive Notifications".</li>
                <li>Or, on the Messages screen, swipe left (or tap and hold for Android) and tap the "mute" button.</li>
            </ul>
        </li>
        <li>For all chats:
            <ul>
                <li>Go to Settings on your profile page.</li>
                <li>Toggle off "All Chat Notifications".</li>
            </ul>
        </li>
    </ul>
</div>

<div class="section">
    <h2>Sharing a Recording in a Message</h2>
    <ol>
        <li>Go to the share screen for your recording.</li>
        <li>Tap the "Message" icon on the left.</li>
        <li>Send your recording to up to 5 chats or users at once.</li>
    </ol>
</div>

<div class="section">
    <h2>Sending a Collab Invite in a Message</h2>
    <ol>
        <li>Go to the invite screen.</li>
        <li>Tap the "Message" icon on the left.</li>
        <li>Send your invite to up to 5 chats or users at once.</li>
    </ol>
</div>

<div class="section">
    <h2>Privacy of Messages</h2>
    <ul>
        <li>Only you and the people involved in the chat can see its contents.</li>
        <li>If new users are added to a group chat, they will see all previous messages.</li>
    </ul>
</div>

<div class="section">
    <h2>Will My Chat History Expire?</h2>
    <ul>
        <li>Previous conversations remain visible when messaging someone again.</li>
        <li>Individual messages cannot be deleted manually.</li>
        <li>Chats are automatically deleted after a period of inactivity.</li>
    </ul>
</div>

<div class="section">
    <h2>New Chat Rules for Non-Mutual Followers</h2>
    <ol>
        <li>If User B does not follow User A:
            <ul>
                <li>User A can send unlimited messages, but User B will only receive the first message.</li>
                <li>This first message is restricted to 100 characters or less.</li>
                <li>User B will not receive additional messages unless they:
                    <ul>
                        <li>Reply to the first message, or</li>
                        <li>Follow User A.</li>
                    </ul>
                </li>
            </ul>
        </li>
        <li>User B can remove the messaging restriction by:
            <ul>
                <li>Replying to the initial message.</li>
                <li>Following User A.</li>
            </ul>
        </li>
    </ol>
</div>

<div class="section">
    <h2>Why Were These Changes Implemented?</h2>
    <ul>
        <li>Reduce spam: Limiting unsolicited messages minimizes disruptions from unknown users.</li>
        <li>Prevent inappropriate behavior: Restricting messages helps curb profanity and harmful content.</li>
        <li>Enhance user safety: Encouraging mutual follow relationships promotes meaningful and secure interactions.</li>
    </ul>
</div>

<div class="section">
    <h2>How Can I Protect Myself from Spam or Unwanted Messages?</h2>
    <ul>
        <li>Ignore messages from unknown users.</li>
        <li>Block or report users who send unwanted or inappropriate content.</li>
    </ul>
</div>
<h2>7. Can I Still Chat Freely with Friends?</h2>
<p><strong>Yes!</strong> If you and another user follow each other, there are no restrictions on messaging.</p>

<h3>Thank You for Your Support!</h3>
<p>We appreciate your understanding as we work to make SparkSinger a safer, more enjoyable space for everyone. For additional questions or concerns, please reach out to our support team.</p>

<h3>Comments: Managing Your Interactions on SparkSinger</h3>
<h4>How Do I Delete a Comment?</h4>
<p>You can delete:</p>
<ul>
  <li>Your own comments on another singer’s recording.</li>
  <li>Any comments posted by other singers on your recordings.</li>
</ul>

<h4>Deleting a Comment Inside the App</h4>
<ol>
  <li>Locate the comment you want to delete.</li>
  <li>For iOS: Swipe the comment to the left. For Android: Tap and hold the comment.</li>
  <li>Tap the trashcan icon to delete the comment.</li>
</ol>

<h4>Deleting a Comment on sparksinger.com</h4>
<ol>
  <li>Sign in to your account at sparksinger.com.</li>
  <li>Navigate to the comment you want to delete.</li>
  <li>Hover your cursor over the comment.</li>
  <li>Click the X icon that appears to delete the comment.</li>
</ol>

<h3>SparkSinger Groups</h3>
<h4>General Information</h4>
<h5>What is a group?</h5>
<p>A group is a community of two or more people who share a common interest, coming together to chat, collaborate, and build meaningful connections.</p>

<h5>Where can I find groups?</h5>
<p>Groups can currently be created via the SparkSinger website or on the iOS version of the app. The Android version is under development. For Android users: navigate to the Explore page and check the "+" sign. However, inviting members to groups is only available on iOS or the website.</p>

<h5>How do I create a group?</h5>
<ol>
  <li>Navigate to the Explore tab in the iOS app or the homepage of the SparkSinger website.</li>
  <li>Scroll to the "Groups" section.</li>
  <li>Tap “See All” and then the “+” button.</li>
  <li>Fill in the required and optional fields.</li>
  <li>Tap “Create” to set up your group.</li>
  <li>Invite members to join your group using the options provided.</li>
</ol>

<h5>Who can create a group?</h5>
<p>Anyone can create a group.</p>

<h5>How many members can a group have?</h5>
<p>Groups can accommodate up to 2,000 members.</p>

<h5>What are the privacy settings for groups?</h5>
<p>All groups are public, meaning non-members can view the group page and its contents. However, posting is restricted to members only.</p>

<h4>Group Management</h4>
<h5>How do I delete a group?</h5>
<p>Groups can be deleted on the SparkSinger website or iOS app.</p>
<h6>On sparksinger.com:</h6>
<ol>
  <li>Go to your profile and click the “Groups” icon below your username.</li>
  <li>Click the three dots (“…”) next to the group you want to delete.</li>
  <li>Select “Manage Group” and then “Delete Group”.</li>
</ol>
<h6>On iOS:</h6>
<ol>
  <li>Open the group page and tap the three dots (“…”) in the top-right corner.</li>
  <li>Go to “Group Settings”, scroll to the bottom, and tap “Delete Group”. Confirm the action.</li>
</ol>

<h5>How do I invite users to my group?</h5>
<h6>On sparksinger.com:</h6>
<ol>
  <li>Navigate to your group page.</li>
  <li>Click the gear icon within the group’s cover picture.</li>
  <li>Go to the “Settings” submenu and select “Group Members”.</li>
  <li>Tap “+” to invite users.</li>
</ol>
<h6>On iOS:</h6>
<ol>
  <li>Open your group page and tap the three dots (“…”) in the top-right corner.</li>
  <li>Go to “Group Settings” and select “Group Members” under the “Settings” submenu.</li>
  <li>Use the “+” icon to search for and add members.</li>
</ol>
<p>Note: Inviting members is not yet available on Android.</p>

<h5>How do I remove or ban someone from my group?</h5>
<ol>
  <li>Open the group page and tap the three dots (“…”) near the group’s title.</li>
  <li>Go to the “Members” page.</li>
  <li>Tap the three dots (“…”) next to a user’s name and choose:
    <ul>
      <li>Ban User</li>
      <li>Remove User</li>
    </ul>
  </li>
</ol>

<h5>How do I approve or manage posts in my group?</h5>
<p>Group members can post to the group page automatically. If needed, group owners can delete posts made by members.</p>

<h5>How do I change my group’s name?</h5>
<h6>On SparkSinger.com:</h6>
<ol>
  <li>Navigate to the “Groups” section in your profile.</li>
  <li>Click the three dots (“…”) next to the group name and select “Manage Group”.</li>
  <li>Edit the name in the Group Name field and click “Save”.</li>
</ol>
<h6>On iOS/Android:</h6>
<ol>
  <li>Open the group page and tap the three dots (“…”) in the top-right corner.</li>
  <li>Go to “Group Settings”, edit the name, and tap “Save”.</li>
</ol>
<h4>How do I upload a cover photo for my group?</h4>
<h5>On SparkSinger.com:</h5>
<ol>
  <li>Go to the “Groups” section of your profile.</li>
  <li>Click the three dots (“…”) next to the group name and select “Manage Group”.</li>
  <li>Add or edit the photo using the camera icon.</li>
</ol>

<h5>On iOS/Android:</h5>
<ol>
  <li>Open the group page and tap the three dots (“…”) in the top-right corner.</li>
  <li>Go to “Group Settings” and select the camera icon to upload a cover photo.</li>
</ol>

<h4>How many owners can a group have?</h4>
<p>Each group can have only one owner. To transfer ownership, contact support at <a href="mailto:support@sparksinger.com">support@sparksinger.com</a>.</p>

<h3>Posting and Sharing</h3>
<h4>How do I post to a group?</h4>
<ol>
  <li>Navigate to the group page.</li>
  <li>Tap the “+” icon next to “Add Post.”</li>
  <li>Select the recordings you want to share on the group page.</li>
  <li>Tap “Post.”</li>
</ol>

<h4>Can I start a Sing Live in a group?</h4>
<p>Yes, tap the “Sing Live” button located below the group’s name and members.</p>

<h4>How do I share my group posts on other social media channels?</h4>
<p>Click the “Share” button located below the group’s name and members to share your post externally.</p>

<h4>Can I search for a specific post or recording in a group?</h4>
<p>No, group posts are not searchable.</p>

<h3>Notifications and Chatting</h3>
<h4>Notifications</h4>
<h5>Will there be notifications for groups?</h5>
<ul>
  <li><strong>Group Owners:</strong> Receive notifications when users request to join their group.</li>
  <li><strong>Users:</strong> Receive notifications when they’re invited to join a group.</li>
</ul>

<h4>I can't add someone to my group—why?</h4>
<p>Only the group owner can invite new members.</p>

<h4>How can I chat in a group?</h4>
<p>Group chats can be initiated from the chat screen. However, there is no shortcut to start a chat directly with a SparkSinger group.</p>

<h3>Joining or Leaving a Group</h3>
<h4>How do I join a group?</h4>
<ol>
  <li>Navigate to the group page you want to join.</li>
  <li>If the group is open to new members, tap the “Join” button.</li>
  <li>For closed groups, tap “Request to Join.”</li>
</ol>

<h4>How do I leave a group?</h4>
<ol>
  <li>Go to the group page.</li>
  <li>Tap the “…” in the top-right corner.</li>
  <li>Select “Leave Group.”</li>
</ol>

<h4>How many groups can I join?</h4>
<p>You can join up to 250 groups.</p>

<h4>Why can I only create three groups?</h4>
<p>Managing a group requires significant effort. To ensure group owners can dedicate sufficient time to their groups, the number of groups an individual can create is limited to three.</p>

<h4>What happens when I’m invited to a group?</h4>
<ul>
  <li>You can accept, reject, or ignore the invitation.</li>
  <li>Invitations expire after 30 days.</li>
  <li>You can respond to invitations from your profile page, notifications, or the group’s page.</li>
</ul>

<h4>How/where can I see a list of groups I own or have joined?</h4>
<ul>
  <li>On your profile page, tap “Groups.”</li>
  <li>This section displays the groups you’re in, pending requests, and invites.</li>
</ul>

<h4>How/where can I find new groups to join?</h4>
<ul>
  <li>Check the Explore tab or browse other users’ profile pages for group suggestions.</li>
</ul>

<h4>How do I audition for a group?</h4>
<p>Audition processes vary by group. Contact the group owner for specific instructions.</p>
    </Box> ,
    2: 
    <Box sx={{ padding: 3, maxWidth: 800, margin: '0 auto',textAlign:'start',color:"#743CED"}}>
   <h1>How to Manage a SparkSinger Subscription</h1>

<h4>Managing Your Subscription via Apple</h4>
<p>To cancel a subscription through Apple:</p>
<ol>
  <li>Open the Settings app.</li>
  <li>Tap your name.</li>
  <li>Select <strong>Subscriptions</strong>.</li>
  <li>Tap the subscription you wish to manage.</li>
  <li>Tap <strong>Cancel Subscription</strong>.</li>
</ol>
<p>For a visual guide, view this video.</p>
<p>If you purchased your subscription via the Play Store or SparkSinger website, please refer to the respective article and video for those platforms.</p>
<p><strong>Note:</strong> Subscriptions purchased through Apple or Google Play do not appear in your SparkSinger profile and can only be managed through those respective accounts.</p>
<p>If you have questions, you can reach out to support by clicking on <a href="mailto:support@sparksinger.com">Submit a Request</a>.</p>

<h4>Managing Your Subscription via Google Play</h4>
<p>To cancel your subscription through Google Play:</p>
<ol>
  <li>Open the Google Play Store app.</li>
  <li>Sign in to the correct Google Account by tapping your profile picture/icon in the top right corner.</li>
  <li>Select <strong>Payments and subscriptions</strong>.</li>
  <li>Tap <strong>Subscriptions</strong>.</li>
  <li>Choose the subscription to cancel.</li>
  <li>Tap <strong>Cancel Subscription</strong>.</li>
</ol>
<p>For a visual guide, view this video.</p>
<p>If you purchased your subscription via the Play Store or SparkSinger website, refer to the respective article and video for those platforms.</p>

<h4>Managing Your Subscription via SparkSinger Website</h4>
<p>To cancel a subscription purchased on the SparkSinger website:</p>
<ol>
  <li>Visit <a href="https://www.sparksinger.com">www.sparksinger.com</a>.</li>
  <li>Log into your account.</li>
  <li>Click on your username in the top right corner and select <strong>Account</strong>.</li>
  <li>In Account Settings, locate the Subscription section and click <strong>Cancel</strong>.</li>
  <li>In the confirmation box, click <strong>Cancel Subscription</strong> to complete the cancellation.</li>
</ol>
<p>For a visual guide, view this video.</p>
<p><strong>Note:</strong> Subscriptions purchased through iTunes/Google Play won’t show in your SparkSinger profile and must be managed on those respective platforms.</p>

<h4>Apple (iOS) via iTunes</h4>
<ol>
  <li>Open the Settings app.</li>
  <li>Find and select <strong>iTunes & App Store</strong>.</li>
  <li>Tap your Apple ID.</li>
  <li>Tap <strong>View Apple ID</strong> (log in if required).</li>
  <li>Scroll down and tap <strong>Subscriptions</strong>.</li>
  <li>Select <strong>SparkSinger</strong>.</li>
  <li>Tap <strong>Cancel Subscription</strong>.</li>
  <li>Confirm your choice in the pop-up box.</li>
</ol>
<p>For a visual guide, watch this video.</p>

<h4>Android via Google Play Store</h4>
<ol>
  <li>Open the Google Play Store app.</li>
  <li>Sign in to the correct Google Account.</li>
  <li>Tap the Menu button (three horizontal lines) or your profile icon.</li>
  <li>Select <strong>Subscriptions</strong>.</li>
  <li>Choose <strong>SparkSinger</strong>.</li>
  <li>Tap <strong>Cancel Subscription</strong>.</li>
  <li>Follow the on-screen instructions.</li>
</ol>
<p>For a visual guide, watch this video.</p>

<h3>How to Request a Refund</h3>

<h4>Refund Policies</h4>
<p>Subscriptions are generally non-refundable, with no partial refunds for unused periods. However, for subscriptions purchased via Google Play or the SparkSinger website, a refund may be available if requested within 14 days of the transaction date and if the VIP service has not been used. Subscriptions bought through iTunes cannot be refunded by SparkSinger.</p>

<h4>Refunds for iTunes Subscriptions (iOS)</h4>
<p>While SparkSinger cannot process iTunes refunds, you can contact Apple Support for a refund request. To start, follow the instructions here.</p>

<h4>Refunds for Google Play Subscriptions (Android)</h4>
<p>If the charge occurred within the past 48 hours, you can request a refund directly from Google by following their instructions. If more than 48 hours and less than seven days have passed, contact SparkSinger support with your Google Play order number (e.g., GPA.1234-5678-9012-34567) and your SparkSinger account details.</p>

<h4>Refunds for Website Subscriptions</h4>
<p>If you purchased a subscription through the SparkSinger website within the last 14 days, contact <a href="mailto:support@sparksinger.com">support@sparksinger.com</a> with your Transaction ID, which starts with "112" and is included in your purchase email receipt. If the Transaction ID is unavailable, please provide additional details, such as card details (last four digits, date, and amount) or PayPal ID details (Subscription/Profile ID or Transaction ID, date, and amount).</p>

<p>Feel free to contact support for any questions!</p>
  </Box>,
  3: <Box sx={{ padding: 3, maxWidth: 800, margin: '0 auto',textAlign:'start',color:"#743CED" }}>
     <h1>Perks of Being PREMIUM</h1>

<h4>How to Subscribe</h4>
<p>Some of the best PREMIUM offers can be found on our official website, <a href="https://www.sparksinger.com" target="_blank">sparksinger.com</a>. Subscribing through the website ensures that your PREMIUM benefits apply across all of your devices. You can use the following link to go directly to the PREMIUM purchasing section on our website:</p>
<p><a href="https://www.sparksinger.com" target="_blank">https://www.sparksinger.com/</a></p>

<p>You can also purchase a subscription from Google Play, Apple, and Huawei:</p>
<ul>
  <li><strong>Google Play:</strong> Subscriptions are valid on Android devices only.</li>
  <li><strong>Apple App Store:</strong> Subscriptions are valid on iOS devices only.</li>
</ul>

<h4>Perks You Gain as a PREMIUM User</h4>
<p>Why should I subscribe?</p>
<p>As a PREMIUM member on SparkSinger, you unlock the following exclusive benefits:</p>
<ul>
  <li>A third-party ad-free experience (you may still see SparkSinger ads).</li>
  <li>Access to a global songbook with over 2 million songs (with new songs added daily!).</li>
  <li>The ability to start any song and enable other singers to join your collab!</li>
  <li>PREMIUM FX to enhance your vocals and videos.</li>
  <li>No archive limit on your profile.</li>
  <li>Profile customization options.</li>
</ul>

<h4>How to Customize Your Profile</h4>
<p>What types of customization can I make to my profile as a PREMIUM?</p>
<p>As a PREMIUM member, you have the option to fully personalize your profile. This includes:</p>
<ul>
  <li>Adding a cover photo.</li>
  <li>Choosing a display name.</li>
  <li>Selecting a colour theme.</li>
  <li>Pinning a recording to your profile.</li>
  <li>Displaying @mentions as avatars.</li>
</ul>

<h4>Ads</h4>
<p><strong>Why am I seeing ads in the app?</strong></p>
<p>Third-party ads help us support the costs of running SparkSinger, including royalties for artists. While free accounts cover these costs through ads, it enables us to continue supporting artists and maintaining the platform.</p>

<p><strong>Can I remove ads?</strong></p>
<p>To enjoy an ad-free experience, please subscribe to SparkSinger PREMIUM. If you are a PREMIUM subscriber and are still seeing third-party ads, please contact SparkSinger support.</p>

<p><strong>What about SparkSinger ads?</strong></p>
<p>You will still see SparkSinger ads in both free and PREMIUM accounts. These ads help us promote new features and content for our users.</p>
    </Box>,
    4:
    <Box sx={{ padding: 3, maxWidth: 800, margin: '0 auto',textAlign:'start',color:"#743CED" }}>
      
    <h1>SparkSinger Profile Management</h1>

<section>
    <h2>Profile Storage FAQ</h2>
    <h3>Why is SparkSinger making changes to its content storage?</h3>
    <p>We’re honored and grateful for the incredible SparkSinger community that has been singing with us for 15 years. Over this time, billions of recordings have been created, putting significant pressure on our storage capacity. To address this, we’re implementing changes to free up space for new recordings and ensure the sustainability of our platform. These updates will allow us to continue investing in new technology and enhancing your SparkSinger experience.</p>

    <h3>When will these changes take effect?</h3>
    <p>We’ll begin our storage cleanup process in mid-January 2024. As this is a technical and time-intensive project, it will take several months to complete.</p>

    <h3>Who will be affected by these changes?</h3>
    <p>Our team has established well-defined criteria, with the goal of limiting disruption to our active SparkSinger community members. The changes will primarily impact free users who have been inactive for over a year. Recordings of current PREMIUM users will remain unaffected, regardless of their recent activity status.</p>

    <h3>What type of content will be removed?</h3>
    <p>We’ll remove  that haven’t been listened to or joined by any SparkSinger users in the past year. At this stage, only solo and duet recordings are being considered for removal, while group recordings will remain unaffected.</p>

    <h3>How can non-PREMIUM users preserve their recordings?</h3>
    <p>To save your recordings, consider upgrading to PREMIUM before January 2024. As a PREMIUM, you’ll not only safeguard your content but also enjoy premium features, including:</p>
    <ul>
        <li>Unlimited solos</li>
        <li>The ability to start duets and group recordings</li>
        <li>Full songbook access</li>
        <li>Ad-free experience</li>
        <li>Customizable profiles</li>
    </ul>
    <p>If upgrading to PREMIUM isn’t an option, logging into your account and participating actively can help preserve your recordings.</p>

    <h3>How can I save the recordings of someone special who has passed away?</h3>
    <p>We understand the emotional value of these recordings and want to support their preservation. Unfortunately, we cannot proactively save these performances as we have no way of verifying ownership or account status. To preserve them, we recommend actively engaging with these recordings by listening, liking, commenting, or joining them.</p>

    <p>For more details, visit our blog post on content storage changes. If you have further questions, feel free to contact us at support@sparksinger.com.</p>
</section>

<section>
    <h2>Account Management</h2>
    <h3>How do I change the email address associated with my account?</h3>
    <p>You can update your email address by visiting the “Settings” screen in both the SparkSinger app and on sparksinger.com. This change will not sign you out of your account in the app. However, moving forward, you will need to use your updated email address to sign in to your SparkSinger account.</p>

    <h3>How do I change the phone number associated with my account?</h3>
    <p>At this time, there is no direct option to change the phone number linked to your account. We recommend adding alternative sign-in methods, such as Facebook, Google, or email, to simplify account recovery. If you wish to unlink the current phone number, you can do so, which will allow you to add a new one. For further assistance, please contact our Support team at support@sparksinger.com.</p>

    <h3>Can I change or unlink my Facebook account from my SparkSinger account?</h3>
    <p>Yes, you can. Please reach out to Sparksinger support at support@sparksinger.com and include your SparkSinger username in your email.</p>

    <h3>How do I sign out of my account in the Sparksinger app?</h3>
    <p>Currently, there is a sign-out option within the app.</p>
</section>

<section>
    <h2>Password Issues and Questions</h2>
    <h3>How do I reset my password?</h3>
    <p>Use our direct password reset link: <a href="https://sparksinger.com/user/password">https://sparksinger.com/user/password</a>. If the link doesn’t resolve the issue, please contact SparkSinger support at support@sparksinger.com, and we’ll gladly assist you.</p>

    <h3>Why am I receiving an error when trying to reset my password?</h3>
    <p>If you created your account using Facebook, Google, or an Apple account, your account is passwordless. This means you don’t need a password to log in. Simply use your original sign-in method to access your account.</p>

    <h3>What to Do If You Log Into the Wrong Account</h3>
    <p>If you previously created an account using an email address but later logged in through another method (e.g., Facebook), you might find yourself in a different account than the one you originally created. Additionally, if you attempt to log in with an email address that has never been registered on Sparksinger, the system will automatically create a new account.</p>
    <p>Steps to Access the Correct Account:</p>
    <ul>
        <li>Both on sparksinger.com and SparkSinger App:
            <ul>
                <li>Sign out of the current account.</li>
                <li>Sign back in using the correct email address or login method.</li>
            </ul>
        </li>
    </ul>
    <p>If you need additional assistance, please contact support@sparksinger.com.</p>
</section>

<section>
    <h2>Account Deletion</h2>
    <h3>What happens if I delete my account?</h3>
    <ul>
        <li>You will lose all your related content, including your performances (except for any collaborations with other users' group performances), comments on performances, followers, likes, gifts, and groups.</li>
        <li>You will no longer have the opportunity to connect with other talented SparkSinger users or make friends from all over the world.</li>
    </ul>

    <h3>Can I cancel my account deletion?</h3>
    <p>Account deletion will occur within 7 to 28 days after your request. If you wish to reactivate your account after requesting deletion, please contact us at support@sparksinger.com as soon as possible. Reactivation can only be requested while your account is still in the deletion process. Once your account is deleted, it cannot be restored.</p>
    <p>Please note that deleting your account is irreversible, and all your content, including recordings made by others using arrangements you've uploaded, will be permanently removed. Additionally, requesting account deletion will not cancel any PREMIUM subscription. You must manage your subscription separately via your Apple subscriptions. For instructions on how to do so, visit: Apple Support.</p>

    <h3>How Do I Delete My Account (Android)?</h3>
    <p>As an Android user on Sparksinger, you can delete your account by submitting a deletion request. Here's how:</p>
    <ol>
        <li>Go to: Sparksinger Support Request</li>
        <li>Choose the "Remove Sparksinger Profile (Account Deletion)" option when submitting the request and select a reason from the drop-down menu.</li>
        <li>After submitting your request, you will receive an email with the subject line "Please confirm that you'd like to delete your Sparksinger account." Reply to this email saying "I confirm."</li>
    </ol>
    <p>Deletion will take place 30 days after your submission. If you want to reactivate your account after requesting deletion, contact us at support@sparksinger.com as soon as possible. Reactivation is only possible if your account is still in the deletion process. Once deleted, a Sparksinger account cannot be restored.</p>

    <h3>How Do I Delete My Account (iOS)?</h3>
    <p>As an iOS user of Sparksinger, you can follow the steps below to initiate the account deletion process:</p>
    <ul>
        <li>Open Settings</li>
        <li>Go to the Support section</li>
        <li>Tap on Delete Account</li>
    </ul>
    <p>Deletion will take place 30 days after your submission. If you would like to have your account reactivated after requesting deletion, contact us at support@sparksinger.com as soon as possible. Reactivation can only be requested if your account is still in the process of deletion. Once deleted, a Sparksinger account cannot be restored.</p>
</section>
      </Box>,
      5:<Box sx={{ padding: 3, maxWidth: 800, margin: '0 auto',textAlign:'start',color:"#743CED" }}><h1>Safety on the App, Reporting, Copyright, Legal, etc.</h1>

      <h4>Safety Tips</h4>
      
      <h5>How to Report an Account from the Chat</h5>
      <p>Safety on SparkSinger is a top priority, and we are continually working to enhance the user experience. If you encounter inappropriate messages or behavior in your Direct Messages/Chat, you can report the sender by following these steps:</p>
      <ol>
        <li>Open the chat with the specific user and tap the information icon in the top right corner.</li>
        <li>Select the "Report User" option and choose a reason for the report.</li>
        <li>After selecting the reason, you can provide additional details in the text box to help us better understand the situation and context of your report.</li>
        <li>You may also attach screenshots to your email when submitting the report. This will help us investigate the account further and take appropriate action if necessary.</li>
      </ol>
      <p>Our team will review the report and take the necessary steps to ensure a safe and positive environment for everyone.</p>
      
      <h5>About the SparkSinger App</h5>
      <p>All of SparkSinger's apps (SparkSinger, Autorap, Magic Piano) are free to download and can be accessed by anyone. However, due to safety guidelines, the SparkSinger apps can only be used by users who are at least 13 years old if they reside in the United States, and at least 16 years old if they reside in the European Economic Area (EEA). For users under the required age, Parental Consent is mandatory to use the app.</p>
      <p>For more detailed information, please refer to our <a href="#" target="_blank">Terms of Service</a>.</p>
      
      <h5>How to Manage Your Privacy on SparkSinger</h5>
      <p>We are committed to ensuring your safety while using SparkSinger, and we want you to enjoy the app without any negative disruptions. Here are some tips to help you manage your privacy and stay safe:</p>
      <ul>
        <li><strong>Unfollow or Block Users</strong>If someone is using your personal information, such as pictures or other details, you can unfollow or block them. To unfollow, simply visit their profile. To block, open the user's profile and tap the "..." option below the "Follow" button. From there, you’ll be able to select the option to block the user. If necessary, you can also report the user to us so we can take appropriate action.</li>
        <li><strong>Account Privacy</strong>While there is no way to make your SparkSinger account entirely private, you can manage the privacy of your recordings. This can be done by adjusting the privacy settings in the "Edit" section within the app or on the SparkSinger website.</li>
        <li><strong>Report Bullying or Harassment</strong>If you are being bullied or harassed, report the account immediately or contact us at <a href="mailto:support@SparkSinger.com">support@SparkSinger.com</a>. We may need additional information and evidence to take appropriate action. In the meantime, we recommend blocking the account to avoid further communication.</li>
        <li><strong>Trust Your Comfort Zone</strong>Never agree to share or do anything that makes you feel uncomfortable. Always prioritize your safety and well-being.</li>
        <li><strong>Report Scam Accounts</strong>If you come across a scam account, report it immediately. We actively remove content that deceives, misrepresents, or exploits users for money or other personal gain.</li>
      </ul>
      <p>By following these steps, you can help maintain your privacy and enjoy a safer experience on SparkSinger.</p>
      
      <h5>What to Do When You See Something You Don't Like on SparkSinger</h5>
      <p>If you encounter content or behavior that you find inappropriate or upsetting on SparkSinger, here are the steps you can take:</p>
      <ol>
        <li><strong>Report it</strong> – If the content violates our Community Guidelines, you can report it directly from within the app. This helps us review the situation and take appropriate action.</li>
        <li><strong>Unfollow the Person</strong> – If you no longer wish to see the content from a specific user, you can unfollow them. This ensures their updates won’t appear in your feed.</li>
        <li><strong>Block the Person</strong> – As a final step, you can block the user. Blocking prevents any further interaction with them and ensures that they cannot contact you or view your content.</li>
      </ol>
      <p>These steps help you maintain control over your experience on SparkSinger and ensure a positive and safe environment for all users.</p>
      
      <h5>How to Avoid Conflicts and Abusive Behavior on SparkSinger</h5>
      <p>We understand that online interactions can sometimes become difficult, and we are committed to helping you navigate such situations in a safe and positive way. Here are some tips to avoid conflicts and handle abusive behavior:</p>
      <ul>
        <li><strong>Contact Us First</strong>If you encounter any issues or conflicts, please contact us directly so we can assist you before taking any actions that could lead to uncomfortable situations. We're here to help resolve matters smoothly.</li>
        <li><strong>Understand the Context</strong>If you come across content that upsets you, make sure you understand the context behind it. Remember, all users on SparkSinger have the freedom of expression and can share their passions and interests. Review the full post or the user's profile to get the complete picture. If the content violates our rules or guidelines, you can report it, and we'll investigate the situation.</li>
        <li><strong>Block Unwanted Communication</strong>If you're receiving unwanted communication from another user, consider blocking them to stop any further interaction. Blocking will prevent the person from following you or searching for your profile, ensuring your space remains safe.</li>
        <li><strong>Ask for Help</strong>If a conflict escalates, don't hesitate to contact us and share more details about the situation. We'll take the necessary actions and assist you in addressing the issue.</li>
      </ul>
      <p>By following these steps, you can help create a positive environment for yourself and other users on SparkSinger. We're here to support you every step of the way!</p>
      
      <h5>Freedom of Speech on SparkSinger</h5>
      <p>SparkSinger is designed for users to sing together, have fun, and express themselves creatively. We support the freedom of expression for all our users, as long as it does not harm or hurt others. Our goal is to foster a positive and safe environment where everyone can feel comfortable sharing their thoughts and passions.</p>
      <ul>
        <li><strong>Respect for Others</strong>While we encourage self-expression, we do not condone any behavior that negatively impacts others. Please be mindful that your words and actions should not cause harm or distress to other users.</li>
        <li><strong>Religious and Political Views</strong>You are welcome to discuss religious and political views, as long as these discussions remain respectful and do not escalate into conflicts. We encourage healthy, open-minded conversations, but we also expect everyone to respect differing opinions. If you find someone who shares your views, feel free to connect with them and engage in meaningful discussions.</li>
        <li><strong>Personal Responsibility</strong>Please remember that you are responsible for what you say and share on the platform. Words have power, and they can be hurtful if not used thoughtfully. Always express yourself in a way that is respectful and considerate of others.</li>
      </ul>
      <p>By maintaining a respectful tone and being aware of how your words might affect others, we can all contribute to creating a safe and supportive community on SparkSinger.</p>  
      <h3>How to Report an Account on SparkSinger</h3>
<p>At SparkSinger, we strive to create a fun and safe environment for everyone. If you ever find yourself in a situation where you feel unsafe or uncomfortable, we want to help. Here's how you can report an account:</p>
<ol>
  <li><strong>Visit the Person's Account</strong>Go to the user's profile that you want to report and tap on the three dots in the top right corner.</li>
  <li><strong>Choose a Reason for Reporting</strong>After tapping the three dots, you'll be given the option to select a reason for reporting the user.</li>
  <li><strong>Provide Additional Details</strong>Once you've chosen a reason, you can provide more context by entering additional details in the text box. This will help us better understand the situation and assist you accordingly.</li>
  <li><strong>Attach Screenshots (Optional)</strong>You may also attach screenshots from your email to support your report. This will help us investigate the issue further and take necessary action.</li>
</ol>
<p>Your safety and well-being are important to us, and we'll review the report as quickly as possible to address the situation.</p>

<h3>How Blocking Works on SparkSinger</h3>
<p>Blocking is a simple way to control your interactions with other users on SparkSinger. Here’s how it works using you and account "B" as an example:</p>
<ol>
  <li><strong>After Blocking "B":</strong>
    <ul>
      <li>You will no longer see "B" or their content in the app or on the SparkSinger website (as long as you are logged into the account where you blocked them).</li>
      <li>Your profile will be inaccessible to "B", and vice versa. You will also be unable to chat with each other.</li>
    </ul>
  </li>
  <li><strong>Notifications and Feed:</strong>
    <ul>
      <li>"B" will not appear in your feed or notifications.</li>
      <li>You will not receive any invites from "B", and you will not be able to follow each other.</li>
    </ul>
  </li>
  <li><strong>Performances:</strong>
    <ul>
      <li>"B" will not be able to see, comment on, or "love" any of your solo, duet, or group performances.</li>
      <li>However, "B" can still interact normally with any Group collaborations you joined (but did not create).</li>
    </ul>
  </li>
</ol>
<p>Blocking helps you maintain a positive experience on SparkSinger by giving you control over who can interact with you.</p>

<h3>Scam Accounts and How to Protect Yourself</h3>
<p>Scammers are individuals who create fraudulent accounts with the intent of tricking people into sharing personal information, passwords, or credit card numbers. They often do this through fraudulent emails, messages, or websites that may appear legitimate, such as those of a bank, email provider, or social media platform. We’ve gathered a few tips on how to deal with scammers:</p>
<ul>
  <li>Scammers often create fake situations to panic you, trying to frighten you into making hasty decisions so they can manipulate you. We strongly recommend taking your time to verify the facts and slow down to regain control of the situation.</li>
  <li>Before taking any action or following any suggestions they give, carefully check the links they provide and verify the information.</li>
  <li>Never feel obligated to provide personal information to suspicious accounts. Keep in mind that no reputable organization will demand payment immediately.</li>
  <li>Avoid clicking on suspicious links, and never download files or software from people you don’t know.</li>
  <li>Do not respond to, and report, messages asking for sensitive information such as passwords, social security numbers, or financial details.</li>
</ul>

<h4>How to Recognize a Scammer:</h4>
<ul>
  <li>Accounts from people you don’t know that seem suspicious.</li>
  <li>Famous people or their supposed managers asking you for money.</li>
  <li>Requests for advance fees to receive a loan or prize.</li>
  <li>Claims of winning a prize or award that seems too good to be true.</li>
  <li>Messages from people pretending to be friends or relatives in an emergency.</li>
  <li>Requests to move the conversation off the platform.</li>
  <li>Individuals quickly claiming to want a romantic connection, followed by requests for money.</li>
  <li>Messages or posts with poor spelling and grammar.</li>
  <li>Urgent messages about something being wrong with your online account.</li>
  <li>A message asking you to log in to your social media, email, or bank account to read an important message about your services.</li>
  <li>Accounts impersonating large companies, organizations, or public figures that are verified.</li>
  <li>People claiming to be from SparkSinger security, asking for your account details (such as username or password), or offering account verification services.</li>
  <li>Accounts with no followers, profile photos, or realistic activity on SparkSinger.</li>
</ul>

<h4>Common Scam Schemes:</h4>
<ul>
  <li><strong>Investment Scams:</strong> These may include "cash flipping" scams, Ponzi schemes, or "get rich quick" schemes.</li>
  <li><strong>Romance Scams:</strong> Scammers in this category often pretend to be divorced, widowed, or in a bad marriage and claim they are seeking a relationship. They may build trust over weeks or months before asking for money.</li>
  <li><strong>Lottery Scams:</strong> Scammers may pretend to be a popular organization offering prizes or large sums of money in exchange for your personal information.</li>
  <li><strong>Commerce Scams:</strong> These scammers may claim to be selling goods or services online at too-good-to-be-true prices and may try to convince you to move the conversation to other communication channels like email or chat apps.</li>
</ul>

<h3>Copyright and DMCA Takedown Infringement</h3>
<p>If you believe that any content made available on or through the Service has been used or exploited in a manner that infringes an intellectual property right you own or control, please promptly send a "Notification of Claimed Infringement" containing the following information to the Designated Agent identified below. Your communication must include the following:</p>
<ol>
  <li>A physical or electronic signature of a person authorized to act on behalf of the owner of the work(s) that have allegedly been infringed.</li>
  <li>Identification of the works or materials claimed to have been infringed, or, if multiple works are covered by a single notification, a representative list of those works.</li>
  <li>Identification of the specific material that is claimed to be infringing or the subject of infringing activity, and information reasonably sufficient for SparkSinger to locate the material.</li>
  <li>Information reasonably sufficient to permit SparkSinger to contact you, such as an address, telephone number, and, if available, an email address where the complaining party can be reached.</li>
  <li>A statement that you have a good faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.</li>
  <li>A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
</ol>
<p>You should consult with your lawyer and/or refer to 17 U.S.C. § 512 of the DMCA to confirm your obligations for providing a valid notice of claimed infringement.</p>

<h4>Copyright Contact Information</h4>
<p>Send written notices for claimed infringement by email or postal mail to:</p>
<p><strong>Address:</strong>
10601 Clarence Dr. Suite 250
Frisco TX, USA 75033</p>
<h3>How to Report an Account on SparkSinger</h3>
<p>At SparkSinger, we strive to create a fun and safe environment for everyone. If you ever find yourself in a situation where you feel unsafe or uncomfortable, we want to help. Here's how you can report an account:</p>
<ol>
  <li><strong>Visit the Person's Account</strong>Go to the user's profile that you want to report and tap on the three dots in the top right corner.</li>
  <li><strong>Choose a Reason for Reporting</strong>After tapping the three dots, you'll be given the option to select a reason for reporting the user.</li>
  <li><strong>Provide Additional Details</strong>Once you've chosen a reason, you can provide more context by entering additional details in the text box. This will help us better understand the situation and assist you accordingly.</li>
  <li><strong>Attach Screenshots (Optional)</strong>You may also attach screenshots from your email to support your report. This will help us investigate the issue further and take necessary action.</li>
</ol>
<p>Your safety and well-being are important to us, and we'll review the report as quickly as possible to address the situation.</p>

<h3>How Blocking Works on SparkSinger</h3>
<p>Blocking is a simple way to control your interactions with other users on SparkSinger. Here’s how it works using you and account "B" as an example:</p>
<ol>
  <li><strong>After Blocking "B":</strong>
    <ul>
      <li>You will no longer see "B" or their content in the app or on the SparkSinger website (as long as you are logged into the account where you blocked them).</li>
      <li>Your profile will be inaccessible to "B", and vice versa. You will also be unable to chat with each other.</li>
    </ul>
  </li>
  <li><strong>Notifications and Feed:</strong>
    <ul>
      <li>"B" will not appear in your feed or notifications.</li>
      <li>You will not receive any invites from "B", and you will not be able to follow each other.</li>
    </ul>
  </li>
  <li><strong>Performances:</strong>
    <ul>
      <li>"B" will not be able to see, comment on, or "love" any of your solo, duet, or group performances.</li>
      <li>However, "B" can still interact normally with any Group collaborations you joined (but did not create).</li>
    </ul>
  </li>
</ol>
<p>Blocking helps you maintain a positive experience on SparkSinger by giving you control over who can interact with you.</p>

<h3>Scam Accounts and How to Protect Yourself</h3>
<p>Scammers are individuals who create fraudulent accounts with the intent of tricking people into sharing personal information, passwords, or credit card numbers. They often do this through fraudulent emails, messages, or websites that may appear legitimate, such as those of a bank, email provider, or social media platform. We’ve gathered a few tips on how to deal with scammers:</p>
<ul>
  <li>Scammers often create fake situations to panic you, trying to frighten you into making hasty decisions so they can manipulate you. We strongly recommend taking your time to verify the facts and slow down to regain control of the situation.</li>
  <li>Before taking any action or following any suggestions they give, carefully check the links they provide and verify the information.</li>
  <li>Never feel obligated to provide personal information to suspicious accounts. Keep in mind that no reputable organization will demand payment immediately.</li>
  <li>Avoid clicking on suspicious links, and never download files or software from people you don’t know.</li>
  <li>Do not respond to, and report, messages asking for sensitive information such as passwords, social security numbers, or financial details.</li>
</ul>

<h4>How to Recognize a Scammer:</h4>
<ul>
  <li>Accounts from people you don’t know that seem suspicious.</li>
  <li>Famous people or their supposed managers asking you for money.</li>
  <li>Requests for advance fees to receive a loan or prize.</li>
  <li>Claims of winning a prize or award that seems too good to be true.</li>
  <li>Messages from people pretending to be friends or relatives in an emergency.</li>
  <li>Requests to move the conversation off the platform.</li>
  <li>Individuals quickly claiming to want a romantic connection, followed by requests for money.</li>
  <li>Messages or posts with poor spelling and grammar.</li>
  <li>Urgent messages about something being wrong with your online account.</li>
  <li>A message asking you to log in to your social media, email, or bank account to read an important message about your services.</li>
  <li>Accounts impersonating large companies, organizations, or public figures that are verified.</li>
  <li>People claiming to be from SparkSinger security, asking for your account details (such as username or password), or offering account verification services.</li>
  <li>Accounts with no followers, profile photos, or realistic activity on SparkSinger.</li>
</ul>

<h4>Common Scam Schemes:</h4>
<ul>
  <li><strong>Investment Scams:</strong> These may include "cash flipping" scams, Ponzi schemes, or "get rich quick" schemes.</li>
  <li><strong>Romance Scams:</strong> Scammers in this category often pretend to be divorced, widowed, or in a bad marriage and claim they are seeking a relationship. They may build trust over weeks or months before asking for money.</li>
  <li><strong>Lottery Scams:</strong> Scammers may pretend to be a popular organization offering prizes or large sums of money in exchange for your personal information.</li>
  <li><strong>Commerce Scams:</strong> These scammers may claim to be selling goods or services online at too-good-to-be-true prices and may try to convince you to move the conversation to other communication channels like email or chat apps.</li>
</ul>

<h3>Copyright and DMCA Takedown Infringement</h3>
<p>If you believe that any content made available on or through the Service has been used or exploited in a manner that infringes an intellectual property right you own or control, please promptly send a "Notification of Claimed Infringement" containing the following information to the Designated Agent identified below. Your communication must include the following:</p>
<ol>
  <li>A physical or electronic signature of a person authorized to act on behalf of the owner of the work(s) that have allegedly been infringed.</li>
  <li>Identification of the works or materials claimed to have been infringed, or, if multiple works are covered by a single notification, a representative list of those works.</li>
  <li>Identification of the specific material that is claimed to be infringing or the subject of infringing activity, and information reasonably sufficient for SparkSinger to locate the material.</li>
  <li>Information reasonably sufficient to permit SparkSinger to contact you, such as an address, telephone number, and, if available, an email address where the complaining party can be reached.</li>
  <li>A statement that you have a good faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.</li>
  <li>A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
</ol>
<p>You should consult with your lawyer and/or refer to 17 U.S.C. § 512 of the DMCA to confirm your obligations for providing a valid notice of claimed infringement.</p>

<h4>Copyright Contact Information</h4>
<p>Send written notices for claimed infringement by email or postal mail to:</p>
<p><strong>Address:</strong>
10601 Clarence Dr. Suite 250
Frisco TX, USA 75033</p>
<h3>5. Preservation Requests</h3>
<p>SparkSinger will honor formal requests to preserve user data for 90 days. SparkSinger will preserve information for an additional 90-day period upon receipt of a formal request to extend the preservation. If SparkSinger does not receive formal legal process for the preserved information before the end of the preservation period, the preserved information may be deleted when the preservation period expires.</p>
<p>Preservation requests must follow the process outlined in Sections 1 and 3. Each request must be signed, dated, and prepared on law enforcement letterhead. The request must clearly identify the specific user data to be preserved by referencing the username, the data type, and the date range, as detailed in Section 3. A hard copy must be sent to the physical address, and a PDF or similar version of the same must be submitted through the web form, as stated in Section 1.</p>
<p>Overly broad or unspecific preservation requests will not be actioned. To seek disclosure of preserved data, the requirements set out in Sections 1 and 3 of these guidelines will apply.</p>

<h3>6. Emergency Requests</h3>
<p>SparkSinger has processes in place to handle emergency requests. Such requests are evaluated on a case-by-case basis.</p>
<p>If, as part of an emergency request, we receive information that is sufficient in our assessment to establish a good faith belief that there is an emergency involving imminent harm, the risk of death, or serious physical injury to a person or persons, we may provide user data necessary to prevent that harm, as permitted by applicable law.</p>
<p>To expedite an emergency request, it must be prepared according to Sections 1 and 3. The request must be signed, dated, and prepared on law enforcement letterhead, and a PDF or similar version must be submitted through our web form:</p>
<p><a href="https://SparkSinger.zendesk.com/hc/en-us/requests/new?ticket_form_id=360000113426">SparkSinger Emergency Request Web Form</a></p>
<p>All emergency requests must be made by a sworn law enforcement official and originate from an official law enforcement email domain.</p>
<p>Non-law enforcement personnel who are aware of an emergency situation should immediately and directly contact local law enforcement and/or emergency officials.</p>

<h3>7. Other</h3>
<p>SparkSinger may request that a law enforcement authority submit a request for legal assistance to designated government authorities under the Mutual Legal Assistance Treaty (“MLAT”) framework or via letters rogatory. This may occur when the requesting law enforcement authority is located in a different country than the SparkSinger entity that provides the service to the relevant user whose data is requested (refer to Section 1).</p>
<p>When submitting a request, law enforcement officials are required to ensure that any information provided is complete and accurate to the best of their knowledge and good faith belief, and that the disclosure (or other action) requested is necessary and proportionate for the purposes of preventing, detecting, or investigating offenses, or to prevent an emergency. Once received by law enforcement, the requesting authority is required to handle such data in accordance with applicable laws, including relevant data protection laws.</p>
<p>SparkSinger reserves the right to seek reimbursement for the costs associated with responding to law enforcement requests.</p>
<p>SparkSinger may update or replace these guidelines at its sole discretion and without notice to any party.</p>
     </Box>,
   6:<Box sx={{ padding: 3, maxWidth: 800, margin: '0 auto',textAlign:'start',color:"#743CED" }}>  <h1>Technical Issues Guide</h1>

   <h2>Audio Issues</h2>
   <h3>Robot Voice, Crackling Noises, and Interruptions During Performances</h3>
   <p>If you're experiencing these issues, please contact our Support team directly and provide a link to the affected performance. They will assist you further.</p>

   <h3>How to Turn Off Google Voice Match</h3>
   <p>If you're using an Android device and encountering issues recording vocals during performances, you may want to disable the Google Voice Match function. Here's how:</p>
   <ol>
       <li>Open the Google app.</li>
       <li>Tap your Profile Picture.</li>
       <li>Select <strong>Settings</strong>.</li>
       <li>Choose <strong>Google Assistant</strong>.</li>
       <li>Tap <strong>Hey Google & Voice Match</strong>.</li>
       <li>Disable the <strong>Hey Google</strong> toggle.</li>
       <li>You're all set! Start recording again.</li>
   </ol>
   <p><em>Note:</em> This is a temporary workaround. We are actively working on a long-term solution to ensure that the app records your voice seamlessly even when the Voice Match feature is enabled.</p>

   <h2>How to Fix Sync Issues on Android</h2>
   <ol>
       <li><strong>Adjusting the Slider</strong>
           <ul>
               <li>Since Android devices vary in latency (lag), the Vocal Match Slider allows adjustments of a few hundred milliseconds in either direction.</li>
               <li>If your voice appears too slow, adjust the slider to move your voice earlier.</li>
               <li>Continuously check the recorded audio by pressing play and fine-tune the slider until the recording is synced.</li>
               <li>Once you're satisfied, click the <strong>Continue</strong> button to proceed with the upload.</li>
           </ul>
       </li>
       <li><strong>Avoid Bluetooth Headphones</strong>
           <ul>
               <li>Bluetooth headphones can introduce latency, causing sync issues. Use wired headphones for better results.</li>
           </ul>
       </li>
       <li><strong>Reinstall the App</strong>
           <ul>
               <li>If issues persist, try uninstalling the app, restarting your mobile device, and reinstalling the app. This ensures you have the latest version. When you log back into your account, all your information will be restored.</li>
           </ul>
       </li>
       <li><strong>Contact Support</strong>
           <ul>
               <li>If you still encounter issues, please send a link to your recording and a screenshot of the Vocal Match Slider to <a href="mailto:support@sparksinger.com">support@sparksinger.com</a>.</li>
           </ul>
       </li>
   </ol>

   <h2>Tips to Address Echo Issues</h2>
   <ol>
       <li><strong>Adjust Headphone Placement and Volume</strong>
           <ul>
               <li>Keep your headphone output away from the microphone. If the microphone is too close to your headphones, it may cause feedback or an echo.</li>
               <li>Try lowering the headphone volume while singing to prevent the sound from being picked up by the microphone.</li>
           </ul>
       </li>
       <li><strong>Use a Different Headset or Microphone</strong>
           <ul>
               <li>Switching to another headset or microphone may resolve the issue.</li>
           </ul>
       </li>
       <li><strong>Try Headphones Without a Built-In Microphone</strong>
           <ul>
               <li>Using headphones without an attached microphone will force the app to use your device's built-in microphone, which may provide better audio input.</li>
           </ul>
       </li>
       <li><strong>Adjust Reverb Settings</strong>
           <ul>
               <li>Lower the Reverb Amount and Reverb Character in the audio settings of the Style template you are using.</li>
           </ul>
       </li>
       <li><strong>Reinstall the App</strong>
           <ul>
               <li>If the problem persists, uninstall the app, restart your device, and reinstall it. This ensures you have the latest version.</li>
           </ul>
       </li>
   </ol>
   <p class="contact">Still Experiencing Issues? Provide a link to your recording and describe the issue in detail. Contact our Support team at <a href="mailto:support@sparksinger.com">support@sparksinger.com</a>, and they will assist you further.</p>

   <h2>Network Issues</h2>
   <ol>
       <li><strong>Switch Between Wi-Fi and Mobile Data</strong>
           <ul>
               <li>Toggle between Wi-Fi and mobile data to determine which provides better performance.</li>
           </ul>
       </li>
       <li><strong>Check Loading Speed</strong>
           <ul>
               <li>Open <a href="https://SparkSinger.com">SparkSinger.com</a> in your browser to check if the loading speed differs from the app.</li>
               <li>If both the app and the website load slower than usual on Wi-Fi, try resetting your Wi-Fi router if you have access to it. Otherwise, consider sticking to mobile data.</li>
           </ul>
       </li>
       <li><strong>Share IP Address Details</strong>
           <ul>
               <li>If Wi-Fi issues persist, visit <a href="https://whatsmyip.com">https://whatsmyip.com</a>, take a screenshot of the page, and send it to us. Our tech team can investigate using the IP address logs.</li>
           </ul>
       </li>
       <li><strong>Provide Error Details</strong>
           <ul>
               <li>If you encounter a specific error message, take a screenshot of the message and share it with us for faster troubleshooting.</li>
           </ul>
       </li>
   </ol>
   <p class="contact">Contact our Support team at <a href="mailto:support@sparksinger.com">support@sparksinger.com</a> with the required information, and we’ll help resolve the issue promptly.</p></Box>,
  7:<Box sx={{ padding: 3, maxWidth: 800, margin: '0 auto',textAlign:'start',color:"#743CED",backgroundColor:"white" }}>
     <h1>Gifting on SparkSinger</h1>
    <p>The Gifting feature allows you to send and receive images to show appreciation for friends and family while collaborating on music through the SparkSinger platform!</p>
    <p>With this feature, you can recognize fellow singers, encourage and reward exceptional performances, and connect with new friends.</p>

    <h2>To purchase diamonds for sending gifts:</h2>
    <ol>
        <li>Go to your profile.</li>
        <li>Click the icon at the top right of your cover photo.</li>
        <li>Select an offer.</li>
    </ol>
    <p>If you don't have diamonds but click the gift icon below a performance, the app will guide you to the same screen where you can purchase diamonds.</p>
    <p>After selecting the desired number of diamonds, click <strong>“Buy”</strong> to complete your purchase. You’ll also find free images and GIFs at the beginning of the Gifts list, although limits may apply.</p>

    <h2>How to send a gift:</h2>
    <ol>
        <li>Click on the avatar of the recipient.</li>
        <li>If you’re part of the performance and don’t want to gift yourself, deselect your avatar to avoid sending the gift to yourself.</li>
        <li>Ensure at least one recipient is selected; otherwise, the <strong>“Send”</strong> button will remain grayed out.</li>
    </ol>

    <div class="video">
        <h2>For more information, watch the video below:</h2>
      
        <iframe width="560" height="315" src="https://www.youtube.com/embed/your-video-id" frameborder="0" allowfullscreen></iframe>
    </div>

    <p>Thank you for being a valued part of the SparkSinger community!</p>
    </Box>,
    8: <Box sx={{ padding: 3, maxWidth: 800, margin: '0 auto', textAlign: 'start', color: "#743CED" }}>
    <Typography variant="h4" className="mb-4 font-bold">
      How to Upload a Song to SparkSinger
    </Typography>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
      <Card className="p-4 shadow-lg">
        <CardContent>
          <Typography variant="body1" className="mb-2">
            1. Open <strong>SparkSinger.com</strong> from your web browser.
          </Typography>
          <Typography variant="body1" className="mb-2">
            2. Click the <strong>Upload</strong> button and select <strong>Upload a song to SparkSinger</strong>.
          </Typography>
          <img src={step1} alt="Step 1" className="w-full rounded-lg mt-2" />
        </CardContent>
      </Card>
      <Card className="p-4 shadow-lg">
        <CardContent>
          <Typography variant="body1" className="mb-2">
            3. Select the backing track from your PC. The upload should take only a few minutes.
          </Typography>
          <img src={step2} alt="Step 2" className="w-full rounded-lg mt-2" />
        </CardContent>
      </Card>
      <Card className="p-4 shadow-lg">
        <CardContent>
          <Typography variant="body1" className="mb-2">
            4. Enter the <strong>title</strong> and <strong>artist</strong> of your song. Choose the <strong>genre</strong> and <strong>mood</strong>.
          </Typography>
          <img src={step3} alt="Step 3" className="w-full rounded-lg mt-2" />
        </CardContent>
      </Card>
      <Card className="p-4 shadow-lg">
        <CardContent>
          <Typography variant="body1" className="mb-2">
            5. Add the lyrics and assign singers for duet performances.
          </Typography>
          <img src={step4} alt="Step 4" className="w-full rounded-lg mt-2" />
        </CardContent>
      </Card>
      <Card className="p-4 shadow-lg">
        <CardContent>
          <Typography variant="body1" className="mb-2">
            6. Time your lyrics by pressing the <strong>spacebar</strong> or clicking with the <strong>mouse</strong>.
          </Typography>
          <img src={step5} alt="Step 5" className="w-full rounded-lg mt-2" />
        </CardContent>
      </Card>
      <Card className="p-4 shadow-lg">
        <CardContent>
          <Typography variant="body1" className="mb-2">
            7. Choose the <strong>Lyric Type</strong> to match your audio and video style.
          </Typography>
          <img src={step6} alt="Step 6" className="w-full rounded-lg mt-2" />
        </CardContent>
      </Card>
      <Card className="p-4 shadow-lg">
        <CardContent>
          <Typography variant="body1" className="mb-2">
            8. Done! Your uploaded song is now available in your SparkSinger account.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  </Box>
  
  };

  // useEffect(() => {
  //   const config = {
  //     headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  //   };

  //   const fetchHelpData = async () => {
  //     try {
  //       const res = await axios.get(`${url}/api/help/get-help-description/${params.id}`, config);
  //       console.log(res.data.data, 'ishelp');
  //       setIshelp([res.data.data]);
       

  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   fetchHelpData();
  // }, [params.id]);

  return (
//     <Box
//   sx={{
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     minHeight: '100vh',
//     backgroundColor: '#f0f0f0',
//     padding: 4,
//   }}
// >
//   {ishelp && ishelp.length > 0 ? (
//     ishelp
//       .filter((data) => data.heading && data.heading.trim() !== '') // Filter out invalid headings
//       .map((data, index) => (
//         <Card
//           key={index}
//           sx={{
//             maxWidth: '600px',
//             margin: '1rem 0',
//             padding: 2,
//             boxShadow: 3,
//             borderRadius: 4,
//           }}
//         >
//           <CardContent>
//             <Typography
//               variant="h5"
//               sx={{
//                 textAlign: 'center',
//                 fontWeight: 'bold',
//                 color: '#6C3FEA',
//                 marginBottom: 1,
//               }}
//             >
//               {data.heading}
//             </Typography>

//             <Typography
//               variant="body1"
//               sx={{
//                 textAlign: 'justify',
//                 fontSize: '1rem',
//                 lineHeight: 1.8,
//                 color: '#333',
//               }}
//             >
//               {data.description}
//             </Typography>
//           </CardContent>
//         </Card>
//       ))
//   ) : (
//     <Typography variant="h6" color="textSecondary">
//       No help content available.
//     </Typography>
//   )}
// </Box>
<Box sx={{backgroundColor:"white"}}>
  <Meta title="Help at Sparksinger| Sparksinger Social Singing & Karaoke App" description="SparkSinger is a social karaoke app where you can enjoy millions of songs, connect with friends worldwide, and even sing with top artists. Join the global singing community today! | SparkSinger Social" keywords="Karaoke, Singing, Social Singing" />
<Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          textAlign: "center",
          padding: 1,
          height: 60,
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Box sx={{ width: 200, maxWidth: 200, marginBottom: { xs: 2, sm: 0 } }}>
          <img src={elpsss} alt="Image" style={{ width: "70%" }} />
        </Box>
        <Box
          sx={{
            color: "black",
            cursor: "pointer",
            fontSize: { xs: "14px", sm: "16px" },
            textAlign: "center",
            "&:hover": { color: "#55AEEA" },
          }}
          onClick={() =>
            (window.location.href =
              "http://SparkSingerFeedback-1476499505.us-east-1.elb.amazonaws.com")
          }
        >
          Submit your feedback
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-around",
          alignItems: "center",
          textAlign: "center",
          padding: 1,
          height: { xs: "auto", sm: 60 },
        }}
      >
        <Box sx={{ width: 200, marginBottom: { xs: 1, sm: 0 } }}>
          <Typography color={"#743CED"}>SparkSinger</Typography>
        </Box>
        {/* <Box
          sx={{
            width: { xs: "80%", sm: "10%" },
            borderRadius: 20,
            marginBottom: { xs: 1, sm: 0 },
          }}
        >
          <input
            type="text"
            placeholder="Search..."
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "20px",
              border: "1px solid #743CED",
              fontSize: "16px",
            }}
          />
        </Box> */}
      </Box>
<Box

>
{/* <Typography sx={{ fontSize: '24px', fontWeight: 'bold', marginBottom: 2 }}>
  Paragraph Screen
</Typography> */}
<Box sx={{textAlign:"start"}}>
        {/* Render content dynamically based on ID */}
        {content[id] || (
          <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }}>
            Content not found for ID: {id}, Name: {name}
          </Typography>
        )}
      </Box>
</Box>

</Box>
  );
};

export default Paragraph;
