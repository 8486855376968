import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, IconButton, InputAdornment, Menu, MenuItem, OutlinedInput, Typography } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { url } from '../../../config/config';
import axios from 'axios';

const Grouplist = () => {
    const [groups, setGroups] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [sortOption, setSortOption] = useState("Most Popular");
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    useEffect(() => {
        const getGroups = async () => {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                };
                const res = await axios.get(`${url}/api/channel/GetGlobalChannel`, config);
                setGroups(res.data.data);
            } catch (error) {
                console.log(error);
            }
        };
        getGroups();
    }, []);

    const filteredGroups = groups.filter(group =>
        group.ChannelName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sortedGroups = [...filteredGroups].sort((a, b) => {
        if (sortOption === "Most Popular") {
            return b.MemberCount - a.MemberCount;
        } else if (sortOption === "Most Recent") {
            return new Date(b.createdAt) - new Date(a.createdAt);
        } else if (sortOption === "Name A-Z") {
            return a.ChannelName.localeCompare(b.ChannelName);
        } else if (sortOption === "Name Z-A") {
            return b.ChannelName.localeCompare(a.ChannelName);
        }
        return 0;
    });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const handleSortChange = (option) => {
        setSortOption(option);
        handleClose();
    };

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
                <FormControl sx={{ width: "65ch" }} variant="outlined">
                    <OutlinedInput
                        type="text"
                        placeholder="Search groups by name"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        sx={{ height: "5ch", boxShadow: '0 4px 6px #E5E1F7', borderRadius: '5px' }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton sx={{ backgroundColor: '#6C2EFF', color: "white", '&:hover': { backgroundColor: '#4B1CB0' } }}>
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <Box>
                    <Button id="basic-button" onClick={handleClick}>
                        <Box sx={{ display: "flex", color: "#2D2867" }}>
                            <FilterListIcon />
                            <Typography sx={{ ml: 1 }}>{sortOption}</Typography>
                        </Box>
                    </Button>
                    <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
                        <MenuItem onClick={() => handleSortChange("Most Popular")}>Most Popular</MenuItem>
                        <MenuItem onClick={() => handleSortChange("Most Recent")}>Most Recent</MenuItem>
                        <MenuItem onClick={() => handleSortChange("Name A-Z")}>Name A-Z</MenuItem>
                        <MenuItem onClick={() => handleSortChange("Name Z-A")}>Name Z-A</MenuItem>
                    </Menu>
                </Box>
            </Box>
            <Box sx={{
                display: "grid",
                gridTemplateColumns: { lg: "repeat(4, 1fr)", md: "repeat(4, 1fr)", sm: "repeat(3, 1fr)", xs: "repeat(1, 1fr)" },
                gap: 2,
                padding: { md: 5, sm: 0 },
                justifyContent: "center",
            }}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1 }}>
                    <Box sx={{ width: "250px", height: "180px", backgroundColor: "#F0F6FF", display: "flex", borderRadius: 3, justifyContent: "center", alignItems: "center", cursor: "pointer", mt: 2 }}
                        onClick={() => navigate("/creategroups")}
                    >
                        <FilterListIcon sx={{ width: "48px", height: "48px", color: "#000052" }} />
                    </Box>
                    <Typography fontSize={16} fontWeight={700}>Create Group</Typography>
                </Box>
                {sortedGroups.map((data, index) => (
                    <Box key={index} sx={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer", gap: 1, mt: 2 }}
                        onClick={() => navigate(`/groupinfo/${data._id}`, { state: data })}
                    >
                        <Box sx={{ width: "240px", height: "180px", overflow: "hidden", borderRadius: 2, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img src={data.CoverImage} alt={data.ChannelName} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                        </Box>
                        <Box sx={{ textAlign: "center" }}>
                            <Typography fontSize={14} color="#000052">{data.ChannelName}</Typography>
                            <Typography fontSize={12} color="#AAADB4">{data.MemberCount} members</Typography>
                            <Typography fontSize={10} color="#AAADB4">{data.type}</Typography>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default Grouplist;
