import { Box, Typography } from '@mui/material'
import React from 'react'
import DragDropFileUpload from '../../../components/drag-drop-file3'

const Beatupload = () => {
    const handleFileUpload = (file) => {
       
      };
  return (
    <Box sx={{display:"flex",justifyContent:"center",flexDirection:"column",gap:3,alignItems:"center",height:"100vh"}}>
        <DragDropFileUpload onFileUpload={handleFileUpload}/>
        <Box>
            <Box><Typography fontSize={12} color='#9598B4'>By uploading this song, you confirm that you are complying with all legal guidelines set forth by our Community Guidelines and Terms of Service.</Typography></Box>
            <Box><Typography fontSize={12} color='#9598B4'>If you have any questions, please refer to our FAQ or contact Smule for more assistance.</Typography></Box>
        </Box>
    </Box>
  )
}

export default Beatupload