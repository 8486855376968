import {  Box } from '@mui/material';
import React from 'react';



const Usesparksinger = () => {
  return (
   <Box sx={{ padding: 3, maxWidth: 800, margin: '0 auto',textAlign:'start',color:"#743CED" }}>
        <h1>How to Use SparkSinger</h1>
       
       <h2>Introduction</h2>
   
       <h2>Singing on SparkSinger</h2>
   
       <h3>How Do I Join a Collab?</h3>
       <ol>
           <li>In the Songbook, select a song you’d like to sing.</li>
           <li>Tap on “View Invites” to see a list of available collab invitations.</li>
           <li>Find a collab you’d like to join and tap “Join” to start singing with others.</li>
       </ol>
       <p>When you join a collab, you are adding your voice to other previously recorded singers.</p>
   
       <h3>How Do I Start a Collab?</h3>
       <ol>
           <li>From the Songbook, choose a song you’d like to sing.
               <em>(Note: Some songs may require a PREMIUM subscription or viewing a short ad.)</em>
           </li>
           <li>Select “Duet” or “Group” to begin:
               <ul>
                   <li><strong>Duet:</strong> Add your voice and let another singer join in to create a dynamic recording showcased on both profiles.</li>
                   <li><strong>Group:</strong> Open your recording to many singers for a collaborative piece that evolves with each addition.</li>
               </ul>
           </li>
       </ol>
       <p>Note: To maintain creative control, set the collab to private and manually invite specific singers. Once satisfied, make it public to share your masterpiece with the world.</p>
   
       <h3>How Do I Invite Other Singers to Join My Collabs?</h3>
       <ol>
           <li>If your collab is set to public, you can invite all your followers. They’ll see your invite in their notification list.</li>
           <li>After saving your recording, you can:
               <ul>
                   <li>Send personalized invitations via direct messages.</li>
                   <li>Share your invite in existing group chats.</li>
                   <li>Invited singers will receive a message with a link to your collab.</li>
               </ul>
           </li>
           <li>Once saved, your collab will appear under the “Invites” tab on your profile. Tap “Invite Singers” later to invite more participants.</li>
       </ol>
   
       <h3>Why Doesn’t My Invite Appear Under “View Invites”?</h3>
       <p>To improve the collab experience, only public invites from singers with a profile picture are shown.</p>
       <ul>
           <li>Ensure your invite is public.</li>
           <li>Upload a profile picture to increase visibility.</li>
       </ul>
       <p>Enjoy collaborating and creating music with the SparkSinger community!</p>
   
       <h2>How to Delete a Recording on SparkSinger?</h2>
       <p>You can delete any solo, duet, or group recording you’ve posted. However, recordings made by other singers cannot be deleted by you.</p>
   
       <h3>Deleting a Recording in the App</h3>
       <ol>
           <li>Go to your profile and select the recording you want to delete.</li>
           <li>Tap the three dots icon at the bottom right of the screen.</li>
           <li>Select "Delete" and proceed to delete the recording.</li>
       </ol>
   
       <h3>Deleting a Recording on the Website</h3>
       <ol>
           <li>Sign in to your account at <a href="https://sparksinger.com">sparksinger.com</a>.</li>
           <li>Locate the recording you want to delete.</li>
           <li>Click the three dots at the top right of the album art for the recording.</li>
           <li>From the menu, select "Edit performance".</li>
           <li>Open the popup menu, select "Delete" to remove the recording.</li>
       </ol>
       <p>Keep your recordings list updated with only the performances you love!</p>
   
       <h2>Managing Group Recordings on SparkSinger</h2>
   
       <h3>How to Remove Yourself from Another Singer’s Group Recording?</h3>
       <ol>
           <li>Open the group recording where you’ve contributed.</li>
           <li>Tap the three dots menu.</li>
           <li>Select “Hide” to remove your layer.
               <em>Your contribution will be hidden within a few minutes.</em>
           </li>
       </ol>
       <p>If you need further assistance, feel free to contact the SparkSinger Support Team via this <a href="#">link</a>.</p>
   
       <h3>How to Remove Another Singer’s Recording from Your Group Song</h3>
       <ul>
           <li>You cannot remove another singer’s recording from your group performance.</li>
           <li>For better control over your group recordings:
               <ol>
                   <li>Set the recording to private.</li>
                   <li>Manually invite specific singers to contribute.</li>
                   <li>Once satisfied with the final result, close the recording and make it public.</li>
               </ol>
           </li>
       </ul>
       <p>This ensures you maintain editorial control while sharing a polished group recording with the world!</p>
   
       <h2>Using Video on SparkSinger</h2>
   
       <h3>Singing with Video</h3>
       <ol>
           <li>Go to the Songbook and choose a song.</li>
           <li>Join an existing collab or start a new solo or collab.</li>
           <li>Before you begin singing, toggle the Video switch to “On”.</li>
       </ol>
   
       <h3>Switching Between Front and Back Cameras</h3>
       <ul>
           <li><strong>iOS & Android:</strong> Use the camera switch button below the Vocal Volume icon at the top-right corner before recording.</li>
       </ul>
   
       <h3>Checking Your Video Posting Status</h3>
       <ul>
           <li><strong>iOS & Android:</strong>
               <ul>
                   <li>Go to your profile to see the channel tab or recordings.</li>
                   <li>Once videos are uploaded, your profile is updating for each recording uploading.</li>
               </ul>
           </li>
       </ul>
   
       <h3>Handling Video Posting Failures</h3>
       <ol>
           <li>Tap the Retry button next to the error message.</li>
           <li>Ensure a stable internet connection.</li>
           <li>Refresh your profile by pulling down the list to automatically retry failed uploads.</li>
       </ol>
   
       <h3>Video Specifics for Android</h3>
   
       <h4>Why Are My Audio and Video Out of Sync During Review?</h4>
       <p>Some devices may show audio and video out of sync due to slower processing speeds. Tap “Continue” to post your video, and they should sync properly after posting.</p>
   
       <h4>Why Can’t I Record Videos?</h4>
       <ul>
           <li>Video recording requires Android 7.0 or above and a device capable of handling video processing.</li>
           <li>If your device doesn’t meet these requirements, you can still use audio-only recordings.</li>
       </ul>
       
   
   <div class="section">
   <h2>SparkSinger Guide</h2>
       <h2>Video Specifics for iOS</h2>
       <h3>What Devices Support Video?</h3>
       <ul>
           <li>Devices running iOS 13.4 or above support video recording.</li>
           <li>Unsupported devices can still join collabs using only audio.</li>
       </ul>
   
       <h3>Sharing Videos to Social Media</h3>
       <h4>Sharing to Snapchat</h4>
       <ol>
           <li>Open the song you wish to share.</li>
           <li>Tap the Share icon and select Snapchat.</li>
           <li>Use the video trimmer to select a 10-second clip.</li>
           <li>Customize with Snapchat filters, stickers, animations, and text before sharing.</li>
       </ol>
       <p>Enjoy creating and sharing your video performances!</p>
   </div>
   
   <div class="section">
       <h2>Uploading Content</h2>
       <h3>Adding a Song to the Songbook</h3>
       <h4>Who can add a song to SparkSinger Songbook?</h4>
       <p>Anyone with a SparkSinger account can add a song to the Songbook. If you don’t have an account, you can sign up at <a href="https://www.sparksinger.com">www.sparksinger.com</a>.</p>
   
       <h4>How do I add a song to the Songbook?</h4>
       <ol>
           <li><strong>Prepare a Backing Track</strong>
               You first need a backing track for your song that doesn’t include vocals.
           </li>
           <li><strong>Log in to Your Account</strong>
               Sign into your SparkSinger account at <a href="https://www.sparksinger.com">www.sparksinger.com</a> and click the green "Upload" button.
           </li>
           <li><strong>Select the Songbook Option</strong>
               Click the “Songbook” option on the right.
           </li>
           <li><strong>Upload Your Backing Track</strong>
               Select and upload your backing track.
           </li>
           <li><strong>Enter Song Details</strong>
               Enter the title, artist, genre(s), and tag(s) for your song.
           </li>
           <li><strong>Add the Lyrics</strong>
               Enter the lyrics for your song and assign parts for duets or groups.
           </li>
           <li><strong>Time Your Lyrics to the Music</strong>
               Use the provided tools to time each line to the music.
           </li>
           <li><strong>Save Your Song</strong>
               Click "Save" to make your song available in the Songbook.
           </li>
       </ol>
   
       <h4>Can I add a song using my mobile phone or tablet?</h4>
       <p>No, songs can only be added through a desktop or laptop computer.</p>
   
       <h4>How can I edit a song I’ve added?</h4>
       <p>Log into your account and go to the Songs section of your profile. Click on the song you wish to edit and select the “Edit Song” button.</p>
   
       <h4>Can I add a song privately?</h4>
       <p>No, all added songs become publicly available in the Songbook.</p>
   
       <h4>How are user-added songs different from songs created by SparkSinger?</h4>
       <p>User-added songs have timed lyrics, while SparkSinger adds pitch lines and syllable-level timing.</p>
   
       <h4>Can I remove a song I’ve added to the Songbook?</h4>
       <p>Yes, log into your account, go to the song’s page, and select the delete option.</p>
   
       <h4>Can I see if the community is singing my song?</h4>
       <p>Yes, view stats like recordings and ratings in the Songs section of your profile.</p>
   
       <h4>How can I learn more about the Songbook?</h4>
       <p>Visit the <a href="https://www.sparksinger.com">Uploading FAQ</a>.</p>
   </div>
   
   <div class="section">
       <h2>Uploading Requirements</h2>
       <ul>
           <li>Maximum file size: 20 MB</li>
           <li>Recording length: 30 seconds to 10 minutes</li>
           <li>Supported formats: mp3</li>
           <li>Sample Rate: 16 Bit, 44100Hz or 48000Hz</li>
           <li>File type: Mono or stereo files only</li>
           <li>Maximum compression: 64kbps (mono) and 128kbps (stereo)</li>
       </ul>
   </div>
   <header>
           <h2>Welcome to SparkSinger</h2>
           <p>Follow these steps to create and upload your music files.</p>
       </header>
   
       <section id="create-backing-track">
           <h2>Creating Your Backing Track</h2>
           <p>Backing tracks typically feature drums, piano, guitar, synths, and bass. You can use various recording programs with built-in synthesizers, drum machines, and recording tools.</p>
           <ul>
               <li><strong>Beginner Option:</strong> GarageBand by Apple (macOS and iOS).</li>
               <li>Ensure your backing track is in an accepted format before uploading (see below).</li>
           </ul>
       </section>
   
       <section id="file-requirements">
           <h2>File Requirements</h2>
           <ul>
               <li>Maximum file size: 20 MB</li>
               <li>Recording length: Up to 10 minutes</li>
               <li>Supported formats: m4a, mp3</li>
               <li>Sample Rate: 16 Bit, 44100Hz or 48000Hz</li>
               <li>File type: Mono or stereo</li>
               <li>Maximum compression: 64kbps (mono) and 128kbps (stereo)</li>
           </ul>
       </section>
   
       <section id="convert-files">
           <h2>Converting Your File</h2>
           <p>Use these tools to save or export your files in the correct format:</p>
           <h3>Paid Options</h3>
           <ul>
               <li>Apple GarageBand</li>
               <li>Adobe Audition</li>
               <li>Reaper</li>
               <li>Ableton Live</li>
           </ul>
           <h3>Free Options</h3>
           <ul>
               <li><a href="https://www.videolan.org/vlc/">VLC Media Player</a></li>
               <li><a href="https://www.audacityteam.org/">Audacity</a> (requires additional codecs for exporting mp3 or m4a files)</li>
           </ul>
       </section>
   
       <section id="reduce-file-size">
           <h2>Reducing File Size</h2>
           <p>If your file is too large:</p>
           <ul>
               <li>Lower the compression bitrate (e.g., 96kbps instead of 128kbps).</li>
               <li>Save the file as mono instead of stereo if stereo is unnecessary.</li>
           </ul>
       </section>
   
       <section id="upload-tutorial">
           <h2>Uploading Your Beat to AutoRap</h2>
           <ol>
               <li><strong>Open SparkSinger.com:</strong> Visit the website in your browser.</li>
               <li><strong>Access the Upload Section:</strong> Click "Upload" and choose "Upload a Beat to AutoRap."</li>
               <li><strong>Select and Upload:</strong> Choose your beat file from your device.</li>
               <li><strong>Provide Details:</strong>
                   <ul>
                       <li>Enter the title and artist.</li>
                       <li>Fill in the genre and mood (required).</li>
                       <li>Upload cover art.</li>
                   </ul>
               </li>
               <li><strong>Mark the Verse Start:</strong> Play the beat and mark where the first verse begins.</li>
               <li><strong>Publish:</strong> Click "Publish Your Beat."</li>
           </ol>
       </section>
   
       <section id="social-features">
           <h2>Followers, Chat, and Comments</h2>
           <h3>Following Singers</h3>
           <p>Follow singers to see their activities and join collaborations. Limits:</p>
           <ul>
               <li>Follow up to 2,000 singers without restrictions.</li>
               <li>Users cannot follow more than 1,000 singers per day.</li>
           </ul>
           <h3>Chat Features</h3>
           <p>To send messages:</p>
           <ol>
               <li>Go to a singer's profile.</li>
               <li>Click the message icon to start chatting.</li>
           </ol>
       </section>
       <h2>SparkSinger Chat Updates: New Rules and Guidelines</h2>
   
   <div class="section">
       <h2>How to Start a 1:1 Chat</h2>
       <ol>
           <li>Tap the "Message" button to start a chat.</li>
           <li>Alternatively, go to the Messages screen.</li>
           <li>Tap the new message icon (top-right corner), select a user (or search by username), and hit "Next" to initiate a 1:1 chat.</li>
       </ol>
   </div>
   
   <div class="section">
       <h2>How to Start a Group Chat</h2>
       <ol>
           <li>Tap the new message icon on the Messages screen.</li>
           <li>Select multiple users, then tap "Next" to create a group chat.</li>
           <li>Your friends will receive your group chat request. Once accepted, they can view the entire group chat history, even if added later.</li>
       </ol>
   </div>
   
   <div class="section">
       <h2>How to Name a Group Chat</h2>
       <ul>
           <li>You must name your group chat during its creation.</li>
           <li>A descriptive name makes it easier for others to identify and accept the group chat.</li>
           <li>To rename a group chat later:
               <ul>
                   <li>Open the group chat.</li>
                   <li>Tap the details icon (<em>i</em>) in the top-right corner.</li>
               </ul>
           </li>
       </ul>
   </div>
   
   <div class="section">
       <h2>Adding or Removing Members in a Group Chat</h2>
       <ol>
           <li>Open the group chat.</li>
           <li>Tap the details icon (<em>i</em>) in the top-right corner.</li>
           <li>On the Group Chat Details screen:
               <ul>
                   <li>Tap "+" to add members.</li>
                   <li>Tap "-" to remove members.</li>
                   <li>Changes to the group (additions or removals) will be announced in the chat.</li>
               </ul>
           </li>
       </ol>
   </div>
   
   <div class="section">
       <h2>How to Leave a Group Chat</h2>
       <ol>
           <li>Open the group chat.</li>
           <li>Tap the details icon (<em>i</em>) in the top-right corner.</li>
           <li>On the Group Chat Details screen, select "Leave Group Chat".</li>
           <li>Your departure will be announced to other group members.</li>
       </ol>
   </div>
   
   <div class="section">
       <h2>Turning Off Chat Notifications</h2>
       <ul>
           <li>For specific chats:
               <ul>
                   <li>Go to Group Chat Details and toggle off "Receive Notifications".</li>
                   <li>Or, on the Messages screen, swipe left (or tap and hold for Android) and tap the "mute" button.</li>
               </ul>
           </li>
           <li>For all chats:
               <ul>
                   <li>Go to Settings on your profile page.</li>
                   <li>Toggle off "All Chat Notifications".</li>
               </ul>
           </li>
       </ul>
   </div>
   
   <div class="section">
       <h2>Sharing a Recording in a Message</h2>
       <ol>
           <li>Go to the share screen for your recording.</li>
           <li>Tap the "Message" icon on the left.</li>
           <li>Send your recording to up to 5 chats or users at once.</li>
       </ol>
   </div>
   
   <div class="section">
       <h2>Sending a Collab Invite in a Message</h2>
       <ol>
           <li>Go to the invite screen.</li>
           <li>Tap the "Message" icon on the left.</li>
           <li>Send your invite to up to 5 chats or users at once.</li>
       </ol>
   </div>
   
   <div class="section">
       <h2>Privacy of Messages</h2>
       <ul>
           <li>Only you and the people involved in the chat can see its contents.</li>
           <li>If new users are added to a group chat, they will see all previous messages.</li>
       </ul>
   </div>
   
   <div class="section">
       <h2>Will My Chat History Expire?</h2>
       <ul>
           <li>Previous conversations remain visible when messaging someone again.</li>
           <li>Individual messages cannot be deleted manually.</li>
           <li>Chats are automatically deleted after a period of inactivity.</li>
       </ul>
   </div>
   
   <div class="section">
       <h2>New Chat Rules for Non-Mutual Followers</h2>
       <ol>
           <li>If User B does not follow User A:
               <ul>
                   <li>User A can send unlimited messages, but User B will only receive the first message.</li>
                   <li>This first message is restricted to 100 characters or less.</li>
                   <li>User B will not receive additional messages unless they:
                       <ul>
                           <li>Reply to the first message, or</li>
                           <li>Follow User A.</li>
                       </ul>
                   </li>
               </ul>
           </li>
           <li>User B can remove the messaging restriction by:
               <ul>
                   <li>Replying to the initial message.</li>
                   <li>Following User A.</li>
               </ul>
           </li>
       </ol>
   </div>
   
   <div class="section">
       <h2>Why Were These Changes Implemented?</h2>
       <ul>
           <li>Reduce spam: Limiting unsolicited messages minimizes disruptions from unknown users.</li>
           <li>Prevent inappropriate behavior: Restricting messages helps curb profanity and harmful content.</li>
           <li>Enhance user safety: Encouraging mutual follow relationships promotes meaningful and secure interactions.</li>
       </ul>
   </div>
   
   <div class="section">
       <h2>How Can I Protect Myself from Spam or Unwanted Messages?</h2>
       <ul>
           <li>Ignore messages from unknown users.</li>
           <li>Block or report users who send unwanted or inappropriate content.</li>
       </ul>
   </div>
   <h2>7. Can I Still Chat Freely with Friends?</h2>
   <p><strong>Yes!</strong> If you and another user follow each other, there are no restrictions on messaging.</p>
   
   <h3>Thank You for Your Support!</h3>
   <p>We appreciate your understanding as we work to make SparkSinger a safer, more enjoyable space for everyone. For additional questions or concerns, please reach out to our support team.</p>
   
   <h3>Comments: Managing Your Interactions on SparkSinger</h3>
   <h4>How Do I Delete a Comment?</h4>
   <p>You can delete:</p>
   <ul>
     <li>Your own comments on another singer’s recording.</li>
     <li>Any comments posted by other singers on your recordings.</li>
   </ul>
   
   <h4>Deleting a Comment Inside the App</h4>
   <ol>
     <li>Locate the comment you want to delete.</li>
     <li>For iOS: Swipe the comment to the left. For Android: Tap and hold the comment.</li>
     <li>Tap the trashcan icon to delete the comment.</li>
   </ol>
   
   <h4>Deleting a Comment on sparksinger.com</h4>
   <ol>
     <li>Sign in to your account at sparksinger.com.</li>
     <li>Navigate to the comment you want to delete.</li>
     <li>Hover your cursor over the comment.</li>
     <li>Click the X icon that appears to delete the comment.</li>
   </ol>
   
   <h3>SparkSinger Groups</h3>
   <h4>General Information</h4>
   <h5>What is a group?</h5>
   <p>A group is a community of two or more people who share a common interest, coming together to chat, collaborate, and build meaningful connections.</p>
   
   <h5>Where can I find groups?</h5>
   <p>Groups can currently be created via the SparkSinger website or on the iOS version of the app. The Android version is under development. For Android users: navigate to the Explore page and check the "+" sign. However, inviting members to groups is only available on iOS or the website.</p>
   
   <h5>How do I create a group?</h5>
   <ol>
     <li>Navigate to the Explore tab in the iOS app or the homepage of the SparkSinger website.</li>
     <li>Scroll to the "Groups" section.</li>
     <li>Tap “See All” and then the “+” button.</li>
     <li>Fill in the required and optional fields.</li>
     <li>Tap “Create” to set up your group.</li>
     <li>Invite members to join your group using the options provided.</li>
   </ol>
   
   <h5>Who can create a group?</h5>
   <p>Anyone can create a group.</p>
   
   <h5>How many members can a group have?</h5>
   <p>Groups can accommodate up to 2,000 members.</p>
   
   <h5>What are the privacy settings for groups?</h5>
   <p>All groups are public, meaning non-members can view the group page and its contents. However, posting is restricted to members only.</p>
   
   <h4>Group Management</h4>
   <h5>How do I delete a group?</h5>
   <p>Groups can be deleted on the SparkSinger website or iOS app.</p>
   <h6>On sparksinger.com:</h6>
   <ol>
     <li>Go to your profile and click the “Groups” icon below your username.</li>
     <li>Click the three dots (“…”) next to the group you want to delete.</li>
     <li>Select “Manage Group” and then “Delete Group”.</li>
   </ol>
   <h6>On iOS:</h6>
   <ol>
     <li>Open the group page and tap the three dots (“…”) in the top-right corner.</li>
     <li>Go to “Group Settings”, scroll to the bottom, and tap “Delete Group”. Confirm the action.</li>
   </ol>
   
   <h5>How do I invite users to my group?</h5>
   <h6>On sparksinger.com:</h6>
   <ol>
     <li>Navigate to your group page.</li>
     <li>Click the gear icon within the group’s cover picture.</li>
     <li>Go to the “Settings” submenu and select “Group Members”.</li>
     <li>Tap “+” to invite users.</li>
   </ol>
   <h6>On iOS:</h6>
   <ol>
     <li>Open your group page and tap the three dots (“…”) in the top-right corner.</li>
     <li>Go to “Group Settings” and select “Group Members” under the “Settings” submenu.</li>
     <li>Use the “+” icon to search for and add members.</li>
   </ol>
   <p>Note: Inviting members is not yet available on Android.</p>
   
   <h5>How do I remove or ban someone from my group?</h5>
   <ol>
     <li>Open the group page and tap the three dots (“…”) near the group’s title.</li>
     <li>Go to the “Members” page.</li>
     <li>Tap the three dots (“…”) next to a user’s name and choose:
       <ul>
         <li>Ban User</li>
         <li>Remove User</li>
       </ul>
     </li>
   </ol>
   
   <h5>How do I approve or manage posts in my group?</h5>
   <p>Group members can post to the group page automatically. If needed, group owners can delete posts made by members.</p>
   
   <h5>How do I change my group’s name?</h5>
   <h6>On SparkSinger.com:</h6>
   <ol>
     <li>Navigate to the “Groups” section in your profile.</li>
     <li>Click the three dots (“…”) next to the group name and select “Manage Group”.</li>
     <li>Edit the name in the Group Name field and click “Save”.</li>
   </ol>
   <h6>On iOS/Android:</h6>
   <ol>
     <li>Open the group page and tap the three dots (“…”) in the top-right corner.</li>
     <li>Go to “Group Settings”, edit the name, and tap “Save”.</li>
   </ol>
   <h4>How do I upload a cover photo for my group?</h4>
   <h5>On SparkSinger.com:</h5>
   <ol>
     <li>Go to the “Groups” section of your profile.</li>
     <li>Click the three dots (“…”) next to the group name and select “Manage Group”.</li>
     <li>Add or edit the photo using the camera icon.</li>
   </ol>
   
   <h5>On iOS/Android:</h5>
   <ol>
     <li>Open the group page and tap the three dots (“…”) in the top-right corner.</li>
     <li>Go to “Group Settings” and select the camera icon to upload a cover photo.</li>
   </ol>
   
   <h4>How many owners can a group have?</h4>
   <p>Each group can have only one owner. To transfer ownership, contact support at <a href="mailto:support@sparksinger.com">support@sparksinger.com</a>.</p>
   
   <h3>Posting and Sharing</h3>
   <h4>How do I post to a group?</h4>
   <ol>
     <li>Navigate to the group page.</li>
     <li>Tap the “+” icon next to “Add Post.”</li>
     <li>Select the recordings you want to share on the group page.</li>
     <li>Tap “Post.”</li>
   </ol>
   
   <h4>Can I start a Sing Live in a group?</h4>
   <p>Yes, tap the “Sing Live” button located below the group’s name and members.</p>
   
   <h4>How do I share my group posts on other social media channels?</h4>
   <p>Click the “Share” button located below the group’s name and members to share your post externally.</p>
   
   <h4>Can I search for a specific post or recording in a group?</h4>
   <p>No, group posts are not searchable.</p>
   
   <h3>Notifications and Chatting</h3>
   <h4>Notifications</h4>
   <h5>Will there be notifications for groups?</h5>
   <ul>
     <li><strong>Group Owners:</strong> Receive notifications when users request to join their group.</li>
     <li><strong>Users:</strong> Receive notifications when they’re invited to join a group.</li>
   </ul>
   
   <h4>I can't add someone to my group—why?</h4>
   <p>Only the group owner can invite new members.</p>
   
   <h4>How can I chat in a group?</h4>
   <p>Group chats can be initiated from the chat screen. However, there is no shortcut to start a chat directly with a SparkSinger group.</p>
   
   <h3>Joining or Leaving a Group</h3>
   <h4>How do I join a group?</h4>
   <ol>
     <li>Navigate to the group page you want to join.</li>
     <li>If the group is open to new members, tap the “Join” button.</li>
     <li>For closed groups, tap “Request to Join.”</li>
   </ol>
   
   <h4>How do I leave a group?</h4>
   <ol>
     <li>Go to the group page.</li>
     <li>Tap the “…” in the top-right corner.</li>
     <li>Select “Leave Group.”</li>
   </ol>
   
   <h4>How many groups can I join?</h4>
   <p>You can join up to 250 groups.</p>
   
   <h4>Why can I only create three groups?</h4>
   <p>Managing a group requires significant effort. To ensure group owners can dedicate sufficient time to their groups, the number of groups an individual can create is limited to three.</p>
   
   <h4>What happens when I’m invited to a group?</h4>
   <ul>
     <li>You can accept, reject, or ignore the invitation.</li>
     <li>Invitations expire after 30 days.</li>
     <li>You can respond to invitations from your profile page, notifications, or the group’s page.</li>
   </ul>
   
   <h4>How/where can I see a list of groups I own or have joined?</h4>
   <ul>
     <li>On your profile page, tap “Groups.”</li>
     <li>This section displays the groups you’re in, pending requests, and invites.</li>
   </ul>
   
   <h4>How/where can I find new groups to join?</h4>
   <ul>
     <li>Check the Explore tab or browse other users’ profile pages for group suggestions.</li>
   </ul>
   
   <h4>How do I audition for a group?</h4>
   <p>Audition processes vary by group. Contact the group owner for specific instructions.</p>
       </Box>
  );
};

export default Usesparksinger;
