import { Box, Container,Typography} from '@mui/material'
import React, { createContext, useEffect, useState } from 'react'
import Header from './components/header'
import Songtabs from './components/song_tabs';
import Spark from '../spark';
import Topnav from '../../layouts/top-nav';
import Topmenu from '../topmenu';
import { ImAppleinc } from 'react-icons/im';
import { BsGooglePlay } from 'react-icons/bs';
import Appcard from '../../components/app-card';
import Meta from '../../components/meta_data';
export const SearchContext = createContext();

const SongBook = () => {
  const [searchValue,setSearchValue] = useState('')
  const [showSpark, setShowSpark] = useState(false);
  useEffect(() => {
    setShowSpark(true); 
    const timeout = setTimeout(() => {
        setShowSpark(false);
    }, 5000);
    return () => clearTimeout(timeout);
}, []);
  return (
    <Box>
                <Meta title="Famous Karaoke Songs & Lyrics | SparkSinger: Social Singing Karaoke App" description="Find your favorite songs and listen to hot recordings from the community | SparkSinger Social Singing Karaoke app" keywords="Karoke, Singing, SparkSinger" />
      {/* <Box sx={{width:'100%',backgroundColor:'rgb(136, 38, 251)',height:'60px'}}>
        <Box sx={{display:'flex',justifyContent:'space-around',textAlign:'center'}}>
          <Box >
          <Typography>Download Spark Singer App</Typography>
          </Box>
        </Box>
      </Box> */} 
      {/* {showSpark && (
                <Box sx={{ position: 'fixed', top: 75, width: '100%', zIndex: 1000, backgroundColor: 'white' }}>
                    <Topmenu/>
                </Box>
             )}  */}
              <Box
      sx={{
        backgroundColor: '#9036FF',
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'center', 
        alignItems: 'center', 
        textAlign: 'center', 
        padding: 1,
        gap:4
      }}
    >
      <Typography sx={{display:'flex' ,fontWeight: 'bold', color: '#fff', marginBottom: 2,fontSize:20 }}>
        Download SparkSinger App
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          justifyContent: 'center',
        }}
      >
        <Appcard icon={<ImAppleinc fontSize="large" />} tittle1="Download on the" tittle2="App Store" />
        <Appcard icon={<BsGooglePlay fontSize="large" />} tittle1="Download on the" tittle2="Google Play" />
      </Box>
      
    </Box>
<Box sx={{margin:5,backgroundColor:"white"}}>
   <Container maxWidth='xl' >
    <SearchContext.Provider value={[searchValue,setSearchValue]}>
    <Header/>
   <Box>
    <Songtabs/>
   </Box>
    </SearchContext.Provider>
   </Container>
   </Box>
   

    </Box>
  )
}

export default SongBook 