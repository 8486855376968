import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';
import { FaGift } from 'react-icons/fa';
import image from '../assest/images/NewLogoWhite.png';
import image1 from '../assest/images/peaceemoji.png';
import image2 from '../assest/images/box.png';
import image3 from '../assest/images/NewLogo.png';

const Styles = () => {
  return (
    <Box sx={{ backgroundColor: 'white', paddingBottom: 5 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 3,
          padding: { xs: 3, sm: 4, md: 6 },
          background: 'linear-gradient(to right,#FF3FFF, #1B1BDB)',
          textAlign: 'center',
        }}
      >
        <Box sx={{ width: { xs: '200px', sm: '220px', md: '250px' } }}>
          <img src={image} alt="logo" style={{ width: '100%' }} />
        </Box>
        <Typography
          sx={{
            fontSize: { xs: '36px', sm: '44px', md: '54px' },
            color: 'white',
            fontFamily: 'Arial, sans-serif',
            fontWeight: 'bold',
            fontStyle: 'italic',
            marginBottom: 2,
          }}
        >
          Gifts & Diamonds
        </Typography>
        <Avatar
          sx={{
            width: { xs: 120, sm: 140, md: 160 },
            height: { xs: 120, sm: 140, md: 160 },
            backgroundColor: '#E46FFF',
          }}
        >
          <FaGift size={70} />
        </Avatar>
      </Box>

      {/* First Section */}
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems="center"
        justifyContent="center"
        mt={5}
        gap={4}
        paddingX={3}
      >
        <img
          src={image1}
          alt="gift"
          style={{ width: '90%', maxWidth: '350px' }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Typography sx={{ fontSize: { xs: 20, md: 24 }, textAlign: 'center' }}>
            <span style={{ fontWeight: 'bold' }}>What are gifts?</span>
            <br />
            Gifts are a fun and exciting way to express your
            appreciation for your favorite singers or songs.
            Show your love by sending them a gift!
          </Typography>
          <Typography sx={{ fontSize: { xs: 20, md: 24 }, textAlign: 'center' }}>
            <span style={{ fontWeight: 'bold' }}>What do gifts do?</span>
            <br />
            Gifting your favorite singers goes beyond just
            liking their performances – it’s a special way to
            show your appreciation! Visit our Explore tab to
            discover recordings that have received the most gifts.
            We’re constantly adding new gifts, so stay tuned!
          </Typography>
        </Box>
      </Box>

      {/* Second Section */}
      <Box
        display="flex"
        flexDirection={{ xs: 'column-reverse', md: 'row' }}
        alignItems="center"
        justifyContent="center"
        mt={5}
        gap={4}
        paddingX={3}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Typography sx={{ fontSize: { xs: 20, md: 24 }, textAlign: 'center' }}>
            <span style={{ fontWeight: 'bold' }}>What are Diamonds?</span>
            <br />
            Diamonds are a virtual currency that you can buy
            and use to send gifts. Show your appreciation
            by gifting your favorite singers with them!
          </Typography>
          <Typography sx={{ fontSize: { xs: 20, md: 24 }, textAlign: 'center' }}>
            <span style={{ fontWeight: 'bold' }}>Can I transfer Diamonds?</span>
            <br />
            Diamonds and any gifts you've received cannot be transferred
            to another user. Please note that Diamonds have no cash value,
            are solely for redeeming gifts, and are non-refundable.
            For more details, refer to Sparksinger Terms of Service.
          </Typography>
        </Box>
        <img
          src={image2}
          alt="gift"
          style={{ width: '90%', maxWidth: '350px' }}
        />
      </Box>
    </Box>
  );
};

export default Styles;
