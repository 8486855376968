import React from 'react'
import { Box } from '@mui/material'
const Appcard = ({icon,tittle1,tittle2}) => {
  return (
    
    <Box sx={{display:"flex",backgroundColor:"black",gap:2,color:"white",alignItems:"center",width:"fit-content",padding:1,borderRadius:3}}>
        <Box>{icon}</Box>
        <Box >
            <Box sx={{fontSize:"12px"}}>{tittle1}</Box>
            <Box sx={{fontSize:"20px",fontWeight:"900"}}>{tittle2}</Box>
        </Box>
    </Box>
  )
}

export default Appcard