import React,{ useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear';
import { Box,Drawer, IconButton, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link,useNavigate} from 'react-router-dom';
import AccordionUsage from './accordian-usage';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import BasicMenu from './basic-menu';
// import logo from '../assest/images/logo.png'
import './Styles/left_Drawer.css'
import Exploredata from '../data/explore-data';
import { exploreScrool } from '../features/addlyric/addLyric';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../assest/images/Bright Color-01.png';
import NotificationDrawer from './notificationDrawer';
import Authmodel from './auth_model';
import { jwtDecode } from 'jwt-decode';
import { Explore } from '@mui/icons-material';
const LeftDrawer = ({Anchor,notificationControl}) => {
  const[open,setOpen]=useState(false)
  const[show,setShow]=useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
 
  const[notification, setNotification]= useState(false)
  const isLoggedIn = localStorage.getItem('token') !== null;
  const handleExplore = (val) => {
    navigate(`/listen/${val}`)
  }
  const handleExplores = (val) => {
    navigate(`/groups`)
  }
  const handlePrivacy = () => {
    navigate("/privacy-policy");
  };
  const handleTerms = () => {
    navigate("/terms-of-service")
  };
  const handleCopyright = () => {
    navigate("/copyright");
  };
  const handleGuidelines = () => {
    navigate("/guidelines");
  };
  const handelprivacy=()=>{
    navigate("/help")
  }
  const hanldeClose = () => {
    setOpen(false)
  }
  const handleNotificationCall = (val) => {
    setNotification(val)
  }
 
  const handleClick = () => {
    notificationControl(true)
    Anchor()
  }

  const handleCloseDrawer =() => {
    console.log('Close');
    setNotification(false)
  }

  const userTokenData = localStorage.getItem('token') !== null ? jwtDecode(localStorage.getItem('token')) : ''

  // console.log(userTokenData.userId,'userTokenData');
  

  return (
   
    <Box sx={{display:"flex",justifyContent:"space-between",flexDirection:"column",height:"100vh"}} >
     <Box  sx={{
              overflowY: 'scroll',
                        
              '::-webkit-scrollbar': {
              display:"none"
              },     
            }}>  
     <Box sx={{display:"flex",height:"77.88px", backgroundColor: "#2D2867",flexDirection:"row-reverse",justifyContent:"flex-start",alignItems:"center",position:'sticky',top:"0px",zIndex:"1"}}>
      <Typography flexBasis={230} sx={{fontSize:25}}>
      <img src={logo} style={{display:"flex",width:"170px",height:"55px",padding:"12px",marginTop:"0px",backgroundColor:"black",alignItems:"center",borderRadius:"0px"}}></img>
      </Typography>
     <Box sx={{backgroundColor:"#8A59FF",paddingTop:2.6,paddingBottom:2.6}}>
     <IconButton 
        onClick={Anchor}
        sx={{color:"white",paddingLeft:2.5,paddingRight:2.5}}
       ><ClearIcon fontSize='small'/></IconButton>
     </Box>
       </Box>
       <Box>
        {
          localStorage.getItem('token') !== null ? (
       <AccordionUsage accName='Profile'>
<Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",gap:1, textAlign:"justify",borderBottomColor:"red"}}>
  <Box  onClick={() => {
    
    if(localStorage.getItem('token') !== null){
      navigate("/profile",{state:{ landingIndex: 0,} })
      if (localStorage.getItem('token') !== null) {
        Anchor()
      } 
    }else{
      setOpen(true)
    }
  }} sx={{textDecoration:"none",color:"white",cursor:"pointer",marginLeft:5}}>Channel

      </Box><Box sx={{backgroundColor:"#DCDCDC"}}><Divider/></Box>
      <Box  onClick={() => {
       if(localStorage.getItem('token') !== null){
        navigate ("/profile",{state:{ landingIndex: 1}
        })
        if (localStorage.getItem('token') !== null) {
          Anchor()
        } 
      }else{
        setOpen(true)   
      }
      }
      } sx={{textDecoration:"none",color:"white",cursor:"pointer",marginLeft:5}}>Invites
      </Box><Box sx={{backgroundColor:"#DCDCDC"}}><Divider/></Box>
      <Box  onClick={() => {
        if(localStorage.getItem('token') !== null){
        navigate("/profile",{state:{ landingIndex: 2 } })
        if (localStorage.getItem('token') !== null) {
          Anchor()
        } 
      }else{
        setOpen(true)
      }
      }} sx={{textDecoration:"none",color:"white",cursor:"pointer",marginLeft:5}}>Playlists
      </Box><Box sx={{backgroundColor:"#DCDCDC"}}><Divider/></Box>
      <Box  onClick={() => {
        if(localStorage.getItem('token') !== null){
        navigate("/profile",{state:{ landingIndex: 3 } })
        if (localStorage.getItem('token') !== null) {
          Anchor()
        } 
      }else{
        setOpen(true)
      }
      }} sx={{textDecoration:"none",color:"white",cursor:"pointer",marginLeft:5}}>Albums
      </Box><Box sx={{backgroundColor:"#DCDCDC"}}><Divider/></Box>
      <Box  onClick={() => {                
        if(localStorage.getItem('token') !== null){
        navigate("/profile",{state:{ landingIndex: 4 }})
        if (localStorage.getItem('token') !== null) {
          Anchor()
        } 
      }else{
        setOpen(true)
      }
      }} sx={{textDecoration:"none",color:"white",cursor:"pointer",marginLeft:5}}>Karaokes
      </Box><Box sx={{backgroundColor:"#DCDCDC"}}><Divider/></Box>
      <Box  onClick={() => {
        if(localStorage.getItem('token') !== null){
        navigate("/profile",{state:{ landingIndex: 5 } })
        if (localStorage.getItem('token') !== null) {
          Anchor()
        } 
        }else{
        setOpen(true)
      }
      }} sx={{textDecoration:"none",color:"white",cursor:"pointer",marginLeft:5}}>About
      </Box>
      <Box sx={{backgroundColor:"#DCDCDC"}}><Divider/></Box>
    
      {/* <Box  onClick={() => {               
        if(localStorage.getItem('token') !== null){
        navigate("/profile",{state:{ landingIndex: 5 } })
        if (localStorage.getItem('token') !== null) {
          Anchor()
        } 
      }else{
        setOpen(true)
      }
      }} sx={{textDecoration:"none",color:"white",cursor:"pointer",marginLeft:5}}>Gifts
      </Box><Box sx={{backgroundColor:"#DCDCDC"}}><Divider/></Box> */}
      
      </Box>
       </AccordionUsage>):null}</Box>
       <Box>
       {
        localStorage.getItem('token') !== null ? (
       <AccordionUsage accName='Notification'>
       
    {/* {isLoggedIn ? (
      <Button onClick={handleLogout}>Logout</Button>
    ) : (
      <InviteComponent />
    )}     */}
    
       <Box sx={{display:"flex",justifyContent:"center",gap:1,flexDirection:{sm:"column",xs:"column",md:"column"},}}>
       <Link style={{textDecoration:"none",color:"white",marginLeft:40}}  onClick={handleClick}>Notification</Link>
      
       <Box sx={{backgroundColor:"#DCDCDC"}}><Divider/></Box>
       
       <Box  onClick={() => {
                if(localStorage.getItem('token') !== null){
                  Anchor()
                navigate ("/profile",{state:{ landingIndex: 1}
                 
                })
              }else{
                setOpen(true)              
              }
              }
              } sx={{textDecoration:"none",color:"white",cursor:"pointer",marginLeft:5}}>Invites
              </Box> <Box sx={{backgroundColor:"#DCDCDC"}}><Divider/></Box>
                 
              {/* <Link style={{textDecoration:"none",color:"white",marginLeft:40}} to='help' onClick={Anchor}>Help</Link>
              <Box sx={{backgroundColor:"#DCDCDC"}}><Divider/></Box> */}
              <Link style={{textDecoration:"none",color:"white",marginLeft:40}} to='friend' onClick={Anchor}>Refer-a-Friend</Link>
              
       {/* <Drawer anchor='right'
      open={notification}
      PaperProps={{
        sx : {
          backgroundColor:'white',
        }
      }}
      onClose={() => {setNotification(false)}}
      >
        <Box>
          <NotificationDrawer value={notification} handleCloseDrawer={handleCloseDrawer}  />
        </Box>
        </Drawer> */}
        <Box sx={{backgroundColor:"#DCDCDC"}}><Divider/></Box>
        </Box>       
         </AccordionUsage>):null}</Box>
        <AccordionUsage accName='Explore' >
        {
        Exploredata && Exploredata.map((data,index)=>(
          <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",gap:1}}>
         
          <Box pt={1} key={index}><Link style={{textDecoration:"none",color:"white",marginLeft:40}} to='explore' onClick={()=>{
            console.log(index,'indexValuesssss')
            dispatch(exploreScrool(index))
            Anchor()
          }}>{data.tittle}</Link></Box>  
          <Box sx={{backgroundColor:"#DCDCDC"}}><Divider/>
          </Box></Box>
        ))
       }
        </AccordionUsage>
        
        <AccordionUsage accName='Songs'>
        <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",gap:1,textAlign:"start"}}>
            <Box  onClick={() => {
                navigate("/song")
                Anchor()             
              }} sx={{textDecoration:"none",color:"white",cursor:"pointer",marginLeft:5}}>Songbook
              </Box>
          </Box>

        </AccordionUsage>
        
        <AccordionUsage accName='Feed' sx={{ color: 'white' }}>
        <Box onClick={()=>{Anchor()} }>
        <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",gap:1,textAlign:"start",cursor:"pointer",marginLeft:5}}  
          onClick={() => handleExplore('explore')} >View All</Box>
          </Box>
          
          
        </AccordionUsage >
        <AccordionUsage accName='Groups' sx={{ color: 'white' }}>
        <Box onClick={()=>{Anchor()} }>
        <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",gap:1,textAlign:"start",cursor:"pointer",marginLeft:5}}  
          onClick={() => handleExplores('/groups')} >Create Group</Box>
          </Box>
          
        </AccordionUsage >
        <Box>
        { 
         userTokenData.userId === '662554b8e4cf8912452cac5a' ? (
        <AccordionUsage accName='Admin'>
       <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",gap:1, textAlign:"justify",borderBottomColor:"red"}}>
       <Box  onClick={() => {
        if(localStorage.getItem('token') !== null){
        navigate("/geners",{state:{ landingIndex: 5 } })
        if (localStorage.getItem('token') !== null) {
          Anchor()
        } 
        }else{
        setOpen(true)
      }
      }} sx={{textDecoration:"none",color:"white",cursor:"pointer",marginLeft:5}}>Genere
      </Box>
      <Box sx={{backgroundColor:"#DCDCDC"}}><Divider/></Box>
      <Box  onClick={() => {
        if(localStorage.getItem('token') !== null){
        navigate("/carousel",{state:{ landingIndex: 5 } })
        if (localStorage.getItem('token') !== null) {
          Anchor()
        } 
        }else{
        setOpen(true)
      }
      }} sx={{textDecoration:"none",color:"white",cursor:"pointer",marginLeft:5}}>Carousel
      </Box>
      <Box sx={{backgroundColor:"#DCDCDC"}}><Divider/></Box>
      <Box  onClick={() => {
        if(localStorage.getItem('token') !== null){
        navigate("/songgiftupload",{state:{ landingIndex: 5 } })
        if (localStorage.getItem('token') !== null) {
          Anchor()
        } 
        }else{
        setOpen(true)
      }
      }} sx={{textDecoration:"none",color:"white",cursor:"pointer",marginLeft:5}}>Gift
      </Box></Box>
      <Box sx={{backgroundColor:"#DCDCDC"}}><Divider/></Box>

        </AccordionUsage>):null}
        </Box>
        <AccordionUsage accName=' About' sx={{ color: 'white' }}>
        <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",gap:1, textAlign:"justify",borderBottomColor:"red"}}>
        <Box onClick={()=>{Anchor()} }>
        <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",gap:1,textAlign:"start",cursor:"pointer",marginLeft:5}}  
          onClick={ handlePrivacy} >Privacy Policy</Box>
          </Box>
      <Box sx={{backgroundColor:"#DCDCDC"}}><Divider/></Box>
      <Box onClick={()=>{Anchor()} }>
        <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",gap:1,textAlign:"start",cursor:"pointer",marginLeft:5}}  
          onClick={ handleTerms} >Terms Of Service</Box>
          </Box>
      <Box sx={{backgroundColor:"#DCDCDC"}}><Divider/></Box>
      <Box onClick={()=>{Anchor()} }>
        <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",gap:1,textAlign:"start",cursor:"pointer",marginLeft:5}}  
          onClick={ handleCopyright} >Copyright</Box>
          </Box>
      <Box sx={{backgroundColor:"#DCDCDC"}}><Divider/></Box>
      <Box onClick={()=>{Anchor()} }>
        <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",gap:1,textAlign:"start",cursor:"pointer",marginLeft:5}}  
          onClick={ handleGuidelines} >Guidelines</Box>
          </Box>
      {/* <Box sx={{backgroundColor:"#DCDCDC"}}><Divider/></Box>
      <Box  onClick={() => {
    
    if(localStorage.getItem('token') !== null){
      navigate("/privacy-policy")
      if (localStorage.getItem('token') !== null) {
        Anchor()
      } 
    }else{
      setOpen(true)
    }
  }} sx={{textDecoration:"none",color:"white",cursor:"pointer",marginLeft:5}}>Privacy Policy

      </Box>
      <Box sx={{backgroundColor:"#DCDCDC"}}><Divider/></Box>
      <Box  onClick={() => {
    
    if(localStorage.getItem('token') !== null){
      dispatch(exploreScrool(5))
      navigate("/privacy-policy")
      if (localStorage.getItem('token') !== null) {
        Anchor()
      } 
    }else{
      setOpen(true)
    }
  }} sx={{textDecoration:"none",color:"white",cursor:"pointer",marginLeft:5}}>Managing and deleting your Information

      </Box> */}
      <Box sx={{backgroundColor:"#DCDCDC"}}><Divider/></Box>
          </Box>
          
          
        </AccordionUsage >
        <AccordionUsage accName='Help Support' sx={{ color: 'white' }}>
        <Box onClick={()=>{Anchor()} }>
        <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",gap:1,textAlign:"start",cursor:"pointer",marginLeft:5,fontSize:15}}  
          onClick={ handelprivacy} >Help</Box>
          </Box>
          
        </AccordionUsage >
        {/* <AccordionUsage accName='Users'> */}
        {/* <Box sx={{textAlign:"center",display:"flex",flexDirection:"column",gap:1,justifyContent:"center"}}> */}
            {/* <Box> */}
                {/* <Link style={{textDecoration:"none",color:"white"}} to='explore'>Explore</Link> */}
            {/* </Box> */}
            {/* <Box> <Link style={{textDecoration:"none",color:"white"}} to='song'>Songbook</Link></Box> */}
            {/* <Box sx={{display:"flex",alignItems:"center"}}> */}
                {/* <Box sx={{marginLeft:"auto",marginRight:"auto"}}> */}
                    {/* <BasicMenu/> */}
                  
                {/* </Box> */}
               
            {/* </Box> */}
            
         {/* </Box> */}

        {/* </AccordionUsage> */}
        </Box>    
       
      
      <Authmodel open={open} HandleClose={hanldeClose}/>  
         <Box sx={{position:"sticky", backgroundColor: "black",bottom:"0px"}}>
      <Divider/>
            <Box margin={1} sx={{display:"flex",justifyContent:"center",gap:1} }>
            <Box>
                <FacebookIcon fontSize="large" />
            </Box>
            <Box>
                <TwitterIcon fontSize="large"/>
            </Box>
            <Box>
                <InstagramIcon fontSize="large"/>
            </Box>
            <Box>
    <a href="https://youtube.com/@sparksingerofficial?si=pXj0IHG47rdlPAm0" target="_blank" rel="noopener noreferrer">
        <YouTubeIcon fontSize="large"style={{ color: "white", cursor: "pointer" }} />
    </a>
</Box>
            <Box>
                <LinkedInIcon fontSize="large"/>
            </Box>
          </Box>
      </Box>
     
        </Box>   
            
      
        
       
   
  )
}

export default LeftDrawer