import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import FlagIcon from '@mui/icons-material/Flag';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import CopyrightIcon from '@mui/icons-material/Copyright';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { MdEditSquare } from "react-icons/md";
import { MdReportProblem } from "react-icons/md";
import { Tooltip } from '@mui/material';
const Menu_Item=[
    {
        id:3,
        tittle:'Favorite',
        icon:<StarOutlineOutlinedIcon/>,
       
    },
    {
        id:4,
        tittle:'Song information',
        icon:<MusicNoteIcon/>,
       
    },
    {
         id : 5,
         tittle : 'Song details',
         icon :
         <Tooltip title="future update">
         <ErrorOutlineIcon/>
         </Tooltip>,
    },
    {
        id:6,
        tittle:'Singing tips',
        icon:
        <Tooltip title="future update">
            <LightbulbIcon />
        </Tooltip>, 

       
    },
    {
        id:7,
        tittle:'Report',
        icon:<FlagIcon />,
       
    },
    {
        id : 2,
        tittle : 'Edit performance',
        icon : <MdEditSquare/> 
    }
   
]
export default Menu_Item;