import { Box, Button, Card, Container, Divider, FormControlLabel, Radio, Typography ,Modal, CircularProgress,Icon } from '@mui/material';
import React, { useState } from 'react';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import { useSelector,useDispatch } from 'react-redux';
import axios from 'axios';
import { animationGenerater, EditlyricType } from '../../../features/addlyric/addLyric';
import RadioGroup from '@mui/material/RadioGroup';
import { url } from '../../../config/config';
import { KaraokeEdit } from '../../../features/addlyric/addLyric';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';
import image from "../../../assest/images/ICON png version-01.png"
import image1 from '../../../assest/images/Bright Color-01.png'
import image2 from "../../../assest/images/love.gif"
import { Cancel } from '@mui/icons-material';
import image3 from "../../../assest/images/Vh2.gif"
import image4 from "../../../assest/images/rain.gif"
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius : '3px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  background: "#DEF1FD",
  textAlign:"center",
};


const Songstructure = () => {
  const lyric = useSelector((state) => state)
  const [open,setOpen] = useState(false)
  const [karaokeStatus,setKaraokeStatus] = useState([])
  const [animation,setAnimation] = useState(false)
  const [canvaimage,setCanvaimage] = useState('')
  const [textAnimation,setTextAnimation] = useState('')
  const [loading, setLoading] = useState(false);
  const [textColor,setTextColor] = useState('')
  console.log(lyric)
  const lyricValue = lyric.Upload.TextfileLyrics[0].LyricType;
  console.log(lyricValue)
  const dispatch=useDispatch()
  const navigate = useNavigate();
  const[lyricType,setlyricType]=useState(0)
  const [canva, setCanva] = useState(null);
  const uploadSong=async()=>{
    console.log('Song Upload')
    setOpen(true)
    const response = await fetch(lyric.Upload.songUpload.url);
    const response1 = await fetch(lyric.Upload.SongMeta.url);
    const blob = await response.blob();
    const blob1 = await response1.blob();
    console.log(blob,'Valueeee')
   
    const formData = new FormData();
    formData.append('audio', blob);
    formData.append('cover', blob1);
    formData.append('SongData', JSON.stringify(lyric.Upload));
     try {
      const config={
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
         const res = await axios.post(`${url}/api/Karaoke/createKaraoke`,formData,config);
         console.log(res.data)
        setKaraokeStatus(res.data)
     } catch (error) {
         // Handle errors
         console.log(error)

     }
   }
   console.log(lyric.Upload.TextfileLyrics)
   const [lyricTypes,setLyricTypes] = useState('')
   const EditLyric=(index,singer)=>{
    console.log(index,singer)
    console.log(index,singer)
    const data={
      index:index,
      singer:+singer
    }
    dispatch(EditlyricType(data))
    setLyricTypes(singer)
  }
  const EditUploadKaraoke = async () => {
    const formData = new FormData();
    formData.append('SongData', JSON.stringify(lyric.Upload));
    try{
      const config={
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
       const res = await axios.patch(`${url}/api/Karaoke/updateKaraoke/${localStorage.getItem('karaokeId')}`,formData,config)
       console.log(res)
    }catch(error) {
        console.log(error)
    }
    const data = {
      edited : false
    }
    dispatch(KaraokeEdit(data))
    localStorage.removeItem('karaokeId')
  }
  const handleClose =() => {
    setOpen(false)
    setAnimation(false)
  }
  const handleClick = () => {
    navigate(`/allsong/${karaokeStatus.data[0]._id}`)
    window.location.reload()
  }

  const handleAnimation = () => {
    setLoading(true); 
    // setAnimation(false)
     const data = {
      canva : canva,
       textAnimation : textAnimation,
       textColor : textColor
     }
     dispatch(animationGenerater(data))
       
  setTimeout(() => {
    setLoading(false); // Hide spinner after processing
    setAnimation(false); // Close modal
  }, 2000);
  }
  // const getContent = () => {
  //   switch (canva) {
  //     case "0":
  //       return (
  //         <div>
  //           <h2>Love Effect</h2>
  //           <img
  //             src={image2}
  //             alt="Love Effect"
  //             style={{ borderRadius: "10px", marginTop: "10px",width:"100px" }}
  //           />
  //         </div>
  //       );
  //     case "1":
  //       return (
  //         <div>
  //           <h2>Heart Effect</h2>
  //           <img
  //             src={image3}
  //             alt="Heart Effect"
  //             style={{ borderRadius: "10px", marginTop: "10px" ,width:"100px" }}
  //           />
  //         </div>
  //       );
  //     case "2":
  //       return (
  //         <div>
  //           <h2>Rove Effect</h2>
  //           <img
  //             src={image4}
  //             alt="Rove Effect"
  //             style={{ borderRadius: "10px", marginTop: "10px" ,width:"150px" }}
  //           />
  //         </div>
  //       );
  //     default:
  //       return <p>Please select an option to see the effect.</p>;
  //   }
  // };

  return (
   <Container maxWidth='lg' sx={{textAlign:"center",minHeight:"100vh"}}>
       <Card>
             <Box sx={{display:"flex",justifyContent:"center",gap:2}}>
              <Box><Typography fontSize={40}>Choose Lyric Type</Typography></Box>
              <Box><Typography fontSize={40}><HelpOutlinedIcon/></Typography></Box>
             </Box>
             <Box sx={{display:"flex",justifyContent:'space-between',borderBottom:'1px solid gray'}}>
              <Box></Box>
              <Box><Typography fontSize={18}>Producers can create Audio & Video Styles that work with musical structure.</Typography></Box>
              <Box sx={{background:'rgb(108, 46, 255)',padding:'10px',color:'white',fontSize:'15px',fontWeight:700,margin:'10px',borderRadius:'5px',cursor:'pointer','&:hover': {
          backgroundColor: 'rgb(85, 33, 204)', 
        }}} onClick={() => {setAnimation(true)}}>Generate Animation</Box>
             </Box>
            <Box sx={{overflowY:"scroll",height:330,textAlign:"center",borderBottom:'1px solid gray'}}>
            {
            lyric.Upload.TextfileLyrics && lyric.Upload.TextfileLyrics.map((data,index)=>(
              <Box key={index} sx={{textAlign:"start",display:"flex",justifyContent:"space-between",pl:2}}>
                <Box sx={{display:"flex",gap:3,alignItems:"center"}}>
                  <Box>{data.startTime}</Box>
                  <Box><Typography fontSize={18 }>{data.Sentence}</Typography></Box>
                </Box>
                <Box>
                <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel  value={0}  checked={data.LyricType === 0 ? true : ''} onChange={(e) => {EditLyric(index,e.target.value)}} control={<Radio />} label="Info" />
        <FormControlLabel value={1} checked={data.LyricType === 1 ? true : ''} onChange={(e) => {EditLyric(index,e.target.value)}} control={<Radio />} label="Music" />
        <FormControlLabel value={2} checked={data.LyricType === 2 ? true : ''} onChange={(e) => {EditLyric(index,e.target.value)}} control={<Radio />} label="Sing" />
            </RadioGroup>
                </Box>
             </Box>
             
            ))
          }
            </Box>
             <Box sx={{padding:2}}>
              {
                lyric&&lyric.Upload.KaraokeEdited[0].isTrue ? <Button variant='contained' onClick={EditUploadKaraoke} sx={{width:200}}> Save</Button> : 
                <Button variant='contained' onClick={uploadSong} sx={{width:200}}> Save</Button>
              }
             </Box>
       </Card>
       <Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  {
    karaokeStatus.length !== 0 ? <Box sx={style}>
    <Box sx={{textAlign:'center'}}><CheckCircleIcon style={{color:"rgb(187 187 187)",fontSize:'40px'}}/></Box>
    <Typography sx={{fontSize:'23px',color:'rgb(61, 56, 57)',marginTop:'10px',textAlign:'center'}}>Saved to songbook</Typography>
    <Typography sx={{fontSize:'14px',color:'rgb(138, 135, 136)'}}>You can find your song on your profile and in the Songbook.</Typography>
    <Box sx={{textAlign:'center'}}>
    <Button sx={{background:'rgb(64, 52, 240)',padding:'5px',color:'white',alignItems:'center',textTransform:'none',marginTop:'10px'}} onClick={handleClick}>Okay!</Button>
    </Box>
    </Box>   : <Box sx={style}>
   <Box sx={{display:'flex',flexDirection:'column'}}>
    <Box sx={{textAlign:'center'}}><MusicNoteIcon style={{color:"#4034f0",fontSize:'30px'}}/></Box>
    <Typography sx={{fontSize:'23px',color:'rgb(61, 56, 57)',marginTop:'10px',textAlign:'center'}}>Song Upload</Typography>
    <Typography sx={{fontSize:'14px',color:'rgb(138, 135, 136)'}}>Your uploading karaoke on progress</Typography>
    <Box sx={{marginTop:'10px',alignItems:'center',textAlign:'center'}}><CircularProgress /></Box>
   </Box>
  </Box>
  }
</Modal>
<Modal
        open={animation}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style,overflowY:{lg:'scroll',xl:'hidden'},height:{lg:'500px',xl:'800px'},color:"black"}}>
          <Box sx={{marginLeft:70,cursor:"pointer",}}><Cancel onClick={ handleClose}/></Box>
        <Box sx={{display:"flex",marginLeft:22,width:"200px"}}><img src={image1}/></Box>
        <Box sx={{backgroundColor:"#DCDCDC",mt:2}}><Divider/></Box>
          {/* <Box sx={{position:"absolute",width:"150px",marginLeft:50,marginTop:6}}><img src={image}/></Box> */}
          <Box sx={{display:'flex',flexDirection:'column',gap:2,color:'black',fontWeight:'bold',fontSize:20,mt:8,justifyContent:"space-between"}}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 8,justifyContent:"space-between"}}>
            <label>Select canva</label>
            <select style={{ padding: '1px',gap:3,
           width:"50%",height:"50px",
          fontSize: '20px',
          borderRadius: '5px',
          cursor:'pointer',
          backgroundColor: 'white',
          color: 'black',}} onChange={(e) => {setCanva(e.target.value)}}>
              <option selected>Select</option>
              <option value={0}>Love_Effect</option>
              <option value={1}>Heart_Efffect</option>
              <option value={2}>Rain_Effect</option>
            </select>
            {/* <div style={{display:'flex', marginTop: "20px" }}>{getContent()}</div> */}
          </Box>
          <Box sx={{backgroundColor:"#DCDCDC",mt:2}}><Divider/></Box>
          <Box sx={{display:'flex',gap:2,color:'black',fontWeight:'bold',mt:5,justifyContent:'space-between',cursor:"pointer",}}>
            <label>Select TextAnimation</label>
           <select style={{  padding: '5px',
          fontSize: '20px',
          width:"50%",height:"50px",
          borderRadius: '5px',
          cursor:'pointer',
          backgroundColor: 'white',
          color: 'black',}}onChange={(e) => {setTextAnimation(e.target.value)}}>
            <option selected>Select</option>
             <option value={0}>FlyIn Bottom</option>
              <option value={1}>Zoom</option>
              <option value={2}>Randombars</option>
            </select>
          </Box>
          <Box sx={{backgroundColor:"#DCDCDC",mt:2}}><Divider/></Box>
          <Box sx={{display:'flex',gap:8,mt:5,justifyContent:'space-between'}}>
            <label>Select TextColor</label>
            <select style={{  padding: '5px',
          fontSize: '20px',
          width:"50%",height:"50px",
          borderRadius: '5px',
          backgroundColor: 'white',
          cursor:"pointer",
          color: 'black',}} onChange={(e) => {setTextColor(e.target.value)}}>
            <option selected>Select</option>
              <option value={0}>Shadow</option>
              <option value={1}>Glow</option>
              <option value={2}>Normal</option>
            </select>
          </Box>
          </Box>
          <Box sx={{backgroundColor:"#DCDCDC",mt:2}}><Divider/></Box>
          <Box sx={{display: 'flex', justifyContent: "center", gap: 4,}}>
          <Button
        onClick={handleClose}
        sx={{
          mt:8,
          padding: '10px 10px',
          fontSize: '16px',
          borderRadius: '5px',
          backgroundColor: '#007bff',
          color: 'white',
          border: 'none',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: '#0056b3', 
            transform: 'scale(1.05)',
            transition: 'background-color 0.3s ease, transform 0.3s ease',
          },
        }}
      >
        Cancel
      </Button>
      <Button
  onClick={handleAnimation}
  sx={{
    mt: 8,
    padding: '10px 10px',
    fontSize: '16px',
    borderRadius: '5px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0056b3',
      transform: 'scale(1.05)',
      transition: 'background-color 0.3s ease, transform 0.3s ease',
    },
  }}
  disabled={loading} // Disable button while loading
>
  {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Confirm'}
</Button>
      
          </Box>

        </Box>
      </Modal>
   </Container>
  )
}

export default Songstructure