import { Avatar, Box, Card, Container, Divider, TextField, Typography,Button, IconButton, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip } from '@mui/material'
import React, { useState,useEffect } from 'react'
import girl from '../assest/images/girl.webp'
import Switch from '@mui/material/Switch'; 
import axios from 'axios';
import { url } from '../config/config';
import { CameraAlt, PhotoCamera } from '@mui/icons-material';
import EditProfileDragDrop from '../components/editProfile';
import men from '../assest/images/male.webp'
import Meta from '../components/meta_data';

const Settings = () => {
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const[userProfileData,setUserProfileData]=useState([])
  const[userameData,setUserNameData]=useState([])
  const [showFields, setShowFields] = useState(false);
  const [userEmail,setUserEmail]=useState()
   const [previewImage, setPreviewImage] = useState(null);
   const [loading, setLoading] = useState(false);
  const [uploadImage, setUploadImage] = useState('')
  const [blurb, setBlurb] = React.useState("");
  const [bio,setBio]=React.useState("")
  const [email,setEmail]= React.useState("");
  const [first,setFirst]=React.useState("");
  const [last,setLast ]=React.useState("");
  const [username,setUsername]=React.useState("");
  const [number,setNumber]=React.useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  // console.log(userProfileData,"iyiuyiutvuytcurctrctrc")
  useEffect(() => {
    if (userProfileData.length > 0) {
      setUsername(userProfileData[0]?.Name || "");
      setBio(userProfileData[0]?. Bio || "");
      setFirst(userProfileData[0]?. FirstName|| "")
      setLast(userProfileData[0]?. LastName|| "")
      setUsername(userProfileData[0]?.Name|| "")
      setNumber(userProfileData[0]?. PhoneNumber|| "")
      setEmail(userProfileData[0]?. Email|| "")
   
       
    }
  }, [userProfileData]);
  useEffect(() => {
    const GetProfileData = async () => {
      try{
        const config={
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/User/getUserById/${localStorage.getItem('token')}`,config)
        setUserProfileData(res.data.data)
        console.log(res.data.data,'dataValue')
         const email = await res.data.data[0].Email;
        setUserEmail(email.split('@'))
      }catch (error){
         console.log(error)
      }
    }
    GetProfileData()
   },[])
   
   const handleSubmits = async () => {
    if (!openModal) {
      setOpenModal(true);
      return; // Show modal first
    }
  
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      };
  
      const formData = new FormData();
      if (uploadImage) {
        formData.append("ProfileImage", uploadImage);
      }
      formData.append("Bio", bio);
      formData.append("LastName", last);
      formData.append("FirstName", first);
      formData.append("PhoneNumber", number);
  
      const res = await axios.put(`${url}/api/User/updateUser`, formData, config);
  
      if (res.data.success) {
        setUserProfileData((prev) => [
          {
            ...prev[0],
            ProfilePhoto: res.data.updatedProfilePhoto,
            Bio: bio,
            LastName: last,
            FirstName: first,
            PhoneNumber: number,
          },
        ]);
      }
  
      console.log("Update Success:", res);
      setOpenSuccessModal(true); // Show success modal
      setOpenModal(false); // Close modal after success
      window.location.reload()
    } catch (error) {
      console.error("Network Error:", error.response ? error.response.data : error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleToggle = () => {
    setShowFields(!showFields);
  };
  const handleFileUpload = (file) => {
    if (file) {
      setUploadImage(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  return (
    
    <Box sx={{minHeight:"100vh"}}>
      <Meta title="Setting | SparkSinger" description="SparkSinger is a social karaoke app where you can enjoy millions of songs, connect with friends worldwide, and even sing with top artists. Join the global singing community today! | SparkSinger Social" keywords="Karaoke, Singing, Social Singing" />
    <Container maxWidth='xl'>
      <Card sx={{ padding: 2, mt: 3, backgroundColor: "white", color: "black" }}>
  <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
    {/* Title Section */}
    <Box sx={{ padding: "10px", color: "black" }}>
      <Typography fontWeight={700} fontSize={20}>Settings</Typography>
    </Box>
    
    <Divider />

    {/* Profile Image Section */}
    <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
  <Typography fontWeight={600}>Profile Image</Typography>
  <Box sx={{ position: "relative", display: "inline-block" }}>
  <Avatar 
    sx={{ width: 90, height: 90 }} 
    alt="Profile" 
    src={previewImage || userProfileData[0]?.ProfilePhoto || men} 
  />

  {/* File Input for Image Upload */}
  <input
    type="file"
    accept="image/*"
    style={{ display: "none" }}
    id="profile-upload"
    onChange={(e) => handleFileUpload(e.target.files[0])}
  />
  <label htmlFor="profile-upload">
    <IconButton 
      component="span" 
      sx={{ 
        position: "absolute", 
        top: "80%", 
        left: "20%", 
        transform: "translate(-50%, -50%)", 
        background: "rgba(0,0,0,0.5)", 
        color: "white",
        borderRadius: "50%",
        "&:hover": { background: "rgba(0,0,0,0.7)" }
      }}
    >
      <PhotoCamera />
    </IconButton>
  </label>
</Box>
</Box>
    
    <Divider sx={{ backgroundColor: "white" }} />
      
  
    <Box sx={{ display: "flex",textAlign:'start', gap: 10 }}>
      <Typography fontWeight={600}>Username</Typography>
      <Box sx={{ flexGrow: 6 }}>
      <Tooltip title="The blurb name is not currently editing." arrow>
      <TextField
  variant="outlined"
  value={userProfileData[0]?.Name } 
  onChange={(e) => setUsername(e.target.value)} 
  maxRows={4}
  multiline
  InputProps={{
    style: {
      fontSize: 18,
      fontWeight: 300,
      width: "60%",
      color: "black",
      backgroundColor: "white",
      height: "40px",
      height: "auto", // Adjust height dynamically
      overflow: "auto", // Enables scrolling
    },
  }}
  fullWidth
/>
</Tooltip>
</Box>
    </Box>
    <Divider sx={{ backgroundColor: "white" }} />
    <Box sx={{ display: "flex",textAlign:'start', gap: 16 }}>
      <Typography fontWeight={600}>Bio</Typography>
      <Box sx={{ flexGrow: 6 }}>
        
            <TextField
  variant="outlined"
  value={bio} // Controlled component
  onChange={(e) => setBio(e.target.value)} // Update state when typing
  maxRows={4}
  multiline
  InputProps={{
    style: {
      fontSize: 18,
      fontWeight: 300,
      width: "60%",
      color: "black",
      backgroundColor: "white",
      height: "auto", // Adjust height dynamically
      overflow: "auto", // Enables scrolling
    },
  }}
  fullWidth
/>
      </Box>
    </Box>
    <Divider sx={{ backgroundColor: "white" }} />
   

  </Box>
</Card> 
<Card sx={{ padding: 2, mt: 3, backgroundColor: "white", color: "black", fontWeight: "bold" }}>
  <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
    {/* Section Title */}
    <Box sx={{ padding: "10px", color: "black" }}>
      <Typography fontWeight={700} fontSize={20}>
        Login Information
      </Typography>
    </Box>

    <Divider />

    {/* Email Field */}
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <Typography fontWeight="bold" sx={{ width: "150px" }}>Email</Typography>
      <TextField
        type="text"
        placeholder="Enter email"
        fullWidth
        maxRows={4}
        multiline
        value={email}
        onChange={(e) => setEmail(e.target.value)} 
        InputProps={{
          style: {
            fontSize: 15,
            fontWeight: 100,
            color: "black",
            backgroundColor: "white",
            height: "40px",
            width:"50%",
            height: "auto", // Adjust height dynamically
            overflow: "auto", // Enables scrolling
          },
        }}
      />
    </Box>

    <Divider />

    {/* Username Field */}
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <Typography fontWeight="bold" sx={{ width: "150px" }}>Username</Typography>
      <Tooltip title="The username name is not currently editing." arrow>
      <TextField
        type="text"
        placeholder="Enter username"
        value={userProfileData[0]?.Name }
        onChange={(e) => setUsername(e.target.value)}
        fullWidth
        maxRows={4}
        multiline
        InputProps={{
          style: {
            fontSize: 15,
            fontWeight: 300,
            color: "black",
            backgroundColor: "white",
            height: "40px",
            width:"50%",
            height: "auto", // Adjust height dynamically
            overflow: "auto", // Enables scrolling

          },
        }}
      /></Tooltip>
    </Box>

    <Divider />

    {/* Name Fields */}
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <Typography fontWeight="bold" sx={{ width: "150px" }}>First Name</Typography>
      <TextField
        type="text"
        placeholder="Enter first name"
        value={first}
        onChange={(e) => setFirst(e.target.value)}
        fullWidth
        maxRows={4}
        multiline
        InputProps={{
          style: {
            fontSize: 15,
            fontWeight: 300,
            color: "black",
            backgroundColor: "white",
            height: "40px",
            width:"50%",
            height: "auto", // Adjust height dynamically
            overflow: "auto", // Enables scrolling
          },
        }}
      />
    </Box>

    <Divider />

    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <Typography fontWeight="bold" sx={{ width: "150px" }}>Last Name</Typography>
      <TextField
        type="text"
        placeholder="Enter last name"
        value={last}
        onChange={(e) => setLast(e.target.value)}
        fullWidth
        maxRows={4}
        multiline
        InputProps={{
          style: {
            fontSize: 15,
            fontWeight: 100,
            color: "black",
            backgroundColor: "white",
            height: "40px",
            width:"50%",
            height: "auto", // Adjust height dynamically
            overflow: "auto", // Enables scrolling
          },
        }}
      />
    </Box>

    <Divider />

    {/* Phone Number Field */}
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <Typography fontWeight="bold" sx={{ width: "150px" }}>Phone Number</Typography>
      <TextField
        type="text"
        placeholder="Enter phone number"
        value={number}
        onChange={(e) => setNumber(e.target.value)}
        fullWidth
        maxRows={4}
        multiline
        InputProps={{
          style: {
            fontSize: 15,
            fontWeight: 300,
            color: "black",
            backgroundColor: "white",
            height: "40px",
            width:"50%",
            height: "auto", // Adjust height dynamically
            overflow: "auto", // Enables scrolling
          },
        }}
      />
    </Box>

    <Divider />

    {/* Save Button */}
    <Box sx={{ textAlign: "center", mt: 2 }}>
      <IconButton>
      <Button
  sx={{ width: 350 }}
  variant="contained"
  onClick={() => setOpenModal(true)} // Opens the confirmation modal
  disabled={loading}
>
  {loading ? <CircularProgress size={24} color="inherit" /> : "Save"}
</Button>
      </IconButton>
    </Box>
  </Box>
</Card>

<Dialog
      open={openModal}
      onClose={() => setOpenModal(false)}
      PaperProps={{
        // component: motion.div,
        initial: { opacity: 0, y: -50 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -50 },
        transition: { duration: 0.3 },
      }}
    >
      <DialogTitle sx={{ bgcolor: "#1976d2", color: "white" }}>Confirm Update</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to update your profile?</Typography>
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={() => setOpenModal(false)} 
          sx={{ color: "#d32f2f", "&:hover": { bgcolor: "#ffebee" } }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            setOpenModal(false);
            handleSubmits();
          }}
          variant="contained"
          sx={{
            bgcolor: "#388e3c",
            "&:hover": { bgcolor: "#2e7d32" },
            transition: "background-color 0.3s ease",
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>

<Card sx={{padding:2,mt:3,backgroundColor:"white"}}>
   <Box sx={{display:"flex",flexDirection:"column",gap:1,color:"black"}}>
   <Box sx={{padding:"20px",color:"black"}}>
          <Box><Typography fontWeight={700} fontSize={20}>SparkSinger Emails</Typography></Box>
        </Box>
        <Divider sx={{backgroundColor:"white"}}></Divider> 
   </Box>
   <Box sx={{display:"flex",justifyContent:"space-between",color:"black"}}>
    <Box><Typography fontWeight={600}>Receive Product Updates from SparkSinger</Typography></Box>
    <Box><Typography><Switch {...label} defaultChecked /></Typography></Box>
   </Box>
      </Card>
    </Container></Box>
  )
}

export default Settings