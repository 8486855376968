import { Box, Button, Container, Typography,Card,CardMedia, Modal, IconButton, Stack, MenuItem, Menu, CardActionArea, Avatar} from "@mui/material";
import React, { useEffect, useState } from "react";
import PublicIcon from '@mui/icons-material/Public';
import img from './gift-removebg-preview.png';
import img1 from './5988768b5dce60de1522e3f28d69093d.jpg';
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { url } from "../../../config/config";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReplyIcon from '@mui/icons-material/Reply';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ModalComponent from "../../songs/componenets/module";
import { jwtDecode } from "jwt-decode";
import { Favorite, PlayArrow } from "@mui/icons-material";
const userTokenData = localStorage.getItem('token') !== null ? jwtDecode(localStorage.getItem('token')) : ''
const LoGinUserID = userTokenData.userId
// console.log(userTokenData,'hsgdyfs');
const UserPlaylist = () => {

    const navigate = useNavigate()
    const [isModal,setIsModal] = useState(false)
    const params = useParams()
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleNavigation = (path) => {
    setOpen(false); // Close modal
  };
    const songs = [
        {
            CoverImageURL : require('./gift-removebg-preview.png'),
            Name : 'vijaykumar',
            Artist : 'Yuvan Shankar raja'
        }, {
            CoverImageURL : require('./gift-removebg-preview.png'),
            Name : 'Ranjith',
            Artist : 'Harris jayaraj'
        }, {
            CoverImageURL : require('./gift-removebg-preview.png'),
            Name : 'vijaykumar',
            Artist : 'Yuvan Shankar raja'
        },
    ]
    const [playlistSongs,setPlaylistSongs] = useState([])
    
    useEffect(() => {
        const GetPlaylist = async () => {
            try{
                const config={
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                  }
                const res = await axios.get(`${url}/api/SongCollaborationPlayList/getSongCollaborationPlayListById/${params.id}`,config)
                console.log(res.data.data,'playlistOfUserrrrr')
                setPlaylistSongs(res.data.data)
            }catch (error){
              console.log(error)
            }
        }   
        GetPlaylist()
    },[])

    
    const modalClose = () => {
        setIsModal(false)
    }

     return(
        <Box sx={{minHeight:"100vh"}} >
            <Box sx={{background:'rgb(255, 255, 255)',marginTop:0,}}>
            <Container maxWidth='lg' >
                <Box sx={{display:'flex',paddingTop:'30px',justifyContent:'space-between',paddingBottom:'30px'}}>
                    <Box> 
                        <Typography sx={{fontSize:'32px',fontWeight:700,color:'rgb(45, 40, 103)'}}>{playlistSongs && playlistSongs[0]?.Name || "Untitled Playlist" }</Typography>
                        <Box sx={{display:'flex',gap:.5,marginBottom:'10px'}}>
                            <Box><PublicIcon style={{fontSize:'14px',color:'rgb(114, 140, 166)'}}/></Box>
                            <Box>
                                <Typography sx={{fontSize:'14px',fontWeight:700,color:'rgb(114, 140, 166)'}}>Public</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
  <Avatar 
    src={playlistSongs[0]?.songcollaborations[0]?.users[0]?. ProfilePhoto ||  userTokenData?.profilePhoto
    } 
    sx={{ width: '40px', height: '40px', marginRight: '8px' }}
  />
  <Typography 
    sx={{ fontSize: '14px', fontWeight: 700, color: 'rgb(45, 40, 103)' }}
  >
    {playlistSongs[0]?.songcollaborations[0]?.users[0]?. Name || userTokenData?.name}
  </Typography>
</Box>
<Box sx={{ display: 'flex', gap: 1.5, marginTop: '20px' }}>
  <Box
    onClick={() => {
      navigate(`/record/${playlistSongs[0]?.songcollaborations[0]?._id}`);
    }}
    sx={{
      display: 'flex',
      cursor: 'pointer',
      background: 'rgb(108, 46, 255)',
      color: 'white',
      padding: '10px',
      textAlign: 'center',
      borderRadius: '5px',
      gap: 1,
      paddingBottom: '5px',
      paddingRight: '15px',
      fontSize: '17px',
      fontWeight: 700,
      transition: 'background 0.3s',
      '&:hover': {
        background: 'rgb(88, 26, 235)', // Darker shade on hover
      },
    }}
  >
    <Box>
      <PlayArrowIcon />
    </Box>
    <Box>Play</Box>
  </Box>

  <Box
    sx={{
      display: 'flex',
      cursor: 'pointer',
      background: 'rgb(45, 40, 103)',
      color: 'white',
      padding: '10px',
      textAlign: 'center',
      borderRadius: '5px',
      gap: 1,
      paddingBottom: '5px',
      paddingRight: '15px',
      fontSize: '17px',
      fontWeight: 700,
      transition: 'background 0.3s',
      '&:hover': {
        background: 'rgb(30, 25, 90)', // Darker shade on hover
      },
    }}
    onClick={() => {
      setIsModal(true);
    }}
  >
    <Box>
      <ReplyIcon />
    </Box>
    <Box>Share</Box>
  </Box>
</Box>
                    </Box>
                    <Box sx={{width:'200px',height:'200px',borderRadius:'2px'}}>
                        <img src={playlistSongs && playlistSongs[0]?.CoverImageURL}/>
                    </Box>
                </Box>
            </Container>
            </Box>
            <Container maxWidth='lg'>
                <Box sx={{marginTop:'20px'}}>
                    <Typography sx={{color:'rgb(45, 40, 103)',fontSize:'14px',fontWeight:700}}>{playlistSongs && playlistSongs[0]?.songcollaborations?.length} recordings</Typography>
                </Box>
                <Box sx={{display:"flex",flexWrap:"wrap",justifyContent:"left",whiteSpace:'nowrap',flex:1,textAlign:'left',marginTop:'10px'}}>
                {playlistSongs && playlistSongs[0]?.songcollaborations?.length > 0 ? (
    <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "left", whiteSpace: 'nowrap', flex: 1, textAlign: 'left', marginTop: '10px' }}>
      {playlistSongs[0].songcollaborations.map((data, index) => (
        <Button key={index} style={{ textDecoration: "none" }} onClick={() => { navigate(`/record/${data._id}`) }}>
          <Box sx={{ margin: 2 }}>
            <Card sx={{ width: 178, borderRadius: "10px", mb: 1, position: "relative" }}>
              <CardActionArea sx={{ position: "relative", overflow: "hidden" }}>
                <CardMedia
                  sx={{ height: 178, transition: "opacity 0.3s", "&:hover": { opacity: 0.7 } }}
                  image={data.CoverImageURL}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "none",
                    zIndex: 2,
                  }}
                  className="play-icon"
                >
                  <PlayArrowIcon sx={{ fontSize: 50, color: "white" }} />
                </Box>
              </CardActionArea>
            </Card>
            <Box sx={{ textOverflow: "ellipsis" }}>
              {data.karaokes?.map((val, ind) => (
                <Box key={ind} sx={{ textOverflow: 'ellipsis', overflow: 'hidden', width: 178, color: "#2D2867", fontWeight: 800, textAlign: 'left' }}>
                  {val.Name}
                </Box>
              ))}
              {data.users?.map((val, ind) => (
                <Box key={ind} sx={{ fontSize: "13px", color: "#2D2867", textAlign: 'left', width: 178, overflow: 'hidden', textOverflow: 'ellipsis', textTransform: 'capitalize' }}>
                  {val.Name}
                </Box>
              ))}
              <Box sx={{display:'flex'}}>
                          <>
                          <Box sx={{display:'flex',gap:1,alignItems:'center',alignContent:'center'}}>
                              <Box sx={{display:'flex',alignItems:'center'}}>
                                  <Box>
                                  <PlayArrow style={{color:'rgb(200, 202, 207)',fontSize:'16px',fontWeight:400,marginTop:'5px'}}/> 
                                  </Box>
                                  <Box sx={{color:'rgb(170, 173, 180)',fontSize:'14px',fontWeight:700}}>
                                  {data.NoOfPlay}
                                  </Box>
                              </Box>
                              <Box sx={{display:'flex',alignItems:'center'}}>
                                  <Box>
                                  <Favorite style={{color:'rgb(200, 202, 207)',fontSize:'14px',fontWeight:400,marginTop:'5px'}}/> 
                                  </Box>
                                  <Box sx={{color:'rgb(170, 173, 180)',fontSize:'14px',fontWeight:700}}>
                                  {data.NoOfLikes}
                                  </Box>
                              </Box>
              </Box>
                          </>
               </Box>
            </Box>
          </Box>
        </Button>
      ))}
    </Box>
  ) : (
    // Centered message when no data is available
   <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", textAlign: "center", color: "gray", height: "50vh", padding: 2, width: "100%" }}>
             <Typography variant="h4" sx={{ color: "black", mb: 2 }}>
               Let’s find some songs for your playlist.
             </Typography>
             <Button sx={{ color: 'white', background: '#6C2EFF', padding: '10px', textTransform: 'none', marginTop: '10px', fontSize: '15px', fontWeight: 700 ,'&:hover': {backgroundColor: '#5A22CC' } }} onClick={() => navigate("/explore")}>Explore</Button>
           </Box>

  )}

   </Box>
            </Container>
            <ModalComponent isOpen={isModal} modalLink={`${window.location.href}`} onClose={modalClose} content={'Spread the word'} type={'share'}/>
        </Box>
    )
}

export default UserPlaylist;