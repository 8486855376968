import Video1 from '../../src/assest/images/cover music.png'

const TopVideo=[
    {
        id:1,
        src:Video1,
        like:'303',
        play:'1.3k',
        descript:'We Dont Talk About Bruno (ENCANTO)by Various'
    },
    {
        id:2,
        src:Video1,
        like:'303',
        play:'1.3k',
        descript:'We Dont Talk About Bruno (ENCANTO)by Various'
    },
    {
        id:3,
        src:Video1,
        like:'303',
        play:'1.3k',
        descript:'We Dont Talk About Bruno (ENCANTO)by Various'
    },
    {
        id:4,
        src:Video1,
        like:'303',
        play:'1.3k',
        descript:'We Dont Talk About Bruno (ENCANTO)by Various'
    }
]
export default TopVideo;