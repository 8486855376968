import { Box, Typography } from '@mui/material'
import React from 'react'

const Header = () => {
  return (
    <Box sx={{textAlign:"center"}}>
      <Box><Typography fontSize={30} fontWeight={700} color='#110E39'>Groups</Typography></Box>
      <Box><Typography fontSize={23} color='#728CA6'>Discover or create your community. Connect and engage with them. Explore and organize groups to find the right fit for you.</Typography></Box>
     
    </Box>
  )
}

export default Header