import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import axios from 'axios';
import { url } from '../config/config';
import { ExpandMore } from '@mui/icons-material';
import Spark from '../pages/spark';
import { useSelector } from 'react-redux';
import Meta from '../components/meta_data';

function PrivacyPolices() {
  const [expanded, setExpanded] = useState(false);
  const [faqs, setFaqs] = useState([]);
   const scrollValue = useSelector((state) => state)
  useEffect(() => {
    window.scrollTo(0, (scrollValue.Upload.scrollValue * 360))
    // return () => {
    //   dispatch(exploreScrool(0))
    //   };
  }, [scrollValue])
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    const detailsfaq = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/FAQ/getFAQ`,config)
        console.log(res,"FAQQQQQQQQQQQQQQQQQ")
        setFaqs(res.data.data)
        
      } catch (error) {
        console.log(error)
      }
    }
    detailsfaq()
  }, [])
  return (
    <Box sx={{ padding: 2, backgroundColor: '#F7F7F7' }}>
       <Meta title="Privacy Policy | Sparksinger Social Singing & Karaoke App" description="SparkSinger is a social karaoke app where you can enjoy millions of songs, connect with friends worldwide, and even sing with top artists. Join the global singing community today! | SparkSinger Social" keywords="Karaoke, Singing, Social Singing" />
      <Typography variant="h4" gutterBottom>
              Privacy Policy
            </Typography>
            <Typography variant="body1" paragraph>
              This privacy policy applies to the Spark Singer app (hereby referred to as "Application") for mobile devices that was created by SoftSpark Integrated Transformation Pvt., Ltd., (hereby referred to as "Service Provider") as a Commercial service. This service is intended for use "AS IS".
            </Typography>
      
            <Typography variant="h6" gutterBottom fontWeight={"bold"}>
              Information Collection and Use
            </Typography>
            <Typography variant="body1" paragraph>
              The Application collects information when you download and use it. This information may include information such as:
            </Typography>
            <ul>
              <li>Your device's Internet Protocol address (e.g. IP address)</li>
              <li>The pages of the Application that you visit, the time and date of your visit, the time spent on those pages</li>
              <li>The time spent on the Application</li>
              <li>The operating system you use on your mobile device</li>
            </ul>
            <Typography variant="body1" paragraph>
              The Application does not gather precise information about the location of your mobile device.
            </Typography>
            <Typography variant="body1" paragraph>
              The Application collects your device's location, which helps the Service Provider determine your approximate geographical location and make use of in below ways:
            </Typography>
            <ul>
              <li><strong>Geolocation Services:</strong> The Service Provider utilizes location data to provide features such as personalized content, relevant recommendations, and location-based services.</li>
              <li><strong>Analytics and Improvements:</strong> Aggregated and anonymized location data helps the Service Provider to analyze user behavior, identify trends, and improve the overall performance and functionality of the Application.</li>
              <li><strong>Third-Party Services:</strong> Periodically, the Service Provider may transmit anonymized location data to external services. These services assist them in enhancing the Application and optimizing their offerings.</li>
            </ul>
            <Typography variant="body1" paragraph>
              The Service Provider may use the information you provided to contact you from time to time to provide you with important information, required notices and marketing promotions.
            </Typography>
            <Typography variant="body1" paragraph>
              For a better experience, while using the Application, the Service Provider may require you to provide us with certain personally identifiable information, including but not limited to Email, userId, gender. The information that the Service Provider requests will be retained by them and used as described in this privacy policy.
            </Typography>
      
            <Typography variant="h6" gutterBottom fontWeight={"bold"}>
              Third Party Access
            </Typography>
            <Typography variant="body1" paragraph>
              Only aggregated, anonymized data is periodically transmitted to external services to aid the Service Provider in improving the Application and their service. The Service Provider may share your information with third parties in the ways that are described in this privacy statement.
            </Typography>
            <Typography variant="body1" paragraph>
              The Service Provider may disclose User Provided and Automatically Collected Information:
            </Typography>
            <ul>
              <li>As required by law, such as to comply with a subpoena, or similar legal process;</li>
              <li>When they believe in good faith that disclosure is necessary to protect their rights, protect your safety or the safety of others, investigate fraud, or respond to a government request;</li>
              <li>With their trusted services providers who work on their behalf, do not have an independent use of the information we disclose to them, and have agreed to adhere to the rules set forth in this privacy statement.</li>
            </ul>
      
            <Typography variant="h6" gutterBottom fontWeight={'bold'}>
              Opt-Out Rights
            </Typography>
            <Typography variant="body1" paragraph>
              You can stop all collection of information by the Application easily by uninstalling it. You may use the standard uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or network.
            </Typography>
      
            <Typography variant="h6" gutterBottom fontWeight={'bold'}> 
              Data Retention Policy
            </Typography>
            <Typography variant="body1" paragraph>
              The Service Provider will retain User Provided data for as long as you use the Application and for a reasonable time thereafter. If you'd like them to delete User Provided Data that you have provided via the Application, please contact them at raj.mahamuni@softspark.com and they will respond in a reasonable time.
            </Typography>
      
            <Typography variant="h6" gutterBottom fontWeight={"bol"}>
              Children
            </Typography>
            <Typography variant="body1" paragraph>
              The Service Provider does not use the Application to knowingly solicit data from or market to children under the age of 13.
            </Typography>
            <Typography variant="body1" paragraph>
              The Service Provider does not knowingly collect personally identifiable information from children. The Service Provider encourages all children to never submit any personally identifiable information through the Application and/or Services. The Service Provider encourages parents and legal guardians to monitor their children's Internet usage and to help enforce this Policy by instructing their children never to provide personally identifiable information through the Application and/or Services without their permission. If you have reason to believe that a child has provided personally identifiable information to the Service Provider through the Application and/or Services, please contact the Service Provider (raj.mahamuni@softspark.com) so that they will be able to take the necessary actions. You must also be at least 16 years of age to consent to the processing of your personally identifiable information in your country (in some countries we may allow your parent or guardian to do so on your behalf).
            </Typography>
      
            <Typography variant="h6" gutterBottom fontWeight={"bold"}>
              Security
            </Typography>
            <Typography variant="body1" paragraph>
              The Service Provider is concerned about safeguarding the confidentiality of your information. The Service Provider provides physical, electronic, and procedural safeguards to protect information the Service Provider processes and maintains.
            </Typography>
      
            <Typography variant="h6" gutterBottom>
              Changes
            </Typography>
            <Typography variant="body1" paragraph>
              This Privacy Policy may be updated from time to time for any reason. The Service Provider will notify you of any changes to the Privacy Policy by updating this page with the new Privacy Policy. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.
            </Typography>
            <Typography variant="body1" paragraph>
              This privacy policy is effective as of march 19, 2025.
            </Typography>
      
            <Typography variant="h6" gutterBottom fontWeight={"bold"}>
              Your Consent
            </Typography>
            <Typography variant="body1" paragraph>
              By using the Application, you are consenting to the processing of your information as set forth in this Privacy Policy now and as amended by us.
            </Typography>
      
            <Typography variant="h6" gutterBottom fontWeight={"bold"}>
              Contact Us
            </Typography>
            <Typography variant="body1" paragraph>
              If you have any questions regarding privacy while using the Application, or have questions about the practices, please contact the Service Provider via email at <a href="mailto:raj.mahamuni@softspark.com">raj.mahamuni@softspark.com</a>.
            </Typography>
            <Box>
            <Typography  variant="h6" gutterBottom>
              Managing And Deleting Your Information
            </Typography>
            <Typography variant="body1" paragraph>
              We provide the ability to correct or update the information you provided during registration via your account settings on our website, the app, or by contacting support through our email <a href="mailto:support@sparksinger.com">support@sparksinger.com</a>.
            </Typography>
            <Typography variant="body1" paragraph>
              You can delete your account through the profile settings in the mobile app. Alternatively, you can request the deletion of your Sparksinger account by reaching out to our support team through the email <a href="mailto:support@sparksinger.com">support@sparksinger.com</a>.
            </Typography>
            <Typography variant="body1" paragraph>
              We retain your information in our server logs, databases, and records only for as long as necessary to fulfill the purposes outlined in this policy, subject to any legal obligations requiring us to retain such information. Third-party service providers may be used to store and manage this data on our behalf.
            </Typography>
            <Typography variant="body1" paragraph>
              Verification-related information is retained for a reasonable period, even if you do not complete all verification steps. This helps facilitate future verifications and prevents abuse of our verification processes.
            </Typography>
            <Typography variant="body1" paragraph>
              If you create content with other users, the recorded performances that are part of such group collaborations will remain accessible even after your account is deleted.
            </Typography>
            </Box>
  
    {/* Copyright and Intellectual Property Infringement Policy */}
    {/* <Typography variant="h4" gutterBottom sx={{ marginTop: 4 }}>
      COPYRIGHT AND INTELLECTUAL PROPERTY INFRINGEMENT POLICY
    </Typography>
    <Typography variant="body1" paragraph>
      <strong>(Updated  march 19, 2025)</strong>
    </Typography>
    <Typography variant="body1" paragraph>
      To protect the rights of copyright holders, it is SparkSinger’s policy to respond to written notices of alleged infringement that comply with the Digital Millennium Copyright Act ("DMCA").
    </Typography>
  
    <Typography variant="h6" gutterBottom fontWeight={"bold"}>
      RESPECT OF THIRD-PARTY RIGHTS
    </Typography>
    <Typography variant="body1" paragraph>
      SparkSinger respects the intellectual property of others and takes the protection of intellectual property very seriously, and we ask our users to do the same. Infringing activity will not be tolerated on or through the Service.
    </Typography>
  
    <Typography variant="h6" gutterBottom fontWeight={"bold"}>
      REPEAT INFRINGER POLICY
    </Typography>
    <Typography variant="body1" paragraph>
      SparkSinger’s intellectual property policy is to (a) remove material that SparkSinger believes in good faith, upon notice from an intellectual property owner or their agent, is infringing the intellectual property of a third party by being made available through the Service, and (b) remove any Content posted to the Service by “repeat infringers.” SparkSinger considers a “repeat infringer” to be any user that has uploaded Content to the Service and for whom SparkSinger has received more than two takedown notices compliant with the provisions of 17 U.S.C. § 512(c) with respect to such Content. SparkSinger has discretion to terminate the account of any user of the Service after receipt of a single notification of claimed infringement or upon SparkSinger’s own determination.
    </Typography>
  
    <Typography variant="h6" gutterBottom fontWeight={"bold"}>
      PROCEDURE FOR REPORTING CLAIMED INFRINGEMENT
    </Typography>
    <Typography variant="body1" paragraph>
      If You believe that any Content made available on or through the Service has been used or exploited in a manner that infringes an intellectual property right You own or control, promptly send a “Notification of Claimed Infringement” containing the following information to the Designated Agent identified below. Your communication must include substantially the following:
    </Typography>
    <ul>
      <li>A physical or electronic signature of a person authorized to act on behalf of the owner of the work(s) that has/have been allegedly infringed;</li>
      <li>Identification of the works or material claimed to have been infringed, or, if multiple works at a single online site are covered by a single notification, a representative list of such works at that site;</li>
      <li>Identification of the specific material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient for SparkSinger to locate the material;</li>
      <li>Information reasonably sufficient to permit SparkSinger to contact You, such as an address, telephone number, and, if available, an electronic mail address at which the complaining party may be contacted;</li>
      <li>A statement that You have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and</li>
      <li>A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
    </ul>
    <Typography variant="body1" paragraph>
      You should consult with Your lawyer and/or see 17 U.S.C. § 512 of the DMCA to confirm Your obligations to provide a valid notice of claimed infringement.
    </Typography>
    <Typography variant="body1" paragraph>
      A copy of Your Notice of Claimed Infringement will be sent to the person who uploaded the material addressed in the notice, who may submit a counter notification, as described below, that could result in restoring material removed in response to Your notice.
    </Typography>
    <Typography variant="body1" paragraph>
      For a Notice of Claimed Infringement involving intellectual property claims other than copyright, a statement made under penalty of perjury is preferred but not required.
    </Typography>
  
    <Typography variant="h6" gutterBottom fontWeight={"bold"}>
      COPYRIGHT CONTACT INFORMATION
    </Typography>
    <Typography variant="body1" paragraph>
      Send written notices for claimed infringement by email or by postal mail to:
    </Typography>
    <Typography variant="body1" paragraph>
  SparkSinger, Inc.<br />
  Attention: Copyright Agent<br />
  10601 Clarence Dr. Suite 250<br />
  Frisco, TX, USA 75033<br />
  <a href="mailto:support@sparksinger.com">support@sparksinger.com</a>
</Typography>
  
    <Typography variant="h6" gutterBottom fontWeight={"bold"}>
      COUNTER-NOTIFICATION (COPYRIGHTS ONLY)
    </Typography>
    <Typography variant="body1" paragraph>
      If You receive a notification from SparkSinger that material made available by You on or through the Service has been the subject of a Notification of Claimed Infringement, You will have the right to provide SparkSinger with what is called a “Counter Notification.” To be effective, a Counter Notification must be in writing, provided to SparkSinger’s Copyright Agent to the same email or postal address identified above for reporting copyright infringement. Your Counter Notification should include substantially the following information:
    </Typography>
    <ul>
      <li>Your physical or electronic signature;</li>
      <li>Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled;</li>
      <li>A statement, under penalty of perjury, that You have a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled;</li>
      <li>Your name, physical address, and telephone number; and</li>
      <li>A statement that You consent to the jurisdiction of a United States District Court for the judicial district in which Your address is located or, if your address is outside of the United States, for any judicial district in which SparkSinger may be found, and that You will accept service of process from the person who provided a notice in compliance with SparkSinger’s Procedure for Reporting Claimed Infringement as described above.</li>
    </ul>
    <Typography variant="body1" paragraph>
      If You are submitting a Counter Notification, You should consult a lawyer or see 17 U.S.C. § 512 of the DMCA to confirm Your obligations to provide a valid counter notification under the DMCA.
    </Typography>
    <Typography variant="body1" paragraph>
      If You submit a Counter Notification, a copy of the counter notification, including Your name and contact information, will be sent to the copyright owner or person who provided the Notification of Claimed Infringement.
    </Typography>
    <Typography variant="body1" paragraph>
      SparkSinger reserves the right to seek damages from any party that submits a notification of claimed infringement or counter notification in violation of the law.
    </Typography> */}
  
    {/* FAQ Section */}
    {/* <Box sx={{ display: "flex", flexDirection: 'column', width: "60%", padding: 6, gap: 3, margin: '0 auto' }}>
      <Typography sx={{ fontSize: 30, fontWeight: "bold" }}>FAQ</Typography>
      {faqs && faqs.map((faq, index) => (
        <Accordion key={index} sx={{ backgroundColor: "#F7F7F7" }} expanded={expanded === `panel${index + 1}`} onChange={handleChange(`panel${index + 1}`)}>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls={`panel${index + 1}-content`} id={`panel${index + 1}-header`}>
            <Typography sx={{ fontSize: '1.2rem', color: "#7D3DE7", fontWeight: "bold", fontSize: 20 }}>
              {faq.Question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {faq.Answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box> */}
  </Box>
  );
}

export default PrivacyPolices;
