import { Box, Container } from '@mui/material';
import React, { useState } from 'react'
import OtherProfileHeader from './components/header';

const OtherUserProfile = () => {
  console.log(window.location.href,'Window Locationnn')
  return (
    <Box sx={{minHeight:'100vh'}}>
        <OtherProfileHeader/>
    </Box>
  )
}

export default OtherUserProfile;