import { Avatar, Box, Typography } from '@mui/material'
import React from 'react'
import girl from '../../../assest/images/girl.webp'
import { deepOrange, deepPurple } from '@mui/material/colors';
import RedoIcon from '@mui/icons-material/Redo';
import MicExternalOnIcon from '@mui/icons-material/MicExternalOn';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { FaEdit } from "react-icons/fa";
const Heading = ({songlyric}) => {
  console.log(songlyric)
  return (
   
    <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",gap:2,alignItems:"center"}}>
        <Box>
           {
            songlyric.length>0? <img src={songlyric[0]['CoverImageURL']} style={{width:"120px",height:"120px"}}></img>:""
           }
        </Box>
        <Box>
            {/* <Box><Typography fontSize={13} sx={{color:"#2D2867"}}>Anne-Marie</Typography></Box>
            <Box><Typography fontSize={25} sx={{color:"#2D2867"}}> Heavy</Typography></Box>
            <Box><Typography fontSize={10} sx={{color:"#2D2867"}}>Published by
ANNEMARIE

</Typography></Box> */}
<Box sx={{display:"flex",flexDirection:"column",gap:2,marginTop:4}}>
  
<Box><Avatar sx={{ backgroundColor:"red",width:50,height:50}}><PriorityHighIcon style={{color:'white'}}/></Avatar>
<Box><Typography fontSize={16} marginLeft={1}>Error</Typography></Box>
</Box>
            {/* <Box><Avatar sx={{backgroundColor:"#6C2EFF",width:50,height:50}}><MicExternalOnIcon/></Avatar>
            <Box><Typography fontSize={16} marginLeft={1}>sing</Typography></Box>
            </Box> */}
</Box>
        </Box>
    </Box>
  )
}

export default Heading