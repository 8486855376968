import { configureStore } from '@reduxjs/toolkit'
import audioReducer from '../features/addlyric/addLyric'
import stickerReducer  from '../features/addlyric/addSticker'
import playerReducer  from '../features/redux/player'

export const store = configureStore({
  reducer: {
    Upload:audioReducer,
    Uploads:stickerReducer,
    player:playerReducer,
  },
})