

const uploaBeat=[
    {
        id:1,
        tittle:"upload youe beat",
        path:"/uploadbeat/beats"
    },
    {
        id:2,
        tittle:"Add Beat Details",
        path:"/uploadbeat/beatdetail"
    },
    {
        id:3,
        tittle:"Mark First Verse Start",
        path:"/uploadbeat/beatmark"
    }
]

export default uploaBeat;