import React, { useEffect, useState } from 'react'
import Header from './componenets/header'
import { Box, Card, Container, Typography,Modal, Button, Divider, Checkbox, CircularProgress } from '@mui/material'
import Footer from './componenets/footer'
import BasicTabs from './componenets/song-tabs'
import OfflinePinRoundedIcon from '@mui/icons-material/OfflinePinRounded';
import { useLocation, useParams } from 'react-router-dom'
import axios from 'axios'
import { url } from '../../config/config';
import { FaEdit } from "react-icons/fa";
import { jwtDecode } from 'jwt-decode';
import { GiSaveArrow } from "react-icons/gi";
import ClearIcon from '@mui/icons-material/Clear';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { GiMicrophone } from "react-icons/gi";
import { useNavigate } from 'react-router-dom';
import { FaCamera } from "react-icons/fa";
import { useSelector } from 'react-redux'
import guna from './componenets/guna.jpg'
import { useDispatch } from 'react-redux';
import { KaraokeEdit, KaraokeTiming } from '../../features/addlyric/addLyric'
import CancelIcon from '@mui/icons-material/Cancel';
import FlagIcon from '@mui/icons-material/Flag';
import image from "../../assest/images/NewLogo.png"
import { FaMusic } from 'react-icons/fa6'
import { FadeLoader } from 'react-spinners'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const Songs = () => {
  let location = useLocation();
  const[karaokeLyric,setKaraokeLyric]=useState([])
  const [karaoke,setKaraoke] = useState([])
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [coverImg,setCoverImg] = useState(null)
  console.log(params.id,"songesjahaau")
  const value = useSelector((data) => data)
  console.log(value)
  const [karaokeTitle,setKaraokeTitle] = useState(false)
  const [karaokeSinger,setKarokeSinger] = useState(false)
  const [titleValue,setTitleValue] = useState('')
  const [artistValue,setArtistValue] = useState('')
  const [reportModal,setReportModal] = React.useState(false)
  const [selectedReportCategory,setSelectedReporCategory] = React.useState('');
  const [discription,setDiscription] = React.useState(false)
  const [discriptionText,setDiscriptionText] = React.useState('')
  const [reportSubmitted,setReportSubmitted] = React.useState(false);
  const [reportData,setReportData] = React.useState([])
  const testArrey = [
    {
      value : ['vijay'],
      num : {
        vijay : 'true'
      }
    }
  ]

  
   useEffect(()=>{
       
    const GetSongbook=async()=>{
    
     
     try {  
      const config={
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
       const res = await axios.get(`${url}/api/Karaoke/getKaraokeByID/${params.id}`,config);
       setKaraoke(res.data.data)
     console.log(res.data.data,"song..............")
      
   } catch (error) {
     console.log(error)
   }
   //    
    }
    GetSongbook()
 
  },[])

  React.useEffect(() => {
    const getReportTypes =  async () => {
      try{
        const config={
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const response = await axios.get(`${url}/api/ReportType/getReportType`,config)
        console.log(response.data,'reportValuesss')
        setReportData(response.data.data)
      }catch (error){
         console.log(error)
      }
    }
    getReportTypes()
  },[])

  const dispatch = useDispatch()
  console.log(karaoke)
  const userTokenData = localStorage.getItem('token') !== null ? jwtDecode(localStorage.getItem('token')) : '';
// console.log(userTokenData)
const [editKaraoke,setEditKaraoke] = useState(false)
const [open,setOpen] = useState(false)
const handleClose = () => {
  setOpen(false)
}
const handleDelete = async () => {
   try{
      const config={
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
      const res = await axios.delete(`${url}/api/Karaoke/deleteKaraoke/${params.id}`,config)
      console.log(res.data.data)
      if(res.data.data !== ''){
        navigate('/song')
      }
    } catch (error) {
      console.log(error)
    }
    setOpen(false)
}
const url1 = coverImg != null ? URL.createObjectURL(coverImg) : '';
console.log(url1)
const handleEditTiming = async () => {
   const karaokeDetails = await karaoke && karaoke[0].KaraokeLyrics 
    const data ={
      lyricTiming : karaokeDetails,
      lyricColor : karaoke && karaoke[0].Color,
      karaokeDetails : {
        artist : karaoke && karaoke[0].Artist,
        genres : karaoke && karaoke[0].Genre,
        title : karaoke && karaoke[0].Name,
        tags : karaoke && karaoke[0].Tags,
        url : url1 !== '' ? url1 : karaoke && karaoke[0].CoverImageURL
      },
      karaokeUrl : {
        url : karaoke && karaoke[0].KaraokeUrl
      }
    }
    const value = {
      edited : true
    }
    dispatch(KaraokeEdit(value))
    dispatch(KaraokeTiming(data))
    navigate('/uploads/songtiming')
    localStorage.setItem('karaokeId',params.id)
}
const handleEditLyric = async () => {
  const karaokeDetails = await karaoke && karaoke[0].KaraokeLyrics 
   const data ={
     lyricTiming : karaokeDetails,
     lyricColor : karaoke && karaoke[0].Color,
     karaokeDetails : {
       artist : karaoke && karaoke[0].Artist,
       genres : karaoke && karaoke[0].Genre,
       title : karaoke && karaoke[0].Name,
       tags : karaoke && karaoke[0].Tags,
       url : karaoke && karaoke[0].CoverImageURL
     },
     karaokeUrl : {
       url : karaoke && karaoke[0].KaraokeUrl
     }
   }
   const value = {
    edited : true
  }
  dispatch(KaraokeEdit(value))
   dispatch(KaraokeTiming(data))
   localStorage.setItem('karaokeId',params.id)
   navigate('/uploads/songlyrics')
}
const handleEditStructure = async () => {
  const karaokeDetails = await karaoke && karaoke[0].KaraokeLyrics 
   const data ={
     lyricTiming : karaokeDetails,
     lyricColor : karaoke && karaoke[0].Color,
     karaokeDetails : {
       artist : karaoke && karaoke[0].Artist,
       genres : karaoke && karaoke[0].Genre,
       title : karaoke && karaoke[0].Name,
       tags : karaoke && karaoke[0].Tags,
       url : karaoke && karaoke[0].CoverImageURL
     },
     karaokeUrl : {
       url : karaoke && karaoke[0].KaraokeUrl
     }
   }
   const value = {
    edited : true
  }
  dispatch(KaraokeEdit(value))
   dispatch(KaraokeTiming(data))
   localStorage.setItem('karaokeId',params.id)
   navigate('/uploads/songstructure')
}

const handleImageChange = async (e) => {
  setCoverImg(e.target.files[0])
  testArrey[0].value.unshift(url1)
  // const image = await e.target.files[0]
  // const url1 = await URL.createObjectURL(image)
  // const karaokeDetails = await karaoke && karaoke[0].KaraokeLyrics 
  // const data ={
  //   lyricTiming : karaokeDetails,
  //   lyricColor : karaoke && karaoke[0].Color,
  //   karaokeDetails : {
  //     artist : karaoke && karaoke[0].Artist,
  //     genres : karaoke && karaoke[0].Genre,
  //     title : karaoke && karaoke[0].Name,
  //     tags : karaoke && karaoke[0].Tags,
  //     url :  karaoke && karaoke[0].CoverImageURL
  //   },
  //   karaokeUrl : {
  //     url : karaoke && karaoke[0].KaraokeUrl
  //   }
  // }
  // dispatch(KaraokeTiming(data))
}
console.log(coverImg)
const [saveConfrim,setSaveConfrim] = useState(false)
const hadldeEditKaraoke =  async () => {
   const karaokeDetails = await karaoke && karaoke[0].KaraokeLyrics
   const data ={
    lyricTiming : karaokeDetails,
    lyricColor : karaoke && karaoke[0].Color,
    karaokeDetails : {
      artist : karaoke && artistValue !== '' ? artistValue : karaoke[0].Artist,
      genres : karaoke && karaoke[0].Genre,
      title : karaoke && titleValue !== '' ? titleValue : karaoke[0].Name,
      tags : karaoke && karaoke[0].Tags,
      url :  karaoke && karaoke[0].CoverImageURL
    },
    karaokeUrl : {
      url : karaoke && karaoke[0].KaraokeUrl
    }
  }
  dispatch(KaraokeTiming(data))
  setSaveConfrim(true)
}
const [editConfrim,setEditConfrim] = useState(false)
const handleEditCancel = () => {  
  // const value = {
  //   edited : true
  // }
  // dispatch(KaraokeEdit(value))
  setEditConfrim(true)
}

const handleEditClose = () => {
  setEditConfrim(false)
}

const handleConfrim = () => {
  const data = {
    edited : false
  }
  dispatch(KaraokeEdit(data))
  value&&value?.Upload?.KaraokeEdited[0]?.isTrue && editKaraoke === false ? navigate(-1) : setEditKaraoke(false)
  setEditConfrim(false)
}

const handleSaveConfrim = async () => {
  setLoading(true); // Start loading
  const response = await fetch(url1);
  const blob = await response.blob();
  console.log(blob)
  const formData = new FormData();
  if(coverImg !== null) {
    formData.append('cover',blob)
  }
  formData.append('SongData', JSON.stringify(value.Upload));

  try{
    const config={
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }
    const res = await axios.patch(`${url}/api/Karaoke/updateKaraoke/${params.id}`,formData,config)
    console.log(res)
  }catch (error){
      console.log(error)
  }finally {
    setLoading(false); // Stop loading
    setSaveConfrim(false)
    window.location.reload();
  }
  // setSaveConfrim(false)
  // window.location.reload()
}
const handleCloseModal = () => {
  setSaveConfrim(false); // Close the modal immediately
};

const handleEdit = () => {
  setEditKaraoke(true)
  const data = {
    edited : true
  }
  dispatch(KaraokeEdit(data))
}
testArrey[0].value.unshift(url1)
console.log(testArrey)

const [screenWidth,setScreenWidth] = useState(window.innerWidth);
useEffect(() => {
 const handleResize = () => {
   setScreenWidth(window.innerWidth);
 };

 window.addEventListener('resize', handleResize);

 // Cleanup the event listener on component unmount
 return () => {
   window.removeEventListener('resize', handleResize);
 };
}, []);

const handleReportModal = () => {
  setReportModal(false)
 }

 const handleCategorySelected = (data) => {
   setSelectedReporCategory(data)
 }
const handleDescriptionChange = (e) => {
  if(discription){
    setDiscription(false)
  }else{
    setDiscription(true)
  }
}

const handleReportSupport  = async () => {
  setReportSubmitted(true)
  try{
    const config={
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }
    const data = {
      Description : discriptionText,
      ReportTypeID : selectedReportCategory._id,
      ReportedByUserID : userTokenData.userId,
      ResourceID : params.id
    }
     const Response = await axios.post(`${url}/api/Report/createReport/Karaoke`,data,config)
     console.log(Response.data,'dataValuessssss')
     function modalLoderFunc () {
      if(Response.data.message === 'Document Create successfully'){
        setSelectedReporCategory('')
        setDiscription(false)
        setReportSubmitted(false)
        setReportModal(false)  
       }
     }
     setTimeout(modalLoderFunc,4000)
  }catch (error){
    console.log(error)
  }
}
const handlePrivacy = () => {
  navigate("/about")
} 
const handleTerms = () => {
  navigate("/apps")
} 
const handleCopyright = () => {
  navigate("/careers")
} 
const handleGuidelines = () => {
  navigate("/guidelines")
} 

  return (
  <Box sx={{backgroundColor:"#F0F2F4",minHeight:"100vh"}}>
    {
        value && value?.Upload?.KaraokeEdited[0].isTrue || editKaraoke ?  <Container maxWidth='md' >
      <Box sx={{display:'flex',flexDirection:'column',gap:2}}>
        {
          karaoke && karaoke.map((val,ind) => (
            <>
            <Box>
            
          <Card sx={{width:1000}}>
            <Box sx={{display:'flex',justifyContent:'space-between',padding:'0px'}}>
              <Box>
                <Box sx={{display:'flex',gap:2,width:"500px"}}>
                <Box style={{ position: 'relative', display: 'inline-block' }}>
  <Box>
    <img
      src={coverImg != null ? url1 : val.CoverImageURL}
      style={{ width: '190px', height: '190px', display: 'block' }}
    />
  </Box>
  <input
    onChange={handleImageChange}
    type="file"
    name="file"
    id="file"
    className="inputfile"
    style={{ display: 'none' }}
  />
  <label
    style={{
      opacity: 1, // Change opacity to 1
      background: 'rgba(255, 255, 255, 0.5)',
      padding: '10px',
      borderRadius: '50%',
      position: 'absolute',
      bottom: '10px', // Position the icon at the bottom
      right: '10px', // Position the icon at the right
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      transition: 'opacity 0.3s ease',
      zIndex: 1, // Ensure it's on top
    }}
    htmlFor="file"
  >
    <FaCamera size={30} color="black" />
  </label>
</Box>
                  <Box>
                  <Card sx={{ background: "#F0F2F4", marginTop: 5, width: "680px", padding: '15px' }}>
  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
    {karaokeTitle ? (
      <Box>
        <input
          onChange={(e) => setTitleValue(e.target.value)}
          style={{
            border: 'none',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            height: '30px',
            width: '430px',
            marginRight: '10px',
            paddingLeft: '10px'
          }}
          type='text'
          defaultValue={val.Name}
        />
      </Box>
    ) : (
      <Box
        sx={{
          fontSize: '30px',
          fontWeight: 'bold',
          color: 'rgb(18, 11, 39)',
          overflow: 'hidden',
          flexGrow: 1,
        }}>
        {val.Name}
      </Box>
    )}
    <Button
      sx={{
        backgroundColor: "#6C2EFF",
        color: "white",
        padding: '10px',
        borderRadius: '5px',
        cursor: 'pointer',
        fontWeight:"bold"
      }}
      onClick={() => setKaraokeTitle(true)}
    >
      Edit
    </Button>
  </Box>

  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    {karaokeSinger ? (
      <Box>
        <input
          onChange={(e) => setArtistValue(e.target.value)}
          style={{
            border: 'none',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            height: '30px',
            width: '430px',
            marginRight: '10px',
            paddingLeft: '10px'
          }}
          type='text'
          defaultValue={val.Artist}
        />
      </Box>
    ) : (
      <Box
        sx={{
          fontSize: '20px',
          fontWeight: 400,
          color: 'rgb(149, 150, 150)',
          flexGrow: 1,
        }}>
        {val.Artist}
      </Box>
    )}
    <Button
      sx={{
        backgroundColor: "#6C2EFF",
        color: "white",
        padding: '10px',
        borderRadius: '5px',
        cursor: 'pointer',
        fontWeight:"bold"
      }}
      onClick={() => setKarokeSinger(true)}
    >
      Edit
    </Button>
  </Box>
</Card>

                  </Box>
                </Box>
              </Box>
              {/* <Box>
                <Box sx={{display:'flex',color:"black",backgroundColor:"#008CBA",padding:'10px',gap:2,borderRadius:'5px',marginTop:'40px',cursor:'pointer'}}>
                  <Box><GiSaveArrow color='black' size={20}/></Box>
                  <Box sx={{color:"black",backgroundColor:"#008CBA"}} onClick={hadldeEditKaraoke}>Save</Box>
                </Box>
                <Box sx={{display:'flex',color:"black",backgroundColor:"#e7e7e7",padding:'10px',marginTop:'15px',gap:2,borderRadius:'5px',cursor:'pointer'}} onClick={handleEditCancel}>
                  <Box><ClearIcon size={20}/></Box>
                  <Box sx={{backgroundColor:"#e7e7e7"}}>Cancel</Box>
                </Box>
                <Box sx={{display:'flex',marginTop:'40px',backgroundColor:'#C32516',color:'black'}} onClick={() => {setOpen(true)}}>
                  <Box><DeleteOutlineIcon style={{color:"black"}}/></Box>
                  <Box sx={{fontSize:'14px',marginTop:'4px',cursor:'pointer'}}>Delete song</Box>
                </Box>
              </Box> */}
            </Box>
          </Card>
        </Box>
        <Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
  <Box>
    <Card sx={{ width: '552px' }}>
      <Box
        sx={{display: 'flex',gap: 7,color: 'rgb(108, 100, 244)',textTransform: 'capitalize',fontSize: '14px',fontWeight: 'bold',
          padding: '10px', marginTop: '10px',}}>
        <Box sx={{ textTransform: 'capitalize',  cursor: 'pointer',  borderRadius: '5px', textAlign: 'center',
            '&:hover': {
              backgroundColor: 'rgba(108, 100, 244, 0.1)', }, }} onClick={handleEditTiming}> Edit Timing
        </Box>
        <Box
          sx={{
            textTransform: 'capitalize',
            cursor: 'pointer',
            textAlign: 'center',
            '&:hover': {
              backgroundColor: 'rgba(108, 100, 244, 0.1)',
            },
          }}
          onClick={handleEditLyric}
        >
          Edit Lyrics + Parts
        </Box>
        <Box
          sx={{
            textTransform: 'capitalize',
            cursor: 'pointer',
            textAlign: 'center',
            '&:hover': {
              backgroundColor: 'rgba(108, 100, 244, 0.1)',
            },
          }}
          onClick={handleEditStructure}
        >
          Edit Structure
        </Box>
      </Box>
      {val.KaraokeLyrics &&
        val.KaraokeLyrics.map((lyricData, lyricIndex) => (
          <Box
            key={lyricIndex}
            sx={{
              display: 'flex',
              gap: 10,
              padding: '5px 10px',
              marginTop: '10px',
              '&:hover': {
                backgroundColor: 'rgba(108, 100, 244, 0.05)',
              },
            }}
          >
            <Box sx={{ color: 'rgb(170, 170, 170)', fontSize: '21px' }}>
              {lyricData.convertedTime}
            </Box>
            <Box
              sx={{
                fontSize: '21px',
                color: 'rgb(34, 34, 34)',
                fontFamily: 'sans-serif',
              }}
            >
              {lyricData.Sentence}
            </Box>
          </Box>
        ))}
    </Card>
  </Box>
  <Box>
  <Box sx={{ marginBottom: '10px' }}>
  <Card>
    <Box
      sx={{display: 'flex',justifyContent: 'space-between',padding: '10px',gap: 9,backgroundColor: '#2D2867',color:"red",
        
      }}
    >
      <Box sx={{ color: "white", fontSize: '21px', fontWeight: 'bold' }}>Save song
      </Box>
      
    </Box>
    <Divider />
    <Box
      sx={{ display: 'flex',justifyContent: 'space-between', padding: '10px',alignItems: 'center',gap:5}}>
      <FaMusic style={{ fontSize: '30px', color: 'black', }} /> {/* Music icon on the left */}
      <Box
        sx={{display: 'flex', alignItems: 'center', gap: 2, padding: '10px', backgroundColor: '#6C2EFF', borderRadius: '5px', cursor: 'pointer',
          '&:hover': { backgroundColor: '#752ED6' },}} onClick={hadldeEditKaraoke}>
        <GiSaveArrow color="white" size={20} gap={5} />
        <Box sx={{ color: 'white', fontWeight: 'bold' }}>Save</Box>
      </Box>
    </Box>
  </Card>
</Box>
    <Box sx={{ marginBottom: '10px' }}>
    <Card>
    <Box
      sx={{display: 'flex',justifyContent: 'space-between',padding: '10px',gap: 9,backgroundColor: '#2D2867',
        '&:hover': { backgroundColor: '#2D2867' },
      }}
    >
      <Box sx={{ color: 'white', fontSize: '21px', fontWeight: 'bold' }}>Cancel song 
      </Box>
      
    </Box>
    <Divider />
    <Box
      sx={{ display: 'flex',justifyContent: 'space-between', padding: '10px',alignItems: 'center',}}>
      <FaMusic style={{ fontSize: '30px', color: 'black' }} /> {/* Music icon on the left */}
      <Box
        sx={{display: 'flex', alignItems: 'center', gap: 2, padding: '10px', backgroundColor: '#6C2EFF', borderRadius: '5px', cursor: 'pointer',
          '&:hover': { backgroundColor: '#752ED6' },}} onClick={handleEditCancel}>
        <ClearIcon sx={{color:"white"}} size={20} />
        <Box sx={{ color: 'white', fontWeight: 'bold' }}>Cancel</Box>
      </Box>
    </Box>
  </Card>
    </Box>
    <Box sx={{ marginBottom: '10px' }}>
    <Card>
    <Box
      sx={{display: 'flex',justifyContent: 'space-between',padding: '10px',gap: 9,backgroundColor: '#2D2867',
        '&:hover': { backgroundColor: '#2D2867' },
      }}
    >
      <Box sx={{ color: 'white', fontSize: '21px', fontWeight: 'bold' }}>Delete song
      </Box>
      
    </Box>
    <Divider />
    <Box
      sx={{ display: 'flex',justifyContent: 'space-between', padding: '10px',alignItems: 'center',}}>
      <FaMusic style={{ fontSize: '30px', color: 'black' }} /> {/* Music icon on the left */}
      <Box
        sx={{display: 'flex', alignItems: 'center', gap: 2, padding: '10px', backgroundColor: '#6C2EFF', borderRadius: '5px', cursor: 'pointer',
          '&:hover': { backgroundColor: '#752ED6' },}} onClick={() => {setOpen(true)}}>
        <DeleteOutlineIcon sx={{color:"white"}} size={20} />
        <Box sx={{ color: 'white', fontWeight: 'bold' }}>Delete</Box>
      </Box>
    </Box>
  </Card>
    </Box>
  </Box>
</Box>
        </Box></>
          ))
        }  
      </Box>
    </Container> :  <Container maxWidth={screenWidth < 960 ? 'xl' : 'md'} sx={{padding: screenWidth < 960 ? 0 : 5}}>
        
        <Card sx={{padding:2,display:"flex",minHeight:"100vh",justifyContent:"start",gap:4,paddingTop:'24px'}}>
        <Box>
            <Header songlyric={karaoke}/>
        </Box>
           <Box sx={{width:'100%'}}>
             <Box sx={{marginTop:'15px'}}>
              {
                karaoke.length !== 0 ?   <Typography  sx={{color:"#2D2867",fontSize:'14px',fontWeight:'700'}}>{ karaoke && karaoke[0]['Artist']}</Typography> : ''
              }
              <Box>
                {
                  karaoke.length !== 0 ?  <Typography  sx={{color:"#2D2867",fontWeight:700,fontSize:'32px',wordBreak: 'break-word', }}> { karaoke && karaoke[0]['Name']}</Typography> : ''
                }
             </Box>
           <Box sx={{display:"flex",alignItems:"center"}}> <Typography  sx={{color:"#2D2867",fontSize:'14px',fontWeight:'400px',}}>Published by<OfflinePinRoundedIcon sx={{color:"#8826FB",fontWeight:700}} fontSize='14px' fontWeight='700' />{karaoke && karaoke[0]?.userData[0]?.Name !== '' ?karaoke[0]?.userData[0]?.Name : 'user' }</Typography></Box>
             </Box>
             <Box sx={{marginTop:2}}>
              <BasicTabs  songlyric={karaoke}/>
             </Box>
           </Box>
           <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',gap:1.5}}>
           <Box onClick={() => {setReportModal(true)}} sx={{display:'flex',justifyContent:'flex-end'}}><FlagIcon  style={{color:'red',fontSize:'25px',cursor:'pointer'}}/></Box>
           {
            userTokenData.userId === karaoke[0]?.userData[0]?._id ?  <Box  sx={{background:'rgb(228 233 238)',width:'56px',height:'50px',textAlign:'center',borderRadius:'50%',display:'flex',justifyContent:'center',alignItems:'center'}} onClick={handleEdit}>
            <Box>
              <FaEdit color='rgb(114 140 166)'/>
            </Box>
           </Box> : ''
           }
           </Box>
           {/* {
            karaoke[0]?.AnimatedVideoURL !== undefined && karaoke[0]?.AnimatedVideoURL !== null ? <Box sx={{padding:'10px',background:'#6c2eff',color:'white',fontSize:'14px',fontWeight:700,height:'40px',width:'content-fit'}}>Animation Preview</Box> : ''
           } */}
        </Card>
        </Container>
        
    }

    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
         <Box sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
          <Box sx={{background:'rgb(187 187 187)',padding:'10px',borderRadius:'50%',width:'50px',height:'50px',textAlign:'center',display:'flex',alignItems:'center'}}>
          <Box sx={{alignItems:'center',textAlign:'center'}}><GiMicrophone color='white' size={40} style={{marginTop:'7px'}}/></Box>
          </Box>
         </Box>
         <Box sx={{textAlign:'center',fontSize:'1em',color:'rgb(61, 56, 57)',fontWeight:700,marginTop:'20px'}}>Delete Song ?</Box>
         <Box sx={{color:'rgb(138, 135, 136)',fontSize:'14px',fontWeight:400,textAlign:'center',marginTop:'10px'}}>Your song will be deleted from your profile and the songbook</Box>
         <Box sx={{textAlign:'center',display:'flex',gap:2,alignItems:'center',justifyContent:'center',marginTop:'20px'}}>
            <Button sx={{background:'rgb(240, 240, 240)',padding:'10px',color:'black',fontSize:'13px',fontWeight:'bold'}} onClick={() => setOpen(false)}>Cancel</Button>
            <Button sx={{background:'rgb(64, 52, 240)',padding:'10px',color:'white',fontSize:'13px',fontWeight:'bold'}} onClick={handleDelete}>Delete Song</Button>
         </Box>
        </Box>
      </Modal>
      <Modal
  open={editConfrim}
  onClose={handleEditClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
   
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
        <Box>
          <img src={image} alt="App Preview" style={{ width: 180, height: 'auto' }} />
        </Box>
        <Box>
          <Typography id="modal-title" variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
          Cancel the song
          </Typography>
          <Typography id="modal-description" variant="body1" sx={{ mb: 3 }}>
          Changes that you made may not be saved
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
  sx={{ backgroundColor:'#4C8650',color:"white",
    '&:hover': {
      backgroundColor: '#4CAF50', // Change background color on hover
      color: 'white', // Change text color
    },
  }}
  onClick={handleConfrim}
>
Leave
</Button>
     </Box>

            
          </Box>
        </Box>
      </Box>
</Modal>
<Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
         <Box sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
          <Box sx={{background:'rgb(187 187 187)',padding:'10px',borderRadius:'50%',width:'50px',height:'50px',textAlign:'center',display:'flex',alignItems:'center'}}>
          <Box sx={{alignItems:'center',textAlign:'center'}}><GiMicrophone color='white' size={40} style={{marginTop:'7px'}}/></Box>
          </Box>
         </Box>
         <Box sx={{textAlign:'center',fontSize:'1em',color:'rgb(61, 56, 57)',fontWeight:700,marginTop:'20px'}}>Delete Song ?</Box>
         <Box sx={{color:'rgb(138, 135, 136)',fontSize:'14px',fontWeight:400,textAlign:'center',marginTop:'10px'}}>Your song will be deleted from your profile and the songbook</Box>
         <Box sx={{textAlign:'center',display:'flex',gap:2,alignItems:'center',justifyContent:'center',marginTop:'20px'}}>
            <Button sx={{background:'rgb(240, 240, 240)',padding:'10px',color:'black',fontSize:'13px',fontWeight:'bold'}} onClick={() => setOpen(false)}>Cancel</Button>
            <Button sx={{background:'rgb(64, 52, 240)',padding:'10px',color:'white',fontSize:'13px',fontWeight:'bold'}} onClick={handleDelete}>Delete Song</Button>
         </Box>
        </Box>
      </Modal>
      <Modal
  open={saveConfrim}
  onClose={handleCloseModal}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>


  <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
        <Box>
          <img src={image} alt="App Preview" style={{ width: 180, height: 'auto' }} />
        </Box>
        <Box>
          <Typography id="modal-title" variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
          Save song 
          </Typography>
          <Typography id="modal-description" variant="body1" sx={{ mb: 3 }}>
          Your changes will appear shortly in Spark singer. If you have the app open on your device, quit and relaunch it to see your changes.
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
  sx={{ backgroundColor:'#4C8650',color:"white",
    '&:hover': {
      backgroundColor: '#4CAF50', // Change background color on hover
      color: 'white', // Change text color
    },
  }}
  onClick={handleCloseModal}
>
  Cancel
</Button>
<Button
  sx={{
    backgroundColor: "#4C8650",
    color: "white",
    "&:hover": {
      backgroundColor: "#4CAF50",
      color: "white",
    },
  }}
  onClick={handleSaveConfrim}
  disabled={loading} // Disable button while loading
>
  {loading ?  <FadeLoader color="LightGray"height={10} width={4} margin={-6} sx={{ color: "#6C2EFF" }} /> : "Confirm"}
</Button>

            
          </Box>
        </Box>
      </Box>
      
  </Box>
</Modal>
<Modal
        open={reportModal}
        onClose={handleReportModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 300 }}>
          {
            selectedReportCategory === '' &&  <Box sx={{display:'flex',justifyContent:'flex-end',cursor:'pointer'}} onClick={() => {setReportModal(false)}}>
            <CancelIcon style={{fontSize:'30px',color:'rgb(192, 204, 216)'}}/>
            </Box>
          }
         <Box sx={{textAlign:'center',color:'rgb(45, 40, 103)'}}>
          <FlagIcon color='rgb(45, 40, 103)'/>
         </Box>
          <Box sx={{fontSize:'24px',fontWeight:700,color:'rgb(45, 40, 103)',textAlign:'center'}}>Report <span style={{fontSize:'24px',fontWeight:700,color:'rgb(45, 40, 103)'}}>{selectedReportCategory.Name}</span></Box>
          {/* <Box sx={{fontSize:'24px',fontWeight:700,color:'rgb(45, 40, 103)',textAlign:'center'}}>{selectedReportCategory.Name}</Box> */}
          <Box sx={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:500,mt:.5,textAlign:'center'}}>Are you sure you want to report this recording for a community <span style={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:500}}>{selectedReportCategory.Name}?</span></Box>
          {
            selectedReportCategory !== '' &&  <Box sx={{display:'flex',mt:1,alignItems:'center',gap:.5}}>
            <Box><Checkbox sx={{padding:0}} size='small' onChange={handleDescriptionChange} checked={discription}/></Box> 
            <Box sx={{fontSize:'14px',color:'rgb(45, 40, 103)',fontWeight:700}}>Description</Box>
         </Box>
          }
          {
            discription && selectedReportCategory !== '' && <Box sx={{mt:1}}>
              <textarea onChange={(e) => {setDiscriptionText(e.target.value)}} style={{width:'100%',height:'80px',paddingInlineStart:'10px',paddingTop:'5px'}}></textarea>
            </Box>
          }
         {
           selectedReportCategory !== ''  ? <Box sx={{display:'flex',flexDirection:'column',gap:1,mt:3}}>
              <Box sx={{width:'100%',textAlign:'center',backgroundColor:'rgb(45, 40, 103)',color:'white',height:'40px',borderRadius:'5px',fontSize:'17px',fontWeight:700,alignItems:'center',display:'flex',justifyContent:'center',cursor:'pointer'}} onClick={handleReportSupport}>
                {
                  reportSubmitted ? <CircularProgress size={25} sx={{color:'white',fontSize:'10px'}}/> : <Box>Report</Box>
                }
              </Box>
              <Box sx={{width:'100%',textAlign:'center',backgroundColor:'rgb(228, 233, 238)',color:'rgb(114, 140, 166)',height:'40px',borderRadius:'5px',fontSize:'17px',fontWeight:700,alignItems:'center',display:'flex',justifyContent:'center',cursor:'pointer'}} onClick={() => {
                setSelectedReporCategory('')
                setDiscription(false)
                }}>Cancel</Box>
           </Box> :  <>
         {
          reportData && reportData.map((val,ind) => (
            <>
             {
               val.resourceTypes && val.resourceTypes.map((data,index) => (
                   data.Name === 'Karaoke' && <p onClick={() => {handleCategorySelected(val)}} style={{color:'rgb(45, 40, 103)',fontSize:'17px',fontWeight:700,borderBottom:'1px solid rgb(192 204 216)',padding:'5px',cursor:'pointer'}}>{val.Name}</p>
                ))
             }
            </>
          ))
         }
         </>
         }
        </Box>
      </Modal>
      
  </Box>
  )
}

export default Songs