import React, { useMemo } from 'react'
import Header from './components/header'
import Cardvideo from './components/Card-video'
import { Alert, AlertTitle, Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Snackbar, Typography } from '@mui/material'
import Cardssong from './components/cards-song'
import Startpage from './components/start-page'
import { useState, useEffect } from 'react'
import Press from '../press'
import Spark from '../spark'
import homeimage from '../../assest/images/sparksingerim.png'
import man from '../../assest/images/man.png'
import goul from "../../assest/images/satellite.png"
import { Cancel, Close, MusicNote, MusicNoteOutlined, MusicNoteRounded, MusicNoteTwoTone, MusicOffSharp, PlayCircle, Star, Stars } from '@mui/icons-material'
import Meta from '../../components/meta_data'
const Home = () => {

  const [scrollPosition, setPosition] = useState({ scrollY: 0 })


  // const handleOpen = () => {
  //   setOpen(true);
  // };


  useEffect(() => {
    function updatePosition() {
      setPosition({ scrollY: window.scrollY })
    }

    window.addEventListener('scroll', updatePosition)
    updatePosition()

    return () => window.removeEventListener('scroll', updatePosition)
  }, [])





  return (
    <Box >
          <Meta title="Spark-Singing karaoke solo, duet, or with friends! 5M+ songs with lyrics and more!" description="SparkSinger is the #1 karaoke app. Sing 14M+ songs with lyrics! Record with pro audio FX to sound your best! Sing karaoke solo, or duet with friends and popular artists" keywords="Karoke, Singing, SparkSinger" />
      {/* <Box sx={{display:"flex",marginLeft:"50%"}}>
        <Snackbar
        open={openSnackbar}
        autoHideDuration={8000} // Duration in ms
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert severity="info" sx={{ width: '100%' }}>
          This is an info notification.
        </Alert>
      </Snackbar></Box> */}
      <Box>
      <div>
      {/* <Button variant="contained" color="primary" onClick={handleOpen}>
        Launch static backdrop modal
      </Button> */}
      
  
      <Box>
      {/* <Dialog
        open={open}
        onClose={() => {}}
        disableEscapeKeyDown
        aria-labelledby="avatar-countdown-modal-title"
        aria-describedby="avatar-countdown-modal-description"
      >
        <DialogTitle
          id="avatar-countdown-modal-title"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            position: 'relative',
          }}
        >
          <Avatar
            sx={{
              width: 50,
              height: 50,
              backgroundColor: '#4521B6',
              fontSize: 24,
              color: 'white',
            }}
          >
            {countdown > 0 ? countdown : <Cancel onClick={handleClose} sx={{fontSize:35}}/>} 
          </Avatar>
    
        </DialogTitle>
        <DialogContent id="avatar-countdown-modal-description" sx={{ textAlign: 'center' }}>
          <Typography variant="body1">
            {countdown > 0 ? 'Please wait while the countdown completes.' : 'The countdown is complete. You can close the modal now.'}
          </Typography>
        </DialogContent>
      </Dialog> */}
    </Box>
    </div>
      </Box>
       
       <Box  sx={{background:scrollPosition.scrollY===0?"#140E18":"linear-gradient(to right, #673EF7, #8A5DFE)",color:"white",padding:1,transition: "background 0.5s ease",}}>
       <Header/>
       {/* <Box  sx={{}}>
       {stars.map((star, index) => (
        <Box
          key={`star-${index}`}
          sx={{
            position: "absolute",
            top: star.top,
            left: star.left,
            animation: `floatStar ${scrollSpeed}s infinite alternate ease-in-out`,
            opacity: star.opacity,
            "@media (max-width: 600px)": { // Mobile responsiveness
              top: star.top.replace('vh', 'vw'),
              left: star.left.replace('vw', 'vh'),
            },
          }}
        >
          <Star sx={{ fontSize: star.size, color: star.color }} />
        </Box>
      ))}
      {notes.map((note, index) => (
        <Box
          key={`note-${index}`}
          sx={{
            position: "absolute",
            top: note.top,
            left: note.left,
            animation: `floatStar ${scrollSpeed}s infinite alternate ease-in-out`,
            opacity: note.opacity,
            "@media (max-width: 600px)": { // Mobile responsiveness
              top: note.top.replace('vh', 'vw'),
              left: note.left.replace('vw', 'vh'),
            },
          }}
        >
          <MusicNoteRounded sx={{ fontSize: note.size, color: note.color }} />
        </Box>
      ))}
       </Box> */}
       
      
        

        <Box sx={{backgroundImage: `url(${homeimage})`,
                   backgroundPosition: "center top",
                   backgroundRepeat: "no-repeat",
                 
                   padding: { xs: 2, sm: 4, md: 2 }, 
          zIndex: -1,
          animation: "rotateImage 10s linear infinite",}}>
               <style>
               {`
        
          
        @keyframes floatStar {
            0% { transform: translateY(0px) rotate(0deg); }
            100% { transform: translateY(-30px) rotate(20deg); }
          }
             @keyframes musicPulse {
            0% { transform: scale(1); opacity: 0.9; }
            100% { transform: scale(1.2); opacity: 1; }
          }
        `}
      </style>
       
        <Cardvideo />
        </Box>
      </Box>
      <Box sx={{ padding: { xs: 5, md: 12 }, backgroundColor: "#FFFFFF" }}>
        <Cardssong />
      </Box>
      <Box sx={{ background: '#4521B6', color: "white", padding: { xs: 3, sm: 5 } }}>
        <Startpage />
      </Box>


    </Box>
  )
}

export default Home