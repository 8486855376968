import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Fade,
  Divider,
  TextField,
  Grid
} from "@mui/material";
import { keyframes } from "@emotion/react";
import logo from '../assest/images/Bright Color-01.png';
import axios from "axios";
import { url } from "../config/config";

// Animations for text and button
const textAnimation = keyframes`
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
`;

const buttonPulse = keyframes`
  0% { box-shadow: 0 0 10px rgba(255, 255, 255, 0.6); }
  50% { box-shadow: 0 0 20px rgba(255, 255, 255, 1); }
  100% { box-shadow: 0 0 10px rgba(255, 255, 255, 0.6); }
`;

const AnimatedCouponModal = ({func}) => {
  const [open, setOpen] = useState(false);
  const [couponCode,setCouponCode] = useState('')
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
   const [errorMessage,setErrormessage] = useState(false)
  console.log(errorMessage,'messageVlauessss')
  const handleCouponApplied  = async () => {
      try{
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        };

        const res = await axios.get(`${url}/api/Coupon/getCouponByID/${couponCode}`,config)
        if(res.data?.data.length !== 0){
          func(res.data.data)
          setErrormessage(false)
        }else{
          setErrormessage(true)
        }
      }catch (error){
        console.log(error)
      }
  }

  return (
    <div>
      {/* Open Modal Button */}
      <Button
        variant="contained"
        onClick={handleOpen}
        sx={{
          fontWeight: "bold",
          textTransform:'capitalize',
          px: 4,
          py: 1.5,
          fontSize: "1em",
          background: "linear-gradient(45deg, #e322de, #8826fb, #3693e6)",
          color: "#fff",
          borderRadius: "12px",
          animation: `${buttonPulse} 2s infinite`,
                  "&:hover": {
                    background: "linear-gradient(45deg, #ff5722, #ff9800, #ffeb3b)",
                  },
        }}
      >
        Enter Coupon
      </Button>

      {/* Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        aria-labelledby="coupon-modal-title"
        aria-describedby="coupon-modal-description"
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "transparent", // Transparent for gradient
              borderRadius: 3, // Increased width for better spacing
              display: "flex",
              flexDirection: "row", // Layout side by side
              background: "linear-gradient(to bottom, #6853ed, #ae5aec)",
              color: "#fff",
              boxShadow: "0 10px 20px rgba(0,0,0,0.5)",
              overflow: "hidden", // Prevents circle shapes from overflowing the modal
              position: "relative",
              width:900,
              height:400
            }}
          >
            {/* Left section (logo and text) */}
            <Box sx={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",padding:'40px'}}>
              <img style={{ width: "180px", marginBottom: "20px" }} src={logo} alt="Logo" />
              <Typography sx={{ color: 'white', fontSize: '20px', fontWeight: 600, marginBottom: '10px' }}>
                SparkSinger
              </Typography>
              <Typography sx={{ color: 'white', fontSize: '14px', marginBottom: '20px',textAlign:'center'}}>
                🎉🎉🎉 Apply this coupon to get 20% off on your subscription fee. Don’t miss out!
              </Typography>
            </Box>

            {/* Vertical Divider */}
            <Divider orientation="vertical" flexItem sx={{ borderColor: "rgba(255, 255, 255, 0.5)", borderStyle: "dotted", borderWidth: "1px", marginX: 2 }} />

            {/* Right section (coupon input and apply button) */}
            <Box sx={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center",padding:'60px'}}>
              <Typography sx={{ fontSize: "18px", fontWeight: "600", color: "#fff", marginBottom: "20px" }}>
                Apply Coupon*
              </Typography>

              {/* Coupon input field */}
              <TextField
  error={!!errorMessage}
  value={couponCode}
  onChange={(e) => setCouponCode(e.target.value)}
  placeholder="Please Enter Coupon"
  variant="outlined"
  InputLabelProps={{ shrink: true }} // Keeps label in place
  sx={{
    marginBottom: "20px",
    width: "100%",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#fff",
    },
    "& .MuiInputLabel-root": {
      color: "#333",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#aaa",
    },
  }}
/>

              {/* Submit button */}
              <Button
                variant="contained"
                sx={{
                  fontWeight: "bold",
                  textTransform: 'capitalize',
                  px: 5,
                  height:'40px',
                  py: 2,
                  fontSize: "1em",
                  background: "linear-gradient(45deg, #e322de, #8826fb, #3693e6)",
                  color: "#fff",
                  borderRadius: "12px",
                  animation: `${buttonPulse} 2s infinite`,
                  "&:hover": {
                    background: "linear-gradient(45deg, #ff5722, #ff9800, #ffeb3b)",
                  },
                }}
                onClick={handleCouponApplied}
              >
                Apply Coupon
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default AnimatedCouponModal;
