import { Box, Button, CircularProgress, IconButton, Skeleton, Stack, Typography } from '@mui/material'
import React, { useState,useEffect, Suspense } from 'react'

import Card from '@mui/material/Card';
import Coverimage from '../../../assest/images/cover music.png'
import FadeLoader from 'react-spinners/FadeLoader';

import CardMedia from '@mui/material/CardMedia';

import { Navigate, useNavigate, useParams } from 'react-router-dom';
import SyncLoader from 'react-spinners/SyncLoader';
import SongbookSkelton from '../../../data/songbook-skelton';
import { OfflinePinRounded, PlayCircle } from '@mui/icons-material';
import { GiLoveSong } from 'react-icons/gi';
import axios from 'axios';
import { url } from '../../../config/config';
const SongCard = ({songs,loading}) => {

 const navigate =useNavigate()
 const [pagination,setPagination]= useState(0)
 const [karaoke,setKaraoke]=useState()
 const params = useParams(); 
 console.log(params._id,"adadadadad");
 
//  songs.map((val,ind) => {
//   console.log(val.metaData)
//  })

useEffect(() => {
  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    console.log(scrollTop + windowHeight, documentHeight, "scrolling..."); 
    if (scrollTop + windowHeight >= documentHeight) {
      console.log('EverOne Incremant')
      setPagination(prevPagination => prevPagination + 1)
    }
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);
useEffect(()=>{
       
  const GetSongbook=async()=>{
  
   
   try {  
    const config={
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }
     const res = await axios.get(`${url}/api/Karaoke/getKaraokeByID/${params.id}`,config);
     setKaraoke(res.data.data)
   console.log(res.data.data,"song..............")
    
 } catch (error) {
   console.log(error)
 }
 //    
  }
  GetSongbook()

},[])

  return ( 
    <Box sx={{minHeight:"100vh"}}>
    {
      songs.length !== 0 ? (   <>
      <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "left", whiteSpace: 'nowrap', flex: 1, textAlign: 'left' }}>
          {songs && songs.map((data, index) => (
            // console.log(data.users[0].Name,"kkddkdkejdsn"),
            
           
            
            <><Button onClick={() => {
              navigate(`/allsong/${data._id}`, {
                state: data._id
              });
            } } style={{ textDecoration: "none" }}>
              <Box sx={{ margin: 2 }}>
                <Box><Card key={index} sx={{ width: 178, borderRadius: '10px', mb: 1,'&:hover .hover-overlay': { opacity: 1,}, }}>
                  <CardMedia
                    sx={{ height: 178 }}
                    image={data.CoverImageURL ? data.CoverImageURL : Coverimage}
                    title={data.Artist} />
                   <Box
          className="hover-overlay"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 0,
            transition: 'opacity 0.3s',
          }}
        >
          <IconButton
            // onClick={() => onPlay(data)}
            sx={{ color: 'white', fontSize: 50 }}
          >
            
            <GiLoveSong fontSize="inherit" size={80} color={"663FF3"}sx={{color:"#663FF3"}}  />
          </IconButton>
        </Box>
       
                </Card></Box>
                <Box sx={{ textOverflow: "ellipsis" }}>
                  <Box

                    sx={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      width: 178,
                      color: "#2D2867",
                      fontWeight: 800,
                      textAlign: 'left'
                    }}
                  >{data.Name}
                  </Box>
                  <Box sx={{ fontSize: "13px", color: "#2D2867", textAlign: 'left', width: 178, overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.Artist}</Box>
                  <Box sx={{ fontSize: "12px", color: "#585A64", textAlign: 'left', width: 178, overflow: 'hidden', textOverflow: 'ellipsis' }}><OfflinePinRounded sx={{color:"#8826FB",fontWeight:700}} fontSize='14px' fontWeight='700' />{data.users[0]?.Name}</Box>
                  {/* <Box sx={{display:"flex",alignItems:"center"}}> <Typography  sx={{color:"#2D2867",fontSize:'14px',fontWeight:'400px'}}>Published by<OfflinePinRounded sx={{color:"#8826FB",fontWeight:700}} fontSize='14px' fontWeight='700' />{data.users[0].Name }</Typography></Box> */}
                </Box>
              </Box></Button>

            </>
          ))}

        </Box>  {loading && (
             <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <FadeLoader color="LightGray"height={10} width={4} margin={-6} sx={{ color: "#6C2EFF" }} />
           </Box>
          )}</>
        ) :( <Box sx={{display:"flex",flexWrap:"wrap",justifyContent:"left",whiteSpace:'nowrap',flex:1,textAlign:'left',gap:6}}>
              {
                SongbookSkelton && SongbookSkelton.map((val,ind) =>(
                  <Box>
                    <Stack>
                    <Skeleton sx={{marginBottom:'10px'}} animation='wave' variant={`${val.type}`} width={val.width} height={val.Height} />
                    <Skeleton animation='wave'/>
                    <Skeleton animation='wave'/>
                    <Skeleton animation='wave'/>
                    </Stack>
                  </Box>
                ))
              }
    </Box> 
         
    )}
 
  </Box>
  )
}

export default SongCard