import { Box, Card, CardContent, Divider, List, ListItem, ListItemText, Typography } from '@mui/material'
import React from 'react'
import Spark from './spark'
import step1 from "../assest/images/step1.png"
import step2 from "../assest/images/step2.png"
import step3 from "../assest/images/step3.png"
import step4 from "../assest/images/step4.png"
import step5 from "../assest/images/step5.png"
import step6 from "../assest/images/step6.png"
 
const Demo = () => {
  return (
  <Box sx={{backgroundColor:"white"}}>
    <Box sx={{ padding: 3, maxWidth: 800, margin: '0 auto', textAlign: 'start', color: "#743CED" }}>
    <Typography variant="h4" className="mb-4 font-bold" sx={{textAlign:"center",mt:2}}>
      How to Upload a Song to SparkSinger
    </Typography>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5,mt:2 }}>
      <Card className="p-4 shadow-lg">
        <CardContent>
          <Typography variant="body1" className="mb-2">
            1. Open <strong>SparkSinger.com</strong> from your web browser.
          </Typography>
          <Typography variant="body1" className="mb-2">
            2. Click the <strong>Upload</strong> button and select <strong>Upload a song to SparkSinger</strong>.
          </Typography>
          <img src={step1} alt="Step 1" className="w-full rounded-lg mt-2" />
        </CardContent>
      </Card>
      <Card className="p-4 shadow-lg">
        <CardContent>
          <Typography variant="body1" className="mb-2">
            3. Select the backing track from your PC. The upload should take only a few minutes.
          </Typography>
          <img src={step2} alt="Step 2" className="w-full rounded-lg mt-2" />
        </CardContent>
      </Card>
      <Card className="p-4 shadow-lg">
        <CardContent>
          <Typography variant="body1" className="mb-2">
            4. Enter the <strong>title</strong> and <strong>artist</strong> of your song. Choose the <strong>genre</strong> and <strong>mood</strong>.
          </Typography>
          <img src={step3} alt="Step 3" className="w-full rounded-lg mt-2" />
        </CardContent>
      </Card>
      <Card className="p-4 shadow-lg">
        <CardContent>
          <Typography variant="body1" className="mb-2">
            5. Add the lyrics and assign singers for duet performances.
          </Typography>
          <img src={step4} alt="Step 4" className="w-full rounded-lg mt-2" />
        </CardContent>
      </Card>
      <Card className="p-4 shadow-lg">
        <CardContent>
          <Typography variant="body1" className="mb-2">
            6. Time your lyrics by pressing the <strong>spacebar</strong> or clicking with the <strong>mouse</strong>.
          </Typography>
          <img src={step5} alt="Step 5" className="w-full rounded-lg mt-2" />
        </CardContent>
      </Card>
      <Card className="p-4 shadow-lg">
        <CardContent>
          <Typography variant="body1" className="mb-2">
            7. Choose the <strong>Lyric Type</strong> to match your audio and video style.
          </Typography>
          <img src={step6} alt="Step 6" className="w-full rounded-lg mt-2" />
        </CardContent>
      </Card>
      <Card className="p-4 shadow-lg">
        <CardContent>
          <Typography variant="body1" className="mb-2">
            8. Done! Your uploaded song is now available in your SparkSinger account.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  </Box></Box>


  )
}

export default Demo