import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { act } from 'react-dom/test-utils'

const initialState = {
  songUpload: [],
  songLyrics: [],
  SongMeta:[],
  TextfileLyrics:[],
  scrollValue:0,
  colorMapping : [],
  AutoPlayOn : [
    {
      isTrue : true
    }
  ],
  KaraokeEdited : [
    {
      isTrue : false
    }
  ],
  FullscreenMode : [
    {
      isTrue : false
    }
  ],
  MediaPlayerPlay : [
    {
      isTrue : false
    }
  ],
  CanvaVideo : '',
  TextAnimation : '',
  TextColor : '',
  isPremium : false
}

export const uploadaudioSlice = createSlice({
  name: 'uploadSong',
  initialState,
  reducers: {
    AddAudio: (state, action) => {
    
      state.songUpload = action.payload
    },
    Lyrics: (state, action) => {
console.log(action.payload)

      state.songLyrics.push(
        action.payload,
      );
     

    },
    deleteLyrics: (state, action) => {
      const indexToDelete = action.payload;
      state.songLyrics.splice(indexToDelete, 1);
    },
    editLyric: (state, action) => {
     
        const updateItemValue = state.songLyrics.map((data, index) => {
          if (index == action.payload.index) {
            return {
              ...data, Sentence: action.payload.lyric
            }
          }
          return data
        })
      
        state.songLyrics = updateItemValue
    },
    editTextFileLyric: (state, action) => {
     
      const updateItemValue = state.TextfileLyrics.map((data, index) => {
        if (index == action.payload.index) {
          return {
            ...data, Sentence: action.payload.lyric
          }
        }
        return data
      })
    
      state.songLyrics = updateItemValue
  },
    editLyricinTiming : (state,action) => {
       const updateItemValue = state.songLyrics.map((data,index) => {
        if(index == action.payload.index){
          return{
            ...data, Sentence: action.payload.lyric
          }
        }
        return data
       })
       state.songLyrics = updateItemValue
    },
    editSinger: (state, action) => {
         console.log(action.payload)
        const updateItemValue = state.songLyrics.map((data, index) => {
          if (index == action.payload.index) {
            return {
              ...data, SingerIndex: action.payload.singer
            }
          }
          return data
        })
      
        state.songLyrics = updateItemValue
    },
    EditlyricType: (state, action) => {
         console.log(action.payload)
        const updateItemValue = state.TextfileLyrics.map((data, index) => {
          if (index == action.payload.index) {
            return {
              ...data, LyricType: action.payload.singer
            }
          }
          return data
        })
      
        state.TextfileLyrics = updateItemValue
    },
    AddTime: (state, action) => {
  
if(state.TextfileLyrics==0){
  

  const updateItemValue = state.songLyrics.map((data, index) => {
    if (index == action.payload.index) {
      return {
        ...data, StartTime: action.payload.time,
                selected:action.payload.bool
      }
    }
    return data
  })

  state.TextfileLyrics = updateItemValue
  const updateItemValue1 = state.songLyrics.map((data, index) => {
    if (index == action.payload.index-1 ) {
      return {
        ...data, EndTime: action.payload.time
      }
    }
    return data
  })

  state.TextfileLyrics = updateItemValue1
}else{
  const updateItemValue = state.TextfileLyrics.map((data, index) => {
    if (index == action.payload.index) {
      return {
        ...data, StartTime: action.payload.time,
        convertedTime : action.payload.convertedTime,
      }
    }
    return data
  })

  state.TextfileLyrics = updateItemValue
}


const updateItemValue1 = state.TextfileLyrics.map((data, index) => {
  if (index == action.payload.index-1 ) {
    return {
      ...data, EndTime: action.payload.time
    }
  }
  return data
})

state.TextfileLyrics = updateItemValue1

const lastValue = state.TextfileLyrics.map((data,index) => {
  const value = state.TextfileLyrics.length - 1
  if(value === action.payload.index && index === action.payload.index){
    return{ 
      ...data, EndTime : action.payload.audioDuration
    }
  }
  return data
})

state.TextfileLyrics = lastValue



    },

    ResetLyricTiming : (state,action) => {
        const resetValue = state.TextfileLyrics.map((data,index) => {
           if(index >= action.payload.timingReset){
            return{
              ...data, StartTime : '',
                       EndTime : ''
            }
           }
           return data
        })
        state.TextfileLyrics = resetValue

        const resetValueEndtime = state.TextfileLyrics.map((data,index) => {
          if(index === action.payload.timingReset - 1){
           return{
             ...data, 
                      EndTime : ''
           }
          }
          return data
       })
       state.TextfileLyrics = resetValueEndtime

    },
    AddMeta: (state, action) => {


      state.SongMeta = action.payload

    },
    exploreScrool: (state, action) => {


      state.scrollValue = action.payload

    },
    TextFile: (state, action) => {
      const elements = action.payload.split("\n");

      if (!elements || !Array.isArray(elements)) {
        alert("Error: elements is null or undefined.");
    } else {
        for (let index = 0; index < elements.length; index++) {
            const lines = elements[index]?.match(/.{1,25}(\s|$)|\S+?(\s|$)/g); 
            if (lines) {
                for (let i = 0; i < lines.length; i++) {
                    state.TextfileLyrics.push({
                        SingerIndex: 2,
                        Sentence: lines[i],
                        StartTime: '',
                        EndTime: '',
                        LyricIndex: '0',
                        SingerType: '',
                        SingerColorIndex: '',
                        LyricType: ''
                    });
                }
            }
        }
    }

//       const elements = action.payload.split(" ");

// for (let index = 0; index < elements.length; index += 2) {
//     const element1 = elements[index];
//     const element2 = elements[index + 1];

//     // Combine element1 and element2 into a single object
//     const combinedObject = {
//         SingerIndex: 0,
//         Sentence: `${element1} ${element2 || ''}`, // If element2 is undefined, use an empty string
//         StartTime: '',
//         EndTime: '',
//         LyricIndex: '0',
//         SingerType: '',
//         SingerColorIndex: '',
//         LyricType: ''
//     };

//     // Push the combined object into the array
//     state.TextfileLyrics.push(combinedObject);
// }

    },
    AddSingerProperty : (state,action) => {
      const updateItemValue = state.TextfileLyrics.map((data,index) => {
        if(index == action.payload.SingerId){
          return{
            ...data,SingerType : action.payload.SingerColor,
            SingerColorIndex : action.payload.SingerId,
            SingerIndex : action.payload.singerIndex    
          }
        }
        return data
      })
      state.TextfileLyrics = updateItemValue
    },
    AddColorPalette : (state,action) => {
      state.colorMapping['0'] = action.payload.color;
      state.colorMapping['1'] = action.payload.singer2Color;
      state.colorMapping['2'] = action.payload.bothColor;
      // state.colorMapping.push(action.payload.color,action.payload.singer2Color,action.payload.bothColor)
    },
    AutoPlayStatus : (state,action) => {
        state.AutoPlayOn[0].isTrue = action.payload.autoplayOn
    },
    MediaPlay : (state,action) => {
      state.MediaPlayerPlay[0].isTrue = action.payload.mediaPlay
  },
    KaraokeEdit : (state,action) => {
         state.KaraokeEdited[0].isTrue = action.payload.edited
    },
    FullScreenSize : (state,action) => {
          state.FullscreenMode[0].isTrue = action.payload.fullscreen;
    },
    KaraokeTiming : (state,action) => {
          let value = action.payload.lyricTiming
          value.map((data,index) => {
              state.TextfileLyrics.push(data)
          })
          state.colorMapping['0'] = action.payload.lyricColor[0]
          state.colorMapping['1'] = action.payload.lyricColor[1]
          state.colorMapping['2'] = action.payload.lyricColor[2]
          state.SongMeta = action.payload.karaokeDetails

          state.songUpload = action.payload.karaokeUrl
    },
    singerTypeChoosing : (state,action) => {
      const soloType = state.TextfileLyrics.map((data,index) => {
        return{
          ...data, SingerIndex : action.payload.singerIndex,
                   SingerType : action.payload.singerType
        }
        return data
      }) 
      state.TextfileLyrics = soloType
    },
    animationGenerater : (state,action) => {
         state.CanvaVideo = action.payload.canva
         state.TextAnimation = action.payload.textAnimation
         state.TextColor = action.payload.textColor
    },
    isPremiumHandle : (state,action) => {
      state.isPremium = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { AddAudio, Lyrics, AddTime,AddMeta, TextFile,exploreScrool,deleteLyrics,editLyric,editSinger,EditlyricType,editLyricinTiming,AddSingerProperty,AddColorPalette,AutoPlayStatus,ResetLyricTiming,KaraokeTiming,singerTypeChoosing,KaraokeEdit,FullScreenSize,MediaPlay,animationGenerater , editTextFileLyric,isPremiumHandle} = uploadaudioSlice.actions

export default uploadaudioSlice.reducer