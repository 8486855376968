import { Avatar, Badge, Box, Button, Card, CardMedia, Checkbox, Container, Divider, IconButton, Link, Menu, MenuItem, Modal, TextField, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { LuLoader } from 'react-icons/lu'
import { FadeLoader, MoonLoader } from 'react-spinners'
import { url } from '../config/config'
import { Cancel, CheckBox, Email, Facebook, Favorite, Google, Group, LinkedIn, More, PlayArrow, Public, Settings, Twitter, WhatsApp } from '@mui/icons-material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Appcard from './app-card'
import { ImAppleinc } from 'react-icons/im'
import image3 from '../assest/images/NewLogo.png'
import { HiUserGroup } from 'react-icons/hi'
import { IoIosArrowBack, IoMdSettings } from "react-icons/io"
import { MdCheckBox } from 'react-icons/md'
import { TfiTwitter } from 'react-icons/tfi'
import { jwtDecode } from 'jwt-decode'
import songesss from '../assest/images/premiumIcon.png'
import { IoTrashBin } from 'react-icons/io5'
import { FcLeave } from 'react-icons/fc'
import { BsGooglePlay } from 'react-icons/bs'
const userTokenData = localStorage.getItem('token') !== null ? jwtDecode(localStorage.getItem('token')) : ''
const LoGinUserID = userTokenData.userId

const Groupinfo = () => {
  const [gorups, setGroups] = useState([])
  const [requests, setRequests] = useState([])
  const [post, setPost] = useState([])
  const [openModal, setOpenModal] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [infogroup, setInfogroup] = useState([])
  
  
  const [invited, setInvited] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null); // Tracks active dropdown
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [membercount, setMemberCount] = useState([]);
  const [groupsinfo, setGroupsinfo] = useState([])
   
  const [searchQuery, setSearchQuery] = useState('');
  const [followerDetails, setFollowerDetails] = useState();
  const [groupjoin, setGroupjoin] = useState([]);
  console.log(groupjoin,"groupjoin"  );
  const [text, setText] = useState(true)
  const [isInvitePage, setIsInvitePage] = useState(false);
  const [isanchorEl, setIsAnchorEl] = useState(null);
  const [selectedFollowersIds, SetSelectedFollowersIds] = useState([])
  const navigate = useNavigate()
  const params = useParams()
  const { state } = useLocation()

  console.log(state, 'state')
  const { id } = useParams();
  console.log(id,"jdhdggd");
  
  console.log(state._id, "data..........")
  const handleOpen = (modalType) => {
    setOpenModal(modalType);
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const opens = Boolean(isanchorEl);

  const handleClick = (event) => {
    setIsAnchorEl(event.currentTarget); // Set anchor element on icon click
  };
  const handleClickcopy = () => {
    const groupLink = window.location.href;
    console.log("group link:", groupLink)
    navigator.clipboard.writeText(groupLink);
  }
  const handleCloseis = () => {
    setIsAnchorEl(null); // Close menu by clearing anchor element
  };
  const handleInviteClick = () => {
    setIsInvitePage(true); // Show invite members page
  };

  const handleBackToProfile = () => {
    setIsInvitePage(false); // Go back to the profile page
  };

  const handleClose = () => {
    setOpenModal(null);
  };
  const handleCheckboxChange = (ind) => {
    if (selectedFollowersIds.includes(ind) === true) {
      SetSelectedFollowersIds((prevState) => prevState.filter((val) => val !== ind))
      console.log()
    } else {
      SetSelectedFollowersIds((prevState) => [...prevState, ind])
    }
  }
  const handleMenuClick = (item) => {
    setActiveDropdown(null); // Close the dropdown after action
    console.log(item);
  };
  const clickfunction = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleSongCollborationNavigate = (val) => {
    console.log(val, 'clikedValuessss')
    navigate(`/record/${val._id}`)
  }

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
  };
  const handleInvite = () => {
    if (selectedUsers.length > 0) {
      console.log('Inviting users:', selectedUsers);
      // Add invite logic here (e.g., API call)
    } else {
      console.log('No users selected for invitation.');
    }
  };
  const handleClickImage = (action, params) => {
    // Navigate to '/managegroup' and pass state
    navigate('/managegroup', {
      state: { groupId: params._id }, // Pass groupId dynamically
    });
  };
  const handleNavigate = (UserId) => {
    console.log(UserId, 'User------------Id');
    navigate(`/otherProfile/${UserId}`)

  }
  const handleSearch = () => {
    console.log('Searching for:', searchQuery); // Replace this with actual search logic
    alert(`Searching for: ${searchQuery}`); // Example alert
  };

  const sortMember = async (data) => {
    if (data.isOwner) {
      return -1
    } else {
      return 1
    }
  }

  useEffect(() => {
    const GetGroup = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/Channel/GetChannelMember/${params.id}`, config)
        const data = await res.data.data.sort(sortMember)
        //  console.log(res.data.data,"johnhhgf");

        setGroups(data)


      } catch (error) {
        console.log(error)
      }
    }
    GetGroup()
  }, [])
  useEffect(() => {
    const Getinfo = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/channel/getchannelbyuserid/${params.id}`, config)
        console.log(res.data.data, "Groupsinfo......")
        setGroupsinfo(res.data.data)

      } catch (error) {
        console.log(error)
      }
    }
    Getinfo()
  }, [])
  useEffect(() => {
    const Getrequsets = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/Channel/GetRequestedMember/${params.id}`, config)
        console.log(res.data.data, "requests......")
        setRequests(res.data.data)

      } catch (error) {
        console.log(error)
      }
    }
    Getrequsets()
  }, [])
  useEffect(() => {
    const Getpost = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/Channel/GetChannelPostByChannelID`, config)
        console.log(res.data.data, "postttttttttt......")
        setPost(res.data.data)

      } catch (error) {
        console.log(error)
      }
    }
    Getpost()
  }, [])
  useEffect(() => {
    const Getinfo = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        };
        const res = await axios.get(`${url}/api/channel/GetChannelPostByChannelID/${params.id}`, config);
        console.log(res.data.data, 'informetion.......');
        setInfogroup(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    Getinfo();
  }, []);
  useEffect(() => {
    const getFollowersData = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/Following/getFollowersByUserID`, config)
        console.log(res.data.data, 'follower,,,,,,,,,')
        setFollowerDetails(res.data.data)
      } catch (error) {
        console.log(error)
      }
    }
    getFollowersData()
  }, [])
  useEffect(() => {
    const getInvited = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/Channel/GetInvitedMember/${id}`, config);
        console.log(res.data.data, 'invited,,,,,,,,,')
        setInvited(res.data.data)
      } catch (error) {
        console.log(error)
      }
    }
    getInvited()
  }, [])
  const filteredFollowers = followerDetails?.filter(val =>
    val.userData[0]?.Name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    val.userData[0]?.Email?.toLowerCase().includes(searchQuery.toLowerCase()) // Search by email as fallback
  );
  const HandlesubmitRemove = async (_id) => {
    if (true) {
      console.log(_id, 'looo')
      try {
        const data = {
          ChannelID: id,
          ChannelOwnerID: LoGinUserID,
          RequestedID: _id,
        }
        console.log(data, 'dataform');

        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'

          }
        };

        const res = await axios.post(`${url}/api/Channel/RemoveRequestFromChannelByUserId`, data, config);

        console.log(res, "kesava,,,,,,");
        navigate('/usergroups');
      } catch (error) {
        console.error('Network Error:', error.response ? error.response.data : error.message);
      }
    }
  };
  const handlejoingroup = async () => {
    if (true) {
      try {

        const data = {
          ChannelOwnerID: groupjoin[0]?._id,
          ChannelID: state._id
        }
        console.log(data, 'dataform');

        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'

          }
        };

        const res = await axios.post(`${url}/api/Channel/SendRequestToChannelByUserId`, data, config);

        console.log(res, "kesava,,,,,,");
        navigate('/usergroups');
      } catch (error) {
        console.error('Network Error:', error.response ? error.response.data : error.message);
      }
    }
  };
  const Handlesubmitinvert = async () => {
    if (true) {
      try {

        const data = {
          ChannelID: id,
          UserIDs: selectedFollowersIds,
          Invite: true
        }
        console.log(data, 'dataform');

        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'

          }
        };

        const res = await axios.post(`${url}/api/Channel/SendInviteToChannelByUserId`, data, config);

        console.log(res, "invirets....");
        navigate('/usergroups');
      } catch (error) {
        console.error('Network Error:', error.response ? error.response.data : error.message);
      }
    }
  };
  const Handlesubmitjoin = async (id) => {
    if (true) {
      try {

        const data = {
          ChannelOwnerID: LoGinUserID,
          ChannelID: state._id,
          RelatedUserId:id ,
        }
        console.log(data, 'dataform');

        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'

          }
        };

        const res = await axios.post(`${url}/api/Channel/AcceptChannelMember`, data, config);

        console.log(res, "invirets....");
        navigate('/usergroups');
      } catch (error) {
        console.error('Network Error:', error.response ? error.response.data : error.message);
      }
    }
  };
  const HandleRemove = async (id) => {
    if (true) {
      try {

        const data = {
          RequestedID: LoGinUserID,
          ChannelID: state._id,
          ChannelOwnerID:groupjoin[0]?.CreatedUserID,
         
        }
        console.log(data, 'dataform');

        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'

          }
        };

        const res = await axios.post(`${url}/api/channel/RemoveChannelMember`, data, config);

        console.log(res, "invirets....");
         navigate('/usergroups');
      } catch (error) {
        console.error('Network Error:', error.response ? error.response.data : error.message);
      }
    }
  };
  const [ChannelOwerID, setChannelOwnerID] = useState('')
  useEffect(() => {
    const GetCancel = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/Channel/GetChannelByChannelID/${id}`, config)
        // setisMemeber(res.data.data[0].isMember);
        // setChaneelreq(res.data.data[0].isRequest);
        // setHeader(res.data.data[0].CreatedUserID )
        // setMemberCount(res.data.data[0].PostCount)
        // setChannelOwnerID(res.data.data[0].CreatedUserID)
        console.log(res.data.data, "GetChannelByChannelID");
        setGroupjoin(res.data.data)
      } catch (error) {
        console.log(error)
      }
    }
    GetCancel()
  }, [id,])


  const handleAddPost = () => {
    console.log("Adding a post...");
    handleOpen('addPost')
  };

  const handleCancelRequest = async () => {
    console.log('CancelCall')
    if (groupjoin.length > 0) {
      try {

        const data = {
          ChannelOwnerID: groupjoin[0]?._id,
          ChannelID: state._id,
          RequestedID: LoGinUserID,
        }
        console.log(data, 'dataform');

        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'

          }
        };

        const res = await axios.post(`${url}/api/Channel/RemoveRequestFromChannelByUserId`, data, config);

        console.log(res, "kesava,,,,,,");

      } catch (error) {
        console.error('Network Error:', error.response ? error.response.data : error.message);
      }
    }
    console.log("Canceling request...");
    // handlejoingroup('cancelRequest');
  };

  const handleJoinRequest = () => {
    console.log("Requesting to join...");
    // handlejoingroup('joinRequest');
    handlejoingroup()
  };

  const getAction = () => {
    if (groupjoin[0]?.isAdmin || groupjoin[0]?.isMember) {
      console.log(groupjoin,"hihihih")

      return { label: 'Add Post', action: handleAddPost };
    } else if (groupjoin[0]?.isRequest && groupjoin[0]?.isMember === false) {
      return { label: 'Cancel', action: handleCancelRequest };
    } else if (!groupjoin[0]?.isAdmin && !groupjoin[0]?.isMember && !groupjoin[0]?.isRequest) {
      return { label: 'Join', action: handleJoinRequest };
    }

    return null;
  };
  // const getAction = () => {
  //   if (state.isAdmin || state.isMember) {
  //     console.log("User is Admin or Member - Showing Add Post button");
  //     return { label: 'Add Post', action: handleAddPost };
  //   }
  //   else if (state.isRequest) {
  //     console.log("User has requested - Showing Cancel Request button");
  //     return { label: 'Cancel Request', action: handleCancelRequest };
  //   }
  //   else {
  //     console.log("User is not a member - Showing Join button");
  //     return { label: 'Join', action: handleJoinRequest };
  //   }
  // };

  const action = getAction();
  // const action = getAction();


  // let actionLabel = "Join"; // Default action

  // if (groupjoin?.isMember) {
  //   actionLabel = "Add Post";
  // } else if (groupjoin?.isRequest) {
  //   actionLabel = "Cancel";
  // }

  // console.log(actionLabel, "Status on");

 console.log(groupjoin,"groupjoin");
 
  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Box sx={{ background: 'rgb(255, 255, 255)', marginTop: 0 }}>
        <Container maxWidth="lg">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
              marginTop: '10px',
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '32px',
                  fontWeight: 700,
                  color: 'rgb(45, 40, 103)',
                }}
              >
                {groupjoin[0]?.ChannelName}
              </Typography>
              <Box sx={{ display: 'flex', gap: 1, }}>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 700,
                    color: 'rgb(114, 140, 166)',
                  }}
                >
                  {groupjoin[0]?.PostCount} Post
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 700,
                    color: 'rgb(114, 140, 166)',
                  }}
                >
                  {/* {state.location} India */}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 700,
                    color: 'rgb(114, 140, 166)',
                  }}
                >
                  {groupjoin[0]?.MemberCount} Members
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >

              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                {gorups && gorups[0]?.users.map((group) => {
                  console.log(group, 'itratio_group')

                  return (
                    <Box key={0} sx={{ display: 'flex', alignItems: 'center', gap: '15px' }} >

                      <Box key={0} sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <Box sx={{ width: '50px', height: '50px', borderRadius: '50%', overflow: 'hidden' }}>
                          <img src={group?.ProfilePhoto} alt="Profile" style={{ width: '100%', height: '100%' }} onClick={() => handleNavigate(group?._id)} />
                        </Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: 700, color: 'rgb(45, 40, 103)' }}>
                          {group?.Name}
                        </Typography>
                        <Box
                          sx={{
                            width: '35px',
                            height: '35px',
                            borderRadius: '50%',
                            overflow: 'hidden',
                            cursor: "pointer"
                          }}
                        >
                          <Box
                            onClick={() => handleOpen('profileMore')}

                          >
                            <HiUserGroup size={25} />
                          </Box>
                        </Box>
                      </Box>

                    </Box>
                  )
                })}
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  gap: 1.5,
                  marginTop: '20px',
                }}
              >
                {action && (
                  <button
                    onClick={() => {action.action()}}
                    style={{
                      cursor: 'pointer',
                      backgroundColor:
                      //  action.label === 'Add Post' ? 'rgb(45, 40, 103)' :
                      //   action.label === 'Cancel Request' ? 'rgb(200, 40, 40)' :
                          'rgb(45, 40, 103)',
                      color: 'white',
                      padding: '10px 15px',
                      textAlign: 'center',
                      borderRadius: '5px',
                      fontSize: '17px',
                      fontWeight: 700,
                      border: 'none',
                    }}
                  >
                    {action.label}
                  </button>
                )}

                {/* {groupjoin?.isMember ? (
                  // If user is a member, show the Add Post button
                  <Button
                    variant="contained"
                  // onClick={() =>
                  //   channelDetail &&
                  //   navigation.navigate('GroupPost', { channeId: channelDetail[0]._id })
                  // }
                  >
                    Add Post
                  </Button>
                ) : (
                  // If not a member, check the join request status
                  !groupjoin?.isRequest ? (
                    // If a join request exists, show Cancel Request button
                    <Button variant="contained" onClick={handleCancelRequest}>
                      Cancel Request
                    </Button>
                  ) : (
                    // Otherwise, show Join button
                    <Button variant="contained" onClick={handleJoinRequest}>
                      Join
                    </Button>
                  )
                )} */}

                {/* <Box onClick={() => handleOpen('singLive')}
                  sx={{
                    cursor: 'pointer', backgroundColor: 'rgb(45, 40, 103)', color: 'white', padding: '10px 15px', textAlign: 'center',
                    borderRadius: '5px', fontSize: '17px', fontWeight: 700,
                  }}> Sing Live
                </Box> */}
                <Box
                  onClick={() => handleOpen('share')}
                  sx={{
                    cursor: 'pointer',
                    backgroundColor: 'rgb(45, 40, 103)',
                    color: 'white',
                    padding: '10px 15px',
                    textAlign: 'center',
                    borderRadius: '5px',
                    fontSize: '17px',
                    fontWeight: 700,
                  }}
                >
                  Share
                </Box>
                <Box
                  onClick={() => handleOpen('viewMore')}
                  sx={{
                    cursor: 'pointer',
                    color: 'rgb(114, 140, 166)',
                    padding: '10px 15px',
                    textAlign: 'center',
                    borderRadius: '5px',
                    fontSize: '17px',
                    fontWeight: 700,
                  }}
                >
                  View More
                </Box>
              </Box>
              <Modal open={openModal === 'addPost'} onClose={handleClose}>
                <Box sx={{ ...modalStyle }}>
                  <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
                    <Cancel onClick={handleClose} style={{
                      cursor: 'pointer'

                    }} />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                    <Box>
                      <img src={image3} alt="App Preview" style={{ width: 180, height: 'auto' }} />
                    </Box>
                    <Box>
                      <Typography id="modal-title" variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                        Open in  SparkSinger App
                      </Typography>
                      <Typography id="modal-description" variant="body1" sx={{ mb: 3 }}>
                        Download the app and access the full feature and many more!
                      </Typography>
                      <Box sx={{ display: 'flex', gap: 2 }}>
                        <Appcard icon={<ImAppleinc fontSize="large" />} tittle1="Download on the" tittle2="App Store" />
                        <Appcard icon={<BsGooglePlay fontSize="large" />} tittle1="Download on the" tittle2="Google Play" />

                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Modal>

              <Modal open={openModal === 'singLive'} onClose={handleClose}>
                <Box sx={{ ...modalStyle }}>
                  <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
                    <Cancel onClick={handleClose} style={{ cursor: 'pointer' }} />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                    <Box>
                      <img src={image3} alt="App Preview" style={{ width: 180, height: 'auto' }} />
                    </Box>
                    <Box>
                      <Typography id="modal-title" variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                        Get the SparkSinger App
                      </Typography>
                      <Typography id="modal-description" variant="body1" sx={{ mb: 3 }}>
                        SparkSinger app to unlock all features and much more!
                      </Typography>
                      <Box sx={{ display: 'flex', gap: 2 }}>
                        <Appcard icon={<ImAppleinc fontSize="large" />} tittle1="Download on the" tittle2="App Store" />
                        <Appcard icon={<ImAppleinc fontSize="large" />} tittle1="Download on the" tittle2="Google Play" />

                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Modal>

              <Modal open={openModal === 'share'} onClose={handleClose}>
                <Box sx={{ ...modalStyle, overflowY: 'auto' }}>

                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '5px' }}>
                    <Box>
                      <Box sx={{ display: 'flex', ml: 40, cursor: 'pointer' }}><Cancel onClick={handleClose} /></Box>
                      <Typography fontSize={15} fontWeight={700} color={'rgb(45, 40, 103)'}>Share</Typography>
                      <Typography fontSize={11} fontWeight={400} color={'rgb(114, 140, 166)'}>Spread the word</Typography>
                    </Box>
                  </Box>
                  <Divider />
                  <Box sx={{ display: 'flex', justifyContent: 'space-around', marginBottom: '10px', padding: '10px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', background: '#f0f2f4', borderRadius: '5px', padding: '5px', paddingRight: '15px' }}>
                      <IconButton><Facebook style={{ fill: 'rgb(16, 149, 244)' }} /></IconButton>
                      <Typography>Facebook</Typography>
                    </Box>
                    <a style={{ cursor: 'pointer', textDecoration: 'none' }} href="https://wa.me/?text=http://99.62.153.141:5555/profile">
                      <Box sx={{ display: 'flex', alignItems: 'center', background: '#f0f2f4', borderRadius: '5px', padding: '5px', paddingRight: '15px' }}>
                        <IconButton><WhatsApp color="green" /></IconButton>
                        <Typography sx={{ color: 'rgb(45, 40, 103)', textDecoration: 'none' }}>WhatsApp</Typography>
                      </Box>
                    </a>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 2, paddingLeft: 2 }}>
                    <Avatar sx={{ backgroundColor: 'blue' }}><Twitter /></Avatar>
                    <Avatar><LinkedIn /></Avatar>
                    <Avatar><Email /></Avatar>
                    <Avatar><More /></Avatar>
                  </Box>
                </Box>
              </Modal>

              <Modal open={openModal === 'viewMore'} onClose={handleClose}>
  <Box
    sx={{
      ...modalStyle,
      display: 'flex',
      flexDirection: 'row',
      maxHeight: '50vh',
      height: '100%',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        width: 400,
        color: 'rgb(45, 40, 103)',
        overflowY: 'auto', // Enable scrolling
        padding: '10px',
      }}
    >
      {/* Header Section */}
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'end', cursor: 'pointer' }}>
          <Cancel onClick={handleClose} />
        </Box>
        <Typography sx={{ fontWeight: 'bold' }}>Group Info</Typography>
      </Box>

      <Divider />

      {/* Content Section */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography color="black">Group Name</Typography>
        <TextField
          fullWidth
          multiline
          variant="outlined"
          value={groupjoin[0]?. ChannelName|| ''}
          InputProps={{ readOnly: true }}
        />

        <Typography color="black">Group Hashtag</Typography>
        <TextField
          fullWidth
          multiline
          variant="outlined"
          value={groupjoin[0]?. Hastag|| ''}
          InputProps={{ readOnly: true }}
        />

        <Typography color="black">Description</Typography>
        <TextField
          fullWidth
          multiline
          variant="outlined"
          value={groupjoin[0]?.Description|| ''}
          InputProps={{ readOnly: true }}
        />
      </Box>
    </Box>
  </Box>
</Modal>

              <Modal open={openModal === 'profileMore'} onClose={handleClose}>
                <Box sx={{ ...modalStyle }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', width: 400, color: 'rgb(45, 40, 103)' }}>
                    <Box>
                      {isInvitePage ? <IoIosArrowBack size={25} onClick={handleBackToProfile} /> : ""}
                      <Box sx={{ display: "flex", justifyContent: "end",cursor:'pointer' }}>
                        <Cancel onClick={handleClose} />
                      </Box>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {isInvitePage ? "Invite Members" : "Group Members"}
                      </Typography>
                    </Box>

                    <Divider />

                    {isInvitePage ? (
                      <>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>



                          <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <input
                              type="text"
                              placeholder="Search members..."
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)} // Update state when input changes
                              style={{
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ddd',
                                flex: 1,
                              }}
                            />
                            <Button  onClick={handleSearch} variant="contained">Search</Button>
                          </Box>
                          <Box sx={{ height: 300, overflowY: 'scroll', width: '100%' }}>
  {filteredFollowers && filteredFollowers.length > 0 ? (
    filteredFollowers.map((val, ind) => (
      <Box
        key={ind}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px',
        }}
      >
        <Box>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              val.userData[0]?.isPremium ? (
                <Avatar
                  sx={{ bgcolor: 'red', width: 20, height: 20, fontSize: 10, border: '2px solid white' }}
                  alt="Premium User"
                  src={songesss}
                />
              ) : null
            }
          >
            <Avatar
              src={val.userData && val.userData[0]?.ProfilePhoto}
              onClick={() => handleNavigate(val.userData[0]._id)}
              sx={{ width: '45px', height: '45px', borderRadius: '50%' }}
            />
          </Badge>
        </Box>
        <Box>
          <Typography
            color="rgb(45, 40, 103)"
            sx={{ marginTop: '7px' }}
            fontWeight={700}
            fontSize={16}
          >
            {val.userData[0]?.Name !== ''
              ? val.userData[0]?.Name
              : val.userData[0]?.Email.split('@')[0]}
          </Typography>
        </Box>
        <Box>
          <Checkbox
            checked={selectedFollowersIds.includes(val.User_ID)}
            onChange={() => handleCheckboxChange(val.User_ID)}
          />
        </Box>
      </Box>
    ))
  ) : (
    <Typography sx={{ textAlign: 'center', marginTop: 2, color: 'gray' }}>
      No followers found.
    </Typography>
  )}
</Box>

                          <Button
                            sx={{
                              fontWeight: 'bold',
                              marginTop: '20px',
                              border: 'none',
                              backgroundColor: '#7FDB8B',
                              cursor: 'pointer',
                              transition: 'background-color 0.3s, color 0.3s',
                              '&:hover': {
                                backgroundColor: 'green',
                                color: '#fff',
                              },
                            }}
                            onClick={Handlesubmitinvert}
                          >
                            Send Invites
                          </Button>

                        </Box>
                      </>
                    ) : (
                      <>
                        <Typography sx={{ fontWeight: "bold", textAlign: "start", color: "#2D2867" }}>Owner</Typography>
                        <Box
                          sx={{
                            overflowY: "scroll",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",

                            padding: "10px",
                            gap: 3, // Adds spacing between groups
                          }}
                        >
                          {

                            gorups.length > 0 && gorups.map((users, index) => {
                              console.log(users, 'text')
                              return users?.users.map((group, idx) => {
                                console.log(group);
                                return (
                                  <Box key={idx} sx={{ display: "flex", flexDirection: "column", justifyItems: "flex-start", gap: 2 }}>
                                    <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: 2, width: "100%" }}>
                                      <Box
                                        sx={{
                                          width: "40px",
                                          height: "40px",
                                          borderRadius: "50%",
                                          overflow: "hidden",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          src={group.ProfilePhoto}
                                          alt="Profile"
                                          style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                          onClick={() => handleNavigate(group._id)}
                                        />
                                      </Box>
                                      <Typography sx={{ fontSize: "14px", fontWeight: 700, color: "rgb(45, 40, 103)" }}>
                                        {group.Name ? group.Name : group.UserName} {index === 0 ? "(Owner)" : "(Members)"}
                                      </Typography>
                                    </Box>
                                  </Box>
                                );
                              });
                            })


                          }


                        </Box>
                        {groupjoin[0]?.isAdmin && (
                          <Button sx={{
                            fontWeight: 'bold',
                            marginTop: '20px',
                            border: 'none',
                            backgroundColor: '#7FDB8B',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s, color 0.3s',
                            '&:hover': {
                              backgroundColor: 'green',
                              color: '#fff',
                            },
                          }} onClick={handleInviteClick}>Invite Members</Button>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              </Modal>
              <Modal open={openModal === 'pending members'} onClose={handleClose}>
                <Box sx={{ ...modalStyle }}>
                  <Card sx={{ height: '480px', width: '400px' }}>
                    {/* Header Section */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                        <Box sx={{ color: 'rgb(45, 40, 103)', fontSize: '17px', fontWeight: 700 }}>
                          Pending Members
                        </Box>
                        <Box sx={{ color: 'rgb(114, 140, 166)' }}>
                          Accept, reject or cancel requests
                        </Box>
                      </Box>
                      <Box sx={{ marginTop: '10px', cursor: 'pointer' }}>
                        <Cancel onClick={handleClose} style={{ fontSize: '30px', color: 'rgb(192, 204, 216)' }} />
                      </Box>
                    </Box>

                    {/* Tab Section */}
                    <Box>
                      <Box
                        sx={{
                          display: 'flex',
                          textAlign: 'center',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderTop: '3px solid #EAE9F0',
                          background: 'rgb(240, 242, 244)',
                        }}
                      >
                        <Box sx={{ marginTop: '15px', marginBottom: '10px' }}>
                          <button
                            onClick={() => setText(true)}
                            style={{
                              width: '130px',
                              height: '32px',
                              background: text ? 'rgb(114, 140, 166)' : 'rgb(255, 255, 255)',
                              color: text ? 'rgb(255, 255, 255)' : 'rgb(114, 140, 166)',
                              borderTopLeftRadius: '3px',
                              borderBottomLeftRadius: '3px',
                              border: 'none',
                              fontSize: '14px',
                              fontWeight: 700,
                              cursor: 'pointer',
                            }}
                          >
                            Requests
                          </button>
                          <button
                            onClick={() => setText(false)}
                            style={{
                              width: '130px',
                              height: '32px',
                              background: text ? 'rgb(255, 255, 255)' : 'rgb(114, 140, 166)',
                              color: text ? 'rgb(114, 140, 166)' : 'rgb(255, 255, 255)',
                              borderTopRightRadius: '3px',
                              borderBottomRightRadius: '3px',
                              border: 'none',
                              fontSize: '14px',
                              fontWeight: 700,
                              cursor: 'pointer',
                            }}
                          >
                            Invites
                          </button>
                        </Box>
                      </Box>
                    </Box>

                    {/* Content Section */}
                    <Box sx={{ height: 300, overflowY: 'scroll', width: '100%', }}>
                      {text ? (
                        requests && requests.length > 0 ? (
                          requests.map((val, ind) => (
                            console.log(val,'jdhdhjsjsh'),
                            
                            <Box  >
                              {
                                val.users.map((user, UserID) => (
                                  console.log(val.users[0]?._id,"hdhdhh"),
                                  

                                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px',cursor:'pointer' }}>
                                    <Box>
                                      <Avatar src={user.ProfilePhoto} onClick={() => handleNavigate(val.users[0]?._id)} />
                                    </Box>
                                    <Box>
                                      <Typography color="rgb(45, 40, 103)" sx={{ marginTop: '7px' }} fontWeight={700}>
                                        {user.Name}
                                      </Typography>
                                    </Box>
                                    <Box sx={{gap:1,display:"flex"}}>
                                      <Button variant="outlined" onClick={() => { HandlesubmitRemove(val.users[0]?._id) }}>
                                        Cancel
                                      </Button>
                                      <Button variant="contained" onClick={() => { Handlesubmitjoin(val.users[0]?._id) }} >
                                        Accept
                                      </Button>
                                    </Box>
                                  </Box>

                                ))
                              }
                              {/* <Box>
          <Avatar src={users[0]?.ProfilePhoto } onClick={() => handleNavigate(val.users[0]._id)} />
        </Box>
        <Box>
          <Typography color="rgb(45, 40, 103)" sx={{ marginTop: '7px' }} fontWeight={700}>
            {val.users[0]?.Name}
          </Typography>
        </Box> */}
                            </Box>
                          ))
                        ) : (
                          <Typography color="rgb(114, 140, 166)" textAlign="center" sx={{ marginTop: '20px', fontSize: '14px' }}>
                            No Requests Found
                          </Typography>
                        )
                      ) : (
                        invited && invited.length > 0 ? (

                          invited?.map((val, ind) => (
                           

                            <Box  >
                              {
                                val.users.map((user, UserID) => (
                                  

                                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px',cursor:'pointer' }}>
                                    <Box>
                                      <Avatar src={user.ProfilePhoto} onClick={() => handleNavigate(val.users[0]._id)} />
                                    </Box>
                                    <Box>
                                      <Typography color="rgb(45, 40, 103)" sx={{ marginTop: '7px' }} fontWeight={700}>
                                        {user.Name}
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Button variant="outlined" onClick={() => { HandlesubmitRemove(val.users[0]?._id) }}>
                                        Cancel
                                      </Button>
                                    </Box>
                                  </Box>

                                ))
                              }
                              {/* <Box>
          <Avatar src={users[0]?.ProfilePhoto } onClick={() => handleNavigate(val.users[0]._id)} />
        </Box>
        <Box>
          <Typography color="rgb(45, 40, 103)" sx={{ marginTop: '7px' }} fontWeight={700}>
            {val.users[0]?.Name}
          </Typography>
        </Box> */}
                            </Box>
                          ))
                        ) : (
                          <Typography color="rgb(114, 140, 166)" textAlign="center" sx={{ marginTop: '20px', fontSize: '14px' }}>
                            No Invites Found
                          </Typography>
                        )
                      )}
                    </Box>
                  </Card>
                </Box>
              </Modal>
              <Modal open={openModal === 'Leave group'} onClose={handleClose}>
                <Box sx={{ ...modalStyle }}>
                  <Box
                           sx={{
                             position: 'absolute',
                             top: '50%',
                             left: '50%',
                             transform: 'translate(-50%, -50%)',
                             width: 300,
                             bgcolor: 'background.paper',
                             boxShadow: 24,
                             p: 4,
                             borderRadius: 2,
                           }}
                         > <Box sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                         <Box
                           sx={{
                             display: 'inline-flex',
                             alignItems: 'center',
                             justifyContent: 'center',
                             backgroundColor: '#EB4646',
                             borderRadius: '50%', // Makes the background circular
                             padding: 1, // Space around the icon
                             width: 40, // Set size of the box
                             height: 40,
                           }}
                         >
                           <Group size={24} color="white" />
                         </Box>
                       </Box>
                           <Typography sx={{textAlign:'center',fontWeight:"bold"}} id="delete-group-modal" variant="h5" component="h2">
                           Leave group
                           </Typography>
                           <Typography id="delete-group-confirmation" sx={{ mt: 2 }}>
                           Are you sure you want to leave this group?
                           </Typography>
                           <Box sx={{
                     mt: 3,
                     display: "flex",
                     flexDirection: "column",
                     justifyContent: "center",
                     alignItems: "center",
                     gap: 2,
                     width:"20",
                     cursor:'pointer'
                    
                      // Adds spacing between buttons
                   }}>
                             <Box 
                   backgroundColor="#EB4646" 
                   borderRadius={2} 
                   fontWeight={"bold"}
                   color="white" 
                   width={300} 
                   height={40} 
                   display="flex" 
                   justifyContent="center" 
                   alignItems="center"
                   cursor='pointer' 
                   textAlign="center"
                   onClick={HandleRemove}
                   
                 >
                   Leave
                 </Box>
                 <Box 
                   backgroundColor="#E4E9EE" 
                   borderRadius={2} 
                   fontWeight={"bold"}
                   color="#7591AA" 
                   width={300} 
                   height={40} 
                   display="flex" 
                   justifyContent="center" 
                   alignItems="center"
                   cursor='pointer'  
                   textAlign="center"
                   onClick={handleClose}
                 >
                   Cancel
                 </Box>
                             
                           </Box>
                         </Box>
                </Box>
              </Modal>
            </Box>
            <Box
              sx={{
                width: '400px',
                height: '400px',
                borderRadius: '2px',
                overflow: 'hidden',
                flexShrink: 0,
                position: 'relative', // For proper placement of the icon
              }}
            >
              <Avatar
                src={groupjoin[0]?.CoverImage}
                alt=""
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '2px', // Ensure rectangular shape
                }}
              />
              <IconButton
                onClick={handleClick}
                sx={{
                  position: 'absolute',
                  // Use breakpoints for responsive positioning
                  bottom: { xs: '20px', md: '20px' }, // Adjust distance for mobile and desktop
                  right: { xs: '20px', md: '20px' }, // Adjust distance for mobile and desktop
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                  },
                }}
              >
                <Settings />
              </IconButton>
            </Box>
            <Box>
              {/* Menu Component */}
              <Menu
                id="basic-menu"
                anchorEl={isanchorEl}
                open={opens}
                onClose={handleCloseis} // Handles clicking outside the menu
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}

              >
                <MenuItem
                  onClick={() => navigate(`/managegroup`, { state: state })}
                  sx={{
                    borderBottom: '2px solid rgb(240, 242, 244)',
                    '&:hover': { backgroundColor: '#4521B6', color: 'white' },
                    display: groupjoin[0]?.isAdmin ? "flex" : 'none'
                  }}
                >
                  Manage group
                </MenuItem>
                <MenuItem
                  onClick={() => handleOpen('pending members')}
                  sx={{
                    borderBottom: '2px solid rgb(240, 242, 244)',
                    '&:hover': { backgroundColor: '#4521B6', color: 'white' },
                    display: groupjoin[0]?.isAdmin ? "flex" : 'none'
                  }}
                >
                  Pending members
                </MenuItem>
                <MenuItem
                  onClick={() => handleOpen('Leave group')}
                  sx={{
                    borderBottom: '2px solid rgb(240, 242, 244)',
                    '&:hover': { backgroundColor: 'red', color: 'white' },
                    display: groupjoin[0]?.isMember ? "flex" : 'none'
                  }}
                >
                  Leave group
                </MenuItem>
                <MenuItem
                  onClick={handleClickcopy}
                  sx={{
                    '&:hover': { backgroundColor: '#4521B6', color: 'white' },
                  }}
                >
                  Copy link
                </MenuItem>
              </Menu>
            </Box>
          </Box>


        </Container>

      </Box>
      <Container>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 700,
            color: 'rgb(114, 140, 166)',
            mt: 5,
          }}
        >
          {groupjoin[0]?.PostCount} Post
        </Typography>
        <Box>
          {infogroup && infogroup.length > 0 ? (
            infogroup.map((data, index) => (
              // <Box key={index} sx={{display:"flex",Description:'row'}}>
              //   <Typography  
              //   >Post: {post.Description}</Typography>
              //   {post.karaokes && post.karaokes.length > 0 ? (
              //     post.karaokes.map((karaoke, i) => (
              //       <Box key={i} sx={{display:"flex"}}>
              //         <img
              //           src={karaoke.CoverImageURL}
              //           alt={karaoke.Name}
              //           onClick={() => {handleSongCollborationNavigate(post)}}
              //           style={{ width: '200px', height: 'auto' }}
              //         />
              //         <Typography>{karaoke.Name}</Typography>
              //       </Box>
              //     ))
              //   ) : (
              //     <p>No karaokes available for this post.</p>
              //   )}
              // </Box>
              <Button style={{ textDecoration: "none" }} onClick={() => { navigate(`/record/${data._id}`) }}>
                <Box sx={{ margin: 2 }}>

                  <Box><Card key={index} sx={{ width: 178, borderRadius: '10px', mb: 1 }}>
                    <CardMedia
                      sx={{ height: 178 }}
                      image={data.CoverImageURL}
                    />

                  </Card></Box>
                  <Box sx={{ textOverflow: "ellipsis" }}>
                    {
                      data.karaokes && data.karaokes.map((val, ind) => (
                        <Box
                          sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap', // Ensure text stays on one line
                            width: 178,
                            color: "#2D2867",
                            fontWeight: 800,
                            textAlign: 'left'
                          }}
                        >
                          {val.Name}
                        </Box>
                      ))
                    }
                    {
                      data.users && data.users.map((val, ind) => (
                        <>
                          <Box sx={{ fontSize: "13px", color: "#2D2867", textAlign: 'left', width: 178, overflow: 'hidden', textOverflow: 'ellipsis', textTransform: 'capitalize' }} >{val.Name}</Box>
                        </>
                      ))
                    }
                    <Box sx={{ display: 'flex' }}>
                      <>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', alignContent: 'center' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box>
                              <PlayArrow style={{ color: 'rgb(200, 202, 207)', fontSize: '14px', fontWeight: 400, marginTop: '5px' }} />
                            </Box>
                            <Box sx={{ color: 'rgb(170, 173, 180)', fontSize: '14px', fontWeight: 700 }}>
                              {data.NoOfPlay}
                            </Box>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box>
                              <Favorite style={{ color: 'rgb(200, 202, 207)', fontSize: '14px', fontWeight: 400, marginTop: '5px' }} />
                            </Box>
                            <Box sx={{ color: 'rgb(170, 173, 180)', fontSize: '14px', fontWeight: 700 }}>
                              {data.NoOfLikes}
                            </Box>
                          </Box>
                        </Box>
                      </>
                    </Box>
                  </Box>
                </Box></Button>
            ))
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                color: 'gray',
                minHeight: '50vh'
              }}
            >
              <Typography variant="h3" sx={{ marginBottom: 2, color: 'black' }}>
              No posts yet!
              </Typography>
              <Typography variant="h5" sx={{ color: 'gray' }}>
              Open the SparkSinger app to create your first post.
              </Typography>
            </Box>
          )}
        </Box>

      </Container>
    </Box>



  )
}

export default Groupinfo