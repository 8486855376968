import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';



export default function BasicCard({video,width,mute,icon,playIcon,likeIcon,like,play,descript,white,tittle1,tittle2,tittle3}) {
  return (
    <Card sx={{ margin:0,color:white,position:"relative",textAlign:"center",boxShadow:"0",background:"0"}}>
      <CardContent>
       {video}
       <Box sx={{display:"flex",alignItems:"center",width:"155px",gap:1,position:"absolute",top:"70%",left:"35%",color:"white",backgroundColor:"#2D2867",borderRadius:2}}>
        <Box>{icon}</Box>
        <Box>
       {
       mute}</Box>
       </Box>
       <Box sx={{textAlign:"center",marginTop:3}}>
            <Typography>{descript}</Typography>
           <Box sx={{display:"flex",justifyContent:"center",gap:3,alignItems:"center"}}>
           <Typography sx={{display:"flex"}}>{playIcon}{like}</Typography>
           <Typography sx={{display:"flex"}}>{likeIcon}{play}</Typography>
           
           </Box>
        </Box>
       
      </CardContent>
      <Box>
       <Typography sx={{fontSize:"20px"}}>{tittle1}</Typography>
           <Typography sx={{fontSize:"12px",color:"#8D858C"}}>{tittle2}</Typography>
           <Typography sx={{fontSize:"12px",color:"#8D858C"}}>{tittle3}</Typography>
       </Box>
    </Card>
  );
}
