import { Box, Button } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'

import uploaBeat from '../../data/upload-beat';
import { useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import './styles/upload_beat.css'
const UplaodBeat = () => {
    const location = useLocation();
    const navigate = useNavigate();
  return (
    <div>
    <div class="items--container">
       
{
uploaBeat && uploaBeat.map((data,index)=>(
    <>
   {
    index==0?
    <div><Button size='small' onClick={()=>{
        navigate(-1)
    }} sx={{ color: "white",visibility:location.pathname=='/uploadbeat/beats'?"hidden":"visible"}} variant="outlined" startIcon={<ArrowBackIcon />}>
    Back
</Button></div>:""
   }
    <div class='item'>
            <div class="arrow top"></div>
            <div class="content" style={{ color: data.path == location.pathname ? 'white' : "#637C74" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div className='no_class'>{data.id}</div>
                    <div>{data.tittle}</div>
                </div>
            </div>
            <div class="arrow bottom"></div>
        </div>
        </>
))
}

</div>
<div>
<Outlet/>
</div>
</div>
  )
}

export default UplaodBeat