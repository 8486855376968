import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import image from "../assest/images/Bright Color-01.png";

const Spark = () => {
  const navigate = useNavigate();
  const [selectedLink, setSelectedLink] = useState(null);

  const handleNavigation = (path, link) => {
    navigate(path);
    setSelectedLink(link);
  };

  // Remove color when clicking outside the links
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".footer-link")) {
        setSelectedLink(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
       
      }}
    >
      {/* Main Content */}

      {/* Footer */}
      <Box
        sx={{
          backgroundColor: "#F0F2F4",
          width: "100%",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          color: "#AAADB4",
          height: { xs: "auto", sm: 70 },
          textAlign: "center",
          padding: { xs: 2, sm: 0 },
        }}
      >
        {/* Left side: Copyright Text */}
        <Typography
          sx={{
            flex: 1,
            minWidth: "200px",
            fontSize: { xs: "12px", sm: "16px" },
          }}
        >
          © 2024 SparkSinger, Inc. All Rights Reserved.
        </Typography>

        {/* Center: Logo */}
        <Box
          sx={{
            width: { xs: "70%", sm: "15%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={image}
            alt="Logo"
            style={{
              width: "130px",
              height: "auto",
              borderRadius: "8px",
            
            }}
          />
        </Box>

        {/* Right side: Links */}
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          {[
            { text: "Privacy Policy", path: "/privacy-policy" },
            { text: "Terms And Service", path: "/terms-of-service" },
            { text: "Copyright", path: "/copyright" },
            { text: "Guidelines", path: "/guidelines" },
          ].map((item, index) => (
            <Box
              key={index}
              className="footer-link"
              onClick={(e) => {
                e.stopPropagation(); // Prevent closing when clicking inside
                handleNavigation(item.path, item.text);
              }}
              sx={{
                fontSize: { xs: 14, sm: 16 },
                borderBottom:
                  selectedLink === item.text
                    ? "2px solid #4521B6"
                    : "1px solid black",
                cursor: "pointer",
                color: selectedLink === item.text ? "#4521B6" : "black",
                "&:hover": {
                  color: "#4521B6",
                  borderBottom: "1px solid #4521B6",
                },
              }}
            >
              {item.text}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Spark;
