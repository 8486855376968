import React,{useEffect, useRef, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, Box, Container,Card , CircularProgress, Modal, colors, Slider, Button, Fade } from "@mui/material";
import axios from "axios";
import { url } from "../../config/config";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';  
import FavoriteIcon from '@mui/icons-material/Favorite';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import './joinlist.css';
import ProfileTimeAgo from "../../components/profileTimestamps";
import logo from './logo1.jpeg';
import playStore from './Play-Store-icon.png';
import Apple from './apple.png'
import { FaFastBackward } from "react-icons/fa";
import { FaBackwardFast, FaForwardFast } from "react-icons/fa6";
import { CiHeart } from "react-icons/ci";
import { CiStar } from "react-icons/ci";
import { IoShareSocialOutline } from "react-icons/io5";
import { HiMiniSpeakerWave } from "react-icons/hi2";
import { IoIosPause } from "react-icons/io";
import CancelIcon from '@mui/icons-material/Cancel';
import { RxCross2 } from "react-icons/rx";
import { IoIosHeart } from "react-icons/io";
import { jwtDecode } from "jwt-decode";
import { IoMdStar } from "react-icons/io";
import { PiSpeakerSimpleSlashFill } from "react-icons/pi";
const style = {
    position: 'absolute',
    top: '50%',
    left: '43%',
    transform: 'translate(-50%, -50%)',
    width: 512,
    height: 512,
  };

const JoinList = () => {
    const params = useParams();
     const scrollRef = useRef();
     const playerRef = useRef();
     const videoPlayerRef = useRef();
     const [songData,setSongData] = useState([])
     const [joinList,setJoinList] = useState([])
     console.log(joinList,'JoinValuesssssss')
     const [recomended,setRecomended] = useState([])
     const [audioTime,setAudioTime] = useState('')
     const [play,setPlay] = useState(false)
     const [icon,setIcon] = useState(false)
     const [open, setOpen] = useState(false);

     
     const [playerData,setPlayerData] = useState({});
     const [playerLoader,setPlayerLoader] = useState(true)
     const [videpPlayModal,setVideoPlayerModal] = useState(true)
      const userTokenData = localStorage.getItem('token') !== null ? jwtDecode(localStorage.getItem('token')) : ''
      const [showSlider, setShowSlider] = useState(false);
      const [speakerController,setSpeakerController] = useState(false);
  const [volume, setVolume] = useState(50); // default volume level
     const [controlsState,setControlsState] = useState({
        likeArray : [],
        favotite : [], 
     })
     const [playlistValues,setPlaylistValues] = useState([])
     const navigate = useNavigate();
     const min = scrollRef.current ? scrollRef.current.min : ''
       const max = scrollRef.current ? scrollRef.current.max : ''
        const value = scrollRef.current ? scrollRef.current.value : ''
        if(scrollRef.current != null ){
         scrollRef.current.style.background = `linear-gradient(to right, #1dca7e 0%, #1dca7e ${(value-min)/(max-min)*100}%, #797979 ${(value-min)/(max-min)*100}%, #797979 100%)`
         scrollRef.current.oninput = function() {
           this.style.background = `linear-gradient(to right, #1dca7e 0%, #1dca7e ${(this.value-this.min)/(this.max-this.min)*100}%, #797979 ${(this.value-this.min)/(this.max-this.min)*100}%, #797979 100%)`
           console.log('vijay')
         };
        }

    useEffect(()=>{
        const GetProfile=async()=>{
         try {
            const config={
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
              }
           const res = await axios.get(`${url}/api/SongCollaboration/getSongCollaborationById/${params.id}`,config);
           console.log(res.data,'SongCollaborationDetails')
           setSongData(res.data.data)
       } catch (error) {
         console.log(error)
       }
       //    
        }
        GetProfile()
      },[])

    useEffect(() => {
        const getJoinList = async () => {
            try{
                const config={
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                  }
                const res = await axios.get(`${url}/api/SongCollaboration/getSongCollaborationJoinBySongCollaborationID/${params.id}`,config);
                console.log(res,'joinDetailssssss')
                setJoinList(res.data.data)
            }catch (error) {
                console.log(error)
            }
        }
        getJoinList()
    },[])
    useEffect(() => {
        const RecommendedList = async () => {
            try{
                const Index = await Number(params.ind)
                console.log(typeof Index)
                const res = await axios.post(`${url}/api/SongCollaboration/getSongCollaborationByJoinListByUserId/${params.userId}/${Index}`)
                console.log(res.data.data,'userRelatedDetials')
                setRecomended(res.data.data)
            }catch (error) {
                console.log(error)
            }
        }
        RecommendedList()
    },[])

    const str_pad_left = (string,pad,length) => {
        return (new Array(length+1).join(pad)+string).slice(-length);
      }

    const audioDuration = playerRef.current ? Math.floor(playerRef.current.duration) : 0;
    const currentValue = Math.floor(audioTime);
    const minutes = Math.floor(audioDuration / 60);
    const seconds = Math.floor(audioDuration - minutes * 60);
    const currentTimeVaue = str_pad_left(minutes,'0',2) + ':' + str_pad_left(seconds,'0',2);
    const currentminutes = Math.floor(audioTime/ 60);
    const currentSeconds = Math.floor(audioTime - currentminutes * 60)
    const currentRunning =  str_pad_left(currentminutes,'0',2) + ':' + str_pad_left(currentSeconds,'0',2);
   
    console.log(songData,'songDStataaaaa')
    const handleTime = (event) => {
        setAudioTime(playerRef.current.currentTime)
        if(playerRef.current && videoPlayerRef.current){
            console.log(playerRef.current.currentTime,'eventVALUESSSSS')
            videoPlayerRef.current.currentTime = playerRef.current.currentTime
            console.log(playerRef.current.currentTime)
        }
    }

    async function PlaylistData (val) {
        console.log(val,'valuessssssPlaylistData')
       if(val){
        try {
            const config={
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
              }
            const response = await axios.get(`${url}/api/UserPlayList/web-getUserPlayListByUserID?SongCollaborationId=${val}`,config)
            console.log(response.data.data,'playerCalledAndPlayed')
             setPlaylistValues(response?.data?.data)
        } catch (error){
            console.log(error)
        }
       }
    }

    const handleInput = async (e) => {
        console.log(e.target.value,'inputsssValue')
        setAudioTime(e.target.value)
        if(playerRef.current){
            playerRef.current.currentTime = e.target.value;
        }
        if(videoPlayerRef.current){
            videoPlayerRef.current.currentTime = e.target.value;
        }
       //  playerRef.current.play()
        // audioRef.current.pause()
    }

 
   const handlePlay = () => {
      setPlayerLoader(false)
   }

   const handleVideoCanPlay = () => {
    setPlayerLoader(false)
   }
   const handleOpen = () => setOpen(true);
     const handleClose = () => setOpen(false);

   const handleLikeSingCollab = () => {
    setControlsState((prevState) => ({
        ...prevState,
        likeArray : [...prevState.likeArray,playerData._id]
    }))
    try{
        const data = {
          UserID :userTokenData.userId,
          SongCollaborationID : playerData._id
        }
        const config={
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = axios.post(`${url}/api/SongCollaborationLike/createSongCollaborationLike`,data,config)
        console.log(res)
      } catch (error){
          console.log(error)
      }
   }

   const handleFavouiteSingCollab = async () => {
    setControlsState((prevState) => ({
        ...prevState,
        favotite : [...prevState.favotite,playerData._id]
    }))
     try{
        const config={
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          }
        const data =  {
            UserPlayListID : playlistValues[0]._id,
            SongCollaborationID : playerData._id,
            UserID: userTokenData.userId
          }
        const response  = await axios.post(`${url}/api/SongCollaborationPlayList/createSongCollaborationPlayList`,data,config)
        console.log(response.data,'playlsitCreated')
     }catch (error){
        console.log(error)
     }
   }

  console.log(controlsState,'controllssValuesssss')
    return(
        <Box sx={{minHeight:"100vh"}}>
         <Box className='back-image' sx={{backgroundImage:`url(${songData[0]?.CoverImageURL})`,height:{xl:'24rem',lg:'24rem',md:'16rem'}}}>
        </Box>
        <Box className='bg-text' sx={{top:{xl:'30%',lg:'30%',md:'35%'}}}>
        <Box sx={{display:'flex',flexDirection:'column',padding:'80px',textAlign:'center',mt:20}}>
                <Box sx={{display:'flex',flexDirection:'column',gap:.5}}>
                    <Box>{songData[0]?.karaokes && songData[0]?.karaokes[0]?.Name}</Box>
                    <Box sx={{fontSize:'1em',fontWeight:300}}>Collab</Box>
                </Box>
                <Box sx={{display:'flex',justifyContent:'center',marginTop:'20px',gap:2}}>
                    <Box onClick={() => {navigate(`/otherProfile/${songData[0]?.users[0]?._id}`)}}>
                        <Box sx={{cursor:'pointer'}}>
                            <Avatar sx={{width:'86px',height:'86px'}} src={songData[0]?.users && songData[0]?.users[0]?.ProfilePhoto}/>
                        </Box>
                    </Box>
                    <Box sx={{display:'flex',flexDirection:'column'}}>
                        <Box sx={{textAlign:'left',marginBottom:'5px',cursor:'pointer'}} onClick={() => {}}>{songData[0]?.users && songData[0]?.users[0]?.Name !== '' ?songData[0]?.users[0]?.Name : songData[0]?.users[0]?.Email }</Box>
                        <Box sx={{textAlign:'left',flexDirection:'column',background:'#f5f5f5',padding:'10px'}}>
                            <Box sx={{fontSize:'1em',fontWeight:700,color:'#1dca7e',padding:'3px'}}>{songData[0]?.Description}</Box>
                            <Box sx={{background:'#fff',display:'flex',gap:2}}>
                                 <Box sx={{width:'98px',background:'rgba(0, 0, 0, 0)',height:'98px',borderRadius:'0',transition:'background-color 0.5s ease',backgroundImage:`url(${songData && songData[0]?.CoverImageURL})`,backgroundSize:'cover',textAlign:'center',backgroundColor : playerData._id === songData[0]?._id && 'rgba(0, 0, 0, 0.5)' , backgroundBlendMode : playerData._id === songData[0]?._id && 'multiply' , '&:hover': {backgroundColor:'rgba(0, 0, 0, 0.5)',backgroundBlendMode:'multiply','&:hover .topPlayIcon':{display:'block'},cursor:'pointer'}}}>
                                    {/* <img style={{width:'98px',height:'98px',borderRadius:'0'}} src={songData && songData[0]?.CoverImageURL}/> */}
                                    {
                                       playerData._id === songData[0]?._id &&  icon ? <Box onClick={() => {
                                            setIcon(false)
                                            playerRef.current.pause()
                                        }} className='topPlayIcon' sx={{marginTop:'25px',display:playerData._id === songData[0]?._id ? 'block' : 'none'}}>
                                        <IoIosPause size={25} style={{color:'white',background:'rgba(0, 0, 0, 0.7)',padding:'10px',borderRadius:'50%',textAlign:'center'}}/>
                                    </Box> : <Box onClick={() => {
                                         PlaylistData(songData[0]?._id)
                                        setPlayerData(songData[0])
                                            setIcon(true)
                                            setPlay(true)
                                            setPlayerLoader(true)
                                            setVideoPlayerModal(true)
                                            if(playerRef.current.currentTime > 0){
                                                console.log('wokingInitaillay')
                                                playerRef.current.play()
                                             }
                                        }} className='topPlayIcon' sx={{marginTop:'25px',display:playerData._id === songData[0]?._id ? 'block' : 'none'}}>
                                        <PlayArrowIcon style={{color:'white',background:'rgba(0, 0, 0, 0.7)',padding:'10px',borderRadius:'50%',textAlign:'center'}}/>
                                    </Box>
                                    }
                                 </Box>
                                 <Box sx={{display:'flex',flexDirection:'column',width:'400px',gap:.5,marginTop:'15px'}}>
                                    <Box sx={{fontSize:'0.83em',fontWeight:700,color:'#2c2c2c'}}>{songData[0]?.karaokes && songData[0]?.karaokes[0]?.Name}</Box>
                                    <Box sx={{color:'#636363',fontSize:'0.83em'}}>{songData[0]?.users && songData[0]?.users[0]?.Name !== '' ?songData[0]?.users[0]?.Name : songData[0]?.users[0]?.Email }</Box>
                                    <Box sx={{display:'flex',marginTop:'5px',justifyContent:'space-between'}}>
                                        <Box sx={{display:'flex'}}>
                                           <Box><PlayArrowIcon style={{color:'#b7b7b7',fontSize:'0.8em'}}/></Box>
                                           <Box sx={{color:'#b7b7b7',fontSize:'0.8em'}}>{songData && songData[0]?.NoOfPlay}</Box>
                                        </Box>
                                        <Box sx={{display:'flex'}}>
                                           <Box ><FavoriteIcon style={{color:'#b7b7b7',fontSize:'0.8em'}}/></Box>
                                           <Box sx={{color:'#b7b7b7',fontSize:'0.8em'}}>{songData && songData[0]?.LikesUserID.length}</Box>
                                        </Box>
                                        <Box sx={{display:'flex',padding:'0 5px'}}>
                                           <Box><AccessTimeIcon style={{color:'#b7b7b7',fontSize:'0.8em'}}/></Box>
                                           <Box sx={{color:'#b7b7b7',fontSize:'0.8em'}}><ProfileTimeAgo timestamp={songData && songData[0]?.createdAt}/></Box>
                                        </Box>
                                        
                                    </Box>
                                 </Box>
                                
                            </Box>
                        </Box>
                        <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
          <Button variant="contained" onClick={handleOpen}>
            Join Now
          </Button>
        </Box>
                    </Box>
                </Box>
            </Box>  
        </Box>
        <Modal open={open} onClose={handleClose}>
          <Fade in={open}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
                textAlign: "center",
                width: "400px",
              }}
            >
              <h2>Welcome to the Collab</h2>
              <p>Get ready to join the music collaboration!</p>
              <Button variant="contained" onClick={handleClose}>
                Close
              </Button>
            </Box>
          </Fade>
        </Modal>
       
              <Container sx={{marginTop:'60px'}} maxWidth='lg'>
                <Box sx={{display:'flex',gap:2}}>
                   <Card sx={{height:'fit-content',marginBottom:'20px'}}>
                    <Box sx={{display:'flex',flexDirection:'column'}}>
                        <Box sx={{background:'#e8e8e8',color:'#000',fontSize:'0.8rem',padding:'15px',fontWeight:'bold'}}>
                            {`${joinList && joinList.length || 0} Singers have joined this collab`}
                        </Box>
                        {
                            joinList &&  joinList.map((val,ind) => (
                                console.log(val,"kesavanagsgsf"),
                                
                                <Box sx={{display:'flex',background:'#fff',padding:'15px',gap:2}}>
                                       <Box sx={{width:'62px',background:'rgba(0, 0, 0, 0)',height:'62px',borderRadius:'0',transition:'background-color 0.5s ease',backgroundImage:`url(${val.joinSongCollaboration && val.joinSongCollaboration[0]?.CoverImageURL})`,cursor:'pointer',backgroundSize:'cover',textAlign:'center',backgroundColor : playerData._id === val.joinSongCollaboration[0]?._id && 'rgba(0, 0, 0, 0.5)' , backgroundBlendMode : playerData._id === val.joinSongCollaboration[0]._id && 'multiply','&:hover': {backgroundColor:'rgba(0, 0, 0, 0.5)',backgroundBlendMode:'multiply','&:hover .playIcon' :{display:'block'}}}}>
                                        {/* <img style={{width:'62px',height:'62px',borderRadius:'0'}} src={val.songCollaboration && val.songCollaboration.CoverImageURL}/> */}
                                        {
                                           playerData._id === val.joinSongCollaboration[0]?._id &&  icon ? <Box onClick={() => {
                                                setIcon(false)
                                                playerRef.current.pause()
                                            }} className='playIcon' sx={{marginTop:'14px',display:playerData._id === val.joinSongCollaboration[0]?._id ? 'block' : 'none'}}>
                                            <IoIosPause size={25} style={{color:'white',background:'rgba(0, 0, 0, 0.7)',padding:'5px',borderRadius:'50%',textAlign:'center'}}/>
                                        </Box> :  <Box onClick={() => {
                                            PlaylistData(val?.joinSongCollaboration[0]?._id)
                                             setPlayerData(val.joinSongCollaboration[0])
                                            setPlay(true)
                                            setIcon(true)
                                            setPlayerLoader(true)
                                            setVideoPlayerModal(true)
                                             if(playerRef.current.currentTime > 0){
                                                playerRef.current.play()
                                             }
                                            }} className='playIcon' sx={{marginTop:'14px',display:playerData._id === val.joinSongCollaboration[0]?._id ? 'block' : 'none'}}>
                                        <PlayArrowIcon style={{color:'white',background:'rgba(0, 0, 0, 0.7)',padding:'5px',borderRadius:'50%',textAlign:'center'}}/>
                                    </Box>
                                        }
                                       </Box>
                                       <Box sx={{display:'flex',flexDirection:'column',gap:.3,width:'500px'}}>
                                             <Box sx={{color:'#2c2c2c',fontSize:'12px',fontWeight:700}}>{val?.joinSongCollaboration[0]?.karaokes[0]?.Name}</Box>
                                             <Box sx={{color:'#636363',fontSize:'12px'}}>{`${songData[0]?.users && songData[0]?.users[0]?.Name !== '' ? songData[0]?.users[0]?.Name : songData[0]?.users[0]?.Email} + ${val?.joinSongCollaboration[0]?.users[0]?.Name !== '' ? val?.joinSongCollaboration[0].users[0]?.Name : val?.joinSongCollaboration[0].users[0]?.Email}`}</Box>
                                             <Box sx={{display:'flex',marginTop:'5px',justifyContent:'space-between'}}>
                                        <Box sx={{display:'flex'}}>
                                           <Box><PlayArrowIcon style={{color:'#b7b7b7',fontSize:'0.8em'}}/></Box>
                                           <Box sx={{color:'#b7b7b7',fontSize:'0.8em'}}>{val.joinSongCollaboration &&  val.joinSongCollaboration[0]?.NoOfPlay}</Box>
                                        </Box>
                                        <Box sx={{display:'flex'}}>
                                           <Box ><FavoriteIcon style={{color:'#b7b7b7',fontSize:'0.8em'}}/></Box>
                                           <Box sx={{color:'#b7b7b7',fontSize:'0.8em'}}>{val.joinSongCollaboration &&  val.joinSongCollaboration[0]?.NoOfLikes}</Box>
                                        </Box>
                                        <Box sx={{display:'flex',padding:'0 5px'}}>
                                           <Box><AccessTimeIcon style={{color:'#b7b7b7',fontSize:'0.8em'}}/></Box>
                                           <Box sx={{color:'#b7b7b7',fontSize:'0.8em'}}><ProfileTimeAgo timestamp={val.joinSongCollaboration &&  val.joinSongCollaboration[0]?.createdAt}/></Box>
                                        </Box>
                                    </Box>
                                       </Box>
                                </Box>
                            ))
                        }
                    </Box>
                   </Card>
                   <Box sx={{display:'flex',flexDirection:'column',gap:1}}>
                     <Card sx={{borderRadius:'0'}}>
                         <Box sx={{display:'flex',padding:'5px 10px',justifyContent:'space-between',width:'350px',paddingTop:'7px'}}>
                            <Box sx={{display:'flex',gap:1}}>
                                <Box>
                                    <img  style={{width:'2em',height:'2em',borderRadius:'0'}} src={logo}/>
                                </Box>
                                <Box sx={{display:'flex',marginTop:'6px',gap:.4}}>
                                    <Box sx={{fontSize:'.87em',color:'#636363',fontWeight:400}}>Download</Box>
                                    <Box sx={{color:'#000',fontSize:'.87em',fontWeight:'bold'}}>SparkSinger</Box>
                                </Box>
                            </Box>
                            <Box sx={{display:'flex',gap:2}}>
                                <Box>
                                <img style={{width:'2em',height:'2em',borderRadius:'0'}} src={playStore}/> 
                                </Box>
                                <Box>
                                <img style={{width:'2em',height:'2em',borderRadius:'0'}} src={Apple}/> 
                                </Box>
                            </Box>
                         </Box>
                     </Card>
                     <Card sx={{borderRadius:'0',marginBottom:'90px'}}>
                           <Box sx={{color:'#000',fontSize:'.87em',fontWeight:'bold',textAlign:'center',padding:'15px',alignItems:'center'}}>Recommendations</Box>
                           {
                             recomended && recomended.map((val,ind) => (
                                <Box sx={{display:'flex',padding:'15px',gap:2,borderTop:'1px solid #e8e8e8'}}>
                                    {/* <Box>
                                        <img style={{width:'62px',height:'62px',borderRadius:'0'}}  src={val.CoverImageURL}/>
                                    </Box> */}
                                      <Box sx={{width:'62px',background:'rgba(0, 0, 0, 0)',height:'62px',borderRadius:'0',transition:'background-color 0.5s ease',backgroundImage:`url(${val.CoverImageURL})`,cursor:'pointer',backgroundSize:'cover',textAlign:'center',backgroundColor : playerData._id === val._id && 'rgba(0, 0, 0, 0.5)' , backgroundBlendMode : playerData._id === val._id && 'multiply','&:hover': {backgroundColor:'rgba(0, 0, 0, 0.5)',backgroundBlendMode:'multiply','&:hover .RecommendedplayIcon' :{display:'block'}}}}>
                                        {/* <img style={{width:'62px',height:'62px',borderRadius:'0'}} src={val.songCollaboration && val.songCollaboration.CoverImageURL}/> */}
                                        {
                                           playerData._id === val?._id && icon ? <Box onClick={() => {
                                                setIcon(false)
                                                playerRef.current.pause()
                                            }} className='RecommendedplayIcon' sx={{marginTop:'14px',display:playerData._id === val._id ? 'block' : 'none'}}>
                                            <IoIosPause size={25} style={{color:'white',background:'rgba(0, 0, 0, 0.7)',padding:'5px',borderRadius:'50%',textAlign:'center'}}/>
                                        </Box> :  <Box onClick={() => {
                                            PlaylistData(val._id)
                                             setPlayerData(val)
                                            setPlay(true)
                                            setIcon(true)
                                            setPlayerLoader(true)
                                            setVideoPlayerModal(true)
                                             if(playerRef.current.currentTime > 0){
                                                playerRef.current.play()
                                             }
                                            }} className='RecommendedplayIcon' sx={{marginTop:'14px',display:playerData._id === val._id ? 'block' : 'none'}}>
                                        <PlayArrowIcon style={{color:'white',background:'rgba(0, 0, 0, 0.7)',padding:'5px',borderRadius:'50%',textAlign:'center'}}/>
                                    </Box>
                                        }
                                       </Box>
                                    <Box sx={{display:'flex',flexDirection:'column',width:'300px'}}>
                                        <Box sx={{color:'#2c2c2c',fontSize:'12px',fontWeight:700}}>{val.karaokes[0]?.Name}</Box>
                                        <Box sx={{color:'#636363',fontSize:'12px',marginTop:'5px'}}>{val.users[0]?.Name != '' ? val.users[0]?.Name : val.users[0]?.Email }</Box>
                                        <Box sx={{display:'flex',marginTop:'5px',justifyContent:'space-between',width:'100%'}}>
                                        <Box sx={{display:'flex'}}>
                                           <Box><PlayArrowIcon style={{color:'#b7b7b7',fontSize:'0.8em'}}/></Box>
                                           <Box sx={{color:'#b7b7b7',fontSize:'0.8em'}}>{val.NoOfPlay}</Box>
                                        </Box>
                                        <Box sx={{display:'flex'}}>
                                           <Box ><FavoriteIcon style={{color:'#b7b7b7',fontSize:'0.8em'}}/></Box>
                                           <Box sx={{color:'#b7b7b7',fontSize:'0.8em'}}>{val.NoOfLikes}</Box>
                                        </Box>
                                        <Box sx={{display:'flex',padding:'0 5px'}}>
                                           <Box><AccessTimeIcon style={{color:'#b7b7b7',fontSize:'0.8em'}}/></Box>
                                           <Box sx={{color:'#b7b7b7',fontSize:'0.8em'}}><ProfileTimeAgo timestamp={val.createdAt}/></Box>
                                        </Box>
                                    </Box>
                                    </Box> 
                                </Box>
                             ) )
                           }
                     </Card>
                   </Box>
                </Box>
              </Container>
              <Box className={play ? 'show' : 'footer'} sx={{display:'flex',flexDirection:'column'}}>
                <Box sx={{marginBottom:'6px',margin:'0'}}>
                    <input style={{margin:'0',width:'100%'}} id="joinInput" onChange={handleInput} max={audioDuration} value={currentValue} ref={scrollRef} type="range"/>
                </Box>
                <Box sx={{display:'flex',justifyContent:'space-between',background:'rgba(0, 0, 0, 0.8)',padding:'20px'}}>
                    <Box sx={{display:'flex',gap:6}}>
                        <Box sx={{display:'flex',gap:2,marginTop:'6px'}}>
                             <Box sx={{marginTop:'9px',cursor:'pointer'}}>
                                <FaBackwardFast style={{fontSize:'20px',color:'white'}}/>
                             </Box>
                             {
                                playerLoader ? <Box>
                                    <CircularProgress size={22} sx={{color:'white',fontSize:'10px',marginTop:'10px'}}/>
                                </Box> :   <>
                             {
                                icon ?  <Box sx={{cursor:'pointer'}} onClick={() => {
                                    setIcon(false)
                                    if( playerRef.current){
                                        playerRef.current.pause()
                                    }
                                    if(videoPlayerRef.current){
                                        videoPlayerRef.current.pause()
                                    }
                                    }}>
                                <IoIosPause style={{fontSize:'35px',color:'white'}}/>
                             </Box> :     <Box sx={{cursor:'pointer'}} onClick={() => {
                                setIcon(true)
                                if( playerRef.current){
                                    console.log('workinggggggggg')
                                    playerRef.current.play()
                                }
                                if(videoPlayerRef.current){
                                    videoPlayerRef.current.play()
                                }
                                setVideoPlayerModal(true)
                                }}>
                                <PlayArrowIcon style={{fontSize:'35px',color:'white'}}/>
                             </Box>
                             }
                             </>
                             }
                             <Box sx={{marginTop:'9px',cursor:'pointer'}}>
                                <FaForwardFast style={{fontSize:'20px',color:'white'}}/>
                             </Box>
                        </Box>
                        <Box sx={{display:'flex',gap:1}}>
                            <Box>
                                <img style={{width:'42px',height:'42px',borderRadius:'0'}} src={playerData?.CoverImageURL}/>
                            </Box>
                            <Box sx={{display:'flex',flexDirection:'column'}}>
                                <Box sx={{color:'white',fontSize:'1em',fontWeight:700}}>{'vijay'}</Box>
                                <Box sx={{color:'#636363',fontWeight:400}}>{`${joinList && joinList.length} collabs`}</Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{display:'flex',justifyContent:'space-between'}}>
                        <Box sx={{display:'flex',padding:'8px',gap:1}}>
                            <Box sx={{cursor:'pointer'}}>
                                {
                                    playerData.isLiked === true || controlsState?.likeArray?.includes(playerData._id) ? <Box>
                                      <IoIosHeart fill="#1dc97d" size={25} style={{marginTop:'3px'}}/>
                                    </Box>  : <Box onClick={handleLikeSingCollab}>
                                    <CiHeart style={{fontSize:'30px',color:'white'}}/>
                                    </Box> 
                                }   
                            </Box>
                            <Box sx={{cursor:'pointer'}}>
                                {
                                    playlistValues[0]?.isFavorite === true || controlsState?.favotite?.includes(playerData._id) ? <Box>
                                    <IoMdStar style={{fontSize:'30px',color:'#1dc97d'}}/>
                                </Box> :  <Box onClick={handleFavouiteSingCollab}>
                                <CiStar style={{fontSize:'30px',color:'white'}}/>
                                </Box>
                                }  
                            </Box>
                            <Box sx={{cursor:'pointer'}}>
                                <IoShareSocialOutline style={{fontSize:'30px',color:'white'}}/>
                            </Box>
                        </Box>
                        <Box
      sx={{
        position: 'relative',
        display: 'inline-block',
        cursor: 'pointer',
        backgroundColor: showSlider ? '#333' : 'transparent',
        padding: '10px',
        borderRadius: '8px',
        transition: 'background-color 0.3s ease',
      }}
      onMouseEnter={() => setShowSlider(true)}
      onMouseLeave={() => setShowSlider(false)}
    >
      {/* Slider Positioned Above the Speaker Icon */}
      {showSlider && (
        <Box
          sx={{
            position: 'absolute',
            bottom: '45px', // Adjust based on icon size
            left: '50%',
            transform: 'translateX(-50%)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#333',
            padding: '5px',
            borderRadius: '8px',
          }}
        >
          <Slider
            orientation="vertical"
            value={volume}
            onChange={(e, newValue) => {
                setVolume(newValue)
                if (playerRef.current) {
                    playerRef.current.volume = newValue / 100; // Ensure ref exists before setting volume
                  }
              
                  if (videoPlayerRef.current) {
                    videoPlayerRef.current.volume = newValue / 100; // Ensure ref exists before setting volume
                  }
            }}
            min={0}
            max={100}
            sx={{
              height: '100px', // Slider height
              color: 'white',
              '& .MuiSlider-thumb': {
                width: '10px',
                height: '10px',
                backgroundColor: 'white',
              },
              '& .MuiSlider-track': {
                backgroundColor: 'white',
              },
              '& .MuiSlider-rail': {
                backgroundColor: 'rgba(255, 255, 255, 0.3)',
              },
            }}
          />
        </Box>
      )}

      {/* Speaker Icon */}
      {
        speakerController === false ?  <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          color: 'white',
        }}
        onClick={() => {
            setSpeakerController(true)
            setVolume(0)
            playerRef.current.volume = 0
        }}
      >
        <HiMiniSpeakerWave style={{ fontSize: '28px', color: 'white' }} />
      </Box> :   <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          color: 'white',
        }}
        onClick={() => {
            setSpeakerController(false)
            setVolume(50)
            playerRef.current.volume = 1
        }}
      >
        <PiSpeakerSimpleSlashFill style={{ fontSize: '28px', color: 'white' }} />
      </Box>
      }
    </Box>
                        <Box sx={{padding:'10px',color:'white',fontWeight:500,fontSize:'1em',paddingTop:'14px'}}>{currentRunning} / {playerRef.current !== undefined ? currentTimeVaue : '00:00'}</Box>
                    </Box>
                </Box>
              </Box>
              {
                    playerData.PostType === 'Audio' && play === true  ?  <audio autoPlay ref={playerRef} onCanPlay={handlePlay} controls={false} onTimeUpdate={handleTime} src={playerData && playerData.MixedSongURL}/> :  
                    play &&
                    <Modal
                    sx={{ 
                        "& > .MuiBackdrop-root" : {
                                 backgroundColor:'rgba(0, 0, 0, .8)'
                              }
                        }}
                    open={videpPlayModal}
                    onClose={() => {
                        setVideoPlayerModal(false)
                        playerRef.current.pause()
                        setIcon(false)
                    }}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                  >
                    <Box sx={{ ...style, width: 200}}>
                       <Box>
                        <video muted onCanPlay={handleVideoCanPlay} ref={videoPlayerRef} autoPlay style={{width:512,height:512,objectFit:'cover'}} src={playerData && playerData.MixedSongURL}/>
                       </Box>
                       <Box onClick={() => {
                        setVideoPlayerModal(false)
                        setIcon(false)
                        playerRef.current.pause()
                    }} sx={{cursor:'pointer',position:'absolute',top:3,left:3,backgroundColor:'rgba(255, 255, 255, 0.5)',padding:'3px','&:hover':{
                        color:'black',background:'white'
                       }}}>
                         <RxCross2  size={20} style={{color:'#7c6e64'}}/>
                       </Box>
                    </Box>
                  </Modal>
                }
                {
                    playerData.PostType !== 'Audio' &&   <video  style={{display:'none'}} controls={false} onCanPlay={handlePlay} ref={playerRef} onTimeUpdate={handleTime}  autoPlay  src={playerData && playerData.MixedSongURL}/>
                }
        </Box>
    )
}

export default JoinList;