import { Box, Button, Card, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const Startpage = () => {
  const navigate = useNavigate()
  
  return (
    <Box sx={{ textAlign: 'center', p: 2 }}>
    <Typography 
      sx={{ 
        fontSize: { xs: '24px', sm: '28px', md: '32px' },
        fontWeight: 'bold'
      }}
    >
      Online-Only Offer: Up to 90% off on Premium
    </Typography>
    <Typography 
      sx={{ 
        fontSize: { xs: '16px', sm: '18px', md: '20px' },
        mt: 1
      }}
    >
      Unlimited songs and solos, access on both iOS and Android, and no ads.
    </Typography>
    <Box sx={{ paddingTop: { xs: 4, sm: 6, md: 7 } }}>
      <Button 
        sx={{ 
          width: { xs: '100%', sm: '316px' },
          height: '56px',
          backgroundColor: '#FD286E',
          fontSize: { xs: '14px', sm: '16px' }
        }} 
        variant="contained"
        onClick={() => navigate('/pricing')}
      >
        Get started
      </Button>
    </Box>
  </Box>
  )
}

export default Startpage