import { Avatar, Box, Button, Card, CardActionArea, CardMedia, Container, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { LuLoader } from 'react-icons/lu'
import { useNavigate, useParams } from 'react-router-dom'
import { FadeLoader, MoonLoader } from 'react-spinners'
import { url } from '../config/config'
import { Favorite, PlayArrow, Public, Replay, ReplayCircleFilledOutlined, Share } from '@mui/icons-material'
import ModuleComment from '../pages/recordings/components/moduleComment'
import ModalComponent from '../pages/songs/componenets/module'
import { jwtDecode } from 'jwt-decode'
import image2 from "../assest/images/cover music.png"
const userTokenData = localStorage.getItem('token') !== null ? jwtDecode(localStorage.getItem('token')) : ''
const LoGinUserID = userTokenData.userId
// console.log(userTokenData?.name,'hsgdyfs');

const Album = () => {
    const navigate = useNavigate()
    
    const [isModal,setIsModal] = useState(false)
    const params = useParams()
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleNavigation = (path) => {
    setOpen(false); // Close modal
  };
   
    const [playlistSongs,setPlaylistSongs] = useState([])
    
    useEffect(() => {
        const GetPlaylist = async () => {
            try{
                const config={
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                  }
                const res = await axios.get(`${url}/api/SongCollaborationAlbum/getSongCollaborationAlbumById/${params.id}`,config)
                console.log(res.data.data[0]?.songcollaborations[0]?.users[0]?. Name,'playlistOfUserrrrr')
                setPlaylistSongs(res.data.data)

            }catch (error){
              console.log(error)
            }
        }   
        GetPlaylist()
    },[])

    
    const modalClose = () => {
        setIsModal(false)
    }

    console.log(playlistSongs[0]?.songcollaborationplaylists,'playlistSongDetsailaaa')
  return (
    <Box sx={{ minHeight: "100vh" }}>
      <Box sx={{ background: "#fff", marginTop: 0 }}>
        <Container maxWidth="lg">
          <Box sx={{ display: "flex", paddingTop: "30px", justifyContent: "space-between", paddingBottom: "30px" }}>
            <Box>
              <Typography sx={{ fontSize: "32px", fontWeight: 700, color: "#2D2867" }}>
                {playlistSongs?.[0]?.Name || "Untitled Albumlist"}
              </Typography>
              <Box sx={{ display: "flex", gap: 0.5, marginBottom: "10px" }}>
                <Public sx={{ fontSize: "14px", color: "#728CA6" }} />
                <Typography sx={{ fontSize: "14px", fontWeight: 700, color: "#728CA6" }}>Public</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  src={playlistSongs?.[0]?.songcollaborations?.[0]?.users?.[0]?.ProfilePhoto || userTokenData?.profilePhoto}
                  sx={{ width: 40, height: 40, marginRight: 1 }}
                />
                <Typography sx={{ fontSize: "14px", fontWeight: 700, color: "#2D2867" }}>
                  {playlistSongs?.[0]?.songcollaborations?.[0]?.users?.[0]?.Name || userTokenData?.name}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 1.5, marginTop: "20px" }}>
  <Button
    sx={{
      background: "#6C2EFF",
      color: "white",
      padding: "10px 15px",
      fontSize: "17px",
      fontWeight: 700,
      "&:hover": {
        background: "#5A23CC", // Darker shade on hover
      },
    }}
    onClick={() => navigate(`/record/${playlistSongs?.[0]?.songcollaborations?.[0]?._id}`)}
  >
    <PlayArrow /> Play
  </Button>
  <Button
    sx={{
      background: "#2D2867",
      color: "white",
      padding: "10px 15px",
      fontSize: "17px",
      fontWeight: 700,
      "&:hover": {
        background: "#252259", // Darker shade on hover
      },
    }}
    onClick={() => setIsModal(true)}
  >
    <Share /> Share
  </Button>
</Box>
            </Box>
            <Box sx={{ width: 200, height: 200, borderRadius: 2 }}>
              <img src={playlistSongs?.[0]?.CoverImageURL || image2} alt="Playlist Cover" style={{ width: "100%", height: "100%" }} />
            </Box>
          </Box>
        </Container>
      </Box>
      <Container maxWidth="lg">
        <Typography sx={{ color: "#2D2867", fontSize: "14px", fontWeight: 700 }}>
          {playlistSongs?.[0]?.songcollaborations?.length || 0} recordings
        </Typography>
        {playlistSongs?.[0]?.songcollaborations?.length > 0 ? (
          <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "left", marginTop: "10px" }}>
            {playlistSongs[0].songcollaborations.map((data, index) => (
              <Button key={index} style={{ textDecoration: "none" }} onClick={() => navigate(`/record/${data._id}`)}>
                <Box sx={{ margin: 2 }}>
                  <Card sx={{ width: 178, borderRadius: "10px", mb: 1, position: "relative" }}>
                    <CardActionArea sx={{ position: "relative", overflow: "hidden" }}>
                      <CardMedia sx={{ height: 178 }} image={data.CoverImageURL} />
                      <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", display: "none" }} className="play-icon">
                        <PlayArrow sx={{ fontSize: 50, color: "white" }} />
                      </Box>
                    </CardActionArea>
                  </Card>
                  <Typography sx={{ fontSize: "13px", color: "#2D2867", textAlign: "left", width: 178, overflow: "hidden", textOverflow: "ellipsis", textTransform: "capitalize" }}>
                    {data.karaokes?.map((val) => val.Name).join(", ")}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <PlayArrow sx={{ color: "#C8CACF", fontSize: 14 }} />
                      <Typography sx={{ color: "#AAAEB4", fontSize: 14, fontWeight: 700 }}>{data.NoOfPlay}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Favorite sx={{ color: "#C8CACF", fontSize: 14 }} />
                      <Typography sx={{ color: "#AAAEB4", fontSize: 14, fontWeight: 700 }}>{data.NoOfLikes}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Button>
            ))}
          </Box>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", textAlign: "center", color: "gray", height: "50vh", padding: 2, width: "100%" }}>
          <Typography variant="h4" sx={{ color: "black", mb: 2 }}>
            Let’s find some songs for your playlist.
          </Typography>
          <Button sx={{ color: 'white', background: '#6C2EFF', padding: '10px', textTransform: 'none', marginTop: '10px', fontSize: '15px', fontWeight: 700 ,'&:hover': {backgroundColor: '#5A22CC' } }} onClick={() => navigate("/explore")}>Explore</Button>
        </Box>
        )}
      </Container>
      <ModalComponent isOpen={isModal} modalLink={window.location.href} onClose={modalClose} content="Spread the word" type="share" />
    </Box>

  )
}

export default Album