import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function FollowTab({tab1,tab2,count,countFollower,values}) {
  const [value, setValue] = React.useState(values);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' ,display:'flex',textAlign:'center',alignItems:'center',justifyContent:'center',borderTop:'3px solid #EAE9F0',background:'rgb(240, 242, 244)'}}>
        <Tabs centered value={value} onChange={handleChange} >
          <Tab sx={{textTransform:"capitalize",fontSize:14,backgroundColor:"white",color:"black","&:hover":{backgroundColor:"#3d5b8f",color:"white",gap:1}}} label={`${count} Following`} {...a11yProps(0)} />
          <Tab  sx={{textTransform:"capitalize",fontSize:14,backgroundColor:"white",color:"black","&:hover":{backgroundColor:"#3d5b8f",color:"white"}}}  label={`${countFollower} Followers`} {...a11yProps(1)} />
         
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
       {tab1}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      {tab2}
      </CustomTabPanel>
      
    </Box>
  );
}