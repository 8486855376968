import React from 'react'
import { Box, Typography, Button, Card,Avatar, Divider, Container } from '@mui/material'
import image from'../assest/images/ICON png version-01.png'
import{TfiAndroid}from "react-icons/tfi";
import { FaApple, FaMusic } from 'react-icons/fa6';
import { FaUnlockAlt } from 'react-icons/fa';
import {SiTicktick} from 'react-icons/si'
import image1 from "../assest/images/save.png";
import image2 from "../assest/images/music (2).png"
import image3 from '../assest/images/more.png'
import image4 from '../assest/images/fun.png'
import image5 from '../assest/images/premiumIcon.png'
import { Bolt } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Spark from './spark';
import AnimatedCouponModal from '../components/couponModal';

const Blog = () => {

  const navigate = useNavigate()

  return (

    <Box sx={{textAlign: "center"}}>
    <Box sx={{
      display: "flex", 
      flexDirection: "row",
      textAlign: "center",
      gap: 0, 
      padding: 5, 
      background: "linear-gradient(to right, #F76720, #1B1BDB)",
      flexDirection:{lg:"row",md:'row',xs:"column"}
    }}>
      <Box  sx={{flex: 1, display: "flex", flexDirection: "column", justifyContent: "center",}}>
        {/* <Box sx={{width: "316px", height: "56px", backgroundColor: "#FD286E"}}>ONLINE EXCLUSIVE</Box> */}
        <Typography sx={{fontSize: "50px", color: "white", fontFamily: "Arial, sans-serif", fontWeight: "bold", fontStyle: "italic"}}>
          Save up <br /> to 90%<br /> on PRE*
        </Typography>
        <Typography sx={{fontSize: "19px", color: "white"}}>
          Unlimited solos, access on both iOS <br /> and Android, no ads—PRE gives you <br /> the best of Smule. Click below to get <br /> our online-only deal.
        </Typography>
        <Typography sx={{paddingTop: 7}}>
          <Button sx={{width: "316px", height: "56px", backgroundColor: "#FD286E"}} variant="contained" onClick={() => {navigate('/payment')}}>Unlock Deal</Button>
        </Typography>
        <Typography sx={{fontSize: "12px", color: "white",mt:2}}>
        *Compared to in-app subscription via App Store <br /> or Google Play</Typography>
      </Box>
  
      <Box sx={{flex: 1, display: "flex", justifyContent: "center"}}>
        <img src={image} alt="Image" style={{width: "400px", height: "500px"}} />
        <Avatar
      sx={{ 
        marginTop: 60, 
        border: "5px solid white", 
        backgroundColor: "white", 
        color: "#000",
        textShadow: "1px 1px 2px #fff",
        width:90,
        height:90,
        fontWeight: "bold",
        fontSize: "40px"
         
      }}
    >
      <img src={image5}/>
    </Avatar>
      </Box>
    </Box>
    <Box><Card sx={{backgroundColor:"white",}}>
    <Box sx={{ padding: 5, borderRadius: 2 }}>
      <Typography sx={{ fontSize: "50px", color: "black", fontFamily: "Arial, sans-serif", fontWeight: "bold", fontStyle: "italic", textAlign: "center" }}>
        The best of SparkSinger with PRE.
      </Typography></Box>
     
     {/* Optional: Add size prop for better icon sizing */}
<Box sx={{display:'flex',textAlign:"center",justifyContent:'center',gap:5,flexDirection:{lg:"row",md:'row',xs:"column"}}}>
<Box><Card sx={{padding:'15px'}}>
<Typography >
  <FaMusic size={50} fill='linear-gradient(45deg, rgb(227, 34, 222), rgb(136, 38, 251), rgb(54, 147, 230))'/>
 <h3>Unlimited Solos </h3>
 <br/>
  Sing how you want, <br/>what you want, whenever you want.
</Typography></Card></Box>

<Box>
  <Card sx={{padding:'15px'}}>
  <TfiAndroid size={50} />
  <FaApple size={50} />
  
<Typography sx={{ marginTop: 2, textAlign: "center"}}>

 <h3>Shared Access</h3> <br/>
  Subscribe online and enjoy PRE on both<br/> iOS and Android.
</Typography> </Card></Box>
<Card sx={{padding:'15px'}}>
<FaUnlockAlt size={50} />

<Typography sx={{ marginTop: 2, textAlign: "center" }}>
 <h3>No Ads</h3> <br/>
  Nothing will get between you and your <br/>music.
</Typography></Card></Box>
<Box sx={{display:'flex',justifyContent:'center',mt:5}}>

</Box>
<Container maxWidth='lg'>
<Box sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center', mt:5,flexDirection:{lg:"row",md:'row',xs:"column"}}}>
  {/* <Card sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', boxShadow: 3 ,background: "linear-gradient(to right, #7FFFD4, #1E90FF)"}}>
   
    <Typography sx={{ flex: 1, paddingRight: 2 }}>
      More Music
      <br />
      Sing and solo our entire songbook of 10M+ tracks. Plus, you can create performances and invite 1 to join you for a duet.
    </Typography> */}

    <Box sx={{ flex:1, display: 'flex', justifyContent: 'center' }}>
      <img src={image2} alt="Image" style={{width: '90%',height:"80%"}} />
    </Box>

</Box>
</Container>
<Container maxWidth='lg'>
<Box sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center',mt:-10}}>
  {/* <Card sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', boxShadow: 3,background: "linear-gradient(to right, #7FFFD4, #1E90FF)" }}> */}
    {/* Text content on the left side */}
    {/* <Typography sx={{ flex: 1, paddingRight: 2 }}>
      
More Personalized<br />
Customize your profile to make it awesome, tailor your sound with vocal filters, and easily switch between iOS and Android devices.
    </Typography> */}

    {/* Image on the right side */}
    <Box sx={{ flex:1, display: 'flex', justifyContent: 'center'}}>
      <img src={image3} alt="Image" style={{ width: '90%',height:"80%" }} />
    </Box>
  {/* </Card> */}
</Box>
</Container>
<Container maxWidth='lg'>
<Box sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center',mt:-10}}>
  {/* <Card sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', boxShadow: 3,background: "linear-gradient(to right, #7FFFD4, #1E90FF)" }}> */}
    {/* Text content on the left side */}
    {/* <Typography sx={{ flex: 1, paddingRight: 2 }}>
      
More Personalized<br />
Customize your profile to make it awesome, tailor your sound with vocal filters, and easily switch between iOS and Android devices.
    </Typography> */}

    {/* Image on the right side */}
    <Box sx={{ flex:1, display: 'flex', justifyContent: 'center'}}>
      <img src={image4} alt="Image" style={{ width: '90%',height:"80%" }} />
    </Box>
  {/* </Card> */}
</Box>
</Container>
<Box
  sx={{
    display: 'flex',
    justifyContent: 'center', // Align cards with space in between
    gap: 12, // Space between the cards
    flexWrap: 'wrap', // Allow cards to wrap to the next line if necessary
    padding: 0,
  }}
>
  {/* First Card */}
  <Card
    sx={{
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'justify',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '1px 1px 10px black',
      padding: 2,
      width: 300,
      height: 400,
      gap: 1,
      mt:6,
      
    }}
  >
    <Avatar
      sx={{
        marginTop: 5,
        border: '2px solid white', // VIP styling
        backgroundColor: 'white',
        color: '#000',
        textShadow: '1px 1px 2px #fff',
        width: 50,
        height: 50,
        fontWeight: 'bold',
        fontSize: '20px',
        textAlign: 'center',
        
      }}
    >
      <img src={image5}/>
    </Avatar>
    <Typography variant="body2" component="p" mt={6}>
      <Box sx={{ backgroundColor: 'black', gap: 3, marginBottom: 5 }}>
        <Divider />
      </Box>

      <SiTicktick /> Unlimited Songs and Solos
      <br />
      <SiTicktick /> Full Songbook Access
      <br />
      <SiTicktick /> Use on Both iOS + Android
      <br />
      <SiTicktick /> No Ads
      <br />
      <SiTicktick /> Premium Audio Filters
      <br />
      <SiTicktick /> Customize Your Profile
    </Typography>
    <Box sx={{ backgroundColor: 'red' }}>
      <Divider />
    </Box>
    <Button
      sx={{
        width: '100%', // Adjust width to fit card
        height: '56px',
        backgroundColor: '#FD286E',
      }}
      variant="contained"
      onClick={() => {navigate('/payment')}}
    >
      Unlock Deal
    </Button>
  </Card>

  
  <Box
    sx={{
      display: 'flex',
      textAlign:'start',
      justifyContent: 'center',
      alignItems: 'center',
      width: 300,
      height: 400,
    
    }}
  >
    <Box sx={{ marginTop: 1}}>
      <Typography fontWeight="bold" fontFamily="Arial, sans-serif" fontSize="50px">
        Subscribe online and save.
      </Typography>
      Don’t miss this chance to save up to 90% on PRE today.
      
    </Box>
  </Box>
  
</Box>
    <Box sx={{display:'flex',justifyContent:'flex-end',margin:'10px'}}>
     
    </Box>
      </Card></Box>
     
      
  </Box>
  )
}

export default Blog