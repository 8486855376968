import { Box, Container } from '@mui/material';
import React, { createContext,useState } from 'react'
import Header from './componenets/header';


export const ModelStateHanlder = createContext()
const UserProfile = () => {
  const [modalState,setModalState] = useState(false)
  
  return (
    <ModelStateHanlder.Provider value={{modalState,setModalState}}> 
     <Box sx={{minHeight:"100vh"}}>
        <Header/>
    </Box>
    </ModelStateHanlder.Provider>
  )
}

export default UserProfile;