import { Box, Button, Typography, Container, TextField, Modal, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import React, { useState } from 'react';
import Card from '@mui/material/Card';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import giftImage from '../../assest/images/giftImage.gif';
import { url } from '../../config/config';
import '../upload-gift/styles/uploadGift.css'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height:400,
  bgcolor: 'background.paper',
  border: '2px',
  boxShadow: 24,
  p: 4,
};

const Uploadgift = () => {
  const navigate = useNavigate();
  const [coverImg, setCoverImg] = useState('');
  const [tittle, setTittle] = useState('');
  const coverImage = coverImg ? URL.createObjectURL(coverImg) : '';
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [plan, setPlan] = useState('');
    const [value, setValue] = useState('');
  
    const handleChanged = (event) => {
      const { value } = event.target;
  
      // Use regex to ensure only numbers are accepted
      if (/^\d*$/.test(value)) {
        setValue(value);
      }
    };
  const handleChange = (event) => {
    setTittle(event.target.value);
  };
  const handlePlanChange = (e) => 
    setPlan(e.target.value);

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setCoverImg(e.target.files[0]);
    }
  };
  const handleemty=()=>{
    setCoverImg("")
    setTittle("")
    setPlan("")
  }

  const Handlesubmit = async () => {
    setOpen(true)
    try {
      if (!tittle || !coverImg) {
        console.error('Title and Cover Image are required.');
        return;
      }
  
      const config = {
        headers: { 
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data' 
        }
      };
      const formData = new FormData();
      formData.append('GiftImage', coverImg); 
      formData.append('Name', tittle);
      formData.append('Gems',value)
      console.log(formData)
      setTittle("")
      setCoverImg("")
      setPlan("")
      const res = await axios.post(`${url}/api/Gift/createGift`, formData, config);
      console.log(res);
    } catch (error) {
      console.error('Network Error:', error.response ? error.response.data : error.message);
    }
  };
  
  

  return (
    <Box sx={{ textAlign: "center", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
    <Container>
      <Card sx={{ padding: 2, marginTop: '60px' }}>
        <Box sx={{ textAlign: "center", display: "flex", flexDirection: "column", gap: 2, padding: 10, justifyContent: 'space-around', paddingTop: '30px', paddingBottom: '40px' }}>
          <Box sx={{ marginBottom: '25px' }}>
            <Box sx={{ fontSize: 40, color: "#120B27" }}>Add Gift Details</Box>
         
          </Box>
        </Box>
        
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 1, justifyContent: "center", alignItems: "center" }}>
          <Box sx={{ padding: '50px', background: '#EDF5FF', textAlign: "center" ,ml:8}}>
            <Box onClick={Handlesubmit}>
              <img src={coverImg ? coverImage : giftImage} style={{ width: '200px', height: '240px',mt:10}} alt="Gift Preview" />
            </Box>
            <input
              onChange={handleFileChange}
              type="file"
              name="file"
              id="file"
              className="inputfile"
              style={{ display: 'none' }}
            />
            <label style={{ opacity: 1, background: '#564CF2', color: 'white', padding: '10px', borderRadius: '5px', marginBottom: '10px' }} htmlFor="file">Change Cover Art</label>
          </Box>
          
          <Box>
            <TextField
              onChange={handleChange}
              value={tittle}
              sx={{ width: { xs: '100%', md: '500px' } }}
              placeholder='Title'
            />
            <TextField
              placeholder='price'
              variant="outlined"
              value={value}
              sx={{ width: { xs: '100%', md: '500px' }, mt: 4 }}
              onChange={handleChanged}
              inputProps={{
                type: 'text',
                pattern: '[0-9]*',
              }}
              fullWidth
            />
            
            <FormControl sx={{ width: { xs: '100%', md: '500px' }, mt: 4 }}>
              <InputLabel>Plan</InputLabel>
              <Select
                value={plan}
                onChange={handlePlanChange}
                label="Plan"
              >
                <MenuItem value="premium">Premium</MenuItem>
                <MenuItem value="free">Free</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        
        <Box sx={{ display: 'flex', justifyContent: "center", gap: 1, marginTop: 2 }}>
          <Button
            variant='contained' sx={{ height: 40, fontSize: 16 }}
            onClick={handleemty}
          >
            Delete
          </Button>
          <Button
            variant='contained' sx={{ height: 40, fontSize: 16 }}
            onClick={Handlesubmit}
          >
            Continue
          </Button>
        </Box>
        
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box sx={{ textAlign: "center" }}>
              <img src={coverImg ? coverImage : giftImage} style={{ width: '350px', height: '350px' }} alt="Gift Preview" />
            </Box>
            <Box sx={{ fontSize: '40px', fontWeight: 700, textAlign: "center" }}>Successfully Gifted!!!</Box>
          </Box>
        </Modal>
      </Card>
    </Container>
  </Box>
  
  );
};

export default Uploadgift;
