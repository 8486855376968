import { Box, Card, Divider, Typography , Tooltip, Skeleton, CircularProgress} from '@mui/material'
import React, { useState , useEffect, useRef} from 'react'
import { useContext } from 'react';
import Switch from '@mui/material/Switch';
import upnext from '../../../assest/images/upnext.jpg'
import NextSong from '../../../data/next-song-data';
import { useLocation , useParams , useNavigate} from 'react-router-dom';
import { url } from '../../../config/config';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { AutoPlayStatus, FullScreenSize, MediaPlay } from '../../../features/addlyric/addLyric';
import img from './guna.jpg';
import { IoIosShuffle } from "react-icons/io";
import { MdSkipPrevious } from "react-icons/md";
import { MdSkipNext } from "react-icons/md";
import { FaArrowRotateLeft } from "react-icons/fa6";
import { FaArrowRotateRight } from "react-icons/fa6";
import { IoRepeat } from "react-icons/io5";
import { FaRegCirclePlay } from "react-icons/fa6";
import { MdOpenInFull } from "react-icons/md";
import { IoVolumeHigh } from "react-icons/io5";
import { BsThreeDots } from "react-icons/bs";
import { useSelector } from 'react-redux';
import { AudioContext } from '..';
import { FaRegPauseCircle } from "react-icons/fa";
import { LuRepeat } from "react-icons/lu";
import { LuRepeat1 } from "react-icons/lu";
import { TbRepeatOff } from "react-icons/tb";
import { PiSpeakerSimpleSlash } from "react-icons/pi";
import { PlayArrow } from '@mui/icons-material';
import VolumeSlider from './volume';
import musice from "../../../assest/images/cover music.png"

const label = { inputProps: { 'aria-label': 'Switch demo' } };
const Footersongs = ({autoPlay}) => {
    // const location = useLocation();
    // const values = location.state.param1;
    // const VideoIndex = location.state.param2;
    const [checked,setChecked] = useState(true)
    const location = useLocation();
    const navigate = useNavigate()
    const [values,setValues] = useState([])
    const params = useParams();
    const dispatch = useDispatch()
    const data = useSelector((data) => data)
    const {audioRef} = useContext(AudioContext);
    const [play,setPlay] = useState(false);
    const [repeat,setRepeat] = useState(false)
    const [loop,setLoop] = useState(true);
    const scrollContainerRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [volumeValue,setVolumeValue] = useState(60)
    const [loading, setLoading] = useState(true);
    const maxValueRange = 100;
     let paramID = params.id
     console.log(paramID,"jdsjjdjd")
    useEffect(()=>{

        const GetExplore=async()=>{
            setLoading(true);
     try {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          }
           const res = await axios.get(`${url}/api/SongCollaboration/getSongCollaborationForiegnkey`,config);
           setValues(res.data.data)
       } catch (error) {
         
       }
       setLoading(false); 
        }
        GetExplore()
       
      },[])
    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
        setScrollLeft(scrollContainerRef.current.scrollLeft);
    };

    // Mouse Move Event
    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - scrollContainerRef.current.offsetLeft;
        const walk = (x - startX) * 1.5; // Adjust scrolling speed
        scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    };

    // Mouse Up Event
    const handleMouseUp = () => {
        setIsDragging(false);
    };

    // Mouse Leave Event
    const handleMouseLeave = () => {
        setIsDragging(false);
    };

      useEffect(() => {
        const scrollToCenter = (id) => {
    const currentSongIndex = values.findIndex((song) => song._id === id);
    if (scrollContainerRef.current && currentSongIndex !== -1) {
        const scrollContainer = scrollContainerRef.current;
        const currentSong = scrollContainer.children[currentSongIndex];

        if (currentSong) {
            const containerWidth = scrollContainer.offsetWidth;
            const songWidth = currentSong.offsetWidth;
            const scrollLeft = currentSong.offsetLeft - (containerWidth / 2 - songWidth / 2);

            // Smooth scroll to center the current song
            scrollContainer.scrollTo({ left: scrollLeft, behavior: 'smooth' });
        }
    }
};
    
        scrollToCenter();
    }, [paramID, values]);
      console.log(values)
      function findNextObjectById(array, id) {
        let index = array.findIndex(obj => obj._id === id);
        if (index !== -1 && index < array.length - 1) {
            return array[index + 1];
        } else {
            return null; // Next object not found
        }
    }

    function findPreviousObjectById(array, id) {
        console.log(array,'arrayOfValuesss')
      let index = array.findIndex(obj => obj._id === id);
      console.log(array[index - 1],'IndexValuesssss')
      if (index) {
          return array[index - 1];
      } else {
          return null; // Next object not found
      }
  }
   

      let nextObject = findNextObjectById(values,paramID)
      let prevoiusObject = findPreviousObjectById(values,paramID)
    //   console.log(prevoiusObject)
      const handleClcik =(id) => {
        navigate(`/record/${id}`);
      }
      console.log(nextObject,prevoiusObject,'DataValuessss For next and previous')
      const handleChange = async (e) => {
         setChecked(e.target.checked)
         const data = await {
            autoplayOn : e.target.checked
         }
         dispatch(AutoPlayStatus(data))
      }
      console.log(checked)
      const handleFullScreen = () => {
        const data = {
            fullscreen : true
        }
        dispatch(FullScreenSize(data))
      }
      const handlePlay = () => {
        audioRef.current.play()
        setPlay(true)
        const data = {
            mediaPlay : false
        }
        dispatch(MediaPlay(data))
      }
      const handlePause = () => {
        audioRef.current.pause()
        setPlay(false)
        const data = {
            mediaPlay : true
        }
        dispatch(MediaPlay(data))
      }

      const handelVolumeController = (e) => {
        console.log(e.target.value,'VoulumeContollerssss')
        setVolumeValue(Number(e.target.value))
         const volume = Number(e.target.value) / maxValueRange
         audioRef.current.volume = volume
      }
    
  return (
    
    <Box>
        <Card sx={{background: "rgba( 255, 255, 255, 0.25 )"}}>
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",color:"white",backgroundColor:"#110E39",borderRadius:11,padding:0.5,overflowX: 'auto'}}>
                <Box sx={{display:'flex',gap:12}}>
                    {
                        values && values.length > 0 ? values.map((val, ind) => (
                     console.log(val._id  ,"karokiesss"),
                        
                            <>
                           {paramID === val._id ? (
    <Box sx={{ display: 'flex', gap: 1, padding: '10px' }}>
        <Box>
            <img style={{ width: '70px', height: '70px' }} src={val.CoverImageURL || musice} />
        </Box>
        <Box sx={{ marginTop: '15px' }}>
            <Box sx={{ 
                color: 'white', 
                width: '200px', 
                overflow: 'hidden', 
                textOverflow: 'ellipsis', 
                whiteSpace: 'nowrap', 
                fontWeight: 'bold'
            }}>
                {val.karaokes?.length > 0 ? val.karaokes[0]?.Artist : "Unknown Artist"}
            </Box>
            <Box sx={{ 
                color: 'white', 
                width: '200px', 
                overflow: 'hidden', 
                textOverflow: 'ellipsis', 
                whiteSpace: 'nowrap', 
                fontWeight: 'bold'
            }}>
                {val.karaokes?.length > 0 ? val.karaokes[0]?.Name : "Unknown Song"}
            </Box>
        </Box>
    </Box>
) : null}
                            </>
                        )):(
                            (
                                <Box sx={{ display: 'flex', gap: 1, padding: '10px' }}>
                                    <Skeleton variant="rectangular" width={70} height={70} />
                                    <Box sx={{ marginTop: '15px' }}>
                                        <Skeleton variant="text" width={200}  />
                                        <Skeleton variant="text" width={200} />
                                    </Box>
                                </Box>
                            )
                        )
                    }
                <Box sx={{display:'flex',gap:2,marginTop:'25px'}}>
                    <Tooltip title="Shuffle" placement="top">
                    <Box><IoIosShuffle style={{marginTop:'10px',cursor:'pointer'}} size={30} color='white'/></Box>
                    </Tooltip>
                    <Tooltip title="Previous" placement="top">
                    <Box onClick={() => {
                        if(prevoiusObject !== null && prevoiusObject !== undefined){
                            navigate(`/record/${prevoiusObject._id}`)
                        }                 
                    }}><MdSkipPrevious style={{marginTop:'10px',opacity : prevoiusObject !== null && prevoiusObject !== undefined ? 1 : 0.3,cursor:'pointer'}} color='white' size={30}/></Box>
                    </Tooltip>
                    <Tooltip title="Skip back 10 seconds" placement='top'>
                    <Box onClick={() => {
                        audioRef.current.currentTime = audioRef.current.currentTime - 10
                    }}><FaArrowRotateLeft style={{marginTop:'15px',cursor:'pointer'}} color='white' size={20}/></Box>
                    </Tooltip>
                    {
                        data?.Upload.MediaPlayerPlay[0].isTrue ?     <Tooltip title="Play" placement='top'>
                    <Box onClick={handlePlay}><FaRegCirclePlay color='white' size={45} style={{cursor:'pointer'}}/></Box>
                    </Tooltip> : <Tooltip title="Play" placement='top'>
                        <Box onClick={handlePause}><FaRegPauseCircle style={{cursor:'pointer'}} color='white' size={45}/></Box>
                        </Tooltip>  
                    }
                    <Tooltip title='Skip forward 30 seconds' placement='top'>
                    <Box onClick={() => {
                        audioRef.current.currentTime = audioRef.current.currentTime + 30
                    }}><FaArrowRotateRight color='white' style={{marginTop:'15px',cursor:'pointer'}} size={20}/></Box>
                    </Tooltip>
                    <Tooltip title='Next' placement='top'>
                    <Box onClick={() => {
                        if(nextObject !== null && nextObject !== undefined){
                            navigate(`/record/${nextObject._id}`)
                        }
                    }}><MdSkipNext color='white' style={{marginTop:'10px',opacity : nextObject !== null && nextObject !== undefined ? 1 : 0.3,cursor:'pointer'}} size={30}/></Box>
                    </Tooltip>
                    {
                        repeat ?  <Tooltip title='Repeat' placement='top'>
                        <Box onClick={() => {
                            setRepeat(false)
                            setLoop(true)
                        }}><LuRepeat1 color='white' style={{marginTop:'10px',cursor:'pointer'}} size={30}/></Box>
                        </Tooltip> : loop ?  <Tooltip title='Repeat' placement='top'>
                    <Box onClick={() => {setRepeat(true)}}><LuRepeat color='white' style={{marginTop:'10px',cursor:'pointer'}} size={30}/></Box>
                    </Tooltip> :  <Tooltip title='Repeat' placement='top'>
                    <Box onClick={() => {
                        setRepeat(true)
                    }}><TbRepeatOff color='white' style={{marginTop:'10px'}} size={30}/></Box>
                    </Tooltip>
                    }
                </Box>
                </Box>
                <Box sx={{display:'flex',gap:2,alignItems: 'center',justifyContent:"space-around"}}>
                    <Box sx={{ display: 'flex', alignItems: 'center',minHeight: '40px' }}>
                        {/* {
                            volumeValue === 0 ? <Tooltip title="Volume" placement='top'>
                            <Box sx={{cursor:'pointer'}} className='mediaVolume' onClick={() => {
                                setVolumeValue(60)
                                audioRef.current.volume = 0.6
                            }}><PiSpeakerSimpleSlash color='white' size={25} style={{marginTop:'5px'}}/></Box>
                            </Tooltip> : <Tooltip title="Volume" placement='top'>
                    <Box sx={{cursor:'pointer'}} className='mediaVolume' onClick={() => {
                                setVolumeValue(0)
                                audioRef.current.volume = 0
                            }}><IoVolumeHigh color='white' size={25} style={{marginTop:'5px'}}/></Box>
                    </Tooltip>
                        }
                    <Box  className='soundTab' sx={{marginTop:'10px',cursor:'pointer'}}>
                        <input style={{width:'70px'}} type='range' min={0} max={maxValueRange} value={volumeValue} onChange={handelVolumeController}/>
                    </Box> */}
                    <VolumeSlider audioRef={audioRef} autoPlay={autoPlay}/>
                    </Box>
                    
                    <Tooltip  title="Full screen" placement='top'>
                    <Box sx={{cursor:'pointer'}} onClick={handleFullScreen}><MdOpenInFull color='white' size={25} style={{marginTop:'5px'}}/></Box>
                    </Tooltip>
                    <Box  sx={{display: 'flex', alignItems: 'center', color: 'white', gap: 1 }}>Autoplay  <Switch checked={checked} onChange={handleChange} {...label} defaultChecked={true}/></Box>
                    <Tooltip title="More" placement='top'>
                    {/* <Box sx={{cursor:'pointer'}}><BsThreeDots color='white' size={25} style={{padding:'5px'}}/></Box> */}
                    </Tooltip>
                </Box>
                
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', color: 'white' ,mt:3}}>
            {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', padding: 2 }}>
            <CircularProgress color="secondary" />
        </Box>
    ) : (
            <Box
                sx={{ display: 'flex', gap: 1 }}
                ref={scrollContainerRef}
                style={{
                    overflowX: 'scroll',
                    whiteSpace: 'nowrap',
                    cursor: isDragging ? 'grabbing' : 'grab', // Change cursor based on drag state
                }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave}
            >
                {values.map((val) => (
                    <Box
                        key={val._id}
                        sx={{
                            display: 'inline-block',
                            textAlign: 'center',
                            backgroundColor: paramID === val._id ? 'darkblue' : 'transparent',
                            color: paramID === val._id ? 'white' : 'black',
                            padding: '2px',
                            borderRadius: '8px',
                            cursor: 'pointer',
                            // "&:hover":{backgroundColor:"white" }
                          
                        }}
                        onClick={() => handleClcik(val._id)}
                    >
                        <Box sx={{ position: 'relative', paddingBottom: '2px'}}>
                            <img
                                style={{ width: '216px', height: '216px', transition: 'opacity 0.3s ease',"&:hover":{ background: "rgba(0, 0, 0, 0.6)",backgroundBlendMode: "overlay"} }}
                                src={val.CoverImageURL || musice }
                                alt={'loading'}
                            />
                         <Box
  sx={{
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '4px',
    opacity: 0,
    transition: 'opacity 0.3s ease',
    '&:hover': {
      opacity: 1,
    },
  }}
>
  <Box
    sx={{
      backgroundColor: "#663FF3",
      width: "60px",
      height: "60px",
      borderRadius: "50%",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft:18,
      marginTop:18
    }}
  >
    <PlayArrow sx={{ width: "50px", height: "50px", color: "white" }} />
    
  </Box>
</Box>   
                            
                        </Box>
                        <Box></Box>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textAlign: 'center',
                                width: '180px',
                            }}
                        >
                            {val.karaokes[0]?.Name}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '12px',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textAlign: 'center',
                                width: '180px',
                            }}
                        >
                            {val.karaokes[0]?.Artist}
                        </Typography>
                    </Box>
                ))}
            </Box>
             )}
        </Box>
        </Card>
    </Box>
  )
}

export default Footersongs