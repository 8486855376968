import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';

export default function SongSelect1({ tittle, onchang }) {
  const countries = ['rock', 'melodies', 'classic', 'mass']; // The available options
  const data = useSelector((state) => state);

  const [defaultGenres, setDefaultGenres] = React.useState([]);

  React.useEffect(() => {
    const value = data.Upload?.SongMeta || {};
    // Check if tags exist and filter accordingly
    if (value.tags && Array.isArray(value.tags) && value.tags.length > 0) {
      const defaultValue = countries.filter((country) => 
        value.tags.includes(country)
      );
      setDefaultGenres(defaultValue);
    }
  }, [data]);

  console.log(defaultGenres, 'defaultGenres');

  return (
    <Autocomplete
      multiple={true} // Allow multiple selections
      id="country-select-demo"
      sx={{ width: 500 }}
      options={countries} // Set the options
      value={defaultGenres} // Controlled component with default genres
      onChange={(event, newValue) => {
        setDefaultGenres(newValue); // Update the selected genres
        onchang(event, newValue); // Call the parent's onChange function
      }}
      autoHighlight
      getOptionLabel={(option) => option} // Display the string as the label
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          {option}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={tittle} // Display the title as a label
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // Disable autocomplete
          }}
        />
      )}
    />
  );
}
