

const uploadSong=[
    {
        id:1,
        tittle:"Upload",
        path:"/uploads/songupload"
    },
    {
        id:2,
        tittle:"Details",
        path:"/uploads/songdetails"
    },
    {
        id:3,
        tittle:"Lyrics",
        path:"/uploads/songlyrics"
    },
    {
        id:4,
        tittle:"Timing",
        path:"/uploads/songtiming"
    },
    {
        id:5,
        tittle:"Structure",
        path:"/uploads/songstructure"
    },
]

export default uploadSong;