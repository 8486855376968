import { Box, Card,Button, Typography, Avatar, Modal, Drawer, Menu, MenuItem, CircularProgress, Divider, Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import giftImage from './gift-removebg-preview.png'
import CancelIcon from '@mui/icons-material/Cancel';
import { BsCoin } from "react-icons/bs";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { url } from "../../../config/config";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useParams } from "react-router-dom";
import FavoriteIcon from '@mui/icons-material/Favorite';
import { jwtDecode } from "jwt-decode";
// import CancelIcon from '@mui/icons-material/Cancel';
import axios from "axios";
import TimeAgo from "../timeStmaps";
import Authmodel from "../../../components/auth_model";
import { IoIosArrowBack } from "react-icons/io";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from "react-router-dom";
import { FcOk } from "react-icons/fc";
import { RiDeleteBin6Line } from "react-icons/ri";
import FlagIcon from '@mui/icons-material/Flag';
import { FaCheckCircle } from "react-icons/fa";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    boxShadow: 24,
    borderRadius: '5px'
  };

const ModuleComment = ({funcComment,user,count}) => {
    const [choosed,setChoosed] = useState(true)
    const [commentLike,setCommentLike] = useState(false)
    const [deleteModal,setDeleteModal] = useState(false)
    const [restrict,setRestrict] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteLoader,setDeleteLoader] = useState(false)
  const [menuInd, setMenuInd] = useState(null);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [modalContent,setModalContant] = useState(false)
    const [reportModal,setReportModal] = React.useState(false)
  const [selectedReportCategory,setSelectedReporCategory] = React.useState('');
  const [discription,setDiscription] = React.useState(false)
  const [discriptionText,setDiscriptionText] = React.useState('')
  const [reportSubmitted,setReportSubmitted] = React.useState(false);
  const [reportData,setReportData] = React.useState([])
  const [selectedComment,setSelectedComment] = useState('');
  const [reportSuccessfullyDetails,setReportSuccessfullyDetails] = useState([]);
  const tokenValue = localStorage.getItem('token')
    // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    // console.log(user,'userValuesssss')
    const navigate = useNavigate();
    const gifts = [
        {
           image : giftImage,
           comment : 'good song'
        },
        {
            image : giftImage,
            comment : 'greate voice'
         },
         {
            image : giftImage,
            comment : 'Fantasitic'
         },{
            image : giftImage,
            comment : 'Super Duper'
         },{
            image : giftImage,
            comment : 'Good'
         },{
            image : giftImage,
            comment : 'Loved it'
         },{
            image : giftImage,
            comment : 'Loved the voice'
         },
    ]
    const params = useParams();
    const [commentData,setCommentData] = useState([])
    useEffect(() => {
        const getComment = async () => {
            const songCollabId = await user[0]._id
            try {
                const config={
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}`}
                  }
                const res = tokenValue !== null ?  await axios.get(`${url}/api/SongCollaborationComment/getSongCollaborationCommentById/${songCollabId}`,config) : await axios.get(`${url}/api/SongCollaborationComment/getSongCollaborationOtherCommentById/${songCollabId}`);
                console.log(res.data.data,"commatend.....")
                setCommentData(res.data.data)
            } catch (error) {
              console.log(error)
            } 
        }
        getComment()
    },[])
    useEffect(() => {
      const getReportTypes =  async () => {
        try{
          const config={
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          }
          const response = await axios.get(`${url}/api/ReportType/getReportType`,config)
          console.log(response.data.data,'dataValuessFroReport')
          setReportData(response.data.data)
        }catch (error){
           console.log(error)
        }
      }
      getReportTypes()
    },[])

    // console.log(reportData,'reportsArrayaaaa')
    const [comment,setComment] = useState('')
    const handleClick = async () => {
        if(!localStorage.getItem('token')){
            setRestrict(true)
        }else{
            setComment('')
            setCommentLike(false)
            const songCollabId = await user[0]._id
            const data = {
                CommentText : comment,
                SongCollaborationID : songCollabId
            }
            try {
                const config={
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}`}
                  }
                const res = await axios.post(`${url}/api/SongCollaborationComment/createSongCollaborationComment`,data,config);
                console.log(res.data.data)
               
            } catch (error) {
              console.log(error)
            }
    
            try {
                const config={
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}`}
                  }
                const res = await axios.get(`${url}/api/SongCollaborationComment/getSongCollaborationCommentById/${songCollabId}`,config);
                console.log(res.data.data)
                setCommentData(res.data.data)
            } catch (error) {
              console.log(error)
            } 
            count(1)
        }
    }
    // console.log(commentData)
    const [commentId,setCommentId] = useState('');
    const handleCommentLike = async (val,ind) => {
        if(!localStorage.getItem('token')){
            setRestrict(true)
        }else{
            setCommentId(ind)
            const data ={
                SongCollaborationID : params.id,
                SongCollaborationCommentLikeID : val
            }
              try{
                const config={
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}`}
                  }
                const res = await axios.post(`${url}/api/SongCollaborationCommentLike/createSongCollaborationCommentLike`,data,config)
                console.log(res.data.data)
                
              }
              catch (error) {
                 console.log(error)
              }
              const songCollabId = await user[0]._id
              try {
                const config={
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}`}
                  }
                const res = await axios.get(`${url}/api/SongCollaborationComment/getSongCollaborationCommentById/${songCollabId}`,config);
                console.log(res.data.data)
                setCommentData(res.data.data)
            } catch (error) {
              console.log(error)
            }
            setCommentLike(true)
        }
    }
    // console.log(user)

    const userTokenData = localStorage.getItem('token') !== null ? jwtDecode(localStorage.getItem('token')) : ''

    // console.log(userTokenData)

    commentData&& commentData.map((val,ind) => (
       val?.SongCollaborationCommentLike?.some(e => e.UserID === userTokenData.userId) === true ? console.log('true') : console.log('not true')
    ))

    // const handleCommentDelete = (val) => {
    //     try{
    //         const config={
    //             headers: { Authorization: `Bearer ${localStorage.getItem('token')}`}
    //           }
    //         const res = await axios.post(`${url}/api/SongCollaborationCommentLike/createSongCollaborationCommentLike`,data,config)
    //         console.log(res.data.data)
    //       }
    //       catch (error) {
    //          console.log(error)
    //       }
    // }
    const HandleClose = () => {
        setRestrict(false)
    }
    const [likeScreen,setLikeScreen] = useState(true)
    const [list,setList] = useState([])
    const handleCommentLikeList = async (val) => {
        if(!localStorage.getItem('token')){
            setRestrict(true)
        }else{
            setLikeScreen(false)
            try{
                const config={
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                  }
                const res = await axios.get(`${url}/api/SongCollaborationCommentLike/getSongCollaborationCommentLikeByCommentId/${val}`,config)
                console.log(res.data.data)
                setList(res.data.data)
            }catch(error){
                 console.log(error)
            }
        }
    }
    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

      // console.log(screenWidth)

      const handleMenuOpener = (event, ind) => {
        setAnchorEl(event.currentTarget);
        setMenuInd(ind);
      };
    
      const handleClose = (val) => {
        setAnchorEl(null)    
      };
      const handleChoose = () => {
        setDeleteModal(true)
      }
      //  console.log(menuInd,'indexOFArray')
      const handleCommentDelete = async () => {
        setDeleteLoader(true)
        try{
            const config={
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
              }
           const res = await axios.delete(`${url}/api/SongCollaborationComment/deleteSongCollaborationComment/${commentData[menuInd]?._id}`,config)
           setCommentData((prevState) => prevState.filter((val,ind) => {
               return ind !== menuInd
           }))
           setDeleteLoader(false)
           setModalContant(true)
           console.log(res.data,'datavALUESSSSSS')
        }catch (error){
            console.log(error)
        }
      }

      const handleReportModal = () => {
        setReportModal(false)
       }
      
       const handleCategorySelected = (data) => {
         setSelectedReporCategory(data)
       }
      const handleDescriptionChange = (e) => {
        if(discription){
          setDiscription(false)
        }else{
          setDiscription(true)
        }
      }
      
      const handleReportSupport  = async () => {
        setReportSubmitted(true)
        try{
          const config={
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          }
          const data = {
            Description : discriptionText,
            ReportTypeID : selectedReportCategory._id,
            ReportedByUserID : userTokenData.userId,
            ResourceID : selectedComment._id
          }
           const Response = await axios.post(`${url}/api/Report/createReport/Comment`,data,config)
           console.log(Response.data,'dataValuessssss')
           setReportSuccessfullyDetails((prevState) => [...prevState,Response.data?.data?.SongCollaborationCommentID])
           function modalLoderFunc () {
            if(Response.data.message === 'Document Create successfully'){
              setSelectedReporCategory('')
              setDiscription(false)
              setReportSubmitted(false)
              setReportModal(false)  
             }
           }
           setTimeout(modalLoderFunc,4000)
        }catch (error){
          console.log(error)
        }
      }

      const handleModalOpen = (val) => {
         setSelectedComment(val)
         setReportModal(true)
      }
      //  console.log(selectedComment,'selectedCommnetsss')
    return(
        <Box>
    <Box sx={{display:screenWidth > 996 ? 'block' : 'none'}}>
            {
              likeScreen ?  <Card className="commnet-container" sx={{width:'100%',height:'480px',marginTop:'50px'}}>
              <Box sx={{display:'flex',justifyContent:'space-between',padding:'10px'}}>
                  <Box>
                      <Box sx={{color:'rgb(45, 40, 103)',fontSize:'17px',fontWeight:700,marginBottom:'5px'}}>Comments</Box>
                      <Box sx={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:500,marginLeft: choosed ? '' : '30px'}}>{commentData.length > 1 ? `${commentData.length} comments` : `${commentData.length} comment`}</Box>
                  </Box>
                  <Box onClick={() => funcComment(true)} sx={{marginTop:'10px',cursor:'pointer'}}><CancelIcon style={{fontSize:'30px',color:'rgb(192, 204, 216)'}}/></Box>
              </Box>
              <Box sx={{display:'flex',flexDirection:'column',justifyContent:'space-between',height:'86%'}}>
              <Box sx={{borderTop:'2px solid #EAE9F0',overflowY:'scroll',height:'100%'}}>
              {
                 commentData && commentData.map((val,ind) => (
                  // console.log(val,"commentttt"),
                  
                      <Box sx={{display:'flex',justifyContent:'space-between',overflowWrap:"break-word"}}>
                      <Box sx={{display:'flex',gap:2,padding:'10px'}}>
                          <Box sx={{cursor:'pointer'}} onClick={() => {navigate(`/otherProfile/${val?.UserID}`)}}>
                          <Avatar alt="loading" sx={{width:'40px',height:'40px',borderRadius:'50%'}} src={val?.UserProfile}/>
                          </Box>
                          <Box>
                          <Box sx={{display:'flex',gap:.5,flexDirection:'column'}}>
                              <Box sx={{color:'rgb(45, 40, 103)',fontSize: '14px',fontWeight: '700',cursor:'pointer'}} onClick={() => {navigate(`/otherProfile/${val?.UserID}`)}}>
                             {val?.Name !== '' ? val?.Name : 'Default User' }
                              </Box>
                           
                              <Box sx={{display:'flex',flexDirection:'column',overflowWrap:"break-word"}}>
                              <Box 
  sx={{
    color: 'rgb(45, 40, 103)',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '20px',
    wordBreak: 'break-word',   // Ensures words break correctly
    whiteSpace: 'pre-wrap'     // Preserves spaces and ensures text wraps
  }}
>
  {val?.CommentText}
</Box>
                              </Box>
                              <Box sx={{display:'flex',gap:1}}>
                          <Box sx={{display:'flex',gap:.5}}>
                              <Box>
                                  {
                                    val?.IsLiked === true || commentId === ind  ? <FavoriteIcon fontSize="10"  style={{fill:'rgb(253 40 110)',cursor:'pointer'}}/> :<FavoriteBorderIcon onClick={() => {handleCommentLike(val._id,ind)}} fontSize="10" style={{color:'rgb(129, 131, 140)',cursor:'pointer'}}/>     
                                  }
                              </Box>
                              {
                                  val.NoOfLikes && val.NoOfLikes != 0 ? <Box onClick={() => handleCommentLikeList(val._id)} sx={{fontSize:'14px',fontWeight:700,color:'rgb(114, 140, 166)',borderBottom:'1px dotted rgb(192, 204, 216)',cursor:'pointer'}}>{val.NoOfLikes && val.NoOfLikes} <span style={{fontWeight:400}}>{val.NoOfLikes && val.NoOfLikes > 1 ? 'loves' : 'love'}</span></Box> : ''
                              }
                          </Box>
                          <Box>
                          <Box sx={{color:'rgb(129, 131, 140)',fontSize: '14px',fontWeight: '400'}}>{val.createdAt ? <TimeAgo timestamp={val.createdAt}/> : '12 Minutes ago'}</Box>
                      </Box>
                      </Box>
                      </Box>
                      {/* <Box sx={{display:'flex',gap:1}}>
                          <Box sx={{display:'flex',gap:.5}}>
                              <Box>
                                  {
                                    val.SongCollaborationCommentLike.some(e => e.UserID === userTokenData.userId) === true || commentId === ind  ? <FavoriteIcon fontSize="10"  style={{fill:'rgb(253 40 110)'}}/> :<FavoriteBorderIcon onClick={() => {handleCommentLike(val._id,ind)}} fontSize="10" style={{color:'rgb(129, 131, 140)'}}/>     
                                  }
                              </Box>
                              {
                                  val.NoOfLikes && val.NoOfLikes != 0 ? <Box sx={{fontSize:'14px',fontWeight:700,color:'rgb(114, 140, 166)',borderBottom:'1px dotted rgb(192, 204, 216)'}}>{val.NoOfLikes && val.NoOfLikes} <span style={{fontWeight:400}}>love</span></Box> : ''
                              }
                          </Box>
                          <Box>
                          <Box sx={{color:'rgb(129, 131, 140)',fontSize: '14px',fontWeight: '400'}}>{val.createdAt ? <TimeAgo timestamp={val.createdAt}/> : '12 Minutes ago'}</Box>
                      </Box>
                      </Box> */}
                      </Box>
                      </Box>
                      <Box
            sx={{ cursor: "pointer" }}
            onClick={(event) => handleMenuOpener(event, ind)}
          >
            <MoreVertIcon
              style={{ color: "rgb(114, 140, 166)", marginTop: "12px" }}
            />
          </Box>
          {menuInd === ind && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              style={{marginTop:'10px',fontSize:'17px',fontWeight:'bold',padding:'0',paddingTop:'0',paddingBottom:'0'}}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
                {
                    val.UserID === userTokenData.userId || user[0]?.users[0]?._id === userTokenData.userId ? <MenuItem sx={{fontSize:'16px',fontWeight:700,padding:'5px',backgroundColor:'white',color:'rgb(235, 70, 70)','&:hover':{color:'white',backgroundColor:'rgb(235, 70, 70)'}}} onClick={() => {handleChoose(val._id)}}>Delete Comment   <span style={{margin:'5px',marginTop:'10px'}}><RiDeleteBin6Line size={20}/></span></MenuItem> : ''
                }

                {
                  val.UserID !== userTokenData.userId && <>
                     {
                      reportSuccessfullyDetails.includes(val._id) ? <MenuItem>
                        <Box sx={{display:'flex',justifyContent:'space-between',opacity:0.2,width:'100%'}}>
                          <Box sx={{color:'rgb(45, 40, 103)',fontSize:'17px',fontWeight:700}}>Reported</Box>
                          <Box><FaCheckCircle fill="rgb(45, 40, 103)" size={25}/></Box>
                        </Box>
                      </MenuItem> : <MenuItem sx={{fontSize:'16px',fontWeight:700,padding:'5px',backgroundColor:'white',color:'rgb(45, 40, 103)','&:hover':{color:'white',backgroundColor:'rgb(45, 40, 103)'}}} onClick={() => {handleModalOpen(val)}}>Report Comment   <span style={{margin:'5px',marginTop:'10px'}}><FlagIcon style={{fontSize:'16px'}}/></span></MenuItem>
                      }
                  </>
                }
            </Menu>
          )}
                  </Box> 
                  ))
              }
              </Box>
              <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        borderTop: "2px solid #EAE9F0",
        padding: "5px",
      }}
    >
      <Box>
        <input
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Add a comment"
          className="commentInput"
          maxLength={40}
          style={{
            border: "none",
            backgroundColor: "rgb(255, 255, 255)",
            width: "492px",
            height: "30px",
            letterSpacing: "1px", // Ensures spacing between letters
            padding: "5px", // Adds some inner spacing
            fontSize: "14px", // Better readability
          }}
        />
      </Box>
      <Box>
        <Button
          disabled={comment.trim() !== "" ? false : true}
          sx={{
            backgroundColor: "rgb(108, 46, 255)",
            color: "white",
            borderRadius: "5px",
            padding: "5px 15px",
            textTransform: "none",
            fontSize: "15px",
            fontWeight: 700,
            opacity: comment.length !== 0 ? 1 : 0.3,
            cursor: comment.length !== 0 ? "pointer" : "not-allowed",
          }}
          onClick={handleClick}
        >
          Post
        </Button>
      </Box>
    </Box>
              </Box>
              <Authmodel open={restrict} HandleClose={HandleClose}/>
          </Card> : <Card className="commnet-container" sx={{width:'480px',height:'480px',marginTop:'50px'}}>
                 <Box sx={{display:'flex',justifyContent:'space-between',padding:'10px'}}>
                      <Box sx={{display:'flex',gap:1}}>
                         <Box onClick={() => {setLikeScreen(true)}} sx={{marginTop:'5px',cursor:'pointer'}}>
                            <IoIosArrowBack color="rgb(192 204 216)" size={27}/>
                         </Box>
                         <Box sx={{display:'flex',flexDirection:'column'}}>
                            <Box sx={{color:'rgb(45, 40, 103)',fontSize:'14px',fontWeight:700}}>Comment likes</Box>
                            <Box sx={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:500}}>{list && list.length > 1 ? `${list.length} loves` : `${list.length} love` } </Box>
                         </Box>
                        </Box> 
                        <Box onClick={() => funcComment(true)} sx={{marginTop:'10px',cursor:'pointer'}}><CancelIcon style={{fontSize:'30px',color:'rgb(192, 204, 216)'}}/></Box>
                 </Box>
                 <Box sx={{borderTop:'2px solid #EAE9F0'}}>
                    {
                      list &&  list.map((data,index) => (
                            <>
                              {
                                data.users && data.users.map((val,ind) => (
                                    <Box sx={{display:'flex',justifyContent:'space-between'}}>
                                        <Box sx={{display:'flex',padding:'10px',gap:2,alignItems:'center'}}>
                                            <Box>
                                                <Avatar sx={{width:'40px',height:'40px',borderRadius:'50%'}} src={val.ProfilePhoto}/>
                                            </Box>
                                                <Box sx={{color:'rgb(45, 40, 103)',fontSize:'14px',fontWeight:700}}>{val.Name != '' ? val.Name : 'User'}</Box>
                                        </Box>
                                        <Box onClick={() => {
                      navigate(`/otherProfile/${val._id}`)
                    }} sx={{padding:'10px',marginTop:'8px',cursor:'pointer'}}>
                       <KeyboardArrowRightIcon style={{fontSize:'30px',color:'rgb(192, 204, 216)',cursor:'pointer'}}/>
                    </Box>
                                    </Box>
                                ))
                              }
                            </>
                        ))
                    }
                 </Box>
          </Card>
            }
        </Box> 
        <Modal  style={{display:screenWidth < 996 && screenWidth > 510 ? 'block' : 'none'}}
        open={screenWidth < 996 ? true : false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
         <Box sx={style}>
            {
              likeScreen ?  <Card className="commnet-container" sx={{width:'480px',height:'480px'}}>
              <Box sx={{display:'flex',justifyContent:'space-between',padding:'10px'}}>
                  <Box>
                      <Box sx={{color:'rgb(45, 40, 103)',fontSize:'17px',fontWeight:700,marginBottom:'5px'}}>Comments</Box>
                      <Box sx={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:500,marginLeft: choosed ? '' : '30px'}}>{commentData.length > 1 ? `${commentData.length} comments` : `${commentData.length} comment`}</Box>
                  </Box>
                  <Box onClick={() => funcComment(true)} sx={{marginTop:'10px',cursor:'pointer'}}><CancelIcon style={{fontSize:'30px',color:'rgb(192, 204, 216)'}}/></Box>
              </Box>
              <Box sx={{display:'flex',flexDirection:'column',justifyContent:'space-between',height:'86%'}}>
              <Box sx={{borderTop:'2px solid #EAE9F0',overflowY:'scroll',height:'100%'}}>
              {
                 commentData && commentData.map((val,ind) => (
                 
                  
                      <Box sx={{display:'flex',justifyContent:'space-between'}}>
                      <Box sx={{display:'flex',gap:2,padding:'10px'}}>
                          <Box>
                            
                          <Avatar alt="loading" sx={{width:'40px',height:'40px',borderRadius:'50%'}} src={val?.ProfilePhoto}/>
                          </Box>
                          <Box>
                          <Box sx={{display:'flex',gap:.5,flexDirection:'column'}}>
                              <Box sx={{color:'rgb(45, 40, 103)',fontSize: '14px',fontWeight: '700'}}>
                             {val?.UserName !== '' ? val?.UserName : 'Default User' }
                              </Box>
                              <Box sx={{display:'flex',flexDirection:'column'}}>
                              <Box sx={{color:'rgb(45, 40, 103)',fontSize: '16px',fontWeight: '500',lineHeight:'20px'}}>
                             {val?.CommentText}
                             </Box>
                              </Box>
                              <Box sx={{display:'flex',gap:1}}>
                          <Box sx={{display:'flex',gap:.5}}>
                              <Box>
                                  {
                                    val?.SongCollaborationCommentLike?.some(e => e.UserID === userTokenData.userId) === true || commentId === ind  ? <FavoriteIcon fontSize="10"  style={{fill:'rgb(253 40 110)',cursor:'pointer'}}/> :<FavoriteBorderIcon onClick={() => {handleCommentLike(val._id,ind)}} fontSize="10" style={{color:'rgb(129, 131, 140)',cursor:'pointer'}}/>     
                                  }
                              </Box>
                              {
                                  val.NoOfLikes && val.NoOfLikes != 0 ? <Box onClick={() => handleCommentLikeList(val._id)} sx={{fontSize:'14px',fontWeight:700,color:'rgb(114, 140, 166)',borderBottom:'1px dotted rgb(192, 204, 216)',cursor:'pointer'}}>{val.NoOfLikes && val.NoOfLikes} <span style={{fontWeight:400}}>{val.NoOfLikes && val.NoOfLikes > 1 ? 'loves' : 'love'}</span></Box> : ''
                              }
                          </Box>
                          <Box>
                          <Box sx={{color:'rgb(129, 131, 140)',fontSize: '14px',fontWeight: '400'}}>{val.createdAt ? <TimeAgo timestamp={val.createdAt}/> : '12 Minutes ago'}</Box>
                      </Box>
                      </Box>
                      </Box>
                      {/* <Box sx={{display:'flex',gap:1}}>
                          <Box sx={{display:'flex',gap:.5}}>
                              <Box>
                                  {
                                    val.SongCollaborationCommentLike.some(e => e.UserID === userTokenData.userId) === true || commentId === ind  ? <FavoriteIcon fontSize="10"  style={{fill:'rgb(253 40 110)'}}/> :<FavoriteBorderIcon onClick={() => {handleCommentLike(val._id,ind)}} fontSize="10" style={{color:'rgb(129, 131, 140)'}}/>     
                                  }
                              </Box>
                              {
                                  val.NoOfLikes && val.NoOfLikes != 0 ? <Box sx={{fontSize:'14px',fontWeight:700,color:'rgb(114, 140, 166)',borderBottom:'1px dotted rgb(192, 204, 216)'}}>{val.NoOfLikes && val.NoOfLikes} <span style={{fontWeight:400}}>love</span></Box> : ''
                              }
                          </Box>
                          <Box>
                          <Box sx={{color:'rgb(129, 131, 140)',fontSize: '14px',fontWeight: '400'}}>{val.createdAt ? <TimeAgo timestamp={val.createdAt}/> : '12 Minutes ago'}</Box>
                      </Box>
                      </Box> */}
                      </Box>
                      </Box>
                      <Box>
                          <MoreVertIcon style={{color:'rgb(114, 140, 166)',marginTop:'12px'}}/>
                      </Box>
                  </Box> 
                  ))
              }
              </Box>
              <Box sx={{display:'flex',justifyContent:'space-between',borderTop:'2px solid #EAE9F0',padding:'5px'}}>
                    <Box><input value={comment} onChange={(e) => {setComment(e.target.value)}} placeholder="Add a comment" className="commentInput" style={{border:'none',backgroundColor:'rgb(255, 255, 255)',width:'400px',height:'30px'}}/></Box>
                    <Box><Button disabled={comment !== '' ? false : true} sx={{backgroundColor:'rgb(108, 46, 255)',color:'white',borderRadius:'5px',padding:'5px 0',textTransform:'none',fontSize:'15px',fontWeight:700,opacity : comment.length !== 0 ? '' : '0.3',cursor:'pointer'}} onClick={handleClick}>Post</Button></Box>
              </Box>
              </Box>
              <Authmodel open={restrict} HandleClose={HandleClose}/>
          </Card> : <Card className="commnet-container" sx={{width:'480px',height:'480px'}}>
                 <Box sx={{display:'flex',justifyContent:'space-between',padding:'10px'}}>
                      <Box sx={{display:'flex',gap:1}}>
                         <Box onClick={() => {setLikeScreen(true)}} sx={{marginTop:'5px',cursor:'pointer'}}>
                            <IoIosArrowBack color="rgb(192 204 216)" size={27}/>
                         </Box>
                         <Box sx={{display:'flex',flexDirection:'column'}}>
                            <Box sx={{color:'rgb(45, 40, 103)',fontSize:'14px',fontWeight:700}}>Comment likes</Box>
                            <Box sx={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:500}}>{list && list.length > 1 ? `${list.length} loves` : `${list.length} love` } </Box>
                         </Box>
                        </Box> 
                        <Box onClick={() => funcComment(true)} sx={{marginTop:'10px',cursor:'pointer'}}><CancelIcon style={{fontSize:'30px',color:'rgb(192, 204, 216)'}}/></Box>
                 </Box>
                 <Box sx={{borderTop:'2px solid #EAE9F0'}}>
                    {
                      list &&  list.map((data,index) => (
                            <>
                              {
                                data.users && data.users.map((val,ind) => (
                                    <Box sx={{display:'flex',justifyContent:'space-between'}}>
                                        <Box sx={{display:'flex',padding:'10px',gap:2}}>
                                            <Box>
                                                <Avatar sx={{width:'40px',height:'40px',borderRadius:'50%'}} src={val.ProfilePhoto}/>
                                            </Box>
                                            <Box sx={{display:'flex',flexDirection:'column'}}>
                                                <Box sx={{color:'rgb(45, 40, 103)',fontSize:'14px',fontWeight:700}}>{val.Name != '' ? val.Name : 'User'}</Box>
                                                <Box sx={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:500}}>{val.Email}</Box>
                                            </Box>
                                        </Box>
                                        <Box onClick={() => {
                      navigate(`/otherProfile/${val._id}`)
                    }} sx={{padding:'10px',marginTop:'8px',cursor:'pointer'}}>
                       <KeyboardArrowRightIcon style={{fontSize:'30px',color:'rgb(192, 204, 216)',cursor:'pointer'}}/>
                    </Box>
                                    </Box>
                                ))
                              }
                            </>
                        ))
                    }
                 </Box>
          </Card>
            }
        </Box>    
    </Modal>
    <Drawer anchor='bottom'
      open={screenWidth < 510 ? true : false} 
      PaperProps={{
        sx : {
          backgroundColor:'white',
        }
      }}
      >
            {
              likeScreen ?  <Card className="commnet-container" sx={{width:'100%'}}>
              <Box sx={{display:'flex',justifyContent:'space-between',padding:'10px'}}>
                  <Box>
                      <Box sx={{color:'rgb(45, 40, 103)',fontSize:'17px',fontWeight:700,marginBottom:'5px'}}>Comments</Box>
                      <Box sx={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:500,marginLeft: choosed ? '' : '30px'}}>{commentData.length > 1 ? `${commentData.length} comments` : `${commentData.length} comment`}</Box>
                  </Box>
                  <Box onClick={() => funcComment(true)} sx={{marginTop:'10px',cursor:'pointer'}}><CancelIcon style={{fontSize:'30px',color:'rgb(192, 204, 216)'}}/></Box>
              </Box>
              <Box sx={{display:'flex',flexDirection:'column',justifyContent:'space-between',height:'86%'}}>
              <Box sx={{borderTop:'2px solid #EAE9F0',overflowY:'scroll',height:'100%'}}>
              {
                 commentData && commentData.map((val,ind) => (
                      <Box sx={{display:'flex',justifyContent:'space-between'}}>
                      <Box sx={{display:'flex',gap:2,padding:'10px'}}>
                          <Box>
                          <Avatar alt="loading" sx={{width:'40px',height:'40px',borderRadius:'50%'}} src={val.ProfilePhoto}/>
                          </Box>
                          <Box>
                          <Box sx={{display:'flex',gap:.5,flexDirection:'column'}}>
                              <Box sx={{color:'rgb(45, 40, 103)',fontSize: '14px',fontWeight: '700'}}>
                             {val?.UserName !== '' ? val?.UserName : 'Default User' }
                              </Box>
                              <Box sx={{display:'flex',flexDirection:'column'}}>
                              <Box sx={{color:'rgb(45, 40, 103)',fontSize: '16px',fontWeight: '500',lineHeight:'20px'}}>
                             {val?.CommentText}
                             </Box>
                              </Box>
                              <Box sx={{display:'flex',gap:1}}>
                          <Box sx={{display:'flex',gap:.5}}>
                              <Box>
                                  {
                                    val?.SongCollaborationCommentLike?.some(e => e.UserID === userTokenData.userId) === true || commentId === ind  ? <FavoriteIcon fontSize="10"  style={{fill:'rgb(253 40 110)',cursor:'pointer'}}/> :<FavoriteBorderIcon onClick={() => {handleCommentLike(val._id,ind)}} fontSize="10" style={{color:'rgb(129, 131, 140)',cursor:'pointer'}}/>     
                                  }
                              </Box>
                              {
                                  val.NoOfLikes && val.NoOfLikes != 0 ? <Box onClick={() => handleCommentLikeList(val._id)} sx={{fontSize:'14px',fontWeight:700,color:'rgb(114, 140, 166)',borderBottom:'1px dotted rgb(192, 204, 216)',cursor:'pointer'}}>{val.NoOfLikes && val.NoOfLikes} <span style={{fontWeight:400}}>{val.NoOfLikes && val.NoOfLikes > 1 ? 'loves' : 'love'}</span></Box> : ''
                              }
                          </Box>
                          <Box>
                          <Box sx={{color:'rgb(129, 131, 140)',fontSize: '14px',fontWeight: '400'}}>{val.createdAt ? <TimeAgo timestamp={val.createdAt}/> : '12 Minutes ago'}</Box>
                      </Box>
                      </Box>
                      </Box>
                      {/* <Box sx={{display:'flex',gap:1}}>
                          <Box sx={{display:'flex',gap:.5}}>
                              <Box>
                                  {
                                    val.SongCollaborationCommentLike.some(e => e.UserID === userTokenData.userId) === true || commentId === ind  ? <FavoriteIcon fontSize="10"  style={{fill:'rgb(253 40 110)'}}/> :<FavoriteBorderIcon onClick={() => {handleCommentLike(val._id,ind)}} fontSize="10" style={{color:'rgb(129, 131, 140)'}}/>     
                                  }
                              </Box>
                              {
                                  val.NoOfLikes && val.NoOfLikes != 0 ? <Box sx={{fontSize:'14px',fontWeight:700,color:'rgb(114, 140, 166)',borderBottom:'1px dotted rgb(192, 204, 216)'}}>{val.NoOfLikes && val.NoOfLikes} <span style={{fontWeight:400}}>love</span></Box> : ''
                              }
                          </Box>
                          <Box>
                          <Box sx={{color:'rgb(129, 131, 140)',fontSize: '14px',fontWeight: '400'}}>{val.createdAt ? <TimeAgo timestamp={val.createdAt}/> : '12 Minutes ago'}</Box>
                      </Box>
                      </Box> */}
                      </Box>
                      </Box>
                      <Box>
                          <MoreVertIcon style={{color:'rgb(114, 140, 166)',marginTop:'12px'}}/>
                      </Box>
                  </Box> 
                  ))
              }
              </Box>
              <Box sx={{display:'flex',justifyContent:'space-between',borderTop:'2px solid #EAE9F0',padding:'5px'}}>
                    <Box><input value={comment} onChange={(e) => {setComment(e.target.value)}} placeholder="Add a comment" className="commentInput" style={{border:'none',backgroundColor:'rgb(255, 255, 255)',width:'90%',height:'30px'}}/></Box>
                    <Box><Button disabled={comment !== '' ? false : true} sx={{backgroundColor:'rgb(108, 46, 255)',color:'white',borderRadius:'5px',padding:'5px 0',textTransform:'none',fontSize:'15px',fontWeight:700,opacity : comment.length !== 0 ? '' : '0.3',cursor:'pointer'}} onClick={handleClick}>Post</Button></Box>
              </Box>
              </Box>
              <Authmodel open={restrict} HandleClose={HandleClose}/>
          </Card> : <Card className="commnet-container" sx={{width:'100%'}}>
                 <Box sx={{display:'flex',justifyContent:'space-between',padding:'10px'}}>
                      <Box sx={{display:'flex',gap:1}}>
                         <Box onClick={() => {setLikeScreen(true)}} sx={{marginTop:'5px',cursor:'pointer'}}>
                            <IoIosArrowBack color="rgb(192 204 216)" size={27}/>
                         </Box>
                         <Box sx={{display:'flex',flexDirection:'column'}}>
                            <Box sx={{color:'rgb(45, 40, 103)',fontSize:'14px',fontWeight:700}}>Comment likes</Box>
                            <Box sx={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:500}}>{list && list.length > 1 ? `${list.length} loves` : `${list.length} love` } </Box>
                         </Box>
                        </Box> 
                        <Box onClick={() => funcComment(true)} sx={{marginTop:'10px',cursor:'pointer'}}><CancelIcon style={{fontSize:'30px',color:'rgb(192, 204, 216)'}}/></Box>
                 </Box>
                 <Box sx={{borderTop:'2px solid #EAE9F0'}}>
                    {
                      list &&  list.map((data,index) => (
                            <>
                              {
                                data.users && data.users.map((val,ind) => (
                                    <Box sx={{display:'flex',justifyContent:'space-between'}}>
                                        <Box sx={{display:'flex',padding:'10px',gap:2}}>
                                            <Box>
                                                <Avatar sx={{width:'40px',height:'40px',borderRadius:'50%'}} src={val.ProfilePhoto}/>
                                            </Box>
                                            <Box sx={{display:'flex',flexDirection:'column'}}>
                                                <Box sx={{color:'rgb(45, 40, 103)',fontSize:'14px',fontWeight:700}}>{val.Name != '' ? val.Name : 'User'}</Box>
                                                <Box sx={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:500}}>{val.Email}</Box>
                                            </Box>
                                        </Box>
                                        <Box onClick={() => {
                      navigate(`/otherProfile/${val._id}`)
                    }} sx={{padding:'10px',marginTop:'8px',cursor:'pointer'}}>
                       <KeyboardArrowRightIcon style={{fontSize:'30px',color:'rgb(192, 204, 216)',cursor:'pointer'}}/>
                    </Box>
                                    </Box>
                                ))
                              }
                            </>
                        ))
                    }
                 </Box>
          </Card>
            }

      </Drawer>
      <Modal
        open={deleteModal}
        onClose={() => {
            setDeleteModal(false)
            setModalContant(false)
        }
        }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            {
                modalContent ? <Box sx={{display:'flex',flexDirection:'column',padding:'40px',justifyContent:'center',paddingBottom:'15px'}}>
                     <Box sx={{textAlign:'center'}}><FcOk size={35}/></Box>
                     <Box sx={{color:'rgb(45, 40, 103)',fontSize:'24px',fontWeight:700,marginTop:'5px',textAlign:'center'}}>
                     Done!
                     </Box>
                     <Box sx={{display:'flex',justifyContent:'center',marginTop:'5px',color:'rgb(114, 140, 166)',textAlign:'center'}}>Your comment was deleted.</Box>
                     <Box sx={{background:'rgb(228 233 238)',color:'rgb(114, 140, 166)',fontSize:'17px',fontWeight:'bold',textAlign:'center',padding:'10px',borderRadius:'5px',width:'90%',margin:'0px',cursor:'pointer',marginTop:'20px'}} onClick={() => {setDeleteModal(false)}}>OK</Box>
                </Box> : <Box sx={{display:'flex',alignItems:'center',flexDirection:'column',padding:'20px'}}>
             <Box>
                <RiDeleteBin6Line fill="rgb(235, 70, 70)" size={25}/>
             </Box>
             <Box sx={{color:'rgb(45, 40, 103)',fontSize:'24px',fontWeight:700,marginTop:'5px'}}>
                Delete a comment
             </Box>
             <Box sx={{display:'flex',justifyContent:'center',marginTop:'5px'}}>
                <Box sx={{color:'rgb(114, 140, 166)'}}>Are you sure you want to delete this comment?</Box>
             </Box>
             <Box sx={{display:'flex',flexDirection:'column',marginTop:'15px',width:'100%'}}>
                <Box sx={{background:'rgb(235, 70, 70)',color:'white',fontSize:'17px',fontWeight:'bold',width:'90%',textAlign:'center',padding:'10px',borderRadius:'5px',margin:'5px',cursor:'pointer'}} onClick={handleCommentDelete}>
                    {
                        deleteLoader ? <CircularProgress size={30} sx={{color:'white',fontSize:'10px'}}/> : 'Delete'
                    }
                </Box>
                <Box sx={{background:'rgb(228 233 238)',color:'rgb(114, 140, 166)',fontSize:'17px',fontWeight:'bold',textAlign:'center',padding:'10px',borderRadius:'5px',width:'90%',margin:'5px',cursor:'pointer'}}>Cancel</Box>
             </Box>
             
          </Box>
            }
        </Box>
      </Modal>
      <Modal
        open={reportModal}
        onClose={handleReportModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 300,padding:'20px'}}>
          {
            selectedReportCategory === '' &&  <Box sx={{display:'flex',justifyContent:'flex-end',cursor:'pointer'}} onClick={() => {setReportModal(false)}}>
            <CancelIcon style={{fontSize:'30px',color:'rgb(192, 204, 216)'}}/>
            </Box>
          }
         <Box sx={{textAlign:'center',color:'rgb(45, 40, 103)'}}>
          <FlagIcon color='rgb(45, 40, 103)'/>
         </Box>
          <Box sx={{fontSize:'24px',fontWeight:700,color:'rgb(45, 40, 103)',textAlign:'center'}}>Report <span style={{fontSize:'24px',fontWeight:700,color:'rgb(45, 40, 103)'}}>{selectedReportCategory.Name}</span></Box>
          {/* <Box sx={{fontSize:'24px',fontWeight:700,color:'rgb(45, 40, 103)',textAlign:'center'}}>{selectedReportCategory.Name}</Box> */}
          <Box sx={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:500,mt:.5,textAlign:'center'}}>Are you sure you want to report this recording for a community <span style={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:500}}>{selectedReportCategory.Name}?</span></Box>
          {
            selectedReportCategory !== '' &&  <Box sx={{display:'flex',mt:1,alignItems:'center',gap:.5}}>
            <Box><Checkbox sx={{padding:0}} size='small' onChange={handleDescriptionChange} checked={discription}/></Box> 
            <Box sx={{fontSize:'14px',color:'rgb(45, 40, 103)',fontWeight:700}}>Description</Box>
         </Box>
          }
          {
            discription && selectedReportCategory !== '' && <Box sx={{mt:1}}>
              <textarea onChange={(e) => {setDiscriptionText(e.target.value)}} style={{width:'100%',height:'80px',paddingInlineStart:'10px',paddingTop:'5px'}}></textarea>
            </Box>
          }
         {
           selectedReportCategory !== ''  ? <Box sx={{display:'flex',flexDirection:'column',gap:1,mt:3}}>
              <Box sx={{width:'100%',textAlign:'center',backgroundColor:'rgb(45, 40, 103)',color:'white',height:'40px',borderRadius:'5px',fontSize:'17px',fontWeight:700,alignItems:'center',display:'flex',justifyContent:'center',cursor:'pointer'}} onClick={handleReportSupport}>
                {
                  reportSubmitted ? <CircularProgress size={25} sx={{color:'white',fontSize:'10px'}}/> : <Box>Report</Box>
                }
              </Box>
              <Box sx={{width:'100%',textAlign:'center',backgroundColor:'rgb(228, 233, 238)',color:'rgb(114, 140, 166)',height:'40px',borderRadius:'5px',fontSize:'17px',fontWeight:700,alignItems:'center',display:'flex',justifyContent:'center',cursor:'pointer'}} onClick={() => {
                setSelectedReporCategory('')
                setDiscription(false)
                }}>Cancel</Box>
           </Box> : 
            <>
         {
          reportData && reportData.map((val,ind) => (
            console.log(val,'jdjdjdjdj'),
            
            <>
             {
               val.resourceTypes && val.resourceTypes.map((data,index) => (
                console.log(data,"comment"),
                
                   data.Name === 'Comment' && <p onClick={() => {handleCategorySelected(val)}} style={{color:'rgb(45, 40, 103)',fontSize:'17px',fontWeight:700,borderBottom:'1px solid rgb(192 204 216)',padding:'5px',cursor:'pointer'}}>{data.Name}</p>
                ))
             }
            </>
          ))
         }
         </>
         }
        </Box>
      </Modal>
        </Box>
    )
}

export default ModuleComment