import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  FormControl,
  IconButton,
  InputAdornment,
  InputBase,
  Modal,
  OutlinedInput,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { url } from '../config/config';
import logo from '../assest/images/Bright Color-01.png'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

const Forgot = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [resetEmails, setResetEmails] = React.useState('');
  const [forpass, setForpass] = React.useState('');
  const [reset, setReset] = React.useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showResetPasswordModal, setShowResetPasswordModal] = React.useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [searchParams] = useSearchParams();
  console.log(searchParams,"hhhhhhhh");
  const { state } = useLocation()
  console.log(state,"hdhdhhd");
  const { resetEmail } = useParams();
  console.log(resetEmail,"jdjdjdjjd");
  const navigate = useNavigate();
   
  
  

  const token = queryParams.get('token');
  const handleCancel = () => {
    navigate('/'); // Redirects to the home screen
  };
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev); // Toggle true/false
  };
  const handleResetPassword = async () => {
    console.log('UserEmail: ', resetEmails, 'UserPassword :', forpass);
    if (!reset || !forpass) {
      setErrorMessage('All fields are required');
      return;
    }
    if (reset !== forpass) {
      setErrorMessage('Passwords do not match');
      return;
    }
    setErrorMessage('');
    setLoading(true);
    
    try {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
      const res = await axios.post(`${url}/api/User/forgotPassword`, {
        UserEmail:resetEmail,
        UserPassword:forpass,
      }, config);
      console.log(res)
      setShowResetPasswordModal(true);
    } catch (error) {
      // Handle errors
      console.log(error)

    }
   finally {
    setLoading(false);
  }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        };
        const res = await axios.get(`${url}/api/User/forgotPasswordEmail?token=${token}`, config);
        console.log(res.data.data.Email, 'forgetemailtoken');
        setResetEmails(res.data.data.Email);
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchData();
  }, [token]);
  
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // minHeight:'100vh',
        // width: '100vw',
        // height: '100vh',
        backgroundColor: '#32286F',
        padding: 3,
        
      }}
    >
      <Box
        sx={{
          width: '400px',
          textAlign: 'center',
          backgroundColor: 'black',
          padding: 3,
          borderRadius: '10px',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
          
        }}
      >
          <Box sx={{ width: '250px', margin: '0 auto' }}>
                <img src={logo} alt="Logo" />
              </Box>
        <Typography
          fontWeight="bold"
          fontSize={25}
          color="white"
          fontFamily="Cursive"
          mt={2}
        >
          Reset Password
        </Typography>
        <Typography
          fontSize={14}
          color="white"
          fontFamily="Cursive"
          mt={1}
        >
          Enter your email address to receive a password reset link.
        </Typography>

       
        <Box sx={{ mt: 3 }}>
          <InputBase
            sx={{
              color: 'white',
              width: '100%',
              borderRadius: '3px',
              padding: '10px 20px',
              border: 1,
              borderColor: '#6f6f6f',
              '&:hover': {
                borderColor: 'white',
              },
            }}
            placeholder="New password"
            value={reset}
            onChange={(e) => setReset(e.target.value)}
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <FormControl sx={{ width: '100%' }} variant="outlined">
            <OutlinedInput
              placeholder="Confirm Password"
              id="outlined-adornment-password"
              sx={{
                backgroundColor: '#121212',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#6F6F6F',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#FFFFFF',
                },
                '& .MuiInputBase-input': {
                  color: 'white',
                },
              }}
              type={showPassword ? 'text' : 'password'}
              value={forpass}
              onChange={(e) => setForpass(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={
                      showPassword ? 'hide the password' : 'display the password'
                    }
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <Visibility sx={{ color: 'white' }} />
                    ) : (
                      <VisibilityOff sx={{ color: 'white' }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {errorMessage && (
            <Typography color="red" fontSize={12} mt={1}>
              {errorMessage}
            </Typography>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 3,
            mt: 3,
          }}
        >
          <Button
  variant="outlined"
  sx={{
    color: 'white',
    borderColor: 'white',
    borderRadius: 20,
    '&:hover': {
      borderColor: '#1ED760',
    },
  }}
  onClick={handleCancel} // Navigate to home on click
>
  Cancel
</Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#1ED760',
              color: 'black',
              borderRadius: 20,
              '&:hover': {
                backgroundColor: '#1EB460',
              },
            }}
            onClick={handleResetPassword}
          >
           {loading ? <CircularProgress size={24} sx={{ color: 'black' }} /> : 'Submit Now'}
          </Button>
        </Box>
      </Box>
      <Modal
  open={showResetPasswordModal}
  onClose={() => setShowResetPasswordModal(false)}
  closeAfterTransition

>
  <Fade in={showResetPasswordModal}>
    <Box sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 300,
      bgcolor: 'black',
      borderRadius: '10px',
      boxShadow: 24,
      p: 3,
      textAlign: 'center'
    }}>
      <Typography color="white" fontSize={18} fontWeight="bold">
        Password Reset Successful!
      </Typography>
      <Typography color="gray" fontSize={14} mt={1}>
        You can now log in with your new password.
      </Typography>
      <Button
        sx={{ mt: 2, backgroundColor: '#1ED760', color: 'black' }}
        onClick={() => setShowResetPasswordModal(false)}
      >
        OK
      </Button>
    </Box>
  </Fade>
</Modal>
    </Box>
  );
};

export default Forgot;
