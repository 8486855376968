import { Box, Container } from '@mui/material'
import React from 'react'
import Header from './components/header'

const Notification = () => {
  return (
    <Container maxWidth='sm'>
        <Box>
        <Header/>
    </Box>
    </Container>
  )
}

export default Notification