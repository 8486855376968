import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  volume: 60,
}

export const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    setVolume: (state, action) => {
    state.volume =action.payload
    },
   
  }
})

// Action creators are generated for each case reducer function
export const { setVolume} = playerSlice.actions

export default playerSlice.reducer