import * as React from 'react';
import Tabs,{tabsClasses} from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import SongCard from '../pages/song-book/components/song-book';
import SongTab from '../data/song-tab-data';
import axios from 'axios';
import { useEffect,useState } from 'react';
import { url } from '../config/config';
import { SearchContext } from '../pages/song-book';
export default function ScrollableTabsButtonAuto() {
  const [value, setValue] = React.useState(0);
  const [searchValue] = React.useContext(SearchContext);
  const [pagination,setPagination]= useState(0)
  const [songCollab,setSongCollab] = useState([])
  const [loading,setloading] = useState(false)
  const [karaoke,setKaraoke]=useState()
const[genere,Setgenere]= useState('all')
  console.log(searchValue)
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      console.log(scrollTop + windowHeight)
      console.log(documentHeight)
      if (Math.round(scrollTop + windowHeight) >= documentHeight - 1) {
        setPagination(prevPagination => prevPagination + 1)
      }
    };

 if(songCollab.length==0){
  window.addEventListener('scroll', handleScroll);

 }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue)
  };
  useEffect(() => {
    const Getbook = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        };
        const res = await axios.get(`${url}/api/Genere/getGeneresKaroakeFilter`, config);
        console.log(res.data.data, 'Karaokeee......');
        setKaraoke(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    Getbook();
  }, []);
  const[songs,setsongs]=React.useState([])
  React.useEffect(()=>{

    const GetSongs=async()=>{
      setloading(true)

 try {
  const config={
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  }    
  //getKaraokeWeb................
       const res = await axios.get(`${url}/api/Karaoke/getKaraoke?page=${pagination}&genere=${genere}`,config);
       console.log(res,'songDatas')
       if(res.status === 200){
        if(pagination === 0){
          setSongCollab(res?.data?.data)
        }else{
          console.log('pagination')
           setSongCollab((prevValues) => [...prevValues,...res?.data?.data])
        }
       }
       setsongs(res.data.data)
       console.log(res.data.data,"sohegehs");
       
   } catch (error) {
    console.log(error)
   }
   finally{
    setloading(false)
   }
   //    
    }
    GetSongs()
   
  },[pagination,genere])
  console.log(songCollab,'songCollab')
    const filter =  songCollab && songCollab.filter((val) => {
      return val.Name.toLowerCase().includes(searchValue.toLowerCase()) === true 
  })
  console.log(pagination,'paginationsss')
  return (
    <>
    {
      searchValue === '' &&   <Tabs
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable auto tabs example" 
      sx={{height:"2px",[`& .${tabsClasses.scrollButtons}`]: {
        '&.Mui-disabled': { opacity: 0.3 },
      }}}
    >
   {
    //  karaoke && karaoke.map((data,index)=>(
    //       <Tab className='tabs' onClickCapture={()=>{
    //         Setgenere(data)
    //       }} label={data} onClick={()=>{
          
    //       }}  sx={{color: value == index ? 'white':'#8A59FF',backgroundColor:value == index ? '#8A59FF' : "rgb(236, 232, 255)",fontSize:14,textTransform:'none',transition:'.5s ease-in-out',margin:'3px',borderRadius:'20px',minWidth:'40px',minHeight:'40px'}}/>
    //   ))
   }
      
    </Tabs>
    }
    <Box sx={{ width: '100%',mt:3,textAlign:'left'}}>
      <Box >
          
         <SongCard songs={filter} loading={loading}/>
           
        </Box>
    </Box>
    </>
  );
}