import { Box, Card, List, ListItem, ListItemText, Typography } from '@mui/material'
import React from 'react'
import { LuLoader } from 'react-icons/lu'
import { FadeLoader, MoonLoader } from 'react-spinners'

const Terms = () => {
  return (
  <Box>
    
  <Box sx={{ padding: 2, backgroundColor: '#F7F7F7', fontSize: 16 }}>
        <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
        Terms of service
        </Typography>
        <Typography variant="h6" gutterBottom fontWeight={"bold"}>
              Information Collection and Use
            </Typography>
            <Typography variant="body1" paragraph>
              The Application collects information when you download and use it. This information may include information such as:
            </Typography>
  
        <Typography paragraph>
        The Terms of Service for SparkSinger have been updated as of the date above. To review a summary of the changes, click here.
        </Typography>
  
        <Typography paragraph>
        These Terms of Service ("Terms" or "Agreement") govern your use of the SparkSinger app and its related services, including the website, mobile applications ("App" or "Apps"), and any associated online properties owned or controlled by SparkSinger. By using the Service, you agree to comply with these Terms, which also incorporate the Community Guidelines.
        </Typography>
  
        <Typography paragraph>
        "SparkSinger," "we," "our," and "us" refer to the SparkSinger team, while "you" and "your" refer to the individual user. These Terms apply to your access to and use of the Apps, website, and other services provided by SparkSinger ("Service").
        </Typography>
        <Typography variant="h6" gutterBottom fontWeight={"bold"}>
        1. Introduction and Overview
            </Typography>
  
            <Typography variant="body1" paragraph>
            These Terms of Service ("Terms") constitute a legally binding agreement between you and SparkSinger.
            </Typography>
  
            <Typography variant="body1" paragraph>
            By clicking on one of the "Account Creation Options" (e.g., Facebook, Phone Number, Email) or any other account creation methods provided by SparkSinger from time to time, or by registering, accessing, or using the App or Service, you agree to enter into a legally binding contract with SparkSinger. Please read these Terms carefully, as they govern your use of the Service and include important legal provisions that limit our liability. By installing the App or using any part of the Service, you confirm that you have read, understood, and agree to be bound by these Terms and any associated policies.
            </Typography>
            <Typography variant="h6" gutterBottom fontWeight={"bold"}>
            Notice Regarding Arbitration and Dispute Resolution:
            </Typography>
            <Typography variant="body1" paragraph>
            YOU AND SPARKSINGER AGREE THAT ANY FUTURE DISPUTES BETWEEN YOU AND SPARKSINGER WILL BE RESOLVED THROUGH BINDING, INDIVIDUAL ARBITRATION UNLESS YOU OPT OUT AS DESCRIBED IN SECTION 21.i. BY AGREEING TO ARBITRATION, YOU WAIVE YOUR RIGHT TO A JURY TRIAL AND TO PARTICIPATE IN ANY CLASS ACTION OR REPRESENTATIVE PROCEEDING. YOU WILL ONLY BE PERMITTED TO SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, OR DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS. IF YOU WISH TO OPT OUT OF ARBITRATION, YOU MUST FOLLOW THE OPT-OUT PROCEDURE SPECIFIED IN SECTION 21.i.
            </Typography>
            <Typography variant="h6" gutterBottom fontWeight={"bold"}>
            2. Eligibility to Use the Service
            </Typography>
            <Typography variant="body1" paragraph>
            The SparkSinger Service is designed for general audiences. No one under the age of 13 is permitted to use the Service.
            </Typography>
            <Typography variant="body1" paragraph>
            TO USE THE SERVICE, YOU MUST BE AT LEAST 13 YEARS OF AGE OR HAVE THE LEGAL CAPACITY TO ENTER INTO THESE TERMS AS REQUIRED BY THE LAWS OF YOUR JURISDICTION. IF YOU ARE UNDER 13 YEARS OF AGE, OR IF YOU LACK THE LEGAL CAPACITY TO AGREE TO THESE TERMS, YOU MUST NOT ACCESS OR USE THE SERVICE IN ANY WAY.
            </Typography>
            <Typography variant="h6" gutterBottom>
        By registering an account with SparkSinger, you represent and warrant that:
      </Typography>
      <Box sx={{ pl: 2 }}>
        <Typography variant="body1" gutterBottom>
          1. You are of sufficient legal age or otherwise have the legal capacity to agree to these Terms as required by the laws of your country of residence.
        </Typography>
        <Typography variant="body1" gutterBottom>
          2. You will comply with these Terms and all applicable laws and regulations.
        </Typography>
        <Typography variant="body1" gutterBottom>
          3. Your use of the Service does not violate any applicable laws or regulations.
        </Typography>
        <Typography variant="body1" gutterBottom>
          4. You are not a convicted sex offender.
        </Typography>
        <Typography variant="body1" gutterBottom>
          5. You have not been previously suspended or removed from the Service by SparkSinger.
        </Typography>
      </Box>
      <Typography variant="h6" gutterBottom fontWeight={"bold"}>
      3. Changes to These Terms
            </Typography>
            <Typography variant="body1" paragraph>
            We may update these Terms from time to time. If there are material changes, we will notify you by posting the amended terms on the Service at least thirty (30) days before the changes take effect. If you are a registered user, we will also notify you of material changes via email at least thirty (30) days prior to the effective date. To ensure you receive these notices, it is important to keep your email address updated in your account settings.
            </Typography>
            <Typography variant="body1" paragraph>
            If you do not agree with the proposed changes, you should stop using the Service before the new Terms take effect. Please note, our customer service representatives cannot modify these Terms, either verbally or in writing.
            </Typography>
            <Typography variant="h6" gutterBottom fontWeight={"bold"}>
            BY CONTINUING TO USE THE SERVICE AFTER THE CHANGES BECOME EFFECTIVE, YOU AGREE TO THE REVISED TERMS.
            </Typography>
            <Typography variant="h6" gutterBottom fontWeight={"bold"}>
            4. Changes to the Service
            </Typography>
            <Typography variant="body1" paragraph>
            We are constantly improving and evolving the SparkSinger Service. We reserve the right to modify, add, suspend, discontinue, or retire any aspect of the Service at any time without prior notice. If you do not agree with any changes, your sole remedy is to discontinue using the Service.
            </Typography>
            <Typography variant="h6" gutterBottom fontWeight={"bold"}>
            5. Privacy
            </Typography>
            <Typography variant="body1" paragraph>
            The provision of the SparkSinger Service and adherence to these Terms require processing information about you and other users. Protecting your privacy is a priority for us. Please review our Privacy Policy to understand how we collect, use, share, and store your information. The Privacy Policy is an integral part of these Terms. By agreeing to these Terms, you also agree to the terms of our Privacy Policy and consent to the use of your information as outlined.
            </Typography>
            <Typography variant="h6" gutterBottom fontWeight={"bold"}>
            6. Community Guidelines
            </Typography>
            <Typography variant="body1" paragraph>
            To ensure SparkSinger remains a fun, safe, and enjoyable platform for everyone, your use of the Service is subject to our Community Guidelines, which are an integral part of these Terms. Please review and adhere to the guidelines, as failure to comply may result in suspension or termination of your account.
            </Typography>
            <Typography variant="h6" gutterBottom fontWeight={"bold"}>
            7. Your Use of the Service
            </Typography>
            <Typography variant="body1" paragraph>
            SparkSinger provides access to the Service free of charge; however, certain features and functionalities are available exclusively through subscription-based access.
            </Typography>
            <Typography variant="body1" paragraph>
            By using the Service, you acknowledge and agree that SparkSinger may generate revenue, goodwill, or other value from your use of the Service. You have no right to share in any of this revenue, goodwill, or value. Additionally, you acknowledge that SparkSinger is not obligated to compensate you for any User Content (defined in Section 11.a) you create or share on the platform.
            </Typography>
            <Typography variant="h6" gutterBottom fontWeight={"bold"}>
            Subscription Terms:
            </Typography>
            <Typography variant="h6" gutterBottom fontWeight={"bold"}>
            a. Auto-Renewal
            </Typography>
            <Typography variant="body1" paragraph>
            Your subscription includes enrolment in a recurring payment plan. At the end of each subscription period, your subscription will automatically renew for successive periods of equal length unless you cancel your subscription following the cancellation instructions below.
            </Typography>
            <Typography variant="h6" gutterBottom fontWeight={"bold"}>
            b. Recurring Chargesl
            </Typography>
            <Typography variant="body1" paragraph>
            When registering for a paid subscription, you authorize SparkSinger’s payment provider to process payments for renewal subscriptions. Subscription prices, including applicable taxes, will be disclosed at the time of purchase. Your billing cycle for renewal subscriptions will match the cycle of your current subscription. Additional terms and conditions may apply upon renewal, and fees may change at any time, subject to applicable law. If you do not agree to a change in fees, you may cancel your subscription as outlined in Section 7.c below.
            </Typography>
            <Typography variant="h6" gutterBottom fontWeight={"bold"}>
            c. Cancellation
          </Typography>
          <Typography variant="body1" paragraph>
            Your subscription includes enrolment in a recurring payment plan. At the end of each subscription period, your subscription will automatically renew for successive periods of equal length unless you cancel your subscription following the cancellation instructions below.
          </Typography>
          <Typography variant="body1" paragraph>
            You may cancel your subscription at any time before the end of the current billing period using the following methods:
          </Typography>
          <ol className="list-decimal pl-5 mb-4 space-y-2">
            <li>
              <strong>Through the SparkSinger Website:</strong> Log in, navigate to the "Account" screen, and select "Cancel."
            </li>
            <li>
              <strong>Through Google Play:</strong> If you manage your subscription on an Android device.
            </li>
            <li>
              <strong>Through Apple iTunes:</strong> If you manage your subscription on an iOS device.
            </li>
          </ol>
          <Typography variant="body1" paragraph>
            For additional assistance, contact SparkSinger Support at <a href="#" className="text-blue-500 underline">[insert support link]</a>.
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            Cancellation via the SparkSinger website is not effective until confirmed in writing. Once confirmed, you will not be charged for subsequent billing periods.
          </Typography>
          <Typography variant="h6" gutterBottom fontWeight={"bold"}>
            Refund Policy
          </Typography>
          <Typography variant="body1" paragraph>
            SparkSinger is under no obligation to provide refunds for partially used subscription periods. However, you can continue using your subscription until the end of the current period.
          </Typography>
          <Typography variant="h6" gutterBottom fontWeight={"bold"}>
            Special Terms for European Economic Area (EEA) Residents
          </Typography>
          <Typography variant="body1" paragraph>
            If you reside in the EEA, you acknowledge that the Service involves the delivery of digital content not provided on a tangible medium. By purchasing a subscription, you consent to SparkSinger immediately granting access to your account, and as a result, you waive any right of withdrawal under Directive 2011/83/EU.
          </Typography>
          <Typography variant="h6" gutterBottom fontWeight={"bold"}>
          8. Your Account
            </Typography>
            <Typography variant="h6" gutterBottom fontWeight={"bold"}>
            Registration
            </Typography>
            <Typography variant="body1" paragraph>
            While you can browse the public-facing portions of the Service without registering, to access the full benefits of the SparkSinger App, you must download the App or visit the SparkSinger website and create an account ("Account").
            </Typography>
            <Typography variant="h6" gutterBottom fontWeight={"bold"}>
            Account Security
            </Typography>
            <Typography variant="body1" paragraph>
            You are solely responsible for all activities that occur through your Account and should ensure its security. Notify SparkSinger Support immediately if you suspect or know of any unauthorized use of your Account or any security breach. SparkSinger will not be liable for any loss or damage resulting from unauthorized use of your Account.
            </Typography>
            <Typography variant="h6" gutterBottom fontWeight={"bold"}>
            Accuracy of Information
            </Typography>
            <Typography variant="body1" paragraph>
            When creating your Account, you must provide accurate, current, and complete information. You are required to promptly update your information as necessary to maintain its accuracy. By creating an Account, you represent that the information provided is accurate and that any email address you supply is under your control.
            </Typography>
            <Typography variant="h6" gutterBottom fontWeight={"bold"}>
            9. SparkSinger’s Management of the Service; User Misconduct
          </Typography>
          <Typography variant="body1" paragraph>
            SparkSinger reserves the right, without obligation, to:
          </Typography>
          <ul className="list-disc pl-5 mb-4 space-y-2">
            <li>Disallow, cancel, remove, or reassign usernames and permalinks when deemed necessary.</li>
            <li>Suspend or terminate your Account without notice if your actions violate these Terms, damage the Service, infringe on third-party rights, harm SparkSinger’s reputation, or violate any laws or regulations.</li>
            <li>Monitor or review the Service to ensure compliance with these Terms and related policies.</li>
            <li>Report violations to law enforcement or take legal action against individuals who breach these Terms.</li>
            <li>Refuse, restrict, remove, or disable User Content or any part of it at SparkSinger’s sole discretion.</li>
            <li>Manage the Service to protect SparkSinger, its members, and third parties, as well as to ensure the platform's proper functionality.</li>
          </ul>
          <Typography variant="h6" gutterBottom fontWeight={"bold"}>
            Denial of Access
          </Typography>
          <Typography variant="body1" paragraph>
            SparkSinger reserves the right, in its sole discretion and without notice, to deny access to or use of the Service to any person for any reason, including but not limited to violations of these Terms, applicable laws, or any breach of representation, warranty, or covenant outlined herein.
          </Typography>
          <Typography variant="h6" gutterBottom fontWeight={"bold"}>
            No Waiver
          </Typography>
          <Typography variant="body1" paragraph>
            A decision to delay enforcing any provision of these Terms does not waive SparkSinger’s right to enforce that provision or any other provision in the future.
          </Typography>
          <Typography variant="h6" gutterBottom fontWeight={"bold"}>
  10. SparkSinger Content and Rights Granted to You
</Typography>
<Typography variant="body1" paragraph>
  <strong>License</strong>
</Typography>
<Typography variant="body1" paragraph>
  Subject to your ongoing compliance with these Terms, SparkSinger grants you a limited, personal use, 
  revocable, non-exclusive, non-transferable, non-sublicensable, royalty-free, worldwide right, and 
  license to use the Service. Under this license, you may:
</Typography>
<ul>
  <li>
    Download, access, and use an object code version of the App on any device you own or control.
  </li>
  <li>
    Access and use the Service solely for your personal, non-commercial use.
  </li>
</ul>
<Typography variant="body1" paragraph>
  <strong>SparkSinger Content</strong>
</Typography>
<Typography variant="body1" paragraph>
  Except for User Content (as defined in Section 11.a below), all content provided by SparkSinger on or 
  through the Service—including sound recordings (and the musical works embodied therein), videos, text, 
  graphics, photos, software, and interactive features—is protected by copyright or other intellectual 
  property rights and owned by SparkSinger or its third-party licensors (collectively, "SparkSinger Content").
</Typography>
<ul>
  <li>SparkSinger Content is for use exclusively as part of the Service and may not be exported outside of the Service unless explicitly permitted and endorsed by SparkSinger.</li>
  <li>Any attempt to circumvent this provision violates these Terms.</li>
  <li>SparkSinger also retains sole ownership of all design rights, databases, compilations, and other intellectual property rights associated with the Service, whether registered or unregistered, along with any related goodwill.</li>
</ul>
<Typography variant="body1" paragraph>
  <strong>SparkSinger Marks</strong>
</Typography>
<Typography variant="body1" paragraph>
  The trademarks, service marks, and logos used and displayed on the Service (collectively, the "SparkSinger Trademarks") are SparkSinger’s registered or unregistered trademarks or service marks. Any other product and service names displayed on the Service may be trademarks or service marks owned by third parties (together with the SparkSinger Trademarks, the "Trademarks").
</Typography>
<ul>
  <li>You may not use the Trademarks for any purpose unless expressly permitted by law or with SparkSinger’s prior written consent.</li>
  <li>You expressly agree not to use the Trademarks to disparage SparkSinger or any third party, or in any manner that damages goodwill associated with the Trademarks.</li>
  <li>You may not use any Trademarks as part of a hyperlink to or from any website without prior written consent from SparkSinger.</li>
  <li>Any goodwill generated from using a SparkSinger Trademark inures solely to SparkSinger’s benefit.</li>
</ul>
<Typography variant="body1" paragraph>
  <strong>Reservation of Rights</strong>
</Typography>
<Typography variant="body1" paragraph>
  SparkSinger reserves all rights not expressly granted to you in this Section 10. Nothing in these Terms or the Service grants additional license rights to you regarding the Service, SparkSinger Content, or Trademarks.
</Typography>
<Typography variant="h6" gutterBottom fontWeight={"bold"}>
        11. User Content and Rights You Grant to SparkSinger
      </Typography>
      <Typography variant="h6" gutterBottom fontWeight={"bold"}>
        Definition of User Content
      </Typography>
      <Typography>
        "User Content" refers to any content that you or other users create, upload, post, or transmit through the Service (collectively, "Post"), including but not limited to photographs, text, sound recordings, musical works, audiovisual works, and other materials protected by copyright, trademark, patent, or trade secret laws. User Content excludes SparkSinger Content as defined in Section 10.
      </Typography>
      <Typography>
        You may Post User Content and use the Service’s functionalities, provided you comply with these Terms.
      </Typography>
      <Typography variant="h6" gutterBottom fontWeight={"bold"}>
       12. User-Generated Content Safety
      </Typography>

      <Typography variant="h6" gutterBottom fontWeight={"bold"}>
        Agreement to Terms
      </Typography>
      <Typography>
        By accessing and using SparkSinger, you agree to comply with the following terms. We maintain a strict policy against objectionable content and abusive users.
      </Typography>

      <Typography variant="h6" gutterBottom fontWeight={"bold"}>
        No Tolerance for Objectionable Content
      </Typography>
      
          <ListItemText primary="Hate speech, harassment, or discrimination" />
       
          <ListItemText primary="Explicit or sexually inappropriate content" />
      
          <ListItemText primary="Threats, violence, or any form of abusive behavior" />
       
          <ListItemText primary="Misinformation or content that incites harm" />
       
      <Typography>
        Users violating these policies will be subject to immediate content removal and account suspension or termination.
      </Typography>

      <Typography variant="h6" gutterBottom fontWeight={"bold"}>
        End User License Agreement (EULA) Consent
      </Typography>
     
          <ListItemText primary="Users are prohibited from posting or engaging in objectionable behavior." />
        
          <ListItemText primary="Violation of these terms may lead to suspension or permanent banning." />
      

      <Typography variant="h6" gutterBottom fontWeight={"bold"}>
        Filtering Objectionable Content
      </Typography>
      
          <ListItemText primary="Automated content moderation tools for text, images, and videos" />
        
          <ListItemText primary="Human moderation for reported content" />
       
          <ListItemText primary="AI-based detection systems for harmful content" />
       

      <Typography variant="h6" gutterBottom fontWeight={"bold"}>
        Reporting Mechanism for Objectionable Content
      </Typography>
      
          <ListItemText primary="A 'Report' button on posts, comments, and profiles" />
        
          <ListItemText primary="Emailing our support team at support@sparksinger.com" />
       
          <ListItemText primary="In-app reporting forms" />
       

      <Typography variant="h6" gutterBottom fontWeight={"bold"}>
        Blocking Abusive Users
      </Typography>
      
     
          <ListItemText primary="Sending messages" />
        
       
          <ListItemText primary="Commenting on their posts" />
       
       
          <ListItemText primary="Viewing their content" />
        
      
      <Typography>
        Blocked users will no longer be able to interact with the reporting user in any way.
      </Typography>

      <Typography variant="h6" gutterBottom fontWeight={"bold"}>
        Action on Reports within 24 Hours
      </Typography>
    
        
          <ListItemText primary="All reported content will be reviewed within 24 hours." />
     
        
          <ListItemText primary="If content is found to be objectionable, it will be removed immediately." />
        
        
          <ListItemText primary="Users responsible for the content may be warned, suspended, or permanently banned, depending on the severity of the violation." />
    
      

      <Typography variant="h6" gutterBottom fontWeight={"bold"}>
        Enforcement
      </Typography>
     
          <ListItemText primary="Remove any content violating these terms" />
      
          <ListItemText primary="Suspend or ban repeat offenders" />
     
          <ListItemText primary="Report illegal activity to law enforcement authorities" />
       
      <Typography variant="h6" gutterBottom fontWeight={"bold"}>
      13. External Sites
      </Typography>
      <Typography variant="body1" paragraph>
      The Service may include links to or integrations with third-party websites and services ("External Sites"). However, SparkSinger does not endorse or control these External Sites and is not responsible for their content, functionality, or policies. External Sites are provided by third parties and may have their own terms of service and privacy policies that apply to your use of their services. You are encouraged to review the applicable terms and policies of any External Sites you interact with.
</Typography>
<Typography variant="body1" paragraph>
If you choose to access External Sites, make purchases, or subscribe to services offered by these sites, you do so at your own risk. SparkSinger assumes no liability for your use or interactions with any External Sites.
</Typography>
<Box >
      <Typography variant="h6" gutterBottom fontWeight={"bold"}>
        14. Consent to Communications
      </Typography>
      <Typography variant="body1" paragraph>
        By using SparkSinger, you consent to receiving electronic communications, such as emails, push notifications, and text messages, in accordance with our Privacy Policy.
      </Typography>
      <Typography variant="h6">Text (SMS) Notifications:</Typography>
      <Typography variant="body2" component="ul">
        <li>If you provide your phone number, SparkSinger may send you notifications, including promotional messages, via SMS. You can opt out at any time by replying STOP to any message.</li>
        <li>You acknowledge that you are not required to receive promotional messages as a condition for using the Service.</li>
      </Typography>
      <Typography variant="h6">Email Communications:</Typography>
      <Typography variant="body2" component="ul">
        <li>If you provide your email address, SparkSinger may send promotional emails. You can opt out by following the unsubscribe instructions included in these emails.</li>
      </Typography>
      <Typography variant="h6">Push Notifications:</Typography>
      <Typography variant="body2" component="ul">
        <li>Push notifications on your mobile device can be managed through your device settings.</li>
      </Typography>
      <Typography variant="h6">Charges:</Typography>
      <Typography variant="body2" paragraph>
        Standard text messaging and data rates may apply for electronic communications. Check with your mobile carrier for details on fees, including roaming charges if applicable.
      </Typography>
    </Box>
    <Typography variant="h6" gutterBottom fontWeight={"bold"}>
    15. Term and Termination
      </Typography>
      <Typography variant="h6" gutterBottom fontWeight={"bold"}>
      Term:
      </Typography>
      <Typography variant="body1" paragraph>
      This Agreement begins when you start using the Service and remains in effect until terminated by either party.
      </Typography>
      <Typography variant="h6" gutterBottom fontWeight={"bold"}>
      Termination by You:
      </Typography>
      <Typography variant="body1" paragraph>
      You can terminate this Agreement and your use of the Service at any time by notifying SparkSinger Support. Upon termination, your Account and User Content will no longer be accessible. You may request deletion of your User Content by contacting SparkSinger Support.
      </Typography>
      <Typography variant="h6" gutterBottom fontWeight={"bold"}>
      Termination by SparkSinger:
      </Typography>
      <Typography variant="body1" paragraph>
      SparkSinger may terminate this Agreement or restrict, suspend, or terminate your access to the Service at its sole discretion, with or without notice, if you violate these Terms or infringe the rights of others.
      </Typography>
      <Typography variant="h6" gutterBottom fontWeight={"bold"}>
      Survival of Terms:
      </Typography>
      <Typography variant="body1" paragraph>
      Sections 2, 6-7, 9.b, 10.b, 10.c, 10.d, 11, 14-26, 28-32, and all defined terms within those sections, will survive the termination of this Agreement indefinitely.
      </Typography>
      <Typography variant="h6" fontWeight={"bold"}>
          16. Diamonds and Virtual Goods
        </Typography>
        <ol className="list-decimal list-inside space-y-3 text-gray-700">
          <li>
            <Typography variant="body">
              <strong>Diamonds:</strong> SparkSinger may offer you the ability to purchase, receive, or earn virtual Diamonds or other virtual currency ("Diamonds"), which are subject to a limited, revocable, non-exclusive, non-transferable, non-sublicensable license for use exclusively within the Service.
            </Typography>
          </li>
          <li>
            <Typography variant="body">
              Once redeemed, Diamonds will be deducted from your balance and cannot be used again. You cannot reverse a completed transaction. SparkSinger will have the final determination regarding your Diamond account balance.
            </Typography>
          </li>
          <li>
            <Typography variant="body">
              SparkSinger may modify or discontinue certain features that require Diamonds at its sole discretion. The cost of Diamonds may change at any time, including during promotions.
            </Typography>
          </li>
          <li>
            <Typography variant="body">
              Diamonds have no real-world value and cannot be redeemed for currency. All purchases of Diamonds are final and non-refundable.
            </Typography>
          </li>
          <li>
            <Typography variant="body">
              <strong>Virtual Goods:</strong> Diamonds may be redeemed for virtual goods ("Virtual Goods"), such as gifts sent to other users. Virtual Goods are subject to the same limitations as Diamonds.
            </Typography>
          </li>
          <li>
            <Typography variant="body">
              SparkSinger reserves the right to modify or discontinue Virtual Goods at any time.
            </Typography>
          </li>
          <li>
            <Typography variant="body">
              <strong>Limitations:</strong> You agree to pay applicable fees and taxes. Unauthorized sale, transfer, or trade of Diamonds or Virtual Goods outside the Service is prohibited.
            </Typography>
          </li>
          <li>
            <Typography variant="body">
              Violation of these terms may result in account termination. If your account is inactive for 180 days, SparkSinger may terminate your account.
            </Typography>
          </li>
          <li>
            <Typography variant="body">
              <strong>No Ownership:</strong> You acknowledge that you do not own Diamonds or Virtual Goods. SparkSinger is not obligated to provide refunds, and unused Diamonds or Virtual Goods will be lost upon account termination.
            </Typography>
          </li>
        </ol>
      
        <Typography variant="h6" gutterBottom fontWeight={"bold"}>
        17. Notice and Procedure for Making Claims of Copyright or Other Intellectual Property Infringements
      </Typography>
     
      <Typography variant="body1" paragraph>
      SparkSinger respects the intellectual property of others and takes the protection of intellectual property very seriously. We ask you to do the same. Infringing activity will not be tolerated on or through the Service. SparkSinger will terminate the Account of any User who is a repeat infringer, where appropriate.
     </Typography>
      <Typography variant="body1" paragraph>
      If you believe that any content on the Service infringes your intellectual property or the rights of a third party, please review our Copyright and Intellectual Property Infringement Policy here. This policy outlines the process for notifying us about potentially infringing content and how SparkSinger addresses such claims. The Copyright and Intellectual Property Infringement Policy is part of these Terms, and by agreeing to these Terms, you agree to be bound by its provisions.
      </Typography>
      <Typography variant="h6" gutterBottom fontWeight={"bold"}>
      18. Mobile Services
      </Typography>
     
      <Typography variant="body1" paragraph>
      The Service will be accessible via mobile devices, including phones, tablets, or other wireless devices (collectively referred to as "Mobile Services"). Your mobile carrier’s normal messaging, data, and other fees will apply to your use of the Mobile Services.
     </Typography>
      <Typography variant="body1" paragraph>
      Additionally, the downloading, installing, or using of certain Mobile Services may be prohibited or restricted by your mobile carrier. Not all Mobile Services may be compatible with all carriers or devices. Please ensure that your device is compatible with the Mobile Services to avoid interruptions or issues with the Service.
      </Typography>
      <Typography variant="h6" gutterBottom fontWeight={"bold"}>
      19. Warranty Disclaimer
      </Typography>
     
      <Typography variant="body1" paragraph>
      We make no promises, representations, warranties, or guarantees that the Service will operate as intended. Errors or other interruptions may cause the Service to not function as intended. By using the Service, you understand and accept this risk.
     </Typography>
      <Typography variant="body1" paragraph>
      NEITHER SparkSinger NOR ITS AFFILIATES (COLLECTIVELY, "SparkSinger") MAKE ANY WARRANTIES OR REPRESENTATIONS ABOUT THE SERVICE AND ANY CONTENT AVAILABLE ON THE SERVICE, INCLUDING, BUT NOT LIMITED TO, THE ACCURACY, RELIABILITY, COMPLETENESS, APPROPRIATENESS, TIMELINESS, OR RELIABILITY THEREOF. SparkSinger WILL NOT BE SUBJECT TO LIABILITY FOR THE TRUTH, ACCURACY, OR COMPLETENESS OF ANY CONTENT ON THE SERVICE, OR FOR ERRORS, MISTAKES, OR OMISSIONS THEREIN, OR FOR ANY DELAYS OR INTERRUPTIONS OF THE DATA OR INFORMATION STREAM FROM WHATEVER CAUSE. AS A USER, YOU AGREE THAT YOU USE THE SERVICE AND ANY CONTENT THEREON AT YOUR OWN RISK. YOU ARE SOLELY RESPONSIBLE FOR ALL CONTENT YOU UPLOAD TO THE SERVICE.
      </Typography>
      
      <Typography variant="body1" paragraph>
      SparkSinger DOES NOT WARRANT THAT THE SERVICE WILL OPERATE ERROR-FREE, OR THAT THE SERVICE AND ANY CONTENT THEREON ARE FREE OF COMPUTER VIRUSES OR SIMILAR CONTAMINATION OR DESTRUCTIVE FEATURES. IF YOUR USE OF THE SERVICE OR ANY CONTENT THEREON RESULTS IN THE NEED FOR SERVICING OR REPLACING EQUIPMENT OR DATA, NO SparkSinger PARTY WILL BE RESPONSIBLE FOR THOSE COSTS.
     </Typography>
     
     <Typography variant="body1" paragraph>
     TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICE AND ALL CONTENT THEREON ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT ANY WARRANTIES OF ANY KIND. ACCORDINGLY, TO THE FULLEST EXTENT PERMITTED BY LAW, SparkSinger DISCLAIMS ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF TITLE, MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES' RIGHTS, AND FITNESS FOR A PARTICULAR PURPOSE.
     </Typography>
     <Typography variant="h6" gutterBottom fontWeight={"bold"}>
     20. Limitation of Liability
      </Typography>
      <Typography variant="h6" gutterBottom fontWeight={"bold"}>
      TO THE FULLEST EXTENT PERMITTED BY LAW:
      </Typography>
     
      <Typography variant="body1" paragraph>
      SparkSinger, OUR LICENSORS, OUR LICENSEES, AND OUR SERVICE PROVIDERS (COLLECTIVELY, "SparkSinger PARTIES") SHALL NOT BE LIABLE FOR ANY SPECIAL, INDIRECT, PUNITIVE, EXTRAORDINARY, EXEMPLARY, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES WHATSOEVER ARISING OUT OF, RELATING TO, OR RESULTING FROM YOUR USE OR INABILITY TO USE OR ACCESS THE SERVICE, INCLUDING BUT NOT LIMITED TO DAMAGES FOR LOSS OF PROFITS, GOODWILL, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY.
     </Typography>
     <Typography variant="body1" paragraph>
     SparkSinger’S LIABILITY, AND THE LIABILITY OF ANY OTHER SparkSinger PARTIES, TO YOU OR ANY THIRD PARTIES IN ANY CIRCUMSTANCE IS LIMITED TO THE LESSER OF U.S. $100 OR THE TOTAL AMOUNT YOU HAVE PAID SparkSinger FOR SUBSCRIPTION-BASED ACCESS TO THE SERVICE AS OF THE DATE OF THE APPLICABLE CLAIM.
     </Typography>
     <Typography variant="body1" paragraph>
     ANY DISPUTE YOU HAVE WITH ANY THIRD PARTY ARISING OUT OF YOUR USE OF THE SERVICE, INCLUDING, BY WAY OF EXAMPLE AND NOT LIMITATION, ANY CARRIER, COPYRIGHT OWNER, OR OTHER USER, IS DIRECTLY BETWEEN YOU AND THE APPLICABLE THIRD PARTY, AND YOU IRREVOCABLY RELEASE THE SparkSinger PARTIES FROM ANY AND ALL CLAIMS, DEMANDS, AND DAMAGES (ACTUAL AND CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES.
     </Typography>
     <Typography variant="h6" gutterBottom fontWeight={"bold"}>
     21. Governing Law
      </Typography>
      <Typography variant="body1" paragraph>
      This Agreement is governed by the substantive laws of the State of California without regard to its laws that would direct the choice of any other state’s laws.
If you are a consumer who lives in the EU, this Section 20 is without prejudice to any rights you may have under mandatory law which cannot be varied by contractual choice of law.

     </Typography>
     <Typography variant="h6" fontWeight="bold" gutterBottom>
        22. Legal Disputes and Arbitration Agreement
      </Typography>
      <Typography variant="body1" fontWeight="bold">
        This Section 21 does not apply if you reside in the European Economic Area or in another jurisdiction which prohibits mandatory arbitration clauses in consumer contracts.
      </Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>
        <b>Please Read This Following Clause Carefully—It May Significantly Affect Your Legal Rights, Including Your Right to File a Lawsuit in Court</b>
      </Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>
        <b>Initial Dispute Resolution:</b> We are available by email at <b>legal@sparksinger.com</b> to address any concerns you may have regarding your use of the Service. Most concerns may be quickly resolved in this manner. Each of you and we agree to use best efforts to settle any dispute, claim, question, or disagreement directly through consultation and good faith negotiations, which shall be a precondition to either party initiating a lawsuit or arbitration.
      </Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>
        <b>Agreement to Arbitrate Disputes:</b> If we do not reach an agreed-upon solution within a period of thirty (30) days... (continue content as needed)
      </Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>
        <b>Exceptions:</b> Notwithstanding Section 21.b above, nothing in this Agreement will be deemed to waive, preclude, or otherwise limit the right of either party to:
      </Typography>
      <ul>
        <li><Typography variant="body1" component="span"><b>bring an individual action in small claims court;</b></Typography></li>
        <li><Typography variant="body1" component="span"><b>pursue an enforcement action through the applicable federal, state, or local agency if that action is available...</b></Typography></li>
      </ul>
      <Typography variant="body1" sx={{ mt: 2 }}>
        <b>Waiver of Class Actions:</b> YOU AND SparkSinger AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY...
      </Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>
        <b>Waiver of Jury Trial:</b> Except where a jury trial waiver is not permissible under applicable law, YOU AND SparkSinger AGREE TO WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JURY...
      </Typography>
      <Typography variant="h6" gutterBottom fontWeight={"bold"}>
      23. Venue
      </Typography>
      <Typography variant="body1" paragraph>
      To the extent a claim, dispute, or controversy arises out of or in connection with these Terms or your use of the Service that is not subject to mandatory arbitration under Section 21, both you and SparkSinger agree that all such claims and disputes will be litigated exclusively in the United States District Court for the Northern District of California (except for small claims court actions which may be brought where you reside). If that court would lack original jurisdiction over the litigation, then such claims and disputes will be litigated exclusively in the Superior Court of California, County of San Francisco. You and SparkSinger waive any objection to the convenience of both courts and consent to the personal jurisdiction of both courts.

     </Typography>
     <Typography variant="h6" gutterBottom fontWeight={"bold"}>
     24. Time for Filing
      </Typography>
      <Typography variant="body1" paragraph>
      TO THE FULLEST EXTENT PERMITTED BY LAW, YOU AGREE THAT ANY CLAIM AGAINST SparkSinger MUST BE COMMENCED BY FILING A DEMAND FOR ARBITRATION UNDER SECTION 21 (OR BY FILING AN ACTION UNDER SECTION 22 TO THE EXTENT SECTION 21 DOES NOT APPLY) WITHIN ONE YEAR AFTER THE DATE THE PARTY ASSERTING THE CLAIM FIRST KNOWS OR REASONABLY SHOULD KNOW OF THE ACT, OMISSION, OR DEFAULT GIVING RISE TO THE CLAIM. YOU AGREE THAT THERE SHALL BE NO RIGHT TO ANY REMEDY FOR ANY CLAIM NOT ASSERTED WITHIN THAT TIME PERIOD.

     </Typography>
     <Typography variant="h6" gutterBottom fontWeight={"bold"}>
     25. Indemnity
      </Typography>
      <Typography variant="body1" paragraph>
      To the fullest extent permitted by law, you agree to defend, indemnify, and hold SparkSinger harmless from and against any claims, actions, or demands, including, without limitation, reasonable legal and accounting fees, arising or resulting from:
(a) your breach of this Agreement;
(b) your access to, use, or misuse of SparkSinger Content or the Service; or
(c) your User Content.
You will not be required to indemnify and hold us harmless from and against any applicable claims or demands to the extent resulting from SparkSinger’s own negligent conduct.
SparkSinger will provide notice to you of any applicable claim, suit, or proceeding. SparkSinger reserves the right to assume the exclusive defense and control of any matter which is subject to indemnification under this Section if SparkSinger believes that you are unwilling or incapable of defending SparkSinger’s interests. In this case, you agree to cooperate with any reasonable requests assisting SparkSinger’s defense of the applicable matter at your expense.


     </Typography>
     <Typography variant="h6" gutterBottom fontWeight={"bold"}>
     26. Notice to New Jersey Users
      </Typography>
      <Typography variant="body1" paragraph>
      Notwithstanding any terms set forth in these Terms, if any of the provisions set forth in Sections 22, 23, or 24 are held unenforceable, void, or inapplicable under New Jersey law, then any applicable provision shall not apply to you but the rest of these Terms shall remain binding on you and SparkSinger. In addition, for New Jersey residents, the limitation on liability is inapplicable where attorneys’ fees, court costs, or other damages are mandated by statute. Notwithstanding any provision in these Terms, nothing in these Terms is intended to, nor shall it be deemed or construed to, limit any rights available to you under the Truth-in-Consumer Contract, Warranty, and Notice Act.

     </Typography>
     <Typography variant="h6" gutterBottom fontWeight={"bold"}>
     27. Notice to California Users
      </Typography>
      <Typography variant="body1" paragraph>
      Under California Civil Code Section 1789.3, users located in California are entitled to the following consumer rights notice: If a user has a question or complaint regarding the Service, please send an email to legal@sparksinger.com. California residents may reach the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs by mail at 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834, or by telephone at +1 (916) 445-1254 or +1 (800) 952-5210.

     </Typography>
     <Typography variant="h6" gutterBottom fontWeight={"bold"}>
     28. Additional Terms
      </Typography>
      <Typography variant="body1" paragraph>
      From time to time, SparkSinger may require you to agree to additional terms and/or policies that it makes available to you in connection with your use of the Service or in connection with a special promotion ("Additional Terms"). Except as expressly stated otherwise, the applicable Additional Terms are hereby incorporated into and subject to this Agreement. This Agreement will control in the event of any conflict or inconsistency with the Additional Terms to the extent of the conflict or inconsistency.

     </Typography>
     <Typography variant="h6" gutterBottom fontWeight={"bold"}>
     29. Severability
      </Typography>
      <Typography variant="body1" paragraph>
      If any provision of this Agreement is found to be invalid by any court having competent jurisdiction, the invalidity of the applicable provision will not affect the validity of the remaining provisions of this Agreement, which will remain in full force and effect.
     </Typography>
     <Typography variant="h6" gutterBottom fontWeight={"bold"}>
     30. Local Laws
      </Typography>
      <Typography variant="body1" paragraph>
      We control and operate the Service from SparkSinger’s offices in the State of California. SparkSinger makes no representation that materials on the Service are appropriate, lawful, or available for use in any locations other than the United States of America. Those who choose to access or use the Service from locations outside the United States of America, do so on their own initiative and are responsible for compliance with local laws, if and to the extent local laws are applicable. Access to the Service from jurisdictions where the content or practices of the Service are illegal, unauthorized, or penalized is strictly prohibited.

     </Typography>
     <Typography variant="h6" gutterBottom fontWeight={"bold"}>
     31. Notice Regarding Apple
      </Typography>
      <Typography variant="body1" paragraph>
      If you are using the Apps on an iOS-based device, you acknowledge that this Agreement is between you and SparkSinger only, not with Apple, and Apple is not responsible for the Apps or the content thereof. Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the Apps. In the event of any failure of any App to conform to any applicable warranty, then you may notify Apple and Apple will refund the purchase price for the relevant App to you; and, to the maximum extent permitted by applicable law, Apple has no other warranty obligation whatsoever with respect to the Apps. Apple is not responsible for addressing any claims by you or any third party relating to the Apps or your possession and/or use of the Apps, including, but not limited to:
(i) product liability claims;
(ii) any claim that any App fails to conform to any applicable legal or regulatory requirement; and
(iii) claims arising under consumer protection or similar legislation.
Apple is not responsible for the investigation, defense, settlement, and discharge of any third-party claim that any App or your possession and use of any App infringes that third party’s intellectual property rights. You agree to comply with any applicable third-party terms when using the Apps. Apple, and Apple’s subsidiaries, are third-party beneficiaries of this Agreement, and upon your acceptance of this Agreement, Apple will have the right (and will be deemed to have accepted the right) to enforce this Agreement against you as a third-party beneficiary of this Agreement.
You hereby represent and warrant that:
(1) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a "terrorist supporting" country; and
(2) you are not listed on any U.S. Government list of prohibited or restricted parties.


     </Typography>
     <Typography variant="h6" gutterBottom fontWeight={"bold"}>
     32. General
      </Typography>
      <Typography variant="body1" paragraph>
      Translations. If SparkSinger provides a translation of the English language version of this Agreement, then the translation is provided solely for convenience, and the English version will prevail.
Independent Contractors. You agree that no joint venture, partnership, employment, or agency relationship exists between you and SparkSinger as a result of this Agreement or your use of the Service.
Waiver. Failure of SparkSinger to act on or enforce any provision of this Agreement will not be construed as a waiver of that provision or any other provision in this Agreement. No waiver will be effective against SparkSinger unless made in writing, and no applicable waiver will be construed as a waiver in any other or subsequent instance.
Entire Agreement. Except as expressly agreed by SparkSinger and you, these Terms and the policies that are incorporated herein by reference constitute the entire and incorporated agreement between you and SparkSinger with respect to the subject matter hereof, and supersede all previous or contemporaneous agreements, whether written or oral, between the parties with respect to the subject matter herein. You and SparkSinger acknowledge that this agreement is being entered into without reliance upon any representation, warranty, statement or document of any kind whatsoever which is not expressly set forth or referred to herein.
Section Headings. The Section headings herein are provided merely for convenience and will not be given any legal import.
Assignment. This Agreement will inure to the benefit of SparkSinger’s successors and assigns. You may not assign this Agreement or any of the rights or licenses granted hereunder, directly or indirectly, without the prior express written consent of SparkSinger. SparkSinger may assign this Agreement, including all its rights hereunder, without restriction.


     </Typography>
     <Typography variant="h6" gutterBottom fontWeight={"bold"}>
     33. Contact Us
      </Typography>
      <Typography variant="body1" paragraph>
      If you would like to contact SparkSinger in connection with your use of the Service, you may reach out by mail at SparkSinger, Inc, and by email at SparkSinger Support here.

     </Typography>
    
      
      
       
      </Box>
    
  </Box>

  )
}

export default Terms