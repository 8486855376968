import { Box } from "@mui/material";
import React from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from "react-router-dom";

const MoreIconDrawer = ({onClose}) => {

    const navigate = useNavigate();
     return(
        <Box sx={{display:'flex',flexDirection:'column',justifyContent:'left'}}>
           <Box onClick={() => {onClose(false)}}>
                <CancelIcon style={{color:'white',fontSize:'30px',padding:'10px',cursor:'pointer'}}/>
           </Box>
           <Box sx={{display:'flex',flexDirection:'column',padding:'10px 40px',fontSize:'20px',fontWeight:'bold',color:'white',gap:1,cursor:'pointer'}}>
             <Box onClick={() => {
                onClose(false)
                navigate('/explore')
             }}>Explore</Box>
             <Box onClick={() => {
                onClose(false)
                navigate('/song')
             }}>Songbook</Box>
             <Box onClick={()=>{
                 onClose(false)
                 navigate('songupload')
             }}>Uploads</Box>
             <Box onClick={()=>{
               onClose(false)
               navigate('/search/:value')
             }}>Search</Box>
             <Box onClick={()=>{
               onClose(false)
               navigate('/pricing')
             }}>Get Premium</Box>
             <Box onClick={()=>{
               onClose(false)
               navigate('/notification')
             }}>Notification</Box>
             <Box onClick={()=>{
               onClose(false)
               navigate('/settings')
             }}>Settings</Box>
             <Box onClick={() => {
                onClose(false)
                navigate('/profile')
             }}>Profile</Box>
             {/* <Box>Logout</Box> */}
           </Box>
        </Box>
     )
}

export default MoreIconDrawer;