import { Box, Container } from '@mui/material'
import React from 'react'
import Header from './components/header'
import Videocard from './components/video-card'
import Meta from '../../components/meta_data'

const Explore = () => {
  return (
    <>
    <Meta title="Explore all performances and songs with lyrics | SparkSinger:Social Singing Karaoke app" description="SparkSinger is the social singing app that lets you enjoy millions of karaoke songs. Connect with your friends from all over the world or sing with top artists! | SparkSinger Social" keywords="Karoke, Singing" />
      <Container>
    <Box>
    <Box>
    <Header/>
    </Box>
    <Box>
        <Videocard/>
    </Box>

   </Box>   
   </Container>
    </>
  )
}

export default  Explore