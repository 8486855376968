import { Helmet } from "react-helmet";

const Meta = ({ title, description, keywords,image}) => {
  console.log(title,description,keywords,image,'Property values for sharing')
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:type" content="website" />
    </Helmet>
  );
};

export default Meta;