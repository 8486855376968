import { Box, Card, Button, Typography, Modal, Drawer, Divider, Badge, Avatar } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import giftImage from './gift-removebg-preview.png'
import CancelIcon from '@mui/icons-material/Cancel';
import { BsCoin } from "react-icons/bs";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useParams } from "react-router-dom";
import axios from "axios";
import { url } from "../../../config/config";
import { dark } from "@mui/material/styles/createPalette";
import diment from '../../../assest/images/diamonds_100 (1).png'
import songesss from '../../../assest/images/premiumIcon.png'
import { GiFastBackwardButton, GiFat } from "react-icons/gi";
import { ArrowBackIosNewRounded, ArrowDownwardRounded, ArrowForwardIosRounded, ArrowUpwardRounded, Forward } from "@mui/icons-material";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'white',
  boxShadow: 24,
};

const Module = ({ func, data, user, count }) => {

  const params = useParams();
  console.log(params, 'hsjisihsh');

  const [text, setText] = useState('')
  const [choosed, setChoosed] = useState(true)
  const [wallet, setWallet] = useState({});
  const [countGift, setCountGift] = useState(0)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [freegift, setFreegift] = useState()
  console.log(user[0]?.users[0]?.isPremium, "joknknjb");
  // console.log(freegift[0]?.GiftImageURL,"hihii");


  const gifts = [
    {
      image: giftImage,
      coins: 42
    },
    {
      image: giftImage,
      coins: 32
    },
    {
      image: giftImage,
      coins: 16
    }, {
      image: giftImage,
      coins: 90
    }, {
      image: giftImage,
      coins: 23
    }, {
      image: giftImage,
      coins: 78
    }, {
      image: giftImage,
      coins: 23
    },
  ]
  console.log(data, 'giftDatassssssss')
  const [giftData, setGiftData] = useState('')
  const [giftInventory, setGiftInventory] = useState([]);
  const [selectedGift, setSelectedGift] = useState(null);
  const [freegifts,setFreegifts] =useState([])
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(""); // Success/Error message
  const scrollRef = useRef(null);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -140, behavior: "smooth" });
    }
  };
  
  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 140, behavior: "smooth" });
    }
  };
  const handleChoose = (ind) => {
    setChoosed(false)
    setGiftData(ind)
    setSelectedGift(freegift[ind])
    console.log(ind, "clickkkdjd");
    console.log(freegift[ind], "gift");
  }

  useEffect(() => {
    handleGetFreeGifts()
  }, [])
  


  const handleGetFreeGifts = async () => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
      console.log(`${url}/api/Gift/send`, 'handleSend Gift');

      const res = await axios.get(`${url}/api/Gift/getGiftByCategory?Category=FREE`, config);
      console.log(res.data.data,'freeGiftsData');
      setFreegifts(res.data.data)
      

    } catch (error) {
      console.error(error, 'error getting');

    }
  }
  const handleGift = async () => {
    try {
      const giftValues = {
        songCollaborationId: params.id,
        giftId: allGifts[giftData]._id,
        description: text,
        // ToUserId: Array(user[0].users[0]._id)
      }
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
      console.log( giftValues, 'handleSend Gift');

      const res = await axios.post(`${url}/api/Gift/send`, giftValues, config);
      console.log(res, "ihuuigigu")
    
      if (res.status === 200) {
        setModalMessage("🎉 Gift sent successfully!");
      } else {
        setModalMessage("❌ Failed to send gift. Please try again.");
      }
    } catch (error) {
      console.log(error);
      setModalMessage("❌ Something went wrong. Please try again.");
    }
    setOpenModal(true); // Show modal
    setChoosed(true)
    count(1)
  }

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // console.log(user)
  useEffect(() => {
    const GetWallet = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/Wallet/getWalletBalance`, config)
        console.log(res.data, "getWalletBalance....")
        setWallet(res.data)

      } catch (error) {
        console.log(error, 'errrors')
      }
    }
    GetWallet()
  }, [])
  useEffect(() => {
    const GetWallet = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/Inventory/getGiftInventory`, config)
        console.log(res.data, "setGiftinventory....")
        setGiftInventory(res.data)

      } catch (error) {
        console.log(error, 'errrors')
      }
    }
    GetWallet()
  }, [])

  useEffect(() => {
    const GetFreeGF = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/Gift/getGift`, config)
        console.log(res.data.data,
          "setFreegift....")
        setFreegift(res.data.data)

      } catch (error) {
        console.log(error, 'errrors')
      }
    }
    GetFreeGF()
  }, [])

  const allGifts = [...freegifts, ...giftInventory.flatMap(item => item.Gifts.map(g => g.Gift))];
  // console.log(allGifts,"jdjdjdjdjdj");
  

  return (

    <Box >
      <Card className="gift-container" sx={{   width: '100%', maxWidth: '700px',  height: screenWidth < 510 ? '100%' : "480px", marginTop: '38px', display: screenWidth > 16 ? 'block' : 'none', overflowX: 'auto', background: 'linear-gradient(to right, #CA24E7 20%,#7535F8 100%)' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px', backgroundColor: "#FFFFFF", }}>
          <Box>
            <Box sx={{ display: 'flex', gap: 1, }}>
              {
                choosed ? '' : <Box onClick={() => setChoosed(true)}><ArrowBackIosNewIcon style={{ color: 'rgb(192, 204, 216)' }} /></Box>
              }
              <Box sx={{ color: 'rgb(45, 40, 103)', fontSize: '17px', fontWeight: 700, marginBottom: '5px' }}>Send a Gift</Box>
            </Box>
            <Box sx={{ color: "#84BBE5", fontSize: '14px', fontWeight: 500, marginLeft: choosed ? '' : '30px' }}>Show appreciation</Box>
          </Box>
          <Box onClick={() => func(true)} sx={{ marginTop: '5px' }}><CancelIcon style={{ fontSize: '30px', color: 'rgb(192, 204, 216)', cursor: 'pointer' }} /></Box>
        </Box>
        <Divider />
        {
          choosed ? <Box sx={{ background: 'linear-gradient(to right, #CA24E7 20%,#7535F8 100%)', height: '100%' }}>
            <Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px', fontWeight: 'bold', color: 'white' }}>
                <Box>Choose a Sticker:</Box>
                <Box sx={{ display: 'flex', background: '#7262E7', padding: '5px 10px', borderRadius: '10px', gap: 1,ml:30 }}>
                  <Box>{wallet?.AvailableBalance}</Box>
                  <Box><img src={diment} size={10} style={{ width: 20 }} /></Box>
                </Box>
              </Box>
            </Box>
            <Box
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
   
  }}
>


  {/* Scrollable Container */}
  <Box
  sx={{
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)', // 3 images per row
    gap: 2,
    overflowY: 'auto', // Enables vertical scrolling
    overflowX: 'hidden',
    height: '380px', // Adjust to fit two rows
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px'
  }}
>
  {
    allGifts?.map((val, ind) => (
      <Card key={ind} onClick={() => handleChoose(ind)} 
        sx={{
          width: '120px',
          height: '140px',
          textAlign: 'center',
          alignItems: 'center',
          cursor: "pointer",
        }}
      >
        <img style={{ width: '76px', height: '76px', marginTop: '10px' }} src={val.GiftImageURL} alt="Gift" />
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
          <p style={{ color: 'rgb(45, 40, 103)' }}>
            {val.Price === 0 ? "Free" : val.Price}
          </p>
          {val.Price !== 0 && (
            <Box sx={{ marginTop: '17px' }}>
              <BsCoin color="rgb(255, 206, 66)" size={20} />
            </Box>
          )}
        </Box>
      </Card>
    ))
  }
</Box>
  {/* Right Scroll Button */}
 
</Box>
          </Box> : <Box sx={{ display: 'flex', borderTop: '2px solid #EAE9F0', gap: 2 }}>
            <Box sx={{ background: 'linear-gradient(rgb(240, 246, 255) 0%, rgb(255, 255, 255) 100%)', height: '100vh', padding: '10px', textAlign: 'center' }}>
              <img style={{ width: '120px', height: '120px', marginTop: '90px' }} src={allGifts[giftData]?.GiftImageURL} />

              <Typography sx={{ width: '200px', height: '50px', background: 'rgb(255, 206, 66)', padding: '10px', borderRadius: '10px', marginTop: '100px' }}>Not enough coins in your wallet.</Typography>
            </Box>
            <Box sx={{ marginTop: '70px', textAlign: 'center' }}>
              <Box sx={{ color: 'white', paddingBottom: '5px' }}>Send the gift to</Box>
              <Box>
                <Box>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                      user[0]?.users[0]?.isPremium ? (  // Show only if isPremium is true
                        <Avatar
                          sx={{ bgcolor: 'red', width: 25, height: 25, fontSize: 10, border: '2px solid white' }}
                          alt="Premium User"
                          src={songesss} // Ensure this is the correct image
                        />
                      ) : null  // Hide if isPremium is false
                    }
                  >
                    <img style={{ width: '64px', height: '64px', borderRadius: '50%' }} alt="loading" src={user && user[0].users[0].ProfilePhoto} /></Badge></Box>
                <Box sx={{ mt: 3 }}> <p style={{ marginTop: '0', color: "white" }}>{user[0]?.users[0]?.Name}</p></Box>
                <input onChange={(e) => { setText(e.target.value) }} placeholder="Say something nice" maxLength={50} style={{ display: 'block', width: '292px', height: '40px', background: 'rgb(251, 251, 251)', border: '1px solid rgb(228, 233, 238)', borderRadius: '6px' }}></input>
                <Button variant="contained"
                  sx={{
                    width: '294px',
                    height: '40px',
                    // backgroundColor: selectedGift?.Price === 0 ? 'rgb(108, 46, 255)' : 'rgb(192, 192, 192)',
                    backgroundColor: 'gray' ,
                    color: 'white',
                    fontWeight: 'bold',
                    marginTop: '20px',
                    // cursor: selectedGift?.Price === 0 ? 'pointer' : 'not-allowed',
                    cursor:"pointer"
                  }}
                  onClick={handleGift}
                  // disabled={selectedGift?.Price !== 0} // Disable button if price is not zero
                >
                  Send
                </Button>
              </Box>
            </Box>
          </Box>
        }
      </Card>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "white",
            borderRadius: "10px",
            boxShadow: 24,
            p: 3,
            textAlign: "center",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold", color: modalMessage.includes("successfully") ? "green" : "red" }}>
            {modalMessage}
          </Typography>
          <Button
            variant="contained"
            sx={{ marginTop: "15px", backgroundColor: "#7535F8" }}
            onClick={() => setOpenModal(false)}
          >
            OK
          </Button>
        </Box>
      </Modal>
      <Modal style={{ overflowY: 'auto' }}
        open={screenWidth < 996 && screenWidth > 510 ? true : false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Card className="gift-container" sx={{ width: '480px', height: '480px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
              <Box>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  {
                    choosed ? '' : <Box onClick={() => setChoosed(true)}><ArrowBackIosNewIcon style={{ color: 'rgb(192, 204, 216)' }} /></Box>

                  }
                  <Box sx={{ color: 'rgb(45, 40, 103)', fontSize: '17px', fontWeight: 700, marginBottom: '5px' }}>Send a Gift</Box>
                </Box>
                <Box sx={{ color: 'rgb(114, 140, 166)', fontSize: '14px', fontWeight: 500, marginLeft: choosed ? '' : '30px' }}>Show appreciation</Box>
              </Box>
              <Box onClick={() => func(true)} sx={{ marginTop: '5px' }}><CancelIcon style={{ fontSize: '30px', color: 'rgb(192, 204, 216)', cursor: 'pointer' }} /></Box>
            </Box>
            {
              choosed ? <Box sx={{ background: 'linear-gradient(to right, #CA24E7 20%,#7535F8 100%)', height: '100%' }}>
                <Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px', fontWeight: 'bold', color: 'white' }}>
                    <Box>Choose a Sticker:</Box>
                    <Box sx={{ display: 'flex', background: '#7262E7', padding: '5px 10px', borderRadius: '10px', gap: 1 }}>
                      <Box>0</Box>
                      <Box><BsCoin color="rgb(255, 206, 66)" size={20} /></Box>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "left", whiteSpace: 'nowrap', flex: 1, textAlign: 'left', gap: 1, padding: '10px', overflowY: 'scroll' }}>
                  <Card>
                  {
                    freegifts && freegifts.map((val, ind) => (
                      <Card onClick={() => { handleChoose(ind) }} sx={{ width: '130px', height: '150px', marginBottom: '10px', textAlign: 'center', alignItems: 'center' }}>
                        <img style={{ width: '76px', height: '76px', marginTop: '10px' }} src={val.GiftImageURL} />
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                          <p style={{ color: 'rgb(45, 40, 103)' }}>25</p>
                          <Box sx={{ marginTop: '17px' }}><BsCoin color="rgb(255, 206, 66)" size={20} /></Box>
                        </Box>
                      </Card>
                    ))
                  }</Card>
                </Box>
              </Box> : <Box sx={{ display: 'flex', borderTop: '2px solid #EAE9F0', gap: 2 }}>
                <Box sx={{ background: 'linear-gradient(rgb(240, 246, 255) 0%, rgb(255, 255, 255) 100%)', height: '100vh', padding: '10px', textAlign: 'center' }}>
                  <img style={{ width: '120px', height: '120px', marginTop: '90px' }} src={freegifts && freegifts[giftData]?.GiftImageURL} />
                  <Typography sx={{ width: '200px', height: '50px', background: 'rgb(255, 206, 66)', padding: '10px', borderRadius: '10px', marginTop: '100px' }}>Not enough coins in your wallet.</Typography>
                </Box>
                <Box sx={{ marginTop: '70px', textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ color: 'rgb(114, 140, 166)', paddingBottom: '5px' }}>Send the gift to</Box>
                  <Box>
                    <img style={{ width: '64px', height: '64px', borderRadius: '50%' }} alt="loading" src={user && user[0].users[0].ProfilePhoto} />
                    <p style={{ marginTop: '0', color: 'rgb(45, 40, 103)' }}>{user && user[0].users[0].Email}</p>
                    <input onChange={(e) => { setText(e.target.value) }} placeholder="Say something nice" style={{ display: 'block', width: '100%', height: '40px', background: 'rgb(251, 251, 251)', border: '1px solid rgb(228, 233, 238)', borderRadius: '6px' }}></input>
                    <Button sx={{ width: '100%', height: '40px', backgroundColor: 'rgb(108, 46, 255)', color: 'white', fontWeight: 'bold', marginTop: '20px' }} onClick={handleGift}>Send</Button>
                  </Box>
                </Box>
              </Box>
            }

          </Card>
        </Box>
      </Modal>
      <Drawer anchor='bottom'
        open={screenWidth < 510 ? true : false}
        PaperProps={{
          sx: {
            backgroundColor: 'white',
          }
        }}
      >
        <Card className="gift-container" sx={{ width: '100%', height: '480px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
            <Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                {
                  choosed ? '' : <Box onClick={() => setChoosed(true)}><ArrowBackIosNewIcon style={{ color: 'rgb(192, 204, 216)' }} /></Box>

                }
                <Box sx={{ color: 'rgb(45, 40, 103)', fontSize: '17px', fontWeight: 700, marginBottom: '5px' }}>Send a Gift</Box>
              </Box>
              <Box sx={{ color: 'rgb(114, 140, 166)', fontSize: '14px', fontWeight: 500, marginLeft: choosed ? '' : '30px' }}>Show appreciation</Box>
            </Box>
            <Box onClick={() => func(true)} sx={{ marginTop: '5px' }}><CancelIcon style={{ fontSize: '30px', color: 'rgb(192, 204, 216)', cursor: 'pointer' }} /></Box>
          </Box>
          {
            choosed ? <Box sx={{ background: 'linear-gradient(to right, #CA24E7 20%,#7535F8 100%)', height: '100%' }}>
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px', fontWeight: 'bold', color: 'white' }}>
                  <Box>Choose a Sticker:</Box>
                  <Box sx={{ display: 'flex', background: '#7262E7', padding: '5px 10px', borderRadius: '10px', gap: 1 }}>
                    <Box>0</Box>
                    <Box><BsCoin color="rgb(255, 206, 66)" size={20} /></Box>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "left", whiteSpace: 'nowrap', flex: 1, textAlign: 'left', gap: 1, padding: '10px', overflowY: 'scroll' }}>
                {
                  freegifts && freegifts.map((val, ind) => (
                    <Card onClick={() => { handleChoose(ind) }} sx={{ width: '130px', height: '150px', marginBottom: '10px', textAlign: 'center', alignItems: 'center' }}>
                      <img style={{ width: '76px', height: '76px', marginTop: '10px' }} src={val.GiftImageURL} />
                      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                        <p style={{ color: 'rgb(45, 40, 103)' }}>25</p>
                        <Box sx={{ marginTop: '17px' }}><BsCoin color="rgb(255, 206, 66)" size={20} /></Box>
                      </Box>
                    </Card>
                  ))
                }
              </Box>
            </Box> : <Box sx={{ display: 'flex', borderTop: '2px solid #EAE9F0', gap: screenWidth < 510 ? 0.5 : 2, flexDirection: screenWidth < 510 ? 'column' : 'row', overflowY: 'scroll', width: '100%', height: '100%' }}>
              <Box sx={{ background: 'linear-gradient(rgb(240, 246, 255) 0%, rgb(255, 255, 255) 100%)', height: '100vh', padding: '10px', textAlign: 'center', display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>
                <img style={{ width: '120px', height: '120px', marginTop: screenWidth < 510 ? '10px' : '90px' }} src={freegifts && freegifts[giftData]?.GiftImageURL} />
                <Typography sx={{ width: '200px', height: '50px', background: 'rgb(255, 206, 66)', padding: '10px', borderRadius: '10px', marginTop: screenWidth < 510 ? '20px' : '100px' }}>Not enough coins in your wallet.</Typography>
              </Box>
              <Box sx={{ marginTop: '70px', textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ color: 'rgb(114, 140, 166)', paddingBottom: '5px' }}>Send the gift to</Box>
                <Box>
                  <img style={{ width: '64px', height: '64px', borderRadius: '50%' }} alt="loading" src={user && user[0].users[0].ProfilePhoto} />
                  <p style={{ marginTop: '0', color: 'rgb(45, 40, 103)' }}>{user && user[0].users[0].Email}</p>
                  <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <input onChange={(e) => { setText(e.target.value) }} placeholder="Say something nice" style={{ width: '50%', height: '40px', background: 'rgb(251, 251, 251)', border: '1px solid rgb(228, 233, 238)', borderRadius: '3px' }}></input>
                  </Box>
                  <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <Button sx={{ width: '50%', height: '40px', backgroundColor: 'rgb(108, 46, 255)', color: 'white', fontWeight: 'bold', marginTop: '20px', marginBottom: screenWidth < 510 ? '70px' : "" }} onClick={handleGift}>Send</Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          }

        </Card>
      </Drawer>
    </Box>
  )
}

export default Module