import React, { useRef, useState } from 'react'
import Player from './player'
// import TextAreaWithInputs from '../../../components/text-area'
import { Box, Button, Card, Checkbox, Container, Divider, Input, Typography,Modal ,styled, TextField, createTheme, ThemeProvider, CircularProgress, Slider, MenuItem, Menu, IconButton } from '@mui/material'
import PropTypes, { func, string } from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ArrowBack, ArrowForward, Favorite, FavoriteBorder } from '@mui/icons-material';
import Crop54Icon from '@mui/icons-material/Crop54';
import RectangleRoundedIcon from '@mui/icons-material/RectangleRounded';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useSelector,useDispatch } from 'react-redux'
import { AddColorPalette, AddSingerProperty, Lyrics, singerTypeChoosing } from '../../../features/addlyric/addLyric';
import { useNavigate } from 'react-router-dom';
import { deleteLyrics } from '../../../features/addlyric/addLyric';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { editLyric } from '../../../features/addlyric/addLyric';
import { editSinger } from '../../../features/addlyric/addLyric';
import { SketchPicker } from 'react-color';
import Grid from '@mui/material/Grid';
import { Saturation,Hue,useColor,ColorPicker } from 'react-color-palette';
import { ImUserPlus } from "react-icons/im";
import RestartAltTwoToneIcon from '@mui/icons-material/RestartAltTwoTone';
import { FaQuestionCircle } from "react-icons/fa";
import { HiPencil } from "react-icons/hi";
import { RiDeleteBin6Line } from "react-icons/ri";
import "react-color-palette/css";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { FaBackwardFast, FaForwardFast } from 'react-icons/fa6';
import { IoIosPause } from 'react-icons/io';
import { HiMiniSpeakerWave } from 'react-icons/hi2';
import { PiSpeakerSimpleSlashFill } from 'react-icons/pi';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import image1 from "../../../assest/images/Duet.gif"  
import image2 from "../../../assest/images/Your paragraph text.gif"
import vieo3 from '../../../assest/videos/sparksinger 1.mp4'
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize:'14px',
    fontWeight:700,
    padding:'4px'
  },
}));

const toolTipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "white",
          boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
        }
      }
    }
  }
})

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    padding:'0',
    margin:'0'
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: '5px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AntTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: 'rgb(136 38 251)',
    height:'5px'
  },
});



const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#635ee7',
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#fff',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  }),
);


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  height : 600,
  bgcolor: 'black',
  border: '2px solid #000',
  borderRadius : '5px',
  boxShadow: 24,
  p: 4,
  overflow : 'scroll',
  textAlign:'center',
  overflowX : 'hidden'
};

const Songlyrics = () => {
  const [value, setValue] = React.useState(0);
const[lyric,setlyric]=useState('')
const[singer,setsinger]=useState(0)
const[lyricArray,setlyricArray]=useState([])
const value1 = useSelector((state) => state)
const EditColor = value1.Upload.colorMapping;
const [songType,setSongType] = useState('')
const [open,setOpen] = useState(false)
const [tabValue,setTabValue] = useState(0);
const [lyricPartsControl,setLyricPartsControl] = useState(4)
const [tabHeaderControler,setTabHeaderControler] = useState(50)
const [lyricHeader,SetLyricHeader] = useState([1,2,3,4])
const [lyricHeaderName,setLyricHeaderName] = useState([])
const [headerName,setHeaderName] = useState('')
const [tabsHandler,setTabsHandler] = useState(
  {
    tebEdited : false,
    tabDeleted : false
  }
)
const [volume, setVolume] = useState(50);
const [icon,setIcon] = useState(false)
const playerRef = useRef();
const scrollRef = useRef();
const [tabHeaderInd,setTabHeaderInd] = useState('');
 const [speakerController,setSpeakerController] = useState(false);
 const [language, setLanguage] = useState('en');
 const [isResetModalOpen, setIsResetModalOpen] = useState(false);

  const openResetModal = () => setIsResetModalOpen(true);
  const closeResetModal = () => setIsResetModalOpen(false);
console.log(value1)
  const navigate=useNavigate()
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  // const [singer1Show,setSinger1Show] = useState(false)
  // const [singer]
  let nextId = 0;
  const languages = [
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Spanish' },
    { value: 'fr', label: 'French' },
    { value: 'de', label: 'German' },
    { value: 'zh', label: 'Chinese' },
    { value: 'ta', label: 'Tamil' },
    { value: 'hi', label: 'Hindi' },
    { value: 'bn', label: 'Bengali' },
    { value: 'te', label: 'Telugu' },
    { value: 'mr', label: 'Marathi' },
    { value: 'gu', label: 'Gujarati' },
    { value: 'ur', label: 'Urdu' },
    { value: 'kn', label: 'Kannada' },
    { value: 'ml', label: 'Malayalam' },
    { value: 'pa', label: 'Punjabi' },
    { value: 'or', label: 'Odia' },
    { value: 'as', label: 'Assamese' },
    { value: 'mai', label: 'Maithili' },
    { value: 'sd', label: 'Sindhi' },
  ];
  const handleChanges = (event) => {
    setLanguage(event.target.value);
  };
  const HandleSubmit=()=>{
    setlyric('')
    
    dispatch(Lyrics( { singerIndex:singer, lyric: lyric,startTime:'',endTime:'',color:color,trackIndex:0,lyricType:0 }))
    
   
  }

  const min = scrollRef.current ? scrollRef.current.min : ''
  const max = scrollRef.current ? scrollRef.current.max : ''
   const values = scrollRef.current ? scrollRef.current.value : ''
   if(scrollRef.current != null ){
    scrollRef.current.style.background = `linear-gradient(to right, #1dca7e 0%, #1dca7e ${(values-min)/(max-min)*100}%, #797979 ${(values-min)/(max-min)*100}%, #797979 100%)`
    scrollRef.current.oninput = function() {
      this.style.background = `linear-gradient(to right, #1dca7e 0%, #1dca7e ${(this.values-this.min)/(this.max-this.min)*100}%, #797979 ${(this.values-this.min)/(this.max-this.min)*100}%, #797979 100%)`
      console.log('vijay')
    };
   }

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: 'rgb(114, 140, 166)',
    fontWeight: 700,
    padding:tabValue === 0 ? '50px' : `${tabHeaderControler}px`,
    paddingTop:'10px',
    paddingBottom:'10px',
    fontSize:'14px',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&.Mui-selected': {
      color: 'rgb(45, 40, 103)',
      fontWeight: 700,
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgb(45, 40, 103)',
    },
  }));
    const maxSound = 100;
   const [color,setColor] = useColor('#6699CC')
   const [singer2Color,setSinger2Color] = useColor('#3CB371')
   const [bothColor,setBothColor] = useColor('#FFAC1C')
   const [singer1Palette,setSinger1Palette] = useState(false)
   const [singer2Palette,setSinger2Palette] = useState(false)
   const [bothPalette,setBothPalette] = useState(false)
  const audio = useSelector((state) => state)
  const [showSlider, setShowSlider] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // For menu placement
  const [selectedSpeed, setSelectedSpeed] = useState("Normal"); // Default speed
  const [tabModalDisplay,setTabModalDisplay] = useState(true)

  // console.log(color,singer2Color,bothColor,EditColor[0])

  const dispatch =useDispatch()
 const HandlSubmit=()=>{
  navigate('/uploads/songtiming')
  // dispatch(Lyrics(lyricArray))
  const data ={
  color : color.hex,
  singer2Color : singer2Color.hex,
  bothColor : bothColor.hex
  }
  dispatch(AddColorPalette(data))
 }
 const [selectedValue, setSelectedValue] = React.useState('a');

 const handleChange = (event) => {
   setSelectedValue(event.target.value);
 };
 
 const handleTabChange = (event,tabValue) => {
    setTabValue(tabValue)
 }

  const deleteLyric=(index)=>{
    dispatch(deleteLyrics(index))
  }
  const editLyrics=(index,lyric)=>{
    const data={
      index:index,
      lyric:lyric
    }
    dispatch(editLyric(data))
  }
  const editSingers=(index,singer)=>{
    console.log(index,singer)
    const data={
      index:index,
      singer:singer
    }
    dispatch(editSinger(data))
  }
  console.log(audio.Upload,'DtaValuessssss')
  const handleColorPalette = async (val,num,id) => {
    console.log(val,num,id)
      let SingerData = {
        SingerId : num,
        SingerColor : val,
        singerIndex : id
      }
      dispatch(AddSingerProperty(SingerData))
  }

  audio.Upload.TextfileLyrics.map((val,ind) => {
    if(ind == val.singerIndex){
      console.log(val.singerType)
    }
  })
  const [lyricsArray, setLyricsArray] = useState([]); // State to store lyrics as objects with index and lyric

  const handleFilelyricChange = (ind, text) => {
    console.log(text, "letters Valuesss");
    
    if (text.length > 40) {
      alert("Lyric should not exceed 40 characters");
      return; // Exit the function to prevent further updates
    }
  };



  const handleCheck = (val) => {
    setSongType(val)
    const data = {
      singerIndex : 0,
      singerType : color.hex
    }
    dispatch(singerTypeChoosing(data))
  }

  for(let i = 0 ; i > audio.Upload.TextfileLyrics.length ; i++){
    
    console.log(audio.Upload.TextfileLyrics[i].Sentence.split(' '))
  }
 console.log(localStorage.getItem('edit'))

 const handleClose = () => {
  setOpen(false)
 }

 const Icons = [
  {
    id:1,
    UserIcon : <Box>
          <ImUserPlus fill='rgb(119 119 119)' style={{cursor:'pointer',padding:'3px',opacity:tabValue === 0 && '0.4'}} size={20}/></Box>
  },
  {
    id : 2,
    RedoIcon :  <Box><RestartAltTwoToneIcon style={{fontSize:'27px',padding:'3px',color:'rgb(119 119 119)',cursor:'pointer'}}/></Box>
  },
  {
    id : 3,
    QuestionIcon :  <Box><FaQuestionCircle fill='rgb(119 119 119)' size={20} style={{cursor:'pointer',padding:'3px'}}/></Box>
  }
 ]

 const handleGroupController = () => {
  if(lyricHeader.length <= 7){
    SetLyricHeader((prevState) => [
      ...prevState,
      prevState.length  + 1,
    ])
    setTabHeaderControler(tabHeaderControler + 12)
  }
 }
 const str_pad_left = (string,pad,length) => {
  return (new Array(length+1).join(pad)+string).slice(-length);
}
 console.log(lyricHeader.length,'headerLength')
 const handleDeletePart = (ind) => {
  SetLyricHeader((prevHeader) =>
    prevHeader
      .filter((value, index) => index !== ind) // Remove the clicked index
      .map((value, i) => 
        typeof value === 'number' && i >= ind ? value - 1 : value // Only decrement numeric values
      )
  );
  setTabHeaderControler(tabHeaderControler - 12)
};
console.log(lyricHeaderName,'lyricHeaderName')

const handleHeaderChange = (ind) => {
   console.log(ind,'clickableValiessss')
   setLyricHeaderName((prevState) => {
    // Check if the key already exists in the state
    const indexExists = prevState.some((item) => item.key === ind);

    if (indexExists) {
      // Update the existing object
      return prevState.map((item) =>
        item.key === ind ? { key: ind, name: headerName } : item
      );
    } else {
      // Add a new object
      return [...prevState, { key: ind, name: headerName }];
    }
  });
  SetLyricHeader((prevState) =>
    prevState.map((value, index) =>
      index === ind ? headerName : value // Replace the clicked index with the first letter of `headerName`
    )
  );
   setTabsHandler((prevState) => ({
  ...prevState,
  tebEdited : false
 }))
 
}
const [opens, setOpens] = useState(false);
const [audioTime,setAudioTime] = useState('')
const audioDuration = playerRef.current ? Math.floor(playerRef.current.duration) : 0;
const currentValue = Math.floor(audioTime);
const minutes = Math.floor(audioDuration / 60);
const seconds = Math.floor(audioDuration - minutes * 60);
const currentTimeVaue = str_pad_left(minutes,'0',2) + ':' + str_pad_left(seconds,'0',2);
const currentminutes = Math.floor(audioTime/ 60);
const currentSeconds = Math.floor(audioTime - currentminutes * 60)
const currentRunning =  str_pad_left(currentminutes,'0',2) + ':' + str_pad_left(currentSeconds,'0',2);
const media = [
  { type: 'video', src: vieo3,heading:"Click-and-drag to select/deselect multiple lines of lyrics for a singer." },
  { type: 'image', src: image1,heading:"If you don't arrange your song for duet or group singers, don't worry - we'll create a default experience for them." },
  { type: 'image', src: image2 ,heading:"You can add up to 8 singers for a group. You can even rename parts to keep track of them."},
  
];

// currentIndex tracks which image is currently visible.
const [currentIndex, setCurrentIndex] = useState(0);
const handlePrev = () => {
  setCurrentIndex((prevIndex) => (prevIndex - 1 + media.length) % media.length);
};

// Function to go to the next image.
const handleNext = () => {
  setCurrentIndex((prevIndex) => (prevIndex + 1) % media.length);
};
const handleInput = async (e) => {
  console.log(e.target.value,'inputsssValue')
  setAudioTime(e.target.value)
  if(playerRef.current){
      playerRef.current.currentTime = e.target.value;
  }
 //  playerRef.current.play()
  // audioRef.current.pause()
}
const [playerLoader,setPlayerLoader] = useState(true)
const handlePlay = () => {
  setPlayerLoader(false)
}

const handleTime = (event) => {
  setAudioTime(playerRef.current.currentTime)
}

const handleLyricIndex = (lyricIndex,singerIndex) => {

}
const handleOpen = () => setOpens(true);
  const handleCloses = () => setOpens(false);

// const handleHover = (event) => {
//   setAnchorEl(event.currentTarget);
// };

// // Close menu
// const handleClosed = () => {
//   setAnchorEl(null);
// };

// // Handle speed selection
// const handleSpeedChange = (speed) => {
//   setSelectedSpeed(speed);
//   handleClose();
// };

 return (
  <Box sx={{minHeight:'100vh'}}>
    <Container maxWidth='lg'>
      <Box>
      <Box>
      <Box textAlign='center'>
        <Box><Typography fontSize={40} fontWeight={600}>Lyrics Editing</Typography></Box>
        <Box><Typography fontSize={20}>Double the fun by arranging your lyrics for singers</Typography></Box>
      </Box>
      <Box sx={{pt:2,position:"sticky",bottom:0,width:'100%'}}>
        {/* <audio controlsList='nodownload noplaybackrate' style={{width:'100%'}} controls={true} src={audio.Upload.songUpload.url}/> */}
        </Box>
        <Box sx={{padding:1,mt:1,flexGrow: 1}}>
               <Grid container spacing={8}>
               <Grid item xs={4}></Grid>
               <Grid item xs={3}>
               {/* <Button onClick={HandlSubmit} sx={{width:200}} variant='contained'>continue</Button> */}
               </Grid>
               <Grid item xs={5}>
               <Grid container sx={{paddingTop:'8px'}}>
              <Grid item xs={4}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Typography sx={{textAlign:'right'}}>Singer1</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <div  id='default-color' onClick={() => setSinger1Palette(true)} style={{backgroundColor:color.hex,width:'35px',height:'20px',borderRadius:'2px'}}></div>
                      {
                        singer1Palette ? <div id='color-palette'>
                        <ColorPicker color={color} onChange={setColor} />
                        <Button sx={{textAlign:'right',color:'red'}} onClick={() => {setSinger1Palette(false)}}>Apply</Button>
                        <Button onClick={() => 
                          {setColor({hex:'#6699CC',rgb:{r:60,g:179,b:113,a:1},hsv:{h:146.72268907563026,s:66.4804469273743,v:70.19607843137254,a:1}})
                            setSinger1Palette(false)
                          }}>Cancel</Button>
                      </div> : ''
                      }
                           
                    </Grid>
                  </Grid>
              </Grid>
              <Grid item xs={4} sx={{paddingLeft:'7px'}} >
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Typography>Singer2</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <div onClick={() => setSinger2Palette(true)} style={{backgroundColor:singer2Color.hex,width:'35px',height:'20px',borderRadius:'2px'}}></div>
                      {
                        singer2Palette ? <div id='color-palette'>
                        <ColorPicker color={singer2Color} onChange={setSinger2Color} />
                        <Button sx={{textAlign:'right',color:'red'}} onClick={() => {setSinger2Palette(false)}}>Apply</Button>
                        <Button onClick={() => {
                          setSinger2Color({hex:'#3CB371',rgb:{r:60,g:179,b:113,a:1},hsv:{h:146.72268907563026,s:66.4804469273743,v:70.19607843137254,a:1}})
                          setSinger2Palette(false)
                          }}>Cancel</Button>
                      </div> : ''
                      }
                    </Grid>
                  </Grid>
              </Grid>
              <Grid item xs={4}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography sx={{textAlign:'right'}}>Both</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <div onClick={() => {setBothPalette(true)}} style={{backgroundColor:bothColor.hex,width:'35px',height:'20px',borderRadius:'2px'}}></div>
                      {
                        bothPalette ? <div id='color-palette'>
                        <ColorPicker color={bothColor} onChange={setBothColor} />
                        <Button sx={{textAlign:'right',color:'red'}} onClick={() => {setBothPalette(false)}}>Apply</Button>
                        <Button onClick={() => {
                          setBothColor({hex:'#FFAC1C',rgb:{r:255,g:172,b:28,a:1},hsv:{h:38.06167400881058,s:89.01960784313725,v:100,a:1}})
                          setBothPalette(false)}}>Cancel</Button>
                      </div> : ''
                      }
                    </Grid>
                  </Grid>
              </Grid>
            </Grid>
               </Grid>
               </Grid>
               <Box sx={{display:'flex',justifyContent:'flex-end'}}>
                <Button sx={{background:'rgb(60, 179, 113)',color:'white',textTransform:'none'}} onClick={() => {setOpen(true)}}>Preview</Button>
               </Box>
               <Box sx={{display:'flex',justifyContent:'flex-end',marginTop:'20px'}}>
                <Box sx={{display:'flex'}}>
                  <Box>
                    Solo
                  </Box>
                  <Box><input type='checkbox' name='solo' checked={songType === 'solo' ? true : false} onChange={(e) => {handleCheck(e.target.name)}}/></Box>
                </Box>
                <Box sx={{display:'flex'}}>
                  <Box>
                    Duet
                  </Box>
                  <Box><input type='checkbox' name='duet' checked={songType === 'duet' ? true : false} onChange={(e) => {handleCheck(e.target.name)}}/></Box>
                </Box>
               </Box>
            </Box>
      </Box>
  {
    audio.Upload.TextfileLyrics.length ==0 ?<div>   <Box sx={{display:"flex",gap:5,alignItems:"center"}}>
        
    <Box>
        <input className='input-focus' onChange={(e)=>{setlyric(e.target.value)
        
        }} value={lyric} style={{background:"none",overflowY:"visible",width:450,height:25,border:"none"}} placeholder='Enter your lyrics here...'></input>
      </Box>
      <Box sx={{ width: '100%',textAlign:"center" }}>
     <Box sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>
     <FormControl>
  
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      defaultValue="female"
      name="radio-buttons-group"
    >
     <Box sx={{display:"flex"}}>
  
      <Box>
      <FormControlLabel value={0} onChange={(e)=>{
        setsinger(e.target.value)
      }} control={<Radio />} sx={{fontSize:11}}  label="singer1" />
      
      </Box>
      <Box><FormControlLabel value={1} onChange={(e)=>{
        setsinger(e.target.value)
      }} control={<Radio />} sx={{fontSize:11}} label="singer2" /></Box>
      <Box><FormControlLabel value={2} onChange={(e)=>{
        setsinger(e.target.value)
      }} control={<Radio />} sx={{fontSize:11}} label="Both" /></Box>
     </Box>
    </RadioGroup>
  </FormControl>
  <Box>
    <Button variant='contained' onClick={HandleSubmit}>Add</Button>
  </Box> 
     </Box>
   
    
  </Box> 
 
 
    </Box>
    <Divider/>
<Box >
   {
      audio.Upload
      . songLyrics  && audio.Upload
      . songLyrics .map((data,index)=>(
    < >
      <Box sx={{display:"flex",gap:6,alignItems:"center"}}>
  
  <Box sx={{textAlign:"center"}}>
  <Box><input style={{overflowY:"visible",width:450,height:25,border:"none",color:data.color}} onChange={(e)=>{
    editLyrics(index,e.target.value)
  }} defaultValue={data.lyric} ></input></Box>
  </Box>
  <Box >
  <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
      <Box>
      <FormControlLabel value={0} checked={data.singerIndex==0} onChange={(e)=>{
        editSingers(index,e.target.value)
      }} control={<Radio />} sx={{fontSize:2}}  label="singer1" />
      </Box>
      <Box><FormControlLabel value={1}checked={data.singerIndex==1} onChange={(e)=>{
        editSingers(index,e.target.value)
      }} control={<Radio />} sx={{fontSize:2}} label="singer2" /></Box>
      <Box><FormControlLabel value={2} checked={data.singerIndex==2} onChange={(e)=>{
        editSingers(index,e.target.value)
      }} control={<Radio />} sx={{fontSize:2}} label="Both" /></Box>
       <Box>
    <Box variant='contained' sx={{cursor:"pointer",color:"red"}} onClick={()=>{
      deleteLyric(index)
    }} ><DeleteForeverIcon/></Box>
  </Box> 
     </Box>
     
  </Box>
  


</Box>
    </>
 ))
}

   </Box> </div>:<Card>

    <Box textAlign='center' sx={{marginTop:'15px'}}>
        <Box><Typography fontSize={40} fontWeight={600}>Lyrics Editing</Typography></Box>
        <Box><Typography fontSize={20}>Double the fun by arranging your lyrics for singers</Typography></Box>
      </Box>
      <Box sx={{display:'flex',justifyContent:'flex-end',padding:'20px 40px',paddingBottom:'0px'}}>
        <Box sx={{display:'flex',flexDirection:'column'}}>
        <Box sx={{background:'rgb(243 243 243)',borderRadius:'5px',paddingTop:'0',width:'fit-content'}}>              
            <AntTabs value={tabValue} onChange={handleTabChange} aria-label="ant example">              
            <AntTab   label="Duet"  {...a11yProps(0)} />
            <AntTab label="Group" {...a11yProps(1)} />
            {/* <AntTab   label="Groups" {...a11yProps(5)} /> */}

          </AntTabs>
      </Box>
        </Box>
      </Box>
    <><Box sx={{display:'flex'}}>
            <Box sx={{flex:3.9}}>
              <Box sx={{display:'flex',flexDirection:'column',padding:'0px',paddingTop:'0'}}>
                <Box sx={{padding:'0px 15px'}}>
                <CustomTabPanel value={tabValue} index={0}>
      <Box sx={{display:'flex',justifyContent:'space-between'}}>
                      <Box></Box>
                      <Box sx={{display:'flex'}}>
                      <Box sx={{padding:'7px',color:'#3dbbc2'}} >Singer 1</Box>
                      <Box sx={{padding:'7px',color:'#3dbbc2'}}>Singer 2</Box>
                      </Box>
                  </Box>
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <Box sx={{display:'flex',justifyContent:'space-between'}}>
        <Box></Box>
                      <Box sx={{display:'flex',gap:3.5,margin:'0px',marginTop:'5px'}}>
                        {
                          lyricHeader.map((val,ind) => (
                            tabsHandler.tebEdited ? <>
                              {
                                val === tabHeaderInd &&  
                                 <ThemeProvider theme={toolTipTheme}>
                                 <Tooltip                                
                                placement='top'
                                arrow
                                open={tabsHandler.tebEdited}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={
                                  <React.Fragment>
                                    <Box>
                                      <TextField placeholder={`Singer ${tabHeaderInd}`} onChange={(e) => {setHeaderName(e.target.value)}}></TextField>
                                      <Box sx={{display:'flex',justifyContent:'space-between',padding:'10px 30px'}}>
                                        <Box sx={{color:'rgba(211, 211, 211,1)',fontSize:'13px',cursor:'pointer'}} onClick={() => {
                                          setTabsHandler((prevState) => ({
                                            ...prevState,
                                            tebEdited : false
                                          }))
                                        }}>CANCEL</Box>
                                        <Box sx={{color:'#3dbbc2',fontSize:'15px',cursor:'pointer'}} onClick={() => {handleHeaderChange(ind)}}>OK</Box>
                                      </Box>
                                    </Box>
                                  </React.Fragment>
                                }
                                slotProps={{
                                  popper: {
                                    disablePortal: true,
                                  },
                                }}
                              > 
                              <Box sx={{height:'23px',padding:'0px 5px','&:hover':{backgroundColor:'rgba(211, 211, 211, 0.3)'}}}>{typeof val === 'string' ? val.charAt(0) : val}</Box>
                              </Tooltip>
                                 </ThemeProvider>
                              }
                            {
                                val !== tabHeaderInd && <Box sx={{height:'23px',padding:'0px 5px','&:hover':{backgroundColor:'rgba(211, 211, 211, 0.3)'}}}>{typeof val === 'string' ? val.charAt(0) : val}</Box>
                              }
                              </>
                      : 
                            <HtmlTooltip placement='top' arrow title={
                              <React.Fragment>
                                   <Box>   
                                          <Box sx={{padding:'10px 80px',textAlign:'center',fontSize:'15px',fontWeight:700,color:'#777777',background:'rgb(243, 243, 243)'}}>{typeof val === 'string' ? val : `Singer ${val}`}</Box>  
                                     <Box sx={{display:'flex',background:'white',justifyContent:'space-between',padding:'5px 10px',alignItems:'center',borderBottom:'1px solid rgb(243, 243, 243)',cursor:'pointer','&:hover':{backgroundColor:'rgba(211, 211, 211, 0.2)'}}}
                                      onClick={() => {
                                        setTabsHandler((prevState) => ({
                                        ...prevState,
                                          tebEdited : true
                                      }))
                                      setTabHeaderInd(val)
                                    }
                                    }
                                     >
                                      <Box sx={{fontSize:'14px',fontWeight:600,color:'#777777'}}>EDIT NAME</Box>
                                      <Box><HiPencil size={20} fill='#777777'/></Box>
                                     </Box>
                                     <Box sx={{display:'flex',background:'white',justifyContent:'space-between',padding:'5px 10px',alignItems:'center',cursor:'pointer','&:hover':{backgroundColor:'rgba(211, 211, 211, 0.2)'}}} onClick={() => {handleDeletePart(ind)}}>
                                      <Box sx={{fontSize:'14px',fontWeight:600,color:'#777777'}}>DELETE PART</Box>
                                      <Box><RiDeleteBin6Line  size={20} fill='#777777'/></Box>
                                     </Box>
                                   </Box>
                              </React.Fragment>
                            }>
                              <Box sx={{height:'23px',padding:'0px 5px',cursor:'pointer','&:hover':{backgroundColor:'rgba(211, 211, 211, 0.3)'}}}>{typeof val === 'string' ? val.charAt(0) : val}</Box>
                            </HtmlTooltip>
                          ))
                        }
                      </Box>
        </Box>
      </CustomTabPanel>
      </Box>
      <Box sx={{display:'flex',justifyContent:'space-between'}}>
      <Box sx={{padding:'0px 60px'}}>
              {audio.Upload.TextfileLyrics.map((data,index) => ( 
              <>
              
                  <Box sx={{display:'flex',justifyContent:'space-between',width:'100%'}}>
                  <Box>
                    <input onChange={(e) => {handleFilelyricChange(index,e.target.value)}} className='lyric-input' style={{color:data.SingerType,fontWeight:'400',height:'28.8px',fontSize:'15px'}} defaultValue={data.Sentence} onClick={() => {console.log(index,'indexInput')}}></input></Box>
                    <Box>
                       {/* <Box sx={{display:'flex',justifyContent:'flex-end'}}>
                        <Box>vijay</Box>
                       </Box> */}
                    </Box>
                </Box>
                </>
              ))}
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
            <Box sx={{padding:'7px',paddingTop:'0px'}}>
            {
                audio.Upload.TextfileLyrics.map((data,index) => (
                  <Box sx={{display:'flex','&:hover .Singer1, &:hover .Singer2': {
                    color: 'rgb(70,150,255)', // Apply color change on hover
                  }}}>
                      <Box className='Singer1' sx={{backgroundColor:data.SingerIndex === 2 ? '#e5edf7' : data.SingerIndex === 0 ? '#e5edf7' : 'white' ,textAlign:'center',color:'#e5edf7',padding:'3px 7px',cursor:'pointer',borderTop:'.5px solid rgb(204,204,204)',borderLeft:'.5px solid rgb(204,204,204)'}} key={index} onClick={() =>{handleColorPalette('#12a1bc',index,data.SingerIndex === 2 ? 1 : data.SingerIndex === '' ? 0 : data.SingerIndex === 1 ? 2 : data.SingerIndex === 0 && '' )}}>Singer 1</Box>
                   <Box className='Singer2' sx={{backgroundColor:data.SingerIndex === 2 ? '#e5edf7' : data.SingerIndex === 1 ? '#e5edf7' : 'white',textAlign:'center',color:'#e5edf7',padding:'3px 7px',cursor:'pointer',borderTop:'1px solid rgb(204,204,204)',borderLeft:'1px solid rgb(204,204,204)',borderRight:'1px solid rgb(204,204,204)'}} key={index} onClick={() =>{handleColorPalette('#12a1bc',index,data.SingerIndex === 1 ? '' : data.SingerIndex === 2 ? 0 : data.SingerIndex === 0 ? 2 : data.SingerIndex === '' && 1 )}}>Singer 2</Box>
                      </Box>
                ))
              }
            </Box>  
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
            <Box sx={{padding:'7px',paddingTop:'0px'}}>
              {
                tabModalDisplay && <Box sx={{position:'absolute',zIndex:2000,width:'200px',background:'white',boxShadow:'0px 5px 10px 0px rgba(0, 0, 0, 0.5)',borderRadius:'3px'}}>
                <Box sx={{padding:'10px',textAlign:'center'}}>Group Singing</Box>
                <Box sx={{textAlign:'center',padding:'10px',color:'rgb(138, 135, 136)'}}>We will create a default group experience based on your duet parts, or you can customize it for up to 8 singers!</Box>
                <Box sx={{display:'flex',justifyContent:'center',gap:1,padding:'10px'}}>
                  <Box sx={{background:'rgb(240, 240, 240)',fontSize:'13px',fontWeight:'bold',padding:'3px 8px',borderRadius:'3px',cursor:'pointer'}} onClick={() => {setTabValue(0)}}>Go Back</Box>
                  <Box sx={{background:'rgb(136,38,251)',fontSize:'13px',fontWeight:'bold',padding:'3px 8px',color:'white',borderRadius:'3px',cursor:'pointer'}} onClick={() => {setTabModalDisplay(false)}}>OK</Box>
                </Box>
              </Box>
              }
            {
                audio.Upload.TextfileLyrics.map((data,index) => (
                  <Box sx={{display:'flex','&:hover .Singer1, &:hover .Singer2': {
                    color: 'rgb(70,150,255)', // Apply color change on hover
                  }}}>
                    {
                      lyricHeader.map((val) => (
                        <Box key={`${index}-${val}`} className='Singer2' sx={{backgroundColor:'#e5edf7',textAlign:'center',color:'#e5edf7',padding:'3px 18px',cursor:'pointer',borderTop:'1px solid rgb(204,204,204)',borderLeft:'1px solid rgb(204,204,204)'}}>{typeof val === 'string' ? val.charAt(0) : val}</Box>
                      ))
                    }
                    {/* <Box className='Singer1' sx={{backgroundColor:'#e5edf7',textAlign:'center',color:'#e5edf7',padding:'3px 18px',cursor:'pointer',borderTop:'.5px solid rgb(204,204,204)',borderLeft:'.5px solid rgb(204,204,204)'}} key={index}>1</Box>
                   <Box className='Singer2' sx={{backgroundColor:'#e5edf7',textAlign:'center',color:'#e5edf7',padding:'3px 18px',cursor:'pointer',borderTop:'1px solid rgb(204,204,204)',borderLeft:'1px solid rgb(204,204,204)',borderRight:'1px solid rgb(204,204,204)'}} key={index}>2</Box>
                   <Box className='Singer2' sx={{backgroundColor:'#e5edf7',textAlign:'center',color:'#e5edf7',padding:'3px 18px',cursor:'pointer',borderTop:'1px solid rgb(204,204,204)',borderRight:'1px solid rgb(204,204,204)'}} key={index}>3</Box>
                   <Box className='Singer2' sx={{backgroundColor:'#e5edf7',textAlign:'center',color:'#e5edf7',padding:'3px 18px',cursor:'pointer',borderTop:'1px solid rgb(204,204,204)',borderRight:'1px solid rgb(204,204,204)'}} key={index}>4</Box>
                   {
                      lyricPartsControl >= 5 && <Box className='Singer2' sx={{backgroundColor:'#e5edf7',textAlign:'center',color:'#e5edf7',padding:'3px 18px',cursor:'pointer',borderTop:'1px solid rgb(204,204,204)',borderRight:'1px solid rgb(204,204,204)'}} key={index}>4</Box>

                   }
                   {
                      lyricPartsControl >= 6 && <Box className='Singer2' sx={{backgroundColor:'#e5edf7',textAlign:'center',color:'#e5edf7',padding:'3px 18px',cursor:'pointer',borderTop:'1px solid rgb(204,204,204)',borderRight:'1px solid rgb(204,204,204)'}} key={index}>4</Box>

                   }
                   {
                      lyricPartsControl >= 7 && <Box className='Singer2' sx={{backgroundColor:'#e5edf7',textAlign:'center',color:'#e5edf7',padding:'3px 18px',cursor:'pointer',borderTop:'1px solid rgb(204,204,204)',borderRight:'1px solid rgb(204,204,204)'}} key={index}>4</Box>

                   }
                   {
                      lyricPartsControl >= 8 && <Box className='Singer2' sx={{backgroundColor:'#e5edf7',textAlign:'center',color:'#e5edf7',padding:'3px 18px',cursor:'pointer',borderTop:'1px solid rgb(204,204,204)',borderRight:'1px solid rgb(204,204,204)'}} key={index}>4</Box>

                   } */}
                      </Box>
                ))
              }
            </Box>  
      </CustomTabPanel>
      </Box>
              </Box>
            </Box>
            <Box sx={{flex:.1}}>
            <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',backgroundColor:'rgb(243, 243, 243)',height:"100%",margin:'40px',marginTop:'0px'}}>
                  <BootstrapTooltip title="Add Singer"  placement='left'>
                  <Box onClick={handleGroupController}>
                  <ImUserPlus fill='rgb(119 119 119)' style={{cursor:'pointer',padding:'3px',opacity:tabValue === 0 && lyricHeader.length <= 7 ? '0.4' : ''}} size={20}/></Box>
                  </BootstrapTooltip>  
                  <BootstrapTooltip title='Reset Parts' placement='left'>
                  <Box><RestartAltTwoToneIcon style={{fontSize:'27px',padding:'3px',color:'rgb(119 119 119)',cursor:'pointer'}} onClick={openResetModal}/></Box>
                  </BootstrapTooltip>
                  <BootstrapTooltip title='Help' placement='left'>
                  <Box onClick={handleOpen}>
          <FaQuestionCircle fill='rgb(119 119 119)' size={20} style={{ cursor: 'pointer', padding: '3px' }} />
        </Box>
                  </BootstrapTooltip>
                </Box>
            </Box>
            {/* <Box sx={{padding:'0px 40px'}}>
            <Grid container spacing={1} >
              <Grid item md={2} sx={{marginTop:'5px'}}></Grid>
              <Grid item md={8} >
                  <CustomTabPanel value={tabValue} index={0}>
                    <>
                    <Box sx={{display:'flex',textAlign:'center',}}>
                      <Box sx={{padding:'7px'}}>Singer 1</Box>
                      <Box sx={{padding:'7px'}}>Singer 2</Box>
                  </Box>
                  <Box>
                  {
                    audio.Upload.TextfileLyrics.map((data,index) => (
                      <Box sx={{display:'flex','&:hover .Singer1, &:hover .Singer2': {
                        color: 'rgb(70,150,255)', // Apply color change on hover
                      }}}>
                       <Box className='Singer1' sx={{backgroundColor:'#e5edf7',textAlign:'center',color:'#e5edf7',padding:'3px 7px',cursor:'pointer',borderTop:'.5px solid rgb(204,204,204)',borderLeft:'.5px solid rgb(204,204,204)'}} key={index}>Singer 1</Box>
                       <Box className='Singer2' sx={{backgroundColor:'#e5edf7',textAlign:'center',color:'#e5edf7',padding:'3px 7px',cursor:'pointer',borderTop:'1px solid rgb(204,204,204)',borderLeft:'1px solid rgb(204,204,204)',borderRight:'1px solid rgb(204,204,204)'}} key={index}>Singer 2</Box>
                      </Box>                    
                    ))
                  }
                </Box>
                    </>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabValue} index={1}>
                    <>
                    <Box sx={{display:'flex',textAlign:'center',}}>
                      <Box sx={{padding:'7px',paddingTop:'0'}}>Singer 1</Box>
                      <Box sx={{padding:'7px',paddingTop:'0'}}>Singer 2</Box>
                  </Box>
                  <Box>
                  {
                    audio.Upload.TextfileLyrics.map((data,index) => (
                      <Box sx={{display:'flex','&:hover .Singer1, &:hover .Singer2': {
                        color: 'rgb(70,150,255)', // Apply color change on hover
                      }}}>
                       <Box className='Singer1' sx={{backgroundColor:'#e5edf7',textAlign:'center',color:'#e5edf7',padding:'3px 7px',cursor:'pointer',borderTop:'.5px solid rgb(204,204,204)',borderLeft:'.5px solid rgb(204,204,204)'}} key={index}>Singer 1</Box>
                       <Box className='Singer2' sx={{backgroundColor:'#e5edf7',textAlign:'center',color:'#e5edf7',padding:'3px 7px',cursor:'pointer',borderTop:'1px solid rgb(204,204,204)',borderLeft:'1px solid rgb(204,204,204)',borderRight:'1px solid rgb(204,204,204)'}} key={index}>Singer 2</Box>
                      </Box>                    
                    ))
                  }
                </Box>
                    </>
                  </CustomTabPanel>
              </Grid>
              <Grid item md={2}>
                <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',backgroundColor:'rgb(243, 243, 243)',height:"100%"}}>
                  <BootstrapTooltip title="Add Singer"  placement='left'>
                  <Box>
                  <ImUserPlus fill='rgb(119 119 119)' style={{cursor:'pointer',padding:'3px',opacity:tabValue === 0 && '0.4'}} size={20}/></Box>
                  </BootstrapTooltip>  
                  <BootstrapTooltip title='Reset Parts' placement='left'>
                  <Box><RestartAltTwoToneIcon style={{fontSize:'27px',padding:'3px',color:'rgb(119 119 119)',cursor:'pointer'}}/></Box>
                  </BootstrapTooltip>
                  <BootstrapTooltip title='Help' placement='left'>
                  <Box><FaQuestionCircle fill='rgb(119 119 119)' size={20} style={{cursor:'pointer',padding:'3px'}}/></Box>
                  </BootstrapTooltip>
                </Box>
              </Grid>
            </Grid>
            </Box> */}
          </Box>
              
            </>
            <Modal open={isResetModalOpen} onClose={closeResetModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: "center",
          }}
        >
          <Typography variant="h6">Reset DUET parts</Typography>
          <Typography sx={{ mt: 2 }}>Are you sure you want to reset your duet parts?</Typography>
          
          <Box sx={{ display: "flex", justifyContent: "space-around", mt: 3 }}>
            <Button variant="contained" color="success" onClick={closeResetModal}>
              Yes
            </Button>
            <Button variant="outlined" onClick={closeResetModal}>
              No
            </Button>
          </Box>
        </Box>
      </Modal>
            <Modal open={opens} onClose={handleCloses} aria-labelledby="modal-title" aria-describedby="modal-description">
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 500,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      textAlign: 'center'
    }}
  >
       <Typography variant="h6" sx={{ mb: 2 }}>
      {media[currentIndex].heading}
    </Typography>
    <Box sx={{ position: 'relative' }}>
      {/* Render Image or Video based on media type */}
      {media[currentIndex].type === 'image' ? (
        <img
          src={media[currentIndex].src}
          alt={`Media ${currentIndex + 1}`}
          style={{ width: '100%', height: 'auto', borderRadius: '4px' }}
        />
      ) : (
        <video
          src={media[currentIndex].src}
          controls
          autoPlay
          style={{ width: '100%', height: 'auto', borderRadius: '4px' }}
        />
      )}

      {/* Navigation buttons */}
      <IconButton
        onClick={handlePrev}
        sx={{
          position: 'absolute',
          top: '50%',
          left: 0,
          transform: 'translateY(-50%)',
          color: 'white',
          backgroundColor: 'rgba(0,0,0,0.5)',
          '&:hover': { backgroundColor: 'rgba(0,0,0,0.7)' }
        }}
      >
        <ArrowBack />
      </IconButton>
      <IconButton
        onClick={handleNext}
        sx={{
          position: 'absolute',
          top: '50%',
          right: 0,
          transform: 'translateY(-50%)',
          color: 'white',
          backgroundColor: 'rgba(0,0,0,0.5)',
          '&:hover': { backgroundColor: 'rgba(0,0,0,0.7)' }
        }}
      >
        <ArrowForward />
      </IconButton>
    </Box>

    {/* Dot Indicators */}
    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
      {media.map((_, index) => (
        <Box
          key={index}
          onClick={() => setCurrentIndex(index)}
          sx={{
            width: 10,
            height: 10,
            borderRadius: '50%',
            bgcolor: currentIndex === index ? 'black' : 'grey',
            mx: 0.5,
            cursor: 'pointer'
          }}
        />
      ))}
    </Box>
  </Box>
</Modal>
            <Box sx={{ mb: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', overflowY: 'auto' }}> 
  <Typography sx={{ mb: 1 }}>Language of Lyrics</Typography>

  <TextField
    select
    value={language}
    onChange={handleChanges}
    variant="outlined"
    fullWidth
    margin="normal"
    sx={{ width: '300px', mb: 3,position: "sticky" }}
    SelectProps={{
      MenuProps: {
        PaperProps: {
          style: {
            maxHeight: 200, // Set the maximum height for the dropdown menu
          },
        },
        disableScrollLock: true, // Prevents the body from locking scroll when the menu is open
      },
    }}
  >
  
    {languages.map((option) => (
      <MenuItem key={option.value} value={option.value} overflowY={"scroll"}>
        {option.label}
      </MenuItem>
    ))}
  </TextField>

  <Button
    variant="contained"
    sx={{
      mt: 2,
      color: "white",
      backgroundColor: "#00B818",
      '&:hover': {
        backgroundColor: "#009914",
      },
    }}onClick={HandlSubmit}
  >
    Next
  </Button>
</Box>
    </Card>
  }
    </Box> 
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className='lyric-preview' sx={style}>
          {
            audio && audio.Upload.TextfileLyrics.map((data,ind) => (
              <Box sx={{color:data.SingerType,marginBottom:'3px'}}>{data.Sentence}</Box>
            ))
          }
        </Box>
      </Modal> 
  </Container>
  <Box className='show' sx={{display:'flex',flexDirection:'column'}}>
                <Box sx={{marginBottom:'6px',margin:'0'}}>
                    <input style={{margin:'0',width:'100%'}} id="joinInput" onChange={handleInput} max={audioDuration} value={currentValue} ref={scrollRef} type="range"/>
                </Box>
                <Box sx={{display:'flex',justifyContent:'space-between',background:'rgba(0, 0, 0, 0.8)',padding:'20px'}}>
                    <Box sx={{display:'flex',gap:6}}>
                        <Box sx={{display:'flex',gap:2,marginTop:'6px'}}>
                          {/* <Box sx={{color:'rgb(99,99,99)',fontSize:'14px',fontWeight:500}}>Speed</Box>
                          <Box
         sx={{color:'white',backgroundColor:'#000000',padding:'3px 10px',paddingBottom:'0',marginBottom:'100px'}}                 
        variant="contained"
        onMouseEnter={handleHover}
        onMouseLeave={handleClosed}
        aria-controls="speed-menu"
        aria-haspopup="true"
      >
        {selectedSpeed}
      </Box>

      <Menu
        id="speed-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClosed}
        MenuListProps={{
          onMouseEnter: handleHover, // Keep menu open on hover
          onMouseLeave: handleClosed,
        }}
      >
        {["Normal", "Slower", "Slowest"].map((speed) => (
          <MenuItem
            key={speed}
            onClick={() => handleSpeedChange(speed)}
            selected={speed === selectedSpeed} // Highlight selected
          >
            {speed}
          </MenuItem>
        ))}
      </Menu> */}
      <Box sx={{display:'flex',gap:1,alignItems:'center'}}>
                            <Box>
                                <img style={{width:'42px',height:'42px',borderRadius:'0'}} src={audio.Upload.SongMeta.url}/>
                            </Box>
                            <Box sx={{display:'flex',flexDirection:'column'}}>
                                <Box sx={{color:'white',fontSize:'1em',fontWeight:700}}>{audio.Upload.SongMeta.tittle}</Box>
                                {/* <Box sx={{color:'#636363',fontWeight:400}}>{`${joinList && joinList.length} collabs`}</Box> */}
                            </Box>
                        </Box>
                             {
                                playerLoader ? <Box>
                                    <CircularProgress size={22} sx={{color:'white',fontSize:'10px',marginTop:'10px'}}/>
                                </Box> :   <>
                             {
                                icon ?  <Box sx={{cursor:'pointer'}} onClick={() => {
                                    setIcon(false)
                                    if( playerRef.current){
                                        playerRef.current.pause()
                                    }
                                    }}>
                                <IoIosPause style={{fontSize:'35px',color:'white'}}/>
                             </Box> :     <Box sx={{cursor:'pointer'}} onClick={() => {
                                setIcon(true)
                                if( playerRef.current){
                                    console.log('workinggggggggg')
                                    playerRef.current.play()
                                }
                                }}>
                                <PlayArrowIcon style={{fontSize:'35px',color:'white'}}/>
                             </Box>
                             }
                             </>
                             }
                        </Box>
                    </Box>
                    <Box sx={{display:'flex',justifyContent:'space-between'}}>
                        <Box sx={{display:'flex',padding:'8px',gap:1}}>
                            {/* <Box sx={{cursor:'pointer'}}>
                                {
                                    playerData.isLiked === true || controlsState?.likeArray?.includes(playerData._id) ? <Box>
                                      <IoIosHeart fill="#1dc97d" size={25} style={{marginTop:'3px'}}/>
                                    </Box>  : <Box onClick={handleLikeSingCollab}>
                                    <CiHeart style={{fontSize:'30px',color:'white'}}/>
                                    </Box> 
                                }   
                            </Box> */}
                            {/* <Box sx={{cursor:'pointer'}}>
                                {
                                    playlistValues[0]?.isFavorite === true || controlsState?.favotite?.includes(playerData._id) ? <Box>
                                    <IoMdStar style={{fontSize:'30px',color:'#1dc97d'}}/>
                                </Box> :  <Box onClick={handleFavouiteSingCollab}>
                                <CiStar style={{fontSize:'30px',color:'white'}}/>
                                </Box>
                                }  
                            </Box>
                            <Box sx={{cursor:'pointer'}}>
                                <IoShareSocialOutline style={{fontSize:'30px',color:'white'}}/>
                            </Box> */}
                        </Box>
                        <Box
      sx={{
        position: 'relative',
        display: 'inline-block',
        cursor: 'pointer',
        backgroundColor: showSlider ? '#333' : 'transparent',
        padding: '10px',
        borderRadius: '8px',
        transition: 'background-color 0.3s ease',
      }}
      onMouseEnter={() => setShowSlider(true)}
      onMouseLeave={() => setShowSlider(false)}
    >
      {/* Slider Positioned Above the Speaker Icon */}
      {showSlider && (
        <Box
          sx={{
            position: 'absolute',
            bottom: '45px', // Adjust based on icon size
            left: '50%',
            transform: 'translateX(-50%)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#333',
            padding: '5px',
            borderRadius: '8px',
          }}
        >
          <Slider
            orientation="vertical"
            value={volume}
            onChange={(e, newValue) => {
                setVolume(newValue)
                playerRef.current.volume = newValue/maxSound
            }}
            min={0}
            max={100}
            sx={{
              height: '100px', // Slider height
              color: 'white',
              '& .MuiSlider-thumb': {
                width: '10px',
                height: '10px',
                backgroundColor: 'white',
              },
              '& .MuiSlider-track': {
                backgroundColor: 'white',
              },
              '& .MuiSlider-rail': {
                backgroundColor: 'rgba(255, 255, 255, 0.3)',
              },
            }}
          />
        </Box>
      )}

      {/* Speaker Icon */}
      {
        speakerController === false ?  <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          color: 'white',
        }}
        onClick={() => {
            setSpeakerController(true)
            setVolume(0)
            playerRef.current.volume = 0
        }}
      >
        <HiMiniSpeakerWave style={{ fontSize: '28px', color: 'white' }} />
      </Box> :   <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          color: 'white',
        }}
        onClick={() => {
            setSpeakerController(false)
            setVolume(50)
            playerRef.current.volume = 1
        }}
      >
        <PiSpeakerSimpleSlashFill style={{ fontSize: '28px', color: 'white' }} />
      </Box>
      }
    </Box>
                        <Box sx={{padding:'10px',color:'white',fontWeight:500,fontSize:'1em',paddingTop:'14px'}}>{currentRunning} / {playerRef.current !== undefined ? currentTimeVaue : '00:00'}</Box>
                    </Box>
                </Box>
              </Box>
              <audio ref={playerRef} onCanPlay={handlePlay} controls={false} onTimeUpdate={handleTime}  src={audio.Upload.songUpload.url}/>
  </Box>
  )
}

export default Songlyrics