import { Button, Divider, Typography } from '@mui/material'
import React, { useContext, useState} from 'react'
import { Box } from '@mui/material'
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import '../styles/songbook.style.css';
import { SearchContext } from '..';
import Topmenu from '../../topmenu';
const Header = () => {
  const [cancelShow,setCancelShow] = useState(false)
  const [searchValue,setSearchValue] = useContext(SearchContext);
  return (
    <Box>
        <Typography sx={{fontSize:"32px",color:"#2D2867",fontWeight:"bold"}}>Songbook</Typography>
        <Typography sx={{fontSize:"20px",color:"#585A64",marginBottom:'20px'}}>Discover a world of music with 2M+ songs with lyrics! Connect and enjoy the performances</Typography>
        <Divider></Divider>
        <Box sx={{display:"flex",alignItems:"center",marginTop:2,marginBottom:2}}>
        <FormControl fullWidth sx={{ m: 1 ,width:{xl:"600px",lg:"500px",md:"400px",xs:"100%"}}} >
          {/* <InputLabel htmlFor="standard-adornment-amount" variant="outlined">Search</InputLabel> */}
          <Input
            id="standard-adornment-amount"
            type='text'
            value={searchValue}
            startAdornment={<SearchIcon position="start" style={{color:'gray',fontSize:'30'}}>$</SearchIcon>}
            placeholder='Search songs by artist or title'
          sx={{backgroundColor:'white',padding:'3px',borderRadius:'5px',borderBottom:'0'}}
           onClick={() => setCancelShow(true)}
           onChange={(e) => setSearchValue(e.target.value)}
          />
        </FormControl>
        <Typography sx={{visibility: searchValue !== '' ? 'visible' : 'hidden',cursor:'pointer'}} id='input-cancel' onClick={() => {setSearchValue('')}}>Cancel</Typography>
        {/* <Box>
          <Button  variant="text">Cancel</Button>
          </Box> */}
        </Box>
    </Box>
  )
}

export default Header