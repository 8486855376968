import { Box, Typography } from '@mui/material';
import React from 'react';
import { ImAppleinc } from 'react-icons/im';
import Appcard from '../components/app-card';
import { BsGooglePlay } from 'react-icons/bs';

const Topmenu = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#9036FF',
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'center', 
        alignItems: 'center', 
        textAlign: 'center', 
        padding: 1,
        gap:4
      }}
    >
      <Typography sx={{display:'flex' ,fontWeight: 'bold', color: '#fff', marginBottom: 2,fontSize:20 }}>
        Download SparkSinger App
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          justifyContent: 'center',
        }}
      >
        <Appcard icon={<ImAppleinc fontSize="large" />} tittle1="Download on the" tittle2="App Store" />
        <Appcard icon={<BsGooglePlay fontSize="large" />} tittle1="Download on the" tittle2="Google Play" />
      </Box>
      
    </Box>
  );
};

export default Topmenu;
