import { Box, Card, Typography } from '@mui/material'
import React from 'react'
import { LuLoader } from 'react-icons/lu'
import { FadeLoader, MoonLoader } from 'react-spinners'

const Press = () => {
  return (
  <Box  sx={{
    boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px',
    borderRadius: '5px',
    height: '300px',
    width: '900px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center' // Center content vertically
  }}>
    <FadeLoader color="LightGray"height={10} width={4} margin={-6} />
  
    
  </Box>

  )
}

export default Press