import { PiSpeakerHigh, PiSpeakerSimpleSlash } from "react-icons/pi"
import { useDispatch, useSelector } from "react-redux"
import { setVolume } from "../../../features/redux/player"
import { useCallback } from "react"
import { Box } from "@mui/material"
const MAX_SOUND = 100;
const VolumeSlider = ({audioRef, autoPlay}) => {
    const player = useSelector(state => state.player)
    const dispatch = useDispatch()
  
    const handleVolume = useCallback((e) => {
      console.log(e.target.value, 'VoulumeContoller')
      dispatch(setVolume(Number(e.target.value)))
      const volume = Number(e.target.value) / MAX_SOUND
      audioRef.current.volume = volume
    }, [])
  
    return (
      <Box>
      <div className={autoPlay?.Upload.MediaPlayerPlay[0].isTrue ? 'pauseSpeaker' : 'speaker'} style={{  display: 'flex',width:"90px", }}>
        {
          player.volume === 0 ? <div onClick={() => {
            audioRef.current.volume = 0.6
            dispatch(setVolume(60))
          }} style={{ color: 'white' ,cursor:'pointer'}}>
            <PiSpeakerSimpleSlash size={20} />
          </div> : <div onClick={() => {
            audioRef.current.volume = 0
            dispatch(setVolume(0))
          }} style={{ color: 'white',cursor:'pointer' }}>
            <PiSpeakerHigh size={20} />
          </div>
        }
        <div className='volumeTab' >
          <input type='range' max={MAX_SOUND} value={player.volume} min={0} onChange={handleVolume} style={{ width: '70px' }} />
        </div>
      </div>
    </Box>
    )
  }
  export default VolumeSlider