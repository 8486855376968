import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Card, useScrollTrigger, Button, Container, styled, Avatar, Modal, CardMedia, CardContent, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, IconButton } from '@mui/material';
import girl from '../assest/images/girl.webp'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useNavigate, Link, useLocation, useParams } from 'react-router-dom';
import music from '../assest/images/music type.jpg';
import { url } from '../config/config';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { PiGreaterThanLight } from "react-icons/pi";
import TimeAgo from '../pages/recordings/timeStmaps';
import img from '../assest/images/03d82d29-8fa2-44ac-b34e-353358b62ee9.jpeg'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { KaraokeTiming } from '../features/addlyric/addLyric';
import FadeLoader from 'react-spinners/FadeLoader';
import { MdOutlineDeleteOutline } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { KaraokeEdit } from '../features/addlyric/addLyric';
import { BiLogoPlayStore, BiSolidMessageAdd } from "react-icons/bi";
import { IoAlbums } from "react-icons/io5";
import ProfileTimeAgo from './profileTimestamps';
import GroupIcon from '@mui/icons-material/Group';
import { ModelStateHanlder } from '../pages/user-profile';
import coverimage from '../assest/images/MUSIC.png';
import Press from '../pages/press';
import { Android, Apple, Cancel, Close, Person, PlayArrow, Store } from '@mui/icons-material';
import { IoMdAdd } from 'react-icons/io';
import Spark from '../pages/spark';
import Appcard from './app-card';
import { ImAppleinc } from 'react-icons/im';
import image1 from '../assest/images/NewLogo.png'
import image2 from '../assest/images/cover music.png'
import { FaAppStore } from 'react-icons/fa';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: '10px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AntTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: 'rgb(45, 40, 103)',
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: 'rgb(114, 140, 166)',
  fontWeight: 400,
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&.Mui-selected': {
    color: 'rgb(45, 40, 103)',
    fontWeight: 700,
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgb(45, 40, 103)',
  },
}));

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#635ee7',
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#fff',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  }),
);

export default function
  BasicTabs({ mixSong }) {
  const [songCollab, setSongCollab] = useState([])
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch()
  const [openDialog, setOpenDialog] = useState(false);
  const [actionType, setActionType] = useState("");
  const [karaokeInd, setKaraokeInd] = useState('');
  const [more,setMore]=useState(true);
  const [pagination, setPagination] = useState(0);
  const [songUpload, setSongUpload] = useState([])
  const [hasMore, setHasMore] = useState(true);
  const [invites, setInvites] = useState([])
  const [playlist, setPlaylist] = useState([])
  const [hasMores, setHasMores] = useState(true);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState([])
  const [album, setAlbum] = useState([])
  const [openi, setOpeni] = useState(false);
  const value1 = useSelector((state) => state)
  console.log(value1)
  const open = Boolean(anchorEl);
  const { modalState, setModalState } = React.useContext(ModelStateHanlder);
  const handleOpeni = () => {
    setOpeni(true); // Open modal
  };

  const handleClosei = () => {
    setOpeni(false); // Close modal
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      console.log(scrollTop + windowHeight)
      console.log(documentHeight)
      if (Math.round(scrollTop + windowHeight) >= documentHeight - 1) {
        setPagination(prevPagination => prevPagination + 1)
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  //   useEffect(()=>{
  //     const GetExplore=async()=>{
  //  try {
  //        const res = await axios.get(`${url}/api/SongCollaboration/getSongCollaborationForiegnkey?page=${pagination}`);
  //        if(pagination ===  1){
  //         setValues(res.data.data)
  //        }else{
  //         console.log('pagination')
  //         setValues((prevValues) => [...prevValues,...res.data.data])
  //        }
  //        console.log(res.data.data)
  //    } catch (error) {
  //       console.log(error)
  //    }
  //     }
  //     GetExplore()
  //   },[pagination])
  console.log(values)
  console.log(pagination)
  useEffect(() => {
    console.log('vijay')
    const userSongCollaboration = async () => {
      try {
        setLoading(true); // Start loading
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/SongCollaboration/getSongCollaborationByUserID?page=${pagination}`, config)
        console.log(res.data.data, 'ProfilePageload')
        if (res.data.data.length < 10) {
          setHasMore(false);
        }
        if (pagination === 0) {
          setSongCollab(res.data.data)
        } else {
          console.log('pagination')
          setSongCollab((prevValues) => [...prevValues, ...res.data.data])
        }
        console.log(res.data.data)
      } catch (error) {
        console.log(error)
      }finally {
        setLoading(false); // Stop loading after fetch
      }
    }
    userSongCollaboration()
  }, [pagination])

  console.log(songCollab, 'songCollab')
  console.log(pagination, 'paginationssss')
  useEffect(() => {
    const userSongUpload = async () => {
      try {
        setLoading(true); // Start loading
  
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        };
  
        const res = await axios.get(`${url}/api/Karaoke/getKaraokeByUserID?page=${pagination}`, config);
        console.log(res.data.data, 'ProfilePageload');
  
        if (res.data.data.length < 10) {
          setHasMore(false);
        }
        if (pagination === 0) {
          setSongUpload(res.data.data)
        } else {
          console.log('pagination')
          setSongUpload((prevValues) => [...prevValues, ...res.data.data])
        }
        console.log(res.data.data)
      } catch (error) {
        console.log(error)
      }finally {
        setLoading(false); // Stop loading after fetch
      }
    }
  
    userSongUpload();
  }, [pagination]);

  useEffect(() => {
    const userSonginvites = async () => {
      try {
        setLoading(true); // Start loading
  
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        };
  
        const res = await axios.get(`${url}/api/UserAlbum/getUserAlbumByUserID?page=${pagination}`, config);
        console.log(res.data.data, 'ProfilePageload');
  
        if (res.data.data.length < 10) {
          setHasMore(false);
        }
        if (pagination === 0) {
          setAlbum(res.data.data)
        } else {
          console.log('pagination')
          setAlbum((prevValues) => [...prevValues, ...res.data.data])
        }
        console.log(res.data.data)
      } catch (error) {
        console.log(error)
      }finally {
        setLoading(false); // Stop loading after fetch
      }
    }
  
    userSonginvites();
  }, [pagination]);
  useEffect(() => {
    const userSonginvites = async () => {
      try {
        setLoading(true); // Start loading
  
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        };
  
        const res = await axios.get(`${url}/api/UserPlayList/getUserPlayListByUserID?page=${pagination}`, config);
        console.log(res.data.data, 'ProfilePageload');
  
        if (res.data.data.length < 10) {
          setHasMore(false);
        }
        if (pagination === 0) {
          setPlaylist(res.data.data)
        } else {
          console.log('pagination')
          setPlaylist((prevValues) => [...prevValues, ...res.data.data])
        }
        console.log(res.data.data)
      } catch (error) {
        console.log(error)
      }finally {
        setLoading(false); // Stop loading after fetch
      }
    }
  
    userSonginvites();
  }, [pagination]);
  useEffect(() => {
    const userSongAlbum = async () => {
      try {
        setLoading(true); // Start loading
  
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        };
  
        const res = await axios.get(`${url}/api/SongCollaboration/getSongCollaborationUserInvitesByUserID?page=${pagination}`, config);
        console.log(res.data.data, 'ProfilePageload');
  
        if (res.data.data.length < 10) {
          setHasMore(false);
        }
        if (pagination === 0) {
          setInvites(res.data.data)
        } else {
          console.log('pagination')
          setInvites((prevValues) => [...prevValues, ...res.data.data])
        }
        console.log(res.data.data)
      } catch (error) {
        console.log(error)
      }finally {
        setLoading(false); // Stop loading after fetch
      }
    }
  
    userSongAlbum();
  }, [pagination]);
  const handleLoadMore = () => {
  if (hasMore && !loading) {
    setPagination((prev) => prev + 1);
  }
};
  const handelemore= () =>{
    if(more){
      setMore ((prevPagination)=> prevPagination+ 1);
    }
  }
  // useEffect(() => {
  //   console.log('vijay')
  //   const userSonguplod = async () => {
  //     try {
  //       const config = {
  //         headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  //       }
  //       const res = await axios.get(`${url}/api/Karaoke/getKaraokeByUserID?page=${pagination}`, config);
  //       console.log(res.data.data, 'ProfilePageload')
  //       if (res.data.data.length < 10) {
  //         setHasMores(false);
  //       }
  //       if (pagination === 0) {
  //         setSongUpload(res.data.data)
  //       } else {
  //         console.log('paginationuploda')
  //         setSongUpload((prevValues) => [...prevValues, ...res.data.data])
  //       }
  //       console.log(res.data.data)
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  //   userSonguplod()
  // }, [pagination])
  // const handleLoadMores = () => {
  //   if (hasMores) {
  //     setPagination((prevPagination) => prevPagination + 1);
  //   }
  // };
  const navigate = useNavigate()
  const location = useLocation()
  const [value, setValue] = React.useState(location.state?.landingIndex ?? 0);
  console.log(value, 'tabs')
  console.log("kesavan")
  useEffect(() => {
    setValue(location.state?.landingIndex !== undefined ? location.state?.landingIndex : 0)
  }, [location])
 
  const [giftis, setGiftis] = useState([])

  const [groups, setGroups] = useState([])
  console.log(songUpload, "")
  
  const [text, setText] = useState(true)

  const [opens, setOpens] = useState(false);
  const [giftData, setGiftData] = useState([])
  const [giftall, setGiftAll] = useState([])
  const [selectedImage, setSelectedImage] = useState('');
  const [giftimage,setGiftimage]=useState(false);
  const [openmodel, setOpenmodel] = useState(false);
   const [channel,setChannel]=useState([])
  const params = useParams();
  const handleOpen = () => setOpens(true);
  const handleCloses = () => setOpens(false);
  const handleOpenimage = (imageURL) => {
    setSelectedImage(imageURL);
    setGiftimage(true);
  };
  const handleCloseimage = () => {
    setGiftimage(false);
    setSelectedImage('');
  };
  const handleOpenmodel = () => setOpenmodel(true);
  const handleClosemodel = () => setOpenmodel(false);
  
  const getUserGift = async () => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
      const res = await axios.get(`${url}/api/SongCollaborationGift/getGiftsByUserId`, config)
      console.log(res.data.data, 'gift,,,,,,,,,,')
      setGiftData(res.data.data)
    } catch (error) {
      console.log(error)
    }
    try {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
      const res = await axios.get(`${url}/api/SongCollaborationGift/getAllGiftsByUserId`, config)
      console.log(res.data.data, 'giftssssssssss')
      setGiftAll(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        const getCollab = async () => {
          try {
            const config = {
              headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            }
            const res = await axios.get(`${url}/api/SongCollaboration/getSongCollaborationByUserID?page=${pagination}`, config)
            console.log(res.data.data,"userssssss");
            
            setChannel(res.data.data,"userssssss")

          } catch (error) {
            console.log(error)
          }
        }
        getCollab()
        break;
      case 1:
        // const getInvites = async () => {
        //   console.log('getInvites');
          
        //   try {
        //     const config = {
        //       headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        //     }
        //     const res = await axios.get(`${url}/api/SongCollaboration/getSongCollaborationUserInvitesByUserID`, config)
        //     console.log(res.data.data,'invites.........')
        //     setInvites(res.data.data)
        //     console.log(res.data.data.length, 'invites');
        //   } catch (error) {
        //     console.log(error)
        //   }
        // }
        // getInvites()
        break;
        case 4:
          // (async () => {
          //   try {
          //     const config = {
          //       headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          //     };
          //     const res = await axios.get(`${url}/api/Karaoke/getKaraokeByUserID?page=${pagination}`, config);
          //     console.log(res.data.data, "Karaoke Data");
          //     if (res.data.data.length < 10) {
          //       setHasMores(false);
          //     }
          //     if (pagination === 0) {
          //       setSongUpload(res.data.data);
          //     } else {
          //       setSongUpload((prevValues) => [...prevValues, ...res.data.data]);
          //     }
          //   } catch (error) {
          //     console.log(error);
          //   }
          // })();
          console.log("pagination........");
          
          break;
      case 2:
        // ( async ()=>{  
        //   try {
        //     const config = {
        //       headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        //     }
        //     const res = await axios.get(`${url}/api/UserPlayList/getUserPlayListByUserID?page=${pagination}`, config)
        //     console.log(res.data.data, 'playlistDatta,,,,,,,,,')
        //     if(res.data.data.length<10){
        //       setMore(false);
        //     }
        //     if(pagination === 0){
        //       setPlaylist(res.data.data)
        //     } else {
        //       setPlaylist((prev)=> [...prev, ...res.data.data])
        //     }
            
        //   } catch (error) {
        //     console.log(error)
        //   }
        // })(pagination);
      
        
        break;
      case 5:
       { const groups = async () => {
          try {
            const config = {
              headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            }
            const res = await axios.get(`${url}/api/channel/getchannelbyuserid`, config)
            console.log(res.data.data, "Groupssssssssss......")
            setGroups(res.data.data)
          } catch (error) {
            console.log(error)
          }
        }

        getUserGift()
         groups()
      }
        break;
      case 3:
        // const userAlbum = async () => {
        //   try {
        //     const config = {
        //       headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        //     }
        //     const res = await axios.get(`${url}/api/UserAlbum/getUserAlbumByUserID`, config)
        //     console.log(res.data.data, "album......")
        //     setAlbum(res.data.data)
        //   } catch (error) {
        //     console.log(error)
        //   }
        // }
        // userAlbum()
        break;
      case 5:
        getUserGift()
    }
  }
const handleLoadMores = () => {
    if (hasMores) {
      setPagination((prevPagination) => prevPagination + 1);
    }
  };
  const [image, setImage] = useState([]);
  useEffect(() => {
    const GetGift = async () => {
      try {
        const config={
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/Gift/getGift`,config);
        console.log(res.data.data, "")
        setImage(res.data.data)


      } catch (error) {
        console.log(error)
      }
      //    
    }
    GetGift()
  }, [])

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // cont
  // const result = findUsersWithMultipleEntries(value);
  // console.log(result);
  console.log(value)
  const handleTiming = async (val) => {
    // const karaokeDetails = await songUpload && songUpload[karaokeInd].KaraokeLyrics 
    // const data ={
    //   lyricTiming : karaokeDetails,
    //   lyricColor : songUpload && songUpload[karaokeInd].Color,
    //   karaokeDetails : {
    //     artist : songUpload && songUpload[karaokeInd].Artist,
    //     genres : songUpload && songUpload[karaokeInd].Genre[0],
    //     title : songUpload && songUpload[karaokeInd].Name,
    //     tags : songUpload && songUpload[karaokeInd].Tags[0],
    //     url : songUpload && songUpload[karaokeInd].CoverImageURL
    //   },
    //   karaokeUrl : {
    //     url : songUpload && songUpload[karaokeInd].KaraokeUrl
    //   }
    // }
    // dispatch(KaraokeTiming(data))
    // setAnchorEl(null)
    // localStorage.setItem('KaraokeEdit',songUpload && songUpload[karaokeInd]._id)
    // navigate('/uploads/songdetails')
    // console.log(val)
    // console.log(val)
    // try{
    //   const config={
    //     headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    //   }
    //   const res = await axios.delete(`${url}/api/Karaoke/deleteKaraoke/${val}`,config)
    //   console.log(res.data.data)
    // } catch (error) {
    //   console.log(error)
    // }
    handleChange(0, 4)
  }
  console.log(songUpload, 'KaraokeDetails')
  const handleClick = (event, val) => {
    setAnchorEl(event.currentTarget);
    console.log(val, 'indValuesss')
    setKaraokeInd(val)
  };
  console.log(karaokeInd)
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditKaraoke = () => {
   
    navigate(`/allsong/${songUpload[karaokeInd]._id}`);
    window.location.reload()
    dispatch(KaraokeEdit({ edited: true }));
    setActionType("edit");
    setOpenDialog(true);
    handleClose();
    
  };
  const handleSongInfo = () => {
    setActionType("info");
    navigate(`/allsong/${songUpload[karaokeInd]._id}`);
    setOpenDialog(true);
    handleClose();
  };
  const handleDeleteKaraoke = () => {
    setActionType("delete");
    setOpenDialog(true);
    handleClose();
  };
  const handleNextPage = () => {
    navigate('/usergroups');
  };
  const handelgroup = () => {
    navigate('/creategroups');
  };
  const handleNavigate =(UserId) => {
    console.log(UserId, 'User------------Id');
    navigate(`/otherProfile/${UserId}`)
    
    }
    const handleClicksing= () => {
      navigate('/');
    };
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', background: 'rgb(255 255 255)' }} variant="fullWidth">
        <Container maxWidth='md'>
          {/* <Tabs  value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab sx={{textTransform:"capitalize"}} label="Recent" {...a11yProps(0)} />
          <Tab sx={{textTransform:"capitalize"}} label="Invites" {...a11yProps(1)} />
          <Tab sx={{textTransform:"capitalize"}} label="Playlists" {...a11yProps(2)} />
          <Tab sx={{textTransform:"capitalize"}} label="Albums" {...a11yProps(3)} />
          <Tab sx={{textTransform:"capitalize"}} label="Karaokes" {...a11yProps(4)} />
          <Tab sx={{textTransform:"capitalize"}} label="Groups" {...a11yProps(5)} />
          <Tab sx={{textTransform:"capitalize"}} label="Gifts" {...a11yProps(6)} />
        </Tabs> */}
          <AntTabs value={value} onChange={handleChange} aria-label="ant example">
            <AntTab label="Channel" {...a11yProps(0)} />
            <AntTab label="Invites" {...a11yProps(1)} />
            <AntTab label="Playlists" {...a11yProps(2)} />
            <AntTab label="Albums" {...a11yProps(3)} />
            <AntTab label="Karaokes" {...a11yProps(4)} />
            <AntTab label="About"  {...a11yProps(5)} />

            {/* <AntTab   label="Groups" {...a11yProps(5)} /> */}

          </AntTabs>
        </Container>
      </Box>
      <Box sx={{ background: 'rgb(240 242 244)', height: '100%' }}>
        <Container maxWidth='md'>
          <CustomTabPanel value={value} index={0}>
            <Card sx={{ boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px', borderRadius: '5px' }}>
              {
               songCollab && songCollab.length !== 0 ? <Box sx={{ display: "flex", flexWrap: 'wrap', whiteSpace: 'nowrap', padding: '15px' }}>
                  {
                    songCollab && songCollab.map((data, index) => (
                      <Box key={index} sx={{ display: "flex", cursor: 'pointer', gap: 1, marginBottom: '15px', padding: '10px' }} >
                        <Box onClick={() => {
                          navigate(`/record/${data._id}`, {
                            state: data._id
                          })
                        }}>

                          <Avatar
                          sx={{ width: 96, height: 96, borderRadius: '5px' }}
                          src={data.CoverImageURL || image2}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          {
                            data.karaokes && data.karaokes.map((val, ind) => (
                              <Box sx={{ width: '150px', overflow: 'hidden', textOverflow: 'ellipsis', color: 'rgb(45, 40, 103)', fontSize: '14px', fontWeight: 700 }}><Box onClick={() => {
                                navigate(`/record/${data._id}`, {
                                  state: data._id
                                })
                              }}>{val.Name}</Box></Box>
                            ))
                          }

                          {
                            data.users && data.users.map((val, ind) => (
                              <Box sx={{ color: 'rgb(170, 173, 180)', fontSize: '14px', fontWeight: 500 ,width: '150px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{val.Name}</Box>
                            ))
                          }
                          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                            <Box sx={{ display: "flex", alignItems: "center", color: "#C8CACF" }} >
                              <Box><PlayArrowIcon sx={{ fontSize: 20, marginTop: '3px' }} /></Box>
                              <Box sx={{ fontSize: 14 }}>{data.NoOfPlay}</Box>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", color: "#C8CACF" }} >
                              <Box><FavoriteIcon sx={{ fontSize: 16, marginTop: '3px' }} /></Box>
                              <Box sx={{ fontSize: 14 }}>{data.NoOfLikes}</Box>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", color: "#C8CACF" }} >
                              <Box><AccessTimeIcon sx={{ fontSize: 16, marginTop: '3px' }} /></Box>
                              <Box sx={{ fontSize: 14 }}><ProfileTimeAgo timestamp={songCollab && data.createdAt} /></Box>
                            </Box>
                          </Box>
                          {
                            data.CollaborationTypeID === "Duet" ? <Box onClick={() => { navigate(`/joinlist/${data._id}/${index}/${data.users[0]?._id}`) }} sx={{ display: 'flex', width: '45px', height: '20px', justifyContent: 'space-between', padding: '5px', background: 'rgb(60 134 247)', borderRadius: '5px' }}>
                              <Box><GroupIcon style={{ color: 'white' }} /></Box>
                              <Box sx={{ color: 'white' }}>{data.NoOfJoin}</Box>
                            </Box> : ''
                          }
                        </Box>
                      </Box>
                    ))
                  }
                    {hasMore && !loading && (
  <Box sx={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "20px" }}>
    <Button
      sx={{
        backgroundColor: "#6C2EFF",
        color: "white",
        fontSize: "15px",
        fontWeight: 700,
        textTransform: "none",
        padding: "10px",
        "&:hover": { backgroundColor: "#5A22CC" },
      }}
      onClick={handleLoadMore}
      disabled={loading}
    >
      Load More
    </Button>
  </Box>
)}

{loading && (
  <Box sx={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "20px" }}>
    <FadeLoader color="LightGray"height={10} width={4} margin={-6} sx={{ color: "#6C2EFF" }} />
  </Box>
)}
                </Box> : 
                <Box sx={{ display: 'flex', justifyContent: 'center', padding: '60px', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                <Box sx={{textAlign:"center"}}>
                <Typography fontSize={18} fontWeight={"bold"}>Channel</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: 500, color: 'rgb(129, 131, 140)' }}>You have no recordings yet</Typography>
                  </Box>
                  <Box>
                    <Button sx={{ backgroundColor: '#6C2EFF', color: 'white', fontSize: '15px', fontWeight: 700, textTransform: 'none', padding: '10px', marginTop: '10px' ,'&:hover': {backgroundColor: '#5A22CC' } }}  onClick={handleOpenmodel}>Start Singing</Button>
                  </Box>
                </Box>
              }
              <Dialog
      open={openmodel}
      onClose={handleClosemodel}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 4,
          padding: 3,
          backgroundColor: "#121212",
          color: "#fff",
          boxShadow: 10,
        },
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 4, p: 3 }}>
        <Box>
          <img src={image1} alt="App Preview" style={{ width: 180, height: "auto", borderRadius: 8 }} />
        </Box>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: "bold", color: "#FFD700", mb: 2 }}>
            Get SparkSinger
          </Typography>
          <Typography variant="body1" sx={{ color: "#B0BEC5", mb: 3 }}>
            Sing and be part of the SparkSinger community!
          </Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Appcard icon={<ImAppleinc fontSize="large" />} tittle1="Download on the" tittle2="App Store" />
            <Appcard icon={<BiLogoPlayStore size={30} />} tittle1="Download on the" tittle2="Google Play" />
          </Box>
        </Box>
      </Box>
      <DialogActions>
        <Button
          onClick={handleClosemodel}
          sx={{
            backgroundColor: "#D32F2F",
            color: "#fff",
            "&:hover": { backgroundColor: "#B71C1C" },
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>

            </Card>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <Card sx={{ boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px' }}>
              {
                songUpload && songUpload.length != 0 ? <Box sx={{ display: 'flex', flexWrap: 'wrap', whiteSpace: 'nowrap', textAlign: 'left' }}>
                  {
                    songUpload && songUpload.map((val, ind) => (
                      
                      <Box>
                        {/* data.CoverImageURL?data.CoverImageURL:coverimage} */}
                        <Box sx={{ display: 'flex', cursor: 'pointer', padding: '10px', marginTop: '10px', '&:hover': { background: '#A0C5E8', borderRadius: '5px' } }}>
                          <Box onClick={() => navigate(`/allsong/${val._id}`)}>
                            <img style={{ width: '64px', height: '64px' }} src={val.CoverImageURL ? val.CoverImageURL : image2} />
                          </Box>
                          <Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', height: '100%', paddingLeft: '8px', marginTop: '7px' }}>
                              <Box onClick={() => navigate(`/allsong/${val._id}`)}>
                                <Typography sx={{ color: 'rgb(45, 40, 103)', width: '160px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{val.Name}</Typography>
                              </Box>
                              <Box>
                                {/* <Typography sx={{ color: 'rgb(170, 173, 180)' }}>vijaykumar</Typography> */}
                                <Typography sx={{ color: 'rgb(45, 40, 103)', width: '160px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{val.users[0]?.Name || 'N/A'}</Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box id="basic-button"
                              aria-controls={open ? 'basic-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? 'true' : undefined}
                              onClick={(e) => { handleClick(e, ind) }}><MoreVertOutlinedIcon style={{ color: 'gray' }} /></Box>
                          </Box>

                          <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          sx: {
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
            boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
          },
        }}
      >
        <MenuItem
          onClick={handleEditKaraoke}
          sx={{
            borderBottom: "2px solid rgb(240, 242, 244)",
            "&:hover": { backgroundColor: "#e3f2fd", color: "#007bff" },
          }}
        >
          Edit Karaoke
        </MenuItem>
        <MenuItem
          onClick={handleSongInfo}
          sx={{
            borderBottom: "2px solid rgb(240, 242, 244)",
            "&:hover": { backgroundColor: "#e3f2fd", color: "#007bff" },
          }}
        >
          Karaoke Info
        </MenuItem>
        <MenuItem
          onClick={handleDeleteKaraoke}
          sx={{
            "&:hover": { backgroundColor: "#e3f2fd", color: "red" },
          }}
        >
          Delete Karaoke
        </MenuItem>
      </Menu>
         {/* Render a dialog dynamically based on action */}
         <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>
          {actionType === "edit" && "Editing Karaoke"}
          {actionType === "info" && "Karaoke Information"}
          {actionType === "delete" && "Confirm Delete"}
        </DialogTitle>
        <DialogContent>
          {actionType === "edit" && <p>You are editing the karaoke song.</p>}
          {actionType === "info" && <p>Here is the song info.</p>}
          {actionType === "delete" && <p>Are you sure you want to delete this song?</p>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Close</Button>
          {actionType === "delete" && <Button color="error" onClick={() => console.log("Deleting...")}>Delete</Button>}
        </DialogActions>
      </Dialog>
                        </Box>
                      </Box>
                    ))
                  }
          {hasMore && !loading && (
  <Box sx={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "20px" }}>
    <Button
      sx={{
        backgroundColor: "#6C2EFF",
        color: "white",
        fontSize: "15px",
        fontWeight: 700,
        textTransform: "none",
        padding: "10px",
        "&:hover": { backgroundColor: "#5A22CC" },
      }}
      onClick={handleLoadMore}
      disabled={loading}
    >
      Load More
    </Button>
  </Box>
)}

{loading && (
  <Box sx={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "20px" }}>
   <FadeLoader color="LightGray"height={10} width={4} margin={-6} sx={{ color: "#6C2EFF" }} />
  </Box>
)}
                </Box> : <Box sx={{ display: 'flex', justifyContent: 'center', padding: '60px', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                <Box sx={{textAlign:"center",gap:1}}>
                <Typography fontSize={18} fontWeight={"bold"}>Karaokes</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: 500, color: 'rgb(129, 131, 140)' }}>You have no uploading song yet</Typography>
                  </Box>
                  <Box>
                    <Button sx={{ backgroundColor: '#6C2EFF', color: 'white', width: '165px', height: '32px', fontSize: '15px', fontWeight: 700, textTransform: 'none','&:hover': {backgroundColor: '#5A22CC' } }} onClick={() => {
                      navigate('/uploads/songupload')
                    }}>Song upload</Button>
                  </Box>
                </Box>
              }

            </Card>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Card sx={{ boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px' }}>
              {
                playlist && playlist.length !== 0 ? <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: "left", whiteSpace: 'nowrap', flex: 1, textAlign: 'left' }}>
                  {
                    playlist && playlist.map((val, ind) => (
                      // console.log(val,"hhggff"),
                      
                      <Box onClick={() => {
                        navigate(`/playlist/${val._id}`)
                      }} sx={{ display: 'flex', margin: '10px', padding: '10px', '&:hover': { background: '#A0C5E8', borderRadius: '5px' },cursor:'pointer' }}>
                        <Box>
                          <img style={{ width: '64px', height: '64px' }} src={val.CoverImageURL || image2} />
                        </Box>
                        <Box>
                          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', height: '100%', paddingLeft: '8px', marginTop: '7px' }}>
                            <Box>
                            <Typography sx={{ color: 'rgb(45, 40, 103)', width: '160px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
  {val.Name || 'Untitled Playlist'}
</Typography>
                            </Box>
                            <Box>
                              <Typography sx={{ color: 'rgb(170, 173, 180)' }}>{val.Count} recording</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ))
                    
                  }
                   {hasMore && !loading && (
  <Box sx={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "20px" }}>
    <Button
      sx={{
        backgroundColor: "#6C2EFF",
        color: "white",
        fontSize: "15px",
        fontWeight: 700,
        textTransform: "none",
        padding: "10px",
        "&:hover": { backgroundColor: "#5A22CC" },
      }}
      onClick={handleLoadMore}
      disabled={loading}
    >
      Load More
    </Button>
  </Box>
)}

{loading && (
  <Box sx={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "20px" }}>
    <FadeLoader color="LightGray"height={10} width={4} margin={-6} sx={{ color: "#6C2EFF" }} />
  </Box>
)}
                </Box> :<Box sx={{ display: 'flex', justifyContent: 'center', padding: '60px', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                  <Box sx={{textAlign:"center",gap:1}}>
                  <Typography fontSize={18} fontWeight={"bold"}>Playlists</Typography>
                      <Typography sx={{ fontSize: '12px', fontWeight: 500, color: 'rgb(129, 131, 140)' }}>You have no playlist song yet</Typography>
                    </Box>
                    <Box>
                    </Box>
                  </Box>
              }

            </Card>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={5}>
            <Card
              sx={{
                boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px',
                borderRadius: '5px',
                padding: '15px',
                position: 'relative',
              }}
            >
              {groups.length !== 0 ? (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2,
      }}
    >
      <Box sx={{ fontSize: 15, fontWeight: 'bold', color: 'rgb(45, 40, 103)' }}>
        {groups.length} Group{groups.length > 1 ? 's' : ''}
      </Box>
      <Button
       
        sx={{
          textTransform: 'none',
          fontWeight:"bold",
          color: '#6C2EFF',
          padding: '6px 12px',
          fontSize: { xs: '12px', sm: '14px' },
        }}
        onClick={handleNextPage}
      >
        View All
      </Button>
    </Box>
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, flexWrap: 'wrap' }}>
  {groups.slice(0, 5).map((data, index) => (
    <Box
      key={index}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
        padding: '5px',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
        gap: 1,
        width: '150px',
        overflow: 'hidden',
        textAlign: 'center'
      }}
    >
      <Box
        sx={{ cursor: 'pointer', display: "flex", flexDirection: "column", alignItems: "center", gap: 1 }}
        onClick={() =>
          navigate(`/groupinfo/${data._id}`, {
            state: data,
          })
        }
      >
        <Avatar
          sx={{
            width: '120px',
            height: '100px',
            borderRadius: '2px',
            mt: 2,
          }}
          src={data.CoverImage}
        />
        <Typography
          sx={{
            width: '100%',
            mt: 1,
            fontSize: 15,
            fontWeight: 'bold',
            color: 'rgb(45, 40, 103)',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {data.ChannelName}
        </Typography>
      </Box>
    </Box>
  ))}
</Box>
  </Box>
) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '60px',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 1,
                  }}
                > 
                <Typography fontSize={18} fontWeight={"bold"}>Group</Typography>
                  <Typography
                    sx={{ fontSize: '12px', fontWeight: 500, color: 'rgb(129, 131, 140)' }}
                  >
                    You have no Create groups
                  </Typography>
                  <Button
  sx={{
    backgroundColor: "#6C2EFF",
    color: "white",
    fontSize: "15px",
    fontWeight: 700,
    textTransform: "none",
    padding: "10px",
    marginTop: "10px",
    "&:hover": {
      backgroundColor: "#4A1EBB", // Change this to your desired hover color
    },
  }}
  onClick={handelgroup}
>
  Create groups
</Button>
                </Box>
              )}
            </Card>

            <Box sx={{ mt: 4 }}>
              <Card
                 sx={{
                  boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px',
                  borderRadius: '5px',
                  padding: '15px',
                  position: 'relative',
                }}
              >
                 {giftData.length !== 0 ? (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2,
      }}
    >
      <Box sx={{ fontSize: 15, fontWeight: 'bold', color: 'rgb(45, 40, 103)' }}>
        {giftData.length} Gift{giftData.length > 1 ? 's' : ''}
      </Box>
      <Button
       
        sx={{
          textTransform: 'none',
          fontWeight:"bold",
          color: '#6C2EFF',
          padding: '6px 12px',
          fontSize: { xs: '12px', sm: '14px' },
        }}
        onClick={handleOpen}
      >
        View All
      </Button>
    </Box>
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, flexWrap: 'wrap' }}>
      {giftData.slice(0, 5).map((data, index) => ( // Only take the first 5 items
        <Box
          key={index}
          sx={{
            padding: '10px',
            borderRadius: '5px',
            backgroundColor: '#f9f9f9',
            gap: 2,
            textAlign: 'center',
            cursor:'pointer'
          }}
        >
          <Box>
            <Avatar
              sx={{
                width: '120px',
                height: '100px',
                borderRadius: '2px',
                mt: 2,
              }}
              src={data.GiftImageURL}
              onClick={() => handleOpenimage(data.GiftImageURL)}
            />
            <Box
              sx={{
                height: '100%',
                paddingLeft: '8px',
                marginTop: '7px',
                fontSize: 15,
                fontWeight: 'bold',
                color: 'rgb(45, 40, 103)',
              }}
            >
              {data.count}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  </Box>
)  : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '60px',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Typography fontSize={18} fontWeight={"bold"}>Gifts</Typography>
                  <Typography
                    sx={{ fontSize: '12px', fontWeight: 500, color: 'rgb(129, 131, 140)' }}
                  >
                    You have not received any gifts yet.
                  </Typography>
                  {/* <Button
                    sx={{
                      backgroundColor: '#6C2EFF',
                      color: 'white',
                      fontSize: '15px',
                      fontWeight: 700,
                      textTransform: 'none',
                      padding: '10px',
                      marginTop: '10px',
                    }}
                    onClick={handelgroup}
                  >
                    Create groups
                  </Button> */}
                </Box>
              )}
              </Card>
              <Modal open={giftimage} onClose={handleCloseimage}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
          }}
        >
          <img
            src={selectedImage}
            alt="Gift"
            style={{
              width: 280,
             
              borderRadius: '8px',
            }}
          />
        </Box>
      </Modal>
              <Modal
                open={opens}
                onClose={handleCloses}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Card sx={{ height: '480px', width: '400px' }}>
                  {/* Header Section */}
                  <Box
  sx={{
    display: 'flex',
    alignItems: 'center', // Ensures vertical alignment
    justifyContent: 'space-between',
    padding: '10px',
  }}
>
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
    <Box
      sx={{
        color: 'rgb(45, 40, 103)',
        fontSize: '17px',
        fontWeight: 700,
      }}
    >
      Gifts
    </Box>
  </Box>
  <Box sx={{ cursor: 'pointer' }}>
    <Cancel
      onClick={handleCloses}
      style={{ fontSize: '30px', color: 'rgb(192, 204, 216)' }}
    />
  </Box>
</Box>

                  {/* Tab Section */}
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        textAlign: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderTop: '3px solid #EAE9F0',
                        background: 'rgb(240, 242, 244)',
                      }}
                    >
                      <Box sx={{ marginTop: '15px', marginBottom: '10px' }}>
                        <button
                          onClick={() => setText(true)}
                          style={{
                            width: '130px',
                            height: '32px',
                            background: text ? 'rgb(114, 140, 166)' : 'rgb(255, 255, 255)',
                            color: text ? 'rgb(255, 255, 255)' : 'rgb(114, 140, 166)',
                            borderTopLeftRadius: '3px',
                            borderBottomLeftRadius: '3px',
                            border: 'none',
                            fontSize: '14px',
                            fontWeight: 700,
                            cursor: 'pointer',
                          }}
                        >
                          All Gifts
                        </button>
                        <button
                          onClick={() => setText(false)}
                          style={{
                            width: '130px',
                            height: '32px',
                            background: text ? 'rgb(255, 255, 255)' : 'rgb(114, 140, 166)',
                            color: text ? 'rgb(114, 140, 166)' : 'rgb(255, 255, 255)',
                            borderTopRightRadius: '3px',
                            borderBottomRightRadius: '3px',
                            border: 'none',
                            fontSize: '14px',
                            fontWeight: 700,
                            cursor: 'pointer',
                          }}
                        >
                          Top Gifters
                        </button>
                      </Box>
                    </Box>
                  </Box>

                  {/* Content Section */}
                  <Box sx={{ height: 300, overflowY: 'scroll', width: '100%', }}>
                    {text ? (
                      giftall && giftall ? (
                        giftall.map((val, ind) => (
                          <Box 
                              key={ind} 
                              sx={{ 
                                display: 'flex', 
                                alignItems: 'center', // Align items vertically center
                                padding: '10px', 
                                borderBottom: '1px solid #ddd' // Optional separator
                              }}
                            >
                              {/* Avatar */}
                              <Avatar 
                                src={val.ProfilePhoto} 
                                onClick={() => handleNavigate(val._id)} 
                                sx={{ width: 50, height: 50, marginRight: '10px' ,cursor:'pointer'}} 
                              />
                          
                              {/* Name and Time */}
                              <Box sx={{ flexGrow: 1 }}> 
                                <Typography 
                                  color="rgb(45, 40, 103)" 
                                  fontWeight={700} 
                                  fontSize={16}
                                >
                                  {val.UserName}
                                </Typography>
                                <Typography 
                                  color="rgb(45, 40, 103)" 
                                  fontWeight={400} 
                                  fontSize={10}
                                  sx={{ marginTop: '3px' }}
                                >
                                  <Box sx={{ fontSize: 14 }}>
                                    <ProfileTimeAgo timestamp={val.createdAt} />
                                  </Box>
                                </Typography>
                              </Box>
                          
                              {/* Gift Image */}
                              <Avatar 
                                src={val.GiftImageURL}
                                sx={{ width: 50, height: 50, borderRadius: 0 }} 
                              />
                            </Box>
                        ))
                      ) : (
                        <Typography color="rgb(114, 140, 166)" textAlign="center" sx={{ marginTop: '20px', fontSize: '14px' }}>
                          No Requests Found
                        </Typography>
                      )
                    ) : (
                      
                        giftall && giftall ? (
                          giftall && giftall.map((val, ind) => (
                            <Box 
                              key={ind} 
                              sx={{ 
                                display: 'flex', 
                                alignItems: 'center', // Align items vertically center
                                padding: '10px', 
                                borderBottom: '1px solid #ddd' // Optional separator
                              }}
                            >
                              {/* Avatar */}
                              <Avatar 
                                src={val.ProfilePhoto} 
                                onClick={() => handleNavigate(val._id)} 
                                sx={{ width: 50, height: 50, marginRight: '10px',cursor:"pointer" }} 
                              />
                          
                              {/* Name and Time */}
                              <Box sx={{ flexGrow: 1 }}> 
                                <Typography 
                                  color="rgb(45, 40, 103)" 
                                  fontWeight={700} 
                                  fontSize={16}
                                >
                                  {val.UserName}
                                </Typography>
                                <Typography 
                                  color="rgb(45, 40, 103)" 
                                  fontWeight={400} 
                                  fontSize={10}
                                  sx={{ marginTop: '3px' }}
                                >
                                  <Box sx={{ fontSize: 14 }}>
                                    <ProfileTimeAgo timestamp={val.createdAt} />
                                  </Box>
                                </Typography>
                              </Box>
                          
                              {/* Gift Image */}
                              <Avatar 
                                src={val.GiftImageURL}
                                sx={{ width: 50, height: 50, borderRadius: 0 }} 
                              />
                            </Box>
                          ))
                        )  : (
                        <Typography color="rgb(114, 140, 166)" textAlign="center" sx={{ marginTop: '20px', fontSize: '14px' }}>
                          No Invites Found
                        </Typography>
                      )
                    )}
                  </Box>
                </Card>
              </Modal></Box>

          </CustomTabPanel>



          <CustomTabPanel value={value} index={1}>
            <Card sx={{ boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px', borderRadius: '5px' }}>
              {
                invites.length !== 0 ? <Box sx={{ display: "flex", flexWrap: 'wrap', whiteSpace: 'nowrap', padding: '15px' }}>
                  {
                    invites && invites.map((data, index) => (
                      <Box key={index} sx={{ display: "flex", cursor: 'pointer', gap: 1, marginBottom: '15px', padding: '10px' }} >
                        <Box onClick={() => {
                          navigate(`/record/${data._id}`, {
                            state: data._id
                          })
                        }}>
                          <Avatar sx={{ width: '96px', height: '96px', borderRadius: '5px' }} src={data.CoverImageURL|| image2} />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          {
                            data.karaokes && data.karaokes.map((val, ind) => (
                              <Box sx={{ width: '150px', overflow: 'hidden', textOverflow: 'ellipsis', color: 'rgb(45, 40, 103)', fontSize: '14px', fontWeight: 700 }}><Box onClick={() => {
                                navigate(`/record/${data._id}`, {
                                  state: data._id
                                })
                              }}>{val.Name}</Box></Box>
                            ))
                          }

                          {
                            data.users && data.users.map((val, ind) => (
                              <Box sx={{ color: 'rgb(170, 173, 180)', fontSize: '14px', fontWeight: 500 }}>{val.Name}</Box>
                            ))
                          }
                          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                            <Box sx={{ display: "flex", alignItems: "center", color: "#C8CACF" }} >
                              <Box><PlayArrowIcon sx={{ fontSize: 20, marginTop: '3px' }} /></Box>
                              <Box sx={{ fontSize: 14 }}>{data.NoOfPlay}</Box>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", color: "#C8CACF" }} >
                              <Box><FavoriteIcon sx={{ fontSize: 16, marginTop: '3px' }} /></Box>
                              <Box sx={{ fontSize: 14 }}>{data.NoOfLikes}</Box>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", color: "#C8CACF" }} >
                              <Box><AccessTimeIcon sx={{ fontSize: 16, marginTop: '3px' }} /></Box>
                              <Box sx={{ fontSize: 14 }}><ProfileTimeAgo timestamp={songCollab && data.createdAt} /></Box>
                            </Box>
                          </Box>
                          <Box sx={{ display: "flex", gap: 1 }}>
                          <Box
  sx={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center", // Centers content horizontally
    color: "white",
    backgroundColor: "#C8CACF",
    borderRadius: "4px",
    width: "70px", // Increased width for better appearance
    height: "30px", // Increased height for better spacing
  }}
>
  <Button
    variant="contained"
    color="primary"
    startIcon={<Person />}
    sx={{ textTransform: "none", fontSize: "14px", padding: "5px 10px" }} // Adjust text size & padding
    onClick={handleOpeni}
  >
    Invite
  </Button>
</Box>
                            {
                              data.CollaborationTypeID === "Duet" ? <Box onClick={() => { navigate(`/joinlist/${data._id}/${index}/${data.users[0]?._id}`) }} sx={{ display: 'flex', width: '45px', height: '20px', justifyContent: 'space-between', padding: '5px', background: 'rgb(60 134 247)', borderRadius: '5px' }}>
                                <Box><GroupIcon style={{ color: 'white' }} /></Box>
                                <Box sx={{ color: 'white' }}>{data.NoOfJoin}</Box>
                              </Box> : ''
                            }
                          </Box>
                        </Box>
                      </Box>
                    ))
                  }{hasMore && !loading && (
                    <Box sx={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "20px" }}>
                      <Button
                        sx={{
                          backgroundColor: "#6C2EFF",
                          color: "white",
                          fontSize: "15px",
                          fontWeight: 700,
                          textTransform: "none",
                          padding: "10px",
                          "&:hover": { backgroundColor: "#5A22CC" },
                        }}
                        onClick={handleLoadMore}
                        disabled={loading}
                      >
                        Load More
                      </Button>
                    </Box>
                  )}
                  
                  {loading && (
                    <Box sx={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "20px" }}>
                      <FadeLoader color="LightGray"height={10} width={4} margin={-6} sx={{ color: "#6C2EFF" }} />
                    </Box>
                  )}
                </Box> : <Box sx={{ display: 'flex', justifyContent: 'center', padding: '60px', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                <Box sx={{textAlign:"center"}}>
                <Typography fontSize={18} fontWeight={"bold"}>Invites</Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: 500, color: 'rgb(129, 131, 140)' }}>You have no invites yet</Typography>
                  </Box>
                  <Box>
                    <Button sx={{ backgroundColor: '#6C2EFF', color: 'white', fontSize: '15px', fontWeight: 700, textTransform: 'none', padding: '10px', marginTop: '10px' ,'&:hover': {backgroundColor: '#5A22CC' } }} onClick={handleOpeni}>Start Singing</Button>
                  </Box>
                </Box>
              }
            </Card>
          </CustomTabPanel>
          <Modal open={openi} onClose={handleClosei} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Box
        sx={{
          width: "600px",
          backgroundColor: "#121212",
          padding: 4,
          margin: "auto",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: 3,
          boxShadow: 24,
          textAlign: "center",
        }}
      >
        {/* Close Button */}
        <IconButton
          onClick={handleClosei}
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
            color: "#555",
            "&:hover": { color: "#D32F2F" },
          }}
        >
          <Close />
        </IconButton>

        {/* Content Section */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
          {/* Image */}
          <Box>
            <img src={image1} alt="App Preview" style={{ width: 180, borderRadius: 8 }} />
          </Box>

          {/* Text & Buttons */}
          <Box>
          
          
          <Typography variant="h6" sx={{ fontWeight: "bold", color: "#FFD700", mb: 2 }}>
              Get the SparkSinger App
            </Typography>
            <Typography id="modal-description" variant="body1" sx={{ color: "#B0BEC5", mb: 3 }}>
              Sing your favorite songs and immerse yourself in a vibrant community of music lovers. Download today!
            </Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Appcard icon={<ImAppleinc fontSize="large" />} tittle1="Download on the" tittle2="App Store" />
              <Appcard icon={<BiLogoPlayStore size={30} />} tittle1="Download on the" tittle2="Google Play" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
          {/* <CustomTabPanel value={value} index={5}>
        <Card sx={{boxShadow:'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px'}}>
        <Box sx={{display:'flex',textAlign:'center',padding:'20px',alignItems:'center',justifyContent:'center',flexDirection:'column',marginTop:'20px'}}>
              <Box sx={{color:'gray'}}>You do not participate in any groups.<br/>Get involved!</Box>
              <Button sx={{color:'white',background:'#6C2EFF',padding:'10px',textTransform:'none',marginTop:'10px',fontSize:'15px',fontWeight:700}}>Explore Groups</Button>
          </Box>
        </Card>
      </CustomTabPanel> */}
          <CustomTabPanel value={value} index={3}>
            <Card sx={{ boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px' }}>
              {
                album.length !== 0 ? <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: "left", whiteSpace: 'nowrap', flex: 1, textAlign: 'left' }}>
                  {
                    album && album.map((val, ind) => (
                      // console.log(val,"albumssss"),
                      
                      <Box onClick={() => {
                        navigate(`/albums/${val._id}`)
                      }} sx={{ display: 'flex', margin: '10px',cursor:"pointer", padding: '10px', '&:hover': { background: '#A0C5E8', borderRadius: '5px' } }}>
                        <Box>
                          <img style={{ width: '64px', height: '64px' }} src={val.CoverImageURL} />
                        </Box>
                        <Box>
                          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', height: '100%', paddingLeft: '8px', marginTop: '7px' }}>
                            <Box>
                              <Typography sx={{ color: 'rgb(45, 40, 103)', width: '160px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{val.Name || 'Untitled Albumlist' }</Typography>
                            </Box>
                            <Box>
                              <Typography sx={{ color: 'rgb(170, 173, 180)' }}>{val.Count} albums</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ))
                  }
                   {hasMore && !loading && (
  <Box sx={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "20px" }}>
    <Button
      sx={{
        backgroundColor: "#6C2EFF",
        color: "white",
        fontSize: "15px",
        fontWeight: 700,
        textTransform: "none",
        padding: "10px",
        "&:hover": { backgroundColor: "#5A22CC" },
      }}
      onClick={handleLoadMore}
      disabled={loading}
    >
      Load More
    </Button>
  </Box>
)}

{loading && (
  <Box sx={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "20px" }}>
    <FadeLoader color="LightGray"height={10} width={4} margin={-6} sx={{ color: "#6C2EFF" }} />
  </Box>
)}
                </Box> :
                  <Box sx={{ display: 'flex', justifyContent: 'center', padding: '60px', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                  <Box sx={{textAlign:"center",gap:1}}>
                  <Typography fontSize={18} fontWeight={"bold"}>Albums</Typography>
                      <Typography sx={{ fontSize: '12px', fontWeight: 500, color: 'rgb(129, 131, 140)' }}>You have no albums song yet</Typography>
                    </Box>
                    <Box>
                    </Box>
                  </Box>
              }

            </Card>
          </CustomTabPanel>
          {/* <CustomTabPanel value={value} index={3}>
            <Card sx={{ boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px' }}>
              {
                album.length !== 0 ?
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', whiteSpace: 'nowrap', textAlign: 'left' }}>
                    {
                      album && album.map((val, ind) => (
                        <Box sx={{  display: 'flex', flexWrap: 'wrap', justifyContent: "left", whiteSpace: 'nowrap', flex: 1, textAlign: 'left' }}>
                          <Box sx={{ display: 'flex', flexDirection: "row" }}>
                            <Box>
                              <img src={val.CoverImageURL} style={{ width: '64px', height: '64px' }} />
                            </Box>
                            <Box>
                            <Typography sx={{ color: 'rgb(45, 40, 103)', width: '160px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{val.Name}</Typography>
                            </Box>
                            <Box>
                              <Typography sx={{ color: 'rgb(170, 173, 180)', width: '160px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{val.Count} albums</Typography>
                            </Box>
                          </Box>
                        </Box>
                      ))
                    }
                  </Box> : <Box sx={{ display: 'flex', textAlign: 'center', padding: '20px', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <Box><IoAlbums color='rgb(192, 204, 216)' style={{ textAlign: 'center' }} size={40} /></Box>
                    <Box sx={{ color: 'gray' }}>Craete Your own albums!</Box>
                    <Button sx={{ color: 'white', background: '#6C2EFF', padding: '10px', textTransform: 'none', marginTop: '10px', fontSize: '15px', fontWeight: 700 }}>Craete Album</Button>
                  </Box>
              }
            </Card>
          </CustomTabPanel> */}
        </Container>
        {/* <Box sx={{mt:2}}><Spark/></Box> */}

      </Box>
  


    </Box>
  );
}
