import { Box, Container } from '@mui/material'
import React from 'react'
import Header from './componenets/header'
import Searchbar from './componenets/search-bar'
import Grouplist from './componenets/group-list'
import Meta from '../../components/meta_data'

const Group = () => {

  return (
   
    <Box sx={{backgroundColor:'white',minHeight:'100vh'}}>
      <Meta title="Check out all Sparksinger groups and become part of the singing community!" description="SparkSinger is a social karaoke app where you can enjoy millions of songs, connect with friends worldwide, and even sing with top artists. Join the global singing community today! | SparkSinger Social" keywords="Karaoke, Singing, Social Singing" />
     <Container >
     <Box sx={{padding:5}}><Header/></Box>
        <Box><Searchbar/></Box>
         <Box>
         <Grouplist/>
         </Box>
     </Container>
    </Box>
  )
}

export default Group