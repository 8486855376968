import React from "react";
import { Box, Card, CardContent, Typography, Button } from "@mui/material";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";

const apps = [
  {
    name: "Sparksinger",
    description:
     "Sing, record, and create stunning music videos with SparkSinger—the ultimate social singing app! Discover millions of karaoke tracks, connect with friends, and duet with top artists like Ed Sheeran, Kelly Clarkson, and Luis Fonsi!",
    icon: "🎤",
  },
  // {
  //   name: "Style Studio",
  //   description:
  //     "Transform the way you sing & record in Smule with Style Studio! Choose from a free library of audio & video effects and design your own Styles to add a special creative spark to your Smule performances.",
  //   icon: "🎨",
  // },
];

const AppGrid = () => {
  return (
    <Box textAlign="center" p={4} minHeight={"100vh"} backgroundColor={"white"}>
      <Typography variant="h4" gutterBottom>
        Our Apps
      </Typography>
      <Typography variant="subtitle1" mb={3}>
      Love music? Experience the ultimate interactive music journey with Sparksinger's apps!
      </Typography>
      <Box display="flex" justifyContent="center" gap={4}>
        {apps.map((app, index) => (
          <Card key={index} sx={{ maxWidth: 300, p: 2, textAlign: "center",backgroundColor:"#F0F2F4" }}>
            <Typography variant="h3">{app.icon}</Typography>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {app.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {app.description}
              </Typography>
              <Box mt={2} display="flex" flexDirection="column" alignItems="center" gap={1}>
                <Button variant="contained" startIcon={<AppleIcon />} fullWidth>
                  App Store
                </Button>
                <Button variant="contained" startIcon={<AndroidIcon />} fullWidth>
                  Google Play
                </Button>
              </Box>
              <Typography variant="caption" display="block" mt={2}>
                Let's Music Together™
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default AppGrid;
