import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Divider, FormControl, FormControlLabel, Modal , Checkbox , CircularProgress, Typography} from '@mui/material';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import Menu_Item from '../data/menu-item';
import axios from 'axios';
import { url } from '../config/config';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import FlagIcon from '@mui/icons-material/Flag';
import CancelIcon from '@mui/icons-material/Cancel';
import { FcApproval } from "react-icons/fc";
import { IoIosStar } from "react-icons/io";
import Authmodel from './auth_model';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius : '5px',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function BasicMenu({value,id}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [reportModal,setReportModal] = React.useState(false)
  const [reportData,setReportData] = React.useState([])
  const [selectedReportCategory,setSelectedReporCategory] = React.useState('');
  const [discription,setDiscription] = React.useState(false)
  const [discriptionText,setDiscriptionText] = React.useState('')
  const [reportSubmitted,setReportSubmitted] = React.useState(false);
  const [playlist,setPlaylist] = React.useState([])
  const [favorite,setFavorite] = React.useState(false)
  const [playListDetails,setPlayListDetails] = React.useState([])
  const tokenValue = localStorage.getItem('token');
  const [authController,setAuthController] = React.useState(false)
  const open = Boolean(anchorEl);
  const params = useParams()
//  console.log(value,'ksksksksks');
  
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation()
  };
  // console.log(value,"hdhdhdhhd")
  const handleClose = () => {
    setAnchorEl(null);
  };
  // console.log(tokenValue,'tokenValuessssss')
  React.useEffect(() => {
    const getUserPlaylist = async () => {
      try{
        const config={
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = tokenValue !== null && await axios.get(`${url}/api/UserPlayList/getUserPlayListByUserID/`,config)
        console.log(res,'playlistDatatssssss')
        setPlaylist(res.data.data)
       }catch(error){
        console.log(error)
       }
     }
     getUserPlaylist()
  },[])
  React.useEffect(() => {
    const getReportTypes =  async () => {
      try{
        const config={
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        
        const response = await axios.get(`${url}/api/ReportType/getReportType`,config)
        setReportData(response.data.data)
      }catch (error){
         console.log(error)
      }
    }
    getReportTypes()
  },[])
  React.useEffect(() => {
     const getPlaylistInfo = async () => {
      try{
        const config={
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const response = tokenValue !== null && await axios.get(`${url}/api/UserPlayList/web-getUserPlayListByUserID?SongCollaborationId=${params.id}`,config)
        console.log(response.data.data,'playlistRespomse')
        setPlayListDetails(response.data.data)
        setFavorite(response?.data?.data[0]?.isFavorite)
      }catch (error){
        console.log(error)
      }
     }
     getPlaylistInfo()
  },[params.id])
   const handleClcik =  (ind) => {
    console.log(ind,'indexId')
     switch(ind) {
         case 0 :
         const getCollab = async () => {
          try{
            const config={
              headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            }
            const data = await {
              UserPlayListID : playlist[0]._id,
              SongCollaborationID : params.id,
              UserID: playlist[0].UserID
            }
            const res = await axios.post(`${url}/api/SongCollaborationPlayList/createSongCollaborationPlayList`,data,config)
            setFavorite(true)
          } catch(error){
             console.log(error)
          }
         }
         getCollab()
          break;
          case 1 :
             navigate(`/allsong/${value}`)
             break;
             case 2 :
             navigate(`/logdetails/${value}`)
             break;
             case 5 :
              if(localStorage.getItem('token') !== null){
                navigate(`/songcollabedit/${params.id}`)
              }else{
                  setAuthController(true)
              }
             break
             case 4 : 
               setReportModal(true)
      }
   }
   const userTokenData = localStorage.getItem('token') !== null ? jwtDecode(localStorage.getItem('token')) : ''
   console.log(userTokenData,"jjdjsjshshs");
   
   const handleReportModal = () => {
    setReportModal(false)
   }

   const handleCategorySelected = (data) => {
     setSelectedReporCategory(data)
   }
  const handleDescriptionChange = (e) => {
    if(discription){
      setDiscription(false)
    }else{
      setDiscription(true)
    }
  }

  const handleReportSupport  = async () => {
    setReportSubmitted(true)
    try{
      const config={
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
      const data = {
        Description : discriptionText,
        ReportTypeID : selectedReportCategory._id,
        ReportedByUserID : userTokenData.userId,
        ResourceID : params.id
      }
       const Response = await axios.post(`${url}/api/Report/createReport/SongCollaboration`,data,config)
       console.log(Response.data,'dataValuessssss')
       function modalLoderFunc () {
        if(Response.data.message === 'Document Create successfully'){
          setSelectedReporCategory('')
          setDiscription(false)
          setReportSubmitted(false)
          setReportModal(false)  
         }
       }
       setTimeout(modalLoderFunc,4000)
    }catch (error){
      console.log(error)
    }
  }
 

 const handleAuthModalClose = () => {
  setAuthController(false)
 }

  return (
    <div>
       <Box onClick={handleClick}><MoreVertIcon /></Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{padding:'0',menuItem: {
            "&:hover": {
              backgroundColor: "rgb(69, 33, 182)"
            }
          }}}
      >
          {
            Menu_Item.length>0 && Menu_Item.map((data,index)=>(
              // console.log(data.id,"jdjjdj"),
              
              data.id === 2 && userTokenData.userId === id ?
          <MenuItem sx={{padding:'0',"&hover":{backgroundColor:"rgb(99 45 229) !important"}}}   divider={true}>
         <Box onClick={() => handleClcik(index)} sx={{display:"flex",padding:'10px',color:"#2D2867",fontSize:"15px",fontWeight:700,justifyContent:"space-between",width:"250px","&:hover": {
backgroundColor: "rgb(99 45 229) !important",color:'white !important'
}}}>
  
         <Box>{data.tittle}</Box>
         
         {
                 data.id === 3 && favorite ? <Box><IoIosStar fill='rgb(255 206 66)' size={25}/></Box> :    <Box>{data.icon}</Box>
                }
     </Box>
 </MenuItem> : data.id !== 2 &&  <MenuItem sx={{padding:'0',"&hover":{backgroundColor:"rgb(99 45 229) !important"}}}   divider={true}>
         <Box onClick={() => handleClcik(index)} sx={{display:"flex",padding:'10px',color:"#2D2867",fontSize:"15px",fontWeight:700,justifyContent:"space-between",width:"250px","&:hover": {
backgroundColor: "rgb(99 45 229) !important",color:'white !important'
}}}>
  
         <Box>{data.tittle}</Box>
         
         {
                 data.id === 3 && favorite ? <Box><IoIosStar fill='rgb(255 206 66)' size={25}/></Box> :    <Box>{data.icon}</Box>
                }
                
     </Box>
     
     
 </MenuItem> 
 
         ))
        }
         <Box>
    </Box>
      </Menu>
      <Modal
        open={reportModal}
        onClose={handleReportModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 300 }}>
          {
            selectedReportCategory === '' &&  <Box sx={{display:'flex',justifyContent:'flex-end',cursor:'pointer'}} onClick={() => {setReportModal(false)}}>
            <CancelIcon style={{fontSize:'30px',color:'rgb(192, 204, 216)'}}/>
            </Box>
          }
         <Box sx={{textAlign:'center',color:'rgb(45, 40, 103)'}}>
          <FlagIcon color='rgb(45, 40, 103)'/>
         </Box>
          <Box sx={{fontSize:'24px',fontWeight:700,color:'rgb(45, 40, 103)',textAlign:'center'}}>Report <span style={{fontSize:'24px',fontWeight:700,color:'rgb(45, 40, 103)'}}>{selectedReportCategory.Name}</span></Box>
          {/* <Box sx={{fontSize:'24px',fontWeight:700,color:'rgb(45, 40, 103)',textAlign:'center'}}>{selectedReportCategory.Name}</Box> */}
          <Box sx={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:500,mt:.5,textAlign:'center'}}>Are you sure you want to report this recording for a community <span style={{color:'rgb(114, 140, 166)',fontSize:'14px',fontWeight:500}}>{selectedReportCategory.Name}?</span></Box>
          {
            selectedReportCategory !== '' &&  <Box sx={{display:'flex',mt:1,alignItems:'center',gap:.5}}>
            <Box><Checkbox sx={{padding:0}} size='small' onChange={handleDescriptionChange} checked={discription}/></Box> 
            <Box sx={{fontSize:'14px',color:'rgb(45, 40, 103)',fontWeight:700}}>Description</Box>
         </Box>
          }
          {
            discription && selectedReportCategory !== '' && <Box sx={{mt:1}}>
              <textarea onChange={(e) => {setDiscriptionText(e.target.value)}} style={{width:'100%',height:'80px',paddingInlineStart:'10px',paddingTop:'5px'}}></textarea>
            </Box>
          }
         {
           selectedReportCategory !== ''  ? <Box sx={{display:'flex',flexDirection:'column',gap:1,mt:3}}>
              <Box sx={{width:'100%',textAlign:'center',backgroundColor:'rgb(45, 40, 103)',color:'white',height:'40px',borderRadius:'5px',fontSize:'17px',fontWeight:700,alignItems:'center',display:'flex',justifyContent:'center',cursor:'pointer'}} onClick={handleReportSupport}>
                {
                  reportSubmitted ? <CircularProgress size={25} sx={{color:'white',fontSize:'10px'}}/> : <Box>Report</Box>
                }
              </Box>
              <Box sx={{width:'100%',textAlign:'center',backgroundColor:'rgb(228, 233, 238)',color:'rgb(114, 140, 166)',height:'40px',borderRadius:'5px',fontSize:'17px',fontWeight:700,alignItems:'center',display:'flex',justifyContent:'center',cursor:'pointer'}} onClick={() => {
                setSelectedReporCategory('')
                setDiscription(false)
                }}>Cancel</Box>
           </Box> :  <>
         {
          reportData && reportData.map((val,ind) => (
           
            
            <>
             {
               val.resourceTypes && val.resourceTypes.map((data,index) => (
                   data.Name === 'SongCollaboration' && <p onClick={() => {handleCategorySelected(val)}} style={{color:'red',fontSize:'17px',fontWeight:700,borderBottom:'1px solid rgb(192 204 216)',padding:'5px',cursor:'pointer'}}>{val.Name}</p>
                ))
             }
            </>
          ))
         }
         </>
         }
        </Box>
      </Modal>
      <Authmodel open={authController} HandleClose={handleAuthModalClose}/>
    </div>
  );
}