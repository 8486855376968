import React, { useState } from "react";
import { Box, Button, Hidden, Typography } from "@mui/material";
import Grid from '@mui/material/Grid';
import DoneIcon from '@mui/icons-material/Done';
import Divider from '@mui/material/Divider';
import { useNavigate } from "react-router-dom";


const Subscribe = () => {
    const navigate = useNavigate();
    const [subPlan,setSubPlan] = useState(false)
    const discount = 10;
    const pro = 20;
    const premium = 40;
    const prodisrate = pro * 12 * 10 / 100
    const premiumdisrate = premium * 12 * 10 / 100
    const AnnuallyPro = pro * 12 - prodisrate
    const AnnuallPremium = premium * 12 - premiumdisrate

    const handlePaymentPortal = () => {
        navigate('/payment')
    }

    return(
     <Box  sx={{backgroundColor:'#0E0C15',color:'white',height:{xl:'100vh',ms:'100%'}}}>
        <Box sx={{textAlign:'center',padding:2,marginBottom:'20px'}}>
            <Typography sx={{fontSize:'25px',fontWeight:'bold',marginBottom:'10px'}}>Pay once, use forever</Typography>
            <Typography sx={{color:'#646071'}}>Unlimited solos, access on both iOS and Android, no ads— gives you the best of Spark Singer</Typography>
            <Typography sx={{color:'#646071'}}>Click below to get our online-only deal</Typography>
        </Box>
        <Box sx={{display:'flex',justifyContent:'center',textTransform:'uppercase',marginBottom:'40px'}}>
            <Box sx={{boxShadow:'rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset',display:'flex',borderTop:'1px solid #623C6E',borderLeft:'.5px solid #644271',borderRight:'1px solid #563761',borderBottom:'#2A2830',borderRadius:'5px',padding:'2px'}}>
            <Button onClick={() => {setSubPlan(false)}} sx={{width:'148px',height:'40px',color:'white',fontSize:'10px',fontWeight:'bold',backgroundColor:subPlan ? '' : '#252134'}}>
                Monthly
            </Button>
            <Button onClick={() => {setSubPlan(true)}} sx={{width:'148px',height:'40px',color:'white',fontSize:'10px',fontWeight:'bold',backgroundColor:subPlan? '#252134' : ''}}>
                Annually<span style={{marginLeft:'10px',backgroundColor:'#AC6AFF',padding:'5px',borderRadius:'5px',paddingTop:'2px',paddingBottom:'2px'}}>-{discount}%</span>
            </Button>
            </Box>
        </Box>
        <Grid container>
            <Grid item md={2} xl={3}></Grid>
            <Grid item md={8} xl={6}>
                <Box sx={{display:'flex',gap:3,marginBottom:'20px'}}>
                    <Box sx={{textAlign:'left',boxShadow: 'rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset',padding:'20px',borderRadius:'10px',paddingTop:'2px',marginTop:'20px'}}>
                       <h4 style={{color:'#FED37C',fontSize:'20px',marginBottom:'10px'}}>Free</h4>
                       <p style={{color:'#646071',margin:'0'}}>Music app to mix music, sing along and enjoy it.</p>
                       <Box sx={{display:'flex',textAlign:'left',gap:1,marginTop:'10px',marginBottom:'10px'}}>
                        <p style={{fontSize:'20px',margin:0,paddingTop:'20px'}}>$</p>
                        <p style={{fontSize:'50px',margin:0}}>0</p>
                       </Box>
                       <Button sx={{backgroundColor:'white',fontWeight:'bold',width:'95%',fontSize:'10px',color:'black',borderTopRightRadius:'20px',marginBottom:'30px'}} onClick={handlePaymentPortal}>Get Started</Button>
                       <Box sx={{display:'flex',textAlign:'left',gap:1}}>
                        <Box>
                            <DoneIcon style={{fontSize:'15px',backgroundColor:'#B26DFA',borderRadius:'50%',color:'black',marginTop:'3px'}}/>
                        </Box>
                        <Typography sx={{color:'#BDBDBE'}}>Music apps to enjoy music</Typography>
                       </Box>
                       <Divider sx={{bgcolor:'#646071',marginTop:'15px',marginBottom:'15px'}}></Divider>
                       <Box sx={{display:'flex',textAlign:'left',gap:1}}>
                        <Box>
                            <DoneIcon style={{fontSize:'15px',backgroundColor:'#B26DFA',borderRadius:'50%',color:'black',marginTop:'3px'}}/>
                        </Box>
                        <Typography sx={{color:'#BDBDBE'}}>Music apps to enjoy music</Typography>
                       </Box>
                       <Divider sx={{bgcolor:'#646071',marginTop:'15px',marginBottom:'15px'}}></Divider>
                       <Box sx={{display:'flex',textAlign:'left',gap:1}}>
                        <Box>
                            <DoneIcon style={{fontSize:'15px',backgroundColor:'#B26DFA',borderRadius:'50%',color:'black',marginTop:'3px'}}/>
                        </Box>
                        <Typography sx={{color:'#BDBDBE'}}>Music apps to enjoy music</Typography>
                       </Box>
                       <Divider sx={{bgcolor:'#646071',marginTop:'15px',marginBottom:'15px'}}></Divider>
                    </Box>
                    <Box sx={{textAlign:'left',boxShadow: 'rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset',padding:'20px',borderRadius:'10px',paddingTop:'2px'}}>
                       <h4 style={{color:'#D582FF',fontSize:'20px',marginBottom:'10px'}}>Pro</h4>
                       <p style={{color:'#646071',margin:'0'}}>Music app to mix music, sing along and enjoy it.</p>
                       <Box sx={{display:'flex',textAlign:'left',gap:1,marginTop:'10px',marginBottom:'10px'}}>
                        <p style={{fontSize:'20px',margin:0,paddingTop:'20px'}}>$</p>
                        {
                            subPlan ? 
                            <p style={{fontSize:'50px',margin:0}}>{AnnuallyPro}</p> :
                            <p style={{fontSize:'50px',margin:0}}>{pro}</p>
                        }
                       </Box>
                       <Button sx={{backgroundColor:'white',width:'95%',fontWeight:'bold',fontSize:'10px',color:'black',borderTopRightRadius:'20px',marginBottom:'30px'}}>Get Started</Button>
                       <Box sx={{display:'flex',textAlign:'left',gap:1}}>
                        <Box>
                            <DoneIcon style={{fontSize:'15px',backgroundColor:'#B26DFA',borderRadius:'50%',color:'black',marginTop:'3px'}}/>
                        </Box>
                        <Typography sx={{color:'#BDBDBE'}}>Music apps to enjoy music</Typography>
                       </Box>
                       <Divider sx={{bgcolor:'#646071',marginTop:'15px',marginBottom:'15px'}}></Divider>
                       <Box sx={{display:'flex',textAlign:'left',gap:1}}>
                        <Box>
                            <DoneIcon style={{fontSize:'15px',backgroundColor:'#B26DFA',borderRadius:'50%',color:'black',marginTop:'3px'}}/>
                        </Box>
                        <Typography sx={{color:'#BDBDBE'}}>Music apps to enjoy music</Typography>
                       </Box>
                       <Divider sx={{bgcolor:'#646071',marginTop:'15px',marginBottom:'15px'}}></Divider>
                       <Box sx={{display:'flex',textAlign:'left',gap:1}}>
                        <Box>
                            <DoneIcon style={{fontSize:'15px',backgroundColor:'#B26DFA',borderRadius:'50%',color:'black',marginTop:'3px'}}/>
                        </Box>
                        <Typography sx={{color:'#BDBDBE'}}>Music apps to enjoy music</Typography>
                       </Box>
                       <Divider sx={{bgcolor:'#646071',marginTop:'15px',marginBottom:'15px'}}></Divider>
                    </Box>
                    <Box sx={{textAlign:'left',boxShadow: 'rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset',padding:'20px',borderRadius:'10px',paddingTop:'2px',marginTop:'20px'}}>
                       <h4 style={{color:'#FF9689',fontSize:'20px',marginBottom:'10px'}}>Premium</h4>
                       <p style={{color:'#646071',margin:'0'}}>Music app to mix music, sing along and enjoy it.</p>
                       <Box sx={{display:'flex',textAlign:'left',gap:1,marginTop:'10px',marginBottom:'10px'}}>
                        <p style={{fontSize:'20px',margin:0,paddingTop:'20px'}}>$</p>
                        {
                            subPlan ? 
                            <p style={{fontSize:'50px',margin:0}}>{AnnuallPremium}</p> : 
                            <p style={{fontSize:'50px',margin:0}}>{premium}</p>
                        }
                       </Box>
                       <Button sx={{backgroundColor:'white',fontWeight:'bold',width:'95%',fontSize:'10px',color:'black',borderTopRightRadius:'20px',marginBottom:'30px'}}>Get Started</Button>
                       <Box sx={{display:'flex',textAlign:'left',gap:1}}>
                        <Box>
                            <DoneIcon style={{fontSize:'15px',backgroundColor:'#B26DFA',borderRadius:'50%',color:'black',marginTop:'3px'}}/>
                        </Box>
                        <Typography sx={{color:'#BDBDBE'}}>Music apps to enjoy music</Typography>
                       </Box>
                       <Divider sx={{bgcolor:'#646071',marginTop:'15px',marginBottom:'15px'}}></Divider>
                       <Box sx={{display:'flex',textAlign:'left',gap:1}}>
                        <Box>
                            <DoneIcon style={{fontSize:'15px',backgroundColor:'#B26DFA',borderRadius:'50%',color:'black',marginTop:'3px'}}/>
                        </Box>
                        <Typography sx={{color:'#BDBDBE'}}>Music apps to enjoy music</Typography>
                       </Box>
                       <Divider sx={{bgcolor:'#646071',marginTop:'15px',marginBottom:'15px'}}></Divider>
                       <Box sx={{display:'flex',textAlign:'left',gap:1}}>
                        <Box>
                            <DoneIcon style={{fontSize:'15px',backgroundColor:'#B26DFA',borderRadius:'50%',color:'black',marginTop:'3px'}}/>
                        </Box>
                        <Typography sx={{color:'#BDBDBE'}}>Music apps to enjoy music</Typography>
                       </Box>
                       <Divider sx={{bgcolor:'#646071',marginTop:'15px',marginBottom:'15px'}}></Divider>
                    </Box>
                </Box>
            </Grid>
            <Grid item md={2} xl={3}></Grid>
        </Grid>
     </Box>
    )
}

export default Subscribe