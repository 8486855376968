import { Box, Button, Divider, TextField, Container, Modal, Typography, Slider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DragDropFileUpload from '../../../components/drag-drop-file2';
import { useNavigate } from 'react-router-dom';
import SongSelect from '../../../components/auto-complete';
import { AddMeta } from '../../../features/addlyric/addLyric';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { url } from '../../../config/config';
import SongSelect1 from '../../../components/auto-complete-1';
import { LuZoomIn, LuZoomOut } from 'react-icons/lu';

const Songdetail = () => {
  const navigate = useNavigate();
  const [tittle, settittle] = useState(null);
  const [genres, setgenres] = useState(null);
  const [Tags, setTags] = useState(null);
  const [coverImg, setcoverImg] = useState(null);
  const [modalOpen, setModalOpen] = useState(false); // Modal state
  const [Artits, setArtits] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [rotation, setRotation] = useState(0); 
  const [rotationAngle, setRotationAngle] = useState(0);
  const [errorModalOpen, setErrorModalOpen] = useState(false); 
  const [uploadedImageCount, setUploadedImageCount] = useState(0);  // Track uploaded images count
  const dispatch = useDispatch();
  const value = useSelector((state) => state);

  const handleFileUpload = (file) => {
    if (uploadedImageCount < 6) {  // Limit to 6 images
      setcoverImg(file);
      setModalOpen(true); // Open modal on file upload
      setUploadedImageCount(uploadedImageCount + 1);  // Increment count
    } else {
      alert('You can upload up to 6 images only.');
    }
  };

  const url = coverImg != null ? URL.createObjectURL(coverImg) : '';

  const HandleSubmit = () => {
    // if (tittle && Artits && url && genres && Tags) {
      if (!tittle || !Artits || !url || !genres || !Tags){
        setErrorModalOpen(true); // Show error modal

      } else{
      dispatch(
        AddMeta({
          url,
          tittle,
          artist: Artits,
          genres : 'Melody',
          tags: 'Melody',
        })
      );
      navigate('/uploads/songlyrics');
    }
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  const handleSave = () => {
    if (coverImg) {
      console.log("Image saved:", url);
      closeModal();
    }
  };

  return (
    <Box sx={{ textAlign: 'center', height: '100vh' }}>
      <Container>
        <Card sx={{ padding: 2, marginTop: '60px' }}>
          <Box
            sx={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              padding: 10,
              justifyContent: 'space-around',
              paddingTop: '30px',
              paddingBottom: '40px',
            }}
          >
            <Box sx={{ marginBottom: '25px' }}>
              <Box sx={{ fontSize: 40, color: '#120B27', fontWeight: 'bold'  }}>Add Song Details</Box>
              <Box sx={{ fontSize: 20, color: '#959696' }}>
                Adding more details makes your song easier to find
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
               
              {coverImg !== null || value.Upload.SongMeta.length !== 0 ? (
                <Box sx={{ padding: '15px', background: '#EDF5FF' }}>
                  <Box>
            
                    <img
                      src={coverImg != null ? url : value.Upload.SongMeta.url}
                      style={{ width: '240px', height: '240px' }}
                      alt="Cover Art"
                      backgroundColor="red"
                    />
                  </Box>
                  <input
                    onChange={(e) => {
                      handleFileUpload(e.target.files[0]);
                    }}
                    type="file"
                    name="file"
                    id="file"
                    className="inputfile"
                  />
                  <label
                    style={{
                      opacity: 1,
                      right: 90,
                      background: '#8409F7',
                      color: 'white',
                      padding: '10px',
                      borderRadius: '5px',
                      marginBottom: '10px',
                      fontWeight: 'bold' ,
                      
                    }}
                    htmlFor="file"
                  >
                    Change Cover Art
                  </label>
                </Box>
              ) : (
                <Box>
                  <Box>
                    <DragDropFileUpload onFileUpload={handleFileUpload} />
                  </Box>
                </Box>
              )}
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box>
                  <TextField
                    defaultValue={value.Upload.SongMeta.tittle}
                    onChange={(e) => {
                      settittle(e.target.value);
                    }}
                    sx={{ width: 500 }}
                    placeholder="Tittle"
                  />
                </Box>
                <Box>
                  <TextField
                    defaultValue={value.Upload.SongMeta.artist}
                    onChange={(e) => {
                      setArtits(e.target.value);
                    }}
                    sx={{ width: 500 }}
                    placeholder="Artist"
                  />
                </Box>

                <Box>
                  <SongSelect onchang={setgenres} tittle="Genre(s):" />
                </Box>
                <Box>
                  <SongSelect1 onchang={setTags} tittle="Tag(s):" />
                </Box>
              </Box>
            </Box>
            <Divider style={{ marginTop: '20px', marginBottom: '20px', padding: '0' }}></Divider>
            <Box>
              <Button
                sx={{ width: 300, height: 35, backgroundColor: '#00B818' }}
                variant="contained"
                onClick={HandleSubmit}
              >
                Continue
              </Button>
            </Box>
          </Box>
        </Card>
      </Container>
      <Modal open={modalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            textAlign: 'center',
          }}
        >
          <Typography variant="h6" component="h2">
            Uploaded Image
          </Typography>
          <Box sx={{color:'black'}}><Divider/></Box>
          {coverImg && <img src={url} alt="Uploaded Preview" style={{ width: '100%', marginTop: 20,backgroundColor:"black",
            transition: 'transform 0.7s ease-in-out',   transform: `rotate(${rotationAngle}deg) scale(${zoomLevel})`,}} />
          }
          <Box sx={{ mt: 4, display: 'flex', gap: 2, justifyContent: 'center' }}>
            <Button
              variant="contained"
              onClick={() => setRotationAngle((prev) => (prev + 90) % 360)} 
            >
              Rotate 
            </Button>
          </Box>
          <Slider
            aria-label="Zoom Level"
            value={zoomLevel}
            min={0.5}
            max={1.2}
            step={0.1}
            onChange={(e, newValue) => setZoomLevel(newValue)}
            valueLabelDisplay="auto"
            sx={{
              width: '80%',
              margin: '20px auto',
              color: 'secondary.main',
            }}
          />
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-around' }}>
            <Button variant="outlined" color="secondary" onClick={closeModal}>
              Cancel
            </Button>   
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave} 
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={errorModalOpen} onClose={() => setErrorModalOpen(false)}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          textAlign: 'center',
        }}
      >
        <Typography variant="h6" color="error">
          Missing Fields
        </Typography>
        <Divider sx={{ marginY: 2 }} />
        <Typography>Please fill in all required fields before continuing.</Typography>
        <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={() => setErrorModalOpen(false)}>
          OK
        </Button>
      </Box>
    </Modal>
    </Box>
  );
};

export default Songdetail;
