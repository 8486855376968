import { Box, Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import DragDropFileUpload from '../../../components/drag-drop-file'
import DragDropFileUploads from '../../../components/drag-drop-file5'
import { useNavigate } from 'react-router-dom'
import { useSelector,useDispatch } from 'react-redux'
import { AddAudio, TextFile } from '../../../features/addlyric/addLyric'
import Meta from '../../../components/meta_data'
const Uploadsong = () => {
    const navigate = useNavigate();
    
    const [spinner,setspinner]=useState(false)
    const [audioUploaded, setAudioUploaded] = useState(false);
    const [lyricsUploaded, setLyricsUploaded] = useState(false);
  const dispatch = useDispatch()
    const handleFileUpload = (file) => {
      
      console.log(file)
      if(file && file.type.startsWith('audio/')){
        const url = URL.createObjectURL(file);
        console.log(url)
        dispatch(AddAudio({ url, name: file.name, size: file.size }));
          // navigate('/uploads/songlyrics')
          // dispatch(AddAudio(file))
          setAudioUploaded(true);
      
      } else {
        alert('Please upload a valid audio file.');
    }
       
        
      };
     
      const handleFileChosen = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'text/plain') {
        const reader = new FileReader();
         console.log(reader)
        reader.onload = (e) => {
          const content = e.target.result;
          console.log(content)
          dispatch(TextFile(content));
          setLyricsUploaded(true);
        };
    
        reader.readAsText(file);
      } else {
        alert('Please upload a valid text file for lyrics.');
    }
      };
      console.log('vijay')
    //   cons
  return (
    <Box sx={{display:"flex",justifyContent:"center",flexDirection:"column",gap:5,alignItems:"center",textAlign:"center",padding:10,minHeight:"100vh"}}>
        <Box>
            <Box ><Typography fontSize={40} color='#120B27'>Upload Song</Typography></Box>
            <Box><Typography fontSize={20} color='#A39696'>Make sure your file doesn't include vocals.</Typography></Box>
        </Box>
        <Box sx={{cursor:"pointer"}}><DragDropFileUpload onFileUpload={handleFileUpload} spinner={spinner}/></Box>
        <Box sx={{display:"flex",flexDirection:"column"}}>
        <Box sx={{cursor:"pointer"}}><Button > Add lyrics (optional)</Button></Box> <Box><input   accept="text/plain" type="file" onChange={handleFileChosen} /></Box>
          </Box>
          {audioUploaded && lyricsUploaded && (
                <Box sx={{cursor:"pointer"}}>
                    <Button onClick={() => navigate('/uploads/songdetails')} variant='contained'>Continue</Button>
                </Box>
            )}
             <Box>
                <Typography fontSize={12} color='#9598B4'>By uploading this song, you acknowledge that you are adhering to all legal requirements outlined in our Community Guidelines and Terms of Service.</Typography>
                <Typography fontSize={12} color='#9598B4'>For any questions, please consult our FAQ or reach out to sapksinger for further assistance.</Typography>
            </Box>
        </Box>
     
   
  )
}

export default Uploadsong