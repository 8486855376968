import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { motion } from "framer-motion";
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Link, Outlet } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Divider, Drawer, colors, Modal, Card, Tooltip } from '@mui/material';
import LeftDrawer from '../components/left-drawer';
import BasicMenu from '../components/basic-menu';
import logo from '../assest/images/Bright Color-01.png';
import newlogo from '../assest/images/PNG-06.png'
import newwhitelogo from '../assest/images/NewLogoWhite.png'
import logo1 from '../assest/images/resized-removebg-preview.png'
import './styles/top_Nav.css'
import Authmodel from '../components/auth_model';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { url } from '../config/config';
import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import NotificationDrawer from '../components/notificationDrawer';
import io from 'socket.io-client';
import MoreIconDrawer from './more';
import { ToastContainer, toast } from 'react-toastify';
import { useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';
import { Cancel, ExitToApp } from '@mui/icons-material';
import { IoAlertCircleOutline } from 'react-icons/io5';
import { FaArrowRight } from 'react-icons/fa6';
import premiumss from "../assest/images/premiumIcon.png"
import { useDispatch, useSelector } from 'react-redux';
import { isPremiumHandle } from '../features/addlyric/addLyric';
import { FcGlobe } from 'react-icons/fc';
import men from "../assest/images/male.webp"

const socket = io(url, {
  transports: ['websocket'], // Ensure using WebSocket transport
  reconnectionAttempts: 5, // Set reconnection attempts if needed
}); const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));
const style = {
  position: 'absolute',
  top: { xs: '4%', sm: '8%', md: '6%', lg: '6%', xl: '4%' }, // Responsive top positioning
  left: '50%',
  transform: 'translate(-50%, 0)', // Removed `-50%` from Y-axis for better mobile behavior
  width: { xs: '90%', sm: '80%', md: 400 }, // Responsive width
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '10px', // Slightly increased for better UX
  padding: '10px', // Added padding for better spacing
};

const styles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: '#DEF1FD',
  pt: 2,
  px: 4,
  pb: 3,
  boxShadow: '1px 1px 20px black',
  p: 4,
  borderRadius: 2
};
export default function Topnav() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [anchor, setanchor] = React.useState(false)
  const [notification, setNotification] = useState(false);
  const [countNotification, setCountNotification] = useState();
  const [isPremiumActive, setIsPremiumActive] = useState(false);
  const [open, setopen] = useState(false)
  const [payment, setPayment] = useState();
  const [searchValue, setSearchValue] = useState('')
  const [modal, setModal] = useState(false)
  const [more, setMore] = useState(false)
  const [logout, setLogut] = useState(false)
   const premium = useSelector((state) => state)
    console.log(premium.Upload.isPremium,"pareeeeee");
  const DrawerRef = React.useRef(0);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const userTokenData = localStorage.getItem('token') !== null ? jwtDecode(localStorage.getItem('token')) : ''

  const navigate = useNavigate()
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const Anchor = () => {
    setanchor(false)
  }
  const HandleClose = () => {
    setopen(false)
  }
  const handleClicklogut = () => {
    if (localStorage.getItem('token') !== null) {
      setLogut(true)
    } else {
      navigate('/')
      setopen(true);

    }
    
  }
  const handleClicks = () => {
    notificationControl(true)
    Anchor()
  }
  const dispatch = useDispatch();
  const [userData, setUserData] = useState([])
  useEffect(() => {
    const GetProfileData = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/User/getUserById/${localStorage.getItem('token')}`, config)
        console.log(res.data.data, "profile......");

        if (res.data) {

          setUserData(res.data.data)

        }

        console.log(res.data.data)
        localStorage.setItem('userID', res.data.data[0]?._id)
      } catch (error) {
        console.log(error)
      }
    }
    GetProfileData()
  }, [])

  // console.log(userData, 'DataVlauesssFor User')
  useEffect(() => {
    const Getpredata = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/Payment/getUserSubAndWallet`, config)
        console.log(res.data, "payment......");
        if (res.status === 200) {
          dispatch(isPremiumHandle(res.data.isPremiumActive))
        }
      } catch (error) {
        console.log(error)
      }
    }
    Getpredata()
  }, [])

  const [notificationCount, setNotificationCount] = useState([])
  const [datais, setDatais] = useState([])
  const fetchNotifications = useCallback(() => {
    socket.emit('fetchNotifications', localStorage.getItem('token') ?? null);
  }, []);

  const handleNotification = React.useCallback((data) => {
    const { notification, senderId, songCollaborationId } = data;

    console.log(data, 'notigy log');

    toast.info(`New Notification from ${senderId}: ${notification}`, {
      position: "top-right",
      autoClose: 3000,

    });
  }, []);




  const handleNotificationCount = (data) => {
    // console.log('callingtheNotifaction')
    // console.log(data, 'datavAluessssss')
    setNotificationCount(data);
  }

  useEffect(() => {
    console.log('socket call');
    socket.on("connect", () => {
      console.log('web socket connected');
    });
    socket.emit('register', userTokenData.userId)
    socket.on('NotifyCount', handleNotificationCount);

    // Cleanup function to disconnect the socket
    return () => {
      socket.off("connect");
      socket.off('NotifyCount', handleNotification);

    };
  }, []);


  // console.log(notificationCount)
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    sx={{ marginLeft: -2, marginTop: 7.5, borderRadius: '10px' }}
    id={menuId}
    keepMounted
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    open={isMenuOpen}
    onClose={handleMenuClose}
  >
    <Tooltip title="View Profile" arrow>
      <MenuItem 
        sx={{ borderBottom: '2px solid rgb(240, 242, 244)', width: "160px", '&:hover': {  backgroundColor: '#673EF7',color:"white" ,fontWeight:"bold"  } }} 
        onClick={handleMenuClose}
      >
        <Link style={{ textDecoration: "none", color: "black" }} to='profile'>Profile</Link>
      </MenuItem>
    </Tooltip>

    <Tooltip title="Check Notifications" arrow>
      <MenuItem 
        sx={{ borderBottom: '2px solid rgb(240, 242, 244)', '&:hover': {  backgroundColor: '#673EF7',color:"white" ,fontWeight:"bold"  } }} 
        onClick={handleMenuClose}
      >
        <Link style={{ textDecoration: "none", color: "black" }} onClick={handleClicks}>Notification</Link>
      </MenuItem>
    </Tooltip>

    <Tooltip title="Go to Settings" arrow>
      <MenuItem 
        sx={{ borderBottom: '2px solid rgb(240, 242, 244)', '&:hover': {  backgroundColor: '#673EF7',color:"white" ,fontWeight:"bold" } }} 
        onClick={handleMenuClose}
      >
        <Link style={{ textDecoration: "none", color: "black", }} to='settings'>Settings</Link>
      </MenuItem>
    </Tooltip>

    <Tooltip title="Log out" arrow>
      <MenuItem 
        sx={{ '&:hover': { backgroundColor: '#673EF7',fontWeight:"bold" } }} 
        onClick={() => {
          handleMenuClose(); // Close menu before logging out
          localStorage.removeItem('token');
          localStorage.removeItem('userID');
          setLogut(false);
          navigate('/');
        }}
      >
        Log out
      </MenuItem>
    </Tooltip>
  </Menu>
  );
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );
  console.log(localStorage.getItem('userID'))
  // console.log(notificationCount)
  const handleClick = () => {
    if (localStorage.getItem('token') !== null) {
      if (notificationCount.length > 0) {
        try {
          const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          }
          const data = {

          }
          const res = axios.patch(`${url}/api/Notification/NotificationViewedWebByUserID`, data, config)
          console.log(res.data)
          setNotification(true)
        } catch (error) {
          console.log(error)
        }
      } else {
        setNotification(true)
      }
    } else {
      setopen(true)
    }

  }
  useEffect(() => {
    const Getnotifie = async () => {
      try {
        //  const res = await axios.get(`${url}/api/Notification/NotificationViewedWebByUserID`);
        const config={
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/Notification/getNotificationByUserID`,config);
        console.log(res.data, 'notifeeeeeeeeeeee')
        setCountNotification(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    Getnotifie()
  }, [])
  useEffect(() => {
    const GetWalletPayment = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/Payment/getUserSubAndWallet`, config)
        console.log(res.data, "getWalletBalancesetPayment....")
        setPayment(res.data)
        // setIsPremiumActive(res.data.isPremiumActive);
      } catch (error) {
        console.log(error, 'errrors')
      }
    }
    GetWalletPayment()
  }, [])
  useEffect(() => {
    const userNotifications = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/Notification/getNotificationByUserID`, config)
        console.log(res.data.data.data1, 'notification')
        console.log(res.data.data.data2, 'notification123')
        setDatais(res.data.data.data2)
        // setIsPremiumActive(res.data.data.data1.isPremium  );
        // const result = await ClearNotify()


      } catch (error) {
        console.log(error)
      }
    }
    userNotifications()
  }, [])


  const handleModalClose = () => {
    setModal(false)
  }

  const handleMoreClose = (val) => {
    setMore(val)
  }

  const handleSearch = () => {
    if (searchValue.trim() === '') return; // Prevent empty searches
    setModal(false)
    navigate(`search/${searchValue}`)
    setSearchValue('');
  }
  const handleProfileClick = () => {
    if (localStorage.getItem('token') !== null) {
      navigate('/profile');
    } else {
      navigate('/')
      setopen(true);

    }
  }
  const handleSettingsclick = () => {
    if (localStorage.getItem("token") !== null) {
      navigate('/settings')
    } else {
      navigate('/')
      setopen(true);

    }
  }

  const notificationCose = (val) => {
    // console.log(val)
    setNotification(val)
  }

  const notificationControl = (val) => {
    setNotification(val)
  }
  return (
    <Box sx={{ flexGrow: 1 }}>

      <AppBar position="sticky"
        sx={{ backgroundColor: "#140E18", boxShadow: "none" }}
      >
        <Toolbar>
          <Box sx={{ backgroundColor: "#3f13a8", padding: "15px", marginLeft: "-24px", height: '100%' }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                setanchor(true)
              }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box className='imageicon' > <Link style={{ textDecoration: "none", color: "white", marginLeft: '9px' }} to='/'>
            <img style={{   width: "100%",
        maxWidth: "180px", // Ensures it doesn't get too large
        height: "auto",
        marginTop: "3px", }} src={logo}></img>
            </Link></Box>
          <Divider orientation="vertical" flexItem sx={{ backgroundColor: '#F7C1F719', marginLeft: '10px' }} />
          <Box sx={{ display: { xs: "none", md: "flex" }, gap: "15px", marginLeft: "30px", fontSize: "17px", alignItems: "center", fontWeight: "bold" }}>
            <Box>
              <Link style={{ textDecoration: "none", color: "white" }} to='explore'><Typography sx={{
                fontWeight: "bold", fontSize: 17,
                color: "White",
                "&:hover": {
                  color: "green"
                },
              }}>Explore</Typography></Link>
            </Box>
            <Box> <Link style={{ textDecoration: "none", color: "white" }} to='song'><Typography sx={{
              fontWeight: "bold", fontSize: 17,
              color: "White",
              "&:hover": {
                color: 'green'
              },
            }}>Songbook</Typography></Link></Box>
            <Box sx={{ display: "flex", alignItems: "c", fontWeight: "bold", fontSize: 17, }}>
              <Box>
                <BasicMenu />
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }} onClick={() => { setModal(true) }}>
            <SearchIcon style={{ color: 'white', fontSize: '28px', cursor: 'pointer' }} />
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              {
                localStorage.getItem('token') ?
                  <Typography sx={{ fontWeight: 'bold', color: '#673EF7', textTransform: 'uppercase', fontSize: '20px' }}>{userData?.length != 0 && userData[0]?.Name}</Typography> : ''
              }
            </Box>
            <Box 
  className='subscribeIcon' 
  onClick={() => !premium.Upload.isPremium && navigate('/pricing')} 
  sx={{ 
    fontSize: 20, 
    cursor: 'pointer', 
    display: { xs: 'none', md: 'block' }, 
    fontWeight: 'bold', 
    color: '#ff5733' 
  }}
>
  {premium.Upload.isPremium ? "Premium" : "Get Premium"}
</Box>
            <Box className='topnav-icons' sx={{ display: { xs: 'none', md: 'flex' }, gap: 1 }}>
            <Box sx={{ cursor: 'pointer' }} onClick={handleClick}>
  <Tooltip title="Notifications" arrow>
    {datais > 0 ? ( // Only show the badge if datais > 0
      <Badge badgeContent={datais} color="secondary">
        <NotificationsIcon />
      </Badge>
    ) : (
      <NotificationsIcon /> // Show only the icon when no notifications
    )}
  </Tooltip>
</Box>
              <Box sx={{ cursor: 'pointer' }}>
                <Tooltip title="Settings" arrow>
                  <SettingsIcon onClick={handleSettingsclick} />
                </Tooltip>
              </Box>
              <Box sx={{ cursor: 'pointer' }}>
                <Tooltip title="Profile" arrow>
                  <PersonIcon onClick={handleProfileClick} />
                </Tooltip>
              </Box>
              {/* <Box sx={{ cursor: 'pointer' }}>
              <Tooltip title="Logout" arrow>
                <LogoutIcon onClick={handleClicklogut} />
                </Tooltip>
              </Box> */}
              <Box sx={{ cursor: 'pointer' }}>
              <Tooltip title="Logout" arrow>
                <div className="group cursor-pointer p-2 rounded-full transition-all duration-300 hover:bg-red-100">
                  <LogoutIcon
                    onClick={handleClicklogut}
                    className="text-gray-700 transition-all duration-300 group-hover:text-red-500 group-hover:scale-110"
                    size={28}
                  />
                </div>
              </Tooltip>
              </Box>
              <Modal
      open={logout}
      onClose={() => setLogut(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styles}>
        <Cancel sx={{ marginLeft: 35, cursor: "pointer" }} onClick={() => setLogut(false)} />

        {/* Framer Motion applied to FcGlobe */}
        <Box sx={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
          <motion.div
            animate={{ rotate: 360 }}
            transition={{ repeat: Infinity, duration: 6, ease: "linear" }}
            style={{ fontSize: "100px", color: "green" }}
          >
            <FcGlobe />
          </motion.div>
        </Box>

        <Box>
          <Typography sx={{ fontWeight: "bold", fontSize: 20, textAlign: "center", mt: 3 }}>
            Are you sure you want to log out?
          </Typography>
        </Box>

        <Box mt={2} sx={{ display: "flex", justifyContent: "end", textAlign: "center", gap: 1 }}>
          <Button onClick={() => setLogut(false)}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              localStorage.removeItem("token");
              localStorage.removeItem("userID");
              setLogut(false);
              navigate("/");
            }}
          >
            Yes <FaArrowRight />
          </Button>
        </Box>
      </Box>
    </Modal>

              {/* Injecting the keyframes animation via sx prop */}
              <Box sx={{
                '@keyframes rotate': {
                  'from': {
                    transform: 'rotate(0deg)',
                  },
                  'to': {
                    transform: 'rotate(360deg)',
                  }
                }
              }}></Box>

              <Box>
              </Box>
            </Box>
            {/* <Box sx={{ display: { xs: 'block', md: 'none' } }} onClick={() => { setMore(true) }}>
              <SearchIcon sx={{fontSize:35}} />
            </Box> */}
             <Box sx={{ display: { xs: 'block', md: 'none' } }} onClick={() => { setModal(true)  }}>
              <SearchIcon sx={{fontSize:35}} />
            </Box>

            <Box>
              {
                localStorage.getItem('token') ? <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent={
                      premium.Upload.isPremium && (
                        <Avatar
                          sx={{ bgcolor: "red", width: 20, height: 20, fontSize: 10 }}
                          alt="Premium Badge"
                        >
                          <img src={premiumss} alt="Premium" width="100%" height="100%" />
                        </Avatar>
                      )
                    }
                  >
                    <Avatar
                      alt="User Profile"
                      src={userData?.length > 0 && userData[0]?.ProfilePhoto ? userData[0].ProfilePhoto : men}
                    />
                  </Badge>


                </IconButton> :
                  <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={() => {
                      setopen(true)
                    }}
                    color="inherit"
                  >
                    <Button sx={{ color: "white" }} variant="outlined">Login</Button>
                  </IconButton>
              }
              <Authmodel open={open} HandleClose={HandleClose} />
            </Box>

          </Box>


          {/* <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box> */}
        </Toolbar>
        <ToastContainer />

      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <Drawer anchor='left'
        open={anchor}
        PaperProps={{
          sx: {
            backgroundColor: "#2D2867",
            color: "white",
          }
        }}
        onClose={() => { setanchor(false) }}
      >
        <Box >
          <LeftDrawer notificationControl={notificationControl} Anchor={Anchor} />
        </Box>
      </Drawer>
      <Drawer anchor='right'
        open={notification}
        PaperProps={{
          sx: {
            backgroundColor: 'white',
          }
        }}
        onClose={() => { setNotification(false) }}
      >
        <Box>
          <NotificationDrawer onNotificationClose={notificationCose} value={notification} />
        </Box>
      </Drawer>
      <Outlet />
      <Modal
        open={modal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', borderRadius: '5px' }}>
            <Box sx={{ display: 'flex', margin: '5px' }}>
              <Box sx={{ padding: '5px', textAlign: 'center' }}>
                <SearchIcon style={{ fontSize: '28px' }} />
              </Box>
              <Box>
                <input placeholder="Search artists, song, users..." onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch()

                  }
                }} className='search-box' style={{ width: '100%', height: '90%', color: 'rgb(45, 40, 103)', fontSize: '17px', fontWeight: 'bold', border: 'none' }} value={searchValue} onChange={(e) => { setSearchValue(e.target.value) }} />
              </Box>
            </Box>
            {
              searchValue !== '' ? <Box onClick={handleSearch} sx={{ margin: '5px' }}>
                <Button sx={{ background: 'rgb(108, 46, 255)', fontSize: '17px', color: 'white', fontWeight: 'bold', height: '40px', textTransform: 'none', cursor: 'pointer' }}>Search</Button>
              </Box> : <Box onClick={() => { setModal(false) }} sx={{ color: 'rgb(108, 46, 255)', fontSize: '15px', fontWeight: 'bold', textAlign: 'center', alignItems: 'center', padding: '15px', cursor: 'pointer' }}>Cancel</Box>
            }
          </Box>
        </Box>
      </Modal>
      <Drawer anchor='top'
        open={more}
        PaperProps={{
          sx: {
            backgroundColor: 'rgb(138 89 255)',
          }
        }}
        onClose={() => { setMore(false) }}
      >
        <Box>
          <MoreIconDrawer onClose={handleMoreClose} />
        </Box>
      </Drawer>

    </Box>

  );
}