import { Box, Divider, List, ListItem, ListItemText, Typography } from '@mui/material'
import React from 'react'
import Spark from './spark'
import Meta from '../components/meta_data'

const Guidelines = () => {
  return (
    
    <Box sx={{ padding: 5, backgroundColor: '#F7F7F7', borderRadius: 2 }}>
      <Meta title="Guidelines | Sparksinger Social Singing & Karaoke App" description="SparkSinger is a social karaoke app where you can enjoy millions of songs, connect with friends worldwide, and even sing with top artists. Join the global singing community today! | SparkSinger Social" keywords="Karaoke, Singing, Social Singing" />
    <Typography variant="h4" sx={{ marginBottom: 3, fontWeight: 'bold', textAlign: 'center' }}>
      Welcome to the SparkSinger Community!
    </Typography>
    <Typography variant="body1" sx={{ marginBottom: 2 }}>
    Our Community Guidelines are here to ensure everyone enjoys their time on SparkSinger. By following these Guidelines, you can trust the connections you make on SparkSinger.
    </Typography>
    <Typography variant="body1" sx={{ marginBottom: 2 }}>
      Your use of SparkSinger is subject to these Guidelines and our Terms of Service. If you break these Guidelines or our Terms of Service, we may remove your content, permanently disable your account, notify law enforcement, or take legal action. If your account is terminated for violating these Guidelines or the Terms of Service, you cannot use SparkSinger again or create a new account without SparkSinger's permission.
    </Typography>
    <Typography variant="h6" gutterBottom fontWeight={"bold"}>
    Be Kind and Respectful
                </Typography>
                <Typography variant="body1" paragraph>
                Be polite and respectful when interacting with others. SparkSinger is a global community, so things that seem harmless to you might be viewed differently by someone else. Treat others the way you want to be treated! Help others find great songs or teach them how to make awesome tracks.
                </Typography>
                <Typography variant="h6" gutterBottom fontWeight={"bold"}>
                Have fun
                </Typography>
                <Typography variant="body1" paragraph>
                SparkSinger is a place for you to enjoy and be yourself, whether you're singing, listening, playing, or collaborating. Remember to have fun and respect other's right to do the same.
                </Typography>
                <Typography variant="h6" gutterBottom fontWeight={"bold"}>
                Keep your account secure
                </Typography>
                <Typography variant="body1" paragraph>
                You are responsible for your account, so keep it safe. If you notice any suspicious activity or security breaches, contact SparkSinger immediately.
                </Typography>
                <Typography variant="h6" gutterBottom fontWeight={"bold"}>
                Stay Connected with Us
                </Typography>
                <Typography variant="body1" paragraph>
                You are a key part of the community! Report any bad behaviour using the app's reporting tools, and reach out to us if you have any questions or concerns.
                </Typography>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
        Stay Connected with Us
      </Typography>
      <Typography>
        You are a key part of the community! Report any bad behaviour using the app's reporting tools, and reach out to us if you have any questions or concerns.
      </Typography>
      <Typography variant="h6" fontWeight="bold" mt={3}>
        For users in India, contact our Grievance Officer:
      </Typography>
      <Typography>Name: Kanagaraj M</Typography>
      <Typography>Email: raj.mahamuni@softspark.com</Typography>

      <Typography variant="h5" fontWeight="bold" mt={4} gutterBottom>
        WHAT YOU SHOULD NOT DO:
      </Typography>
      <Typography variant="h6" gutterBottom fontWeight={"bold"}>
      Avoid Bullying or Harassment
                </Typography>
                <Typography variant="body1" paragraph>
                Never harass, abuse, or intimidate others. There’s no reason or situation where bullying is acceptable.
                </Typography>
                <Typography variant="h6" gutterBottom fontWeight={"bold"}>
                Do Not Post Objectionable Content
                </Typography>
                <Typography variant="body1" paragraph>
                SparkSinger can review flagged content and may remove anything considered harmful or unsafe for the community. This includes:
                </Typography>
                <li>Harassment, threats, or bullying</li>
          <li>Bigotry, racism, or discrimination</li>
          <li>Pornographic, obscene, or vulgar content</li>
          <li>Content that promotes violence, falsehoods, or defamation</li>
          <li>Anything that harms someone’s privacy or security</li>
          <Typography variant="h6" gutterBottom fontWeight={"bold"}>
          Be Yourself
                </Typography>
                <Typography variant="body1" paragraph>
                SparkSinger is about real people, so don’t create fake profiles or impersonate others. Don’t make multiple accounts to boost visibility or harass others. This will lead to your account being banned.
                </Typography> <Typography variant="h6" gutterBottom fontWeight={"bold"}>
                Don’t be a Troll
                </Typography>
                <Typography variant="body1" paragraph>
                Avoid being negative or causing trouble in the community. If you disrupt the purpose of SparkSinger or harm user's safety and privacy, your account will be banned.
                </Typography> <Typography variant="h6" gutterBottom fontWeight={"bold"}>
                No Spamming
                </Typography>
                <Typography variant="body1" paragraph>
                Don’t use SparkSinger for unsolicited mass messages or sales. You are responsible for anything you send through the platform.
                </Typography> <Typography variant="h6" gutterBottom fontWeight={"bold"}>
                Protect Your Own and Other’s Privacy
                </Typography>
                <Typography variant="body1" paragraph>
                Be careful about sharing sensitive information like your password or account details.
Don’t post or share someone else’s personal info without their consent, and don’t use someone else's account without permission.

                </Typography> 
                <Typography variant="h6" gutterBottom fontWeight={"bold"}>
                Avoid Brigading
                </Typography>
                <Typography variant="body1" paragraph>
                Don’t encourage others to target or flag users in order to get their content or account removed. If someone violates the Guidelines, report them to us.
                </Typography>
                <Typography variant="h6" gutterBottom fontWeight={"bold"}>
                Respect Intellectual Property Rights
                </Typography>
                <Typography variant="body1" paragraph>
                SparkSinger respects intellectual property rights and expects you to do the same. If we find you violating these rights, we will terminate your account.
                </Typography>
                <Typography variant="h6" gutterBottom fontWeight={"bold"}>
                Respect SparkSinger’s Policies
                </Typography>
                <Typography variant="body1" paragraph>
                Don’t post content or engage in activities that encourage others to break the Guidelines or Terms of Service, and don’t try to harm SparkSinger’s service or compete with it.
                </Typography>
                <Typography variant="h6" gutterBottom fontWeight={"bold"}>
                No Illegal or Unauthorized Activities
                </Typography>
                <Typography variant="body1" paragraph>
                You may not use SparkSinger for illegal purposes or actions that harm the platform or its users. Specifically, avoid:
                </Typography>
                <li>Gaining unauthorized access to accounts, data, or linked websites</li>
        <li>Deleting or altering content without permission</li>
        <li>Disabling or tampering with security features</li>
        <li>Uploading harmful software or viruses</li>
        <li>Interfering with the service or networks</li>
        <li>Scraping or using automated tools to gather data from SparkSinger</li>
        <li>Using or developing apps that interact with SparkSinger without permission</li>
    
  
  </Box>
  )
}

export default Guidelines