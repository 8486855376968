import React, { useEffect, useState, useRef, useCallback } from "react";
import { COUNTRIES_DATA } from "./countries";
import HEX_DATA from './countries_hex_data.json';
import Globe from "react-globe.gl";
import './CustomGlobe.css';
import globeImage from './earth-day.jpg'

import userPhotoDelhi from '../../assest/images/songimage.jpg'
import userPhotoNewZealand from '../../assest/images/upnext.jpg';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box } from "@mui/material";
import defaultImg from '../../assest/images/cover music.png'
import axios from "axios";
import { url } from "../../config/config";
import { useParams } from "react-router-dom";
const getRandomCountry = () => {
  return COUNTRIES_DATA[Math.floor(Math.random() * COUNTRIES_DATA.length)];
};

export default function CustomGlobe({data,handlepLay}) {
  const params = useParams();
  const [isReversed, setIsReversed] = useState(false);
  const [songCollabData,setSongCollabData] = useState([])
  const globeEl = useRef();

  useEffect(() => {
    if (globeEl.current) {
      const controls = globeEl.current.controls();
      
      controls.autoRotate = true; // Enable auto-rotation initially
      controls.autoRotateSpeed = 0.5; // Set desired rotation speed
  
      const handleDragStart = () => {
        controls.autoRotate = false; // Disable auto-rotation when user starts dragging
      };
  
      const handleDragEnd = () => {
        controls.autoRotate = true; // Re-enable auto-rotation when user stops dragging
      };
  
      // Add event listeners for drag start and end
      controls.addEventListener('start', handleDragStart);
      controls.addEventListener('end', handleDragEnd);
  
      // Clean up event listeners on component unmount
      return () => {
        controls.removeEventListener('start', handleDragStart);
        controls.removeEventListener('end', handleDragEnd);
      };
    }
  }, []);

  useEffect(() => {
    const songCollabData = async () => {
      try {
        const config={
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/SongCollaboration/getSongCollaborationById/${params.id}`,config);
        console.log(res.data,'datasssss')
        setSongCollabData(res.data.data)
    } catch (error) {
      console.log(error)
    }
    }
    songCollabData()
  },[params.id])

  useEffect(() => {
    const interval = setInterval(() => {
      setIsReversed((prev) => !prev);
    }, 5000); // Change direction every 5 seconds
    return () => clearInterval(interval);
  }, []);
  console.log(songCollabData,'songCollabDataa')
  const myData = songCollabData && songCollabData.CollaborationTypeID === 'Solo' ? isReversed
    ? [
        
        {
          city: 'New Delhi',
          lat: songCollabData && songCollabData[0]?.Lattitude,
          lng: songCollabData && songCollabData[0]?.Longitude,
          altitude: 0.1,
          color: '#ff0000',
          img: songCollabData && songCollabData[0]?.users[0]?.ProfilePhoto !== null  ? songCollabData[0]?.users[0]?.ProfilePhoto : defaultImg ,
        },
      ]
    : [
        {
          city: 'New Delhi',
          lat: songCollabData && songCollabData[0]?.Lattitude,
          lng: songCollabData && songCollabData[0]?.Longitude,
          altitude: 0.1,
          color: '#ff0000',
          img: songCollabData && songCollabData[0]?.users[0]?.ProfilePhoto !== null  ? songCollabData[0]?.users[0]?.ProfilePhoto : defaultImg ,
        },
        
      ] : isReversed
      ? [
          
          {
            city: 'New Delhi',
            lat: songCollabData && songCollabData[0]?.Lattitude,
            lng: songCollabData && songCollabData[0]?.Longitude,
            altitude: 0.1,
            color: '#ff0000',
            img: songCollabData && songCollabData[0]?.users[0]?.ProfilePhoto !== null  ? songCollabData[0]?.users[0]?.ProfilePhoto : defaultImg ,
          },
          {
            city: 'New Delhi',
            lat: songCollabData && songCollabData[0]?.joinOriginalSongCollaboration[0]?.Lattitude,
            lng: songCollabData && songCollabData[0]?.joinOriginalSongCollaboration[0]?.Longitude,
            altitude: 0.1,
            color: '#ff0000',
            img: songCollabData && songCollabData[0]?.joinOriginaluser[0]?.ProfilePhoto !== null  ? songCollabData[0]?.joinOriginaluser[0]?.ProfilePhoto : defaultImg ,
          },
        ]
      : [
          
        {
          city: 'New Delhi',
          lat: songCollabData && songCollabData[0]?.Lattitude,
          lng: songCollabData && songCollabData[0]?.Longitude,
          altitude: 0.1,
          color: '#ff0000',
          img: songCollabData && songCollabData[0]?.users[0]?.ProfilePhoto !== null  ? songCollabData[0]?.users[0]?.ProfilePhoto : defaultImg ,
        },
        {
          city: 'New Delhi',
          lat: songCollabData && songCollabData[0]?.joinOriginalSongCollaboration[0]?.Lattitude,
          lng: songCollabData && songCollabData[0]?.joinOriginalSongCollaboration[0]?.Longitude,
          altitude: 0.1,
          color: '#ff0000',
          img: songCollabData && songCollabData[0]?.joinOriginaluser[0]?.ProfilePhoto !== null  ? songCollabData[0]?.joinOriginaluser[0]?.ProfilePhoto : defaultImg ,
        },
      ]

  const arcsData = songCollabData && songCollabData.CollaborationTypeID === 'Solo' ?  [
    {
      startLat: myData[0].lat,
      startLng: myData[0].lng,
      endLat: myData[0].lat,
      endLng: myData[0].lng,
      color: 'rgba(255, 192, 203, 0.5)', // Transparent pink color
      altitude: 0.5,
       stroke: 1,
      gap: 0.03,
      dash: 0.02,
      scale: 0.3,
      time: 5000,
    },
  ] : [
    {
      startLat: myData[0].lat,
      startLng: myData[0].lng,
      endLat: myData[1].lat,
      endLng: myData[1].lng,
      color: 'rgba(255, 192, 203, 0.5)', // Transparent pink color
      altitude: 0.5,
       stroke: 1,
      gap: 0.03,
      dash: 0.02,
      scale: 0.3,
      time: 5000,
    },
  ]

  let screenWidth = window.innerWidth;

  console.log( data && data,'paramsssData')

  return (
    <div className="globe-container">
    {/* Starry sky background */}
    <div className="starry-overlay"></div>
    {/* Globe component */}
    <Box onClick={() => {handlepLay(false)}} className='controler-container'><CancelIcon style={{fontSize:'30px',color:'rgb(192, 204, 216)'}}/></Box>
    <div className="image-container" style={{cursor:'pointer'}} onClick={() => {handlepLay(false)}}>
    {
        data && data[0].MixedSongURL.search('mp3') !== -1 ?  <img src={data && data[0].CoverImageURL} alt="Image" width="80" height="80" /> :  <video src={data && data[0].MixedSongURL} style={{borderRadius:'5px'}} alt="Image" width="80" height="80" />
       }
       
      </div>
      <div  style={{
        width: '480px',
        height: '480px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Globe
      ref={globeEl}
      className="globe-wrapper" 
        width={screenWidth > 515 ? 480 : 350}
        height={screenWidth > 515 ? 480 : 350}
        globeImageUrl={globeImage}
        htmlElementsData={myData}
        htmlAltitude='altitude'
        htmlElement={(data) => {
          const { city, color, img } = data;
          const element = document.createElement('div');
          element.style.color = color;
          element.innerHTML = `
            <div style="text-align:center;">
              <img src="${img}" style="width:30px;height:30px;margin:0 auto;border-radius:50%;border:2px solid white;" />
            </div>`;
          return element;
        }}
        arcsData={arcsData}
        arcColor='color'
        arcStroke='stroke'
        arcDashGap='gap'
        arcDashLength='dash'
        arcAltitudeAutoScale='scale'
        arcDashAnimateTime='time'
      />
      </div>
  </div>
  );
}
